<!--CAR EXCHANGE PANEL STARTS HERE-->
<form
  [formGroup]="carExchangeForm"
  [ngClass]="{ 'pointer-block': !ACCESS }"
  class="one3d-exchange-panel one3d-car-exchange-new-panel-wrapper"
>
  <div class="maincontainerrightin">
    <!--title-->
    <div class="fg summarylisttitle">
      <!-- <div class="one3d-exchange-panel-heading"> -->
      <!-- <span
          [hidden]="!ACCESS && callConnected"
          aria-label="Back to Home"
          (click)="goBack(); show3d()"
          class="car-exchange-close"
        >
          <img src="assets/images/exchange-close-icon.svg" />
        </span> -->
      <!-- </div> -->
      <span>CAR EXCHANGE</span>
    </div>

    <!--title-->
    <!--VARIANTS-->
    <div class="fg summarylisttitlespace">
      <!--form details-->
      <div class="fg detailforminfo" #scrollToForm>
        <div class="fg detailforminfoRow">
          <div class="one3d-exchange-panel-row">
            <div class="one3d-exchange-panel-col-left">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select
                    formControlName="year"
                    placeholder="2022"
                    (change)="onYearChange()"
                  >
                    <option value="">Select</option>
                    <option
                      [value]="item"
                      *ngFor="let item of exchangeParamList.year_data_array"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>
                <label>Manufacture Year</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('year', 'required')"
                >
                  Please select Year.
                </div>
              </div>
            </div>
            <!--  -->
            <div class="one3d-exchange-panel-col-right">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select
                    formControlName="make"
                    placeholder="1"
                    name="make"
                    (change)="onMakeChange()"
                  >
                    <option value="">Select</option>
                    <option
                      *ngFor="let list of exchangeParamList.make_list"
                      [value]="list.makeId"
                    >
                      {{ list.makeName }}
                    </option>
                  </select>
                </div>
                <label>Make</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('make', 'required')"
                >
                  Please select Make.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fg detailforminfoRow">
          <div class="one3d-exchange-panel-row">
            <!-- <div class="one3d-exchange-panel-col-left">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select formControlName="month" placeholder="1">
                    <option value="1">Select</option>
                    <option value="2">1</option>
                    <option value="3">2</option>
                    <option value="4">3</option>
                    <option value="5">4</option>
                  </select>
                </div>
                <label>Month</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('month', 'required')"
                >
                  Please select Month.
                </div>
              </div>
            </div> -->
            <div class="one3d-exchange-panel-col-left">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select
                    formControlName="model"
                    name="model"
                    (change)="onModelChange()"
                  >
                    <option value="">Select</option>
                    <ng-container
                      *ngFor="let item of exchangeParamList.model_list"
                    >
                      <option
                        *ngIf="filterOnLaunchDate(item.LaunchedOn)"
                        [value]="item.ModelId"
                      >
                        {{ item.ModelName }}
                      </option>
                    </ng-container>
                  </select>
                </div>
                <label>Model</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('model', 'required')"
                >
                  Please select Model.
                </div>
              </div>
            </div>
            <div class="one3d-exchange-panel-col-right">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select formControlName="variant" name="model">
                    <option value="">Select</option>
                    <option
                      [value]="item.ID"
                      *ngFor="let item of exchangeParamList.variant_data_array"
                    >
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
                <label>Variant</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('variant', 'required')"
                >
                  Please select Variant.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fg detailforminfoRow">
          <div class="one3d-exchange-panel-row">
            <div class="one3d-exchange-panel-col-left">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select formControlName="city">
                    <option value="">Select</option>
                    <option *ngFor="let city of cityList" [value]="city.CityId">
                      {{ city.CityName }}
                    </option>
                  </select>
                </div>
                <label>City</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('city', 'required')"
                >
                  Please select City.
                </div>
              </div>
            </div>
            <div class="one3d-exchange-panel-col-right">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select formControlName="owner" placeholder="1">
                    <option value="">Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
                <label>Owner</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('owner', 'required')"
                >
                  Please select Owner.
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="fg detailforminfoRow">
          <div class="one3d-exchange-panel-row">
            <div class="one3d-exchange-panel-col-left">
              <div class="floating-label coobjectinputlable2">
                <div class="custom-select mngpaym">
                  <select formControlName="color" placeholder="Blue">
                    <option value="1">Select</option>
                    <option value="2">Blue</option>
                    <option value="3">Blue</option>
                    <option value="4">Blue</option>
                    <option value="5">Blue</option>
                  </select>
                </div>
                <label>Color</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('color', 'required')"
                >
                  Please select Color.
                </div>
              </div>
            </div>
           
          </div>
        </div> -->
        <div class="fg detailforminfoRow">
          <div class="one3d-exchange-panel-row">
            <div class="one3d-exchange-panel-col w-100">
              <div class="floating-label coobjectinputlable2">
                <input
                  type="number"
                  name="drivenKM"
                  formControlName="drivenKM"
                  (blur)="onDrivenBlur()"
                />
                <label>Kilometer Driven</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('drivenKM', 'required')"
                >
                  Please provide Kilometer.
                </div>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('drivenKM', 'min')"
                >
                  Kilometer should be greater value of 500.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="one3d-yes-no-switch-button-wrapper">
          <div class="one3d-border-gradient one3d-border-gradient-top"></div>
          <div class="fg detailforminfoRow one3d-detailforminfoRow-new">
            <div class="one3d-exchange-panel-row">
              <div class="one3d-exchange-panel-col-left">
                <div class="floating-label coobjectinputlable2">
                  <p>Existing Car loan ?</p>
                </div>
              </div>
              <div
                class="one3d-exchange-panel-col-right"
                *ngIf="!callConnected"
              >
                <div class="one3d-exchange-panel-switch-box">
                  <div class="filter-checkbox">
                    <input
                      type="checkbox"
                      name="haveLoan"
                      value=""
                      formControlName="haveLoan"
                      id="my-checkbox"
                      (change)="toggleHaveLoan()"
                    />

                    <label for="my-checkbox" class="label-hidden"></label>
                    <label
                      for="my-checkbox"
                      class="second-label label-displayed"
                      >Yes</label
                    >
                    <label for="my-checkbox" class="first-label label-displayed"
                      >No</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="one3d-border-gradient one3d-border-gradient-bottom"></div>
        </div>
        <div class="fg detailforminfoRow" *ngIf="!userHaveLoan">
          <!-- <div class="floating-label coobjectinputlable2">
            <a
              (click)="saveExchangeDetails()"
              href="javascript:void(0)"
              class="variantlisttitlebtnrightbtn one3d-exchange-panel-calculate"
            >
              Calculate
            </a>
          </div> -->
          <span class="note">
            Note: To know the exact exchange value, please visit the nearest
            dealership
          </span>
          <!-- <span class="note" *ngIf="approxPrice">
            Note: To know the exact exchange value, please visit the nearest
            dealership
          </span> -->
        </div>
        <div *ngIf="userHaveLoan" class="one3d-car-exchange-new-details">
          <div class="fg detailforminfoRow">
            <div class="one3d-exchange-panel-row">
              <div class="one3d-exchange-panel-col-left">
                <div class="floating-label coobjectinputlable2">
                  <input
                    type="number"
                    name="existingEMI"
                    formControlName="existingEMI"
                  />
                  <label>Existing EMI</label>
                  <div
                    class="fg errmessagered"
                    *ngIf="hasError('existingEMI', 'required')"
                  >
                    Please provide Existing EMI.
                  </div>
                </div>
              </div>

              <div class="one3d-exchange-panel-col-right">
                <div class="floating-label coobjectinputlable2">
                  <input
                    type="number"
                    name="existingLoanTenure"
                    formControlName="existingLoanTenure"
                  />
                  <label>Loan tenure (months)</label>
                  <div
                    class="fg errmessagered"
                    *ngIf="hasError('existingLoanTenure', 'required')"
                  >
                    Please provide Loan tenure.
                  </div>
                  <div
                    class="fg errmessagered"
                    *ngIf="hasError('existingLoanTenure', 'max')"
                  >
                    Loan tenure can't be greater than 120 months.
                  </div>
                  <div
                    class="fg errmessagered"
                    *ngIf="hasError('existingLoanTenure', 'min')"
                  >
                    Loan tenure can't be smaller than 11 months.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fg detailforminfoRow">
            <div class="floating-label coobjectinputlable2">
              <a
                (click)="onCalculate()"
                [ngClass]="{ 'disable-access': !carExchangeForm.valid }"
                href="javascript:void(0)"
                class="variantlisttitlebtnrightbtn one3d-exchange-panel-calculate"
              >
                Calculate
              </a>
            </div>
          </div>
        </div>
        <div class="one3d-emi-three-boxes-wrapper" *ngIf="showOptions">
          <div class="one3d-emi-three-boxes-wrapper-top">
            <!-- <ng-container *ngFor="let item of optionEmiList; let i = index"> -->
            <div
              class="one3d-emi-three-boxes-wrap-1"
              [ngClass]="{
                'one3d-selected-exchange-box': 1 == selectedOption
              }"
              (click)="onSelectOption(1)"
            >
              <h5>For your existing EMI</h5>
              <p>
                Down Payment =
                {{ formatCurrency(sameEMIDownPayment) }}
              </p>
              <p>Tenure = {{ sameEMITenure }} months (Approx)</p>
              <div class="one3d-selected-icon-wrap">
                <img
                  src="assets/images/one3d-unselected-circle.svg"
                  class="one3d-unselected-circle"
                  *ngIf="1 != selectedOption"
                />
                <img
                  src="assets/images/one3d-selected-check.svg"
                  class="one3d-selected-check"
                  *ngIf="1 == selectedOption"
                />
              </div>
            </div>
            <div
              class="one3d-emi-three-boxes-wrap-1"
              style="display: 'grid'"
              [ngClass]="{
                'one3d-selected-exchange-box': '2' == selectedOption
              }"
            >
              <h5 (click)="onSelectOption(2)">Customize your own plan</h5>
              <div *ngIf="selectedOption == '2'">
                <div class="fg emititle emititleinputOuter black-font">
                  Down payment<span>*</span>
                  <div class="emititleinputInput">
                    <input
                      class="coobjectinput2"
                      type="text"
                      [min]="customDownPaymentRange.min"
                      [max]="customDownPaymentRange.max"
                      placeholder=""
                      [(ngModel)]="customDownPayment"
                      [ngModelOptions]="{ standalone: true }"
                      (blur)="onChangeCustomDownPaymentSlider()"
                    />
                  </div>
                </div>
                <div class="fg emititleslide">
                  <span class="emititleslide-min black-font">
                    {{ formatCurrency(customDownPaymentRange.min) }}
                  </span>
                  <mat-slider
                    thumbLabel
                    [min]="customDownPaymentRange.min"
                    [max]="customDownPaymentRange.max"
                    step="1"
                    [(ngModel)]="customDownPayment"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onChangeCustomDownPaymentSlider()"
                    aria-label="unit(s)"
                  ></mat-slider>
                  <span class="emititleslide-max black-font">
                    {{ formatCurrency(customDownPaymentRange.max) }}
                  </span>
                </div>

                <!-- loan -->
                <div class="fg emititle emititleinputOuter black-font">
                  Loan Tenure (months)<span>*</span>
                  <div class="emititleinputInput emi-loan-tenure-input-wrap">
                    <input
                      class="coobjectinput2"
                      type="text"
                      min="0"
                      max="120"
                      placeholder=""
                      [(ngModel)]="customMonths"
                      [ngModelOptions]="{ standalone: true }"
                      (blur)="onChangeCustomMonthSlider()"
                    />
                  </div>
                </div>
                <div class="fg emititleslide">
                  <span class="emititleslide-min black-font"> 11 </span>
                  <mat-slider
                    thumbLabel
                    min="11"
                    max="120"
                    step="1"
                    aria-label="unit(s)"
                    [(ngModel)]="customMonths"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onChangeCustomMonthSlider()"
                  ></mat-slider>
                  <span class="emititleslide-max black-font"> 120 </span>
                </div>
                <div class="fg result-side">
                  <h3 style="font-weight: 900; font-size: 18px">
                    EMI value = {{ formatCurrency(EMI) }}
                  </h3>
                </div>
              </div>
              <div class="one3d-selected-icon-wrap" (click)="onSelectOption(2)">
                <img
                  src="assets/images/one3d-unselected-circle.svg"
                  class="one3d-unselected-circle"
                  *ngIf="2 != selectedOption"
                />
                <img
                  src="assets/images/one3d-selected-check.svg"
                  class="one3d-selected-check"
                  *ngIf="2 == selectedOption"
                />
              </div>
            </div>
            <!-- </ng-container> -->
            <span class="note"
              >Note : This is the tentative EMI for your new MG vehicle after
              exchanging your existing car. To know the exact exchange value,
              please visit the nearest dealership</span
            >
          </div>
          <!-- <div class="one3d-emi-three-boxes-wrapper-bottom">
            <div class="one3d-emi-three-boxes-wrapper-bottom-left">
              <div class="one3d-emi-three-boxes-wrap-1">
                <h5>Same EMI -</h5>
                <p>Change in Down payment (D) and same tenure</p>
                <div class="one3d-selected-icon-wrap">
                  <img
                    src="assets/images/one3d-unselected-circle.svg"
                    class="one3d-unselected-circle"
                  />
                  <img
                    src="assets/images/one3d-selected-check.svg"
                    class="one3d-selected-check"
                  />
                </div>
              </div>
            </div>
            <div class="one3d-emi-three-boxes-wrapper-bottom-right">
              <div class="one3d-emi-three-boxes-wrap-1">
                <h5>Normal EMI -</h5>
                <p>Same Tenure</p>
                <div class="one3d-selected-icon-wrap">
                  <img
                    src="assets/images/one3d-unselected-circle.svg"
                    class="one3d-unselected-circle"
                  />
                  <img
                    src="assets/images/one3d-selected-check.svg"
                    class="one3d-selected-check"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="fg detailforminfoRow">
            <div class="one3d-exchange-panel-row">
              <div class="one3d-exchange-panel-col-left">
                <div class="floating-label coobjectinputlable2">
                  <input
                    type="number"
                    name="downPayment"
                    formControlName="downPayment"
                  />
                  <label>Down payment</label>
                  <div
                    class="fg errmessagered"
                    *ngIf="hasError('downPayment', 'required')"
                  >
                    Please provide Down payment.
                  </div>
                </div>
              </div>
              <div class="one3d-exchange-panel-col-right">
                <div class="floating-label coobjectinputlable2">
                  <input
                    type="number"
                    name="existingEMI"
                    formControlName="existingEMI"
                  />
                  <label>Loan tenure (months)</label>
                  <div
                    class="fg errmessagered"
                    *ngIf="hasError('existingEMI', 'required')"
                  >
                    Please provide Loan tenure.
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="fg detailforminfoRow">
            <div class="floating-label coobjectinputlable2">
              <a
                (click)="createOptions()"
                href="javascript:void(0)"
                class="variantlisttitlebtnrightbtn one3d-exchange-panel-calculate"
              >
                Calculate
              </a>
            </div>
          </div> -->
          <!-- <div class="fg summarylistfdetails" *ngIf="approxPrice">
            Approximate exchange value of your car will be :
            <strong> {{ formatCurrency(approxPrice) }} </strong>
          </div> -->
        </div>
        <!-- <div *ngIf="approxPrice">
          <div class="one3d-emi-value-text" style="display: none">
            EMI VALUE = ₹ 25,000
          </div>
          <div class="fg summarylistfdetails one3d-border-new-top pdl-0 pdr-0">
            Approximate exchange value of your car will be :
            <strong> INR {{ formatCurrency(approxPrice) }} </strong>
          </div>
          <div
            class="fg summarylistfdetails one3d-small-text-desc border-0 pdl-0 pdr-0"
            *ngIf="!callConnected"
          >
            The Buying Price mentioned above for your existing car is tentative
            & based on inputs filled. The Actual Buying Price can only be told
            after proper Physical Evaluation of the Vehicle.
          </div>
        </div> -->
      </div>
      <!--form details-->
    </div>
  </div>
  <!--button-->
  <div class="variantlisttitlebtn" *ngIf="ACCESS || !callConnected">
    <div class="variantlisttitlebtnleft">
      <a
        href="javascript:void(0)"
        (click)="goBack()"
        class="variantlisttitlebtnleftbtn"
        >Cancel</a
      >
    </div>
    <!-- [ngClass]="{ 'disable-access': exchangePrice == '' }" -->
    <div class="variantlisttitlebtnright">
      <a
        href="javascript:void(0)"
        [ngClass]="{ 'disable-access': !carExchangeForm.valid }"
        (click)="updateExchangeData()"
        class="variantlisttitlebtnrightbtn"
      >
        Save
      </a>
    </div>
  </div>
</form>
<!--CAR EXCHANGE PANEL ENDS HERE-->
