// import { DefaultComponent } from './default.component';
// import { GlosterComponent } from './gloster/gloster.component';
// import { Hector3dComponent } from './hector3d/hector3d.component';

'use strict';

let assetsUrl = '';
let arAssetsUrl = '';
if (window.location.host == 'cc.mgmotor.co.in') {
  assetsUrl = 'https://d6qbp9nx8649u.cloudfront.net/mg_expert';
  arAssetsUrl = 'https://d6qbp9nx8649u.cloudfront.net/ar/';
} else {
  assetsUrl = 'https://dxf3kulhcrbjg.cloudfront.net/mg_expert';
  // assetsUrl = '';
  arAssetsUrl = 'https://dxf3kulhcrbjg.cloudfront.net/ar/';
}
export function getAssetsURL() {
  return assetsUrl;
}
export function getArAssetsURL() {
  return arAssetsUrl;
}
export const appointmentTimings: any[] = [
  { value: "09:00", text: "09:00 AM" },
  { value: "09:30", text: "09:30 AM" },
  { value: "10:00", text: "10:00 AM" },
  { value: "10:30", text: "10:30 AM" },
  { value: "11:00", text: "11:00 AM" },
  { value: "11:30", text: "11:30 AM" },
  { value: "12:00", text: "12:00 PM" },
  { value: "12:30", text: "12:30 PM" },
  { value: "13:00", text: "01:00 PM" },
  { value: "13:30", text: "01:30 PM" },
  { value: "14:00", text: "02:00 PM" },
  { value: "14:30", text: "02:30 PM" },
  { value: "15:00", text: "03:00 PM" },
  { value: "15:30", text: "03:30 PM" },
  { value: "16:00", text: "04:00 PM" },
  { value: "16:30", text: "04:30 PM" },
  { value: "17:00", text: "05:00 PM" },
  { value: "17:30", text: "05:30 PM" },
  { value: "18:00", text: "06:00 PM" },
  { value: "18:30", text: "06:30 PM" },
  { value: "19:00", text: "07:00 PM" },
  { value: "19:30", text: "07:30 PM" },
  { value: "20:00", text: "08:00 PM" },
  { value: "20:30", text: "08:30 PM" },
  { value: "21:00", text: "09:00 PM" },
  { value: "21:30", text: "09:30 PM" },
  { value: "22:00", text: "10:00 PM" },
  { value: "22:30", text: "10:30 PM" },
  { value: "23:00", text: "11:00 PM" },
  { value: "23:30", text: "11:30 PM" },
  { value: "00:00", text: "12:00 AM" },
  { value: "00:30", text: "12:30 AM" },
  { value: "01:00", text: "01:00 AM" },
  { value: "01:30", text: "01:30 AM" },
  { value: "02:00", text: "02:00 AM" },
  { value: "02:30", text: "02:30 AM" },
  { value: "03:00", text: "03:00 AM" },
  { value: "03:30", text: "03:30 AM" },
  { value: "04:00", text: "04:00 AM" },
  { value: "04:30", text: "04:30 AM" },
  { value: "05:00", text: "05:00 AM" },
  { value: "05:30", text: "05:30 AM" },
  { value: "06:00", text: "06:00 AM" },
  { value: "06:30", text: "06:30 AM" },
  { value: "07:00", text: "07:00 AM" },
  { value: "07:30", text: "07:30 AM" },
  { value: "08:00", text: "08:00 AM" },
  { value: "08:30", text: "08:30 AM" },
];

export const defaultCarDetails: any = {
  // hectorplus: {
  //   variant_id: '62',
  //   model_id: '3',
  //   variant_feature_id: '331',
  //   mainCar: 'Hector Plus',
  //   urlScheme: '/hectorplus',
  //   gaModelName: 'Hector Plus',
  //   component: null,
  //   arTitle: 'Hector AR',
  //   isExpertEnabled: true,
  //   topVar: 'Sharp CVT',
  //   audio: {
  //     intro: {
  //       start: `${assetsUrl}/audio-journey/hector/eXpert Introduction.mp3`,
  //       end: `${assetsUrl}/audio-journey/hector/Nudge to fill Personal Details 1.mp3`,
  //     },
  //     home: {
  //       feature: '',
  //       variant: `${assetsUrl}/audio-journey/hector/On Variants Selection Screen .mp3`,
  //       summary: `${assetsUrl}/audio-journey/hector/Nudge while in Summary 1.mp3`,
  //       self: `${assetsUrl}/audio-journey/hector/Nudge to tap or take on Self Explore 1.mp3`,
  //     },
  //   },
  //   video: {
  //     intro: {
  //       desktop: `${assetsUrl}/intro_video/hector/Hector_desktop_v1_.m3u8`,
  //       desktop_Mp4: `${assetsUrl}/intro_video/hector/web/MG_Hector_Intro_DESKTOP.mp4`,
  //       mobile: `${assetsUrl}/intro_video/hector/Hector_mobile_v1_.m3u8`,
  //       mobile_Mp4: `${assetsUrl}/intro_video/hector/web/MG_Hector_Intro_MOBILE.mp4`,
  //       thumbnail_d: `${assetsUrl}/intro_video/thumb/hector_thumb.png`,
  //       thumbnail_m: `${assetsUrl}/intro_video/thumb/hector_thumb_mob.png`,
  //     },
  //   },
  // },
  // hector: {
  //   variant_id: '46',
  //   model_id: '1',
  //   variant_feature_id: '294',
  //   mainCar: 'HectorWeb',
  //   gaModelName: 'Hector',
  //   urlScheme: '/hector',
  //   arTitle: 'Hector AR',
  //   topVar: '1.5 Hybrid Sharp',
  //   isExpertEnabled: true,
  //   component: null,
  //   audio: {
  //     intro: {
  //       start: `${assetsUrl}/audio-journey/hector/eXpert Introduction.mp3`,
  //       end: `${assetsUrl}/audio-journey/hector/Nudge to fill Personal Details 1.mp3`,
  //     },
  //     home: {
  //       feature: '',
  //       variant: `${assetsUrl}/audio-journey/hector/On Variants Selection Screen .mp3`,
  //       summary: `${assetsUrl}/audio-journey/hector/Nudge while in Summary 1.mp3`,
  //       self: `${assetsUrl}/audio-journey/hector/Nudge to tap or take on Self Explore 1.mp3`,
  //     },
  //   },
  //   video: {
  //     intro: {
  //       desktop_Mp4: `${assetsUrl}/intro_video/hector/web/MG_Hector_Intro_DESKTOP.mp4`,
  //       mobile_Mp4: `${assetsUrl}/intro_video/hector/web/MG_Hector_Intro_MOBILE.mp4`,
  //       desktop: `${assetsUrl}/intro_video/hector/Hector_desktop_v1_.m3u8`,
  //       mobile: `${assetsUrl}/intro_video/hector/Hector_mobile_v1_.m3u8`,
  //       thumbnail_d: `${assetsUrl}/intro_video/thumb/hector_thumb.png`,
  //       thumbnail_m: `${assetsUrl}/intro_video/thumb/hector_thumb_mob.png`,
  //     },
  //   },
  // },
  gloster: {
    variant_id: '226',
    model_id: '4',
    variant_feature_id: '1239',
    gaModelName: 'Gloster',
    mainCar: 'Gloster',
    urlScheme: '/gloster',
    topVar: 'LV4',
    component: null,
    isExpertEnabled: true,
    arTitle: 'Gloster AR',
    audio: {
      intro: {
        start: `${assetsUrl}/audio-journey/gloster/eXpert Introduction.mp3`,
        end: `${assetsUrl}/audio-journey/gloster/Nudge to fill Personal Details 1.mp3`,
      },
      home: {
        feature: '',
        variant: `${assetsUrl}/audio-journey/hector/On Variants Selection Screen.mp3`,
        summary: `${assetsUrl}/audio-journey/hector/Nudge while in Summary 1.mp3`,
        self: `${assetsUrl}/audio-journey/hector/Nudge to tap or take on Self Explore 1.mp3`,
      },
    },
    video: {
      intro: {
        desktop_Mp4: `${assetsUrl}/intro_video/gloster/web/MG_Gloster_Intro_Desktop.mp4`,
        mobile_Mp4: `${assetsUrl}/intro_video/gloster/web/MG_Gloster_Intro_Mobile.mp4`,
        desktop: `${assetsUrl}/intro_video/gloster/MG_Gloster_Intro_Desktop.m3u8`,
        mobile: `${assetsUrl}/intro_video/gloster/intro.m3u8`,
        thumbnail_d: `${assetsUrl}/intro_video/thumb/gloster_thumb.png`,
        thumbnail_m: `${assetsUrl}/intro_video/thumb/gloster_thumb_mob.png`,
      },
    },
  },
  zsev: {
    // variant_id: '201',
    variant_id: '232',
    model_id: '6',
    // variant_feature_id: '1100',
    variant_feature_id: '1278',
    mainCar: 'MG_ZS_EV',
    arTitle: 'ZSEV AR',
    gaModelName: 'ZSEV-2022',
    urlScheme: '/zsev',
    topVar: 'EXCLUSIVE',
    component: null,
    isExpertEnabled: true,
    audio: {
      intro: {
        start: `${assetsUrl}/audio-journey/zsev/Scenario 1.mp3`,
        end: `${assetsUrl}/audio-journey/zsev/Nudge to fill Personal Details 1.mp3`,
      },
      home: {
        feature: '',
        variant: `${assetsUrl}/audio-journey/zsev/On Variants Selection Screen.mp3`,
        summary: `${assetsUrl}/audio-journey/zsev/Nudge while in Summary 1.mp3`,
        self: `${assetsUrl}/audio-journey/zsev/Nudge to tap or take on Self Explore 1.mp3`,
      },
    },
    video: {
      intro: {
        desktop_Mp4: `${assetsUrl}/intro_video/zsev/web/MG_ZS_EV_2022_INTRO.mp4`,
        mobile_Mp4: `${assetsUrl}/intro_video/zsev/web/MG_ZS_EV_2022_INTRO_Mobile.mp4`,
        desktop: `${assetsUrl}/intro_video/zsev/zsev_desktop_v1_.m3u8`,
        mobile: `${assetsUrl}/intro_video/zsev/MG ZS EV 2022_INTRO_Mobile.m3u8`,
        thumbnail_d: `${assetsUrl}/intro_video/thumb/zsev_thumb.png`,
        thumbnail_m: `${assetsUrl}/intro_video/thumb/zsev_thumb_mob.png`,
      },
    },
  },
  kmodel: {
    // variant_id: '183',
    variant_id: '239',
    model_id: '5',
    // variant_feature_id: '1028',
    variant_feature_id: '1327',
    // mainCar: 'KModel',
    mainCar: 'KMODEL_2024',
    arTitle: 'Astor AR',
    urlScheme: '/astor',
    gaModelName: 'Astor',
    // topVar: 'LV3_O_Wine_AT',
    // topVar:'SAVYPRO_WINE_CVT',
    topVar: 'SAVYPRO_WINE_AT',
    component: null,
    isExpertEnabled: true,
    audio: {
      intro: {
        start: `${assetsUrl}/audio-journey/astor/Scenario 1.mp3`,
        end: `${assetsUrl}/audio-journey/astor/Nudge to fill Personal Details 2.mp3`,
      },
      home: {
        feature: '',
        variant: `${assetsUrl}/audio-journey/astor/On Variants Selection Screen.mp3`,
        summary: `${assetsUrl}/audio-journey/astor/Nudge while in Summary 1.mp3`,
        self: `${assetsUrl}/audio-journey/astor/Nudge to tap or take on Self Explore 2.mp3`,
      },
    },
    video: {
      intro: {
        desktop_Mp4: `${assetsUrl}/intro_video/astor/web/MG_Astor_Desktop_V8_2.mp4`,
        mobile_Mp4: `${assetsUrl}/intro_video/astor/web/MG_Astor_Mobile_v4.mp4`,
        desktop: `${assetsUrl}/intro_video/astor/astor_desktop_v82_.m3u8`,
        mobile: `${assetsUrl}/intro_video/astor/astor_mobile_v4_.m3u8`,
        thumbnail_d: `${assetsUrl}/intro_video/thumb/astor_thumb.png`,
        thumbnail_m: `${assetsUrl}/intro_video/thumb/astor_thumb_mob.png`,
      },
    },
  },
  hectornew: {
    // variant_id: '159',
    variant_id: '235',
    model_id: '7',
    // variant_feature_id: '869',
    variant_feature_id: '1299',
    mainCar: 'HECTOR_2022',
    urlScheme: '/new-hector',
    gaModelName: 'Hector Next-Gen',
    arTitle: 'Hector Next-Gen AR',
    topVar: 'SAVYPRO_5_PETROL_CVT',
    isExpertEnabled: true,
    component: null,
    audio: {
      intro: {
        start: `${assetsUrl}/audio-journey/hector/eXpert Introduction.mp3`,
        end: `${assetsUrl}/audio-journey/hector/Nudge to fill Personal Details 1.mp3`,
      },
      home: {
        feature: '',
        variant: `${assetsUrl}/audio-journey/hector/On Variants Selection Screen.mp3`,
        summary: `${assetsUrl}/audio-journey/hector/Nudge while in Summary 1.mp3`,
        self: `${assetsUrl}/audio-journey/hector/Nudge to tap or take on Self Explore 1.mp3`,
      },
    },
    video: {
      intro: {
        desktop_Mp4: `${assetsUrl}/intro_video/hector22/web/MG_Hector_5_Desktop.mp4`,
        mobile_Mp4: `${assetsUrl}/intro_video/hector22/web/MG_Hector_5_Mobile.mp4`,
        desktop: `${assetsUrl}/intro_video/hector22/desktop/MG_Hector_5_seater.m3u8`,
        mobile: `${assetsUrl}/intro_video/hector22/mobile/MG_Hector_5_seater.m3u8`,
        thumbnail_d: `${assetsUrl}/intro_video/thumb/hector22_thumb.png`,
        thumbnail_m: `${assetsUrl}/intro_video/thumb/hector22_thumb_mob.png`,
      },
    },
  },
  hectorplusnew: {
    variant_id: '164',
    // variant_id: '237',
    model_id: '8',
    variant_feature_id: '903',
    // variant_feature_id: '1313',
    mainCar: 'HECTOR_2022',
    gaModelName: 'HectorPlus Next-Gen',
    urlScheme: '/new-hectorplus',
    arTitle: 'HectorPlus Next-Gen AR',
    topVar: '"SAVYPRO_6_PETROL_WOODEN_CVT"',
    isExpertEnabled: true,
    component: null,
    audio: {
      intro: {
        start: `${assetsUrl}/audio-journey/hector-plus/eXpert Introduction.mp3`,
        end: `${assetsUrl}/audio-journey/hector-plus/Nudge to fill Personal Details 1.mp3`,
      },
      home: {
        feature: '',
        variant: `${assetsUrl}/audio-journey/hector/On Variants Selection Screen.mp3`,
        summary: `${assetsUrl}/audio-journey/hector/Nudge while in Summary 1.mp3`,
        self: `${assetsUrl}/audio-journey/hector/Nudge to tap or take on Self Explore 1.mp3`,
      },
    },
    video: {
      intro: {
        desktop_Mp4: `${assetsUrl}/intro_video/hectorplus22/web/MG_Hector_7_Desktop.mp4`,
        mobile_Mp4: `${assetsUrl}/intro_video/hectorplus22/web/MG_Hector_7_Mobile.mp4`,
        desktop: `${assetsUrl}/intro_video/hectorplus22/desktop/MG_Hector_7_seater.m3u8`,
        mobile: `${assetsUrl}/intro_video/hectorplus22/mobile/MG_Hector_7_seater.m3u8`,
        thumbnail_d: `${assetsUrl}/intro_video/thumb/hectorplus22_thumb.png`,
        thumbnail_m: `${assetsUrl}/intro_video/thumb/hectorplus22_thumb_mob.png`,
      },
    },
  },
  emodel: {
    variant_id: '233',
    model_id: '9',
    variant_feature_id: '1285',
    mainCar: 'EV',
    gaModelName: 'EV',
    urlScheme: '/e-model',
    arTitle: 'Comet EV',
    topVar: '"PLUSH"',
    isExpertEnabled: true,
    component: null,
    audio: {
      intro: {
        start: `${assetsUrl}/audio-journey/EV/eXpert Introduction.mp3`,
        end: `${assetsUrl}/audio-journey/EV/Nudge to fill Personal Details 1.mp3`,
      },
      home: {
        feature: '',
        variant: `${assetsUrl}/audio-journey/EV/On Variants Selection Screen.mp3`,
        summary: `${assetsUrl}/audio-journey/EV/Nudge while in Summary 1.mp3`,
        self: `${assetsUrl}/audio-journey/EV/Nudge to tap or take on Self Explore 1.mp3`,
      },
    },
    video: {
      intro: {
        desktop_Mp4: `${assetsUrl}/intro_video/ev/web/Mg_Comet_Intro_Desktop.mp4`,
        mobile_Mp4: `${assetsUrl}/intro_video/ev/web/Mg_Comet_Intro_Mobile.mp4`,
        desktop: `${assetsUrl}/intro_video/ev/desktop/Mg_Comet_Intro_desktop.m3u8`,
        mobile: `${assetsUrl}/intro_video/ev/mobile/Mg_Comet_Intro_Mobile.m3u8`,
        thumbnail_d: `${assetsUrl}/intro_video/thumb/ev_thumb.png`,
        thumbnail_m: `${assetsUrl}/intro_video/thumb/ev_thumb_mobile.png`,
      },
    },
  },
};

// export assetsUrl;
