import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../service/global.service';
import { GaService } from '../../service/ga.service';
import { DataService } from '../../service/data.service';
import { defaultCarDetails } from '../carSettings';
import { ApiService } from 'src/app/service/api.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { EventService } from 'src/app/service/event.service';
import { Subscription } from 'rxjs';
declare var window: any;
declare var ONE3D: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  selectedVariant: import('../../service/global.service').variant;
  isInterior: boolean;
  colorList: any[];
  loadedCar: any;
  pinNo: string;
  $dataTransfer: Subscription;
  showConnectButton: any;
  exploreCar: boolean = false;
  limitedEdition: boolean;
  showLimitedEdition: boolean;
  disableLimitedEdition: boolean;
  limitedEditionData: any[] = [];
  $subscription: Subscription;
  summaryBtnText: string;
  bookNowText: string;

  constructor(
    private _global: GlobalService,
    public _data: DataService,
    private _api: ApiService,
    private _ga: GaService,
    private _snack: SnackbarService,
    private _event: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // this._global.hideAllIconsInHome(true);
    this.receiveDataTransfer();
  }

  ngOnInit(): void {
    this.loadedCar = this._data.getLocalCarBrand();
    if (this.loadedCar == 'emodel') {
      this.summaryBtnText = 'Checkout: View Summary';
      this.bookNowText = 'Book Now';
      this.showLimitedEdition = true;
    } else {
      this.bookNowText = 'Book Now';
      this.summaryBtnText = 'Checkout: View Summary';
      this.showLimitedEdition = false;
    }
    this.$subscription = this._global.variant.subscribe((data) => {
      this.selectedVariant = data;
      // alert("hOMEEEE"+JSON.stringify(data))
    });
    this.$subscription.add(
      this._global.pinno.subscribe((data) => (this.pinNo = data))
    );
    this.$subscription.add(
      this._global.isInterior.subscribe((data) => (this.isInterior = data))
    );
    this.$subscription.add(
      this._global.colorList.subscribe((data) => (this.colorList = data))
    );
    this.$subscription.add(
      this._global.limitedEditionUI.subscribe(
        (data) => (this.limitedEdition = data)
      )
    );
    this.$subscription.add(
      this._global.limitedEditionData.subscribe(
        (data) => (this.limitedEditionData = data)
      )
    );
    this.$subscription.add(
      this._global.showLimitedPackFlag.subscribe((data) => {
        console.log('data', data);
        if (data) {
          this.showLimitedEdition = true;
        } else {
          this.showLimitedEdition = false;
        }
      })
    );
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.dealer_agent_id) {
        this._global.updateDealerDetailsToken(params);
        this._global.togglePulseHubEnqForm(true);
      }
      // if (params && params.pin_no) {
      //   this._data.setLocalPinno(params.pin_no);
      //   this._global.updatePinno(params.pin_no);
      //   this.getAppointmentDataByPin(params.pin_no);
      // }
    });

    // if (
    //   this.params.snapshot.queryParams &&
    //   this.params.snapshot.queryParams.pin_no
    // ) {
    //   this._data.setLocalPinno(this.params.snapshot.queryParams.pin_no);
    //   await this.getAppointmentDataByPin(
    //     this.params.snapshot.queryParams.pin_no
    //   ).catch((err) => {
    //     console.error('err', err);
    //   });
    //   this._global.togglePulseHubEnqForm(true);
    // }
    window.sendData({ eName: 'page_view', eCat: 'home_page' });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._global.hideAllIconsInHome(false);
    this.$dataTransfer.unsubscribe();
    this.$subscription.unsubscribe();
  }

  playAudio(type: string) {
    let loadedCar = this._data.getLocalCarBrand();
    let url = defaultCarDetails[loadedCar].urlScheme;
    let audioUrl = null;
    ONE3D.toggleHotspot(false);
    switch (type) {
      case 'self':
        window.sendLiveDashboardData({
          name: 'click',
          category: 'car_config_side_menu',
          type: 'self_explore_button_clicked',
        });
        audioUrl = defaultCarDetails[loadedCar].audio.home.self;
        if (audioUrl && audioUrl != '') {
          this._global.updateSoundTrackURL(audioUrl);
        }
        // this.callGa('self explore');
        this._ga.sendGAEvent('landing page', 'Feature_Menu_2', 'self explore');
        this._api.saveBuildSummary(this.pinNo, 'self_explore');
        this.router.navigate([`${url}/self-explore`]);
        break;
      case 'best_feature':
        // this._global.updateSoundTrackURL(
        //   'https://dxf3kulhcrbjg.cloudfront.net/mg_expert/audio-journey/View best in class features.wav'
        // );
        window.sendData({
          eName: 'click',
          eCat: 'car_config_side_menu',
          eAct: 'audio_assisted_journey_clicked',
        });
        this.router.navigate([`${url}/features`]);
        this._api.saveBuildSummary(this.pinNo, 'audio_journey');
        // this.callGa('Feature_Menu_1');
        this._ga.sendGAEvent('landing page', 'Feature_Menu_1', 'audio');

        break;
      case 'variant':
        window.sendLiveDashboardData({
          name: 'click',
          category: 'car_config_side_menu',
          type: 'variant_button_clicked',
        });
        audioUrl = defaultCarDetails[loadedCar].audio.home.variant;
        this.callGa('variants');
        this._api.saveBuildSummary(this.pinNo, 'view_varient');
        this.router.navigate([`${url}/variants`]);
        break;
      case 'accessorize':
        audioUrl = defaultCarDetails[loadedCar].audio.home.variant;
        window.sendLiveDashboardData({
          name: 'click',
          category: 'car_config_side_menu',
          type: 'accessory_button_clicked',
        });

        this.callGa('accessorize');
        // this._api.saveBuildSummary(this.pinNo, 'view_accessorize');
        this.router.navigate([`${url}/accessorize`]);
        break;
      case 'summary':
        window.sendLiveDashboardData({
          name: 'Summary',
          category: 'RHS',
          type: 'Menu_RHS',
        });
        // this._global.updateSoundTrackURL(
        //   'https://dxf3kulhcrbjg.cloudfront.net/mg_expert/audio-journey/Nudge while in Summary 1.wav'
        // );
        audioUrl = defaultCarDetails[loadedCar].audio.home.summary;

        this.callGa('summary');
        this._api.saveBuildSummary(this.pinNo, 'view_summary');
        this.router.navigate([`${url}/summary`]);
        break;

      default:
        break;
    }
  }

  returnCarName(): string {
    switch (this.loadedCar) {
      case 'kmodel':
        return 'Astor';
      case 'hector':
        return 'Hector';
        break;
      case 'hectorplus':
        return 'Hector Plus';
        break;
      case 'zsev':
        return 'ZS EV';
      case 'gloster':
        return 'Gloster';
        break;
      case 'hectornew':
        return 'Next Gen Hector';
        break;
      case 'hectorplusnew':
        return 'Next Gen Hector Plus';
        break;
      case 'emodel':
        return 'Comet EV';
        break;

      default:
        break;
    }
  }

  connectToCall() {
    // console.log('Called');
    window.sendLiveDashboardData({
      eName: 'click',
      eCat: 'car_config_side_menu',
      eAct: 'live_consultation_button_clicked',
    });
    this._ga.sendGAEvent('landing page', 'click', 'expert');
    this._event.publish('openCallingModal', '');
  }

  openArModal() {
    this._global.toggleArModal(true);
  }

  callGa(label: string) {
    this._ga.sendGAEvent('Menu_RHS', 'click', label);
  }

  receiveDataTransfer() {
    this.$dataTransfer = this._event.subscribe('show_connect', (data) => {
      this.showConnectButton = data;
    });
  }

  toggleExplore() {
    // document.getElementById("exploreCar").style.display = document?.getElementById("exploreCar").style.display=="block"?"none":"block";
    // this.exploreCar =   document?.getElementById("exploreCar").style.display=="block"?true:false;
    // this._data.exploreCar = this.exploreCar;
    this._data.toggelExplore();
  }

  gotoLimitedEdition() {
    if (!this.limitedEdition) {
      this.toggleGamerEdition(true);
    }
    this._ga.sendGAEvent('Menu_RHS', 'click', 'Gamer edition');
    let loadedCar = this._data.getLocalCarBrand();
    let url = defaultCarDetails[loadedCar].urlScheme;
    this.router.navigate([`${url}/limited-edition-accessories`]);
  }

  toggleGamerEdition($event) {
    this._global.updateLimitedEditionUI($event);
    this._global.updateLimitedEdition3D($event);
  }
}
