
  <div class="one3d-primary-loader">
    <div id="lottie"></div>
    <div class="one3d-pg-br">
      <div id="loader">
        <h4 class="loading-text">Assembling your MG Hector</h4>
        <progress id='hector-progress' [value]=p max='100'></progress>
        <output class="new-progress" [for]=p>{{p}}</output>
      </div>
    </div>
  </div>





