<!-- <div class="feedbackform" id="FeedbackForm">
  <div class="feedbackOuter">
    <form class="rate-form" #reviewForm="ngForm">
      <div class="fg feedbackOutertitle1">
        How would you rate our MG Expert Experience
        <span>Rate Us on a scale of one to five</span>
      </div>
      <div class="fg feedbackOutertitle2">
        <div class="feedbackOutertitle2star" (click)="ratingGiven = 1">
          <img src="{{ ratingGiven < 1 ? grayStarUrl : redStarUrl }}" />
        </div>
        <div class="feedbackOutertitle2star" (click)="ratingGiven = 2">
          <img src="{{ ratingGiven < 2 ? grayStarUrl : redStarUrl }}" />
        </div>
        <div class="feedbackOutertitle2star" (click)="ratingGiven = 3">
          <img src="{{ ratingGiven < 3 ? grayStarUrl : yellowStarUrl }}" />
        </div>
        <div class="feedbackOutertitle2star" (click)="ratingGiven = 4">
          <img src="{{ ratingGiven < 4 ? grayStarUrl : yellowStarUrl }}" />
        </div>
        <div class="feedbackOutertitle2star" (click)="ratingGiven = 5">
          <img src="{{ ratingGiven < 5 ? grayStarUrl : redStarUrl }}" />
        </div>
      </div>
      <div class="fg feedbackOuterradio">
        <ul>
          <li
            [ngClass]="{ active: item.isChecked }"
            *ngFor="let item of ratingAry"
          >
            <a>
              <input
                type="checkbox"
                id="{{ item.id }}"
                [(ngModel)]="item.isChecked"
                name="rating-options"
                value="{{ item.label }}"
              />
              <label for="{{ item.id }}">{{ item.label }}</label>
            </a>
          </li>
        </ul>
      </div>
      <div class="feedbackOutertexarea">
        <div class="textareatitle"><span>Share Your Opinion with Us</span></div>
        <textarea
          class="inputfeedbackta"
          name="text"
          ngModel
          placeholder="Share about your experience with us"
        ></textarea>
      </div>
      <div
        class="fg feedbackOuterbtn"
        [ngClass]="{ 'disable-access': ratingGiven < 1 }"
      >
        <button (click)="submitReview(reviewForm)" class="submitbuttonobject">
          Submit
        </button>
      </div>
    </form>
  </div>

  <div class="SelectLocationOverlay"></div>
</div> -->

<div class="feedbackform one3d-new-feedback-popup-wrapper" id="newFeedbackForm">
  <div class="feedbackOuter">
    <div class="one3d-feedbackOuter-in">
      <form class="rate-form">
        <div class="feedbackform one3d-new-feedback-top">
          <div class="fg feedbackOutertitle1">
            How would you rate our MG eXpert Experience?
          </div>
          <div class="new-feedback-stars-wrapper">
            <h4 class="new-feedback-stars-heading">
              Rate us on the scale of one to five
            </h4>
            <div class="fg feedbackOutertitle2">
              <div
                class="feedbackOutertitle2star {{ starColorPath(1) }}"
                (click)="rateGivenNumber(1)"
              >
                <svg
                  width="29"
                  height="27"
                  viewBox="0 0 29 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0L17.7555 10.0193L28.2903 10.0193L19.7674 16.2115L23.0229 26.2307L14.5 20.0385L5.97711 26.2307L9.23257 16.2115L0.709681 10.0193L11.2445 10.0193L14.5 0Z"
                    fill="#C4C4C4"
                  />
                </svg>
              </div>
              <div
                class="feedbackOutertitle2star {{ starColorPath(2) }}"
                (click)="rateGivenNumber(2)"
              >
                <svg
                  width="29"
                  height="27"
                  viewBox="0 0 29 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0L17.7555 10.0193L28.2903 10.0193L19.7674 16.2115L23.0229 26.2307L14.5 20.0385L5.97711 26.2307L9.23257 16.2115L0.709681 10.0193L11.2445 10.0193L14.5 0Z"
                    fill="#C4C4C4"
                  />
                </svg>
              </div>
              <div
                class="feedbackOutertitle2star {{ starColorPath(3) }}"
                (click)="rateGivenNumber(3)"
              >
                <svg
                  width="29"
                  height="27"
                  viewBox="0 0 29 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0L17.7555 10.0193L28.2903 10.0193L19.7674 16.2115L23.0229 26.2307L14.5 20.0385L5.97711 26.2307L9.23257 16.2115L0.709681 10.0193L11.2445 10.0193L14.5 0Z"
                    fill="#C4C4C4"
                  />
                </svg>
              </div>
              <div
                class="feedbackOutertitle2star {{ starColorPath(4) }}"
                (click)="rateGivenNumber(4)"
              >
                <svg
                  width="29"
                  height="27"
                  viewBox="0 0 29 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0L17.7555 10.0193L28.2903 10.0193L19.7674 16.2115L23.0229 26.2307L14.5 20.0385L5.97711 26.2307L9.23257 16.2115L0.709681 10.0193L11.2445 10.0193L14.5 0Z"
                    fill="#C4C4C4"
                  />
                </svg>
              </div>
              <div
                class="feedbackOutertitle2star {{ starColorPath(5) }}"
                (click)="rateGivenNumber(5)"
              >
                <svg
                  width="29"
                  height="27"
                  viewBox="0 0 29 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5 0L17.7555 10.0193L28.2903 10.0193L19.7674 16.2115L23.0229 26.2307L14.5 20.0385L5.97711 26.2307L9.23257 16.2115L0.709681 10.0193L11.2445 10.0193L14.5 0Z"
                    fill="#C4C4C4"
                  />
                </svg>
              </div>
            </div>
          </div>

          <h2 class="one3d-feedback-good-bad-heading" *ngIf="ratingGiven > 0">
            {{ ratingSystem && ratingSystem[ratingGiven]?.rattingText }}
          </h2>
          <div
            class="one3d-feedback-three-icons-wrapper"
            *ngIf="ratingGiven > 0"
          >
            <h5>
              {{ ratingSystem && ratingSystem[ratingGiven]?.rattingQuestion }}
            </h5>
            <div class="one3d-feedback-three-icons-main">
              <div
                *ngFor="
                  let item of ratingSystem &&
                    ratingSystem[ratingGiven]?.rattingAnswer;
                  let indx = index
                "
                class="one3d-feedback-three-icons-box"
                (click)="selectActionsMulti(indx + 1)"
              >
                <span class="one3d-feedback-three-icon">
                  <img
                    src="{{ selectedT[indx + 1] ? item.icon2 : item.icon1 }}"
                    alt=""
                    srcset=""
                  />
                </span>
                <span class="one3d-feedback-three-text">
                  {{ item.text }}
                  <!-- {{ checkActionExits(index + 1) }} -->
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="one3d-new-feedback-bottom">
          <!-- <div class="one3d-new-feedback-questions-wrapper">
            <h5 class="one3d-new-feedback-questions-heading">
              {{ questionList && questionList[0]?.question }}
            </h5>
            <div class="one3d-new-feedback-planning-select-wrap">
              <select
                class="one3d-new-feedback-planning-select"
                name="firstQ"
                [(ngModel)]="firstQ"
              >
                <option value="" selected disabled>Select</option>
                <option
                  *ngFor="let item of questionList && questionList[0]?.answer"
                  [value]="item"
                >
                  {{ item }}
                </option>
              </select>
            </div>
            
          </div> -->

          <div class="one3d-new-feedback-planning-vehicle">
            <p>{{ questionList && questionList[1]?.question }}</p>
            <div class="one3d-new-feedback-planning-select-wrap">
              <select
                class="one3d-new-feedback-planning-select"
                name="secondQ"
                [(ngModel)]="secondQ"
              >
                <option value="" selected disabled>Select</option>
                <option
                  *ngFor="let item of questionList && questionList[1]?.answer"
                  [value]="item"
                >
                  {{ item }}
                </option>
                <!-- <option>With in 2 weeks</option>
                <option>With in a month</option>
                <option>Not decided yet</option> -->
              </select>
            </div>
          </div>
          <div class="feedbackOutertexarea">
            <textarea
              class="inputfeedbackta"
              name="text"
              rows="1"
              [(ngModel)]="feedBackText"
              placeholder="TELL US MORE ABOUT YOUR EXPERIENCE"
            ></textarea>
          </div>
          <div class="fg feedbackOuterbtn">
            <button
              class="submitbuttonobject"
              [disabled]="ratingGiven == 0"
              (click)="submitReview()"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="SelectLocationOverlay"></div>
</div>
