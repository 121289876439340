<!--continue-viewing-popup-->
<div class="soundcheckmain">
    <!--popup-->
    <div class="soundcheckmaininner soundcheckmaininner-eModel">
      <div class="soundcheckmainOuter soundcheckmainOuter2">
        <div class="soundcheckbg">
          <div class="fg cvbftitle">
            Your applied accessories or packs is overlapping. Remove the one applied earlier?
          </div>
  
          <div class="fg soundcheckbtnarea"> 
            <div class="soundcheckbtnarealeft">
              <a
                href="javascript:void(0)"
                (click)="onCancel()"
                class="btnplayright"
                ><span>Cancel</span></a
              >
            </div>
            <div class="soundcheckbtnarearight">
              <a
                href="javascript:void(0)"
                class="btnplayleft"
                (click)="onContinue()"
                ><span>Continue</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--popup-->
  
    <!--overlay-->
    <div class="soundcheckmainOverlay soundcheckmainOverlay2"></div>
    <!--overlay-->
  </div>
  