<!--continue-viewing-popup-->
<div class="soundcheckmain">
  <!--popup-->
  <div class="soundcheckmaininner">
    <div class="soundcheckmainOuter soundcheckmainOuter2">
      <div class="soundcheckbg">
        <div class="fg cvbftitle">
          Do you want to continue in the same session ?
        </div>

        <div class="fg soundcheckbtnarea">
          <div class="soundcheckbtnarealeft">
            <a
              (click)="onClose()"
              href="javascript:void(0)"
              class="btnplayright"
              ><span>No</span></a
            >
          </div>
          <div class="soundcheckbtnarearight">
            <a
              (click)="onClickYes()"
              href="javascript:void(0)"
              class="btnplayleft"
              ><span>Yes</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--popup-->

  <!--overlay-->
  <div class="soundcheckmainOverlay soundcheckmainOverlay2"></div>
  <!--overlay-->
</div>
