import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThreeDModuleModule } from './three-d-module/three-d-module.module';
import { SoundCheckComponent } from './sound-check/sound-check.component';
import { AccessModalComponent } from './access-modal/access-modal.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { IntroVideoComponent } from './intro-video/intro-video.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SessionWarningComponent } from './three-d-module/session-warning/session-warning.component';

// import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NewLoaderComponent } from './new-loader/new-loader.component';

@NgModule({
  declarations: [
    AppComponent,
    SoundCheckComponent,
    IntroVideoComponent,
    AccessModalComponent,
    SessionWarningComponent,
    NewLoaderComponent,
    // SessionWarningDirective,
    // VJsPlayerDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    ThreeDModuleModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatProgressSpinnerModule,
    // MatSliderModule,
    // MatTooltipModule,
    // MatButtonModule,
    // MatIconModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
