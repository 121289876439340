import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/service/global.service';

@Component({
  selector: 'agent-audio',
  templateUrl: './agent-audio.component.html',
  styleUrls: ['./agent-audio.component.scss'],
})
export class AgentAudioComponent implements OnInit {
  @Input('agentData') agentDetails;
  audioCallStatus: string = '';
  isSoundMuted: boolean;
  imageNotFound: boolean;
  dealerDetailsForToken: any;
  constructor(private _global: GlobalService) {}

  ngOnInit(): void {
    this._global.isSoundMuted.subscribe((data) => (this.isSoundMuted = data));
    this._global.dealerDetailsForToken.subscribe(
      (data) => (this.dealerDetailsForToken = data)
    );
    this._global.socketStatus.subscribe((s) => {
      console.log('sss', s);
      this.audioCallStatus = s;
    });

    console.log('this.agentDetails', this.agentDetails);
  }

  errorHandler(event) {
    if (this.audioCallStatus == 'connected') {
      event.target.src = './assets/images/dealer_agent_not_found.svg';
      this.imageNotFound = true;
    } else {
      event.target.src = './assets/images/mngthumb.jpg';
    }
  }

  checkIsDealerAgent() {
    return (
      this.dealerDetailsForToken &&
      this.dealerDetailsForToken.hasOwnProperty('dealer_agent_id')
    );
  }
}
