<!--back up code-->

<div class="fg secObjectlbtm">
  <div
    class="fg secObjectlbtminner new-main-schedule-live-consultation new-connect-with-expert-wrapper"
  >
    <h4>
      <strong>Connect with the MG eXpert</strong>
      <span class="like-to-join-expert-oops-close">
        <img src="./assets/images/cross-white.svg"
      /></span>
    </h4>
    <div
      class="secObjectlbtmClose"
      *ngIf="showCloseButton"
      (click)="closeModal()"
    >
      <a><img src="./assets/images/Close.svg" /></a>
    </div>
    <p class="one3d-sub-heading-text">
      Get your questions answered by our expert live on a call.
    </p>
    <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
      <div class="secObjectlbtmform">
        <div
          *ngIf="!hideAllFormInput"
          class="floating-label coobjectinputlable"
          [ngClass]="{
            errmessageredinput: hasError('name', 'required')
          }"
        >
          <input
            class="coobjectinput"
            value=""
            maxlength="100"
            formControlName="name"
            type="text"
            placeholder="Enter your name here"
          />

          <label>Name *</label>
          <div class="fg errmessagered" *ngIf="hasError('name', 'required')">
            Please fill First Name
          </div>
        </div>

        <div
          *ngIf="!hideAllFormInput"
          class="floating-label coobjectinputlable"
          [ngClass]="{
            errmessageredinput:
              hasError('mobile', 'required') || hasError('mobile', 'pattern')
          }"
        >
          <div class="fg">
            <input
              class="floating-input coobjectinput"
              type="tel"
              formControlName="mobile"
              maxlength="10"
              placeholder="Enter your number here"
              (keyup)="onChangeMobileNo()"
            />
          </div>
          <label>Number *</label>

          <div class="fg errmessagered" *ngIf="hasError('mobile', 'required')">
            Please fill Mobile Number
          </div>
          <div class="fg errmessagered" *ngIf="hasError('mobile', 'pattern')">
            Please enter valid 10 digit Mobile Number
          </div>
        </div>

        <div class="new-otp-row">
          <div class="new-otp-col-left">
            <div
              class="floating-label coobjectinputlable"
              *ngIf="showOTPField"
              [ngClass]="{
                errmessageredinput:
                  hasError('otp', 'required') || failedVerifiedOpt
              }"
            >
              <input
                class="floating-input coobjectinput"
                value=""
                type="tel"
                formControlName="otp"
                maxlength="4"
                placeholder="Enter OTP"
              />
              <label>OTP *</label>
              <div
                class="fg errmessagered"
                *ngIf="hasError('otp', 'required') || failedVerifiedOpt"
              >
                OTP is not correct
              </div>
            </div>
          </div>
          <div class="new-otp-col-right" *ngIf="enableTimer">
            <div class="one3d-copy-static">Didn’t get an OTP?</div>
            <button
              class="submitbuttonobject one3d-resend-otp-new-btn"
              type="button"
              (click)="sendOTP('Resend OTP')"
              [disabled]="showResendOtpCounter"
            >
              {{
                showResendOtpCounter
                  ? "Resend in " + countdown + "s"
                  : "Resend OTP"
              }}
            </button>
          </div>
        </div>

        <div
          class="new-terms-conditions-checkbox-wrapper"
          *ngIf="!hideAllFormInput"
        >
          <label class="terms-checkbox-container"
            >I agree to the
            <a
              href="javascript:void(0)"
              (click)="openTancCpopup()"
              class="terms-read-faqs"
              >Terms & Conditions</a
            > and 
            <a target="_blank"
            href="https://www.mgmotor.co.in/privacy"
            class="terms-read-faqs"> Privacy Policy</a>
            <input
              type="checkbox"
              checked="checked"
              [(ngModel)]="isChecked"
              [ngModelOptions]="{ standalone: true }"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="new-terms-conditions-checkbox-wrapper">
          <label class="terms-checkbox-container"
            >Receive updates from MG Motor and its partners.
            <input
              type="checkbox"
              checked="checked"
              [(ngModel)]="isMarketingUpdates"
              [ngModelOptions]="{ standalone: true }"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <ng-container *ngIf="checkPinPresentOrNotInQueryParam()">
          <p class="agent-not-available" *ngIf="waitIngForAgent">
            Please wait for the Relationship Manager to join the session.
          </p>
          <p class="agent-not-available" *ngIf="!waitIngForAgent">
            Relationship Manager has joined.
          </p>
        </ng-container>

        <div class="fg secObjectlbtmformbtn">
          <ng-container *ngIf="!waitIngForAgent && APPOINTMENT_USER != true">
            <div class="secObjectlbtmformbtnright w-100">
              <button
                class="submitbuttonobject one3d-send-otp-new-btn"
                type="button"
                (click)="continueWOOTP()"
                [disabled]="disableSendOTPButton || !isChecked"
                *ngIf="showSendOTPButton"
              >
                Confirm
              </button>
            </div>
            <div class="secObjectlbtmformbtnright w-100">
              <button
                class="submitbuttonobject one3d-send-otp-new-btn"
                type="button"
                (click)="onSubmit()"
                [disabled]="queryForm.invalid || !isChecked"
                *ngIf="showVerifyButton"
              >
                Verify OTP
              </button>
            </div>
          </ng-container>
          <div
            class="secObjectlbtmformbtnright"
            [ngClass]="{ 'w-100': hideSkipIcon }"
            *ngIf="APPOINTMENT_USER == true"
          >
            <button
              class="submitbuttonobject"
              type="button"
              [disabled]="queryForm.invalid || waitIngForAgent"
              (click)="createLead()"
            >
              Join Now
            </button>
          </div>
        </div>
        <div
          class="fg secObjectlbtmformbtn one3d-continue-browsing-new-btn-wrap"
          *ngIf="!APPOINTMENT_USER"
        >
          <div class="secObjectlbtmformbtnright w-100">
            <button
              class="submitbuttonobject one3d-continue-browsing-new-btn"
              type="button"
              (click)="closeModal()"
            >
              Continue Browsing
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!--overlay-->
<!-- <div class="one3d-expert-modal-overlay"></div> -->
<!--overlay-->
<!--back up code-->
