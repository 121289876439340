<!--bottom-->
<div class="fg secObjectlbtm">
  <div class="fg secObjectlbtminner new-main-schedule-live-consultation">
    <div class="one3d-slc-popup-header">
      <div class="one3d-slc-popup-heading">
        Reschedule your MG eXpert call
      </div>
      <div class="secObjectlbtmClose" (click)="closeModal()"><a><img src="./assets/images/Close.svg"></a></div>
    </div>
    <!-- <span
          class="like-to-join-expert-oops-close"
          (click)="closeAppointmentForm()"
          *ngIf="!isScheduleButtonClick"
        >
          <img src="./assets/images/cross-white.svg"
        /></span> -->

    <form [formGroup]="queryForm">
      <div class="secObjectlbtmform">
        <div class="user-details-wrapper" *ngIf="appointmentData">
          <!-- <div class="details-title">
            Dear {{appointmentData?.cust_name}},
          </div> -->
          <div class="details-value">
            Currently, you have a call set up with the MG eXpert on <strong>
              {{appointmentDate | date:'dd/MM/yyyy'}}</strong> at <strong>{{appointmentDate | date:'shortTime'}}</strong>
          </div>
        </div>

        <div class="new-date-time-schedule-container">
          <div class="new-date-time-schedule-wrapper">
            <div class="new-date-time-schedule-left">
              <div class="floating-label coobjectinputlable">
                <label>Select Date *</label>
                <input matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" formControlName="selectedDate"
                  class="coobjectinput" placeholder="DD/MM/YY" />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <span class="new-date-time-icon">
                  <img src="./assets/images/new-date-icon-red.svg" />
                </span>
                <div class="fg errmessagered" *ngIf="hasError('selectedDate', 'required')">
                  Please select date
                </div>
              </div>
            </div>
            <div class="new-date-time-schedule-right">
              <div class="floating-label coobjectinputlable one3d-form-option-wrapper">
                <select id="schedule-time" formControlName="selectedTime" placeholder="HH:MM"
                  class="one3d-form-control-select coobjectinput">
                  <option value="" selected="" disabled="true" value=""></option>
                  <option [value]="item.value" *ngFor="let item of timeSlots">{{item.text}}</option>
                </select>
                <label>Select Time *</label>
                <span class="new-date-time-icon">
                  <img src="./assets/images/new-time-icon-red.svg" />
                </span>
                <!-- <span class="new-arrow-down-icon">
                      <img src="./assets/images/new-arrow-down.svg" />
                    </span> -->
                <span class="new-time-placeholder" [hidden]="queryForm.value.selectedTime">
                  HH:MM
                </span>
                <div class="fg errmessagered" *ngIf="hasError('selectedTime', 'required')">
                  Please select time
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fg secObjectlbtmformbtn">
          <div class="secObjectlbtmformbtnright">
            <button class="submitbuttonobject2 w-100 one3d-confirm-new-btn" type="button" (click)="onSubmit()">
              Confirm
            </button>
          </div>
        </div>
        <div class="fg secObjectlbtmformbtn one3d-connect-now-new-btn-wrap" *ngIf="showConnectButton">
          <div class="secObjectlbtmformbtnright ">
            <button class="submitbuttonobject w-100 one3d-connect-now-new-btn" type="button" (click)="connectNow()">
              Connect Now
            </button>
          </div>
        </div>
      </div>
    </form>
    <!--secObjectlbtmform-->
  </div>
</div>
<!--bottom-->
<div class="secObject-overlay"></div>