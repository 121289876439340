import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMaterialComponent } from '../shared/snackbar-material/snackbar-material.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}
  public openSnackBar(message: string, snackType?: string) {
    const _snackType: string = snackType ? snackType : 'Success';

    this.snackBar.openFromComponent(SnackbarMaterialComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: { message: message, snackType: _snackType },
    });
  }
}
