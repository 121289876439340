import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { CallService } from '../../service/call.service';
import { SocketService } from '../../service/socket.service';
import { environment } from '../../../environments/environment';
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser';

@Component({
  selector: 'app-ar-video-overlay',
  templateUrl: './ar-video-overlay.component.html',
  styleUrls: ['./ar-video-overlay.component.scss'],
})
export class ArVideoOverlayComponent implements OnInit {
  iframeSRC: any;

  constructor(
    public _call: CallService,
    public _socket: SocketService,
    public _global: GlobalService,
    protected _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    let tokenE = encodeURIComponent(this._call.TOKEN);
    console.log('Encode', tokenE);

    let enci = `${environment.agora_APP_ID}&token=${tokenE}&channel=${this._call.CHANNEL_NAME}&uid=0`;
    let url = `https://butleruistaging.one3d.in/mg-ar-stream/index.html?appid=${enci}`;
    this.iframeSRC = this._sanitizer.bypassSecurityTrustResourceUrl(url);
    // this.iframeSRC = `https://www.google.com/`;
  }
  cleanURL(url: string) {
    return;
  }
  closeModal() {
    this._global.toggleArModal(false);
  }
}
