import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/service/global.service';

@Component({
  selector: 'app-microphone-blocked',
  templateUrl: './microphone-blocked.component.html',
  styleUrls: ['./microphone-blocked.component.scss']
})
export class MicrophoneBlockedComponent implements OnInit {
  hideMicrophoneGif:boolean=true;
  @Output() closeDisabledMicPopUp : EventEmitter<boolean> = new EventEmitter();
  gifUrl: string;
  constructor(
  ) { }

  ngOnInit(): void {
    this.detectDevice()
  }
  showMicrophoneGif(){
    console.log('cick', );
    this.hideMicrophoneGif=false;
  }
  exit(){
    this.closeDisabledMicPopUp.emit(false);
  }
  detectDevice(){
    if (navigator.userAgent.match(/Mac|iPad/) && window.screen.width>768) {
      console.log('mac OS',);
      this.gifUrl = "assets/gif/desktop-safari.gif"
    } else if (/Android|BlackBerry/i.test(navigator.userAgent)) {
      // MAC ANDROID
      console.log('android',);
      this.gifUrl = "assets/gif/mobile-chrome.gif"
    } else if (/iPhone/i.test(navigator.userAgent)) {
      console.log('iphone',);
      // iPHONES
      this.gifUrl = "assets/gif/mobile-safari.gif"
    } else {
      console.log('chrome',);
      // other browsers default chrome showing as didn't receive common gif 
      this.gifUrl = "assets/gif/desktop-chrome.gif"
    }
  }
}
