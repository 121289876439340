<!--wrapper-start-->
<div class="wrapper">
  <!--<div
    id="loader_BG"
    style="
      background-color: #000000;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0px;
      overflow-x: hidden;
      overflow-y: hidden;
      left: 0px;
      z-index: 999;
      display: none;
    "
  > 
    <img
      src="./assets/images/main_loading.gif"
      style="
        display: block;
        position: absolute;
        transform: translate(50%, -50%);
        top: 50%;
        right: 50%;
      "
    />
    <div
      style="
        width: 42%;
        height: 5px;
        z-index: 9999;
        top: 79%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        overflow: hidden;
      "
    >
      <div
        id="myBar"
        style="
          width: 0%;
          background: rgb(252, 248, 247);
          overflow: hidden;
          height: 2px;
        "
      ></div>
    </div>
  </div>-->
  <!--new-main-loader-wrapper starts here-->
  <ng-container *ngIf="!newLoader">
    <div class="new-main-loader-wrapper" id="loader_BG">
      <div class="new-main-loader-box">
        <!-- <img src="./assets/images/one3d-new-loader-small-logo.svg" class="one3d-new-loader-small-logo" /> -->
        <img
          src="./assets/images/one3d-new-loader-desktop.png"
          class="one3d-new-loader-desktop"
        />
        <img
          src="./assets/images/one3d-new-loader-mobile.png"
          class="one3d-new-loader-mobile"
        />
        <!-- <div id="lottie"></div> -->
        <div class="new-main-loader-progress-bar-new">
          <div class="new-main-loader-left-right-side-text">
            <div class="new-main-loader-left-side-text">
              <p id="loader-persent-text-left">Assembling your MG Car</p>
            </div>
            <div class="new-main-loader-right-side-text">
              <p id="loader-persent-text">100</p>
            </div>
          </div>
          <div class="new-main-loader-progress-bar">
            <div id="myBar"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="newLoader">
    <app-new-loader></app-new-loader>
  </ng-container>
  <!--new-main-loader-wrapper ends here-->
  <router-outlet></router-outlet>
  <!-- <div class="pulshubObject2" [hidden]="ACCESS">
    <div class="pulshubObject2in">
      <div class="pulshubObject2title1">Selected Variant: <span></span></div>
      <div class="pulshubObject2title2">
        {{ selectedVariant?.title }}:
        <span>₹ {{ selectedVariant?.price }}</span>
      </div>
    </div>
  </div> -->
  <div class="afpulshubObject2" [hidden]="ACCESS">
    <div class="afpulshubObject2in">
      <div class="afpulshubObject2title1">Selected Variant: <span></span></div>
      <div class="afpulshubObject2title2">
        {{ selectedVariant?.title }}:
        <span>₹ {{ globalPrice }}</span>
      </div>
    </div>
  </div>
  <div
    class="pulshubObject4"
    [hidden]="ACCESS || DISPLAY_TO_CUSTOMER == true"
    *ngIf="createNewPinWhenModelChange"
  >
    <div class="pulshubObject4in">
      <div class="pulshubObject4left">
        <a
          href="javascript:void(0)"
          class="rcicon"
          [ngClass]="{ disabled: isInFeaturePAge }"
          (click)="requestForAccess()"
          ><span><img src="./assets/images/carbon-touch-icon.svg" /></span>
          <span>Request Control</span>
        </a>
      </div>
      <!-- <div class="pulshubObject4right"> commented as per requirement not needed 
        <a href="#" class="maximizeicon"
          ><span><img src="./assets/images/maximize-icon.svg" /></span>
        </a>
      </div> -->
    </div>
  </div>
</div>
<div class="unsupportbrowserOpen" id="noInternet" style="display: none">
  <div class="unsupportbrowserbg unsupportbrowserbg2">
    <div class="unsupportbrowserin">
      <div class="fg unsupportbrowsertitle4img">
        <img src="./assets/images/wifi-off.svg" alt="img" />
      </div>
      <div class="fg unsupportbrowsertitle4">NO INTERNET CONNECTION</div>
      <div class="fg unsupportbrowsertitle5">
        <!-- This device is not connected to the internet. Please check your
        connection and try again  -->
        Please wait, we are trying to reconnect you.
        <mat-spinner style="margin-left: 12px" [diameter]="20"></mat-spinner>
      </div>
    </div>
  </div>
</div>

<div class="unsupportbrowserOpen" id="rotateScreen">
  <div class="fg myrotateScreen">
    <div class="rotateScreen">
      <div class="rotateScreeninfo">
        <div class="fg rotateScreeninfotitle">
          THIS EXPERIENCE WILL TAKE <br />
          PLACE IN {{ isLandScape ? "PORTRAIT" : "LANDSCAPE" }} MODE
        </div>
        <div class="fg rotateScreeninfoicon">
          <img
            src="./assets/images/rotate-screen-icon.png"
            [ngClass]="{ rotate270: isLandScape }"
          />
        </div>
        <div class="fg rotateScreeninfodesk">
          Please ensure to keep your phone in<br />
          {{ !isLandScape ? "portrait" : "landscape" }} scale !
        </div>
      </div>
    </div>
  </div>
</div>
<app-access-modal
  *ngIf="controlAlert"
  [nameRequester]="requesterName"
  [afterFirstTime]="firstTimeAccess"
  (dismiss)="onRequestModalDismiss($event)"
></app-access-modal>
<!-- <div class="access-request-popup" *ngIf="controlAlert">
  <div class="fg imagevideopopup2in">
    <h4>{{ requesterName }} is requesting your 3D model access</h4>
    <div class="imagevideopopup2desk">
      <button
        class="access-alert-btn access-alert-allow"
        (click)="accessResp(true)"
      >
        Allow
      </button>
      <button
        class="access-alert-btn access-alert-deny"
        (click)="accessResp(false)"
      >
        Deny
      </button>
    </div>
  </div>
  <div class="imagevideopopupOverlay"></div>
</div> -->

<!--terms-conditions-popup starts-->
<section class="terms-conditions-popup" *ngIf="toggleTCPopup">
  <div class="tcp-wrap">
    <div class="tcp-container">
      <div class="tcp-box">
        <div class="tcp-box-top">
          <div class="tcp-heading">
            <span class="tcp-logo">
              <img src="./assets/images/mg-logo.svg" alt="MG Logo" />
            </span>
            <h1>TERMS & CONDITIONS</h1>
            <span class="tcp-close" (click)="closeModal()">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div class="tcp-small-heading">
            <h4>Welcome to MG eXpert !</h4>
            <p><strong>Last Revised:</strong> September 25, 2020</p>
          </div>
        </div>
        <div class="tcp-content">
          <ul class="tcp-content-list">
            <li>
              MG will record [store, process and analyse] the content from MG
              Expert session for quality improvement and training purposes. Any
              personal information or sensitive personal data that is recorded,
              stored, processed and/or handled by MG Motor through the MG Expert
              session will be subject to the Privacy Policy of MG Motor
              <a class="tcp-anchor" href="https://www.mgmotor.co.in/privacy"
                >(https://www.mgmotor.co.in/privacy)</a
              >, which is hereby incorporated by reference.
            </li>
            <li>
              The purchase details provided by our Expert during the session
              will be with an approximate value and the disclosure of such
              details will not constitute as an offer to sell from MG Motor.
            </li>
            <li>
              In order to purchase the car, you will be required to approach
              your nearest Dealer, who will help you in this regard.
            </li>
            <li>
              The session is only for sharing of information and shall not
              amount to any binding commitments on part of MG Motor and/ or
              Dealer.
            </li>
            <li>
              MG Motor does not make any warranties and/or representations of
              any kind, expressed or implied, as to the nature, standard,
              accuracy or otherwise of the information provided through the MG
              Expert session nor to the suitability of the information to your
              particular requirements.
            </li>
            <li>
              Our Expert will be able to access only the MG configurator website
              and no other content on your Screen. Such information includes 3D
              visuals, prices and your preferences. No other content on your
              device will be accessible to the Expert.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--terms-conditions-popup ends-->
<div
  class="new-video-stream-overlay"
  *ngIf="!firstTimeAccess && callConnected"
></div>

<!-- <app-session-warning></app-session-warning> -->

<div class="video-grid" id="video" style="display: none">
  <div class="col">
    <div id="local-player" class="player"></div>
  </div>
  <div class="w-100"></div>
  <div class="col">
    <div id="remote-playerlist" class="video-view new-video"></div>
    <div class="new-video-bottom">
      <div class="new-video-bottom-box" *ngIf="callConnected">
        <div class="new-video-bottom-box-left">
          <div class="video-connected-agent-name" id="userName">
            <img src="assets/images/logo-for-video-streaming.svg" />
          </div>
        </div>
        <span class="new-video-bottom-box-right">
          <div class="new-video-bottom-box-right-stars">
            <img src="assets/images/video-red-star.svg" />
            <img src="assets/images/video-red-star.svg" />
            <img src="assets/images/video-red-star.svg" />
            <img src="assets/images/video-red-star.svg" />
            <img src="assets/images/video-half-star.svg" />
          </div>
          <div class="new-video-bottom-box-right-stars-text">
            4.7 out of 5 stars
          </div>
        </span>
      </div>
    </div>
  </div>
</div>
<!--  -->
<div class="secObject-overlay" *ngIf="circleLoader">
  <mat-spinner mode="indeterminate" color="#fff" [diameter]="100"></mat-spinner>
</div>


<!-- <div class="secObject-overlay" id="block-ui" style="display: none;">
</div> -->

<!--expert assistance-->
<div id="block-ui" style="display: none;" class="one3d-assistance-popup-wrapper">
  <div class="one3d-assistance-popup-inner">
    <div class="one3d-assistance-popup-bg">
      <!-- <div class="one3d-assistance-popup-close"><button><img src="assets/images/close-tech-support-icon.svg" alt="img"></button></div> -->
      <div class="one3d-assistance-popup-row-img"><img src="assets/images/tech-support-icon.svg" alt="icon"></div>
      <div class="one3d-assistance-popup-row1">Need an expert assistance?</div>
      <div class="one3d-assistance-popup-row2"><p>Looks like you are enjoying our 3d experience! Do you need any live expert assistance?</p></div>
      <div (click)="connectCall()" class="one3d-assistance-popup-row3"><button class="one3d-connect-now-button">Connect Now</button></div>
    </div>
  </div>
  <div class="one3d-assistance-popup-overlay"></div>
</div>
<!--expert assistance-->

<!--overlay-->
<div id="block-ui-layer" style="display: none;" class="one3d-expert-modal-overlay"></div>
<!--overlay-->
