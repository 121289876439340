<div
  class="maincontainerrightin new-emi-calculator-main-container"
  [ngClass]="{ 'pointer-block': !ACCESS }"
>
  <!--title-->
  <div class="fg summarylisttitle">
    <span>EMI CALCULATOR</span>
    <!-- <div class="summarylisttitleClose">
            <div class="summarylisttitleClosebtn"><a><img src="assets/images/summary-close-icon.svg"></a></div>
        </div> -->
  </div>
  <!--title-->
  <!--VARIANTS-->
  <div class="fg summarylisttitlespace">
    <!--top-->
    <div class="fg emiOuter">
      <div class="fg detailforminfoRow">
        <div class="floating-label coobjectinputlable2">
          <input
            class="coobjectinput2"
            [(ngModel)]="downPayment"
            (blur)="calculateEMI()"
            name="downPayment"
            type="number"
            [max]="exShowRoomPriceNumber"
            min="0"
            placeholder="Enter Amount"
          />
          <label>Down Payment</label>
        </div>
        <div class="fg errmessagered" *ngIf="showErrorMsg">
          Down Payment should't be greater or equal to Exshowroom price.
        </div>
      </div>
      <!-- <div class="fg emititle">Down Payment<span>*</span></div>
      <div class="fg emititleslide">
        <span class="emititleslide-min"> {{ formatCurrency(10000) }} </span>
        <mat-slider
          thumbLabel
          min="10000"
          [max]="exShowRoomPriceNumber"
          (change)="calculateEMI()"
          [(ngModel)]="downPayment"
          step="1"
          aria-label="unit(s)"
        ></mat-slider>
        <span class="emititleslide-max">
          {{ formatCurrency(exShowRoomPriceNumber) }}
        </span>
      </div> -->
      <div class="fg emititle emititleinputOuter">
        Loan Tenure(in months)<span>*</span>
        <div class="emititleinputInput">
          <input
            (blur)="onMonthKeyUp($event, 'months')"
            class="coobjectinput2"
            [(ngModel)]="months"
            type="number"
            min="12"
            max="84"
            (change)="calculateEMI()"
            placeholder=""
          />
        </div>
      </div>

      <!--add range slider code-->
      <!--tem. images add-->
      <div class="fg emititleslide">
        <span class="emititleslide-min"> 12 </span>
        <mat-slider
          thumbLabel
          tickInterval="1000"
          min="12"
          max="84"
          (change)="calculateEMI()"
          [(ngModel)]="months"
          step="1"
          aria-label="unit(s)"
        ></mat-slider>
        <span class="emititleslide-max"> 84 </span>
      </div>
      <div class="fg emititle emititleinputOuter">
        Rate of Interest(%)<span>*</span>
        <div class="emititleinputInput">
          <input
            (blur)="onMonthKeyUp($event, 'rateOfInterest')"
            class="coobjectinput2"
            [(ngModel)]="rateOfInterest"
            type="text"
            min="7"
            max="12"
            (change)="calculateEMI()"
            placeholder=""
          />
        </div>
      </div>
      <div class="fg emititleslide">
        <span class="emititleslide-min"> 7 </span>
        <mat-slider
          thumbLabel
          tickInterval="1000"
          min="7"
          max="12"
          (change)="calculateEMI()"
          [(ngModel)]="rateOfInterest"
          step="0.01"
          aria-label="unit(s)"
        ></mat-slider>
        <span class="emititleslide-max"> 12 </span>
      </div>

      <!-- <div class="fg emititleslide"><img src="assets/images/range-slide2.svg" /></div> -->
    </div>
    <!--top-->

    <!--bottom-->
    <div class="fg emiOuter emiOuter2">
      <!--row-->
      <div class="emiOuterRowprice">
        <div class="emiOuterRowpricel">Ex-Showroom Price</div>
        <div class="emiOuterRowpricer">
          {{ formatCurrency(exShowRoomPriceNumber) }}
        </div>
      </div>
      <!--row-->
      <!--row-->
      <div class="emiOuterRowprice">
        <div class="emiOuterRowpricel">Down Payment</div>
        <div class="emiOuterRowpricer">
          {{ formatCurrency(downPayment) || 0 }}
        </div>
      </div>
      <!--row-->
      <!--row-->
      <div class="emiOuterRowprice">
        <div class="emiOuterRowpricel">Finance Needed</div>
        <div class="emiOuterRowpricer">
          {{ formatCurrency(financeNeeded) || 0 }}
        </div>
      </div>
      <!--row-->
      <!--row-->
      <div class="emiOuterRowprice">
        <div class="emiOuterRowpricel">Your Monthly Payment</div>
        <div class="emiOuterRowpricer">{{ formatCurrency(EMI) || 0 }}</div>
      </div>
      <!--row-->
    </div>
    <!--bottom-->
  </div>
  <!--VARIANTS-->
</div>
<!--button-->
<div class="variantlisttitlebtn" *ngIf="!callConnected">
  <div class="variantlisttitlebtnleft">
    <a
      href="javascript:void(0)"
      (click)="cancelEmi()"
      class="variantlisttitlebtnleftbtn"
      >Cancel</a
    >
  </div>
  <div class="variantlisttitlebtnright">
    <a
      href="javascript:void(0)"
      (click)="saveEmi()"
      class="variantlisttitlebtnrightbtn"
    >
      Save
    </a>
  </div>
</div>
<!--button-->
