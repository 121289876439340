// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  agora_APP_ID: 'ccd61a2f415f44b88a36686dbc2663bd',
  agent_image_location:"https://dxf3kulhcrbjg.cloudfront.net/mg_expert/agent_image/",
  accessoriesImageCdnUrl: "https://d2l8ucf1wjoc2o.cloudfront.net/accessories/",


    //CAR WISE GA-4 tags FOR STAGING ALL ARE SAME
    ASTOR_GA4 : 'G-54EN6E794D',
    HECTOR_NEW_GA4 : 'G-54EN6E794D',
    ZSEV_GA4 : 'G-54EN6E794D',
    HECTOR_PLUS_NEW_GA4 : 'G-54EN6E794D',
    GLOSTER_GA4 : 'G-54EN6E794D',
    COMET_GA4 : 'G-54EN6E794D'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
