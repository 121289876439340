<!--maincontainerrightin-->
<div class="maincontainerrightin new-features-main-container">
    <!--title-->
    <div class="fg variantlisttitle variantlisttitlehome">
      <button mat-icon-button aria-label="Back to Home" (click)="goBack()">
        <mat-icon class="icon-back-mat">chevron_left</mat-icon>
      </button>
      <span>Features</span>
    </div>
    <!--title-->
    <!--VARIANTS-->
    <div class="fg accessrizelistspace selfexplorespace">
      <div class="selfExploreOuter">
        <div class="fg selfExploreOuterpo">
          <div class="fg selfExploretitle">
            {{ selectedFeature?.title }}
  
            <span *ngIf="selectedFeature?.class == 'new'" class="new-badge"
              >NEW</span
            >
          </div>
          <div class="fg selfExploretitle2">
            {{ selectedFeature?.description }}
          </div>
          <!-- <button (click)="gotoNextFeature()">Next</button> -->
          <div class="selfExploreQest">
            <div class="selfExploreQestleft">
              <div class="selfExploreQestright">
                <a href="javascript:void(0)" class="next">{{
                  selectedFeatureIndex + 1
                }}</a
                ><span>/</span
                ><a href="javascript:void(0)" class="pre">{{
                  featuresList.length
                }}</a>
              </div>
              <div class="selfExploreQestborder">
                <div
                  class="selfExploreQestborderSlide"
                  [ngStyle]="{
                    width: progressBarLength() + '%'
                  }"
                  style="background: #cf2230"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="selfExplorebtn">
          <div class="selfExplorebtnl">
            <button
              [disabled]="featurePreviousDisable || disableAllButtons"
              class="selfExplorebtnlin"
              (click)="gotoPreviousFeature()"
            >
              <img src="assets/images/fea-previous-arow.svg" />
            </button>
          </div>
          <div class="selfExplorebtnl">
            <button
              [disabled]="featureNextDisable || disableAllButtons"
              class="selfExplorebtnlin"
              (click)="gotoNextFeature()"
            >
              <img src="assets/images/fea-next-arow.svg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  