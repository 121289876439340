<!--maincontainerrightin-->
<!-- <div
  class="maincontainerrightin new-home-main-container"
  [ngClass]="{ 'new-variant-has-trims': isInterior && colorList.length > 0 }"
> -->
<div class="maincontainerrightin new-home-main-container">
  <!--title-->

  <!-- <div class="fg variantlisttitle variantlisttitlehome">
    <span><img src="./assets/images/homeicon.svg" alt="img" /></span>
    <span>HOME</span>
  </div> -->
  <!--title-->
  <!--VARIANTS-->

  <!-- New code -->

  <!--<div *ngIf="_data.exploreCar" class="fg variantlisttitle new-variantlisttitle-box murgeNavleftbtn" style="position: static; ">
      <button
        mat-icon-button
        aria-label="Back to Home"
        (click)="toggleExplore()">
        <mat-icon class="icon-back-mat">chevron_left</mat-icon>
      </button>
      <span>Explore Your {{returnCarName()}}</span>
    </div>-->

  <div class="fg accessrizelistspace accessrizelist" style="margin-top: 0px">
    <div class="fg questionList">
      <div
        *ngIf="_data.exploreCar"
        class="fg variantlisttitle new-variantlisttitle-box murgeNavleftbtn"
        style="position: static; width: auto"
      >
        <button
          mat-icon-button
          aria-label="Back to Home"
          (click)="toggleExplore()"
        >
          <mat-icon class="icon-back-mat">chevron_left</mat-icon>
        </button>
        <span>Explore Your {{ returnCarName() }} </span>
      </div>

      <!--fe-list-->
      <ul>
        <li *ngIf="!_data.exploreCar" class="question-list-li">
          <!-- class="lhs-talk-to-our-expert" -->
          <a href="javascript:void(0)" (click)="connectToCall()">
            <span class="new-ticker-expert">
              <img src="./assets/images/new-ticker-expert.svg" alt="img" />
            </span>
            <div class="questionListOuter">
              <div class="questionListOuterleft">
                <!-- <img src="./assets/images/customer-support.svg" alt="img" /> -->
                <div
                  class="secObjectltopleftthumb2in secObjectltopleftthumb2incalling"
                >
                  <img src="./assets/images/calling-icon.svg" />
                </div>
              </div>
              <div class="questionListOuterright">
                <strong> 24*7 Live Consultation</strong>
                <span
                  >Sit back and relax while our Expert consultant walks you
                  through the product, just like at any MG Showroom!
                </span>
              </div>
            </div>
            <!-- <div class="questionListOuterright">
                <strong> Live Consultation from Product Expert</strong>
                <span
                  >Sit back and relax while our Expert consultant walks you
                  through the product, just like at any MG Showroom!
                </span>
              </div> -->
            <!-- </div> -->
            <!-- <div class="questionListOuterright">
                Live Consultation from Product Expert
                <span
                  >Sit back and relax while our Expert consultant walks you
                  through the product, just like at any MG Showroom!
                </span>
              </div> -->
          </a>
        </li>
        <div *ngIf="_data.exploreCar" id="exploreCar">
          <li>
            <a href="javascript:void(0)" (click)="playAudio('best_feature')"
              ><div class="questionListOuter">
                <div class="questionListOuterleft">
                  <img
                    src="./assets/images/audio-assisted-Journey-icon.svg"
                    alt="img"
                  />
                </div>
                <div class="questionListOuterright">
                  Audio Assisted Journey
                  <span
                    >Sit Back & Relax as we walk you through the
                    {{ returnCarName() }}.
                  </span>
                </div>
              </div></a
            >
          </li>
          <li>
            <a href="javascript:void(0)" (click)="playAudio('self')"
              ><div class="questionListOuter">
                <div class="questionListOuterleft">
                  <img
                    src="./assets/images/selx-explore-features-icon.svg"
                    alt="img"
                  />
                </div>
                <div class="questionListOuterright">
                  Self Explore Your MG
                  <span
                    >Immerse yourself in the 3D walkthrough of the
                    {{ returnCarName() }}
                  </span>
                </div>
              </div></a
            >
          </li>
        </div>
        <ng-container *ngIf="!_data.exploreCar">
          <ng-container *ngFor="let item of limitedEditionData">
            <li class="gamer-edition-title">
              <div class="gamer-edition-left">{{ item.pack_name }}</div>
              <div class="gamer-edition-right">
                <label class="switch">
                  <input
                    (ngModelChange)="toggleGamerEdition($event)"
                    [ngModel]="limitedEdition"
                    type="checkbox"
                  />
                  <span class="sliding round"></span>
                </label>
              </div>
            </li>
            <li class="gamer-edition-bg">
              <a (click)="gotoLimitedEdition()">
                <div class="questionListOuter">
                  <div class="questionListOuterleft">
                    <img
                      src="./assets/images/limited-edition-tag.svg"
                      alt="img"
                    />
                  </div>
                  <div class="questionListOuterright">
                    {{ item.pack_name }}
                    <span class="game-edition-highlighted"
                      >{{ selectedVariant.title }}
                    </span>
                  </div>
                </div></a
              >
            </li>
          </ng-container>
        </ng-container>

        <!-- New Code -->
        <li *ngIf="!_data.exploreCar">
          <a href="javascript:void(0)"
            ><div class="questionListOuter" (click)="toggleExplore()">
              <div class="questionListOuterleft">
                <img src="./assets/images/explore-car-icon.svg" alt="img" />
              </div>
              <div class="questionListOuterright">
                Explore Your {{ returnCarName() }}
                <!-- <span class="one3d-color-highlighted"
                  >{{ selectedVariant.title }}
                </span> -->
              </div>
            </div></a
          >
        </li>

        <li *ngIf="!_data.exploreCar">
          <a href="javascript:void(0)" (click)="playAudio('variant')"
            ><div class="questionListOuter">
              <div class="questionListOuterleft">
                <img src="./assets/images/home-car-icon.svg" alt="img" />
              </div>
              <div class="questionListOuterright">
                Variants
                <span class="one3d-color-highlighted"
                  >{{ selectedVariant.title }}
                </span>
              </div>
            </div></a
          >
        </li>

        <li *ngIf="!_data.exploreCar">
          <a href="javascript:void(0)" (click)="playAudio('accessorize')"
            ><div class="questionListOuter">
              <div class="questionListOuterleft">
                <img src="./assets/images/home-acc-icon.svg" alt="img" />
              </div>
              <div class="questionListOuterright">
                Accessories
                <span
                  >Checkout all the accessories for {{ returnCarName() }}
                </span>
              </div>
            </div></a
          >
        </li>

        <li *ngIf="!_data.exploreCar">
          <a href="javascript:void(0)" (click)="playAudio('summary')"
            ><div class="questionListOuter">
              <div class="questionListOuterleft">
                <img src="./assets/images/home-checkout-icon.svg" alt="img" />
              </div>
              <div class="questionListOuterright">
                {{ summaryBtnText }}
                <span
                  >Your first step towards owning the
                  {{ returnCarName() }}</span
                >
              </div>
            </div></a
          >
        </li>
        <!-- <li>
          <a href="javascript:void(0)" (click)="openArModal()">OpenAr</a>
        </li> -->
      </ul>
      <!--fe-list-->
    </div>
  </div>
  <!--VARIANTS-->

  <!--disclaimer-->
  <div class="one3d-disclaimer-mob">
    <div class="one3d-disclaimer-mob-inner">
      <!-- *ngIf="!_global.detectMob()"-->
      <div class="one3d-disclaimer-mob-row-1">
        <div class="one3d-disclaimer-mob-row-1-animation">
          <p>
            <b>Disclaimer:</b> Specifications & colors shown here may vary from
            the actual product.
          </p>
        </div>
      </div>
      <div class="one3d-disclaimer-mob-row-2">
        <div class="one3d-branding-mob-icon">
          <b
            ><img
              src="assets/images/one3d-branding-icon-mob.svg"
              alt="eccentric"
          /></b>
        </div>
      </div>
    </div>
  </div>
  <!--disclaimer-->
</div>

<!--maincontainerrightin-->
<div class="variantlisttitlebtn" *ngIf="false">
  <a
    target="_blank"
    href="https://www.mgmotor.co.in/vehicles/mgastor?utm_source=Online+Voice&utm_medium=Web&utm_campaign=astor&utm_id=EE"
    class="variantlisttitlebtnrightbtn"
  >
    KEEP ME INFORMED
  </a>
</div>

<!--button-->
<div class="variantlisttitlebtn" (click)="playAudio('summary')">
  <div class="variantlisttitlebtnleft">
    <a href="javascript:void(0)" class="variantlisttitlebtnleftbtn"
      >Book Test Drive</a
    >
  </div>
  <div class="variantlisttitlebtnright">
    <a href="javascript:void(0)" class="variantlisttitlebtnrightbtn">
      {{ bookNowText }}
    </a>
  </div>
</div>
