import { Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import {
  GlobalService,
  glosterSeating,
  hectorPlusSeating,
} from '../../service/global.service';
import { CallService } from '../../service/call.service';
import { SocketService } from '../../service/socket.service';
import {
  defaultCarDetails,
  getArAssetsURL,
  getAssetsURL,
} from '../carSettings';
import { GaService } from '../../service/ga.service';
import { EventService } from 'src/app/service/event.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT, Location } from '@angular/common';
import { SessionWarningComponent } from '../session-warning/session-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

declare var ONE3D: any;
declare var ONE3D_lib: any;
declare var one3dFunctions: any;
declare var TweenMax: any;
declare var window: any;

@Component({
  selector: 'app-hector-plus',
  templateUrl: './hector-plus.component.html',
  styleUrls: ['./hector-plus.component.scss'],
})
export class HectorPlusComponent implements OnInit {
  queryParams: any = {};
  usersData: any;
  colorList: any[];
  selectedColor: any;
  selectedVariant: any;
  isOpenRightPanel: boolean = true;
  isCarDoorOpen: any;
  isInterior: boolean;
  pinNo: string;
  loadedCar: string;
  variantList: any;
  ACCESS: boolean;
  groupUsers: any[];
  isAccCamSet: boolean;
  agentDetails: any;
  CALL_CONNECTED: boolean;
  isMuted: any;
  HIDE_3D: boolean;
  overlaySrc: string;
  soundTrackURL: string;
  scrollPos: {
    isBottom: boolean;
    isTop: boolean;
  };
  videoOptions: {
    autoplay: boolean;
    controls: boolean;
    fluid: boolean;
    loop: boolean;
    sources: { src: string; type: string }[];
  };
  openFeatureCompletePopup: boolean;
  doorInProgress: boolean;
  hideAllIcons: boolean;
  isHiddenRightPanel: boolean=true;
  openLocationModal: boolean;
  comingFromInt: any;
  isOpenPulseHubEnqForm: boolean;
  isOpenPulseHubForm:boolean;
  headLampOn: boolean;
  sunRoofOn: boolean;
  sunRoofInProgress: boolean;
  featureImageList: any;
  isCarCompareModalOpen: boolean;
  isSubmitModalOpen: boolean;
  DISPLAY_TO_CUSTOMER: boolean = false;
  assetsUrl: string = getAssetsURL();
  $dataTransfer: Subscription;
  $roomUsers: Subscription;
  $colorChangeData: Subscription;
  isFeedBackModelOpen: boolean = false;
  audioCallStatus: boolean;
  arAssetsUrl: string = getArAssetsURL();
  urlForAr: string;
  SOCKET_STATUS: string;
  openArModal: boolean;
  $callConnectedSubs: Subscription;
  enableGrantAccessModal: boolean;
  intExtState: string = 'ext';
  toggleEndCallModal: boolean;
  hectorSeatingOptions: hectorPlusSeating | glosterSeating;
  seatingOptions: string[];
  isEnqGenerated: boolean;
  checkRoute: any;
  summaryFlag: boolean = false;
  createNewPinWhenModelChange: boolean;
  isAgentMute: boolean;
  $seatingOptions: Subscription;
  isVDSession: boolean;
  pinSummaryData: any;
  isVisible = [false, false, false, false];
  currentDivIndex = 0;
  isPinSubmitted = false;
  pincodeForm: FormGroup;
  queryForm: FormGroup;
  pincode: any;
  dealerList: any = [];
  selectedDealer: any = null;
  selectedDealerObj: any = {};
  apiDataObj:any={};
  oldNo: any;
  enableSendOTPButton: boolean;
  enableTimer: boolean;
  interval: any = 1000;
  showResendOtpCounter: boolean;
  countdown: number;
  failedVerifiedOpt: boolean;
  showOTPField: boolean;
  isLocationBtnDisable: boolean = false;
  runPinCodeFlow: boolean=false;
  noDataForPin: boolean=false;
  source:string="hector-plus";
  setTimeoutFn: any;
  setTimeOutFnHidePanel:any;
  constructor(
    private _api: ApiService,
    private _data: DataService,
    public _global: GlobalService,
    private _socket: SocketService,
    private _callS: CallService,
    public _ga: GaService,
    private zone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private _event: EventService,
    public sanitizer: DomSanitizer,
    private _snack: SnackbarService,
    private location: Location,
    private dialog: MatDialog,
    public formBuilder:FormBuilder
  ) {
    this.createForms();
    this.injectAssetsToDOM();
  }

  createForms() {
    this.pincodeForm = this.formBuilder.group({
      pincode: [
        '',
        [Validators.required, Validators.pattern(/^[1-9][0-9]{5}$/)],
      ]
    });

    this.queryForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]+$/),
        Validators.maxLength(100)
      ]),
      mobile: new FormControl('', {
        validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
      }),
      carName: new FormControl('', {
        validators: [Validators.pattern(/^[a-zA-Z0-9 ]+$/),  Validators.maxLength(70),  Validators.minLength(2)],
      }),
      privacyPolicyCheckbox: new FormControl(true, {
        validators: [Validators.requiredTrue],
      }),
      dealerAssit: new FormControl(true), // Optional checkbox, not selected by default
      // otp: new FormControl('', {
      //   validators: [Validators.required, Validators.minLength(4)],
      // }),
    });
  }

  async ngOnInit(): Promise<void> {
    /******************************* */
    this.checkRoute = window.location.href;
    if (this.checkRoute.includes('/home')) {
      document.body.classList.add('one3d-home-page-open');
      if (localStorage.getItem("pincodeFlowProgress") !=undefined || localStorage.getItem("pincodeFlowProgress") !=null ){
        if (JSON.parse(localStorage.getItem("pincodeFlowProgress")).source != this.source){
          localStorage.removeItem("pincodeFlowProgress");
          localStorage.removeItem("selectedDealer");
        }
      }
      this.runPinCodeFlow=true;
    } else {
      document.body.classList.remove('one3d-home-page-open');
      this.runPinCodeFlow=false;
    }
    /******************************* */
    console.log('this.mobile', this._global.detectMob());
    this.loadedCar = 'hectorplus';
    this.titleService.setTitle('MG HectorPlus | eXpert');
    window.updatePayloadParameter(
      'eLa1',
      defaultCarDetails[this.loadedCar].gaModelName
    );
    let seatC = this._data.getSeatingInfo();
    if (seatC) {
      if (seatC == '6') {
        this._global.updateHectorSeatingOption(hectorPlusSeating.sixSeater);
      } else {
        this._global.updateHectorSeatingOption(hectorPlusSeating.sevenSeater);
      }
    } else {
      this._global.updateHectorSeatingOption(hectorPlusSeating.sixSeater);
    }
    // this._global.updateHectorSeatingOption(hectorPlusSeating.fiveSeater);
    this.seatingOptions = Object.values(hectorPlusSeating).filter(String);
    console.log('this.seatingOptions', this.seatingOptions);
    this.scrollPos = {
      isTop: true,
      isBottom: false,
    };
    this.activatedRoute.queryParams.subscribe((data) => {
      if (data) {
        this.queryParams = data;
      }
    });
    window['hectorRef'] = {
      zone: this.zone,
      one3dcallback: {
        onVariantLoadComplete: () => this.onVariantLoadComplete(),
        onCarLoadComplete: () => this.onCarLoadComplete(),
        onInteriorView: () => this.interiorOrExteriorViewchange(true),
        onExteriorView: () => this.interiorOrExteriorViewchange(false),
        onFrontSeatView: () => this.gotoFrontSeatCallBack(),
        onBackSeatView: () => this.gotoBackSeatCallBack(),
        onLastSeatView: () => this.gotoRearSeatCallBack(),
        doorEnded: () => {
          this.doorInProgress = false;
        },
        sunRoofEnded: () => {
          this.sunRoofInProgress = false;
        },
      },
      component: this,
    };
    this._global.hide3D.subscribe((flag) => (this.HIDE_3D = flag));
    let loc = this._data.getLocationInfo();
    if (loc) {
      this._global.updateLocation(JSON.parse(loc));
    }
    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this._global.createNewPinWhenModelChange.subscribe(
      (data) => (this.createNewPinWhenModelChange = data)
    );

    this._global.colorList.subscribe((data) => (this.colorList = data));
    this._global.selectedColor.subscribe((data) => {
      this.selectedColor = data;
      this.setArUrl();
    });
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.groupMembers.subscribe((data) => (this.groupUsers = data));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.showRightPanelToCustomer.subscribe(
      (data) => (this.DISPLAY_TO_CUSTOMER = data)
    );
    this._global.variantList.subscribe((data) => (this.variantList = data));
    this._global.resetAccCam.subscribe((data) => (this.isAccCamSet = data));
    this._global.hideAllIcons.subscribe((data) => (this.hideAllIcons = data));
    this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data));
    this._global.headLampIsOpen.subscribe((data) => (this.headLampOn = data));
    this._global.sunRoofIsOpen.subscribe((data) => (this.sunRoofOn = data));
    this._global.isAgentMute.subscribe((data) => (this.isAgentMute = data));
    this._global.isInterior.subscribe((data) => {
      this.isInterior = data;
      if (!this.isInterior) {
        document.body.classList.remove(
          'one3d-colors-in-interior-view-not-available'
        );
      }
    });
    this._global.socketStatus.subscribe((data) => (this.SOCKET_STATUS = data));
    this._global.isArModalOpen.subscribe((data) => (this.openArModal = data));
    this._global.isEnqGenerated.subscribe(
      (data) => (this.isEnqGenerated = data)
    );
    this.$seatingOptions = this._global.hectorSeatingOptions.subscribe(
      (data) => {
        this.hectorSeatingOptions = data;
        // if (!data) {
        //   this._global.updateHectorSeatingOption(hectorPlusSeating.sixSeater);
        // }
      }
    );
    this._global.isCarCompareModalOpen.subscribe(
      (data) => (this.isCarCompareModalOpen = data)
    );
    this._global.isFeedBackModelOpen.subscribe(
      (data) => (this.isFeedBackModelOpen = data)
    );
    this._global.isSubmitModalOpen.subscribe(
      (data) => (this.isSubmitModalOpen = data)
    );
    this._global.featureImageList.subscribe(
      (data) => (this.featureImageList = data)
    );
    this._global.isOpenPulseHubEnqForm.subscribe(
      (data) => (this.isOpenPulseHubEnqForm = data)
    );
    this._global.isOpenPulseHubForm.subscribe(
      (data) => {
        this.isOpenPulseHubForm = data;
        if (this.isOpenPulseHubForm){
          clearInterval(this.setTimeoutFn);
        }
      }  
    );
    this._global.clearPincodeFlow.subscribe(
      (data) => {
        console.log("Pincode flow data", data)
        if (data) {
          clearInterval(this.setTimeoutFn);
        }
      }
    );
    this._global.openLocModal.subscribe(
      (data) => (this.openLocationModal = data)
    );
    this._global.openFeatureCompletePopup.subscribe(
      (data) => (this.openFeatureCompletePopup = data)
    );
    this._global.overlaySrc.subscribe((data) => {
      this.overlaySrc = data;
      if (data.includes('.mp4')) {
        this.videoOptions = {
          autoplay: true,
          controls: false,
          fluid: true,
          loop: true,
          sources: [
            {
              src: this.overlaySrc,
              type: 'video/mp4',
            },
          ],
        };
        console.log('this.videoOptions', this.videoOptions);
      }
    });
    this._global.soundTrackURL.subscribe((data) => (this.soundTrackURL = data));

    this._global.callingAgentDetails.subscribe(
      (data) => (this.agentDetails = data)
    );
    this.$callConnectedSubs = this._global.audioStatus.subscribe((s) => {
      console.log('this.audioCallStatus', s);
      this.audioCallStatus = s;
      if (s && !location.hash.includes('variants')) {
        this.gotoVariant();
      }
      if (s) {
        this._global.updateSoundTrackURL('');
        this._global.updateAccCam(false);
        this._global.updateHide3D(false);
        this.enableGrantAccessModal = true;
        setTimeout(() => {
          this.enableGrantAccessModal = false;
        }, 5 * 1000);
        if (this.createNewPinWhenModelChange) {
          this._snack.openSnackBar('Agent has Joined', 'Success');
          this._data.clearLoadCounter();
        }
        this.resetPosition();
      }
      // ONE3D.event.onResizeWindow(); // resize animation conflict issue fixed
    });

    if (this.queryParams?.pin_no) {
      this.isVDSession = true;
      this._data.setLocalPinno(this.queryParams.pin_no);
      this._global.updatePinno(this.queryParams.pin_no);
      this.getAppointmentDataByPin(this.queryParams.pin_no);
    }

    const localData = this._data.getDealerDetails();
    if (localData) {
      const result = await this.openDialog();
      console.log('result', result);
      if (result.data == 'no') {
        sessionStorage.clear();
        window.location.replace('https://www.mgmotor.co.in/');
        return;
      }
    }

    let pinNo = this._data.getLocalPinNo();
    console.log('getLocalCarBrand()', this._data.getLocalCarBrand());
    console.log('this.loadedCar', defaultCarDetails);

    if (
      !pinNo ||
      (this._data.getLocalCarBrand() != this.loadedCar &&
        this.createNewPinWhenModelChange)
    ) {
      this.createNewConfig();
      sessionStorage.removeItem('v_');
      sessionStorage.removeItem('c_');
      sessionStorage.removeItem('loc_');
      sessionStorage.removeItem('seating_');
      sessionStorage.removeItem('selectedProtectionPlan');
    } else {
      window.updatePayloadParameter('pinId', pinNo);
      this._global.updatePinno(pinNo);
      this._data.setLocalPinno(pinNo);
      this.getVariantList();
    }
    console.log('hectorplus web component called');
    this._data.setLocalCarBrand('hectorplus');
    this.registerSocket();
    if (!this.createNewPinWhenModelChange) {
      sessionStorage.removeItem('c_');
      sessionStorage.removeItem('v_');
      sessionStorage.removeItem('selectedProtectionPlan');
    }
    /**********************************checking route event @JP *********************************************/
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log(event.url);
        this.navPanelModelSel(event.url);
        this.checkRoute = event.url;
      }
    });
    /**********************************checking route event @JP *********************************************/
  }

  ngOnDestroy(): void {
    console.log('hectorplus web component left');
    this.$seatingOptions.unsubscribe();
    this.$dataTransfer.unsubscribe();
    this.$colorChangeData.unsubscribe();
    this.$roomUsers.unsubscribe();
    this.$callConnectedSubs.unsubscribe();
    this._global.updateHectorSeatingOption(null);
    if (!this.createNewPinWhenModelChange) {
      ONE3D.event.removeAllHotspot();
      ONE3D.event.showAllHotspot(false);
      ONE3D.event.getScene().getEngine().dispose();
      ONE3D.event.getScene().dispose();
      ONE3D._one3dExecute = false;
      ONE3D = null;
      ONE3D_lib = null;
      one3dFunctions = null;
    }
  }
  endCall(): void {
    this._global.updateAccessUI(true);
    this._global.updateAgentDetails({});
    ONE3D.exitAccessoriesView();
    ONE3D.exitFeatureView();
    this._global.toggleFeedBackFormModal(true);
    this._callS.endCall();
    this._global.updateAudioCallStatus(false);
    this._global.updateEnqGenerated(false);
    let name = this._data.getLocalName();
    if (!name) {
      name = 'anonymous';
    }
    console.log('name', name);
    this._socket.socket.emit('user_left', {
      username: name,
      room: this._socket.room,
    });
    this._socket.disconnect();
  }

  async openDialog(): Promise<any> {
    const dialogRef = this.dialog.open(SessionWarningComponent, {});

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        console.log('The dialog was closed', result);
        return Promise.resolve(result);
      });
  }

  injectAssetsToDOM() {
    let previous = document.getElementById('carF');
    if (previous) {
      previous.remove();
      const scriptList = document.getElementsByTagName('script');

      for (let index = 0; index < scriptList.length; index++) {
        if (scriptList[index].src.includes('packageV3.js')) {
          console.log(scriptList[index]);
          scriptList[index].remove();
        }
      }
    }
    console.log('this.loadedCar', this.loadedCar);
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.id = 'carF';
    s.async = true;
    s.src = `${this.assetsUrl}/assets/one3d/assets/Hector Plus/functions.js`;
    document.body.appendChild(s);
    s.onload = () => {
      if (ONE3D.loadedPercent < 90) {
        document.getElementById('loader_BG').style.display = 'block';
      }
    };
  }

  onVariantLoadComplete() {
    if (this.isInterior) {
      this.getInteriorColors();
    }
  }

  interiorOrExteriorViewchange(status: boolean) {
    // this.isInterior = status;
    this._global.updateInteriorState(status);
    if (!status && ONE3D.loadedPercent == 100) {
      this.getVariantDetails();
    } else {
      this.getInteriorColors();
    }
    if (status) {
      if (this.audioCallStatus && this.ACCESS) {
        this._socket.socket.emit('dataTransfer', {
          room: this._socket.room,
          gotoInt: null,
        });
      }
      this.intExtState = 'int';
    } else {
      if (this.audioCallStatus && this.ACCESS) {
        this._socket.socket.emit('dataTransfer', {
          room: this._socket.room,
          gotoExt: null,
        });
      }
      this.intExtState = 'ext';
    }
  }
  gotoFrontSeatView() {
    ONE3D.interiorView();
  }
  gotoBackSeatView() {
    ONE3D.backseatView();
  }
  gotoRearSeatView() {
    ONE3D.event.lastseatView();
  }
  gotoFrontSeatCallBack() {
    window.sendData({
      eName: 'click',
      eCat: 'car_config_page',
      eAct: 'frontseat_view_button_clicked',
    });
    if (this.ACCESS) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        gotoFront: null,
      });
    }
  }
  gotoBackSeatCallBack() {
    window.sendData({
      eName: 'click',
      eCat: 'car_config_page',
      eAct: 'backseat_view_button_clicked',
    });
    if (this.ACCESS) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        gotoBack: null,
      });
    }
  }
  gotoRearSeatCallBack() {
    window.sendData({
      eName: 'click',
      eCat: 'car_config_page',
      eAct: 'rear_seat_view_button_clicked',
    });
    if (this.ACCESS) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        gotoRareSeat: null,
      });
    }
  }
  onCarLoadComplete() {
    console.log('#####this.variantDetails', this.selectedVariant);
    this._ga.sendGAEvent('loader', 'click', 'end');
    window.updatePayloadParameter('pinId', this.pinNo);
    window.updatePayloadParameter(
      'cuId',
      this.usersData ? this.usersData.cust_id : ''
    );
    this.setTimeOutFnHidePanel= setTimeout(() => {
      this.hideRightPanel();
     }, 15000);
    //If isVDSession is false, only then run the code.
    // if (this.runPinCodeFlow && !this.isVDSession){
    //   this.setTimeoutFn = setTimeout(() => {
    //     let result: any;
    //     result = this.checkWhichDivToShow();
    //     console.log("The result", result?.otp?.val);
    //     if (result != null || result != undefined) {
    //       if (result?.otp?.flag) {
    //         this.pincode = result.pincode?.val;
    //         this.selectedDealer = result.dealer.val?.dealer_id;
    //         this.selectedDealerObj = result?.dealer?.val;
    //         this.apiDataObj = result?.otp?.val;
    //         return false;
    //       } else
    //         if (result?.dealer?.flag) {
    //           //User has already filled the pincode, dealer form. 
    //           //Open OTP form.
    //           this.pincode = result.pincode?.val;
    //           this.selectedDealer = result.dealer.val?.dealer_id;
    //           this.selectedDealerObj = result?.dealer?.val;
    //           this.isVisible[2] = true;
    //         } else if (result?.pincode?.flag) {
    //           //User has already filled the pincode form
    //           //Open the select dealer list form
    //           this.pincode = result.pincode?.val;
    //           this.fetchDealersViaPin();
    //           this.isVisible[1] = true;
    //         }
    //     } else {
    //       this.isVisible[0] = true;
    //     }
    //   }, 60000);
    // }

    window.updatePayloadParameter('eLa2', this.selectedVariant.title);
    window.updatePayloadParameter('eLa3', this.selectedColor.color_name);
    window.updatePayloadParameter('pinId', this.pinNo);
    window.updatePayloadParameter(
      'cuId',
      this.usersData ? this.usersData.cust_id : ''
    );

    window.updatePayloadParameter('eLa2', this.selectedVariant.title);
    window.updatePayloadParameter('eLa3', this.selectedColor.color_name);
    if (this._socket.connected) {
      this._socket.saveApiCallJourney('socket_connected', 'true');
    } // this._global.updateExteriorLoading(true);
    // setTimeout(() => {
    //   ONE3D.changeVariant(this.selectedVariant.d_name, {
    //     color: this.selectedColor.hex_code,
    //   });
    // }, 500);
    if (!this.createNewPinWhenModelChange) {
      setTimeout(() => {
        this._socket.emitModelCompletedWhenCall();
        this._global.updateNewPinWhenModelChange(true);
      }, 2000);
    }
  }

  async createNewConfig() {
    try {
      const customerIP = await this.getIPAddress();

      let apiData = {
        pin_no: this.pinNo,
        mid: '',
        source: 'Web',
        city: 'Delhi',
        state: 'Delhi',
        ip: customerIP,
        update_variant: this.isVDSession ? 'no' : 'yes',
        variant_id: defaultCarDetails[this.loadedCar].variant_id,
        variant_feature_id:
          defaultCarDetails[this.loadedCar].variant_feature_id,
        utm: '',
        utm_source: this.queryParams.hasOwnProperty('utm_source')
          ? this.queryParams.utm_source
          : '',
        utm_medium: this.queryParams.hasOwnProperty('utm_medium')
          ? this.queryParams.utm_medium
          : '',
        utm_campaign: this.queryParams.hasOwnProperty('utm_campaign')
          ? this.queryParams.utm_campaign
          : '',
        cust_id: this.usersData ? this.usersData.cust_id : '',
      };

      let apiResp: any = await this._api.createNewConfiguration(apiData);
      console.log('apiResp', apiResp);
      if (apiResp.status === 1) {
        this._ga.sendGAEvent('pin_no', 'click', apiResp.response.pin_no);
        // this._data.variantList = apiResp.response.variants;
        window.updatePayloadParameter('pinId', apiResp.response.pin_no);
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'created',
        });
        this._global.updatePinno(apiResp.response.pin_no);
        this._data.setLocalPinno(apiResp.response.pin_no);
        this.getVariantList();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getIPAddress() {
    try {
      const apiIp: any = await this._api.getIpofUser();
      return apiIp.ip;
    } catch (error) {
      console.error(error);
    }
  }

  async getVariantList() {
    try {
      this._global.showLoading(true);
      let apiData = {
        fuel_type: '',
        transmission: '',
        sel_range: '',
        model_id: defaultCarDetails[this.loadedCar].model_id,
        source: 'web',
      };

      let apiResp: any = await this._api.getVariantList(apiData);
      if (apiResp.status === 1) {
        this.variantList = apiResp.response;
        this._global.updateVariantList(this.variantList);
        // this._data.variantList = this.variantList;
        this.setSelectedVariant();
      }
    } catch (error) {
      console.error(error);
    }
  }

  setSelectedVariant() {
    let variant = this._data.getLocalVariant();
    console.log('defaultVariant', variant);

    if (variant) {
      let foundVariant = this.variantList.filter(
        (ele) => ele.variant_id == variant
      );
      if (foundVariant.length > 0) {
        this.selectedVariant = foundVariant[0];
        this._global.updatedVariant(this.selectedVariant);
        this._global.updatedPrice(this.selectedVariant.price);
      } else {
        // this.selectedVariant = this.variantList[0];
        let selectV = this.variantList.filter(
          (ele) =>
            ele.variant_id == defaultCarDetails[this.loadedCar].variant_id
        );
        this.selectedVariant = selectV[0];
        this._global.updatedVariant(this.selectedVariant);
        this._global.updatedPrice(this.selectedVariant.price);
      }
    } else {
      // this.selectedVariant = this.variantList[0];
      let selectV = this.variantList.filter(
        (ele) =>
          ele.is_default == 1 && ele.seats_no == this.hectorSeatingOptions + 'S'
      );
      this.selectedVariant = selectV[0];
      this._global.updatedVariant(this.selectedVariant);
      this._global.updatedPrice(this.selectedVariant.price);
    }
    this.getVariantDetails();
    // if (this.globalPinNO != '') {
    // } else {
    //   this._global.showLoading(false);
    // }
  }

  async getVariantDetails() {
    try {
      let apiData = {
        pin_no: this.pinNo,
        variant_id: this._data.getLocalVariant()
          ? this._data.getLocalVariant()
          : defaultCarDetails[this.loadedCar].variant_id,
        source: 'web',
      };
      console.log('before selectedColorCode..');

      let apiResp: any = await this._api.getVariantDetails(apiData);
      if (apiResp.status === 1) {
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });

        this.colorList = apiResp.response.varinat_color;
        this._global.updateColorList(this.colorList);
        this.setSelectedColor();
      }
    } catch (error) {
      console.error(error);
    }
  }
  setSelectedColor() {
    let selectedColorCode = this._data.getLocalColor();
    console.log('selectedColorCode', selectedColorCode);

    if (selectedColorCode) {
      let foundColorItem = this.colorList.filter(
        (ele) => ele.variant_feature_id == selectedColorCode
      );
      // let index = this.colorList.findIndex(
      //   (ele) => ele.variant_feature_id == selectedColorCode
      // );
      if (foundColorItem.length > 0) {
        this._global.updateSelectedColor(foundColorItem[0]);
      } else {
        // this._global.updateSelectedColor(this.colorList[0]);
        let filteredD = this.colorList.find((ele) => ele.default == 1);
        if (filteredD) {
          this._global.updateSelectedColor(filteredD);
        }
      }
    } else {
      // this.selectedColor = this.colorList[0];
      // this._global.updateSelectedColor(this.colorList[0]);
      let filteredD = this.colorList.find((ele) => ele.default == 1);
      if (filteredD) {
        this._global.updateSelectedColor(filteredD);
      }
    }
    if (!this.comingFromInt) {
      if (ONE3D.loadedPercent == 100) {
        ONE3D.changeVariant(this.selectedVariant.d_name);
        window.updatePayloadParameter('eLa2', this.selectedVariant.title);
      } else {
        if (this.createNewPinWhenModelChange) {
          this._global.updateSoundTrackURL(
            `${this.assetsUrl}/audio-journey/Loading COpy.mp3`
          );
        }
        this._ga.sendGAEvent('loader', 'click', 'start');
        window.sendData({
          eName: 'internal',
          eCat: 'car_config_page',
          eAct: '3d_model_loading',
          eVal: 'start',
        });
        ONE3D.init(
          'one3d',
          `${this.assetsUrl}/assets/one3d/`,
          'Hector Plus',
          // this.hectorSeatingOptions == hectorPlusSeating.sixSeater
          //   ? defaultCarDetails[this.loadedCar].topVar
          //   : 'Smart(O)',
          this.selectedVariant.d_name,
          {
            showFeature: true,
            color: this.selectedColor.hex_code,
            reflectionMap: {
              exterior: 'dayExterior',
              interior: 'interior',
            },
          }
        );
      }
    } else {
      this.comingFromInt = false;
    }
    this.setArUrl();
  }

  returnColor1(hexcode: string) {
    if (hexcode.trim()) {
      return hexcode.split(',')[0];
    }
    return '';
  }
  returnColor2(hexcode: string) {
    if (hexcode.trim()) {
      if (hexcode.split(',').length > 1) {
        return hexcode.split(',')[1];
      } else {
        return hexcode.split(',')[0];
      }
    }
    // return '';
  }
  toggleRightPanel() {
    this.isOpenRightPanel = !this.isOpenRightPanel;
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 500); // resize animation conflict issue fixed
  }

  toggleDoor() {
    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      window.sendData({
        eName: 'click',
        eCat: 'car_config_page',
        eAct: 'door_button_clicked',
        eVal: 'open',
        eVal4: this.isInterior ? 'interior' : 'exterior',
      });
      // this.isCarDoorOpen = false;
    } else {
      this._global.updateDoorState(true);
      window.sendData({
        eName: 'click',
        eCat: 'car_config_page',
        eAct: 'door_button_clicked',
        eVal: 'close',
        eVal4: this.isInterior ? 'interior' : 'exterior',
      });
      // this.isCarDoorOpen = true;
    }
    this.doorInProgress = true;
    ONE3D.toggleDoors();
    this._ga.sendGAEvent(`micro`, 'click', 'door');
    if (this.audioCallStatus && this.ACCESS) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        toggleDoors: this.isCarDoorOpen,
      });
    }
    // this._global.carDoorIsOpen.subscribe((data: any) => {
    //   console.log('datatata', data);
    // });

    // AnimationManager.animmanager.doorAnimate();
    //this.gaScript.sendGAEvent('view', 'doorOpen', 'click');
    // this.gaScript.sendGAEvent(
    //   `${gaName[this.loadedCar]}view`,
    //   'click',
    //   'doorOpen'
    // );
    // if (localStorage.getItem('car') == 'hector-plus') {
    //   this.gaScript.sendGAEvent('view', 'click', 'doorOpen');
    // } else if (localStorage.getItem('car') == 'hector') {
    //   this.gaScript.sendGAEvent(`hec_view`, 'click', 'doorOpen');
    // } else if (localStorage.getItem('car') == 'gloster') {
    //   this.gaScript.sendGAEvent(`glos_view`, 'click', 'doorOpen');
    // }
  }

  toggleHeadLamp() {
    window.sendData({
      eName: 'click',
      eCat: 'car_config_page',
      eAct: 'head_lamp_button_clicked',
      eVal: !this.headLampOn ? 'on' : 'off',
      eVal4: this.isInterior ? 'interior' : 'exterior',
    });
    this._global.updateHeadLampState(!this.headLampOn);
    // this.headLampOn = !this.headLampOn;
    ONE3D.toggleLights();
    this._ga.sendGAEvent(`micro`, 'click', 'lights');
    // if (this.headLampOn) {
    //   this.headLampOn
    // }
    if (this.audioCallStatus && this.ACCESS) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        toggleLights: !this.isCarDoorOpen,
      });
    }
  }
  toggleRoof() {
    window.sendData({
      eName: 'click',
      eCat: 'car_config_page',
      eAct: 'sunroof_button_clicked',
      eVal: !this.sunRoofOn ? 'on' : 'off',
      eVal4: this.isInterior ? 'interior' : 'exterior',
    });
    this._global.updateSunRoofState(!this.sunRoofOn);
    // this.sunRoofOn = !this.sunRoofOn;
    this.sunRoofInProgress = true;
    ONE3D.toggleSunroof();
    this._ga.sendGAEvent(`micro`, 'click', 'sunroof');
  }
  toggleIntExt(state: string) {
    console.log(this.intExtState);
    console.log(state);

    if (this.intExtState != state) {
      // if (this.isCarDoorOpen) {
      //   this._global.updateDoorState(false);
      //   this._ga.sendGAEvent(`car look`, 'click', 'int');
      //   // this.isCarDoorOpen = false;
      //   // AnimationManager.animmanager.doorAnimate();
      // }

      if (this.isInterior) {
        ONE3D.exteriorView();
        this._ga.sendGAEvent(`car look`, 'click', 'ext');
        document.body.classList.remove(
          'one3d-colors-in-interior-view-not-available'
        );
        window.sendData({
          eName: 'click',
          eCat: 'car_config_page',
          eAct: 'go_exterior_button_clicked',
          eVal: 'exterior',
        });
        // this.isInterior = false;
        // this._global.updateInteriorState(false);
      } else {
        ONE3D.interiorView();
        this._ga.sendGAEvent(`car look`, 'click', 'int');
        window.sendData({
          eName: 'click',
          eCat: 'car_config_page',
          eAct: 'go_interior_button_clicked',
          eVal: 'interior',
        });
        // this.isInterior = true;
        // this._global.updateInteriorState(true);
      }
    }
  }

  onSelectColor(color: any) {
    this.selectedColor = color;

    console.log(color);
    if (this.selectedColor.color_category == 'interior') {
      // console.log('change variant');
      // SceneManager.scenemanager.variantManager.ChangeVariant(color.threed_code);

      ONE3D.changeVariant(color.threed_code);
      localStorage.setItem('selectedColorInt', color.variant_feature_id);
      // if (color.color_name == 'Champagne & Black') {
      // } else {
      //   sessionStorage.removeItem('selectedColorInt');
      // }
    } else {
      // sessionStorage.removeItem('selectedColorInt');
      this._data.setLocalColor(color.variant_feature_id);
      ONE3D.changeColor(this.selectedColor.hex_code);
      window.sendData({
        eName: 'click',
        eCat: 'car_config_page',
        eAct: 'color_change_button_clicked',
      });
      window.updatePayloadParameter('eLa3', this.selectedColor.color_name);
    }
    this._global.updateSelectedColor(color);
    // this.gaScript.sendGAEvent('colours', color.color_name, 'click');
    // this.saveCustomerJourny('view_color');
    this.changeColorerVariant();
    this._ga.sendGAEvent('colours', 'click', color.color_name);
    this.setArUrl();
    if (this.ACCESS) {
      if (this.selectedColor.color_category == 'interior') {
        this._socket.emitChangeInteriorColor(color);
      } else {
        this._socket.emitChangeColor(color);
      }
    }
  }
  async changeColorerVariant(flag = '') {
    try {
      let locationInfo = JSON.parse(localStorage.getItem('location_info'));
      let intColorId = localStorage.getItem('selectedColorInt');
      this._global.showLoading(true);
      let apiData = {
        pin_no: this.pinNo,
        variant_id: this._data.getLocalVariant()
          ? this._data.getLocalVariant()
          : defaultCarDetails[this.loadedCar].variant_id,
        variant_feature_id: this._data.getLocalColor()
          ? this._data.getLocalColor()
          : defaultCarDetails[this.loadedCar].variant_feature_id,
        state: locationInfo ? locationInfo.state : 'Delhi',
        city: locationInfo ? locationInfo.city : 'Delhi',
        change_variant: flag,
        source: 'web',
        interior_color_id: intColorId ? intColorId : '',

        page: 'feature',
      };
      let apiResp: any = await this._api.checkVariantColorSelection(apiData);
      if (apiResp.status == 1) {
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });

        this._global.updatedPrice(apiResp.response.showroom_price);
        if (apiResp.response && apiResp.response.variant_feature_id) {
          if (apiResp.response.hasOwnProperty('color_category')) {
            if (apiResp.response.color_category == 'interior') {
              localStorage.setItem(
                'selectedColorInt',
                apiResp.response.variant_feature_id
              );
            } else if (apiResp.response.color_category == 'exterior') {
              // let selectdC = this.colorList.filter(
              //   (ele) =>
              //     ele.variant_feature_id == apiResp.response.variant_feature_id
              // );
              // this.selectedColor = selectdC[0];
              // console.log('this.selectedColor', this.selectedColor);
              // this._data.setLocalColor(apiResp.response.variant_feature_id);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  audioCompleted($event) {
    this._global.updateSoundTrackURL('');
  }

  gotoFeaturePage() {
    ONE3D.exitAccessoriesView();
    this._global.updateAccCam(false);
    this._global.updateOverlaySrc('');
    document.getElementById('featureImageFullScreen').style.display = 'none';
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/single-feature-play`]);
    // if (this.ACCESS) {
    //   this._socket.socket.emit('dataTransfer', {
    //     room: this._socket.room,
    //     gotoFeaturePage: null,
    //   });
    // }
  }

  resetAccCam() {
    console.log(
      'this.isAccCamSet',
      this.isAccCamSet,
      location.hash.includes('accessorize')
    );
    if (this.isAccCamSet && location.hash.includes('accessorize')) {
      window.sendData({
        eName: 'click',
        eCat: 'accessorize_menu',
        eAct: 'back_to_default_button_clicked',
      });
      ONE3D.exitAccessoriesView();
      // this._global.updateAccPreview(false);
      this._global.updateAccCam(false);
      this._global.updateOverlaySrc('');
      document.getElementById('featureImageFullScreen').style.display = 'none';
      if (this.ACCESS && this.audioCallStatus) {
        this._socket.socket.emit('dataTransfer', {
          room: this._socket.room,
          resetAccCam: null,
        });
      }
    }
  }
  hideHotspotOnAccPage() {
    return window.location.hash.includes('accessorize') ||
      window.location.hash.includes('summary')
      ? true
      : false;
  }
  isInAccessoryPage() {
    return window.location.hash.includes('accessorize') ? true : false;
  }
  isInFeaturePage() {
    return window.location.hash.includes('features') ? true : false;
  }

  hideColorsOnSummary() {
    return window.location.hash.includes('emi') ||
      window.location.hash.includes('summary') ||
      window.location.hash.includes('car-exchange')
      ? true
      : false;
  }

  setArUrl() {
    if (this.selectedColor.color_name) {
      let color_name = this.selectedColor.color_name
        .replaceAll(/\s/g, '')
        .replace(/[^a-zA-Z ]/g, '');

      if (/android/i.test(navigator.userAgent)) {
        let url = `intent://arvr.google.com/scene-viewer/1.0?file=${
          this.arAssetsUrl
        }${this.loadedCar}/${color_name}.glb&mode=ar&title=${
          defaultCarDetails[this.loadedCar].arTitle
        }&link=https://cc.mgmotor.co.in/mgexpert/#${
          defaultCarDetails[this.loadedCar].urlScheme
        }/home#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=/assets/eccentriengine-360/ar-not-supported.html;end;`;
        this.urlForAr = url;
      }

      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        this.urlForAr = `${this.arAssetsUrl}${this.loadedCar}/${color_name}.usdz`;
      }
    }
  }

  setViewArIcon() {
    return (window.location.hash.includes('home') ||
      window.location.hash.includes('self-explore')) &&
      !this.audioCallStatus &&
      (this._global.detectMob() || /iPad|iPhone|iPod/.test(navigator.userAgent)) && this.selectedVariant.is_default == 1
      ? true
      : false;
  }

  onClickArGA() {
    this._ga.sendGAEvent(`micro`, 'click', 'AR');
  }

  gotoVariant() {
    this._global.toggleFeatureCompletePopup(false);
    let loadedCar = this._data.getLocalCarBrand();
    this._ga.sendGAEvent('audio features', 'click', 'skip');
    window.sendData({
      eName: 'click',
      eCat: 'next_burst_features',
      eAct: 'skip_button_clicked',
    });
    let url = defaultCarDetails[loadedCar].urlScheme;
    this.router.navigate([`${url}/variants`]);
  }
  gotoAccessoriesPage() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this._api.saveBuildSummary(this.pinNo, 'accessories');
    this.router.navigate([`${url}/accessorize`]);
  }

  hideRightPanel() {
    if (this.isHiddenRightPanel) {
      clearInterval(this.setTimeOutFnHidePanel);
      window.sendData({
        eName: 'click',
        eCat: 'car_config_page',
        eAct: 'show_button_clicked',
      });
    } else {
      window.sendData({
        eName: 'click',
        eCat: 'car_config_page',
        eAct: 'hide_button_clicked',
      });
    }
    this.isHiddenRightPanel = !this.isHiddenRightPanel;
    console.log(this.isHiddenRightPanel);
    this._ga.sendGAEvent(
      'view',
      'click',
      this.isHiddenRightPanel ? 'show' : 'hide'
    );
    let updateDummy = { value: 0 };
    TweenMax.to(updateDummy, 0.6, {
      value: 1,
      onUpdate: () => {
        ONE3D.event.onResizeWindow();
      },
    });
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 500);
    /**************** TOGGLE CONDI€TION IN MOBILE *******************/
    if (this._global.detectMob()) {
      if (this.isHiddenRightPanel) {
        document.body.classList.remove('one3d-open-bottom-panel');
      } else {
        document.body.classList.add('one3d-open-bottom-panel');
      }
      if (this.isInterior && this.colorList.length == 0) {
        document.body.classList.add(
          'one3d-colors-in-interior-view-not-available'
        );
      }
    }
    /**************** TOGGLE CONDI€TION IN MOBILE *******************/
  }

  gotoHome() {
    ONE3D.exitFeatureView();
    this._global.updateSoundTrackURL('');
    this._global.updateAccCam(false);
    let loadedCar = this._data.getLocalCarBrand();
    this._global.updateHide3D(false);
    this._ga.sendGAEvent('Home button', 'click', 'home');
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 800); // resize animation conflict issue fixed
    let url = defaultCarDetails[loadedCar].urlScheme;
    this.router.navigate([`${url}/home`]);
    window.sendData({ eName: 'click', eCat: '', eAct: 'home_button_clicked' });
  }

  palyMoreFeatures() {
    window.sendData({
      eName: 'click',
      eCat: 'next_burst_features',
      eAct: 'continue_button_clicked',
    });
    this._global.toggleFeatureCompletePopup(false);
    this._ga.sendGAEvent('audio features', 'click', 'continue');
    this._global.updatePlayNextFeature(true);
  }
  scrollDownColors() {
    let scrollArea = document.querySelector('.colorList ul');
    scrollArea.scrollTo(0, scrollArea.scrollHeight);
    this.scrollPos = {
      isTop: false,
      isBottom: true,
    };
    console.log('Scrolled Done');
  }
  scrollUpwards() {
    let scrollArea = document.querySelector('.colorList ul');
    scrollArea.scrollTo(0, 0);
    this.scrollPos = {
      isTop: true,
      isBottom: false,
    };
  }
  socketConnect() {
    this._socket.socket.connect();
    this._socket.setupSocketConnection();
  }

  muteCall() {
    // callGA("mute", "click", "click");
    console.log('mute called,isMuted', this.isMuted);
    // this.isMuted = true;
    this._global.toggleAgentMute(true);
    this._callS.muteCall();
    // this.cdr.detectChanges();
  }
  unmuteCall(status?) {
    // callGA("unmute", "click", "click");

    console.log('unmute called,isMuted', this.isMuted);
    // this.isMuted = false;
    this._global.toggleAgentMute(false);
    this._callS.unmuteCall();

    // this.cdr.detectChanges();
  }

  registerSocket() {
    this.$roomUsers = this._event.subscribe('roomUsers', ({ room, users }) => {
      console.log('New User Joined', users);

      // this.gotoVariant();
      // this.gotoVariant();
      if (this.createNewPinWhenModelChange) {
        this._socket.emitVariantDetails(this.selectedVariant);
      }
    });
    this.$colorChangeData = this._event.subscribe(
      'color_change_data',
      (data) => {
        if (this.createNewPinWhenModelChange) {
          console.log('color_change log', data);

          this.onSelectColor(data.color);
        }
      }
    );

    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      if (this.createNewPinWhenModelChange) {
        console.log('dataT model web', data);
        this.modelMainSocketSubs(data);
      }
    });
  }

  modelMainSocketSubs(data) {
    let keys = Object.keys(data);
    let key = keys[0];
    console.log('dataTransfer111', data);
    switch (key) {
      case 'gotoFeaturePage':
        this._global.rightPanelHideShow(false);
        this.gotoFeaturePage();
        break;
      case 'gotoVariantPage':
        this._global.rightPanelHideShow(false);
        this.gotoVariant();
        break;
      case 'gotoAccpage':
        this._global.rightPanelHideShow(false);
        this.gotoAccessoriesPage();
        break;
      case 'goToSummary':
        // this.hideVideDiv();
        // this.gotoSummaryPage();
        /**
         * Conditions added by @JP
         */
        if (this._global.detectMob()) {
          this.HIDE_3D = true;
          this._global.updateHide3D(this.HIDE_3D);
          this.DISPLAY_TO_CUSTOMER = true;
          this.summaryFlag = true;
          this._global.updateSummaryFlag(this.summaryFlag);
          this.hideVideDiv();
          this.gotoSummaryPage();
        } else {
          this.summaryFlag = true;
          this._global.updateSummaryFlag(this.summaryFlag);
          this.hideVideDiv();
          this.gotoSummaryPage();
        }
        break;
      case 'gotoInt':
        this.toggleIntExt('int');
        break;
      case 'gotoExt':
        this.toggleIntExt('ext');
        break;
      case 'gotoFront':
        this.gotoFrontSeatView();
        break;
      case 'gotoBack':
        this.gotoBackSeatView();
        break;
      case 'gotoRareSeat':
        this.gotoRearSeatView();
        break;
      case 'toggleLights':
        let light = data[key];
        console.log('flag', light);
        this.toggleHeadLamp();
        break;
      case 'toggleDoors':
        let door = data[key];
        console.log('flag', door);
        this.toggleDoor();
        break;
      case 'toggleSunRoof':
        // let door = data[key];
        // console.log('flag', door);
        this.toggleRoof();
        break;
      case 'gotoEMICalculatorPage':
        this.hideVideDiv();
        this.gotoEMICalculatorPage();
        break;
      case 'closeEMICalculator':
        /**
         * Conditions added by @JP
         */
        this.location.back();
        if (this.summaryFlag) {
          this._global.rightPanelHideShow(true);
          if (this._global.detectMob()) {
            this._global.updateHide3D(true);
          }
        } else {
          // this.location.back();
          this._global.rightPanelHideShow(false);
        }
        break;
      case 'gotoCarComparisonPage':
        this.hideVideDiv();
        this._global.toggleCarCompareModal(true);
        break;
      case 'closeCarComparisonPage':
        this._global.toggleCarCompareModal(false);
        break;
      case 'openFeedBackForm':
        this._global.toggleFeedBackFormModal(true);
        break;
      case 'openThanksModal':
        this._global.toggleSubmitModal(true);
        this._global.updateThankyouText(
          'Your test drive request was successfully submitted.'
        );
        setTimeout(() => {
          this._global.toggleSubmitModal(false);
        }, 1000 * 10);
        break;
      case 'openArStream':
        this._global.toggleArModal(true);
        break;
      case 'showAgentVideo':
        this.showOrHideStreamDiv(data[key], true);
        break;
      case 'hideAgentVideo':
        this.showOrHideStreamDiv(data[key], false);
        break;
      case 'closeSummary':
        this.closeSummaryPage();
        // this._global.rightPanelHideShow(false);
        // this.location.back();
        /**
         * Conditions added by @JP
         */
        this.summaryFlag = false;
        this._global.updateSummaryFlag(this.summaryFlag);
        // this._global.updateHide3D(false);
        this.HIDE_3D = false;
        this._global.updateHide3D(this.HIDE_3D);
        this.DISPLAY_TO_CUSTOMER = false;
        this.location.back();
        this._global.rightPanelHideShow(false);
        break;
      case 'hideArStream':
        this._global.toggleArModal(false);
        this.muteCall();
        setTimeout(() => {
          this.unmuteCall();
        }, 1000);
        break;
      case 'gotoCarExchangePage':
        this.hideVideDiv();
        this.gotoCarExchangePage();
        break;
      case 'closeCarExchange':
        this.location.back();
        this._global.rightPanelHideShow(false);
        break;
      case 'forceReloadClient':
        this.gotoVariant();
        window.location.reload();
        break;
      default:
        break;
    }
  }
  gotoEMICalculatorPage() {
    console.log('going on Emi Calculator');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/emi-calculator`]);
    this._global.rightPanelHideShow(true);
  }
  gotoSummaryPage() {
    console.log('going on Summary Page');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    if (this._global.detectMob()) {
      this._global.updateHide3D(true);
    }
    this.router.navigate([`${url}/summary`]);
    this._global.rightPanelHideShow(true);
  }
  async getInteriorColors() {
    console.log('this.selectedColor', this.selectedColor);
    if (this.selectedColor) {
      let apiBody = {
        pin_no: this.pinNo,
        variant_id: this.selectedVariant.variant_id,
        variant_feature_id: this.selectedColor.variant_feature_id,
        d_code: this.selectedColor.d_code,
      };
      let apiResp: any = await this._api.getInteriorColors(apiBody);
      // .subscribe((data: any) => {
      console.log('data', apiResp);
      // this.globalSrv.showLoading(false);
      if (apiResp.status == 1) {
        this.comingFromInt = true;
        this.colorList = apiResp.response;
        if (this.colorList.length > 0) {
          let selectedColorInt = localStorage.getItem('selectedColorInt');
          if (selectedColorInt) {
            let intSelected = this.colorList.filter(
              (ele) => ele.variant_feature_id == selectedColorInt
            );
            this.selectedColor = intSelected[0];
          } else {
            let intSelected = this.colorList.filter(
              (ele) => ele.is_default == 1
            );
            this.selectedColor = intSelected[0];
          }
          ONE3D.changeVariant(this.selectedColor.threed_code);
        }
        if (this.colorList.length == 0) {
          console.log(this.checkRoute);
          if (this.checkRoute.includes('/home')) {
            document.body.classList.add(
              'one3d-colors-in-interior-view-not-available'
            );
          } else {
            document.body.classList.remove(
              'one3d-colors-in-interior-view-not-available'
            );
          }
        }
      }
      // });
    }
  }

  resetPosition() {
    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      ONE3D.toggleDoors();
    }
    if (this.headLampOn) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofOn) {
      this._global.updateSunRoofState(false);
      ONE3D.toggleSunroof();
    }
    ONE3D.exitFeatureView();
    ONE3D.exitAccessoriesView();
    ONE3D.exteriorView();
  }

  onClickEndCall() {
    this.toggleEndCallModal = true;
  }
  onModalDismiss(ev: string) {
    if (ev == 'continue') {
      this.endCall();
      this.toggleEndCallModal = false;
    } else {
      this.toggleEndCallModal = false;
    }
  }

  hideVideDiv() {
    let videDiv = document.getElementById('video');
    if (videDiv) videDiv.style.display = 'none';
  }
  showOrHideStreamDiv(id: string, show: boolean) {
    document.getElementById('video').style.display = show ? 'block' : 'none';
    let streamDiv = document.getElementById(`remote_video_panel_${id}`);
    if (streamDiv) {
      streamDiv.style.display = show ? 'block' : 'none';
    }
  }
  onSeatingOptionChange(seating: string) {
    console.log(seating);
    this._data.setSeatingInfo(seating);
    if (seating == '5') {
      this._global.updateHectorSeatingOption(hectorPlusSeating.fiveSeater);
      // this.router.navigate(['/sound-check'], {
      //   queryParams: { car: 'hector' },
      // });
      this.router.navigate(['/hector/home']);
      this._global.updateCarExchangePrice('');
      sessionStorage.removeItem('p_');
      sessionStorage.removeItem('v_');
      sessionStorage.removeItem('c_');
    }
    if (seating == '6') {
      this._global.updateHectorSeatingOption(hectorPlusSeating.sixSeater);
      this._global.updateCarExchangePrice('');
    }
    if (seating == '7') {
      this._global.updateHectorSeatingOption(hectorPlusSeating.sevenSeater);
      this._global.updateCarExchangePrice('');
    }
  }

  isVariantPage() {
    return window.location.hash.includes('/variants');
  }
  gotoCarExchangePage() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/car-exchange`]);
    this._global.rightPanelHideShow(true);
  }
  homePageCheck() {
    return window.location.hash.includes('home') ? false : true;
  }
  homePageColorCheck() {
    return window.location.hash.includes('home') ||
      window.location.hash.includes('self-explore') ||
      window.location.hash.includes('variants') ||
      window.location.hash.includes('accessorize')
      ? true
      : false;
  }
  /**************checking route event @JP and clearing the finance checkbox selection **************/
  async navPanelModelSel(url) {
    if (url.includes('/home')) {
      console.log('CONDITION MET');
      document.body.classList.add('one3d-home-page-open');
    } else {
      console.log('CONDITION NOT MET');
      document.body.classList.remove('one3d-home-page-open');
      document.body.classList.remove(
        'one3d-colors-in-interior-view-not-available'
      );
    }
  }
  /*************checking route event @JP and clearing the finance checkbox selection ***************/

  getAppointmentDataByPin(pin) {
    // return new Promise((res, rej) => {
    console.log('pin_no', pin);
    let apiData = {
      source: 'web',
      pin_no: pin,
    };
    this._api.getBuildDetails(apiData).then(
      (data: any) => {
        console.log('data', data);
        if (data.status == 1) {
          let userInfo = JSON.parse(atob(data.response.customer_info));
          this._data.setUserDataInfo(userInfo);
          this._global.updateDealerDetailsToken(data.response.dealer_details);
          this._data.setDealerDetails(data.response.dealer_details);
          this._global.togglePulseHubEnqForm(true);
          this.pinSummaryData = data.response.summary;
          this._data.setLocalVariant(this.pinSummaryData.variant_id);
          this._data.setLocalColor(this.pinSummaryData.variant_feature_id);
          // res(true);
        } else {
          this._snack.openSnackBar(data.msg, 'Error');
          // res(false);
        }
      },
      (err) => {
        console.error('err', err);
        // rej(err);
      }
    );
    // });
  }

  closeSummaryPage() {
    if (this._global.detectMob()) {
      this._global.updateHide3D(false);
    }
    this._global.rightPanelHideShow(false);
    this.location.back();
  }

   
  async getLocation() {
    let result = await this._api.getLocation();
    console.log("The result is", result);
    if (result != null || result != undefined) {
      this.pincode = result;
      this._ga.sendGAEvent('Form_PIN', 'click', 'Locate_Me');
    } else {
      //Disable the get location button 
      this.isLocationBtnDisable = true;
    }
  }

  async submitPincode() {
    this.isPinSubmitted = true;
    if (this.pincodeForm.valid) {
      this._ga.sendGAEvent('Form_PIN', 'click', 'Pin_Confirm');
      this.fetchDealersViaPin();

    } else {
      //Handle the error. 
    }
  }
  async fetchDealersViaPin() {
    let obj = { "pincode": this.pincode, model_id: defaultCarDetails[this.loadedCar].model_id }
    let result: any;
    result = await this._api.getPincodeWiseDealers(obj);
    // console.log("result", result);
    if (result?.status == 1 && result?.response.length > 0) {
      console.log("Setting false");
      this.dealerList = [];
      this.noDataForPin=false;
      result.response.forEach(element => {
        this.dealerList.push(element);
      });
      this.isVisible[0] = false;
      this.isVisible[1] = true;
      let obj = {
        arr: this.isVisible,
        pincode: { flag: true, val: this.pincode },
        source:this.source
      }
      localStorage.setItem("pincodeFlowProgress", JSON.stringify(obj));
    } else {
      console.log("Setting true");
      this.noDataForPin=true;
      this._ga.sendGAEvent('Form_INFO', 'click', 'PIN_Error');
      //No data for the pincode
    }
  }


  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    this.noDataForPin=false;
    return true
  }

  selectDealer(dealer: any) {
    this.selectedDealer = dealer.dealer_id;
    // console.log(this.selectedDealer);
    // Save the selected item to localStorage
    this.selectedDealerObj = dealer;
    localStorage.setItem('selectedDealer', JSON.stringify(dealer));
    this.isVisible[0] = false;
    this.isVisible[1] = false;
    this.isVisible[2] = true;
    this.isVisible[3] = false;
    let obj = {
      arr: this.isVisible,
      pincode: { flag: true, val: this.pincode },
      dealer: { flag: true, val: dealer, details: dealer },
      source:this.source
    }
    this._ga.sendGAEvent('Form_Dealer', 'click', dealer?.company_name);
    localStorage.setItem("pincodeFlowProgress", JSON.stringify(obj));
  }

  onChangeMobileNo() {
    if (this.queryForm.get('mobile').valid) {
      if (this.oldNo != this.queryForm.value.mobile) {
        this.enableSendOTPButton = true;
        this.oldNo = this.queryForm.value.mobile;
        this.enableTimer = false;
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    (this.queryForm.controls[controlName].touched ||
      this.queryForm.controls[controlName].dirty) &&
    this.queryForm.controls[controlName].hasError(errorName);


    async onSubmit() {
      // $e.preventDefault();
      try {
        console.log('this.queryForm.value', this.queryForm.value);
        if (this.queryForm.valid) {
          clearInterval(this.interval);
          this.showResendOtpCounter = false;
          this.countdown = 30;
          let apiData = {
            mobile: this.queryForm.value.mobile.toString(),
            // otp: this.queryForm.value.otp.toString(),
            otp:null,
            pin_no: this._data.getLocalPinNo(),
            customer_name: this.queryForm.value.name,
            source: 'web',
            dealer_id: JSON.parse(localStorage.getItem("selectedDealer")) ? JSON.parse(localStorage.getItem("selectedDealer")).dealer_id : null,
            pincode: this.pincode? this.pincode:null,
            virtual_tour: this.queryForm.value.dealerAssit? 1: 0
          };
          console.log("My API data", apiData);
          let resp: string = await this._api.customerRegistrationWOOTP(apiData);
          let apiRespData: any = JSON.parse(atob(resp));
          console.log("My response", apiRespData);
          if (apiRespData.status == 1) {
            this._data.setUserDataInfo(apiRespData.response);
            sessionStorage.setItem('mobile', this.queryForm.value.phone);
            this.failedVerifiedOpt = false;
            // let obj = {
            //   arr:this.isVisible,
            //   pincode: {flag:true, val:this.pincode},
            //   dealer: {flag:true, val:dealer}
            // }
            this.isVisible[0] = false;
            this.isVisible[1] = false;
            this.isVisible[2] = false;
            this.isVisible[3] = true;
            if (localStorage.getItem("pincodeFlowProgress") != null || localStorage.getItem("pincodeFlowProgress") != undefined) {
              let obj = JSON.parse(localStorage.getItem("pincodeFlowProgress"))
              obj.otp = {};
              obj.otp.flag = true;
              obj.otp.val= apiData;
              obj.arr = this.isVisible;
              console.log("My obj", obj);
              this.apiDataObj = apiData;
              this._ga.sendGAEvent('Form_INFO', 'click', 'Confirm');
              localStorage.setItem("pincodeFlowProgress", JSON.stringify(obj));
            }
            // this.gotoHomePage();
            // this._ga.sendGAEvent('Lead', 'click', 'confirm');
            // window.sendData({
            //   eName: 'click',
            //   eCat: 'intro_video_lead_form',
            //   eAct: 'submit_button_clicked',
            //   eVal: 'success',
            // });
          } else {
            this.failedVerifiedOpt = true;
            this._ga.sendGAEvent('Form_INFO', 'click', 'PIN_Error');
            // window.sendData({
            //   eName: 'click',
            //   eCat: 'intro_video_lead_form',
            //   eAct: 'submit_button_clicked',
            //   eVal: 'fail',
            // });
          }
        } else {
          console.log('Here 2', this.queryForm);
          Object.keys(this.queryForm.controls).forEach((field) => {
            const control = this.queryForm.get(field);
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
          });
        }
      } catch (error) { }
    }

  async sendOTP() {
    try {
      let payload = { mobile: this.queryForm.value.mobile, sourec: 'web' };
      let resp: string = await this._api.sendOtp(payload);
      // window.sendData({
      //   eName: 'click',
      //   eCat: 'intro_video_lead_form',
      //   eAct: 'send_otp_button_clicked',
      // });
      let apiResp: any = JSON.parse(atob(resp));
      console.log('resp', apiResp);
      if (apiResp.status == 1) {
        this.enableSendOTPButton = false;
        this.enableTimer = true;
        this.showOTPField = true;
        this.showResendOtpCounter = true;
        this.countdown = 30;
        this.interval = setInterval(() => {
          this.resendOtpCounter();
        }, 1000);
        // this._ga.sendGAEvent('Lead', 'click', 'send otp');
        this._ga.sendGAEvent('Form_INFO', 'click', 'Send_OTP');        this.showOTPField = true;
      }
    } catch (error) {
      console.error(error);
    }
  }
  resendOtpCounter() {
    if (this.countdown == 0) {
      clearInterval(this.interval);
      this.showResendOtpCounter = false;
    } else {
      // this.showResendOtpCounter = true;
      this.countdown--;
    }
  }

  // /**
  //  * @Author JP
  //  * @param e iAgree value
  //  * used to make the Submit button enable & disable based on iAgree value
  //  */
  onCheckboxChange(e) {
    console.log(e, 'checkboxEvent');
    console.log(this.queryForm);
    // if (this.queryForm.value.name!= '' && this.queryForm.value.mobile.length==10 && this.queryForm.value.otp!=''){
    if (!e && this.queryForm.valid) {
      this.queryForm.setErrors({ 'invalid': true });
    } else if (e && this.queryForm.invalid) {
      this.queryForm.setErrors(null);
      console.log(this.queryForm);
    }
    // }
  }

  goBack(val: string) {
    if (val == "pincode") {
      this.isVisible[0] = true;
      this.isVisible[1] = false;
      this.isVisible[2] = false;
      this.isVisible[3] = false;
      this._ga.sendGAEvent('Form_Dealer', 'click', 'Edit_Pin');
    }
    if (val == "dealerList") {
      if (this.dealerList.length > 0) {
        this.isVisible[0] = false;
        this.isVisible[1] = true;
        this.isVisible[2] = false;
        this.isVisible[3] = false;
        this._ga.sendGAEvent('Form_INFO', 'click', 'Back');
      } else {
        this.fetchDealersViaPin();
        this.isVisible[0] = false;
        this.isVisible[1] = true;
        this.isVisible[2] = false;
        this.isVisible[3] = false;
      }
    }
  }

  checkWhichDivToShow() {
    if (localStorage.getItem("pincodeFlowProgress") != null || localStorage.getItem("pincodeFlowProgress") != undefined) {
      let progress = JSON.parse(localStorage.getItem("pincodeFlowProgress"));
      console.log("My progress", progress);
      const hasPincode = 'pincode' in progress && progress.pincode?.flag;
      const hasDealer = 'dealer' in progress && progress.dealer?.flag;
      const hasOTP = 'dealer' in progress && progress.otp?.flag;
      if (hasOTP) {
        return { otp: { flag: true, val:progress.otp?.val}, dealer: { flag: true, val: progress.dealer?.val }, pincode: { flag: true, val: progress.pincode?.val } }
      } else
        if (hasPincode && hasDealer) {
          return { dealer: { flag: true, val: progress.dealer?.val }, pincode: { flag: true, val: progress.pincode?.val } }
        } else if (hasPincode) {
          return { pincode: { flag: true, val: progress.pincode?.val } }
        } else {
          return false;
        }
    }
  }

  continue() {
    this.isVisible[3] = false;
    this._ga.sendGAEvent('Form_INFO', 'click', 'Continue');
  }

  closePopup(val){
    this.isVisible[val]=false;
    if (val == 0){
      this._ga.sendGAEvent('Form_PIN', 'click', 'Close_Form');
    }
    if (val == 1){
      this._ga.sendGAEvent('Form_Dealer', 'click', 'Close_Form');
    }
    if (val == 2){
      this._ga.sendGAEvent('Form_INFO', 'click', 'Close_Form');
    }
  }
}
