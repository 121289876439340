<div class="one3d-micro-wrap-popup" *ngIf="hideMicrophoneGif">
    <div class="one3d-popup-wrap-box">
        <h2>WE NEED MICROPHONE PERMISSION TO CONTINUE</h2>
        <p class="one3d-popup-wrap-box-subheading">Don’t miss out on</p>
        <div class="one3d-list-plan">
            <div class="one3d-single-list">
                <img src="assets/images/one3d-car-rupee-icon.svg">
                <p>On-road price in your state</p>
            </div>
            <div class="one3d-single-list">
                <img src="assets/images/one3d-emi-icon.svg">
                <p>Financing plans</p>
            </div>
            <div class="one3d-single-list">
                <img src="assets/images/one3d-black-car.svg">
                <p>Personalised walkthrough</p>
            </div>
        </div>
        <div class="one3d-buttons-wrap">
            <div class="one3d-red-button" >
                <a (click)="showMicrophoneGif()">See how to allow mic access</a>
            </div>
            <div class="one3d-exit-button" (click)="exit()">
                <a >Skip & Exit</a>
            </div>
        </div>
    </div>
    <div class="one-3d-overlay"></div>
</div>

<!-- second-popup -->

<div class="one3d-mic-access-popup" *ngIf="!hideMicrophoneGif" >
    <div class="one3d-mic-access-wrap-box">
        <div class="one3d-continue-arrow">
            <img [src]="gifUrl">
            <h3>ALLOW MIC ACCESS TO CONTINUE</h3>
            <p>Here’s how <img src="assets/images/one3d-down-arrow.svg"></p>
        </div>
        <div class="one3d-listing">
            <ul class="one3d-mic-permission">
                <li>Click on <img src="assets/images/one3d-lock-icon.svg"> or <img
                        src="assets/images/one3d-aa-icon.svg"> next to the URL</li>
                <li>Click on Permissions or Website Settings</li>
                <li>Enable your Microphone</li>
            </ul>
        </div>
        <div class="one3d-buttons-wrap">
            <div class="one3d-exit-button" (click)="exit()">
                <a>Exit</a>
            </div>
        </div>
    </div>
    <div class="one-3d-overlay"></div>
</div>