<!--bottom-->
<div class="fg secObjectlbtm" *ngIf="showAppointmentForm==true">
  <div class="fg secObjectlbtminner new-main-schedule-live-consultation">
    <!-- <div class="secObjectlbtmClose" (click)="closeModal()"><a><img src="./assets/images/Close.svg"></a></div> -->

    <div class="one3d-slc-popup-header">
      <div class="one3d-slc-popup-heading">
        When can we talk?
      </div>
      <div class="secObjectlbtmClose" (click)="closeModal('Schedule')"><a><img src="./assets/images/Close.svg"></a></div>
    </div> 
    <!-- <h4>
      <strong>When can we talk?</strong>
      <span
        class="like-to-join-expert-oops-close"
        (click)="closeAppointmentForm()"
        *ngIf="!isScheduleButtonClick"
      >
        <img src="./assets/images/cross-white.svg"
      /></span>
    </h4> -->
    <form [formGroup]="queryForm">
      <div class="secObjectlbtmform">
        <div class="user-details-wrapper">
          <div class="details-title">
           Name
            <span class="details-title-icon">
              <img src="./assets/images/one3d-user-red-icon.svg" />
            </span>
            
          </div>
          <div class="details-value">
            {{this.queryForm.value.name}}
          </div>
        </div>
        <div class="user-details-wrapper">
          <div class="details-title">
            Number
            <span class="details-title-icon">
              <img src="./assets/images/one3d-phone-red-icon.svg" />
            </span>
          
          </div>
          <div class="details-value">
            {{this.queryForm.value.mobile}}
          </div>
        </div>
        <!--input-->
       
        <div
          class="floating-label coobjectinputlable"
          [ngClass]="{
            errmessageredinput: hasError('email', 'required')
          }"
        >
          <input
            class="floating-input coobjectinput"
            type="text"
            maxlength="100"
            formControlName="email"
            placeholder="Enter Email"
          />
          <label>Email (Optional)</label>
          <div class="one3d-static-copy">
            Your email will let us send you the link to the call
          </div>
          <div
            class="fg errmessagered"
            *ngIf="hasError('email', 'pattern')"
          >
            Email is not correct
          </div>
        </div>
        <!--input-->
        <div class="new-date-time-schedule-container">
          <div class="new-date-time-schedule-wrapper">
            <div class="new-date-time-schedule-left">
              <div class="floating-label coobjectinputlable">
                <label>Select Date *</label>
                <input
                  matInput
                  [matDatepicker]="picker"
                  [min]="minDate"
                  [max]="maxDate"
                  formControlName="selectedDate"
                  class="coobjectinput"
                  placeholder="DD/MM/YY"
                />
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <span class="new-date-time-icon">
                  <img src="./assets/images/new-date-icon-red.svg" />
                </span>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('selectedDate', 'required')"
                >
                  Please select date
                </div>
              </div>
            </div>
            <div class="new-date-time-schedule-right">
              <div class="floating-label coobjectinputlable one3d-form-option-wrapper">
                <select
                  id="schedule-time"
                  formControlName="selectedTime"
                  placeholder="HH:MM"
                  class="one3d-form-control-select coobjectinput"
                >
                  <option
                    value=""
                    selected=""
                    disabled="true"
                    value=""
                  ></option>
                  <option [value]="item.value" *ngFor="let item of timeSlots">{{item.text}}</option>
                </select>
                <label>Select Time *</label>
                <span class="new-date-time-icon">
                  <img src="./assets/images/new-time-icon-red.svg" />
                </span>
                <!-- <span class="new-arrow-down-icon">
                  <img src="./assets/images/new-arrow-down.svg" />
                </span> -->
                <span
                  class="new-time-placeholder"
                  [hidden]="queryForm.value.selectedTime"
                >
                  HH:MM
                </span>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('selectedTime', 'required')"
                >
                  Please select time
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="new-terms-conditions-checkbox-wrapper">
          <label class="terms-checkbox-container"
            >I agree to the
            <a
              href="javascript:void(0)"
              (click)="openTancCpopup()"
              class="terms-read-faqs"
              >Terms & Conditions</a
            >
            <input
              type="checkbox"
              checked="checked"
              [(ngModel)]="isChecked"
              [ngModelOptions]="{ standalone: true }"
            />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="fg secObjectlbtmformbtn">
          <div class="secObjectlbtmformbtnright">
            <button
              class="submitbuttonobject2 w-100 one3d-schedule-a-call-btn"
              type="button"
              (click)="onSubmit()"
              [disabled] = "!isChecked"
            >
              Schedule a Call
            </button>
          </div>
        </div>
        <div class="fg secObjectlbtmformbtn one3d-connect-now-new-btn-wrap" *ngIf="showConnectButton">
          <div class="secObjectlbtmformbtnright ">
            <button
              class="submitbuttonobject w-100 one3d-connect-now-new-btn"
              type="button"
              (click)="connectNow('schedule')"
              [disabled] = "!isChecked"
            >
              Connect Now
            </button>
          </div>
        </div>
      </div>
    </form>
    <!--secObjectlbtmform-->
  </div>
</div>
<div class="fg secObjectlbtm" *ngIf="!showAppointmentForm">
  <div class="fg secObjectlbtminner new-main-schedule-live-consultation">
    <div class="one3d-slc-popup-header">
      <div class="one3d-slc-popup-heading">
        Welcome {{this.queryForm.value.name}}
      </div>
      <div class="secObjectlbtmClose" (click)="closeModal('Connect now')"><a><img src="./assets/images/Close.svg"></a></div>
      <div class="one3d-slc-popup-sub-heading">
        This will be a call from your browser. I am ready when you are!
      </div>
    </div> 
    <div class="secObjectlbtmform">
      <div class="fg secObjectlbtmformbtn">
        <div class="secObjectlbtmformbtnright">
          <!-- <button
            class="submitbuttonobject2 w-100 one3d-schedule-a-call-btn"
            type="button"
            (click)="connectNow('connect')"
          >
            Connect Now 
          </button> -->
          <button
            class="submitbuttonobject2 w-100 one3d-schedule-a-call-btn"
            type="button"
            (click)="connectNow('schedule')"
          >
            Connect Now 
          </button>
        </div>
      </div>
      <div class="fg secObjectlbtmformbtn one3d-connect-now-new-btn-wrap" *ngIf="showConnectButton">
        <div class="secObjectlbtmformbtnright ">
          <button
            class="submitbuttonobject w-100 one3d-connect-now-new-btn"
            type="button"
            (click)="showAppointmentForm=true;logGA()"
          >
            Schedule a call for later
          </button>
        </div>
      </div>
    </div>
    <!--secObjectlbtmform-->
  </div>
</div>
<!--bottom-->
<div class="secObject-overlay"></div>
