export const resp = {
  "status": 1,
  "message": "success",
  "response": {
    "packages": [
      {
        "pack_id": 1,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Serenity Lush Pack",
        "flag": "Add",
        "pack_description": "Serenity Lush Pack",
        "pack_part_number": "KIT0018-A",
        "pack_image_url": "Serenity_Pack.png",
        "pack_thumb_url": "Serenity_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "GREEN_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 688,
            "marketing_name": "Body Side Moulding - Painted - GRE",
            "part_number": "A4000195-GRE",
            "description": "",
            "qty": 1,
            "price": 4184,
            "image_url": "",
            "thumb_url": "A4000195-GRE.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_GREEN_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 683,
            "marketing_name": "Hood Garnish - GRE",
            "part_number": "A4000190-GRE",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-GRE.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 761,
            "marketing_name": "Seat Covers - Pastel Green",
            "part_number": "A4000895",
            "description": "",
            "qty": 1,
            "price": 10775,
            "image_url": "",
            "thumb_url": "A4000895.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 743,
            "marketing_name": "Steering Wheel Cover Pastel Green",
            "part_number": "A4000890",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000890.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_GREEN_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 731,
            "marketing_name": "Door Edge Guard - GRE",
            "part_number": "A4000898",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000898.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 726,
            "marketing_name": "Hood Branding - GRE",
            "part_number": "A4000884",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000884.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 713,
            "marketing_name": "Door Handle Garnish - Painted - GRE",
            "part_number": "A4000201-GRE",
            "description": "",
            "qty": 1,
            "price": 1564,
            "image_url": "",
            "thumb_url": "A4000201-GRE.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 708,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - GRE",
            "part_number": "A4000203-GRE",
            "description": "",
            "qty": 1,
            "price": 2939,
            "image_url": "",
            "thumb_url": "A4000203-GRE.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 703,
            "marketing_name": "Skid Plate - Rear - Painted - GRE",
            "part_number": "A4000198-GRE",
            "description": "",
            "qty": 1,
            "price": 3135,
            "image_url": "",
            "thumb_url": "A4000198-GRE.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 698,
            "marketing_name": "Skid Plate - Front - Painted - GRE",
            "part_number": "A4000197-GRE",
            "description": "",
            "qty": 1,
            "price": 2800,
            "image_url": "",
            "thumb_url": "A4000197-GRE.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 693,
            "marketing_name": "ORVM Garnish - Painted - GRE",
            "part_number": "A4000199-GRE",
            "description": "",
            "qty": 1,
            "price": 3812,
            "image_url": "",
            "thumb_url": "A4000199-GRE.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 6,
            "parent_pack_id": 1,
            "allow_custom": 0,
            "pack_name": "Serenity Additional Pack",
            "flag": "Add",
            "pack_description": "Serenity Additional Pack",
            "pack_part_number": "KIT0019-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0019-A",
            "accessoriesList": [
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 168,
                "accessories_id": 736,
                "marketing_name": "Interior Inserts (GRE) KIT",
                "part_number": "A4000862",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000862.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_GREEN_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 11,
            "parent_pack_id": 1,
            "allow_custom": 2,
            "pack_name": "GRAPHICS - KIT 2",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT 2",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 795,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 791,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 787,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 783,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 779,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 775,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 771,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 767,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 10,
            "parent_pack_id": 1,
            "allow_custom": 2,
            "pack_name": "GRAPHICS - KIT 1",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT 1",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 795,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 791,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 787,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 783,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 779,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 775,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 771,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 767,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 14,
            "parent_pack_id": 1,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 754,
                "marketing_name": "Wheel Covers - 3 - GRE",
                "part_number": "A4000861-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 751,
                "marketing_name": "Wheel Covers - 1 - GRE",
                "part_number": "A4000859-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 746,
                "marketing_name": "Wheel Covers - 2 - GRE",
                "part_number": "A4000860-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 2,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Sundowner Lush Pack",
        "flag": "Add",
        "pack_description": "Sundowner Lush Pack",
        "pack_part_number": "KIT0020-A",
        "pack_image_url": "Sundowner_Pack.png",
        "pack_thumb_url": "Sundowner_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "ORANGE_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 699,
            "marketing_name": "Skid Plate - Front - Painted - ORG",
            "part_number": "A4000197-ORG",
            "description": "",
            "qty": 1,
            "price": 2644,
            "image_url": "",
            "thumb_url": "A4000197-ORG.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 759,
            "marketing_name": "Seat Covers - Evening Orange",
            "part_number": "A4000808",
            "description": "",
            "qty": 1,
            "price": 10811,
            "image_url": "",
            "thumb_url": "A4000808.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 741,
            "marketing_name": "Steering Wheel Cover Evening Orange",
            "part_number": "A4000807",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000807.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_ORANGE_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 732,
            "marketing_name": "Door Edge Guard - ORG",
            "part_number": "A4000899",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000899.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 727,
            "marketing_name": "Hood Branding - ORG",
            "part_number": "A4000885",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000885.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 714,
            "marketing_name": "Door Handle Garnish - Painted - ORG",
            "part_number": "A4000201-ORG",
            "description": "",
            "qty": 1,
            "price": 1354,
            "image_url": "",
            "thumb_url": "A4000201-ORG.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 709,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - ORG",
            "part_number": "A4000203-ORG",
            "description": "",
            "qty": 1,
            "price": 2654,
            "image_url": "",
            "thumb_url": "A4000203-ORG.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 704,
            "marketing_name": "Skid Plate - Rear - Painted - ORG",
            "part_number": "A4000198-ORG",
            "description": "",
            "qty": 1,
            "price": 2941,
            "image_url": "",
            "thumb_url": "A4000198-ORG.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 694,
            "marketing_name": "ORVM Garnish - Painted - ORG",
            "part_number": "A4000199-ORG",
            "description": "",
            "qty": 1,
            "price": 3161,
            "image_url": "",
            "thumb_url": "A4000199-ORG.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 689,
            "marketing_name": "Body Side Moulding - Painted - ORG",
            "part_number": "A4000195-ORG",
            "description": "",
            "qty": 1,
            "price": 4350,
            "image_url": "",
            "thumb_url": "A4000195-ORG.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_ORANGE_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 684,
            "marketing_name": "Hood Garnish - ORG",
            "part_number": "A4000190-ORG",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-ORG.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 7,
            "parent_pack_id": 2,
            "allow_custom": 0,
            "pack_name": "Sundowner Additional Pack",
            "flag": "Add",
            "pack_description": "Sundowner Additional Pack",
            "pack_part_number": "KIT0021-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0021-A",
            "accessoriesList": [
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 168,
                "accessories_id": 737,
                "marketing_name": "Interior Inserts (ORG) KIT",
                "part_number": "A4000878",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000878.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_ORANGE_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 11,
            "parent_pack_id": 2,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 796,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 792,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-ORE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-ORE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 788,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 784,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 780,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 776,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-ORE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-ORE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 772,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 768,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 15,
            "parent_pack_id": 2,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 747,
                "marketing_name": "Wheel Covers - 2 - ORG",
                "part_number": "A4000860-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 752,
                "marketing_name": "Wheel Covers - 1 - ORG",
                "part_number": "A4000859-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 755,
                "marketing_name": "Wheel Covers - 3- ORG",
                "part_number": "A4000861-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 3,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Flex Lush Pack",
        "flag": "Add",
        "pack_description": "Flex Lush Pack",
        "pack_part_number": "KIT0024-A",
        "pack_image_url": "Flex_Pack.png",
        "pack_thumb_url": "Flex_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "RED_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 685,
            "marketing_name": "Hood Garnish - RED",
            "part_number": "A4000190-RED",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-RED.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 760,
            "marketing_name": "Seat Covers - Sangria Red",
            "part_number": "A4000809",
            "description": "",
            "qty": 1,
            "price": 10775,
            "image_url": "",
            "thumb_url": "A4000809.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 742,
            "marketing_name": "Steering Wheel Cover Sangria Red",
            "part_number": "A4000822",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000822.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_RED_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 733,
            "marketing_name": "Door Edge Guard - RED",
            "part_number": "A4000900",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000900.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 728,
            "marketing_name": "Hood Branding - RED",
            "part_number": "A4000886",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000886.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 715,
            "marketing_name": "Door Handle Garnish - Painted - RED",
            "part_number": "A4000201-RED",
            "description": "",
            "qty": 1,
            "price": 1317,
            "image_url": "",
            "thumb_url": "A4000201-RED.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 710,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - RED",
            "part_number": "A4000203-RED",
            "description": "",
            "qty": 1,
            "price": 2643,
            "image_url": "",
            "thumb_url": "A4000203-RED.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 705,
            "marketing_name": "Skid Plate - Rear - Painted - RED",
            "part_number": "A4000198-RED",
            "description": "",
            "qty": 1,
            "price": 2908,
            "image_url": "",
            "thumb_url": "A4000198-RED.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 700,
            "marketing_name": "Skid Plate - Front - Painted - RED",
            "part_number": "A4000197-RED",
            "description": "",
            "qty": 1,
            "price": 2649,
            "image_url": "",
            "thumb_url": "A4000197-RED.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 695,
            "marketing_name": "ORVM Garnish - Painted - RED",
            "part_number": "A4000199-RED",
            "description": "",
            "qty": 1,
            "price": 3047,
            "image_url": "",
            "thumb_url": "A4000199-RED.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 690,
            "marketing_name": "Body Side Moulding - Painted - RED",
            "part_number": "A4000195-RED",
            "description": "",
            "qty": 1,
            "price": 4270,
            "image_url": "",
            "thumb_url": "A4000195-RED.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_RED_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          }
        ],
        "additionalPack": [
          {
            "pack_id": 8,
            "parent_pack_id": 3,
            "allow_custom": 0,
            "pack_name": "Flex Additional Pack",
            "flag": "Add",
            "pack_description": "Flex Additional Pack",
            "pack_part_number": "KIT0025-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0025-A",
            "accessoriesList": [
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 168,
                "accessories_id": 738,
                "marketing_name": "Interior Inserts(RED) KIT",
                "part_number": "A4000879",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000879.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_RED_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 12,
            "parent_pack_id": 3,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 797,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 793,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 789,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": ".",
                "thumb_url": "A4000817-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 785,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 781,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 777,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 773,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 769,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 16,
            "parent_pack_id": 3,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 756,
                "marketing_name": "Wheel Covers - 3 - RED",
                "part_number": "A4000861-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 753,
                "marketing_name": "Wheel Covers - 1 - RED",
                "part_number": "A4000859-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 748,
                "marketing_name": "Wheel Covers - 2 - RED",
                "part_number": "A4000860-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 4,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Beach Bae Lush Pack",
        "flag": "Add",
        "pack_description": "Beach Bae Lush Pack",
        "pack_part_number": "KIT0022-A",
        "pack_image_url": "Beach_Bae_Pack.png",
        "pack_thumb_url": "Beach_Bae_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "CYAN_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 740,
            "marketing_name": "Steering Wheel Cover Cool Cyan",
            "part_number": "A4000805",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000805.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_CYAN_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 758,
            "marketing_name": "Seat Covers - Cool Cyan",
            "part_number": "A4000806",
            "description": "",
            "qty": 1,
            "price": 10594,
            "image_url": "",
            "thumb_url": "A4000806.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_CYAN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 687,
            "marketing_name": "Hood Garnish - CYN",
            "part_number": "A4000190-CYN",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-CYN.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 692,
            "marketing_name": "Body Side Moulding - Painted - CYN",
            "part_number": "A4000195-CYN",
            "description": "",
            "qty": 1,
            "price": 4184,
            "image_url": "",
            "thumb_url": "A4000195-CYN.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_CYN_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 697,
            "marketing_name": "ORVM Garnish - Painted - CYN",
            "part_number": "A4000199-CYN",
            "description": "",
            "qty": 1,
            "price": 2923,
            "image_url": "",
            "thumb_url": "A4000199-CYN.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 702,
            "marketing_name": "Skid Plate - Front - Painted - CYN",
            "part_number": "A4000197-CYN",
            "description": "",
            "qty": 1,
            "price": 2587,
            "image_url": "",
            "thumb_url": "A4000197-CYN.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 707,
            "marketing_name": "Skid Plate - Rear - Painted - CYN",
            "part_number": "A4000198-CYN",
            "description": "",
            "qty": 1,
            "price": 2712,
            "image_url": "",
            "thumb_url": "A4000198-CYN.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 712,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - CYN",
            "part_number": "A4000203-CYN",
            "description": "",
            "qty": 1,
            "price": 2595,
            "image_url": "",
            "thumb_url": "A4000203-CYN.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 717,
            "marketing_name": "Door Handle Garnish - Painted - CYN",
            "part_number": "A4000201-CYN",
            "description": "",
            "qty": 1,
            "price": 1277,
            "image_url": "",
            "thumb_url": "A4000201-CYN.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 730,
            "marketing_name": "Hood Branding - CYN",
            "part_number": "A4000888",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000888.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 735,
            "marketing_name": "Door Edge Guard - CNY",
            "part_number": "A4000902",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000902.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 9,
            "parent_pack_id": 4,
            "allow_custom": 0,
            "pack_name": "Beach Bae Additional Pack",
            "flag": "Add",
            "pack_description": "Beach Bae Additional Pack",
            "pack_part_number": "KIT0023-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0023-A",
            "accessoriesList": [
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 168,
                "accessories_id": 739,
                "marketing_name": "Interior Inserts(CYN) KIT",
                "part_number": "A4000880",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000880.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_CYN_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 13,
            "parent_pack_id": 4,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 794,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 790,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 786,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 782,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 778,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 774,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 770,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 17,
            "parent_pack_id": 4,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 757,
                "marketing_name": "Wheel Covers - 3 - CYN",
                "part_number": "A4000861-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 750,
                "marketing_name": "Wheel Covers - 1 - CYN",
                "part_number": "A4000859-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 749,
                "marketing_name": "Wheel Covers - 2 - CYN",
                "part_number": "A4000860-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      }
    ],
    "accessories": [
      {
        "accessories_id": 669,
        "price": "1,996",
        "subgroup_name": "Hood Garnish",
        "marketing_name": "Hood Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_HOOD_CHROME_GARNISH_MESH",
        "tag": "EX_ACC_1",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 156,
        "sort_order": 1,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000190.png",
        "flag": "Add",
        "hotspot_name": "HOOD_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 670,
        "price": "3,737",
        "subgroup_name": "Body Side Moulding",
        "marketing_name": "Body Side Moulding Chrome",
        "mesh": "MG_COMET_EX_ACC_CHROME_BSM_MESH",
        "tag": "EX_ACC_2",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 157,
        "sort_order": 2,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000196.png",
        "flag": "Add",
        "hotspot_name": "BSM_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "178",
        "top_accessories": 0
      },
      {
        "accessories_id": 671,
        "price": "2,157",
        "subgroup_name": "Orvm Garnish",
        "marketing_name": "Orvm Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_ORVM_CHROME_MESH",
        "tag": "EX_ACC_3",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 158,
        "sort_order": 3,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000200.png",
        "flag": "Add",
        "hotspot_name": "ORVM_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 672,
        "price": "2,220",
        "subgroup_name": "Fog Lamp Garnish",
        "marketing_name": "Fog Lamp Garnish Chrome",
        "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GARNISH_MESH",
        "tag": "EX_ACC_6",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 159,
        "sort_order": 4,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000204.png",
        "flag": "Add",
        "hotspot_name": "FOGLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 673,
        "price": "1,110",
        "subgroup_name": "Door Handle Garnish",
        "marketing_name": "Door Handle Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CHROME_GARNISH_MESH",
        "tag": "EX_ACC_7",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 160,
        "sort_order": 5,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000202.png",
        "flag": "Add",
        "hotspot_name": "DOOR_HANDLE_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 674,
        "price": "2,068",
        "subgroup_name": "Hood Branding",
        "marketing_name": "Hood Branding Chrome",
        "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CHROME_MESH",
        "tag": "EX_ACC_8",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 161,
        "sort_order": 6,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000883.png",
        "flag": "Add",
        "hotspot_name": "HOOD_BRANDING_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 675,
        "price": "1,156",
        "subgroup_name": "Weather Deflector",
        "marketing_name": "Weather Deflector",
        "mesh": "MG_COMET_EX_ACC_WIND_DEFLECTOR_MESH",
        "tag": "EX_ACC_9",
        "category": "Essential",
        "is_multiple": 1,
        "subgroup_id": 100,
        "sort_order": 7,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000185.png",
        "flag": "Add",
        "hotspot_name": "WIND_DEFLECTOR_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 676,
        "price": "595",
        "subgroup_name": "Mud Flaps",
        "marketing_name": "Mud Flaps",
        "mesh": "MG_COMET_EX_ACC_MUD_FLAP_MESH",
        "tag": "EX_ACC_10",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 90,
        "sort_order": 8,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000186.png",
        "flag": "Add",
        "hotspot_name": "MUD_FLAP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 677,
        "price": "1,591",
        "subgroup_name": "Head Lamp Garnish",
        "marketing_name": "Head Lamp Garnish",
        "mesh": "MG_COMET_EX_ACC_HEADLAMP_CHROME_GARNISH",
        "tag": "EX_ACC_11",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 44,
        "sort_order": 9,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000187.png",
        "flag": "Add",
        "hotspot_name": "HEADLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 678,
        "price": "1,617",
        "subgroup_name": "Tail Lamp Garnish",
        "marketing_name": "Tail Lamp Garnish",
        "mesh": "MG_COMET_EX_ACC_TAILLAMP_CHROME_GARNISH",
        "tag": "EX_ACC_12",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 51,
        "sort_order": 10,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000188.png",
        "flag": "Add",
        "hotspot_name": "TAILLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 679,
        "price": "1,635",
        "subgroup_name": "Tailgate",
        "marketing_name": "Tailgate Chrome",
        "mesh": "MG_COMET_EX_ACC_TAILGATE_CHROME_MESH",
        "tag": "EX_ACC_13",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 162,
        "sort_order": 11,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000189.png",
        "flag": "Add",
        "hotspot_name": "TAILGATE_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 680,
        "price": "2,434",
        "subgroup_name": "Wheel Arch Cladding",
        "marketing_name": "Wheel Arch Cladding",
        "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
        "tag": "EX_ACC_14",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 163,
        "sort_order": 12,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000193.png",
        "flag": "Add",
        "hotspot_name": "WHEEL_ARCH_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 681,
        "price": "2,072",
        "subgroup_name": "Lower Side/door Cladding",
        "marketing_name": "Lower Side/Door Cladding",
        "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
        "tag": "EX_ACC_15",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 164,
        "sort_order": 13,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000194.png",
        "flag": "Add",
        "hotspot_name": "DOOR_CLADDING_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 682,
        "price": "2,582",
        "subgroup_name": "3D Mats",
        "marketing_name": "3D Mats",
        "mesh": "MG_COMET_IN_ACC_3D_MATS_MESH",
        "tag": "IN_ACC_1",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 165,
        "sort_order": 14,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000205.png",
        "flag": "Add",
        "hotspot_name": "3D_MAT_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 762,
        "price": "xx,xx,xxx",
        "subgroup_name": "Integrated Roof Carrier",
        "marketing_name": "Integrated Roof Carrier",
        "mesh": "MG_COMET_EX_ACC_ROOF_CARRIER_MESH",
        "tag": "EX_ACC_21",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 181,
        "sort_order": 18,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000882.png",
        "flag": "Add",
        "hotspot_name": "ROOF_CARRIER_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 763,
        "price": "xx,xx,xxx",
        "subgroup_name": "Interior Organiser Front ",
        "marketing_name": "Interior Organiser Front ",
        "mesh": "MG_COMET_IN_ACC_INTERIOR_ORGANIZER_MESH",
        "tag": "IN_ACC_19",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 182,
        "sort_order": 19,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000903.png",
        "flag": "Add",
        "hotspot_name": "INT_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 764,
        "price": "xx,xx,xxx",
        "subgroup_name": "Dash Board Organizer",
        "marketing_name": "Dash Board Organizer",
        "mesh": "MG_COMET_IN_ACC_DASH_ORGANIZER_MESH",
        "tag": "IN_ACC_20",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 183,
        "sort_order": 20,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000904.png",
        "flag": "Add",
        "hotspot_name": "DASHBOARD_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 799,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Blossom",
        "mesh": "MG_COMET_EX_SBLOSSOM_ONE_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000928.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 798,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Astronaut",
        "mesh": "MG_COMET_EX_SPACE_TWO_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000927.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 745,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Space",
        "mesh": "MG_COMET_EX_SPACE_ONE_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000926.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "169,157",
        "top_accessories": 0
      },
      {
        "accessories_id": 744,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Floresta",
        "mesh": "MG_COMET_EX_FLORESTA_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000925.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "169,157",
        "top_accessories": 0
      },
      {
        "accessories_id": 821,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "NIGHT CAFÉ",
        "mesh": "MG_COMET_EX_NIGHTCAFE_PINK_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000923.png",
        "thumb_url": "A4000923.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 822,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "EMOTICON",
        "mesh": "MG_COMET_EX_SKULL_GRAPHICS_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000924.png",
        "thumb_url": "A4000924.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 820,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "YOUTH",
        "mesh": "MG_COMET_EX_YOUTH_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000922.png",
        "thumb_url": "A4000922.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 802,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000865.png",
        "thumb_url": "A4000865.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 819,
        "price": "xx,xx,xxx",
        "subgroup_name": "Storage organizer",
        "marketing_name": "Arm rest with storage organizer",
        "mesh": "Arm rest with storage organizer",
        "tag": "Arm rest with storage organizer",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 193,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000906.png",
        "thumb_url": "A4000906.png",
        "flag": "Add",
        "hotspot_name": "Storage_organizer",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 801,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000864.png",
        "thumb_url": "A4000864.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 800,
        "price": "2,946",
        "subgroup_name": "Carpet Mat",
        "marketing_name": "Carpet Mat",
        "mesh": "Carpet Mat",
        "tag": "Carpet Mat",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 186,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000207.png",
        "thumb_url": "A4000207.png",
        "flag": "Add",
        "hotspot_name": "Carpet_Mat",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 815,
        "price": "xx,xx,xxx",
        "subgroup_name": "SUN SHADE",
        "marketing_name": "Sun Shade - 2 Doors",
        "mesh": "SUN SHADE - 2 Doors",
        "tag": "SUN SHADE - 2 Doors",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 187,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000215.png",
        "thumb_url": "A4000215.png",
        "flag": "Add",
        "hotspot_name": "SUN_SHADE",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 813,
        "price": "591",
        "subgroup_name": "Screen Guard",
        "marketing_name": "Screen Guard  - Infotainment ",
        "mesh": "Screen Guard  - Infotainment ",
        "tag": "Screen Guard  - Infotainment ",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 188,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000824.png",
        "thumb_url": "A4000824.png",
        "flag": "Add",
        "hotspot_name": "Screen_Guard",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 814,
        "price": "35,999",
        "subgroup_name": "POWER STATION",
        "marketing_name": "PORTABLE POWER STATION",
        "mesh": "AUXILIARY BATTERY PACK",
        "tag": "AUXILIARY BATTERY PACK",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 189,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000833.png",
        "thumb_url": "A4000833.png",
        "flag": "Add",
        "hotspot_name": "POWER_STATION",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 816,
        "price": "xx,xx,xxx",
        "subgroup_name": "Vacuum cleaner",
        "marketing_name": "3-in-1 Wireless Vacuum cleaner",
        "mesh": "3-in-1 Wireless Vacuum cleaner",
        "tag": "3-in-1 Wireless Vacuum cleaner",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 190,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000829.png",
        "thumb_url": "A4000829.png",
        "flag": "Add",
        "hotspot_name": "Vacuum_cleaner",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 817,
        "price": "xx,xx,xxx",
        "subgroup_name": "Quick Charger",
        "marketing_name": "Quick Charger",
        "mesh": "Quick Charger",
        "tag": "Quick Charger",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 191,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000832.png",
        "thumb_url": "A4000832.png",
        "flag": "Add",
        "hotspot_name": "Quick_Charger",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 818,
        "price": "xx,xx,xxx",
        "subgroup_name": "Charging cable",
        "marketing_name": "4 in 1 charging cable",
        "mesh": "4 in 1 charging cable",
        "tag": "4 in 1 charging cable",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 192,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000876.png",
        "thumb_url": "A4000876.png",
        "flag": "Add",
        "hotspot_name": "Charging_cable",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 803,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Soft TPU(RED)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(RED)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(RED)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000866.png",
        "thumb_url": "A4000866.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 804,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Soft TPU (CYN)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU (CYN)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU (CYN)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000867.png",
        "thumb_url": "A4000867.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 805,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(GRE)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(GRE)",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(GRE)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000869.png",
        "thumb_url": "A4000869.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 806,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(ORG) ",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(ORG) ",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(ORG) ",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000870.png",
        "thumb_url": "A4000870.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 807,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(RED)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(RED)",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(RED)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000871.png",
        "thumb_url": "A4000871.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 808,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPUCYN)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPUCYN)",
        "tag": "KEY  COVER - SMART KEY - Soft TPUCYN)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000872.png",
        "thumb_url": "A4000872.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 809,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "mesh": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "tag": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000896.png",
        "thumb_url": "A4000896.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 810,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "mesh": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "tag": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000897.png",
        "thumb_url": "A4000897.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 811,
        "price": "860",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY  COVER - SMART KEY - Leather",
        "mesh": "KEY  COVER - SMART KEY - Leather",
        "tag": "KEY  COVER - SMART KEY - Leather",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000811.png",
        "thumb_url": "A4000811.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 812,
        "price": "860",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Leather",
        "mesh": "KEY COVER - FLIP KEY - Leather",
        "tag": "KEY COVER - FLIP KEY - Leather",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000812.png",
        "thumb_url": "A4000812.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      }
    ],
    "exterior_subgroup": [
      {
        "accessories_id": 669,
        "subgroup_name": "Hood Garnish",
        "category": "Exterior",
        "subgroup_id": 156,
        "thumb_url": "A4000190.png",
        "mesh": "MG_COMET_EX_ACC_HOOD_CHROME_GARNISH_MESH",
        "price": "1,996",
        "marketing_name": "Hood Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_1",
        "hotspot_name": "HOOD_HP",
        "acces_type": "normal",
        "sort_order": 1,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 670,
        "subgroup_name": "Body Side Moulding",
        "category": "Exterior",
        "subgroup_id": 157,
        "thumb_url": "A4000196.png",
        "mesh": "MG_COMET_EX_ACC_CHROME_BSM_MESH",
        "price": "3,737",
        "marketing_name": "Body Side Moulding Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_2",
        "hotspot_name": "BSM_HP",
        "acces_type": "normal",
        "sort_order": 2,
        "type": "3d",
        "image_url": "",
        "explicit_access": "178",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 671,
        "subgroup_name": "Orvm Garnish",
        "category": "Exterior",
        "subgroup_id": 158,
        "thumb_url": "A4000200.png",
        "mesh": "MG_COMET_EX_ACC_ORVM_CHROME_MESH",
        "price": "2,157",
        "marketing_name": "Orvm Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_3",
        "hotspot_name": "ORVM_HP",
        "acces_type": "normal",
        "sort_order": 3,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 672,
        "subgroup_name": "Fog Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 159,
        "thumb_url": "A4000204.png",
        "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GARNISH_MESH",
        "price": "2,220",
        "marketing_name": "Fog Lamp Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_6",
        "hotspot_name": "FOGLAMP_HP",
        "acces_type": "normal",
        "sort_order": 4,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 673,
        "subgroup_name": "Door Handle Garnish",
        "category": "Exterior",
        "subgroup_id": 160,
        "thumb_url": "A4000202.png",
        "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CHROME_GARNISH_MESH",
        "price": "1,110",
        "marketing_name": "Door Handle Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_7",
        "hotspot_name": "DOOR_HANDLE_HP",
        "acces_type": "normal",
        "sort_order": 5,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 674,
        "subgroup_name": "Hood Branding",
        "category": "Exterior",
        "subgroup_id": 161,
        "thumb_url": "A4000883.png",
        "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CHROME_MESH",
        "price": "2,068",
        "marketing_name": "Hood Branding Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_8",
        "hotspot_name": "HOOD_BRANDING_HP",
        "acces_type": "normal",
        "sort_order": 6,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 677,
        "subgroup_name": "Head Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 44,
        "thumb_url": "A4000187.png",
        "mesh": "MG_COMET_EX_ACC_HEADLAMP_CHROME_GARNISH",
        "price": "1,591",
        "marketing_name": "Head Lamp Garnish",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_11",
        "hotspot_name": "HEADLAMP_HP",
        "acces_type": "normal",
        "sort_order": 9,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 678,
        "subgroup_name": "Tail Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 51,
        "thumb_url": "A4000188.png",
        "mesh": "MG_COMET_EX_ACC_TAILLAMP_CHROME_GARNISH",
        "price": "1,617",
        "marketing_name": "Tail Lamp Garnish",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_12",
        "hotspot_name": "TAILLAMP_HP",
        "acces_type": "normal",
        "sort_order": 10,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 679,
        "subgroup_name": "Tailgate",
        "category": "Exterior",
        "subgroup_id": 162,
        "thumb_url": "A4000189.png",
        "mesh": "MG_COMET_EX_ACC_TAILGATE_CHROME_MESH",
        "price": "1,635",
        "marketing_name": "Tailgate Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_13",
        "hotspot_name": "TAILGATE_HP",
        "acces_type": "normal",
        "sort_order": 11,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 680,
        "subgroup_name": "Wheel Arch Cladding",
        "category": "Exterior",
        "subgroup_id": 163,
        "thumb_url": "A4000193.png",
        "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
        "price": "2,434",
        "marketing_name": "Wheel Arch Cladding",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_14",
        "hotspot_name": "WHEEL_ARCH_HP",
        "acces_type": "normal",
        "sort_order": 12,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 681,
        "subgroup_name": "Lower Side/door Cladding",
        "category": "Exterior",
        "subgroup_id": 164,
        "thumb_url": "A4000194.png",
        "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
        "price": "2,072",
        "marketing_name": "Lower Side/Door Cladding",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_15",
        "hotspot_name": "DOOR_CLADDING_HP",
        "acces_type": "normal",
        "sort_order": 13,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 762,
        "subgroup_name": "Integrated Roof Carrier",
        "category": "Exterior",
        "subgroup_id": 181,
        "thumb_url": "A4000882.png",
        "mesh": "MG_COMET_EX_ACC_ROOF_CARRIER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Integrated Roof Carrier",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_21",
        "hotspot_name": "ROOF_CARRIER_HP",
        "acces_type": "normal",
        "sort_order": 18,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "essential_subgroup": [
      {
        "accessories_id": 675,
        "subgroup_name": "Weather Deflector",
        "category": "Essential",
        "subgroup_id": 100,
        "thumb_url": "A4000185.png",
        "mesh": "MG_COMET_EX_ACC_WIND_DEFLECTOR_MESH",
        "price": "1,156",
        "marketing_name": "Weather Deflector",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "EX_ACC_9",
        "hotspot_name": "WIND_DEFLECTOR_HP",
        "acces_type": "normal",
        "sort_order": 7,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 676,
        "subgroup_name": "Mud Flaps",
        "category": "Essential",
        "subgroup_id": 90,
        "thumb_url": "A4000186.png",
        "mesh": "MG_COMET_EX_ACC_MUD_FLAP_MESH",
        "price": "595",
        "marketing_name": "Mud Flaps",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_10",
        "hotspot_name": "MUD_FLAP_HP",
        "acces_type": "normal",
        "sort_order": 8,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 800,
        "subgroup_name": "Carpet Mat",
        "category": "Essential",
        "subgroup_id": 186,
        "thumb_url": "A4000207.png",
        "mesh": "Carpet Mat",
        "price": "2,946",
        "marketing_name": "Carpet Mat",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Carpet Mat",
        "hotspot_name": "Carpet_Mat",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000207.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 815,
        "subgroup_name": "SUN SHADE",
        "category": "Essential",
        "subgroup_id": 187,
        "thumb_url": "A4000215.png",
        "mesh": "SUN SHADE - 2 Doors",
        "price": "xx,xx,xxx",
        "marketing_name": "Sun Shade - 2 Doors",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "SUN SHADE - 2 Doors",
        "hotspot_name": "SUN_SHADE",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000215.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "interior_subgroup": [
      {
        "accessories_id": 682,
        "subgroup_name": "3D Mats",
        "category": "Interior",
        "subgroup_id": 165,
        "thumb_url": "A4000205.png",
        "mesh": "MG_COMET_IN_ACC_3D_MATS_MESH",
        "price": "2,582",
        "marketing_name": "3D Mats",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_1",
        "hotspot_name": "3D_MAT_HP",
        "acces_type": "normal",
        "sort_order": 14,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 763,
        "subgroup_name": "Interior Organiser Front ",
        "category": "Interior",
        "subgroup_id": 182,
        "thumb_url": "A4000903.png",
        "mesh": "MG_COMET_IN_ACC_INTERIOR_ORGANIZER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Interior Organiser Front ",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_19",
        "hotspot_name": "INT_HP",
        "acces_type": "normal",
        "sort_order": 19,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 764,
        "subgroup_name": "Dash Board Organizer",
        "category": "Interior",
        "subgroup_id": 183,
        "thumb_url": "A4000904.png",
        "mesh": "MG_COMET_IN_ACC_DASH_ORGANIZER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Dash Board Organizer",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_20",
        "hotspot_name": "DASHBOARD_HP",
        "acces_type": "normal",
        "sort_order": 20,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 802,
        "subgroup_name": "KEY COVER ",
        "category": "Interior",
        "subgroup_id": 185,
        "thumb_url": "A4000865.png",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "price": "899",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000865.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 12
      },
      {
        "accessories_id": 819,
        "subgroup_name": "Storage organizer",
        "category": "Interior",
        "subgroup_id": 193,
        "thumb_url": "A4000906.png",
        "mesh": "Arm rest with storage organizer",
        "price": "xx,xx,xxx",
        "marketing_name": "Arm rest with storage organizer",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Arm rest with storage organizer",
        "hotspot_name": "Storage_organizer",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000906.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 813,
        "subgroup_name": "Screen Guard",
        "category": "Interior",
        "subgroup_id": 188,
        "thumb_url": "A4000824.png",
        "mesh": "Screen Guard  - Infotainment ",
        "price": "591",
        "marketing_name": "Screen Guard  - Infotainment ",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Screen Guard  - Infotainment ",
        "hotspot_name": "Screen_Guard",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000824.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 814,
        "subgroup_name": "POWER STATION",
        "category": "Interior",
        "subgroup_id": 189,
        "thumb_url": "A4000833.png",
        "mesh": "AUXILIARY BATTERY PACK",
        "price": "35,999",
        "marketing_name": "PORTABLE POWER STATION",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "AUXILIARY BATTERY PACK",
        "hotspot_name": "POWER_STATION",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000833.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 816,
        "subgroup_name": "Vacuum cleaner",
        "category": "Interior",
        "subgroup_id": 190,
        "thumb_url": "A4000829.png",
        "mesh": "3-in-1 Wireless Vacuum cleaner",
        "price": "xx,xx,xxx",
        "marketing_name": "3-in-1 Wireless Vacuum cleaner",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "3-in-1 Wireless Vacuum cleaner",
        "hotspot_name": "Vacuum_cleaner",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000829.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 817,
        "subgroup_name": "Quick Charger",
        "category": "Interior",
        "subgroup_id": 191,
        "thumb_url": "A4000832.png",
        "mesh": "Quick Charger",
        "price": "xx,xx,xxx",
        "marketing_name": "Quick Charger",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Quick Charger",
        "hotspot_name": "Quick_Charger",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000832.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 818,
        "subgroup_name": "Charging cable",
        "category": "Interior",
        "subgroup_id": 192,
        "thumb_url": "A4000876.png",
        "mesh": "4 in 1 charging cable",
        "price": "xx,xx,xxx",
        "marketing_name": "4 in 1 charging cable",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "4 in 1 charging cable",
        "hotspot_name": "Charging_cable",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000876.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "stickers_subgroup": [
      {
        "accessories_id": 799,
        "subgroup_name": "Sticker",
        "category": "Stickers",
        "subgroup_id": 178,
        "thumb_url": "A4000928.png",
        "mesh": "MG_COMET_EX_SBLOSSOM_ONE_GRAPHIC_MESH",
        "price": "15,499",
        "marketing_name": "Blossom",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "EX_ACC_20",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "sort_order": 21,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 7
      }
    ]
  }
}


export const respAdded = {
  "status": 1,
  "message": "success",
  "response": {
    "packages": [
      {
        "pack_id": 1,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Serenity Lush Pack",
        "flag": "Remove",
        "pack_description": "Serenity Lush Pack",
        "pack_part_number": "KIT0018-A",
        "pack_image_url": "Serenity_Pack.png",
        "pack_thumb_url": "Serenity_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "GREEN_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 688,
            "marketing_name": "Body Side Moulding - Painted - GRE",
            "part_number": "A4000195-GRE",
            "description": "",
            "qty": 1,
            "price": 4184,
            "image_url": "",
            "thumb_url": "A4000195-GRE.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_GREEN_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 683,
            "marketing_name": "Hood Garnish - GRE",
            "part_number": "A4000190-GRE",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-GRE.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 761,
            "marketing_name": "Seat Covers - Pastel Green",
            "part_number": "A4000895",
            "description": "",
            "qty": 1,
            "price": 10775,
            "image_url": "",
            "thumb_url": "A4000895.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 743,
            "marketing_name": "Steering Wheel Cover Pastel Green",
            "part_number": "A4000890",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000890.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_GREEN_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 731,
            "marketing_name": "Door Edge Guard - GRE",
            "part_number": "A4000898",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000898.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 726,
            "marketing_name": "Hood Branding - GRE",
            "part_number": "A4000884",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000884.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 713,
            "marketing_name": "Door Handle Garnish - Painted - GRE",
            "part_number": "A4000201-GRE",
            "description": "",
            "qty": 1,
            "price": 1564,
            "image_url": "",
            "thumb_url": "A4000201-GRE.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 708,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - GRE",
            "part_number": "A4000203-GRE",
            "description": "",
            "qty": 1,
            "price": 2939,
            "image_url": "",
            "thumb_url": "A4000203-GRE.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 703,
            "marketing_name": "Skid Plate - Rear - Painted - GRE",
            "part_number": "A4000198-GRE",
            "description": "",
            "qty": 1,
            "price": 3135,
            "image_url": "",
            "thumb_url": "A4000198-GRE.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 698,
            "marketing_name": "Skid Plate - Front - Painted - GRE",
            "part_number": "A4000197-GRE",
            "description": "",
            "qty": 1,
            "price": 2800,
            "image_url": "",
            "thumb_url": "A4000197-GRE.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 693,
            "marketing_name": "ORVM Garnish - Painted - GRE",
            "part_number": "A4000199-GRE",
            "description": "",
            "qty": 1,
            "price": 3812,
            "image_url": "",
            "thumb_url": "A4000199-GRE.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 6,
            "parent_pack_id": 1,
            "allow_custom": 0,
            "pack_name": "Serenity Additional Pack",
            "flag": "Add",
            "pack_description": "Serenity Additional Pack",
            "pack_part_number": "KIT0019-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0019-A",
            "accessoriesList": [
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 168,
                "accessories_id": 736,
                "marketing_name": "Interior Inserts (GRE) KIT",
                "part_number": "A4000862",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000862.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_GREEN_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 11,
            "parent_pack_id": 1,
            "allow_custom": 2,
            "pack_name": "GRAPHICS - KIT 2",
            "flag": "Remove",
            "pack_description": "GRAPHICS - KIT 2",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 795,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 791,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-GRE",
                "description": "",
                "qty": 1,
                "flag": "Remove",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 787,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 783,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-GRE",
                "description": "",
                "qty": 1,
                "flag": "Remove",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 779,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-GRE",
                "description": "",
                "qty": 1,
                "flag": "Remove",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 775,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-GRE",
                "description": "",
                "qty": 1,
                "flag": "Remove",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 771,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-GRE",
                "description": "",
                "qty": 1,
                "flag": "Remove",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 767,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-GRE",
                "description": "",
                "qty": 1,
                "flag": "Remove",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 10,
            "parent_pack_id": 1,
            "allow_custom": 2,
            "pack_name": "GRAPHICS - KIT 1",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT 1",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 795,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 791,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 787,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 783,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 779,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 775,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 771,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 767,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 14,
            "parent_pack_id": 1,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 754,
                "marketing_name": "Wheel Covers - 3 - GRE",
                "part_number": "A4000861-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 751,
                "marketing_name": "Wheel Covers - 1 - GRE",
                "part_number": "A4000859-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 746,
                "marketing_name": "Wheel Covers - 2 - GRE",
                "part_number": "A4000860-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 2,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Sundowner Lush Pack",
        "flag": "Add",
        "pack_description": "Sundowner Lush Pack",
        "pack_part_number": "KIT0020-A",
        "pack_image_url": "Sundowner_Pack.png",
        "pack_thumb_url": "Sundowner_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "ORANGE_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 699,
            "marketing_name": "Skid Plate - Front - Painted - ORG",
            "part_number": "A4000197-ORG",
            "description": "",
            "qty": 1,
            "price": 2644,
            "image_url": "",
            "thumb_url": "A4000197-ORG.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 759,
            "marketing_name": "Seat Covers - Evening Orange",
            "part_number": "A4000808",
            "description": "",
            "qty": 1,
            "price": 10811,
            "image_url": "",
            "thumb_url": "A4000808.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 741,
            "marketing_name": "Steering Wheel Cover Evening Orange",
            "part_number": "A4000807",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000807.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_ORANGE_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 732,
            "marketing_name": "Door Edge Guard - ORG",
            "part_number": "A4000899",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000899.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 727,
            "marketing_name": "Hood Branding - ORG",
            "part_number": "A4000885",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000885.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 714,
            "marketing_name": "Door Handle Garnish - Painted - ORG",
            "part_number": "A4000201-ORG",
            "description": "",
            "qty": 1,
            "price": 1354,
            "image_url": "",
            "thumb_url": "A4000201-ORG.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 709,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - ORG",
            "part_number": "A4000203-ORG",
            "description": "",
            "qty": 1,
            "price": 2654,
            "image_url": "",
            "thumb_url": "A4000203-ORG.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 704,
            "marketing_name": "Skid Plate - Rear - Painted - ORG",
            "part_number": "A4000198-ORG",
            "description": "",
            "qty": 1,
            "price": 2941,
            "image_url": "",
            "thumb_url": "A4000198-ORG.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 694,
            "marketing_name": "ORVM Garnish - Painted - ORG",
            "part_number": "A4000199-ORG",
            "description": "",
            "qty": 1,
            "price": 3161,
            "image_url": "",
            "thumb_url": "A4000199-ORG.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 689,
            "marketing_name": "Body Side Moulding - Painted - ORG",
            "part_number": "A4000195-ORG",
            "description": "",
            "qty": 1,
            "price": 4350,
            "image_url": "",
            "thumb_url": "A4000195-ORG.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_ORANGE_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 684,
            "marketing_name": "Hood Garnish - ORG",
            "part_number": "A4000190-ORG",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-ORG.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 7,
            "parent_pack_id": 2,
            "allow_custom": 0,
            "pack_name": "Sundowner Additional Pack",
            "flag": "Add",
            "pack_description": "Sundowner Additional Pack",
            "pack_part_number": "KIT0021-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0021-A",
            "accessoriesList": [
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 168,
                "accessories_id": 737,
                "marketing_name": "Interior Inserts (ORG) KIT",
                "part_number": "A4000878",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000878.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_ORANGE_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 11,
            "parent_pack_id": 2,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 796,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 792,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-ORE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-ORE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 788,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 784,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 780,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 776,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-ORE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-ORE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 772,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 768,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 15,
            "parent_pack_id": 2,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 747,
                "marketing_name": "Wheel Covers - 2 - ORG",
                "part_number": "A4000860-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 752,
                "marketing_name": "Wheel Covers - 1 - ORG",
                "part_number": "A4000859-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 755,
                "marketing_name": "Wheel Covers - 3- ORG",
                "part_number": "A4000861-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 3,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Flex Lush Pack",
        "flag": "Add",
        "pack_description": "Flex Lush Pack",
        "pack_part_number": "KIT0024-A",
        "pack_image_url": "Flex_Pack.png",
        "pack_thumb_url": "Flex_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "RED_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 685,
            "marketing_name": "Hood Garnish - RED",
            "part_number": "A4000190-RED",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-RED.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 760,
            "marketing_name": "Seat Covers - Sangria Red",
            "part_number": "A4000809",
            "description": "",
            "qty": 1,
            "price": 10775,
            "image_url": "",
            "thumb_url": "A4000809.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 742,
            "marketing_name": "Steering Wheel Cover Sangria Red",
            "part_number": "A4000822",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000822.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_RED_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 733,
            "marketing_name": "Door Edge Guard - RED",
            "part_number": "A4000900",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000900.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 728,
            "marketing_name": "Hood Branding - RED",
            "part_number": "A4000886",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000886.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 715,
            "marketing_name": "Door Handle Garnish - Painted - RED",
            "part_number": "A4000201-RED",
            "description": "",
            "qty": 1,
            "price": 1317,
            "image_url": "",
            "thumb_url": "A4000201-RED.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 710,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - RED",
            "part_number": "A4000203-RED",
            "description": "",
            "qty": 1,
            "price": 2643,
            "image_url": "",
            "thumb_url": "A4000203-RED.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 705,
            "marketing_name": "Skid Plate - Rear - Painted - RED",
            "part_number": "A4000198-RED",
            "description": "",
            "qty": 1,
            "price": 2908,
            "image_url": "",
            "thumb_url": "A4000198-RED.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 700,
            "marketing_name": "Skid Plate - Front - Painted - RED",
            "part_number": "A4000197-RED",
            "description": "",
            "qty": 1,
            "price": 2649,
            "image_url": "",
            "thumb_url": "A4000197-RED.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 695,
            "marketing_name": "ORVM Garnish - Painted - RED",
            "part_number": "A4000199-RED",
            "description": "",
            "qty": 1,
            "price": 3047,
            "image_url": "",
            "thumb_url": "A4000199-RED.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 690,
            "marketing_name": "Body Side Moulding - Painted - RED",
            "part_number": "A4000195-RED",
            "description": "",
            "qty": 1,
            "price": 4270,
            "image_url": "",
            "thumb_url": "A4000195-RED.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_RED_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          }
        ],
        "additionalPack": [
          {
            "pack_id": 8,
            "parent_pack_id": 3,
            "allow_custom": 0,
            "pack_name": "Flex Additional Pack",
            "flag": "Add",
            "pack_description": "Flex Additional Pack",
            "pack_part_number": "KIT0025-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0025-A",
            "accessoriesList": [
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 168,
                "accessories_id": 738,
                "marketing_name": "Interior Inserts(RED) KIT",
                "part_number": "A4000879",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000879.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_RED_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 12,
            "parent_pack_id": 3,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 797,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 793,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 789,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": ".",
                "thumb_url": "A4000817-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 785,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 781,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 777,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 773,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 769,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 16,
            "parent_pack_id": 3,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 756,
                "marketing_name": "Wheel Covers - 3 - RED",
                "part_number": "A4000861-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 753,
                "marketing_name": "Wheel Covers - 1 - RED",
                "part_number": "A4000859-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 748,
                "marketing_name": "Wheel Covers - 2 - RED",
                "part_number": "A4000860-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 4,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Beach Bae Lush Pack",
        "flag": "Add",
        "pack_description": "Beach Bae Lush Pack",
        "pack_part_number": "KIT0022-A",
        "pack_image_url": "Beach_Bae_Pack.png",
        "pack_thumb_url": "Beach_Bae_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "CYAN_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 740,
            "marketing_name": "Steering Wheel Cover Cool Cyan",
            "part_number": "A4000805",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000805.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_CYAN_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 758,
            "marketing_name": "Seat Covers - Cool Cyan",
            "part_number": "A4000806",
            "description": "",
            "qty": 1,
            "price": 10594,
            "image_url": "",
            "thumb_url": "A4000806.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_CYAN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 687,
            "marketing_name": "Hood Garnish - CYN",
            "part_number": "A4000190-CYN",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-CYN.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 692,
            "marketing_name": "Body Side Moulding - Painted - CYN",
            "part_number": "A4000195-CYN",
            "description": "",
            "qty": 1,
            "price": 4184,
            "image_url": "",
            "thumb_url": "A4000195-CYN.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_CYN_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 697,
            "marketing_name": "ORVM Garnish - Painted - CYN",
            "part_number": "A4000199-CYN",
            "description": "",
            "qty": 1,
            "price": 2923,
            "image_url": "",
            "thumb_url": "A4000199-CYN.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 702,
            "marketing_name": "Skid Plate - Front - Painted - CYN",
            "part_number": "A4000197-CYN",
            "description": "",
            "qty": 1,
            "price": 2587,
            "image_url": "",
            "thumb_url": "A4000197-CYN.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 707,
            "marketing_name": "Skid Plate - Rear - Painted - CYN",
            "part_number": "A4000198-CYN",
            "description": "",
            "qty": 1,
            "price": 2712,
            "image_url": "",
            "thumb_url": "A4000198-CYN.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 712,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - CYN",
            "part_number": "A4000203-CYN",
            "description": "",
            "qty": 1,
            "price": 2595,
            "image_url": "",
            "thumb_url": "A4000203-CYN.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 717,
            "marketing_name": "Door Handle Garnish - Painted - CYN",
            "part_number": "A4000201-CYN",
            "description": "",
            "qty": 1,
            "price": 1277,
            "image_url": "",
            "thumb_url": "A4000201-CYN.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 730,
            "marketing_name": "Hood Branding - CYN",
            "part_number": "A4000888",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000888.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 735,
            "marketing_name": "Door Edge Guard - CNY",
            "part_number": "A4000902",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000902.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 9,
            "parent_pack_id": 4,
            "allow_custom": 0,
            "pack_name": "Beach Bae Additional Pack",
            "flag": "Add",
            "pack_description": "Beach Bae Additional Pack",
            "pack_part_number": "KIT0023-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0023-A",
            "accessoriesList": [
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 168,
                "accessories_id": 739,
                "marketing_name": "Interior Inserts(CYN) KIT",
                "part_number": "A4000880",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000880.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_CYN_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 13,
            "parent_pack_id": 4,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 794,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 790,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 786,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 782,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 778,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 774,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 770,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 17,
            "parent_pack_id": 4,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 757,
                "marketing_name": "Wheel Covers - 3 - CYN",
                "part_number": "A4000861-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 750,
                "marketing_name": "Wheel Covers - 1 - CYN",
                "part_number": "A4000859-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 749,
                "marketing_name": "Wheel Covers - 2 - CYN",
                "part_number": "A4000860-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      }
    ],
    "accessories": [
      {
        "accessories_id": 669,
        "price": "1,996",
        "subgroup_name": "Hood Garnish",
        "marketing_name": "Hood Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_HOOD_CHROME_GARNISH_MESH",
        "tag": "EX_ACC_1",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 156,
        "sort_order": 1,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000190.png",
        "flag": "Add",
        "hotspot_name": "HOOD_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 670,
        "price": "3,737",
        "subgroup_name": "Body Side Moulding",
        "marketing_name": "Body Side Moulding Chrome",
        "mesh": "MG_COMET_EX_ACC_CHROME_BSM_MESH",
        "tag": "EX_ACC_2",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 157,
        "sort_order": 2,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000196.png",
        "flag": "Add",
        "hotspot_name": "BSM_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "178",
        "top_accessories": 0
      },
      {
        "accessories_id": 671,
        "price": "2,157",
        "subgroup_name": "Orvm Garnish",
        "marketing_name": "Orvm Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_ORVM_CHROME_MESH",
        "tag": "EX_ACC_3",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 158,
        "sort_order": 3,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000200.png",
        "flag": "Add",
        "hotspot_name": "ORVM_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 672,
        "price": "2,220",
        "subgroup_name": "Fog Lamp Garnish",
        "marketing_name": "Fog Lamp Garnish Chrome",
        "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GARNISH_MESH",
        "tag": "EX_ACC_6",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 159,
        "sort_order": 4,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000204.png",
        "flag": "Add",
        "hotspot_name": "FOGLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 673,
        "price": "1,110",
        "subgroup_name": "Door Handle Garnish",
        "marketing_name": "Door Handle Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CHROME_GARNISH_MESH",
        "tag": "EX_ACC_7",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 160,
        "sort_order": 5,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000202.png",
        "flag": "Add",
        "hotspot_name": "DOOR_HANDLE_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 674,
        "price": "2,068",
        "subgroup_name": "Hood Branding",
        "marketing_name": "Hood Branding Chrome",
        "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CHROME_MESH",
        "tag": "EX_ACC_8",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 161,
        "sort_order": 6,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000883.png",
        "flag": "Add",
        "hotspot_name": "HOOD_BRANDING_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 675,
        "price": "1,156",
        "subgroup_name": "Weather Deflector",
        "marketing_name": "Weather Deflector",
        "mesh": "MG_COMET_EX_ACC_WIND_DEFLECTOR_MESH",
        "tag": "EX_ACC_9",
        "category": "Essential",
        "is_multiple": 1,
        "subgroup_id": 100,
        "sort_order": 7,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000185.png",
        "flag": "Add",
        "hotspot_name": "WIND_DEFLECTOR_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 676,
        "price": "595",
        "subgroup_name": "Mud Flaps",
        "marketing_name": "Mud Flaps",
        "mesh": "MG_COMET_EX_ACC_MUD_FLAP_MESH",
        "tag": "EX_ACC_10",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 90,
        "sort_order": 8,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000186.png",
        "flag": "Add",
        "hotspot_name": "MUD_FLAP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 677,
        "price": "1,591",
        "subgroup_name": "Head Lamp Garnish",
        "marketing_name": "Head Lamp Garnish",
        "mesh": "MG_COMET_EX_ACC_HEADLAMP_CHROME_GARNISH",
        "tag": "EX_ACC_11",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 44,
        "sort_order": 9,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000187.png",
        "flag": "Add",
        "hotspot_name": "HEADLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 678,
        "price": "1,617",
        "subgroup_name": "Tail Lamp Garnish",
        "marketing_name": "Tail Lamp Garnish",
        "mesh": "MG_COMET_EX_ACC_TAILLAMP_CHROME_GARNISH",
        "tag": "EX_ACC_12",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 51,
        "sort_order": 10,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000188.png",
        "flag": "Add",
        "hotspot_name": "TAILLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 679,
        "price": "1,635",
        "subgroup_name": "Tailgate",
        "marketing_name": "Tailgate Chrome",
        "mesh": "MG_COMET_EX_ACC_TAILGATE_CHROME_MESH",
        "tag": "EX_ACC_13",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 162,
        "sort_order": 11,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000189.png",
        "flag": "Add",
        "hotspot_name": "TAILGATE_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 680,
        "price": "2,434",
        "subgroup_name": "Wheel Arch Cladding",
        "marketing_name": "Wheel Arch Cladding",
        "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
        "tag": "EX_ACC_14",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 163,
        "sort_order": 12,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000193.png",
        "flag": "Add",
        "hotspot_name": "WHEEL_ARCH_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 681,
        "price": "2,072",
        "subgroup_name": "Lower Side/door Cladding",
        "marketing_name": "Lower Side/Door Cladding",
        "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
        "tag": "EX_ACC_15",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 164,
        "sort_order": 13,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000194.png",
        "flag": "Add",
        "hotspot_name": "DOOR_CLADDING_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 682,
        "price": "2,582",
        "subgroup_name": "3D Mats",
        "marketing_name": "3D Mats",
        "mesh": "MG_COMET_IN_ACC_3D_MATS_MESH",
        "tag": "IN_ACC_1",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 165,
        "sort_order": 14,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000205.png",
        "flag": "Add",
        "hotspot_name": "3D_MAT_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 762,
        "price": "xx,xx,xxx",
        "subgroup_name": "Integrated Roof Carrier",
        "marketing_name": "Integrated Roof Carrier",
        "mesh": "MG_COMET_EX_ACC_ROOF_CARRIER_MESH",
        "tag": "EX_ACC_21",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 181,
        "sort_order": 18,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000882.png",
        "flag": "Add",
        "hotspot_name": "ROOF_CARRIER_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 763,
        "price": "xx,xx,xxx",
        "subgroup_name": "Interior Organiser Front ",
        "marketing_name": "Interior Organiser Front ",
        "mesh": "MG_COMET_IN_ACC_INTERIOR_ORGANIZER_MESH",
        "tag": "IN_ACC_19",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 182,
        "sort_order": 19,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000903.png",
        "flag": "Add",
        "hotspot_name": "INT_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 764,
        "price": "xx,xx,xxx",
        "subgroup_name": "Dash Board Organizer",
        "marketing_name": "Dash Board Organizer",
        "mesh": "MG_COMET_IN_ACC_DASH_ORGANIZER_MESH",
        "tag": "IN_ACC_20",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 183,
        "sort_order": 20,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000904.png",
        "flag": "Add",
        "hotspot_name": "DASHBOARD_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 799,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Blossom",
        "mesh": "MG_COMET_EX_SBLOSSOM_ONE_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000928.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 798,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Astronaut",
        "mesh": "MG_COMET_EX_SPACE_TWO_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000927.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 745,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Space",
        "mesh": "MG_COMET_EX_SPACE_ONE_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000926.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "169,157",
        "top_accessories": 0
      },
      {
        "accessories_id": 744,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Floresta",
        "mesh": "MG_COMET_EX_FLORESTA_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000925.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "169,157",
        "top_accessories": 0
      },
      {
        "accessories_id": 821,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "NIGHT CAFÉ",
        "mesh": "MG_COMET_EX_NIGHTCAFE_PINK_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000923.png",
        "thumb_url": "A4000923.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 822,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "EMOTICON",
        "mesh": "MG_COMET_EX_SKULL_GRAPHICS_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000924.png",
        "thumb_url": "A4000924.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 820,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "YOUTH",
        "mesh": "MG_COMET_EX_YOUTH_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000922.png",
        "thumb_url": "A4000922.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 802,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000865.png",
        "thumb_url": "A4000865.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 819,
        "price": "xx,xx,xxx",
        "subgroup_name": "Storage organizer",
        "marketing_name": "Arm rest with storage organizer",
        "mesh": "Arm rest with storage organizer",
        "tag": "Arm rest with storage organizer",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 193,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000906.png",
        "thumb_url": "A4000906.png",
        "flag": "Add",
        "hotspot_name": "Storage_organizer",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 801,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000864.png",
        "thumb_url": "A4000864.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 800,
        "price": "2,946",
        "subgroup_name": "Carpet Mat",
        "marketing_name": "Carpet Mat",
        "mesh": "Carpet Mat",
        "tag": "Carpet Mat",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 186,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000207.png",
        "thumb_url": "A4000207.png",
        "flag": "Add",
        "hotspot_name": "Carpet_Mat",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 815,
        "price": "xx,xx,xxx",
        "subgroup_name": "SUN SHADE",
        "marketing_name": "Sun Shade - 2 Doors",
        "mesh": "SUN SHADE - 2 Doors",
        "tag": "SUN SHADE - 2 Doors",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 187,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000215.png",
        "thumb_url": "A4000215.png",
        "flag": "Add",
        "hotspot_name": "SUN_SHADE",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 813,
        "price": "591",
        "subgroup_name": "Screen Guard",
        "marketing_name": "Screen Guard  - Infotainment ",
        "mesh": "Screen Guard  - Infotainment ",
        "tag": "Screen Guard  - Infotainment ",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 188,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000824.png",
        "thumb_url": "A4000824.png",
        "flag": "Add",
        "hotspot_name": "Screen_Guard",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 814,
        "price": "35,999",
        "subgroup_name": "POWER STATION",
        "marketing_name": "PORTABLE POWER STATION",
        "mesh": "AUXILIARY BATTERY PACK",
        "tag": "AUXILIARY BATTERY PACK",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 189,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000833.png",
        "thumb_url": "A4000833.png",
        "flag": "Add",
        "hotspot_name": "POWER_STATION",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 816,
        "price": "xx,xx,xxx",
        "subgroup_name": "Vacuum cleaner",
        "marketing_name": "3-in-1 Wireless Vacuum cleaner",
        "mesh": "3-in-1 Wireless Vacuum cleaner",
        "tag": "3-in-1 Wireless Vacuum cleaner",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 190,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000829.png",
        "thumb_url": "A4000829.png",
        "flag": "Add",
        "hotspot_name": "Vacuum_cleaner",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 817,
        "price": "xx,xx,xxx",
        "subgroup_name": "Quick Charger",
        "marketing_name": "Quick Charger",
        "mesh": "Quick Charger",
        "tag": "Quick Charger",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 191,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000832.png",
        "thumb_url": "A4000832.png",
        "flag": "Add",
        "hotspot_name": "Quick_Charger",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 818,
        "price": "xx,xx,xxx",
        "subgroup_name": "Charging cable",
        "marketing_name": "4 in 1 charging cable",
        "mesh": "4 in 1 charging cable",
        "tag": "4 in 1 charging cable",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 192,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000876.png",
        "thumb_url": "A4000876.png",
        "flag": "Add",
        "hotspot_name": "Charging_cable",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 803,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Soft TPU(RED)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(RED)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(RED)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000866.png",
        "thumb_url": "A4000866.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 804,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Soft TPU (CYN)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU (CYN)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU (CYN)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000867.png",
        "thumb_url": "A4000867.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 805,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(GRE)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(GRE)",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(GRE)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000869.png",
        "thumb_url": "A4000869.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 806,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(ORG) ",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(ORG) ",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(ORG) ",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000870.png",
        "thumb_url": "A4000870.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 807,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(RED)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(RED)",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(RED)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000871.png",
        "thumb_url": "A4000871.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 808,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPUCYN)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPUCYN)",
        "tag": "KEY  COVER - SMART KEY - Soft TPUCYN)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000872.png",
        "thumb_url": "A4000872.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 809,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "mesh": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "tag": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000896.png",
        "thumb_url": "A4000896.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 810,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "mesh": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "tag": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000897.png",
        "thumb_url": "A4000897.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 811,
        "price": "860",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY  COVER - SMART KEY - Leather",
        "mesh": "KEY  COVER - SMART KEY - Leather",
        "tag": "KEY  COVER - SMART KEY - Leather",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000811.png",
        "thumb_url": "A4000811.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 812,
        "price": "860",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Leather",
        "mesh": "KEY COVER - FLIP KEY - Leather",
        "tag": "KEY COVER - FLIP KEY - Leather",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000812.png",
        "thumb_url": "A4000812.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      }
    ],
    "exterior_subgroup": [
      {
        "accessories_id": 669,
        "subgroup_name": "Hood Garnish",
        "category": "Exterior",
        "subgroup_id": 156,
        "thumb_url": "A4000190.png",
        "mesh": "MG_COMET_EX_ACC_HOOD_CHROME_GARNISH_MESH",
        "price": "1,996",
        "marketing_name": "Hood Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_1",
        "hotspot_name": "HOOD_HP",
        "acces_type": "normal",
        "sort_order": 1,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 670,
        "subgroup_name": "Body Side Moulding",
        "category": "Exterior",
        "subgroup_id": 157,
        "thumb_url": "A4000196.png",
        "mesh": "MG_COMET_EX_ACC_CHROME_BSM_MESH",
        "price": "3,737",
        "marketing_name": "Body Side Moulding Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_2",
        "hotspot_name": "BSM_HP",
        "acces_type": "normal",
        "sort_order": 2,
        "type": "3d",
        "image_url": "",
        "explicit_access": "178",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 671,
        "subgroup_name": "Orvm Garnish",
        "category": "Exterior",
        "subgroup_id": 158,
        "thumb_url": "A4000200.png",
        "mesh": "MG_COMET_EX_ACC_ORVM_CHROME_MESH",
        "price": "2,157",
        "marketing_name": "Orvm Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_3",
        "hotspot_name": "ORVM_HP",
        "acces_type": "normal",
        "sort_order": 3,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 672,
        "subgroup_name": "Fog Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 159,
        "thumb_url": "A4000204.png",
        "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GARNISH_MESH",
        "price": "2,220",
        "marketing_name": "Fog Lamp Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_6",
        "hotspot_name": "FOGLAMP_HP",
        "acces_type": "normal",
        "sort_order": 4,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 673,
        "subgroup_name": "Door Handle Garnish",
        "category": "Exterior",
        "subgroup_id": 160,
        "thumb_url": "A4000202.png",
        "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CHROME_GARNISH_MESH",
        "price": "1,110",
        "marketing_name": "Door Handle Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_7",
        "hotspot_name": "DOOR_HANDLE_HP",
        "acces_type": "normal",
        "sort_order": 5,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 674,
        "subgroup_name": "Hood Branding",
        "category": "Exterior",
        "subgroup_id": 161,
        "thumb_url": "A4000883.png",
        "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CHROME_MESH",
        "price": "2,068",
        "marketing_name": "Hood Branding Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_8",
        "hotspot_name": "HOOD_BRANDING_HP",
        "acces_type": "normal",
        "sort_order": 6,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 677,
        "subgroup_name": "Head Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 44,
        "thumb_url": "A4000187.png",
        "mesh": "MG_COMET_EX_ACC_HEADLAMP_CHROME_GARNISH",
        "price": "1,591",
        "marketing_name": "Head Lamp Garnish",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_11",
        "hotspot_name": "HEADLAMP_HP",
        "acces_type": "normal",
        "sort_order": 9,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 678,
        "subgroup_name": "Tail Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 51,
        "thumb_url": "A4000188.png",
        "mesh": "MG_COMET_EX_ACC_TAILLAMP_CHROME_GARNISH",
        "price": "1,617",
        "marketing_name": "Tail Lamp Garnish",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_12",
        "hotspot_name": "TAILLAMP_HP",
        "acces_type": "normal",
        "sort_order": 10,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 679,
        "subgroup_name": "Tailgate",
        "category": "Exterior",
        "subgroup_id": 162,
        "thumb_url": "A4000189.png",
        "mesh": "MG_COMET_EX_ACC_TAILGATE_CHROME_MESH",
        "price": "1,635",
        "marketing_name": "Tailgate Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_13",
        "hotspot_name": "TAILGATE_HP",
        "acces_type": "normal",
        "sort_order": 11,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 680,
        "subgroup_name": "Wheel Arch Cladding",
        "category": "Exterior",
        "subgroup_id": 163,
        "thumb_url": "A4000193.png",
        "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
        "price": "2,434",
        "marketing_name": "Wheel Arch Cladding",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_14",
        "hotspot_name": "WHEEL_ARCH_HP",
        "acces_type": "normal",
        "sort_order": 12,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 681,
        "subgroup_name": "Lower Side/door Cladding",
        "category": "Exterior",
        "subgroup_id": 164,
        "thumb_url": "A4000194.png",
        "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
        "price": "2,072",
        "marketing_name": "Lower Side/Door Cladding",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_15",
        "hotspot_name": "DOOR_CLADDING_HP",
        "acces_type": "normal",
        "sort_order": 13,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 762,
        "subgroup_name": "Integrated Roof Carrier",
        "category": "Exterior",
        "subgroup_id": 181,
        "thumb_url": "A4000882.png",
        "mesh": "MG_COMET_EX_ACC_ROOF_CARRIER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Integrated Roof Carrier",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_21",
        "hotspot_name": "ROOF_CARRIER_HP",
        "acces_type": "normal",
        "sort_order": 18,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "essential_subgroup": [
      {
        "accessories_id": 675,
        "subgroup_name": "Weather Deflector",
        "category": "Essential",
        "subgroup_id": 100,
        "thumb_url": "A4000185.png",
        "mesh": "MG_COMET_EX_ACC_WIND_DEFLECTOR_MESH",
        "price": "1,156",
        "marketing_name": "Weather Deflector",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "EX_ACC_9",
        "hotspot_name": "WIND_DEFLECTOR_HP",
        "acces_type": "normal",
        "sort_order": 7,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 676,
        "subgroup_name": "Mud Flaps",
        "category": "Essential",
        "subgroup_id": 90,
        "thumb_url": "A4000186.png",
        "mesh": "MG_COMET_EX_ACC_MUD_FLAP_MESH",
        "price": "595",
        "marketing_name": "Mud Flaps",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_10",
        "hotspot_name": "MUD_FLAP_HP",
        "acces_type": "normal",
        "sort_order": 8,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 800,
        "subgroup_name": "Carpet Mat",
        "category": "Essential",
        "subgroup_id": 186,
        "thumb_url": "A4000207.png",
        "mesh": "Carpet Mat",
        "price": "2,946",
        "marketing_name": "Carpet Mat",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Carpet Mat",
        "hotspot_name": "Carpet_Mat",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000207.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 815,
        "subgroup_name": "SUN SHADE",
        "category": "Essential",
        "subgroup_id": 187,
        "thumb_url": "A4000215.png",
        "mesh": "SUN SHADE - 2 Doors",
        "price": "xx,xx,xxx",
        "marketing_name": "Sun Shade - 2 Doors",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "SUN SHADE - 2 Doors",
        "hotspot_name": "SUN_SHADE",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000215.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "interior_subgroup": [
      {
        "accessories_id": 682,
        "subgroup_name": "3D Mats",
        "category": "Interior",
        "subgroup_id": 165,
        "thumb_url": "A4000205.png",
        "mesh": "MG_COMET_IN_ACC_3D_MATS_MESH",
        "price": "2,582",
        "marketing_name": "3D Mats",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_1",
        "hotspot_name": "3D_MAT_HP",
        "acces_type": "normal",
        "sort_order": 14,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 763,
        "subgroup_name": "Interior Organiser Front ",
        "category": "Interior",
        "subgroup_id": 182,
        "thumb_url": "A4000903.png",
        "mesh": "MG_COMET_IN_ACC_INTERIOR_ORGANIZER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Interior Organiser Front ",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_19",
        "hotspot_name": "INT_HP",
        "acces_type": "normal",
        "sort_order": 19,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 764,
        "subgroup_name": "Dash Board Organizer",
        "category": "Interior",
        "subgroup_id": 183,
        "thumb_url": "A4000904.png",
        "mesh": "MG_COMET_IN_ACC_DASH_ORGANIZER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Dash Board Organizer",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_20",
        "hotspot_name": "DASHBOARD_HP",
        "acces_type": "normal",
        "sort_order": 20,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 802,
        "subgroup_name": "KEY COVER ",
        "category": "Interior",
        "subgroup_id": 185,
        "thumb_url": "A4000865.png",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "price": "899",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000865.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 12
      },
      {
        "accessories_id": 819,
        "subgroup_name": "Storage organizer",
        "category": "Interior",
        "subgroup_id": 193,
        "thumb_url": "A4000906.png",
        "mesh": "Arm rest with storage organizer",
        "price": "xx,xx,xxx",
        "marketing_name": "Arm rest with storage organizer",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Arm rest with storage organizer",
        "hotspot_name": "Storage_organizer",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000906.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 813,
        "subgroup_name": "Screen Guard",
        "category": "Interior",
        "subgroup_id": 188,
        "thumb_url": "A4000824.png",
        "mesh": "Screen Guard  - Infotainment ",
        "price": "591",
        "marketing_name": "Screen Guard  - Infotainment ",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Screen Guard  - Infotainment ",
        "hotspot_name": "Screen_Guard",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000824.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 814,
        "subgroup_name": "POWER STATION",
        "category": "Interior",
        "subgroup_id": 189,
        "thumb_url": "A4000833.png",
        "mesh": "AUXILIARY BATTERY PACK",
        "price": "35,999",
        "marketing_name": "PORTABLE POWER STATION",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "AUXILIARY BATTERY PACK",
        "hotspot_name": "POWER_STATION",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000833.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 816,
        "subgroup_name": "Vacuum cleaner",
        "category": "Interior",
        "subgroup_id": 190,
        "thumb_url": "A4000829.png",
        "mesh": "3-in-1 Wireless Vacuum cleaner",
        "price": "xx,xx,xxx",
        "marketing_name": "3-in-1 Wireless Vacuum cleaner",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "3-in-1 Wireless Vacuum cleaner",
        "hotspot_name": "Vacuum_cleaner",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000829.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 817,
        "subgroup_name": "Quick Charger",
        "category": "Interior",
        "subgroup_id": 191,
        "thumb_url": "A4000832.png",
        "mesh": "Quick Charger",
        "price": "xx,xx,xxx",
        "marketing_name": "Quick Charger",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Quick Charger",
        "hotspot_name": "Quick_Charger",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000832.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 818,
        "subgroup_name": "Charging cable",
        "category": "Interior",
        "subgroup_id": 192,
        "thumb_url": "A4000876.png",
        "mesh": "4 in 1 charging cable",
        "price": "xx,xx,xxx",
        "marketing_name": "4 in 1 charging cable",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "4 in 1 charging cable",
        "hotspot_name": "Charging_cable",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000876.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "stickers_subgroup": [
      {
        "accessories_id": 799,
        "subgroup_name": "Sticker",
        "category": "Stickers",
        "subgroup_id": 178,
        "thumb_url": "A4000928.png",
        "mesh": "MG_COMET_EX_SBLOSSOM_ONE_GRAPHIC_MESH",
        "price": "15,499",
        "marketing_name": "Blossom",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "EX_ACC_20",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "sort_order": 21,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 7
      }
    ]
  }
}

export const respRemoved = {
  "status": 1,
  "message": "success",
  "response": {
    "packages": [
      {
        "pack_id": 1,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Serenity Lush Pack",
        "flag": "Remove",
        "pack_description": "Serenity Lush Pack",
        "pack_part_number": "KIT0018-A",
        "pack_image_url": "Serenity_Pack.png",
        "pack_thumb_url": "Serenity_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "GREEN_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 688,
            "marketing_name": "Body Side Moulding - Painted - GRE",
            "part_number": "A4000195-GRE",
            "description": "",
            "qty": 1,
            "price": 4184,
            "image_url": "",
            "thumb_url": "A4000195-GRE.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_GREEN_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 683,
            "marketing_name": "Hood Garnish - GRE",
            "part_number": "A4000190-GRE",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-GRE.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 761,
            "marketing_name": "Seat Covers - Pastel Green",
            "part_number": "A4000895",
            "description": "",
            "qty": 1,
            "price": 10775,
            "image_url": "",
            "thumb_url": "A4000895.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 743,
            "marketing_name": "Steering Wheel Cover Pastel Green",
            "part_number": "A4000890",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000890.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_GREEN_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 731,
            "marketing_name": "Door Edge Guard - GRE",
            "part_number": "A4000898",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000898.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 726,
            "marketing_name": "Hood Branding - GRE",
            "part_number": "A4000884",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000884.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 713,
            "marketing_name": "Door Handle Garnish - Painted - GRE",
            "part_number": "A4000201-GRE",
            "description": "",
            "qty": 1,
            "price": 1564,
            "image_url": "",
            "thumb_url": "A4000201-GRE.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 708,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - GRE",
            "part_number": "A4000203-GRE",
            "description": "",
            "qty": 1,
            "price": 2939,
            "image_url": "",
            "thumb_url": "A4000203-GRE.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 703,
            "marketing_name": "Skid Plate - Rear - Painted - GRE",
            "part_number": "A4000198-GRE",
            "description": "",
            "qty": 1,
            "price": 3135,
            "image_url": "",
            "thumb_url": "A4000198-GRE.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 698,
            "marketing_name": "Skid Plate - Front - Painted - GRE",
            "part_number": "A4000197-GRE",
            "description": "",
            "qty": 1,
            "price": 2800,
            "image_url": "",
            "thumb_url": "A4000197-GRE.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 1,
            "pack_name": "Serenity Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Serenity Lush Pack",
            "pack_part_number": "KIT0018-A",
            "pack_image_url": "Serenity_Pack.png",
            "pack_thumb_url": "Serenity_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "GREEN_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 693,
            "marketing_name": "ORVM Garnish - Painted - GRE",
            "part_number": "A4000199-GRE",
            "description": "",
            "qty": 1,
            "price": 3812,
            "image_url": "",
            "thumb_url": "A4000199-GRE.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_GREEN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 6,
            "parent_pack_id": 1,
            "allow_custom": 0,
            "pack_name": "Serenity Additional Pack",
            "flag": "Add",
            "pack_description": "Serenity Additional Pack",
            "pack_part_number": "KIT0019-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0019-A",
            "accessoriesList": [
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 6,
                "pack_name": "Serenity Additional Pack",
                "parent_pack_id": 1,
                "allow_custom": 0,
                "pack_description": "Serenity Additional Pack",
                "pack_part_number": "KIT0019-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0019-A",
                "subgroup_id": 168,
                "accessories_id": 736,
                "marketing_name": "Interior Inserts (GRE) KIT",
                "part_number": "A4000862",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000862.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_GREEN_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 11,
            "parent_pack_id": 1,
            "allow_custom": 2,
            "pack_name": "GRAPHICS - KIT 2",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT 2",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 795,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 791,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 787,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 783,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 779,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 775,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 771,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 767,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 10,
            "parent_pack_id": 1,
            "allow_custom": 2,
            "pack_name": "GRAPHICS - KIT 1",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT 1",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 795,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 791,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 787,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 783,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-GRE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 779,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 775,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 771,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 10,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 767,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-GRE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 14,
            "parent_pack_id": 1,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 754,
                "marketing_name": "Wheel Covers - 3 - GRE",
                "part_number": "A4000861-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 751,
                "marketing_name": "Wheel Covers - 1 - GRE",
                "part_number": "A4000859-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 14,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 1,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 746,
                "marketing_name": "Wheel Covers - 2 - GRE",
                "part_number": "A4000860-GRE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-GRE.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_GREEN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 2,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Sundowner Lush Pack",
        "flag": "Add",
        "pack_description": "Sundowner Lush Pack",
        "pack_part_number": "KIT0020-A",
        "pack_image_url": "Sundowner_Pack.png",
        "pack_thumb_url": "Sundowner_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "ORANGE_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 699,
            "marketing_name": "Skid Plate - Front - Painted - ORG",
            "part_number": "A4000197-ORG",
            "description": "",
            "qty": 1,
            "price": 2644,
            "image_url": "",
            "thumb_url": "A4000197-ORG.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 759,
            "marketing_name": "Seat Covers - Evening Orange",
            "part_number": "A4000808",
            "description": "",
            "qty": 1,
            "price": 10811,
            "image_url": "",
            "thumb_url": "A4000808.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 741,
            "marketing_name": "Steering Wheel Cover Evening Orange",
            "part_number": "A4000807",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000807.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_ORANGE_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 732,
            "marketing_name": "Door Edge Guard - ORG",
            "part_number": "A4000899",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000899.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 727,
            "marketing_name": "Hood Branding - ORG",
            "part_number": "A4000885",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000885.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 714,
            "marketing_name": "Door Handle Garnish - Painted - ORG",
            "part_number": "A4000201-ORG",
            "description": "",
            "qty": 1,
            "price": 1354,
            "image_url": "",
            "thumb_url": "A4000201-ORG.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 709,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - ORG",
            "part_number": "A4000203-ORG",
            "description": "",
            "qty": 1,
            "price": 2654,
            "image_url": "",
            "thumb_url": "A4000203-ORG.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 704,
            "marketing_name": "Skid Plate - Rear - Painted - ORG",
            "part_number": "A4000198-ORG",
            "description": "",
            "qty": 1,
            "price": 2941,
            "image_url": "",
            "thumb_url": "A4000198-ORG.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 694,
            "marketing_name": "ORVM Garnish - Painted - ORG",
            "part_number": "A4000199-ORG",
            "description": "",
            "qty": 1,
            "price": 3161,
            "image_url": "",
            "thumb_url": "A4000199-ORG.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 689,
            "marketing_name": "Body Side Moulding - Painted - ORG",
            "part_number": "A4000195-ORG",
            "description": "",
            "qty": 1,
            "price": 4350,
            "image_url": "",
            "thumb_url": "A4000195-ORG.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_ORANGE_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 2,
            "pack_name": "Sundowner Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Sundowner Lush Pack",
            "pack_part_number": "KIT0020-A",
            "pack_image_url": "Sundowner_Pack.png",
            "pack_thumb_url": "Sundowner_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "ORANGE_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 684,
            "marketing_name": "Hood Garnish - ORG",
            "part_number": "A4000190-ORG",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-ORG.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_ORANGE_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 7,
            "parent_pack_id": 2,
            "allow_custom": 0,
            "pack_name": "Sundowner Additional Pack",
            "flag": "Add",
            "pack_description": "Sundowner Additional Pack",
            "pack_part_number": "KIT0021-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0021-A",
            "accessoriesList": [
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 7,
                "pack_name": "Sundowner Additional Pack",
                "parent_pack_id": 2,
                "allow_custom": 0,
                "pack_description": "Sundowner Additional Pack",
                "pack_part_number": "KIT0021-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0021-A",
                "subgroup_id": 168,
                "accessories_id": 737,
                "marketing_name": "Interior Inserts (ORG) KIT",
                "part_number": "A4000878",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000878.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_ORANGE_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 11,
            "parent_pack_id": 2,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 796,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 792,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-ORE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-ORE.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 788,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 784,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-ORG.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 780,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 776,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-ORE",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-ORE.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 772,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 11,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 768,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-ORG.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_ORANGE_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 15,
            "parent_pack_id": 2,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 747,
                "marketing_name": "Wheel Covers - 2 - ORG",
                "part_number": "A4000860-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 752,
                "marketing_name": "Wheel Covers - 1 - ORG",
                "part_number": "A4000859-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 15,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 2,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 755,
                "marketing_name": "Wheel Covers - 3- ORG",
                "part_number": "A4000861-ORG",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-ORG.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_ORANGE_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 3,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Flex Lush Pack",
        "flag": "Add",
        "pack_description": "Flex Lush Pack",
        "pack_part_number": "KIT0024-A",
        "pack_image_url": "Flex_Pack.png",
        "pack_thumb_url": "Flex_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "RED_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 685,
            "marketing_name": "Hood Garnish - RED",
            "part_number": "A4000190-RED",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-RED.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 760,
            "marketing_name": "Seat Covers - Sangria Red",
            "part_number": "A4000809",
            "description": "",
            "qty": 1,
            "price": 10775,
            "image_url": "",
            "thumb_url": "A4000809.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 742,
            "marketing_name": "Steering Wheel Cover Sangria Red",
            "part_number": "A4000822",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000822.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_RED_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 733,
            "marketing_name": "Door Edge Guard - RED",
            "part_number": "A4000900",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000900.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 728,
            "marketing_name": "Hood Branding - RED",
            "part_number": "A4000886",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000886.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 715,
            "marketing_name": "Door Handle Garnish - Painted - RED",
            "part_number": "A4000201-RED",
            "description": "",
            "qty": 1,
            "price": 1317,
            "image_url": "",
            "thumb_url": "A4000201-RED.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 710,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - RED",
            "part_number": "A4000203-RED",
            "description": "",
            "qty": 1,
            "price": 2643,
            "image_url": "",
            "thumb_url": "A4000203-RED.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 705,
            "marketing_name": "Skid Plate - Rear - Painted - RED",
            "part_number": "A4000198-RED",
            "description": "",
            "qty": 1,
            "price": 2908,
            "image_url": "",
            "thumb_url": "A4000198-RED.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 700,
            "marketing_name": "Skid Plate - Front - Painted - RED",
            "part_number": "A4000197-RED",
            "description": "",
            "qty": 1,
            "price": 2649,
            "image_url": "",
            "thumb_url": "A4000197-RED.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 695,
            "marketing_name": "ORVM Garnish - Painted - RED",
            "part_number": "A4000199-RED",
            "description": "",
            "qty": 1,
            "price": 3047,
            "image_url": "",
            "thumb_url": "A4000199-RED.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_RED_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 3,
            "pack_name": "Flex Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Flex Lush Pack",
            "pack_part_number": "KIT0024-A",
            "pack_image_url": "Flex_Pack.png",
            "pack_thumb_url": "Flex_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "RED_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 690,
            "marketing_name": "Body Side Moulding - Painted - RED",
            "part_number": "A4000195-RED",
            "description": "",
            "qty": 1,
            "price": 4270,
            "image_url": "",
            "thumb_url": "A4000195-RED.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_RED_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          }
        ],
        "additionalPack": [
          {
            "pack_id": 8,
            "parent_pack_id": 3,
            "allow_custom": 0,
            "pack_name": "Flex Additional Pack",
            "flag": "Add",
            "pack_description": "Flex Additional Pack",
            "pack_part_number": "KIT0025-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0025-A",
            "accessoriesList": [
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 8,
                "pack_name": "Flex Additional Pack",
                "parent_pack_id": 3,
                "allow_custom": 0,
                "pack_description": "Flex Additional Pack",
                "pack_part_number": "KIT0025-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0025-A",
                "subgroup_id": 168,
                "accessories_id": 738,
                "marketing_name": "Interior Inserts(RED) KIT",
                "part_number": "A4000879",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000879.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_RED_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 12,
            "parent_pack_id": 3,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 797,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 793,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 789,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": ".",
                "thumb_url": "A4000817-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 785,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-RED.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 781,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 777,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 773,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 12,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 769,
                "marketing_name": "Body Graphics Option - 1",
                "part_number": "A4000814-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3681,
                "image_url": "",
                "thumb_url": "A4000814-RED.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_RED_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 16,
            "parent_pack_id": 3,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 756,
                "marketing_name": "Wheel Covers - 3 - RED",
                "part_number": "A4000861-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 753,
                "marketing_name": "Wheel Covers - 1 - RED",
                "part_number": "A4000859-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 16,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 3,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 748,
                "marketing_name": "Wheel Covers - 2 - RED",
                "part_number": "A4000860-RED",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-RED.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_RED_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      },
      {
        "pack_id": 4,
        "parent_pack_id": 0,
        "allow_custom": 0,
        "pack_name": "Beach Bae Lush Pack",
        "flag": "Add",
        "pack_description": "Beach Bae Lush Pack",
        "pack_part_number": "KIT0022-A",
        "pack_image_url": "Beach_Bae_Pack.png",
        "pack_thumb_url": "Beach_Bae_Pack.png",
        "pack_price": "31999",
        "pack_mesh_code": "CYAN_COLOR_KIT",
        "accessoriesList": [
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 171,
            "accessories_id": 740,
            "marketing_name": "Steering Wheel Cover Cool Cyan",
            "part_number": "A4000805",
            "description": "",
            "qty": 1,
            "price": 579,
            "image_url": "",
            "thumb_url": "A4000805.png",
            "tag": "STEERING",
            "mesh": "MG_COMET_OPTION_1_CYAN_ONE_IN_STEERING_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "STEERING WHEEL COVER - GAMER",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "HP_STEERING",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 180,
            "accessories_id": 758,
            "marketing_name": "Seat Covers - Cool Cyan",
            "part_number": "A4000806",
            "description": "",
            "qty": 1,
            "price": 10594,
            "image_url": "",
            "thumb_url": "A4000806.png",
            "tag": "SEAT",
            "mesh": "MG_COMET_IN_ACC_SEAT_COVER_CYAN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Seat Covers",
            "category": "Interior",
            "maingroup_id": 2,
            "hotspot_name": "SEAT_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 156,
            "accessories_id": 687,
            "marketing_name": "Hood Garnish - CYN",
            "part_number": "A4000190-CYN",
            "description": "",
            "qty": 1,
            "price": 3128,
            "image_url": "",
            "thumb_url": "A4000190-CYN.png",
            "tag": "EX_ACC_1",
            "mesh": "MG_COMET_EX_ACC_HOOD_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 157,
            "accessories_id": 692,
            "marketing_name": "Body Side Moulding - Painted - CYN",
            "part_number": "A4000195-CYN",
            "description": "",
            "qty": 1,
            "price": 4184,
            "image_url": "",
            "thumb_url": "A4000195-CYN.png",
            "tag": "EX_ACC_2",
            "mesh": "MG_COMET_EX_ACC_CYN_BSM_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Body Side Moulding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "BSM_HP",
            "explicit_access": "178"
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 158,
            "accessories_id": 697,
            "marketing_name": "ORVM Garnish - Painted - CYN",
            "part_number": "A4000199-CYN",
            "description": "",
            "qty": 1,
            "price": 2923,
            "image_url": "",
            "thumb_url": "A4000199-CYN.png",
            "tag": "EX_ACC_3",
            "mesh": "MG_COMET_EX_ACC_ORVM_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Orvm Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "ORVM_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 166,
            "accessories_id": 702,
            "marketing_name": "Skid Plate - Front - Painted - CYN",
            "part_number": "A4000197-CYN",
            "description": "",
            "qty": 1,
            "price": 2587,
            "image_url": "",
            "thumb_url": "A4000197-CYN.png",
            "tag": "EX_ACC_4",
            "mesh": "MG_COMET_EX_ACC_FRONT_SKID_PLATE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Front",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 167,
            "accessories_id": 707,
            "marketing_name": "Skid Plate - Rear - Painted - CYN",
            "part_number": "A4000198-CYN",
            "description": "",
            "qty": 1,
            "price": 2712,
            "image_url": "",
            "thumb_url": "A4000198-CYN.png",
            "tag": "EX_ACC_5",
            "mesh": "MG_COMET_EX_ACC_REAR_SKID_PLATE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Skid Plate Rear",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "RR_SKIDPLATE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 159,
            "accessories_id": 712,
            "marketing_name": "Fog Lamp Garnish - Painted - (F/R) - CYN",
            "part_number": "A4000203-CYN",
            "description": "",
            "qty": 1,
            "price": 2595,
            "image_url": "",
            "thumb_url": "A4000203-CYN.png",
            "tag": "EX_ACC_6",
            "mesh": "MG_COMENT_EX_ACC_FOGLAMP_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Fog Lamp Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "FOGLAMP_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 160,
            "accessories_id": 717,
            "marketing_name": "Door Handle Garnish - Painted - CYN",
            "part_number": "A4000201-CYN",
            "description": "",
            "qty": 1,
            "price": 1277,
            "image_url": "",
            "thumb_url": "A4000201-CYN.png",
            "tag": "EX_ACC_7",
            "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Handle Garnish",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_HANDLE_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 161,
            "accessories_id": 730,
            "marketing_name": "Hood Branding - CYN",
            "part_number": "A4000888",
            "description": "",
            "qty": 1,
            "price": 2404,
            "image_url": "",
            "thumb_url": "A4000888.png",
            "tag": "EX_ACC_8",
            "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Hood Branding",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "HOOD_BRANDING_HP",
            "explicit_access": ""
          },
          {
            "pack_id": 4,
            "pack_name": "Beach Bae Lush Pack",
            "parent_pack_id": 0,
            "allow_custom": 0,
            "pack_description": "Beach Bae Lush Pack",
            "pack_part_number": "KIT0022-A",
            "pack_image_url": "Beach_Bae_Pack.png",
            "pack_thumb_url": "Beach_Bae_Pack.png",
            "pack_price": "31999",
            "pack_mesh_code": "CYAN_COLOR_KIT",
            "subgroup_id": 175,
            "accessories_id": 735,
            "marketing_name": "Door Edge Guard - CNY",
            "part_number": "A4000902",
            "description": "",
            "qty": 1,
            "price": 1401,
            "image_url": "",
            "thumb_url": "A4000902.png",
            "tag": "EX_ACC_16",
            "mesh": "MG_COMET_EX_ACC_DOOR_GUARD_CYN_MESH",
            "type": "3d",
            "acces_type": "normal",
            "subgroup_name": "Door Edge Guard",
            "category": "Exterior",
            "maingroup_id": 1,
            "hotspot_name": "DOOR_GUARD_HP",
            "explicit_access": ""
          }
        ],
        "additionalPack": [
          {
            "pack_id": 9,
            "parent_pack_id": 4,
            "allow_custom": 0,
            "pack_name": "Beach Bae Additional Pack",
            "flag": "Add",
            "pack_description": "Beach Bae Additional Pack",
            "pack_part_number": "KIT0023-A",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "21999",
            "pack_mesh_code": "KIT0023-A",
            "accessoriesList": [
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 168,
                "accessories_id": 739,
                "marketing_name": "Interior Inserts(CYN) KIT",
                "part_number": "A4000880",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 17963,
                "image_url": "",
                "thumb_url": "A4000880.png",
                "tag": "IN_ACC_18",
                "mesh": "MG_COMET_CYN_IN_ACC_ISK_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Interior Inserts",
                "category": "Interior",
                "maingroup_id": 2,
                "hotspot_name": "ISK_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 164,
                "accessories_id": 681,
                "marketing_name": "Lower Side/Door Cladding",
                "part_number": "A4000194",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2072,
                "image_url": "",
                "thumb_url": "A4000194.png",
                "tag": "EX_ACC_15",
                "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Lower Side/door Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "DOOR_CLADDING_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 9,
                "pack_name": "Beach Bae Additional Pack",
                "parent_pack_id": 4,
                "allow_custom": 0,
                "pack_description": "Beach Bae Additional Pack",
                "pack_part_number": "KIT0023-A",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "21999",
                "pack_mesh_code": "KIT0023-A",
                "subgroup_id": 163,
                "accessories_id": 680,
                "marketing_name": "Wheel Arch Cladding",
                "part_number": "A4000193",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 2434,
                "image_url": "",
                "thumb_url": "A4000193.png",
                "tag": "EX_ACC_14",
                "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Arch Cladding",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "WHEEL_ARCH_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 13,
            "parent_pack_id": 4,
            "allow_custom": 1,
            "pack_name": "GRAPHICS - KIT",
            "flag": "Add",
            "pack_description": "GRAPHICS - KIT",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 794,
                "marketing_name": "Roof Graphics Option 4 BG - Option 4",
                "part_number": "A4000894-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5375,
                "image_url": "",
                "thumb_url": "A4000894-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 790,
                "marketing_name": "Roof Graphics Option 3 BG - Option 3",
                "part_number": "A4000819-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6124,
                "image_url": "",
                "thumb_url": "A4000819-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 786,
                "marketing_name": "Roof Graphics Option 2 BG - Option 2",
                "part_number": "A4000817-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 7378,
                "image_url": "",
                "thumb_url": "A4000817-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 184,
                "accessories_id": 782,
                "marketing_name": "Roof Graphics Option 1 BG - Option 1",
                "part_number": "A4000815-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6808,
                "image_url": "",
                "thumb_url": "A4000815-CYN.png",
                "tag": "EX_ACC_19",
                "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Roof Graphic",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "ROOFGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 778,
                "marketing_name": "Body Graphics Option - 4",
                "part_number": "A4000892-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3486,
                "image_url": "",
                "thumb_url": "A4000892-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 774,
                "marketing_name": "Body Graphics Option - 3",
                "part_number": "A4000818-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 3192,
                "image_url": "",
                "thumb_url": "A4000818-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              },
              {
                "pack_id": 13,
                "pack_name": "GRAPHICS - KIT",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "GRAPHICS - KIT",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 169,
                "accessories_id": 770,
                "marketing_name": "Body Graphics Option - 2",
                "part_number": "A4000816-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 4560,
                "image_url": "",
                "thumb_url": "A4000816-CYN.png",
                "tag": "EX_ACC_18",
                "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_CYN_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Body Graphics",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "BODYGRAPHIC_HP",
                "explicit_access": ""
              }
            ]
          },
          {
            "pack_id": 17,
            "parent_pack_id": 4,
            "allow_custom": 1,
            "pack_name": "Wheel Cover Kit",
            "flag": "Add",
            "pack_description": "Wheel Cover Kit",
            "pack_part_number": "",
            "pack_image_url": "",
            "pack_thumb_url": "",
            "pack_price": "xx,xx,xxx",
            "pack_mesh_code": "",
            "accessoriesList": [
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 757,
                "marketing_name": "Wheel Covers - 3 - CYN",
                "part_number": "A4000861-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 0,
                "image_url": "",
                "thumb_url": "A4000861-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_3_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 750,
                "marketing_name": "Wheel Covers - 1 - CYN",
                "part_number": "A4000859-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 6499,
                "image_url": "",
                "thumb_url": "A4000859-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_1_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              },
              {
                "pack_id": 17,
                "pack_name": "Wheel Cover Kit",
                "parent_pack_id": 4,
                "allow_custom": 1,
                "pack_description": "Wheel Cover Kit",
                "pack_part_number": "",
                "pack_image_url": "",
                "pack_thumb_url": "",
                "pack_price": "",
                "pack_mesh_code": "",
                "subgroup_id": 179,
                "accessories_id": 749,
                "marketing_name": "Wheel Covers - 2 - CYN",
                "part_number": "A4000860-CYN",
                "description": "",
                "qty": 1,
                "flag": "Add",
                "price": 5999,
                "image_url": "",
                "thumb_url": "A4000860-CYN.png",
                "tag": "EX_ALLOYWHEEL_MESH",
                "mesh": "MG_COMET_EX_ACC_OPTION_2_CYAN_WHEEL_COVER_MESH",
                "type": "3d",
                "acces_type": "normal",
                "subgroup_name": "Wheel Covers",
                "category": "Exterior",
                "maingroup_id": 1,
                "hotspot_name": "HP_ALLOYWHEEL",
                "explicit_access": ""
              }
            ]
          }
        ]
      }
    ],
    "accessories": [
      {
        "accessories_id": 669,
        "price": "1,996",
        "subgroup_name": "Hood Garnish",
        "marketing_name": "Hood Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_HOOD_CHROME_GARNISH_MESH",
        "tag": "EX_ACC_1",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 156,
        "sort_order": 1,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000190.png",
        "flag": "Add",
        "hotspot_name": "HOOD_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 670,
        "price": "3,737",
        "subgroup_name": "Body Side Moulding",
        "marketing_name": "Body Side Moulding Chrome",
        "mesh": "MG_COMET_EX_ACC_CHROME_BSM_MESH",
        "tag": "EX_ACC_2",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 157,
        "sort_order": 2,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000196.png",
        "flag": "Add",
        "hotspot_name": "BSM_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "178",
        "top_accessories": 0
      },
      {
        "accessories_id": 671,
        "price": "2,157",
        "subgroup_name": "Orvm Garnish",
        "marketing_name": "Orvm Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_ORVM_CHROME_MESH",
        "tag": "EX_ACC_3",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 158,
        "sort_order": 3,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000200.png",
        "flag": "Add",
        "hotspot_name": "ORVM_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 672,
        "price": "2,220",
        "subgroup_name": "Fog Lamp Garnish",
        "marketing_name": "Fog Lamp Garnish Chrome",
        "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GARNISH_MESH",
        "tag": "EX_ACC_6",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 159,
        "sort_order": 4,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000204.png",
        "flag": "Add",
        "hotspot_name": "FOGLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 673,
        "price": "1,110",
        "subgroup_name": "Door Handle Garnish",
        "marketing_name": "Door Handle Garnish Chrome",
        "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CHROME_GARNISH_MESH",
        "tag": "EX_ACC_7",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 160,
        "sort_order": 5,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000202.png",
        "flag": "Add",
        "hotspot_name": "DOOR_HANDLE_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 674,
        "price": "2,068",
        "subgroup_name": "Hood Branding",
        "marketing_name": "Hood Branding Chrome",
        "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CHROME_MESH",
        "tag": "EX_ACC_8",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 161,
        "sort_order": 6,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000883.png",
        "flag": "Add",
        "hotspot_name": "HOOD_BRANDING_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 675,
        "price": "1,156",
        "subgroup_name": "Weather Deflector",
        "marketing_name": "Weather Deflector",
        "mesh": "MG_COMET_EX_ACC_WIND_DEFLECTOR_MESH",
        "tag": "EX_ACC_9",
        "category": "Essential",
        "is_multiple": 1,
        "subgroup_id": 100,
        "sort_order": 7,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000185.png",
        "flag": "Add",
        "hotspot_name": "WIND_DEFLECTOR_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 676,
        "price": "595",
        "subgroup_name": "Mud Flaps",
        "marketing_name": "Mud Flaps",
        "mesh": "MG_COMET_EX_ACC_MUD_FLAP_MESH",
        "tag": "EX_ACC_10",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 90,
        "sort_order": 8,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000186.png",
        "flag": "Add",
        "hotspot_name": "MUD_FLAP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 677,
        "price": "1,591",
        "subgroup_name": "Head Lamp Garnish",
        "marketing_name": "Head Lamp Garnish",
        "mesh": "MG_COMET_EX_ACC_HEADLAMP_CHROME_GARNISH",
        "tag": "EX_ACC_11",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 44,
        "sort_order": 9,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000187.png",
        "flag": "Add",
        "hotspot_name": "HEADLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 678,
        "price": "1,617",
        "subgroup_name": "Tail Lamp Garnish",
        "marketing_name": "Tail Lamp Garnish",
        "mesh": "MG_COMET_EX_ACC_TAILLAMP_CHROME_GARNISH",
        "tag": "EX_ACC_12",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 51,
        "sort_order": 10,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000188.png",
        "flag": "Add",
        "hotspot_name": "TAILLAMP_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 679,
        "price": "1,635",
        "subgroup_name": "Tailgate",
        "marketing_name": "Tailgate Chrome",
        "mesh": "MG_COMET_EX_ACC_TAILGATE_CHROME_MESH",
        "tag": "EX_ACC_13",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 162,
        "sort_order": 11,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000189.png",
        "flag": "Add",
        "hotspot_name": "TAILGATE_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 680,
        "price": "2,434",
        "subgroup_name": "Wheel Arch Cladding",
        "marketing_name": "Wheel Arch Cladding",
        "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
        "tag": "EX_ACC_14",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 163,
        "sort_order": 12,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000193.png",
        "flag": "Add",
        "hotspot_name": "WHEEL_ARCH_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 681,
        "price": "2,072",
        "subgroup_name": "Lower Side/door Cladding",
        "marketing_name": "Lower Side/Door Cladding",
        "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
        "tag": "EX_ACC_15",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 164,
        "sort_order": 13,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000194.png",
        "flag": "Add",
        "hotspot_name": "DOOR_CLADDING_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 682,
        "price": "2,582",
        "subgroup_name": "3D Mats",
        "marketing_name": "3D Mats",
        "mesh": "MG_COMET_IN_ACC_3D_MATS_MESH",
        "tag": "IN_ACC_1",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 165,
        "sort_order": 14,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000205.png",
        "flag": "Add",
        "hotspot_name": "3D_MAT_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 762,
        "price": "xx,xx,xxx",
        "subgroup_name": "Integrated Roof Carrier",
        "marketing_name": "Integrated Roof Carrier",
        "mesh": "MG_COMET_EX_ACC_ROOF_CARRIER_MESH",
        "tag": "EX_ACC_21",
        "category": "Exterior",
        "is_multiple": 0,
        "subgroup_id": 181,
        "sort_order": 18,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000882.png",
        "flag": "Add",
        "hotspot_name": "ROOF_CARRIER_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 763,
        "price": "xx,xx,xxx",
        "subgroup_name": "Interior Organiser Front ",
        "marketing_name": "Interior Organiser Front ",
        "mesh": "MG_COMET_IN_ACC_INTERIOR_ORGANIZER_MESH",
        "tag": "IN_ACC_19",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 182,
        "sort_order": 19,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000903.png",
        "flag": "Add",
        "hotspot_name": "INT_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 764,
        "price": "xx,xx,xxx",
        "subgroup_name": "Dash Board Organizer",
        "marketing_name": "Dash Board Organizer",
        "mesh": "MG_COMET_IN_ACC_DASH_ORGANIZER_MESH",
        "tag": "IN_ACC_20",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 183,
        "sort_order": 20,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000904.png",
        "flag": "Add",
        "hotspot_name": "DASHBOARD_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 799,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Blossom",
        "mesh": "MG_COMET_EX_SBLOSSOM_ONE_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000928.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 798,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Astronaut",
        "mesh": "MG_COMET_EX_SPACE_TWO_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000927.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 745,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Space",
        "mesh": "MG_COMET_EX_SPACE_ONE_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000926.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "169,157",
        "top_accessories": 0
      },
      {
        "accessories_id": 744,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "Floresta",
        "mesh": "MG_COMET_EX_FLORESTA_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "",
        "thumb_url": "A4000925.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "169,157",
        "top_accessories": 0
      },
      {
        "accessories_id": 821,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "NIGHT CAFÉ",
        "mesh": "MG_COMET_EX_NIGHTCAFE_PINK_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000923.png",
        "thumb_url": "A4000923.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 822,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "EMOTICON",
        "mesh": "MG_COMET_EX_SKULL_GRAPHICS_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000924.png",
        "thumb_url": "A4000924.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 820,
        "price": "15,499",
        "subgroup_name": "Sticker",
        "marketing_name": "YOUTH",
        "mesh": "MG_COMET_EX_YOUTH_GRAPHIC_MESH",
        "tag": "EX_ACC_20",
        "category": "Stickers",
        "is_multiple": 1,
        "subgroup_id": 178,
        "sort_order": 21,
        "is_default": 1,
        "image_url": "A4000922.png",
        "thumb_url": "A4000922.png",
        "flag": "Add",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "type": "3d",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 802,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000865.png",
        "thumb_url": "A4000865.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 819,
        "price": "xx,xx,xxx",
        "subgroup_name": "Storage organizer",
        "marketing_name": "Arm rest with storage organizer",
        "mesh": "Arm rest with storage organizer",
        "tag": "Arm rest with storage organizer",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 193,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000906.png",
        "thumb_url": "A4000906.png",
        "flag": "Add",
        "hotspot_name": "Storage_organizer",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 801,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(GRE)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000864.png",
        "thumb_url": "A4000864.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 800,
        "price": "2,946",
        "subgroup_name": "Carpet Mat",
        "marketing_name": "Carpet Mat",
        "mesh": "Carpet Mat",
        "tag": "Carpet Mat",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 186,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000207.png",
        "thumb_url": "A4000207.png",
        "flag": "Add",
        "hotspot_name": "Carpet_Mat",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 815,
        "price": "xx,xx,xxx",
        "subgroup_name": "SUN SHADE",
        "marketing_name": "Sun Shade - 2 Doors",
        "mesh": "SUN SHADE - 2 Doors",
        "tag": "SUN SHADE - 2 Doors",
        "category": "Essential",
        "is_multiple": 0,
        "subgroup_id": 187,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000215.png",
        "thumb_url": "A4000215.png",
        "flag": "Add",
        "hotspot_name": "SUN_SHADE",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 813,
        "price": "591",
        "subgroup_name": "Screen Guard",
        "marketing_name": "Screen Guard  - Infotainment ",
        "mesh": "Screen Guard  - Infotainment ",
        "tag": "Screen Guard  - Infotainment ",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 188,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000824.png",
        "thumb_url": "A4000824.png",
        "flag": "Add",
        "hotspot_name": "Screen_Guard",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 814,
        "price": "35,999",
        "subgroup_name": "POWER STATION",
        "marketing_name": "PORTABLE POWER STATION",
        "mesh": "AUXILIARY BATTERY PACK",
        "tag": "AUXILIARY BATTERY PACK",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 189,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000833.png",
        "thumb_url": "A4000833.png",
        "flag": "Add",
        "hotspot_name": "POWER_STATION",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 816,
        "price": "xx,xx,xxx",
        "subgroup_name": "Vacuum cleaner",
        "marketing_name": "3-in-1 Wireless Vacuum cleaner",
        "mesh": "3-in-1 Wireless Vacuum cleaner",
        "tag": "3-in-1 Wireless Vacuum cleaner",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 190,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000829.png",
        "thumb_url": "A4000829.png",
        "flag": "Add",
        "hotspot_name": "Vacuum_cleaner",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 817,
        "price": "xx,xx,xxx",
        "subgroup_name": "Quick Charger",
        "marketing_name": "Quick Charger",
        "mesh": "Quick Charger",
        "tag": "Quick Charger",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 191,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000832.png",
        "thumb_url": "A4000832.png",
        "flag": "Add",
        "hotspot_name": "Quick_Charger",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 818,
        "price": "xx,xx,xxx",
        "subgroup_name": "Charging cable",
        "marketing_name": "4 in 1 charging cable",
        "mesh": "4 in 1 charging cable",
        "tag": "4 in 1 charging cable",
        "category": "Interior",
        "is_multiple": 0,
        "subgroup_id": 192,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000876.png",
        "thumb_url": "A4000876.png",
        "flag": "Add",
        "hotspot_name": "Charging_cable",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 803,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Soft TPU(RED)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(RED)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(RED)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000866.png",
        "thumb_url": "A4000866.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 804,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Soft TPU (CYN)",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU (CYN)",
        "tag": "KEY COVER  - FLIP KEY - Soft TPU (CYN)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000867.png",
        "thumb_url": "A4000867.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 805,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(GRE)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(GRE)",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(GRE)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000869.png",
        "thumb_url": "A4000869.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 806,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(ORG) ",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(ORG) ",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(ORG) ",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000870.png",
        "thumb_url": "A4000870.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 807,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPU(RED)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPU(RED)",
        "tag": "KEY  COVER - SMART KEY - Soft TPU(RED)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000871.png",
        "thumb_url": "A4000871.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 808,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY - Soft TPUCYN)",
        "mesh": "KEY  COVER - SMART KEY - Soft TPUCYN)",
        "tag": "KEY  COVER - SMART KEY - Soft TPUCYN)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000872.png",
        "thumb_url": "A4000872.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 809,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "mesh": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "tag": "KEY COVER - FLIP KEY  - Soft TPU(BLK)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000896.png",
        "thumb_url": "A4000896.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 810,
        "price": "899",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "mesh": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "tag": "KEY COVER - SMART KEY  - Soft TPU(BLK)",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000897.png",
        "thumb_url": "A4000897.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 811,
        "price": "860",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY  COVER - SMART KEY - Leather",
        "mesh": "KEY  COVER - SMART KEY - Leather",
        "tag": "KEY  COVER - SMART KEY - Leather",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000811.png",
        "thumb_url": "A4000811.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      },
      {
        "accessories_id": 812,
        "price": "860",
        "subgroup_name": "KEY COVER ",
        "marketing_name": "KEY COVER - FLIP KEY - Leather",
        "mesh": "KEY COVER - FLIP KEY - Leather",
        "tag": "KEY COVER - FLIP KEY - Leather",
        "category": "Interior",
        "is_multiple": 1,
        "subgroup_id": 185,
        "sort_order": 22,
        "is_default": 1,
        "image_url": "A4000812.png",
        "thumb_url": "A4000812.png",
        "flag": "Add",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "type": "image",
        "explicit_access": "",
        "top_accessories": 0
      }
    ],
    "exterior_subgroup": [
      {
        "accessories_id": 669,
        "subgroup_name": "Hood Garnish",
        "category": "Exterior",
        "subgroup_id": 156,
        "thumb_url": "A4000190.png",
        "mesh": "MG_COMET_EX_ACC_HOOD_CHROME_GARNISH_MESH",
        "price": "1,996",
        "marketing_name": "Hood Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_1",
        "hotspot_name": "HOOD_HP",
        "acces_type": "normal",
        "sort_order": 1,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 670,
        "subgroup_name": "Body Side Moulding",
        "category": "Exterior",
        "subgroup_id": 157,
        "thumb_url": "A4000196.png",
        "mesh": "MG_COMET_EX_ACC_CHROME_BSM_MESH",
        "price": "3,737",
        "marketing_name": "Body Side Moulding Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_2",
        "hotspot_name": "BSM_HP",
        "acces_type": "normal",
        "sort_order": 2,
        "type": "3d",
        "image_url": "",
        "explicit_access": "178",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 671,
        "subgroup_name": "Orvm Garnish",
        "category": "Exterior",
        "subgroup_id": 158,
        "thumb_url": "A4000200.png",
        "mesh": "MG_COMET_EX_ACC_ORVM_CHROME_MESH",
        "price": "2,157",
        "marketing_name": "Orvm Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_3",
        "hotspot_name": "ORVM_HP",
        "acces_type": "normal",
        "sort_order": 3,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 672,
        "subgroup_name": "Fog Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 159,
        "thumb_url": "A4000204.png",
        "mesh": "MG_COMENT_EX_ACC_FOGLAMP_GARNISH_MESH",
        "price": "2,220",
        "marketing_name": "Fog Lamp Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_6",
        "hotspot_name": "FOGLAMP_HP",
        "acces_type": "normal",
        "sort_order": 4,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 673,
        "subgroup_name": "Door Handle Garnish",
        "category": "Exterior",
        "subgroup_id": 160,
        "thumb_url": "A4000202.png",
        "mesh": "MG_COMET_EX_ACC_DOOR_HANDLE_CHROME_GARNISH_MESH",
        "price": "1,110",
        "marketing_name": "Door Handle Garnish Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_7",
        "hotspot_name": "DOOR_HANDLE_HP",
        "acces_type": "normal",
        "sort_order": 5,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 674,
        "subgroup_name": "Hood Branding",
        "category": "Exterior",
        "subgroup_id": 161,
        "thumb_url": "A4000883.png",
        "mesh": "MG_COMET_EX_ACC_BONET_BRANDING_CHROME_MESH",
        "price": "2,068",
        "marketing_name": "Hood Branding Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_8",
        "hotspot_name": "HOOD_BRANDING_HP",
        "acces_type": "normal",
        "sort_order": 6,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 677,
        "subgroup_name": "Head Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 44,
        "thumb_url": "A4000187.png",
        "mesh": "MG_COMET_EX_ACC_HEADLAMP_CHROME_GARNISH",
        "price": "1,591",
        "marketing_name": "Head Lamp Garnish",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_11",
        "hotspot_name": "HEADLAMP_HP",
        "acces_type": "normal",
        "sort_order": 9,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 678,
        "subgroup_name": "Tail Lamp Garnish",
        "category": "Exterior",
        "subgroup_id": 51,
        "thumb_url": "A4000188.png",
        "mesh": "MG_COMET_EX_ACC_TAILLAMP_CHROME_GARNISH",
        "price": "1,617",
        "marketing_name": "Tail Lamp Garnish",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_12",
        "hotspot_name": "TAILLAMP_HP",
        "acces_type": "normal",
        "sort_order": 10,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 679,
        "subgroup_name": "Tailgate",
        "category": "Exterior",
        "subgroup_id": 162,
        "thumb_url": "A4000189.png",
        "mesh": "MG_COMET_EX_ACC_TAILGATE_CHROME_MESH",
        "price": "1,635",
        "marketing_name": "Tailgate Chrome",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_13",
        "hotspot_name": "TAILGATE_HP",
        "acces_type": "normal",
        "sort_order": 11,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 680,
        "subgroup_name": "Wheel Arch Cladding",
        "category": "Exterior",
        "subgroup_id": 163,
        "thumb_url": "A4000193.png",
        "mesh": "MG_COMET_EX_ACC_WHEEL_ARCH_MESH",
        "price": "2,434",
        "marketing_name": "Wheel Arch Cladding",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_14",
        "hotspot_name": "WHEEL_ARCH_HP",
        "acces_type": "normal",
        "sort_order": 12,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 681,
        "subgroup_name": "Lower Side/door Cladding",
        "category": "Exterior",
        "subgroup_id": 164,
        "thumb_url": "A4000194.png",
        "mesh": "MG_COMET_EX_ACC_LOWERCLADDING_MESH",
        "price": "2,072",
        "marketing_name": "Lower Side/Door Cladding",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_15",
        "hotspot_name": "DOOR_CLADDING_HP",
        "acces_type": "normal",
        "sort_order": 13,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 762,
        "subgroup_name": "Integrated Roof Carrier",
        "category": "Exterior",
        "subgroup_id": 181,
        "thumb_url": "A4000882.png",
        "mesh": "MG_COMET_EX_ACC_ROOF_CARRIER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Integrated Roof Carrier",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_21",
        "hotspot_name": "ROOF_CARRIER_HP",
        "acces_type": "normal",
        "sort_order": 18,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "essential_subgroup": [
      {
        "accessories_id": 675,
        "subgroup_name": "Weather Deflector",
        "category": "Essential",
        "subgroup_id": 100,
        "thumb_url": "A4000185.png",
        "mesh": "MG_COMET_EX_ACC_WIND_DEFLECTOR_MESH",
        "price": "1,156",
        "marketing_name": "Weather Deflector",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "EX_ACC_9",
        "hotspot_name": "WIND_DEFLECTOR_HP",
        "acces_type": "normal",
        "sort_order": 7,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 676,
        "subgroup_name": "Mud Flaps",
        "category": "Essential",
        "subgroup_id": 90,
        "thumb_url": "A4000186.png",
        "mesh": "MG_COMET_EX_ACC_MUD_FLAP_MESH",
        "price": "595",
        "marketing_name": "Mud Flaps",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "EX_ACC_10",
        "hotspot_name": "MUD_FLAP_HP",
        "acces_type": "normal",
        "sort_order": 8,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 800,
        "subgroup_name": "Carpet Mat",
        "category": "Essential",
        "subgroup_id": 186,
        "thumb_url": "A4000207.png",
        "mesh": "Carpet Mat",
        "price": "2,946",
        "marketing_name": "Carpet Mat",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Carpet Mat",
        "hotspot_name": "Carpet_Mat",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000207.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 815,
        "subgroup_name": "SUN SHADE",
        "category": "Essential",
        "subgroup_id": 187,
        "thumb_url": "A4000215.png",
        "mesh": "SUN SHADE - 2 Doors",
        "price": "xx,xx,xxx",
        "marketing_name": "Sun Shade - 2 Doors",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "SUN SHADE - 2 Doors",
        "hotspot_name": "SUN_SHADE",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000215.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "interior_subgroup": [
      {
        "accessories_id": 682,
        "subgroup_name": "3D Mats",
        "category": "Interior",
        "subgroup_id": 165,
        "thumb_url": "A4000205.png",
        "mesh": "MG_COMET_IN_ACC_3D_MATS_MESH",
        "price": "2,582",
        "marketing_name": "3D Mats",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_1",
        "hotspot_name": "3D_MAT_HP",
        "acces_type": "normal",
        "sort_order": 14,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 763,
        "subgroup_name": "Interior Organiser Front ",
        "category": "Interior",
        "subgroup_id": 182,
        "thumb_url": "A4000903.png",
        "mesh": "MG_COMET_IN_ACC_INTERIOR_ORGANIZER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Interior Organiser Front ",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_19",
        "hotspot_name": "INT_HP",
        "acces_type": "normal",
        "sort_order": 19,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 764,
        "subgroup_name": "Dash Board Organizer",
        "category": "Interior",
        "subgroup_id": 183,
        "thumb_url": "A4000904.png",
        "mesh": "MG_COMET_IN_ACC_DASH_ORGANIZER_MESH",
        "price": "xx,xx,xxx",
        "marketing_name": "Dash Board Organizer",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "IN_ACC_20",
        "hotspot_name": "DASHBOARD_HP",
        "acces_type": "normal",
        "sort_order": 20,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 802,
        "subgroup_name": "KEY COVER ",
        "category": "Interior",
        "subgroup_id": 185,
        "thumb_url": "A4000865.png",
        "mesh": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "price": "899",
        "marketing_name": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "KEY COVER  - FLIP KEY - Soft TPU(ORG) ",
        "hotspot_name": "KEY_COVER ",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000865.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 12
      },
      {
        "accessories_id": 819,
        "subgroup_name": "Storage organizer",
        "category": "Interior",
        "subgroup_id": 193,
        "thumb_url": "A4000906.png",
        "mesh": "Arm rest with storage organizer",
        "price": "xx,xx,xxx",
        "marketing_name": "Arm rest with storage organizer",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Arm rest with storage organizer",
        "hotspot_name": "Storage_organizer",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000906.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 813,
        "subgroup_name": "Screen Guard",
        "category": "Interior",
        "subgroup_id": 188,
        "thumb_url": "A4000824.png",
        "mesh": "Screen Guard  - Infotainment ",
        "price": "591",
        "marketing_name": "Screen Guard  - Infotainment ",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Screen Guard  - Infotainment ",
        "hotspot_name": "Screen_Guard",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000824.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 814,
        "subgroup_name": "POWER STATION",
        "category": "Interior",
        "subgroup_id": 189,
        "thumb_url": "A4000833.png",
        "mesh": "AUXILIARY BATTERY PACK",
        "price": "35,999",
        "marketing_name": "PORTABLE POWER STATION",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "AUXILIARY BATTERY PACK",
        "hotspot_name": "POWER_STATION",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000833.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 816,
        "subgroup_name": "Vacuum cleaner",
        "category": "Interior",
        "subgroup_id": 190,
        "thumb_url": "A4000829.png",
        "mesh": "3-in-1 Wireless Vacuum cleaner",
        "price": "xx,xx,xxx",
        "marketing_name": "3-in-1 Wireless Vacuum cleaner",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "3-in-1 Wireless Vacuum cleaner",
        "hotspot_name": "Vacuum_cleaner",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000829.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 817,
        "subgroup_name": "Quick Charger",
        "category": "Interior",
        "subgroup_id": 191,
        "thumb_url": "A4000832.png",
        "mesh": "Quick Charger",
        "price": "xx,xx,xxx",
        "marketing_name": "Quick Charger",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "Quick Charger",
        "hotspot_name": "Quick_Charger",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000832.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      },
      {
        "accessories_id": 818,
        "subgroup_name": "Charging cable",
        "category": "Interior",
        "subgroup_id": 192,
        "thumb_url": "A4000876.png",
        "mesh": "4 in 1 charging cable",
        "price": "xx,xx,xxx",
        "marketing_name": "4 in 1 charging cable",
        "flag": "Add",
        "is_multiple": 0,
        "tag": "4 in 1 charging cable",
        "hotspot_name": "Charging_cable",
        "acces_type": "normal",
        "sort_order": 22,
        "type": "image",
        "image_url": "A4000876.png",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 1
      }
    ],
    "stickers_subgroup": [
      {
        "accessories_id": 799,
        "subgroup_name": "Sticker",
        "category": "Stickers",
        "subgroup_id": 178,
        "thumb_url": "A4000928.png",
        "mesh": "MG_COMET_EX_SBLOSSOM_ONE_GRAPHIC_MESH",
        "price": "15,499",
        "marketing_name": "Blossom",
        "flag": "Add",
        "is_multiple": 1,
        "tag": "EX_ACC_20",
        "hotspot_name": "STICKERS_HP",
        "acces_type": "normal",
        "sort_order": 21,
        "type": "3d",
        "image_url": "",
        "explicit_access": "",
        "top_accessories": 0,
        "accessories_count": 7
      }
    ]
  }
}

export const dummyObj = {
  
    "pack_id": 111,
    "parent_pack_id": 112,
    "allow_custom": 2,
    "pack_name": "GRAPHICS - KIT 2",
    "flag": "added",
    "pack_description": "GRAPHICS - KIT 2",
    "pack_part_number": "",
    "pack_image_url": "",
    "pack_thumb_url": "",
    "pack_price": "xx,xx,xxx",
    "pack_mesh_code": "",
    "accessoriesList": [
      {
        "pack_id": 1110,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 18411,
        "accessories_id": 79125,
        "marketing_name": "Roof Graphics Option 4 BG - Option 4",
        "part_number": "A4000894-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 5375,
        "image_url": "",
        "thumb_url": "A4000894-GRE.png",
        "tag": "EX_ACC_19",
        "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_4_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Roof Graphic",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "ROOFGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 1120,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 13484,
        "accessories_id": 7923231,
        "marketing_name": "Roof Graphics Option 3 BG - Option 3",
        "part_number": "A4000819-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 6124,
        "image_url": "",
        "thumb_url": "A4000819-GRE.png",
        "tag": "EX_ACC_19",
        "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_3_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Roof Graphic",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "ROOFGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 32310,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 2323,
        "accessories_id": 7823237,
        "marketing_name": "Roof Graphics Option 2 BG - Option 2",
        "part_number": "A4000817-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 7378,
        "image_url": "",
        "thumb_url": "A4000817-GRE.png",
        "tag": "EX_ACC_19",
        "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_2_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Roof Graphic",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "ROOFGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 1231230,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 181231234,
        "accessories_id": 712383,
        "marketing_name": "Roof Graphics Option 1 BG - Option 1",
        "part_number": "A4000815-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 6808,
        "image_url": "",
        "thumb_url": "A4000815-GRE.png",
        "tag": "EX_ACC_19",
        "mesh": "MG_COMET_EX_ACC_ROOFGRAPHICS_OPTION_1_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Roof Graphic",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "ROOFGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 12131230,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 16923123,
        "accessories_id": 721312379,
        "marketing_name": "Body Graphics Option - 4",
        "part_number": "A4000892-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 3486,
        "image_url": "",
        "thumb_url": "A4000892-GRE.png",
        "tag": "EX_ACC_18",
        "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_4_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Body Graphics",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "BODYGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 1023123,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 161231239,
        "accessories_id": 712312312375,
        "marketing_name": "Body Graphics Option - 3",
        "part_number": "A4000818-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 3192,
        "image_url": "",
        "thumb_url": "A4000818-GRE.png",
        "tag": "EX_ACC_18",
        "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_3_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Body Graphics",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "BODYGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 10232323,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 12369,
        "accessories_id": 77231231,
        "marketing_name": "Body Graphics Option - 2",
        "part_number": "A4000816-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 4560,
        "image_url": "",
        "thumb_url": "A4000816-GRE.png",
        "tag": "EX_ACC_18",
        "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_2_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Body Graphics",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "BODYGRAPHIC_HP",
        "explicit_access": ""
      },
      {
        "pack_id": 111110,
        "pack_name": "GRAPHICS - KIT",
        "parent_pack_id": 1,
        "allow_custom": 1,
        "pack_description": "GRAPHICS - KIT",
        "pack_part_number": "",
        "pack_image_url": "",
        "pack_thumb_url": "",
        "pack_price": "",
        "pack_mesh_code": "",
        "subgroup_id": 161231239,
        "accessories_id": 712312367,
        "marketing_name": "Body Graphics Option - 1",
        "part_number": "A4000814-GRE",
        "description": "",
        "qty": 1,
        "flag": "Add",
        "price": 3681,
        "image_url": "",
        "thumb_url": "A4000814-GRE.png",
        "tag": "EX_ACC_18",
        "mesh": "MG_COMET_EX_ACC_BODYGRAPHICS_OPTION_1_GREEN_MESH",
        "type": "3d",
        "acces_type": "normal",
        "subgroup_name": "Body Graphics",
        "category": "Exterior",
        "maingroup_id": 1,
        "hotspot_name": "BODYGRAPHIC_HP",
        "explicit_access": ""
      }
    ]
  
}


