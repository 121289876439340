import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { CallService } from 'src/app/service/call.service';
import { DataService } from 'src/app/service/data.service';
import { GlobalService } from 'src/app/service/global.service';
import { SocketService } from 'src/app/service/socket.service';
import { EventService } from 'src/app/service/event.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import * as moment from 'moment';
declare const ONE3D: any;
@Component({
  selector: 'app-car-exchange',
  templateUrl: './car-exchange.component.html',
  styleUrls: ['./car-exchange.component.scss'],
})
export class CarExchangeComponent implements OnInit {
  carExchangeForm: FormGroup;
  HIDE_3D: boolean;
  globalPinNO: string;
  ACCESS: boolean;
  showLoading: boolean;
  callConnected: boolean;
  SOCKET_STATUS: string;
  cityList: any;
  exchangeParamList: exchangeParamList = {
    make_list: [],
    model_list: [],
    variant_data_array: [],
    year_data_array: [],
  };
  approxPrice: string;
  receivedData: any = {};
  $receiveEmiData: any;
  exchangePrice: string;
  /**
   * @saveButtonDisableFlag to control disable and enable of SAVE  @JP
   */
  // saveButtonDisableFlag: boolean = false;
  savedCity: string = '';
  userHaveLoan: boolean = false;

  selectedOption: number = -1;
  exShowRoomPrice: any;
  loanRequired: number;
  apiSelectedOption: string = '';
  customMonths: number = 11;
  customDownPayment: any;
  EMI: number;
  rateOfInterest: number = 7.25;
  customDownPaymentRange = {
    max: 0,
    min: 0,
  };
  showOptions: boolean;
  sameEMIDownPayment: number;
  sameEMITenure: number;

  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _snack: SnackbarService,
    private _socket: SocketService,
    private _callS: CallService,
    private _event: EventService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this._global.hide3D.subscribe((flag) => (this.HIDE_3D = flag));
    this._global.pinno.subscribe((flag) => (this.globalPinNO = flag));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.loader.subscribe((data) => (this.showLoading = data));
    this._global.audioStatus.subscribe((data) => (this.callConnected = data));
    this._global.socketStatus.subscribe((data) => (this.SOCKET_STATUS = data));
    this._global.exchangePrice.subscribe((data) => (this.exchangePrice = data));
    if (this._global.detectMob() && !/iPad/.test(navigator.userAgent)) {
      this._global.updateHide3D(true);
    }
    this.fetchCarExchangeData();
    this.createForm();
    this.getCityList();
    // this.getMakeList();
    this.registerSocket();
    let dateObj = new Date();
    let currentY = dateObj.getFullYear();
    for (let index = currentY; index > currentY - 15; index--) {
      // const element = array[index];

      this.exchangeParamList.year_data_array.push(index.toString());
    }
    console.log(
      'this.exchangeParamList.year_data_array',
      this.exchangeParamList.year_data_array
    );
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.toggleHaveLoan()
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this._global.updateHide3D(false);
    setTimeout(() => {
      ONE3D.event.onResizeWindow();
    }, 2000);
  }
  async fetchCarExchangeData() {
    try {
      let apiBody = {
        pin_no: this.globalPinNO,
      };
      let resp: any = await this._api.getCarExchangeData(apiBody);
      console.log('resp', resp);
      if (resp && resp.status == 1) {
        this.exShowRoomPrice = resp.response.showroom_price;
        this.customDownPaymentRange.max = Math.ceil(
          Number(this.exShowRoomPrice) * 0.5
        );
        this.customDownPaymentRange.min = Math.ceil(Number(0));
        if (resp.response.model_id != '') {
          let data: any = resp.response;
          this.savedCity = data.city;
          this.approxPrice = data.exchange_value; // added by @JP
          // this._global.update
          this._global.updateCarExchangePrice(this.approxPrice);
          this.carExchangeForm.patchValue({
            owner: data.owners,
            // drivenKM: data.drivenKM, // attribute changed by @JP
            drivenKM: data.kilometers, // attribute added  by @JP
            year: data.entry_year, // attribute added  by @JP
          });

          if (resp.response.selected_emi_option != null) {
            console.log('Inner Condtion');
            this.toggleHaveLoan();
            this.carExchangeForm.patchValue({
              haveLoan: true,
              existingEMI: resp.response.selected_emi_option.input_emi,
              existingLoanTenure:
                resp.response.selected_emi_option.input_tenure,
              downPayment: resp.response.selected_emi_option.option_downpayment,
            });
            this.apiSelectedOption = resp.response.selected_emi_option.option;
            if (this.apiSelectedOption === 'option1') {
              this.sameEMIDownPayment =
                resp.response.selected_emi_option.option_downpayment;
              this.sameEMITenure =
                resp.response.selected_emi_option.option_tenure;
            } else {
              this.customDownPayment =
                resp.response.selected_emi_option.option_downpayment;
              this.customMonths =
                resp.response.selected_emi_option.option_tenure;
            }
            //
          }
          // console.log(
          //   'Payment----',
          //   Number(resp.response.on_road_price) -
          //     Number(resp.response.selected_emi_option.loan_required) -
          //     Number(resp.response.exchange_value)
          // );

          this.receivedData = {
            city: data.city_id,
            make: data.make_year,
            model: data.model_id,
            variant: data.version_id,
          };
          this.onYearChange();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getCityList() {
    try {
      let apiBody = {};
      let apiResp: any = await this._api.getCarExchangeCityList(apiBody);
      if (apiResp && apiResp.status == 1) {
        this.cityList = apiResp.response;
        if (this.receivedData?.city && this.receivedData?.city != '') {
          console.log('City===>', this.receivedData?.city);
          setTimeout(() => {
            this.carExchangeForm.patchValue({
              city: this.receivedData.city,
            });
          }, 2000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  // async getMakeList() {
  //   try {
  //     let apiBody: makeListApiBody = {
  //       year: '',
  //       make: '',
  //       model: '',
  //     };
  //     let apiResp: any = await this._api.getCarExchangeMakeModelList(apiBody);
  //     if (apiResp && apiResp.status == 1) {
  //       console.log('apiResp.response.make_list', apiResp.response.make_list);

  //       this.exchangeParamList.year_data_array =
  //         apiResp.response.year_data_array;
  //       // console.log('this.exchangeParamList', this.exchangeParamList);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async getExchangeDetails(apiBody: makeListApiBody) {
  //   try {
  //     let apiResp: any = await this._api.getCarExchangeMakeModelList(apiBody);
  //     if (apiResp && apiResp.status == 1) {
  //       let data = apiResp.response;
  //       for (const key in data) {
  //         if (Object.prototype.hasOwnProperty.call(data, key)) {
  //           const element = data[key];
  //           if (element.length > 0) {
  //             this.exchangeParamList[key] = element;
  //           }
  //         }
  //       }
  //       if (
  //         this.receivedData.hasOwnProperty('make') &&
  //         this.receivedData.make != ''
  //       ) {
  //         this.carExchangeForm.patchValue({
  //           make: this.receivedData.make,
  //         });
  //         this.onMakeChange();
  //         this.receivedData.make = '';
  //       } else if (
  //         this.receivedData.hasOwnProperty('model') &&
  //         this.receivedData.model != ''
  //       ) {
  //         this.carExchangeForm.patchValue({
  //           model: this.receivedData.model,
  //         });
  //         this.onModelChange();
  //         this.receivedData.model = '';
  //       } else if (
  //         this.receivedData.hasOwnProperty('variant') &&
  //         this.receivedData.variant != ''
  //       ) {
  //         this.carExchangeForm.patchValue({
  //           variant: this.receivedData.variant,
  //           // city: this.receivedData.city,
  //         });
  //         this.receivedData.variant = '';
  //         if (this.receivedData.city) {
  //           this.carExchangeForm.patchValue({
  //             city: this.receivedData.city,
  //           });
  //         }
  //         this.saveExchangeDetails();
  //       }
  //       // this.exchangeParamList = apiResp.response;
  //     }
  //     //Block introduce BY @JP to patch the city value
  //     if (this.savedCity) {
  //       this.carExchangeForm.patchValue({
  //         city: this.savedCity,
  //       });
  //       this.savedCity = '';
  //     } //Block ends here
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  registerSocket() {
    this.$receiveEmiData = this._event.subscribe(
      'receive_car_exchange_data',
      (data) => {
        if (data) {
          console.log('this.cityList', this.cityList);

          this.carExchangeForm.patchValue({
            year: data.year,
            owner: data.owner,
            drivenKM: data.drivenKM,
          });
          this.receivedData = {
            city: data.city,
            make: data.make,
            model: data.model,
            variant: data.variant,
          };
          this.onYearChange();
        }
      }
    );
  }

  onMakeChange() {
    this.carExchangeForm.patchValue({
      city: '',
      model: '',
      variant: '',
    });
    this.getModelList();
    this.exchangeParamList.model_list = [];
    this.exchangeParamList.variant_data_array = [];
  }
  onModelChange() {
    this.carExchangeForm.patchValue({
      city: '',
      variant: '',
    });
    this.exchangeParamList.variant_data_array = [];

    this.getVariantList();
  }
  onYearChange() {
    this.carExchangeForm.patchValue({
      model: '',
      make: '',
      variant: '',
    });
    this.getMakeList();
    this.exchangeParamList.make_list = [];
    this.exchangeParamList.model_list = [];
    this.exchangeParamList.variant_data_array = [];
  }

  async getMakeList() {
    try {
      let apiBody = {
        year: this.carExchangeForm.value.year,
      };
      let apiResp: any = await this._api.getCarExchangeMakeList(apiBody);
      // console.log('apiResp', apiResp);
      if (apiResp.status == 1) {
        this.exchangeParamList.make_list = apiResp.response;
        console.log('this.receivedData', this.receivedData);

        if (
          this.receivedData &&
          this.receivedData.hasOwnProperty('make') &&
          this.receivedData.make != ''
        ) {
          console.log('Inner of make');

          this.carExchangeForm.patchValue({
            make: this.receivedData.make,
          });
          this.onMakeChange();
          this.receivedData.make = '';
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getModelList() {
    try {
      let apiBody = {
        makeid: this.carExchangeForm.value.make,
      };
      let apiResp: any = await this._api.getCarExchangeModelList(apiBody);
      // console.log('apiResp', apiResp);
      if (apiResp.status == 1) {
        this.exchangeParamList.model_list = apiResp.response;
        if (
          this.receivedData &&
          this.receivedData.hasOwnProperty('model') &&
          this.receivedData.model != ''
        ) {
          this.carExchangeForm.patchValue({
            model: this.receivedData.model,
          });
          this.onModelChange();
          this.receivedData.model = '';
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getVariantList() {
    try {
      let apiBody = {
        modelid: this.carExchangeForm.value.model,
        year: this.carExchangeForm.value.year,
      };
      let apiResp: any = await this._api.getCarExchangeModeVariantlList(
        apiBody
      );
      // console.log('apiResp', apiResp);
      if (apiResp.status == 1) {
        this.exchangeParamList.variant_data_array = apiResp.response;
        if (
          this.receivedData &&
          this.receivedData.hasOwnProperty('variant') &&
          this.receivedData.variant != ''
        ) {
          this.carExchangeForm.patchValue({
            variant: this.receivedData.variant,
          });
          this.receivedData.variant = '';
          console.log(this.receivedData);

          if (this.apiSelectedOption && this.apiSelectedOption != '') {
            // this.onCalculate();
            this.showOptions = true;
            if (this.apiSelectedOption != '') {
              if (this.apiSelectedOption == 'option1') {
                this.selectedOption = 1;
              } else if (this.apiSelectedOption == 'option2') {
                this.selectedOption = 2;
              } else {
                this.selectedOption = -1;
              }
            } else {
              this.selectedOption = 1;
            }
            this.calculateSameEMIDownPayment();
            setTimeout(() => {
              this.onCalculate();
              this.calculateCustomEmi();
            }, 100);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  createForm() {
    this.carExchangeForm = new FormGroup({
      city: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      make: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      variant: new FormControl('', [Validators.required]),
      owner: new FormControl('', [Validators.required]),
      drivenKM: new FormControl('', [Validators.required, Validators.min(500)]),
      haveLoan: new FormControl(false, [Validators.required]),
      existingEMI: new FormControl(''),
      existingLoanTenure: new FormControl(''),
      downPayment: new FormControl(''),
    });
  }

  onDrivenBlur() {
    console.log(this.carExchangeForm.get('drivenKM'));
  }

  goBack() {
    // let url = defaultCarDetails[this.loadedCar].urlScheme;
    // this.router.navigate([`${url}/accessorize`]);
    this.location.back();
    this._global.updateCarExchangePrice('');
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 1000); // resize animation conflict issue fixed
  }
  show3d() {
    this._global.updateHide3D(false);
  }

  updateExchangeData() {
    this._global.updateCarExchangePrice(this.approxPrice);
    this.saveCarExchangeData();
    this.saveCustomerEmi();
    this.location.back();
  }

  /**
   *
   * @param controlName controller whose value needs to check
   * @param errorName type of error needs to check
   * @returns boolean
   */
  hasError = (controlName: string, errorName: string) =>
    (this.carExchangeForm.controls[controlName].touched ||
      this.carExchangeForm.controls[controlName].dirty) &&
    this.carExchangeForm.controls[controlName].hasError(errorName);

  async saveExchangeDetails() {
    try {
      console.log('this.carExchangeForm.valid', this.carExchangeForm.valid);

      if (this.carExchangeForm.valid) {
        let { city, year, make, model, variant, owner, drivenKM } =
          this.carExchangeForm.value;
        let apiBody = {
          kilometers: drivenKM,
          owners: owner,
          entryYear: year,
          makeYear: make,
          valuationType: 'Buy',
          versionId: variant,
          cityId: city,
        };
        let apiResp: any = await this._api.getCarExchangeValuation(apiBody);
        if (apiResp) {
          // console.log('saved');
          if (apiResp.price == 0) {
            this.approxPrice = '';
            this._snack.openSnackBar('No Data Found', 'Error');
            // this.saveButtonDisableFlag = true; // flag to enable or diable SAVE  by @JP
          } else {
            this.approxPrice = apiResp.price.toString();
            this._global.updateCarExchangePrice(this.approxPrice);
          }
        }
      } else {
        Object.keys(this.carExchangeForm.controls).forEach((field) => {
          const control = this.carExchangeForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  get saveButtonDisableFlag() {
    return this.carExchangeForm.valid && this.selectedOption != -1;
  }

  async saveCarExchangeData() {
    try {
      let { city, year, make, model, variant, owner, drivenKM } =
        this.carExchangeForm.value;
      let makeText: string, modelText: string, variantText: string;

      let selectedMake: any = this.exchangeParamList.make_list.find(
        (ele: any) => ele.makeId == make
      );
      if (selectedMake) {
        makeText = selectedMake.makeName;
      }
      let selectedM: any = this.exchangeParamList.model_list.find(
        (ele: any) => ele.ModelId == model
      );
      if (selectedM) {
        modelText = selectedM.ModelName;
      }
      let selectedV: any = this.exchangeParamList.variant_data_array.find(
        (ele: any) => ele.ID == variant
      );
      if (selectedV) {
        variantText = selectedV.Name;
      }
      let apiBody = {
        pin_no: this.globalPinNO,
        modelid: model,
        versionId: variant,
        cityId: city,
        makeYear: make,
        owners: owner,
        kilometers: drivenKM,
        exchange_value: this.approxPrice ? this.approxPrice : 0,
        entryYear: year,
        valuationType: 'Buy',
        make: makeText,
        model: modelText,
        variant: variantText,
      };
      let apiReq: any = await this._api.saveCarExchangeData(apiBody);
      if (apiReq) {
        console.log('SAved');
        this.approxPrice = ''; //reseting the variable
      }
    } catch (error) {
      console.error(error);
    }
  }

  // priceIndianFormatter(price: number) {
  //   return price.toLocaleString('en-IN');
  // }

  toggleHaveLoan() {
    this.userHaveLoan = !this.userHaveLoan;
    const validators = [Validators.required];
    const exitingLoanTenureValidator = [
      Validators.required,
      Validators.max(120),
      Validators.min(11),
    ];

    if (this.userHaveLoan) {
      this.carExchangeForm.get('existingEMI').setValidators(validators);
      this.carExchangeForm
        .get('existingLoanTenure')
        .setValidators(exitingLoanTenureValidator);
      // this.carExchangeForm.get('downPayment').setValidators(validators);
    } else {
      this.carExchangeForm.get('existingEMI').setValidators(null);
      this.carExchangeForm.get('existingLoanTenure').setValidators(null);
      // this.carExchangeForm.get('downPayment').setValidators(null);
    }

    this.carExchangeForm.get('existingEMI').updateValueAndValidity();
    this.carExchangeForm.get('existingLoanTenure').updateValueAndValidity();
    // this.carExchangeForm.get('downPayment').updateValueAndValidity();
    console.log('this.carExchangeForm', this.carExchangeForm);
    if (this.showOptions) {
      this.showOptions = false;
    }
    this.apiSelectedOption = '';
  }

  // async calculateExchange() {
  //   try {
  //     console.log('Called');
  //     if (this.carExchangeForm.valid) {
  //       let apiBody = {
  //         existing_emi: this.carExchangeForm.value.existingEMI,
  //         loan_tenure: this.carExchangeForm.value.existingLoanTenure,
  //         down_payment: this.carExchangeForm.value.downPayment,
  //         pin_no: this.globalPinNO,
  //       };
  //       let resp: any = await this._api.calculateEmiCarExchangeData(apiBody);
  //       if (resp.status == 1) {
  //         this.optionEmiList = resp.response.options;
  //         console.log('this.optionEmiList', this.optionEmiList);
  //       } else {
  //         this._snack.openSnackBar('No Data Found', 'Error');
  //       }
  //     } else {
  //       console.log('Error..', this.carExchangeForm);

  //       Object.keys(this.carExchangeForm.controls).forEach((field) => {
  //         const control = this.carExchangeForm.get(field);
  //         control.markAsTouched({ onlySelf: true });
  //         control.markAsDirty({ onlySelf: true });
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  onSelectOption(index: number) {
    this.selectedOption = index;
  }

  formatCurrency(value: any) {
    // console.log('value', value);
    // value = value.toString();
    if (value == 0 || value == '0') return 0;
    return value
      ? isNaN(value)
        ? 'xx,xx,xxx'
        : Number(value).toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
      : null;
  }

  onChangeCustomMonthSlider() {
    console.log(this.customMonths);

    if (this.customMonths > 0 && this.customMonths <= 120) {
      this.calculateCustomEmi();
    } else {
      this._snack.openSnackBar(
        "Loan Tenure can't be larger than 120 or smaller than 0",
        'Error'
      );
      this.customMonths = 120;
      this.calculateCustomEmi();
    }
  }
  onChangeCustomDownPaymentSlider() {
    if (
      this.customDownPayment > this.customDownPaymentRange.min &&
      this.customDownPayment <= this.customDownPaymentRange.max
    ) {
      this.calculateCustomEmi();
    } else {
      this._snack.openSnackBar('Down Payment Error', 'Error');
      this.customDownPayment = this.customDownPaymentRange.min;
      this.calculateCustomEmi();
    }
  }

  calculateCustomEmi() {
    let loanRequired =
      this.exShowRoomPrice -
      (Number(this.approxPrice) + Number(this.customDownPayment));
    let perMonthROI = Number(this.rateOfInterest) / (12 * 100);
    this.EMI = Math.ceil(
      Number(
        (loanRequired *
          perMonthROI *
          Math.pow(1 + perMonthROI, Number(this.customMonths))) /
          (Math.pow(1 + perMonthROI, Number(this.customMonths)) - 1)
      )
    );
    console.log('this.EMI-->', this.EMI);
  }

  async onCalculate() {
    if (this.carExchangeForm.valid) {
      await this.saveExchangeDetails();
      this.calculateSameEMIDownPayment();
      this.showOptions = true;
      if (this.apiSelectedOption != '') {
        if (this.apiSelectedOption == 'option1') {
          this.selectedOption = 1;
        } else if (this.apiSelectedOption == 'option2') {
          this.selectedOption = 2;
        } else {
          this.selectedOption = -1;
        }
      } else {
        this.selectedOption = 1;
      }
    } else {
      console.log('Error..', this.carExchangeForm);
      Object.keys(this.carExchangeForm.controls).forEach((field) => {
        const control = this.carExchangeForm.get(field);
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      });
    }
  }

  calculateSameEMIDownPayment() {
    const { existingEMI, existingLoanTenure } = this.carExchangeForm.value;
    this.loanRequired = this.exShowRoomPrice - Number(this.approxPrice);
    const downPayment = Math.ceil(
      this.loanRequired - existingEMI * existingLoanTenure
    );
    const calculatedMonth = this.loanRequired / existingEMI;

    console.log('calculatedMonth--->', calculatedMonth);

    if (calculatedMonth <= 120) {
      this.sameEMIDownPayment = 0;
      this.sameEMITenure = Math.ceil(calculatedMonth);
    } else {
      const payedAmount = existingEMI * 120;
      this.sameEMIDownPayment = Math.ceil(this.loanRequired - payedAmount);
      this.sameEMITenure = 120;
      console.log(
        'this.sameEMIDownPayment',
        this.sameEMIDownPayment,
        payedAmount
      );
    }
    if (this.apiSelectedOption == '' || this.apiSelectedOption === 'option1') {
      this.customDownPayment = this.sameEMIDownPayment;
      this.customMonths = this.sameEMITenure;
    }
    // if (downPayment > this.customDownPaymentRange.max) {
    //   this._snack.openSnackBar(
    //     "Downpayemnt can't be larger than max range.",
    //     'Error'
    //   );
    //   this.carExchangeForm.patchValue({
    //     downPayment: this.customDownPaymentRange.max,
    //   });
    //   this.customDownPayment = this.customDownPaymentRange.max;
    //   this.customMonths = 11;
    // } else if (downPayment < 11) {
    //   this._snack.openSnackBar(
    //     "Downpayemnt can't be smaller than 11.",
    //     'Error'
    //   );
    //   this.carExchangeForm.patchValue({
    //     downPayment: 0,
    //   });
    //   this.customDownPayment = 0;
    //   this.customDownPaymentRange.min = 0;
    //   this.customMonths = 11;
    //   console.log(this.carExchangeForm.value);
    // } else {
    //   this.carExchangeForm.patchValue({
    //     downPayment,
    //   });
    //   this.customDownPayment = downPayment;
    //   this.customMonths = this.carExchangeForm.value.existingLoanTenure;
    // }
    console.log(
      ' this.customDownPayment',
      this.customDownPayment,
      this.loanRequired
    );

    this.calculateCustomEmi();
  }

  async saveCustomerEmi() {
    try {
      if (this.carExchangeForm.valid && this.selectedOption != -1) {
        const { existingEMI, existingLoanTenure, downPayment } =
          this.carExchangeForm.value;
        const option = this.selectedOption == 1 ? 'option1' : 'option2';
        const optionTenure =
          this.selectedOption == 1 ? this.sameEMITenure : this.customMonths;
        const optionDownPayment =
          this.selectedOption == 1
            ? this.sameEMIDownPayment
            : this.customDownPayment;
        let apiBody = {
          option: option,
          option_downpayment: optionDownPayment,
          option_emi_value: this.EMI,
          option_tenure: optionTenure,
          pin_no: this.globalPinNO,
          input_emi: existingEMI,
          input_tenure: existingLoanTenure,
        };
        let resp: any = await this._api.saveEmiCarExchangeData(apiBody);
        if (resp.status == 1) {
          this._snack.openSnackBar('Data Saved!', 'Success');
        } else {
          this._snack.openSnackBar('No Data Found', 'Error');
        }
      } else {
        console.log('Error..', this.carExchangeForm);

        Object.keys(this.carExchangeForm.controls).forEach((field) => {
          const control = this.carExchangeForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // createOptions() {
  //   console.log('this.approxPrice', this.approxPrice);
  //   if (this.carExchangeForm.valid) {
  //     if (this.approxPrice && this.approxPrice != '') {
  //       this.optionEmiList = [];
  //       const { existingEMI, existingLoanTenure, downPayment } =
  //         this.carExchangeForm.value;
  //       console.log('downPayment', downPayment);

  //       let loanRequired =
  //         this.exShowRoomPrice -
  //         (Number(this.approxPrice) + Number(downPayment));

  //       let roi = 7.25 / 1200;
  //       let op1 = Math.ceil(
  //         (Math.log(existingEMI) - Math.log(existingEMI - loanRequired * roi)) /
  //           Math.log(1 + roi)
  //       );
  //       let emi = null;
  //       if (op1 > 160 || isNaN(op1)) {
  //         this._snack.openSnackBar(
  //           "Tenure can't be more than 160 months, updated the minimum EMI",
  //           'Error'
  //         );
  //         emi = Math.ceil(
  //           Number(
  //             (loanRequired * roi * Math.pow(1 + roi, Number(160))) /
  //               (Math.pow(1 + roi, Number(160)) - 1)
  //           )
  //         );
  //         console.log('emi--->', emi, loanRequired, roi);

  //         this.carExchangeForm.patchValue({
  //           existingEMI: emi,
  //         });
  //         op1 = Math.ceil(
  //           (Math.log(emi) - Math.log(emi - loanRequired * roi)) /
  //             Math.log(1 + roi)
  //         );
  //       }
  //       // console.log('loanRequired', loanRequired);

  //       // console.log(op1);
  //       let objForOption1 = {
  //         option: 'option1',
  //         title: 'Same EMI - Change in Tenure ( C )',
  //         emi: emi != null ? emi : existingEMI,
  //         tenure: op1,
  //       };
  //       let op2 = Math.ceil(
  //         Number(
  //           (loanRequired *
  //             roi *
  //             Math.pow(1 + roi, Number(existingLoanTenure))) /
  //             (Math.pow(1 + roi, Number(existingLoanTenure)) - 1)
  //         )
  //       );

  //       // console.log(op2);
  //       let objForOption2 = {
  //         option: 'option2',
  //         title: 'Normal EMI - Same Tenure',
  //         emi: op2,
  //         tenure: existingLoanTenure,
  //       };
  //       this.loanRequired = loanRequired;
  //       this.optionEmiList.push(objForOption1);
  //       this.optionEmiList.push(objForOption2);
  //       console.log('this.optionEmiList', this.optionEmiList);

  //       if (this.apiSelectedOption != '') {
  //         if (this.apiSelectedOption == 'option1') {
  //           this.selectedOption = 0;
  //         } else if (this.apiSelectedOption == 'option2') {
  //           this.selectedOption = 1;
  //         } else {
  //           this.selectedOption = -1;
  //         }
  //       } else {
  //         this.selectedOption = 0;
  //       }
  //     } else {
  //       this._snack.openSnackBar(
  //         'Please fill the previous form to get the result.',
  //         'Error'
  //       );
  //     }
  //   } else {
  //     console.log('Error..', this.carExchangeForm);

  //     Object.keys(this.carExchangeForm.controls).forEach((field) => {
  //       const control = this.carExchangeForm.get(field);
  //       control.markAsTouched({ onlySelf: true });
  //       control.markAsDirty({ onlySelf: true });
  //     });
  //   }
  // }

  filterOnLaunchDate(date: string): boolean {
    const year = Number(moment(date).format('YYYY'));
    // console.log(year);
    return year == this.carExchangeForm.value['year'];
  }
}

interface makeListApiBody {
  make: string;
  model: string;
  year: string;
}
interface exchangeParamList {
  make_list: string[];
  model_list: string[];
  variant_data_array: string[];
  year_data_array: string[];
}
