import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { CarouselModule } from 'ngx-owl-carousel-o';

import { ThreeDModuleRoutingModule } from './three-d-module-routing.module';
import { HectorWebComponent } from './hector-web/hector-web.component';
import { VariantsComponent } from './variants/variants.component';
import { AccessorizeListComponent } from './accessorize-list/accessorize-list.component';
import { SummaryComponent } from './summary/summary.component';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
// import { OliviaAudioComponent } from '../shared/olivia-audio/olivia-audio.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmiCalculatorComponent } from './emi-calculator/emi-calculator.component';

import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ProtectionPlanComponent } from './protection-plan/protection-plan.component';

// import { VJsPlayerDirective } from '../directives/v-js-player.directive';
import { SharedModule } from '../shared/shared.module';
import { KModelComponent } from './k-model/k-model.component';
import { LocationModalComponent } from './location-modal/location-modal.component';
import { AccDetailsComponent } from './acc-details/acc-details.component';
import { SelfExploreComponent } from './self-explore/self-explore.component';
import { CarCompareComponent } from './car-compare/car-compare.component';
import { ThankYouModalComponent } from './thank-you-modal/thank-you-modal.component';
import { MgShieldComponent } from './mg-shield/mg-shield.component';
import { AccRemoveModalComponent } from './acc-remove-modal/acc-remove-modal.component';
import { SingleFeaturePlayComponent } from './single-feature-play/single-feature-play.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { ArVideoOverlayComponent } from './ar-video-overlay/ar-video-overlay.component';
import { EndCallModalComponent } from './end-call-modal/end-call-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ZsevModelComponent } from './zsev-model/zsev-model.component';
import { HectorPlusComponent } from './hector-plus/hector-plus.component';
import { GlosterModelComponent } from './gloster-model/gloster-model.component';
import { CarExchangeComponent } from './car-exchange/car-exchange.component';
import { NewHectorNextGenComponent } from './new-hector-next-gen/new-hector-next-gen.component';
import { NewHectorplusNextGenComponent } from './new-hectorplus-next-gen/new-hectorplus-next-gen.component';
import { EModelComponent } from './e-model/e-model.component';
import { LimitedEditionAccessoriesComponent } from './limited-edition-accessories/limited-edition-accessories.component';
import { OverlappingModalComponent } from '../overlapping-modal/overlapping-modal.component';
import { FeaturesListComponent } from './features-list/features-list.component';

@NgModule({
  declarations: [
    HectorWebComponent,
    VariantsComponent,
    AccessorizeListComponent,
    SummaryComponent,
    HomeComponent,
    FeaturesComponent,
    SummaryComponent,
    EmiCalculatorComponent,
    ProtectionPlanComponent,
    KModelComponent,
    LocationModalComponent,
    AccDetailsComponent,
    SelfExploreComponent,
    CarCompareComponent,
    ThankYouModalComponent,
    MgShieldComponent,
    AccRemoveModalComponent,
    SingleFeaturePlayComponent,
    FeedbackFormComponent,
    ArVideoOverlayComponent,
    EndCallModalComponent,
    ZsevModelComponent,
    HectorPlusComponent,
    GlosterModelComponent,
    CarExchangeComponent,
    NewHectorNextGenComponent,
    NewHectorplusNextGenComponent,
    EModelComponent,
    LimitedEditionAccessoriesComponent,
    OverlappingModalComponent,
    FeaturesListComponent
    // SessionWarningComponent,
  ],
  imports: [
    CommonModule,
    // CarouselModule,
    ThreeDModuleRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MatSliderModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
})
export class ThreeDModuleModule {}
