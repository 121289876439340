import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { GaService } from '../../service/ga.service';
import { SocketService } from '../../service/socket.service';
import { CallService } from '../../service/call.service';
import { MgExpertService } from '../../service/mg-expert.service';
import { SnackbarService } from '../../service/snackbar.service';
import { appointmentTimings, defaultCarDetails } from '../../three-d-module/carSettings';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EventService } from 'src/app/service/event.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-appointment-form',
  templateUrl: './appointment-form.component.html',
  styleUrls: ['./appointment-form.component.scss'],
})
export class AppointmentFormComponent implements OnInit {
  queryForm: FormGroup;
  pinNo: string;
  loadedCar: any;
  dealerDetails: any;
  socketStatus: string;
  isChecked: boolean = true;
  oldNo: any;
  enableSendOTPButton: boolean;
  enableTimer: boolean;
  showResendOtpCounter: boolean;
  showOTPField: boolean;
  countdown: number;
  interval: any;
  failedVerifiedOpt: boolean;
  userData: any;
  showAgentBusy:boolean;
  minDate: Date = moment().add(1, 'd').toDate();
  maxDate: Date = moment().add(15, 'd').toDate();
  @Input() isScheduleButtonClick: boolean;
  @Output() cancelAppointment = new EventEmitter<any>();
  @Output() connectNowClicked = new EventEmitter<any>();
  @Output() appointmentChanged = new EventEmitter<any>();
  timeSlots = appointmentTimings;
  $dataTransfer: Subscription;
  showConnectButton: any;
  showAppointmentForm: boolean;

  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _ga: GaService,
    private _socket: SocketService,
    private _expert: MgExpertService,
    private _snack: SnackbarService,
    private _event: EventService,
  ) { }

  ngOnInit(): void {
    this._data.dismissUIBlockLayer();
    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this._global.socketStatus.subscribe((data) => (this.socketStatus = data));
    this.userData = this._data.geUserDataInfo();
    console.log('this.userData', this.userData);
    this.createForm();
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.dealerDetailsForToken.subscribe(
      (data) => (this.dealerDetails = data)
    );
    setTimeout(() => {
      this.$dataTransfer = this._event.subscribe('show_connect', (data) => {
        this.showConnectButton = data;
      });
    }, 10);
    console.log('isScheduleButtonClick --->', this.isScheduleButtonClick);
    this._global.agentBusy.subscribe((data)=>{
      console.log("The data I am getting is", data);
      this.showAgentBusy = data;
      if (!data || (typeof data === 'object' && Object.keys(data).length === 0)) {
        this.showAppointmentForm = false;
    } else {
        this.showAppointmentForm = true;
    }
    
    });
  }
  ngAfterViewChecked() {

  }
  ngOnDestroy(): void {
    if (this.$dataTransfer) {
      this.$dataTransfer.unsubscribe();
    }
  }
  createForm() {
    const regx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    this.queryForm = new FormGroup({
      name: new FormControl(
        this.userData && this.userData.first_name
          ? `${this.userData.first_name}`
          : '',
        this.userData && this.userData.first_name ? [] : [Validators.required]
      ),
      mobile: new FormControl(
        this.userData && this.userData.mobile ? `${this.userData.mobile}` : '',
        {
          validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
        }
      ),
      email: new FormControl(
        this.userData && this.userData.email ? `${this.userData.email}` : '',
        {
          validators: [
            Validators.pattern(regx),
          ],
        }
      ),
      otp: new FormControl('', {
        validators:
          this.userData && this.userData.mobile ? [] : [Validators.required],
      }),
      selectedDate: new FormControl('', {
        validators: [Validators.required],
      }),
      selectedTime: new FormControl('', {
        validators: [Validators.required],
      }),
    });
    console.log(' this.queryForm ', this.queryForm);

    // this.queryForm.invalid
  }

  public hasError = (controlName: string, errorName: string) =>
    (this.queryForm.controls[controlName].touched ||
      this.queryForm.controls[controlName].dirty) &&
    this.queryForm.controls[controlName].hasError(errorName);

  closeModal(type) {
    this._ga.sendGAEvent(type, 'click', 'close');
    this._global.updateAppointmentForm(false);
    this.cancelAppointment.emit();
  }
  socketConnect() {
    // this._socket.socket.connect();
    this._socket.setupSocketConnection();
  }
  openTancCpopup() {
    this._global.toggleTCModal(true);
  }

  onChangeMobileNo() {
    if (this.queryForm.get('mobile').valid) {
      if (this.oldNo != this.queryForm.value.mobile) {
        this.enableSendOTPButton = true;
        this.oldNo = this.queryForm.value.mobile;
        this.enableTimer = false;
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
      }
    }
  }
  async sendOTP() {
    try {
      this._global.showCircleLoader(true);
      let payload = { mobile: this.queryForm.value.mobile, sourec: 'web' };
      let resp: string = await this._api.sendOtp(payload);

      let apiResp: any = JSON.parse(atob(resp));
      console.log('resp', apiResp);
      if (apiResp.status == 1) {
        this._global.showCircleLoader(false);
        this.enableSendOTPButton = false;
        this.enableTimer = true;
        this.showResendOtpCounter = true;
        this.countdown = 30;
        this.interval = setInterval(() => {
          this.resendOtpCounter();
        }, 1000);
        this._ga.sendGAEvent('appointment', 'Login_Intro', 'Send_OTP');
      } else {
        this._global.showCircleLoader(false);
      }
    } catch (error) {
      this._global.showCircleLoader(false);
      console.error(error);
    }
  }
  resendOtpCounter() {
    if (this.countdown == 0) {
      clearInterval(this.interval);
      this.showResendOtpCounter = false;
    } else {
      // this.showResendOtpCounter = true;
      this.countdown--;
    }
  }

  async createAppointment() {
    // $e.preventDefault();
    try {
      console.log('this.queryForm.value', this.queryForm);
      let selectedDate = moment(this.queryForm.value.selectedDate);
      let loadedCar = this._data.getLocalCarBrand();
      if (this.queryForm.valid) {
        this._global.showCircleLoader(true)
        let apiData = {
          pin_no: this.pinNo,
          customer_name: this.queryForm.value.name,
          mobile: this._data.encryptData(
            this.queryForm.value.mobile.toString()
          ),
          appointment_time: `${selectedDate.format('YYYY-MM-DD')} ${this.queryForm.value.selectedTime
            }`,
          model_id: defaultCarDetails[loadedCar].model_id,
          customer_email: this.queryForm.value.email,
          optin_whatsapp: '',
          utm_source: '',
          utm_medium: '',
          utm_campaign: '',
        };
        console.log('apiData', apiData);
        let apiRespData: any = await this._expert.generateAppointmentLead(
          apiData
        );
        if (apiRespData.status == 200) {
          this._global.showCircleLoader(false);
          this._ga.sendGAEvent('schedule', 'click', 'submit');
          console.log('apiRespData', apiRespData);
          //#region created by @Animesh for display success popup

          // this._snack.openSnackBar(apiRespData.msg, 'Success');
          // send event emitter to parent to show the success modal
          this.appointmentChanged.emit(apiRespData);
          this._global.updateShowRescheduleBtnFlag(false);
          this._global.updateAppointmentSuccessModal(true);

          //#endregion

          this._global.updateAppointmentForm(false);
          window.sendData({
            eName: 'internal',
            eCat: 'car_config_page',
            eAct: 'appointment_scheduled',
            eVal: `${selectedDate.format('YYYY-MM-DD')} ${this.queryForm.value.selectedTime
              }`,
          });
        } else {
          this._global.showCircleLoader(false);
          this.failedVerifiedOpt = true;
          this._snack.openSnackBar(apiRespData.msg, 'Error');
        }
      } else {
        console.log('Here 2', this.queryForm);
        Object.keys(this.queryForm.controls).forEach((field) => {
          const control = this.queryForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      this._global.showCircleLoader(false);
      console.error(error);
    }
  }

  async onSubmit() {
    // $e.preventDefault();
    try {
      this._ga.sendGAEvent("Pulsehub Icon", "click", "Schedule Call");
      window.sendLiveDashboardData({
        name: 'schedule_call_for_later',
        category: 'pulsehub icon_schedule',
        type: 'MG_eXpert',
      });
      console.log('this.queryForm.value', this.queryForm.value);
      this.failedVerifiedOpt = false;
      if (this.queryForm.valid) {
        // if (this.showOTPField) {
        //   clearInterval(this.interval);
        //   this.showResendOtpCounter = false;
        //   this.countdown = 30;
        //   let apiData = {
        //     mobile: this.queryForm.value.mobile.toString(),
        //     otp: this.queryForm.value.otp.toString(),
        //     pin_no: this._data.getLocalPinNo(),
        //     customer_name: this.queryForm.value.name,
        //     source: 'web',
        //   };
        //   let resp: string = await this._api.customerRegistration(apiData);
        //   let apiRespData: any = JSON.parse(atob(resp));
        //   if (apiRespData.status == 1) {
        //     this._ga.sendGAEvent('pulsehub number', 'click', 'confirm');
        //     this._data.setUserDataInfo(apiRespData.response);
        //     sessionStorage.setItem('mobile', this.queryForm.value.phone);
        //     this.createAppointment();
        //     this._global.updateHide3D(false);
        //     // this._ga.sendGAEvent('lead', 'click', 'confirm');
        //   } else {
        //     this.failedVerifiedOpt = true;
        //   }
        // } else {
        this.createAppointment();
        // }
      } else {
        console.log('Here 2', this.queryForm);

        Object.keys(this.queryForm.controls).forEach((field) => {
          const control = this.queryForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * close the appointment form
   */
  closeAppointmentForm() {
    this._global.updateAppointmentForm(false);
    this.cancelAppointment.emit();
  }
  /**
   * connect now clicked
   */
  connectNow(page) {
    this._ga.sendGAEvent("Pulsehub Icon", "click", page + " - Connect Now");
    window.sendLiveDashboardData({
      name: 'Connect Now',
      category: 'pulsehub icon_connectnow',
      type: 'MG_eXpert',
    });
    this.connectNowClicked.emit();
  }
  logGA() {
    this._ga.sendGAEvent("Pulsehub Icon", "click", "Schedule a call for later");
    window.sendLiveDashboardData({
      name: 'Schedule Call',
      category: 'pulsehub',
      type: 'MG_eXpert',
    });
  }
}
