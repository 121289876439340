// vjs-player.component.ts
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import videojs from 'video.js';
import { GlobalService } from '../service/global.service';

@Component({
  selector: 'app-vjs-player',
  template: `
    <video 
      #target
      class="video-js vjs-default-skin vjs-big-play-centered"
      style="width: 100%; height: 100%;"
      [muted]="muted"
      autoplay
      preload="none"
      (ended)="calledVideoEnded()"
      (error) ="onErrEvent($event)"
    ></video>

  `,
  
  encapsulation: ViewEncapsulation.None,
})
export class VJsPlayer implements OnInit, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() muted: boolean;
  @Output() videoCompleted: EventEmitter<boolean> = new EventEmitter();
  /**
   * On Error playing the video, emit an event to get the mp4 link @JP
   */
  @Output() onError: EventEmitter<boolean> = new EventEmitter();
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    controls: boolean;
    loop: boolean;
    sources: {
      src: string;
      type: string;
    }[];
  };
  player: videojs.Player;
  constructor(private elementRef: ElementRef, private _global: GlobalService) {}

  ngOnInit() {
    console.log('Videojs', this.muted);

    // instantiate Video.js
    // this._global.isSoundMuted.subscribe((data) => (this.isSoundMuted = data));
    this.player = videojs(
      this.target.nativeElement,
      this.options,
      function onPlayerReady() {
        console.log('onPlayerReady', this);
        var self = this;
        self.on('timeupdate', function () {
          console.log(this.currentTime());
        });
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('OnChanges video', changes);
    if (
      changes.hasOwnProperty('options') &&
      changes.options.currentValue &&
      this.player
    ) {
      this.player.src(changes.options.currentValue.sources);
    }
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
  calledVideoEnded() {
    this.videoCompleted.emit(true);
  }
  loggedDuration(sec) {
    console.log('playing', sec);
  }
    /**
   * On Error playing the video, emit an event to get the mp4 link  from intro component  @JP
   */
  onErrEvent(e){
    this.onError.emit(true)
  }
}
