import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import { DataService } from 'src/app/service/data.service';
import { GlobalService } from 'src/app/service/global.service';
import { SocketService } from 'src/app/service/socket.service';
import { EventService } from 'src/app/service/event.service';
import { Subscription } from 'rxjs';

import { defaultCarDetails } from '../carSettings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from '../../service/snackbar.service';

declare var ONE3D: any;
declare var window: any;
@Component({
  selector: 'app-emi-calculator',
  templateUrl: './emi-calculator.component.html',
  styleUrls: ['./emi-calculator.component.scss'],
})
export class EmiCalculatorComponent implements OnInit {
  exShowRoomPrice: string;
  exShowRoomPriceNumber: number = 0;
  downPayment: number = 100000;
  months: number = 20;
  rateOfInterest: number = 7.25;
  financeNeeded: number = 0;
  EMI: number = 0;
  loadedCar: any;
  globalPinNO: string;
  EMI_OPTION: boolean;
  ACCESS: boolean;
  DISPLAY_TO_CUSTOMER: any;
  $receiveEmiData: Subscription;
  callConnected: boolean;
  emiOption: any;
  showErrorMsg: boolean;
  summaryFlag: boolean = false;
  constructor(
    private _api: ApiService,
    private globalService: GlobalService,
    private dataService: DataService,
    private location: Location,
    private _snack: SnackbarService,
    private _socket: SocketService,
    private _event: EventService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    console.log('ngInit called');
    this.globalService.showRightPanelToCustomer.subscribe(
      (data) => (this.DISPLAY_TO_CUSTOMER = data)
    );
    this.globalService.audioStatus.subscribe(
      (data) => (this.callConnected = data)
    );
    this.globalService.emiOption.subscribe((data) => {
      this.emiOption = data;

      console.log('sss', Object.keys(this.emiOption).length);
      if (Object.keys(this.emiOption).length == 0) {
        this.EMI_OPTION = false;
      } else {
        this.EMI_OPTION = true;

        this.downPayment = data.downPayment;
        this.months = data.tenure;
        this.rateOfInterest = data.roi;
        this.financeNeeded = data.financeNeeded;
      }
    });

    var ua = navigator.userAgent;
    if (this.globalService.detectMob() && !/iPad/.test(navigator.userAgent)) {
      this.globalService.updateHide3D(true);
    }
    this.loadedCar = this.dataService.getLocalCarBrand();
    this.globalService.pinno.subscribe((data) => (this.globalPinNO = data));
    this.globalService.accessUi.subscribe((data) => (this.ACCESS = data));

    this.globalService.summaryFlag.subscribe(
      (data) => (this.summaryFlag = data)
    );

    this.getCarData();
    this.registerSocket();
    window.sendData({eName:'page_view',eCat:'emi_page'})
  }

  ngOnDestroy(): void {
    console.log('ngOnDestroy called');
    var ua = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    )
      this.$receiveEmiData.unsubscribe();
    /**
     * Conditions added by @JP
     * to make full screen on Emi Close
     */
    if (this.globalService.detectMob()) {
      if (this.summaryFlag) {
        this.globalService.updateHide3D(true);
      } else {
        this.globalService.updateHide3D(false);
      }
    } else {
      this.globalService.updateHide3D(false);
    }
  }

  getCarData() {
    let pin_no = this.dataService.getLocalPinNo();
    this.globalService.showLoading(true);
    let apiData = {
      source: 'web',
      pin_no: pin_no,
    };
    this._api.getBuildDetails(apiData).then((data: any) => {
      this.globalService.showLoading(false);
      console.log('data', data);
      if (data.status == 1) {
        this.exShowRoomPrice = data.response.summary.showroom_price;
        this.exShowRoomPriceNumber = Number(
          data.response.summary.showroom_price.replace(/,/g, '')
        );
        this.financeNeeded = Number(
          this.exShowRoomPriceNumber - this.downPayment
        );
        this.calculateEMI();
      }
    });
  }
  /**
   * calculate Emi on change of months and ROI
   */
  calculateEMI() {
    console.log('this.rate', this.rateOfInterest);

    console.log('this.financeNeeded', this.exShowRoomPriceNumber);
    if (Number(this.exShowRoomPriceNumber) >= Number(this.downPayment)) {
      this.showErrorMsg = false;
      this.financeNeeded = Number(
        this.exShowRoomPriceNumber - this.downPayment
      );
      let perMonthROI = Number(this.rateOfInterest) / (12 * 100);
      this.EMI = Math.ceil(
        Number(
          (this.financeNeeded *
            perMonthROI *
            Math.pow(1 + perMonthROI, Number(this.months))) /
            (Math.pow(1 + perMonthROI, Number(this.months)) - 1)
        )
      );
    } else {
      this.downPayment = Number(this.exShowRoomPriceNumber);
      this.showErrorMsg = true;
    }
  }
  sendToOther() {
    if (this.ACCESS) {
      const sendData= {
        room: this._socket.room,
        dp: this.downPayment,
        roi: this.rateOfInterest.toFixed(2),
        lt: this.months,
      };
      this._socket.socket.emit('send_emi_data', sendData);
    } else {
      console.log('you have no access');
    }
  }
  /**
   * close emi calculator and back to summary page
   */
  cancelEmi() {
    window.sendData({eName:'click',eCat:'emi_page',eAct:"cancel_button_clicked"})
    if (!this.EMI_OPTION) {
      this.globalService.updateEmiOptions(false);
    }
    // let url = defaultCarDetails[this.loadedCar].urlScheme;
    // this.router.navigate([`${url}/summary`]);
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 1000);

    this.location.back();
    // this.router.navigate(["/hector/summary"]);
  }
  /**
   * save Emi data
   */
  async saveEmi() {
    window.sendData({eName:'click',eCat:'emi_page',eAct:"saved_button_clicked"})
    try {
      if (Number(this.downPayment) > 0) {
        let apiBody = {
          pin_no: this.globalPinNO,
          down_payment: Number(this.downPayment),
          interest_rate: this.rateOfInterest,
          finance_needed: Number(this.financeNeeded),
          monthly_payment: this.EMI,
          loan_tenure: `${this.months} years`,
        };
        let apiResp: any = await this._api.saveEmiData(apiBody);
        if (apiResp.status == 1) {
          let data = {
            downPayment: this.downPayment,
            monthlyEmi: this.EMI,
            financeNeeded: this.financeNeeded,
            roi: this.rateOfInterest,
            tenure: this.months,
          };
          this.globalService.updateEmiOptions(data);
          // let url = defaultCarDetails[this.loadedCar].urlScheme;
          // this.router.navigate([`${url}/summary`]);
          // this.matSnack.open('Your EMI option is saved.', '✔', {});
          this._snack.openSnackBar('Your EMI option is saved.', 'Success');
          // this.matSnack;
          // setTimeout(() => {
          //   ONE3D.event.onResizeWindow();
          // }, 1000); // resize animation conflict issue fixed
          this.location.back();
        }
      } else {
        this._snack.openSnackBar(
          'Downpayment should be greater than 100000.',
          'Error'
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  formatCurrency(value: any) {
    return isNaN(value)
      ? 'xx,xx,xxx'
      : Number(value).toLocaleString('en-IN', {
          style: 'currency',
          currency: 'INR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
  }
  registerSocket() {
    this.$receiveEmiData = this._event.subscribe('receive_emi_data', (data) => {
      this.downPayment = Number(data.dp);
      this.rateOfInterest = Number(data.roi);
      this.months = Number(data.lt);
      this.calculateEMI();
      let emiData = {
        downPayment: this.downPayment,
        monthlyEmi: this.EMI,
        financeNeeded: this.financeNeeded,
        roi: this.rateOfInterest,
        tenure: this.months,
      };
      this.globalService.updateEmiOptions(emiData);
    });
  }

  onMonthKeyUp(e, type: string) {
    const min = parseInt(e.target.min);
    const max = parseInt(e.target.max);
    const currentVal = parseInt(e.target.value) || 0;

    // console.log('newVal', newVal);
    // console.log('currentVal', currentVal);
    // console.log('min', min, max);
    if (currentVal > max) {
      e.preventDefault();
      e.stopPropagation();
      // e.target.value = max;
      if (type == 'months') {
        this.months = max;
      } else {
        this.rateOfInterest = max;
      }
      this._snack.openSnackBar('Value should not be more than ' + max, 'Error');
    } else if (currentVal < min) {
      e.preventDefault();
      e.stopPropagation();
      // e.target.value = min;
      if (type == 'months') {
        this.months = min;
      } else {
        this.rateOfInterest = min;
      }
      this._snack.openSnackBar('Value should not be less than ' + min, 'Error');
      // this.months = min;
    }
    setTimeout(() => {
      console.log('Callled 2nd', this.months);

      this.calculateEMI();
    }, 2000);
  }
}
