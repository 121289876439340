import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { GaService } from '../../service/ga.service';
import { SocketService } from '../../service/socket.service';
import { defaultCarDetails } from '../carSettings';
declare var ONE3D: any;
@Component({
  selector: 'app-location-modal',
  templateUrl: './location-modal.component.html',
  styleUrls: ['./location-modal.component.scss'],
})
export class LocationModalComponent implements OnInit {
  // @Input() showModal: boolean;
  popupFrom: FormGroup;
  loadedCar: any;
  statePriceList: any = [];
  cityPriceList: any = [];
  userData: any;
  pinNo: string;
  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _ga: GaService,
    private _socket: SocketService
  ) {}

  ngOnInit(): void {
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.pinNo = data));

    this.createFrom();
    this.getStateList();
  }
  createFrom() {
    this.popupFrom = new FormGroup({
      state: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
    });
    console.log(this.popupFrom.invalid);
  }

  async getStateList() {
    let apiBody = {
      variant_id: defaultCarDetails[this.loadedCar].variant_id,
      model_id: defaultCarDetails[this.loadedCar].model_id,
    };

    console.log('apiResp', apiBody);
    let apiResp: any = await this._api.getPriceState(apiBody);
    console.log('apiResp', apiResp);

    if (apiResp.status == 1) {
      this.statePriceList = apiResp.response;

      let locationInfo = this._data.getLocationInfo();
      console.log('locationInfo', locationInfo);

      if (locationInfo) {
        let loc = JSON.parse(locationInfo);
        this.getPriceCityList(loc.state);
        this.popupFrom.patchValue({
          state: loc.state,
        });
      }
    }
  }

  async getPriceCityList(state: string) {
    let apiBody = {
      variant_id: defaultCarDetails[this.loadedCar].variant_id,
      state,
      model_id: defaultCarDetails[this.loadedCar].model_id,
    };

    let apiResp: any = await this._api.getPriceCity(apiBody);
    if (apiResp.status == 1) {
      this.cityPriceList = apiResp.response;
      let locationInfo = this._data.getLocationInfo();
      if (locationInfo) {
        console.log('locationInfo', locationInfo);

        let loc = JSON.parse(locationInfo);
        this.popupFrom.patchValue({
          city: loc.city,
        });
      }
    }
  }

  setLocationOnChange(state: string) {
    this._data.setLocationInfo({
      state,
      city: '',
    });
  }

  async getCarPrice() {
    let user_data = this._data.geUserDataInfo();
    if (user_data) {
      this.userData = user_data;
    }
    let variant_id = this._data.getLocalVariant()
      ? this._data.getLocalVariant()
      : defaultCarDetails[this.loadedCar].variant_id;
    let variant_feature_id = this._data.getLocalColor()
      ? this._data.getLocalColor()
      : defaultCarDetails[this.loadedCar].variant_feature_id;

    let apiData = {
      pin_no: this.pinNo,
      mid: '',
      source: 'Web',
      city: this.popupFrom.value.city,
      state: this.popupFrom.value.state,

      variant_id: variant_id,
      variant_feature_id: variant_feature_id,
      utm: '',
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      cust_id: this.userData ? this.userData.cust_id : '',
    };
    let apiResp: any = await this._api.createNewConfiguration(apiData);
    if (apiResp.status == 1) {
      this._ga.sendGAEvent('pin_no', 'click', apiResp.response.pin_no);
      this._global.updatedPrice(apiResp.response.showroom_price);
      window.updatePayloadParameter('pinId', apiResp.response.pin_no);
      this._global.updatePinno(apiResp.response.pin_no);
      // ONE3D.removeAccessories();
      this._global.toggleLocModal(false);
      this._global.updateLocation({ state: apiData.state, city: apiData.city });
      this._data.setLocationInfo({ state: apiData.state, city: apiData.city });
    }
  }
  oncloseModal() {
    this._global.toggleLocModal(false);
    this._ga.sendGAEvent('city', 'click', 'close');
  }
}
