import { Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { SocketService } from '../../service/socket.service';
import { GaService } from '../../service/ga.service';
import { CallService } from '../../service/call.service';
import { defaultCarDetails } from '../carSettings';
import { Router } from '@angular/router';
declare var ONE3D: any;

@Component({
  selector: 'app-self-explore',
  templateUrl: './self-explore.component.html',
  styleUrls: ['./self-explore.component.scss'],
})
export class SelfExploreComponent implements OnInit {
  loadedCar: any;
  isOpenExploreFeature: boolean = true;
  pinNo: string;
  featureCatList: any;
  headLampIsOpen: boolean;
  isCarDoorOpen: boolean;
  sunRoofIsOpen: boolean;
  selectedVariant: import('../../service/global.service').variant;
  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _ga: GaService,
    private _callS: CallService,
    private router: Router,
    private zone: NgZone,
    private location: Location
  ) {}

  ngOnInit(): void {
    this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data));
    this._global.sunRoofIsOpen.subscribe((data) => (this.sunRoofIsOpen = data));
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.headLampIsOpen.subscribe(
      (data) => (this.headLampIsOpen = data)
    );
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this.getFeatureStoryList();

    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      ONE3D.toggleDoors();
    }
    if (this.headLampIsOpen) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this._global.updateSunRoofState(false);
      ONE3D.toggleSunroof();
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      ONE3D.toggleDoors();
    }
    if (this.headLampIsOpen) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this._global.updateSunRoofState(false);
      ONE3D.toggleSunroof();
    }
  }
  async getFeatureStoryList() {
    try {
      let apiBody = {
        model_id: defaultCarDetails[this.loadedCar].model_id,
        pin_no: this.pinNo,
        variant_id: this.selectedVariant.variant_id,
      };
      let apiResp: any = await this._api.getFeatureStoryCategory(apiBody);
      if (apiResp.status == 1) {
        this.featureCatList = apiResp.response;
      }
    } catch (error) {
      console.error(error);
    }
  }
  openEnqModal() {
    this._global.togglePulseHubEnqForm(true);
  }
  gotoVariant() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this._ga.sendGAEvent('Self explore', 'click', 'explore features');
    this.router.navigate([`${url}/features`]);
  }

  goBack() {
    this._data.exploreCar=true;
    this.location.back();
  }
  async applyFeatureList(id) {
    console.log(id);
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/features`], { queryParams: { goto: id } });
  }
  logGA(item){
    this._ga.sendGAEvent("Feature_Category","click",item.feature_story_name)
  }
  /**
   * goto summary page on click of CTA Summary
   */
  gotoSummaryPage() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/summary`]);
    // this.router.navigate(['/hector/summary']);
  }

  gotoAccPage() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/variants`]);
  }
}
