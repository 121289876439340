import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-end-call-modal',
  templateUrl: './end-call-modal.component.html',
  styleUrls: ['./end-call-modal.component.scss'],
})
export class EndCallModalComponent implements OnInit {
  @Output('dismiss') action: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  onCancel() {
    this.action.emit('cancel');
  }
  onContinue() {
    this.action.emit('continue');
  }
}
