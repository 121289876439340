import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { GaService } from '../../service/ga.service';
import { SocketService } from '../../service/socket.service';
import { CallService } from '../../service/call.service';
import { MgExpertService } from '../../service/mg-expert.service';
import { SnackbarService } from '../../service/snackbar.service';
import { defaultCarDetails } from '../../three-d-module/carSettings';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { EventService } from 'src/app/service/event.service';
import { Subscription } from 'rxjs';
declare var window;
@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.scss'],
})
export class EnquiryFormComponent implements OnInit {
  queryForm: FormGroup;
  pinNo: string;
  loadedCar: any;
  dealerDetails: any;
  socketStatus: string;
  isChecked: boolean = false;
  isMarketingUpdates:boolean=true;
  oldNo: any;
  disableSendOTPButton: boolean = true;
  showSendOTPButton: boolean = true;
  enableTimer: boolean;
  showResendOtpCounter: boolean;
  showOTPField: boolean;
  countdown: number;
  interval: any;
  failedVerifiedOpt: boolean;
  userData: any;
  APPOINTMENT_USER: boolean;
  hideSkipIcon: boolean;
  waitIngForAgent: boolean = false;
  queryParamsData: any;
  hideAllFormInput: boolean;
  @Output() openAppointment = new EventEmitter<any>();
  @Output() appointmentChanged = new EventEmitter<any>();
  subs: Subscription;
  showVerifyButton: boolean = false;
  showCloseButton: boolean;

  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _ga: GaService,
    private _expert: MgExpertService,
    private _callS: CallService,
    private _snack: SnackbarService,
    private router: Router,
    private queryParams: ActivatedRoute,
    private _event: EventService,
  ) { }

  ngOnInit(): void {
    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this._global.socketStatus.subscribe((data) => (this.socketStatus = data));
    this.userData = this._data.geUserDataInfo();
    console.log('this.userData', this.userData);
    this._data.enableUIBlockLayer();
    window.sendData({
      eName: 'form_view',
      eCat: 'connect_to_agent',
      eAct: '',
    });
    if (this.userData) {
      this.APPOINTMENT_USER = true;
    } else {
      this.APPOINTMENT_USER = false;
    }
    console.log('this.APPOINTMENT_USER', this.APPOINTMENT_USER);
    this.createForm();
    this.queryParamsData = this.queryParams.snapshot.queryParams;
    if (
      this.queryParamsData &&
      this.queryParamsData.utm_source &&
      this.queryParamsData.utm_medium
    ) {
      this.hideSkipIcon = true;
    }
    if (
      this.queryParamsData &&
      this.queryParamsData.comet_pin_no
    ) {
      this.showCloseButton = true;
    }
    console.log('this.queryParamsData--->', this.queryParamsData);
    this.loadedCar = this._data.getLocalCarBrand();
    // if(this.queryParamsData.type && this.queryParamsData.type=='appointment'){
    //   this.loadCallCenterLead();
    // }else{
    //   this.loadDealerLead();
    // }
    window.sendData({ eName: 'form_view' });
    this.subs = this._event.subscribe('load_complete', async () => {
      if (this.queryParamsData.type && this.queryParamsData.type == 'appointment') {
        this._ga.sendGAEvent("Appointment_Lead", "click", this.queryParamsData.utm_source);
        this.loadCallCenterLead();
      } else if (!this.queryParamsData.type) {
        this.loadDealerLead();
      } else {
        console.error('last field', this.queryParamsData.type);
      }
      if (this.queryParamsData.type && this.queryParamsData.type == 'reschedule') {
        this._ga.sendGAEvent("Reschedule", "click", this.queryParamsData.utm_source);
        this._global.showCircleLoader(true);
        this._api.checkAppointmentByPin({ pin_no: this.pinNo })
          .then((data: any) => {
            this._global.showCircleLoader(false);
            if (data.status == 1) {
              this.appointmentChanged.emit(data.response);
              this._global.togglePulseHubEnqForm(false);
              this._global.updateAppointmentRescheduleForm(true);
            } else {
              console.error('error from server', data);
              this._global.togglePulseHubEnqForm(false);
              this._global.updateAppointmentRescheduleForm(true);
            }
          })
          .catch(err => {
            this._global.showCircleLoader(false);
            console.error('error', err)
            this._global.togglePulseHubEnqForm(false);
            this._global.updateAppointmentRescheduleForm(true);
          });
      }
    });
  }
  ngOnDestroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }
  loadCallCenterLead() {
    this.createLead();
  }
  loadDealerLead() {
    if (this.checkPinPresentOrNotInQueryParam()) {
      this.waitIngForAgent = true;
      this.hideAllFormInput = true;
      this.subscribeAgents();
      this.loopCheckForAgent();
    }
    this._global.dealerDetailsForToken.subscribe(
      (data) => (this.dealerDetails = data)
    );
  }
  loopCheckForAgent() {
    this.interval = setInterval(() => {
      if (this.dealerDetails.dealer_agent_id != '') {
        this._socket.socket.emit('dealer_agent_waiting', {
          id: this.dealerDetails.dealer_agent_id,
          userSocket: this._socket.socket.id,
          pin_no: this.pinNo,
        });
      }
    }, 15 * 1000);
  }
  subscribeAgents() {
    this._socket.socket.on('dealer_agent_joined', (data) => {
      if (
        data.id == this.dealerDetails.dealer_agent_id &&
        this.waitIngForAgent
      ) {
        this.waitIngForAgent = false;
      }
    });
    let time = 0;
    this._socket.socket.on('dealer_agent_not_joined', (data) => {
      console.log('Agent Not Joined.....');
      time += 1;
      if (time == 8) {
        // console.log('Try to send email..');
        this._api.notifyAgentsEmail({ pin_no: this.pinNo });
      }
      this.waitIngForAgent = true;
    });
  }

  checkPinPresentOrNotInQueryParam() {
    return (
      this.queryParamsData &&
      this.queryParamsData.hasOwnProperty('pin_no') &&
      this.queryParamsData.pin_no !== ''
    );
  }
  createForm() {
    if (this.APPOINTMENT_USER == true) {
      console.log('this.userData', this.userData);
      this.queryForm = new FormGroup({
        name: new FormControl(
          this.userData.first_name
            ? `${this.userData.first_name} ${this.userData.last_name}`
            : '',
          this.userData.first_name ? [] : [Validators.required]
        ),
        mobile: new FormControl(
          this.userData.mobile ? `${this.userData.mobile}` : '',
          {
            validators: [
              Validators.required,
              Validators.pattern(/^[6-9]\d{9}$/),
            ],
          }
        ),
        // otp: new FormControl('', {
        //   validators:
        //     this.userData && this.userData.mobile ? [] : [Validators.required],
        // }),
      });
    } else {
      this.queryForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        mobile: new FormControl('', {
          validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
        }),
        // otp: new FormControl('', {
        //   validators: [Validators.required],
        // }),
      });
    }
    // this.queryForm.invalid
  }

  public hasError = (controlName: string, errorName: string) =>
    (this.queryForm.controls[controlName].touched ||
      this.queryForm.controls[controlName].dirty) &&
    this.queryForm.controls[controlName].hasError(errorName);

  async createLead() {
    try {
      // console.log(this.isMarketingUpdates);
      // if (this.queryForm.valid && this.isChecked) {
      // console.log('valid');
      // let apiBody = {
      //   pin_no: this.pinNo,
      //   chat_id: this._socket.room,
      //   mobile: this.queryForm.value.mobile,
      //   first_name: this.queryForm.value.name,
      //   last_name: '',
      //   user_type: 'new',
      // };
      // console.log('---->', this._data.getDealerDetails());
      // this._global.updateDealerDetailsToken(this._data.getDealerDetails());
      let apiBody = {
        pin_no: this.pinNo,
        // chat_id: this._socket.room,
        mobile: this._data.encryptData(this.queryForm.value.mobile),
        first_name: this.queryForm.value.name,
        last_name: '',
        user_type: 'new',
        scanid: this.dealerDetails ? this.dealerDetails.scanid : '',
        dealer_code: this.dealerDetails ? this.dealerDetails.dealer_code : '',
        dealer_agent_id: this.dealerDetails
          ? this.dealerDetails.dealer_agent_id
          : '',
        device_token: this.dealerDetails ? this.dealerDetails.device_token : '',
        utm_source: this.dealerDetails ? 'vdcall' : 'pulseuup',
      };
      let apiResp: any = await this._expert.createEnqForPulseHub(apiBody);
      if (apiResp.status == 200) {
        console.log('---->', this.userData);
        window.sendData({
          eName: 'internal',
          eCat: 'lead_generated',
        });
        if (this.userData && this.userData.admin_id) {
          let data = {
            id: this.userData.admin_id,
            chat_id: `chat${this.pinNo}`,
          };
          this._socket.socket.emit('lead_submit_with_token', data);
          clearInterval(this.interval);
        }
        this._data.setLocalName(this.queryForm.value.name);
        console.log('this.socketStatus', this.socketStatus);

        if (this.socketStatus == '') {
          this._global.updateEnqGenerated(true);
          this._global.togglePulseHubEnqForm(false);
          this.socketConnect();
        }
        // this._snackBar.open(
        //   'Request generated successfully, our agent is joining you soon',
        //   '',
        //   {
        //     duration: 5000,
        //   }
        // );
        this._snack.openSnackBar(
          'Request generated successfully, our agent is joining you soon',
          'Success'
        );

        let url = defaultCarDetails[this.loadedCar].urlScheme;
        this.router.navigate([`${url}/home`]);
      }
      // } else {
      //   Object.keys(this.queryForm.controls).forEach((field) => {
      //     const control = this.queryForm.get(field);
      //     control.markAsTouched({ onlySelf: true });
      //     control.markAsDirty({ onlySelf: true });
      //   });
      // }
    } catch (error) {
      console.error(error);
    }
  }
  closeModal() {
    this._ga.sendGAEvent('Pulsehub Icon', 'click', 'Continue Browsing');
    window.sendLiveDashboardData({
      name: 'Confirm',
      category: 'pulsehub icon_continuebrowsing',
      type: 'MG_eXpert',
    });
    this._global.togglePulseHubEnqForm(false);
    let result = this._data.getOnLoadCounter();
    if (result >= 6) {
      this._data.enableBlockUI();
      this._data.dismissUIBlockLayer();
    }
  }
  socketConnect() {
    // this._socket.socket.connect();
    this._socket.setupSocketConnection();
  }
  openTancCpopup() {
    this._global.toggleTCModal(true);
  }

  onChangeMobileNo() {
    if (this.queryForm.get('mobile').valid) {
      this.disableSendOTPButton = false;
      if (this.oldNo != this.queryForm.value.mobile) {
        this.oldNo = this.queryForm.value.mobile;
        this.enableTimer = false;
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
        this.showSendOTPButton = true
        this.showVerifyButton = false
      }
    } else {
      this.disableSendOTPButton = true;
    }
  }
  async sendOTP(gaLabel = "Send Otp") {
    try {
      this._ga.sendGAEvent('Pulsehub Icon', 'click', gaLabel);
      let payload = { mobile: this.queryForm.value.mobile, sourec: 'web' };
      this._global.showCircleLoader(true);
      let resp: string = await this._api.sendOtp(payload);

      let apiResp: any = JSON.parse(atob(resp));
      console.log('resp', apiResp);
      if (apiResp.status == 1) {
        this._global.showCircleLoader(false);
        this.disableSendOTPButton = true;
        this.showSendOTPButton = false;
        this.showVerifyButton = true;
        this.enableTimer = true;
        this.showOTPField = true;
        this.showResendOtpCounter = true;
        this.countdown = 30;
        this.interval = setInterval(() => {
          this.resendOtpCounter();
        }, 1000);
      } else {
        this._global.showCircleLoader(false);
      }
    } catch (error) {
      this._global.showCircleLoader(false);
      console.error(error);
    }
  }
  resendOtpCounter() {
    if (this.countdown == 0) {
      clearInterval(this.interval);
      this.showResendOtpCounter = false;
    } else {
      // this.showResendOtpCounter = true;
      this.countdown--;
    }
  }

  async onSubmit() {
    // $e.preventDefault();
    try {
      console.log('this.queryForm.value', this.queryForm.value);
      this.failedVerifiedOpt = false;
      if (this.queryForm.valid) {
        this._ga.sendGAEvent("Pulsehub Icon", "click", "Verify OTP");
        this._global.showCircleLoader(true);
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
        this.countdown = 30;
        let apiData = {
          mobile: this.queryForm.value.mobile.toString(),
          otp: this.queryForm.value.otp.toString(),
          pin_no: this._data.getLocalPinNo(),
          customer_name: this.queryForm.value.name,
          source: 'web',
        };
        let resp: string = await this._api.customerRegistration(apiData);
        let apiRespData: any = JSON.parse(atob(resp));
        if (apiRespData.status == 1) {
          this._global.showCircleLoader(false);
          sessionStorage.setItem('mobile', this.queryForm.value.mobile);
          this._data.setUserDataInfo(apiRespData.response);
          // this._ga.sendGAEvent('pulsehub number', 'click', 'confirm');
          // this.createLead();
          // this._global.updateHide3D(false);
          this.openAppointmentFrom();
        } else {
          this._global.showCircleLoader(false);
          this.failedVerifiedOpt = true;
        }
      } else {
        console.log('Here 2', this.queryForm);

        Object.keys(this.queryForm.controls).forEach((field) => {
          const control = this.queryForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      this._global.showCircleLoader(false);
      console.error(error);
    }
  }

  async continueWOOTP() {
    // $e.preventDefault();
    try {
      console.log('this.queryForm.value', this.isMarketingUpdates);
      // this.failedVerifiedOpt = false;
      if (this.queryForm.valid) {
        this._ga.sendGAEvent("Pulsehub Icon", "click", "Confirm");
        window.sendLiveDashboardData({
          name: 'Confirm',
          category: 'pulsehub icon_confirm',
          type: 'MG_eXpert',
        });
        this._global.showCircleLoader(true);
        // clearInterval(this.interval);
        // this.showResendOtpCounter = false;
        // this.countdown = 30;
        this._data.dismissUIBlockLayer();
        let apiData = {
          mobile: this.queryForm.value.mobile.toString(),
          otp: null,
          pin_no: this._data.getLocalPinNo(),
          customer_name: this.queryForm.value.name,
          source: 'web',
          consent:this.isMarketingUpdates?"yes":"no"
        };
        // console.log("The data", apiData);
        let resp: string = await this._api.customerRegistrationWOOTP(apiData);
        let apiRespData: any = JSON.parse(atob(resp));
        if (apiRespData.status == 1) {
          this._global.showCircleLoader(false);
          sessionStorage.setItem('mobile', this.queryForm.value.mobile);
          this._data.setUserDataInfo(apiRespData.response);
          // this._ga.sendGAEvent('pulsehub number', 'click', 'confirm');
          // this.createLead();
          // this._global.updateHide3D(false);
          this.openAppointmentFrom(true);
        } else {
          this._global.showCircleLoader(false);
          this.failedVerifiedOpt = true;
        }
      } else {
        console.log('Here 2', this.queryForm);
        Object.keys(this.queryForm.controls).forEach((field) => {
          const control = this.queryForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      this._global.showCircleLoader(false);
      console.error(error);
    }
  }

  async openAppointmentFrom(flag?) {
    try {
      console.log('this.queryForm.value', this.queryForm.value);
      this.failedVerifiedOpt = false;
      if (this.queryForm.valid) {
        this._global.showCircleLoader(true);
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
        this.countdown = 30;
        let apiData = {
          mobile: this.queryForm.value.mobile.toString(),
          otp: flag ? null : this.queryForm.value.otp.toString(),
          pin_no: this._data.getLocalPinNo(),
          customer_name: this.queryForm.value.name,
          source: 'web',
          consent:this.isMarketingUpdates?"yes":"no"
        };
        let resp: string;
        if (flag) {
          resp = await this._api.customerRegistrationWOOTP(apiData);
        } else {
          resp = await this._api.customerRegistration(apiData);
        }

        let apiRespData: any = JSON.parse(atob(resp));
        if (apiRespData.status == 1) {
          this._global.showCircleLoader(false);
          // this._ga.sendGAEvent('pulsehub number', 'click', 'confirm');
          this._data.setUserDataInfo(apiRespData.response);

          this.openAppointment.emit();
          // sessionStorage.setItem('mobile', this.queryForm.value.phone);
          // this.createLead();
          // this._global.updateHide3D(false);
          // this._ga.sendGAEvent('lead', 'click', 'confirm');
        } else {
          this._global.showCircleLoader(false);
          this.failedVerifiedOpt = true;
        }
      } else {
        console.log('Here 2', this.queryForm);

        Object.keys(this.queryForm.controls).forEach((field) => {
          const control = this.queryForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {
      this._global.showCircleLoader(false);
      console.error(error);
    }
  }
}
