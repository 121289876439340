<form [formGroup]="summaryForm" [ngClass]="{ 'pointer-block': !ACCESS }">
  <div class="maincontainerrightin">
    <!--title-->
    <div class="fg summarylisttitle">
      <button
        [hidden]="!ACCESS && callConnected"
        mat-icon-button
        aria-label="Back to Home"
        (click)="goBack(); show3d()"
      >
        <mat-icon class="icon-back-mat">chevron_left</mat-icon>
      </button>
      <span>SUMMARY</span>
      <!-- <div class="summarylisttitleClose">
        <div class="summarylisttitleClosebtn">
          <a href="javascript:void(0)" (click)="gotoAccPage()"
            ><img src="assets/images/summary-close-icon.svg"
          /></a>
        </div>
      </div> -->
      <div
        class="summarylisttitlemob"
        *ngIf="!callConnected"
        (click)="hide3d()"
      >
        {{ HIDE_3D ? "Hide" : "Show" }} <span>Full Summary</span>
        <span class="material-icons md-light md-18">
          {{ HIDE_3D ? "expand_more" : "expand_less" }}
        </span>
      </div>
    </div>

    <!--title-->
    <!--VARIANTS-->
    <div class="fg summarylisttitlespace" *ngIf="pinSummary">
      <!--group row-->
      <!-- <div class="fg summarylistRow">
        <div class="summarylistRowspace">
          <h2 class="summarylistRowMtitle">VARIANT</h2>
          <div class="fg summarylistRowMinfo">
            <div class="summarylistRowMinfoRow">
              <div class="summarylistRowMinfol">
                {{ pinSummary.title || "-" }}
              </div>
              <div class="summarylistRowMinfor">
                ₹ {{ pinSummary.showroom_price || "XX,XX,XXX" }}
                <span
                  >Ex-Showroom Price <b>{{ pinSummary.city || "-" }}</b></span
                >
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--group row-->

      <!--group row-->
      <ng-container>
        <div class="fg summarylistRow">
          <!--inner-->
          <!-- <div class="summarylistRowspace">
          <div class="fg summarylistRowMinfo">
            <div class="summarylistRowMinfoRow">
              <h2 class="summarylistRowMtitle">COLOR</h2>
              <div class="summarylistRowMinfol">
                {{ pinSummary.color_name || "-" }}
              </div>
              <div class="summarylistRowMinfor">Included</div>
            </div>
            <div class="fg summarylistRowMinfoRow">
              <div class="summarylistRowMinfol summarylistRowMinfolb">
                ADDED ACCESSORIES
              </div>
              <div class="summarylistRowMinfor">
                ₹ {{ pinSummary.accessories_amount || "0" }}
              </div>
            </div>
            <div class="fg summarylistRowMinfoRow">
              <div class="summarylistRowMinfol summarylistRowMinfolb">
                OTHER CHARGES
                <span
                  *ngIf="openOtherCharges != true"
                  (click)="openOtherCharges = !openOtherCharges"
                  ><a>View</a></span
                >
                <span
                  *ngIf="openOtherCharges == true"
                  (click)="openOtherCharges = !openOtherCharges"
                  ><a class="hide">Hide</a></span
                >
              </div>
              <div class="summarylistRowMinfor">
                ₹ {{ pinSummary.other_charges_total || "0" }}
              </div>
            </div>
            <div
              class="fg otherChargeslist"
              id="otherChargeslist"
              *ngIf="openOtherCharges"
            >
              <div
                class="fg summarylistRowMinfoRow"
                *ngFor="let item of pinSummary.other_charges | keyvalue"
              >
                <div class="summarylistRowMinfol">{{ item.key }}</div>
                <div class="summarylistRowMinfor">₹ {{ item.value }}</div>
              </div>
            </div>
          </div>
        </div> -->
          <!--inner-->
          <!--inner-->
          <div class="summarylistRowspace">
            <div class="fg summarylistRowMinfo">
              <!--summarylistRowMinfoRow-->
              <div class="summarylistRowMinfoRow summarylistRowMinfoRowmb10">
                <h2 class="summarylistRowMtitle">COLOR</h2>
                <div class="summarylistRowMinfol">
                  {{ pinSummary.color_name || "-" }}
                </div>
                <div class="summarylistRowMinfor">Included</div>
              </div>
              <!--summarylistRowMinfoRow-->
              <!--summarylistRowMinfoRow-->
              <div class="summarylistRowMinfoRow summarylistRowMinfoRowmb10">
                <h2 class="summarylistRowMtitle">
                  VARIANT
                  <span class="summarylistRowMtitlespan"
                    >Ex-Showroom Price <b>{{ selectedCity || "-" }}</b></span
                  >
                </h2>
                <div class="summarylistRowMinfol">
                  {{ pinSummary.title || "-" }}
                </div>
                <div class="summarylistRowMinfor">
                  ₹ {{ pinSummary.showroom_price || "XX,XX,XXX" }}
                </div>
              </div>
              <!--summarylistRowMinfoRow-->
              <!--summarylistRowMinfoRow-->
              <div
                class="fg summarylistRowMinfoRow"
                *ngIf="
                  pinSummary?.other_charges_total != 0 &&
                  pinSummary?.other_charges_flag === 'yes'
                "
              >
                <div class="summarylistRowMinfol summarylistRowMinfolb">
                  OTHER CHARGES
                  <span
                    ><a
                      href="javascript:void(0)"
                      [ngClass]="{ hide: openOtherCharges }"
                      (click)="toggleCharges()"
                      >{{ !openOtherCharges ? "View" : "Hide" }}</a
                    ></span
                  >
                </div>
                <div class="summarylistRowMinfor">
                  ₹ {{ pinSummary.other_charges_total || "0" }}
                </div>
              </div>
              <!--summarylistRowMinfoRow-->

              <div
                class="fg otherChargeslist"
                id="otherChargeslist"
                *ngIf="
                  openOtherCharges && pinSummary?.other_charges_flag === 'yes'
                "
              >
                <!--summarylistRowMinfoRow-->
                <div
                  class="fg summarylistRowMinfoRow"
                  *ngFor="let item of pinSummary.other_charges"
                >
                  <div class="summarylistRowMinfol">{{ item.name }}</div>
                  <div class="summarylistRowMinfor">₹ {{ item.value }}</div>
                </div>
                <!--summarylistRowMinfoRow-->
              </div>
            </div>
          </div>
        </div>
        <!--group row-->
        <div class="summarylistpriceblockbrd"></div>
        <div
          class="fg summarylistpriceblock"
          *ngIf="pinSummary?.other_charges_total != 0"
        >
          <div class="summarylistpriceblockl">On-road Price</div>
          <div class="summarylistpriceblockr">
            ₹ {{ pinSummary.on_road_price }}
          </div>
        </div>
        <div class="summarylistpriceblockbrd"></div>
        <div class="fg summarylistpriceblock">
          <div class="summarylistpriceblockl">ADDED ACCESSORIES</div>
          <div class="summarylistpriceblockr summarylistpriceblockrlast">
            ₹ {{ pinSummary.accessories_amount || "0" }}
          </div>

          <div class="fg proTitlePlan" *ngIf="protectionPlanDetails?.year">
            <div class="summarylistpriceblockl">SELECTED PLAN</div>
            <div class="summarylistRowMinfol">
              {{ protectionPlanDetails?.plan_type }} ({{
                protectionPlanDetails?.year
              }})
            </div>
            <div class="summarylistRowMinfor">
              ₹ {{ protectionPlanDetails?.mrp }}
            </div>
          </div>
          <div
            class="fg proTitlePlan"
            *ngIf="
              !protectionPlanDetails?.year &&
              protectionPlanDetails?.pro_plan_type == 'MG Shield Plan'
            "
          >
            <div class="summarylistpriceblockl">MG SHIELD PLAN</div>
            <div class="summarylistRowMinfol">
              {{ protectionPlanDetails?.plan_type }}
            </div>
            <div class="summarylistRowMinfor">
              ₹ {{ protectionPlanDetails?.mrp }}
            </div>
          </div>
        </div>

        <div class="summarylistpriceblockbrd"></div>
        <div class="fg summarylistpriceblock">
          <div class="summarylistpriceblockl total-red-text">TOTAL PRICE *</div>
          <div class="summarylistpriceblockr">
            {{ formatCurrency(pinSummary?.total) }}
          </div>
          <div class="one3d-disclaimer-copy">
            <!-- <p>*Retail price (Taxes & Installation charges extra)</p> -->
            <!-- <p>*Ex-Showroom price PAN India</p> -->
            <p>Inclusive of ex showroom and selected accessory price</p>
          </div>
        </div>
        <div class="summarylistpriceblockbrd"></div>
        <!--Total Price-->

        <!--group row-->

        <div
          class="fg summarylistRow summarylistRowm30"
          style="margin-top: 16px"
          [hidden]="accessoriesList.Exterior.length == 0"
        >
          <!--inner-->
          <div class="summarylistRowspace">
            <h2 class="summarylistRowMtitle">EXTERIOR ACCESSORIES</h2>
            <div class="fg summarylistRowMinfo">
              <!--summarylistRowMinfoRow-->
              <div
                class="summarylistRowMinfoRow"
                *ngFor="let item of accessoriesList.Exterior"
              >
                <div class="summarylistRowMinfol">
                  {{ item.marketing_name }}
                </div>
                <div class="summarylistRowMinfor summarylistremovepricei">
                  ₹ {{ item.price || "0" }}
                  <!--trash-->
                  <div class="summarylistremoveprice">
                    <a
                      href="javascript:void(0)"
                      (click)="openDeleteConfirmModal(item)"
                      ><img src="assets/images/trash-icon.svg"
                    /></a>
                  </div>
                  <!--trash-->
                </div>
              </div>
              <!--summarylistRowMinfoRow-->
            </div>
          </div>
          <!--inner-->
        </div>
        <!--group row-->

        <!--group row-->
        <!-- are you sure you want to remove this accessories? -->

        <div
          class="fg summarylistRow summarylistRowm30"
          [hidden]="accessoriesList.Interior.length == 0"
        >
          <!--inner-->
          <div class="summarylistRowspace">
            <h2 class="summarylistRowMtitle">INTERIOR ACCESSORIES</h2>
            <div class="fg summarylistRowMinfo">
              <!--summarylistRowMinfoRow-->
              <div
                class="summarylistRowMinfoRow"
                *ngFor="let item of accessoriesList.Interior"
              >
                <div class="summarylistRowMinfol">
                  {{ item.marketing_name }}
                </div>
                <div class="summarylistRowMinfor summarylistremovepricei">
                  ₹ {{ item.price || "0" }}
                  <!--trash-->
                  <div class="summarylistremoveprice">
                    <a
                      href="javascript:void(0)"
                      (click)="openDeleteConfirmModal(item)"
                      ><img src="assets/images/trash-icon.svg"
                    /></a>
                  </div>
                  <!--trash-->
                </div>
              </div>
              <!--summarylistRowMinfoRow-->
            </div>
          </div>
          <!--inner-->
        </div>
        <!--group row-->

        <!--group row-->
        <div
          class="fg summarylistRow summarylistRowm30"
          [hidden]="accessoriesList.Essential.length == 0"
        >
          <!--inner-->
          <div class="summarylistRowspace">
            <h2 class="summarylistRowMtitle">ESSENTIAL ACCESSORIES</h2>
            <div class="fg summarylistRowMinfo">
              <!--summarylistRowMinfoRow-->
              <div
                class="summarylistRowMinfoRow"
                *ngFor="let item of accessoriesList.Essential"
              >
                <div class="summarylistRowMinfol">
                  {{ item.marketing_name }}
                </div>
                <div class="summarylistRowMinfor summarylistremovepricei">
                  ₹ {{ item.price || "0" }}
                  <!--trash-->
                  <div class="summarylistremoveprice">
                    <a
                      href="javascript:void(0)"
                      (click)="openDeleteConfirmModal(item)"
                      ><img src="assets/images/trash-icon.svg"
                    /></a>
                  </div>
                  <!--trash-->
                </div>
              </div>
              <!--summarylistRowMinfoRow-->
            </div>
          </div>
          <!--inner-->
        </div>
        <!--group row-->
        <div
          class="fg summarylistRow summarylistRowm30 one3d-lit-portion"
          [hidden]="packData.length == 0"
        >
          <!--inner-->
          <div class="summarylistRowspace">
            <h2 class="summarylistRowMtitle">COMET LIT</h2>
            <div class="fg summarylistRowMinfo">
              <!--summarylistRowMinfoRow-->
              <div class="summarylistRowMinfoRow" *ngFor="let item of packData">
                <div class="summarylistRowMinfol">{{ item.pack_name }}</div>
                <div class="summarylistRowMinfor summarylistremovepricei">
                  {{ formatCurrency(item.pack_price) }}
                  <!--trash-->
                  <div [hidden]="true" class="summarylistremoveprice">
                    <a
                      href="javascript:void(0)"
                      (click)="openDeleteConfirmModal(item)"
                      ><img src="assets/images/trash-icon.svg"
                    /></a>
                  </div>
                  <!--trash-->
                </div>
              </div>

              <div
                *ngIf="additionalPack?.length"
                class="summarylistRowMinfoRow"
              >
                <h2 class="summarylistRowMtitle">Additional Pack</h2>
                <div *ngFor="let addPack of additionalPack">
                  <div class="summarylistRowMinfol">
                    {{ addPack.pack_name }}
                  </div>
                  <div class="summarylistRowMinfor summarylistremovepricei">
                    {{ formatCurrency(addPack?.pack_price) }}
                  </div>
                </div>
              </div>

              <div *ngIf="optionsPack?.length" class="summarylistRowMinfoRow">
                <h2 class="summarylistRowMtitle">Options Kit</h2>
                <div *ngFor="let optionalPack of optionsPack" class="fg">
                  <div class="summarylistRowMinfol">
                    {{ optionalPack.marketing_name }}
                  </div>
                  <div class="summarylistRowMinfor summarylistremovepricei">
                    {{ formatCurrency(optionalPack?.price) }}
                  </div>
                  <br />
                  <br />
                </div>
              </div>

              <!--summarylistRowMinfoRow-->
            </div>
          </div>
          <!--inner-->
        </div>
        <div
          [hidden]="accessoriesList.Stickers.length == 0"
          class="one3d-summary-listing-wrapper fg"
        >
          <div class="one3d-sumheader">
            <h4>STICKERS</h4>
          </div>
          <div
            class="one3d-sumlist d-flex"
            *ngFor="let sticker of accessoriesList.Stickers"
          >
            <div class="one3d-sumlist-left">
              <p>{{ sticker.marketing_name }}</p>
            </div>
            <div class="one3d-sumlist-right">
              <p>
                <span>₹ {{ sticker.price }}</span>
                <span [hidden]="true" class="one3d-trash-icon"
                  ><img
                    src="assets/images/one3d-redtrash-icon.svg"
                    alt="trash-icon"
                /></span>
              </p>
            </div>
          </div>
        </div>
        <!--group row-->
        <div class="fg summarylistRow" *ngIf="EMI_OPTION">
          <div class="summarylistRowspace">
            <h2 class="summarylistRowMtitle">EMI Calculation</h2>
            <div class="fg summarylistRowMinfo">
              <div class="summarylistRowMinfoRow">
                <div class="summarylistRowMinfol">Down Payment</div>
                <div class="summarylistRowMinfor">
                  {{ formatCurrency(emiOption?.downPayment) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--group row-->
        <div class="fg summarylistpriceblock" *ngIf="EMI_OPTION">
          <div class="summarylistpriceblockl">Your Monthly Payment</div>
          <div class="summarylistpriceblockr">
            {{ formatCurrency(emiOption?.monthlyEmi) }}
          </div>
        </div>

        <!--group row-->
        <!-- <div
        class="fg summarylistRow summarylistRowm30 car-exchange-value-wrapper"
        *ngIf="exchangePrice != ''"
      >
        <div class="summarylistRowspace">
          <h2 class="summarylistRowMtitle">
            CAR EXCHANGE VALUE
            <span
              class="car-exchange-value-edit"
              (click)="gotoCarExchangePage()"
              ><svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.67187 1.00002C5.75942 0.912471 5.86336 0.843025 5.97774 0.795644C6.09213 0.748263 6.21473 0.723877 6.33854 0.723877C6.46235 0.723877 6.58495 0.748263 6.69934 0.795644C6.81373 0.843025 6.91766 0.912471 7.00521 1.00002C7.09276 1.08757 7.1622 1.1915 7.20958 1.30589C7.25696 1.42028 7.28135 1.54287 7.28135 1.66669C7.28135 1.7905 7.25696 1.9131 7.20958 2.02748C7.1622 2.14187 7.09276 2.2458 7.00521 2.33335L2.50521 6.83335L0.671875 7.33335L1.17187 5.50002L5.67187 1.00002Z"
                  stroke="#CF2230"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Edit</span
            >
          </h2>
          <div class="fg summarylistRowMinfo">
            <div class="summarylistRowMinfoRow">
              <div class="summarylistRowMinfol">
                Approximate Exchange Value :
              </div>
              <div class="summarylistRowMinfor summarylistremovepricei">
                {{ formatCurrency(exchangePrice) }}
              </div>
            </div>
            <div class="summarylistRowMinfoRow">
              <p class="fs-10">
                • The Buying price mentioned above for your existing car is
                tentative & based on inputs filled. The Actual Buying Price can
                only be told after proper Physical Evaluation of the Vehicle.
              </p>
            </div>
          </div>
        </div>
      </div> -->
        <!--group row-->

        <!--links-->
        <div
          class="fg summarylistlink"
          *ngIf="!callConnected"
          [ngClass]="{ 'pointer-block': !ACCESS && callConnected }"
        >
          <div class="summarylistlinko">
            <div class="summarylistlinkoicon">
              <a href="javascript:void(0)" (click)="gotoEmiPage()">
                <div class="summarylistlinkoiconi">
                  <img src="assets/images/cal-emi-icon.svg" />
                </div>
                EMI Calculator
              </a>
            </div>
          </div>
          <div class="summarylistlinko disabled-view">
            <div class="summarylistlinkoicon">
              <a href="javascript:void(0)" (click)="gotoCarExchangePage()">
                <div class="summarylistlinkoiconi">
                  <img src="assets/images/one3d-car-exchange-icon.svg" />
                </div>
                Car Exchange
              </a>
            </div>
          </div>
          <div
            class="summarylistlinko disabled-view"
            *ngIf="loadedCar == 'zsev'"
          >
            <div class="summarylistlinkoicon">
              <a href="javascript:void(0)" (click)="gotoProtectionPlan()">
                <div class="summarylistlinkoiconi">
                  <img src="assets/images/carbon_manage-protection.svg" />
                </div>
                Protect Plan
              </a>
            </div>
          </div>
          <div
            class="summarylistlinko disabled-view"
            *ngIf="
              loadedCar == 'gloster' ||
              loadedCar == 'hectornew' ||
              loadedCar == 'kmodel'
            "
          >
            <div class="summarylistlinkoicon">
              <a href="javascript:void(0)" (click)="openShieldPlan()">
                <div class="summarylistlinkoiconi">
                  <img src="assets/images/carbon_manage-protection.svg" />
                </div>
                Shield Plan
              </a>
            </div>
          </div>
          <!-- <div class="summarylistlinko">
          <div class="summarylistlinkoicon">
            <a href="javascript:void(0)" (click)="gotoCarExchangePage()">
              <div class="summarylistlinkoiconi">
                <img src="assets/images/car-exchange-icon.svg" />
              </div>
              Car Exchange
            </a>
          </div>
        </div> -->
        </div>
        <!--links-->
      </ng-container>

      <!--Enter your details-->
      <div class="fg summarylistfdetails" *ngIf="!callConnected">
        Enter your details to save your build and enjoy these benefits:
      </div>
      <!--Enter your details-->

      <!--form details-->
      <div class="fg detailforminfo" #scrollToForm *ngIf="!callConnected">
        <!--mobile no.-->
        <div class="fg detailforminfoRow">
          <div
            class="floating-label coobjectinputlable2"
            [ngClass]="{
              errmessageredinput:
                hasError('phone', 'required') || hasError('phone', 'pattern')
            }"
          >
            <label>Mobile number *</label>
            <div class="formobtitle">
              <input
                class="coobjectinput2"
                type="tel"
                formControlName="phone"
                maxlength="10"
                (keyup)="onPhoneNoChange()"
                placeholder="Enter mobile no."
              />
            </div>
            <div
              class="summaryerrsecbtn"
              *ngIf="showSendOtpBtn"
              (click)="otpSend(); logEvent('send_otp_button_clicked')"
            >
              <button class="submitbuttonobject">Send OTP</button>
            </div>
            <div
              class="summaryerrsecbtn"
              *ngIf="showChangeNo"
              (click)="onClickChangeNo()"
            >
              <button class="submitbuttonobject">Change Number</button>
            </div>
            <div
              class="summaryerrsecbtn"
              *ngIf="showReSendOtpBtn && !showSendOtpBtn"
            >
              <button
                class="submitbuttonobject"
                type="button"
                (click)="otpSend(); logEvent('resend_otp_button_clicked')"
                [disabled]="showResendOtpCounter"
              >
                {{
                  showResendOtpCounter
                    ? "Resend in " + timerCount + "s"
                    : "Resend OTP"
                }}
              </button>
            </div>
            <div class="fg errmessagered" *ngIf="hasError('phone', 'required')">
              Please enter a valid Mobile Number
            </div>
            <div class="fg errmessagered" *ngIf="hasError('phone', 'pattern')">
              Please enter valid 10 digit Mobile Number
            </div>
          </div>
        </div>
        <!--mobile no.-->

        <!--OTP no.-->
        <div
          class="fg detailforminfoRow"
          *ngIf="showReSendOtpBtn && !showSendOtpBtn"
        >
          <div class="floating-label coobjectinputlable2">
            <label> OTP *</label>
            <div class="formobtitle">
              <input
                class="coobjectinput2"
                type="tel"
                maxlength="4"
                placeholder="Enter OTP"
                (keyup)="validateOtp()"
                formControlName="otp"
              />
            </div>
            <div
              class="summaryerrsecbtn"
              (click)="onVeriFyOtp(); showSendOtpBtn = false"
            >
              <button
                [disabled]="!validOtp || hasError('otp', 'pattern')"
                class="submitbuttonobject"
              >
                Verify
              </button>
            </div>
            <div
              class="fg errmessagered"
              *ngIf="hasError('otp', 'pattern') || failedVerifiedOpt"
            >
              OTP is not correct
            </div>
          </div>
        </div>
        <!--OTP no.-->

        <div class="fg detailforminfoRow">
          <div
            class="floating-label coobjectinputlable2"
            [ngClass]="{
              errmessageredinput: hasError('firstName', 'required')
            }"
          >
            <input
              class="coobjectinput2"
              type="text"
              formControlName="firstName"
              maxlength="100"
              placeholder="Enter first name"
            />
            <label>First Name *</label>
            <div
              class="fg errmessagered"
              *ngIf="hasError('firstName', 'required')"
            >
              Please provide your First Name.
            </div>
          </div>
        </div>

        <div class="fg detailforminfoRow">
          <div
            class="floating-label coobjectinputlable2"
            [ngClass]="{
              errmessageredinput: hasError('lastName', 'required')
            }"
          >
            <input
              class="coobjectinput2"
              type="text"
              formControlName="lastName"
              maxlength="50"
              placeholder="Enter last name"
            />
            <label>Last Name *</label>
            <div
              class="fg errmessagered"
              *ngIf="hasError('lastName', 'required')"
            >
              Please provide your Last Name.
            </div>
          </div>
        </div>

        <div class="fg detailforminfoRow">
          <div
            class="floating-label coobjectinputlable2"
            [ngClass]="{
              errmessageredinput:
                hasError('email', 'required') || hasError('email', 'pattern')
            }"
          >
            <input
              class="coobjectinput2"
              type="text"
              formControlName="email"
              type="text"
              placeholder="Enter email id"
            />
            <label>Email address *</label>
            <div class="fg errmessagered" *ngIf="hasError('email', 'pattern')">
              Please enter valid Email address.
            </div>
            <div class="fg errmessagered" *ngIf="hasError('email', 'required')">
              Please provide your Email.
            </div>
          </div>
        </div>
        <div class="fg detailforminfoRow">
          <div
            class="floating-label coobjectinputlable2"
            [ngClass]="{
              errmessageredinput: hasError('pin', 'required')
            }"
          >
            <input
              class="coobjectinput2"
              type="tel"
              formControlName="pin"
              maxlength="6"
              placeholder="Enter Pin Code"
              (keyup)="getDealerByPinCode()"
            />
            <label>Pin Code *</label>

            <div class="fg errmessagered" *ngIf="hasError('pin', 'required')">
              Please provide your Pin code.
            </div>
          </div>
        </div>

        <div class="fg detailforminfoRow">
          <div class="floating-label coobjectinputlable2">
            <div class="custom-select mngpaym">
              <select
                formControlName="state"
                (change)="stateSelected($event.target.value)"
              >
                <option value="">Select</option>
                <option *ngFor="let item of stateList" [value]="item.state">
                  {{ item.state }}
                </option>
              </select>
            </div>
            <label>State Selection*</label>
            <div class="fg errmessagered" *ngIf="hasError('state', 'required')">
              Please select State.
            </div>
          </div>
        </div>

        <div class="fg detailforminfoRow">
          <div class="floating-label coobjectinputlable2">
            <div class="custom-select mngpaym">
              <select
                formControlName="city"
                (change)="citySelected($event.target.value)"
              >
                <option value="">Select</option>
                <option *ngFor="let item of cityList" [value]="item.city">
                  {{ item.city }}
                </option>
              </select>
            </div>
            <label>City Selection*</label>
            <div class="fg errmessagered" *ngIf="hasError('city', 'required')">
              Please select City.
            </div>
          </div>
        </div>

        <div class="fg detailforminfoRow">
          <div
            class="floating-label coobjectinputlable2"
            [ngClass]="{
              errmessageredinput: hasError('dealer', 'required')
            }"
          >
            <div class="custom-select mngpaym">
              <select
                name="selectedDealer"
                formControlName="dealer"
                (change)="dealerSelected($event.target.value)"
              >
                <option value="">Select</option>
                <option
                  [value]="item.dealer_id"
                  *ngFor="let item of dealerList"
                >
                  {{ item.facillity_description }}
                </option>
              </select>
            </div>
            <label>Dealer Selection*</label>

            <div
              class="fg errmessagered"
              *ngIf="hasError('dealer', 'required')"
            >
              Please select your nearest dealership.
            </div>
          </div>
        </div>

        <div class="fg detailforminfoRow financeyesornoouter">
          <div class="financeyesorno">Looking for Finance?</div>
          <div class="financeyesornor">
            <!--block-1-->
            <div class="coradio3 financeyesornol">
              <input
                id="yes"
                type="radio"
                name="lookingForFinance"
                formControlName="lookingForFinance"
                value="yes"
              />
              <label for="yes">Yes</label>
            </div>
            <!--block-1-->
            <!--block-2-->
            <div class="coradio3 financeyesornol">
              <input
                id="no"
                type="radio"
                name="lookingForFinance"
                formControlName="lookingForFinance"
                value="no"
              />
              <label for="no">No</label>
            </div>
            <!--block-2-->
          </div>
        </div>

        <!-- <div class="fg detailforminfoRow">
          <div class="fg ppbuttonlefto">
            <a
              href="javascript:void(0)"
              (click)="gotoProtectionPlan()"
              class="ppbuttonleft"
              ><span>View Protect Plan</span></a
            >
          </div>
        </div> -->

        <div class="fg detailforminfoRow privacyleftOuter">
          <div class="privacyleft">
            <div class="iuthis mngcheckBox">
              <input
                id="iagree"
                type="checkbox"
                [checked]="iAgree"
                (change)="iAgree = !iAgree"
              />
              <label for="iagree"></label>
            </div>
          </div>
          <div class="privacyright">
            I agree to the
            <span
              ><a href="https://www.mgmotor.co.in/privacy" target="_blank"
                >Privacy Policy</a
              ></span
            >
          </div>
        </div>
        <div class="fg detailforminfoRow receive-updates-partners">
          <div class="privacyleft">
            <div class="iuthis mngcheckBox">
              <input
                id="iAgreeForMarketting"
                type="checkbox"
                [checked]="isMarketingUpdates"
                (change)="isMarketingUpdates = !isMarketingUpdates"
              />
              <label for="iAgreeForMarketting"></label>
            </div>
          </div>
          <div class="privacyright">
            Receive updates from MG Motor and its partners.
            <span
              ><a href="https://www.mgmotor.co.in/privacy" target="_blank">
                (T&Cs)</a
              ></span
            >
          </div>
        </div>
        <p
          style="color: #cf2230; font-size: 12px; margin-top: 12px"
          *ngIf="otpErrorMsg"
        >
          {{ showErrorMsg }}
        </p>
        <p
          style="color: #cf2230; font-size: 12px; margin-top: 12px"
          *ngIf="verifyOtpErr"
        >
          Please verify your mobile number with OTP.
        </p>
      </div>
      <!--form details-->
    </div>
    <!--VARIANTS-->
  </div>
  <!--button-->
  <div class="variantlisttitlebtn" *ngIf="ACCESS || !callConnected">
    <div
      class="variantlisttitlebtnleft"
      [ngClass]="{ 'disable-access': blockTestDrive == true }"
    >
      <a
        [ngClass]="{ disabled: showLoading }"
        href="javascript:void(0)"
        (click)="scrollToBottom(); updateCustomerInfo('test_drive')"
        class="variantlisttitlebtnleftbtn"
        >Book Test Drive</a
      >
    </div>
    <div class="variantlisttitlebtnright">
      <a
        [ngClass]="{ disabled: showLoading }"
        href="javascript:void(0)"
        (click)="scrollToBottom(); updateCustomerInfo('bool_now')"
        class="variantlisttitlebtnrightbtn"
      >
        <!-- <mat-spinner [diameter]="20"></mat-spinner> -->

        Book Now
      </a>
    </div>
  </div>
  <!--button-->
  <!-- <div
    class="variantlisttitlebtn"
    [ngClass]="{ 'disable-access': blockTestDrive == true }"
    *ngIf="loadedCar == 'kmodel' || !callConnected"
  >
    <a
      href="javascript:void(0)"
      (click)="scrollToBottom(); updateCustomerInfo('test_drive')"
      class="variantlisttitlebtnrightbtn"
    >
      Book a Test Drive
    </a>
  </div> -->
</form>

<app-protection-plan
  (closeBtnClickOnProtectionPlanModal)="closeProtectionPlanModal($event)"
  (closeBtnClickOnProtectionPlanModal2)="setProtectionPlanType($event)"
  *ngIf="showProtectionPlan"
>
</app-protection-plan>
<app-mg-shield
  *ngIf="isOpenshieldPlan"
  [fromShow]="'summary'"
  (closePopup)="closeShieldPopup($event)"
></app-mg-shield>
<!--Acc-remove-popup-->
<div class="soundcheckmain" *ngIf="openAlert">
  <!--popup-->
  <div class="soundcheckmaininner">
    <div class="soundcheckmainOuter soundcheckmainOuter2">
      <div class="soundcheckbg">
        <div class="fg cvbftitle">
          Are you sure you want to remove this accessory?
        </div>

        <div class="fg soundcheckbtnarea">
          <div class="soundcheckbtnarealeft">
            <a
              href="javascript:void(0)"
              (click)="removeAccessory()"
              class="btnplayright"
              ><span>Yes</span></a
            >
          </div>
          <div class="soundcheckbtnarearight">
            <a
              href="javascript:void(0)"
              class="btnplayleft"
              (click)="openAlert = false"
              ><span>Cancel</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--popup-->

  <!--overlay-->
  <div class="soundcheckmainOverlay soundcheckmainOverlay2"></div>
  <!--overlay-->
</div>
<!--Acc-remove-popup-->
