import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
} from '@angular/common/http';
import { DataService } from './data.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class MgExpertService {
  apiUrl: string;
  headers: any;
  apiVersion: string = 'v1';
  constructor(private http: HttpClient, private _data: DataService) {
    if (window.location.host == 'cc.mgmotor.co.in') {
      this.apiUrl = 'https://api-mgexpert.one3d.in:7458/api';
    } else {
      this.apiUrl = 'https://apibutlerstaging.one3d.in:7458/api';
      // this.apiUrl = 'http://localhost:7458/api';
    }
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', `application/json`);
  }

  generateToken() {
    let body = {
      username: this._data.encryptData('sanketm@eccentricengine.com'),
      password: this._data.encryptData('GR$%^FFF7785Dddd'),
    };
    this.http
      .post(`${this.apiUrl}/login`, body, {
        headers: this.headers,
      })
      .subscribe((data: any) => {
        console.log('data for token', data);
        if (data.status == 200) {
          let str = this._data.decryptData(data.token);
          let decryptString = str.toString(CryptoJS.enc.Utf8);
          let newStr = decryptString.replace(/["]+/g, '');
          this.updateTokenToHeader(newStr);
        }
      });
  }

  updateTokenToHeader(token: string) {
    this.headers = this.headers.set('Authorization', `Bearer ${token}`);
  }

  createEnqForPulseHub(body) {
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergechat/customer_lead`,
        body,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }
  getCarCompareData(body) {
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergeadmin/variant_base_carcomparison`,
        body,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }
  getVariantCodeList(body) {
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergeadmin/variant_code_list`,
        body,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }

  /**
   * update Customer status after 2,5 interval
   */
  updateUserStatus(body: any) {
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergeadmin/update_cust_status`,
        body,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }

  /**
   * submit the feedback form
   * @param apiBody
   * @returns
   */
  submitFeedback(apiBody) {
    return this.http.post(
      `${this.apiUrl}/${this.apiVersion}/conciergeadmin/save_customer_feedback`,
      apiBody,
      {
        headers: this.headers,
      }
    );
  }

  /**
   * create ccuid for booking
   * @param apiBody
   * @returns
   */
  createCCuid(apiBody) {
    return this.http
      .post(`${this.apiUrl}/${this.apiVersion}/booking/create_ccuid`, apiBody, {
        headers: this.headers,
      })
      .toPromise();
  }

  /**
   * get dealers by pincode
   * @param apiBody
   * @returns promise of api request
   * @author sanket
   */
  getDealerByPinCode(apiBody) {
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergechat/get_dealers_from_pincode`,
        apiBody,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }
  /**
   * save call journey
   * @param apiBody
   * @returns promise of api request
   * @author sanket
   */
  saveCallJourney(apiBody) {
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergechat/store_call_event`,
        apiBody,
        {
          headers: this.headers,
        }
      )
      .toPromise();
  }
  /**
   *
   * @param body {"customer_name":"Ankit","mobile":"U2FsdGVkX18XNjens6w8/RiPu9rRsCqy5ir4mfG5SLg=","appointment_time":"2022-01-06 19:24","model_id":"5","optin_whatsapp":"yes","utm_source":"","utm_medium":"","utm_campaign":""}
   * @returns
   */
  generateAppointmentLead(apiBody) {
    let head = new HttpHeaders();
    head = head.set('Content-Type', `application/json`);
    head = head.set(
      'Authorization',
      'Basic ' + btoa('Eccentric' + ':' + 'kl$%92278frt0ds')
    );
    return this.http
      .post(
        `${this.apiUrl}/${this.apiVersion}/conciergeadmin/save_appointment_data_with_pin`,
        apiBody,
        {
          headers: head,
        }
      )
      .toPromise();
  }
}
