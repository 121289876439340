<!--Custom-selectLocation-->
<!-- <div
  class="myLocation thankyoumessageouter"
  id="thankyoumessage"
  [ngStyle]="{ 'pointer-events': callConnected ? 'none' : 'auto' }"
>
  <div class="SelectLocationPopup">
    <div class="SelectLocationPopupin">
      <div class="sharePopup tymbg">
        <div class="fg sharePopupw tymPopup">
          <button
            class="
              shareProClosepopup
              selectLocation_close
              tymshareProClosepopup
            "
            (click)="closeModal()"
          ></button>

          <div class="fg tymtitle">
            <span
              ><img src="./assets/images/thankyou-tik.svg" alt="img"
            /></span>
            Thank You !
          </div>

          <div class="fg tymtitle1">
            {{ text }}
          </div>
        </div>
        <div class="fg shareFormbtn shareContinue">
          <button class="submitbutton" (click)="gotoMainPage()">OK</button>
        </div>
      </div>
    </div>
  </div>
  <div class="SelectLocationOverlay"></div>
</div> -->
<!--Custom-selectLocation-->
<!--new-thank-you-popup-->
<div
  class="soundcheckmain new-latest-rounded-popup new-thank-you-popup-wrapper"
  [ngStyle]="{ 'pointer-events': callConnected ? 'none' : 'auto' }"
>
  <!--popup-->
  <div class="soundcheckmaininner">
    <div class="soundcheckmainOuter soundcheckmainOuter2">
      <div class="soundcheckbg">
        <span class="new-main-close-icon" (click)="closeModal()">
          <img
            class="new-main-close-cross"
            src="assets/images/new-close-icon.svg"
          />
        </span>
        <div class="fg cvbftitle">THANK YOU!</div>
        <div class="new-cvbf-content">
          {{ text }}
        </div>

        <!-- <div class="fg soundcheckbtnarea">
          <div class="soundcheckbtnarearight">
            <a
              href="javascript:void(0)"
              class="btnplayleft"
              (click)="gotoMainPage()"
              ><span>Confirm</span></a
            >
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!--popup-->

  <!--overlay-->
  <div class="soundcheckmainOverlay soundcheckmainOverlay2"></div>
  <!--overlay-->
</div>
<!--new-thank-you-popup-->
