<!--Custom-selectLocation-->
<div class="myLocation" id="SelectLocation">
  <div class="SelectLocationPopup">
    <div class="SelectLocationPopupin">
      <div class="sharePopup">
        <!--form-->
        <div class="fg sharePopupw locationPopup">
          <button
            class="shareProClosepopup selectLocation_close"
            (click)="oncloseModal()"
          ></button>

          <div class="fg sharePopupwtitle">
            <span><img src="./assets/images/map-pin.svg" alt="img" /></span>
            PLEASE SELECT YOUR LOCATION
          </div>
          <form [formGroup]="popupFrom" (ngSubmit)="getCarPrice()">
            <div class="fg locationPopupmg">
              <div class="mngddtitle">Select State</div>
              <div class="custom-select mngpaym">
                <select
                  name="state"
                  formControlName="state"
                  (change)="
                    getPriceCityList(popupFrom.value.state);
                    setLocationOnChange(popupFrom.value.state)
                  "
                >
                  <option selected value="">Select State</option>
                  <option
                    *ngFor="let state of statePriceList"
                    [value]="state.state"
                  >
                    {{ state?.state }}
                  </option>
                </select>
              </div>
            </div>
            <div class="fg locationPopupmg">
              <div class="mngddtitle">Select City</div>
              <div class="custom-select mngpaym">
                <select formControlName="city" name="city">
                  <option selected value="">Select City</option>
                  <option
                    *ngFor="let item of cityPriceList"
                    [value]="item?.city"
                  >
                    {{ item?.city }}
                  </option>
                </select>
              </div>
            </div>
            <div class="fg shareFormbtn shareContinue">
              <button
                type="submit"
                class="submitbutton"
                [disabled]="!popupFrom.valid"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
        <!--form-->
      </div>
    </div>
  </div>
  <div class="SelectLocationOverlay"></div>
</div>
<!--Custom-selectLocation-->
