<!--sound-check-popup-->
<div class="soundcheckmain" id="soundcheckPopup">
  <!--popup-->
  <div class="soundcheckmaininner" [hidden]="stepCount == '2'">
    <div class="soundcheckmainOuter">
      <div class="soundcheckbg">
        <div class="fg soundchecktitle1">
          THIS EXPERIENCE IS BETTER WITH SOUND
        </div>
        <div class="fg soundchecktitle2">
          Adjust the volume of your speakers or headphones accordingly
        </div>

        <div class="fg soundPlayer">
          <div class="audio-player-container">
            <!-- <img src="./assets/images/sound-mute-icon.png" alt="img" /> -->
            <div class="sound-wave" [ngClass]="{ stop: !showAnim }">
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
            </div>
          </div>
        </div>

        <div class="fg soundcheckbtnarea">
          <!--<div class="soundcheckbtnarealeft"><a href="#" class="btnplayright"><span>No</span></a></div>-->
          <div class="soundcheckbtnarearight">
            <a
              href="javascript:void(0)"
              (click)="gotoCheckPage()"
              class="btnplayleft"
              ><span
                ><img src="./assets/images/sound-play.svg" alt="img" /> </span
              ><span>Play Sound</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--popup-->

  <!--popup-->
  <div class="soundcheckmaininner" [hidden]="stepCount == '1'">
    <div class="soundcheckmainOuter">
      <div class="soundcheckbg">
        <div class="fg soundchecktitle1">
          ARE YOU ABLE TO HEAR THE SOUND CLEARLY?
        </div>
        <div class="fg soundchecktitle3">
          <img src="./assets/images/headphones-icon.svg" alt="img" />
        </div>
        <div class="fg soundchecktitle2">
          This experience is better with sound, make sure your speakers are on
        </div>
        <div class="fg soundPlayer">
          <div class="audio-player-container">
            <!-- <img src="./assets/images/sound-play-icon.png" alt="img" /> -->
            <div class="sound-wave" [ngClass]="{ stop: !showAnim }">
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
              <div class="sound-bar"></div>
            </div>
          </div>
        </div>

        <div class="fg soundcheckbtnarea">
          <div class="soundcheckbtnarealeft">
            <a
              href="javascript:void(0)"
              (click)="gotoForwad()"
              class="btnplayright"
              ><span>No</span></a
            >
          </div>
          <div class="soundcheckbtnarearight">
            <a
              href="javascript:void(0)"
              (click)="gotoForwad()"
              class="btnplayleft"
              ><span>Yes, It works</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--popup-->

  <!--overlay-->
  <div class="soundcheckmainOverlay"></div>
  <!--overlay-->
</div>
<!--sound-check-popup-->
