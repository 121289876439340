import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import {
  GlobalService,
  glosterSeating,
  hectorPlusSeating,
} from '../../service/global.service';
import { audioStatus } from '../../service/global.service';
import { SocketService } from '../../service/socket.service';
import { CallService } from '../../service/call.service';
import { defaultCarDetails } from '../carSettings';
import { BehaviorSubject, Subscription } from 'rxjs';
import { KModelComponent } from '../k-model/k-model.component';
import { GaService } from 'src/app/service/ga.service';
declare var ONE3D: any;
declare var $: any;
declare var window;

@Component({
  selector: 'app-features-list',
  templateUrl: './features-list.component.html',
  styleUrls: ['./features-list.component.scss']
})
export class FeaturesListComponent implements OnInit {
  globalPinNO: string;
  isCarDoorOpen: boolean;
  sunRoofIsOpen: boolean;
  headLampIsOpen: boolean;
  ACCESS: boolean;
  selectedVariant: import('../../service/global.service').variant;
  loadedCar: string;
  featuresList: any[] = [];
  selectedFeature: any;
  isFeatureSelected: boolean;
  featureNextDisable: boolean;
  featurePreviousDisable: boolean;
  featureAnimationPlaying: boolean;
  selectedFeatureIndex: number = 0;
  audioOliviaStatus: audioStatus;
  showEndPopup: boolean = false;
  variantList: any[];
  selectedColor: any;
  colorList: any[];
  audioObserver$: Subscription;
  currentFeatureStory: any;
  totalNoStory: any;
  queryParams: any;
  private _timer: number;
  playNextFeature$: Subscription;
  isIntColorChange: boolean;
  hectorSeatingOptions: hectorPlusSeating | glosterSeating;
  waitingForNextFeature: number;
  disableAllButtons: boolean;

  // private playMoreFeature = new BehaviorSubject<any>({});
  // emiOption = this.emiOptionSource.asObservable();

  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _callS: CallService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private location: Location,
    private _ga : GaService,
  ) {}

  ngOnInit(): void {
    window['featureRef'] = {
      zone: this.zone,
      one3dcallback: {
        onFeatureComplete: () => this.onFeatureComplete(),
        onCarLoadComplete: () => this.onCarLoadComplete(),
        onVariantLoadComplete: () => this.onVariantChangeComplete(),
      },
      component: this,
    };
    this.route.queryParams.subscribe((data) => {
      if (data && data.hp) {
        this.queryParams = data;
      }
    });
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.globalPinNO = data));

    this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data));
    this._global.sunRoofIsOpen.subscribe((data) => (this.sunRoofIsOpen = data));
    this._global.headLampIsOpen.subscribe(
      (data) => (this.headLampIsOpen = data)
    );

    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.variantList.subscribe((data) => (this.variantList = data));
    this._global.colorList.subscribe((data) => (this.colorList = data));
    this._global.selectedColor.subscribe((data) => (this.selectedColor = data));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.isIntColorChange.subscribe(
      (data) => (this.isIntColorChange = data)
    );
    this.playNextFeature$ = this._global.playNextFeature.subscribe((data) => {
      if (data && this.featuresList.length > 0) {
        this.getFeatureList();
        this.featurePreviousDisable = true;
        this.featureNextDisable = false;
      }
    });
    this._global.hectorSeatingOptions.subscribe(
      (data) => (this.hectorSeatingOptions = data)
    );
    this.audioObserver$ = this._global.audioOliviaStatus.subscribe((data) => {
      this.audioOliviaStatus = data;
      if (
        this.featuresList.length > 0 &&
        data == audioStatus.stop &&
        !this.featureAnimationPlaying
      ) {
        console.log('Both completed, 1st feature and then audio');
        this._timer = setTimeout(() => {
          this.gotoNextFeature();
        }, 4000);
      }
    });
    if (!this.hectorSeatingOptions) {
      if (
        this.selectedVariant.d_name !=
          defaultCarDetails[this.loadedCar].topVar &&
        window.hasOwnProperty('ONE3D') &&
        ONE3D.loadedPercent == 100
      ) {
        let selectedV = null;
        if (this.loadedCar == 'hectorplus') {
          selectedV = this.variantList.find(
            (ele) => ele.is_default == 1 && ele.seats_no == '6S'
          );
          this._global.updateHectorSeatingOption(hectorPlusSeating.sixSeater);
        } else if (this.loadedCar == 'zsev') {
          console.log('Logged 1--->');
          ONE3D.removeAllAccessories();
          this.getFeatureList();
          // selectedV = this.variantList.find(
          //   (ele) => ele.variant_id == this.selectedVariant.variant_id
          // );
        } else {
          selectedV = this.variantList.find((ele) => ele.is_default == 1);
        }
        if (selectedV) {
          this.onVariantSelect(selectedV);
        }
      } else {
        ONE3D.removeAllAccessories();
        if (this.queryParams && this.queryParams.hasOwnProperty('hp')) {
          this.getFeatureList();
        } else {
          this.getFeatureList();
        }
      }
    } else {
      let seatOp = this.hectorSeatingOptions + 'S';
      let filteredVariant = this.variantList.filter(
        (ele) => ele.seats_no == seatOp && ele.is_default == 1
      );
      if (
        filteredVariant.length > 0 &&
        this.selectedVariant.variant_id != filteredVariant[0].variant_id
      ) {
        this.onVariantSelect(filteredVariant[0]);
      } else if (this.isIntColorChange) {
        localStorage.removeItem('selectedColorInt');

        this.onVariantSelect(filteredVariant[0]);
      } else {
        if (this.queryParams && this.queryParams.hasOwnProperty('hp')) {
          this.getFeatureList();
        } else {
          this.getFeatureList();
        }
      }
    }

    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      ONE3D.toggleDoors();
    }
    if (this.headLampIsOpen) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this._global.updateSunRoofState(false);
      ONE3D.toggleSunroof();
    }
    window.sendData({ eName: 'page_view', eCat: 'features_page' });
  }

  ngOnDestroy(): void {
    window['featureRef'] = null;
    this.audioObserver$.unsubscribe();
    this.playNextFeature$.unsubscribe();
    window.clearTimeout(this._timer);
    clearTimeout(this.waitingForNextFeature);
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    this._global.updateImageFeatureList([]);
    ONE3D.exitFeatureView();
    this._global.updateSoundTrackURL('');
    this._global.updateAccCam(false);
  }

  async getVariantListByStoryId() {
    try {
      let variantId: number = this.selectedVariant.variant_id;
      // if (this.loadedCar === 'zsev') {
      //   variantId = this.selectedVariant.variant_id;
      // } else {
      //   variantId = defaultCarDetails[this.loadedCar].variant_id;
      // }
      let apiBody = {
        model_id: defaultCarDetails[this.loadedCar].model_id,
        pin_no: this.globalPinNO,
        feature_story_id: this.queryParams.goto,
        seats: this.hectorSeatingOptions ? `${this.hectorSeatingOptions}S` : '',
        variant_id: variantId,
      };
      let apiResp: any = await this._api.getFeatureStoryByID(apiBody);
      if (apiResp.status == 1) {
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });

        console.log(apiResp);
        this.totalNoStory = apiResp.response.total_feature_story;
        this.currentFeatureStory = apiResp.response.currant_feature_story_id;
        this.featuresList = apiResp.response.features;
        if (ONE3D.loadedPercent == 100) {
          this.onSelectFeature(this.featuresList[0]);
        }
        // this.selectedFeature = this.featuresList[0];
      }
    } catch (error) {
      console.error(error);
    }
  }
  onVariantChangeComplete() {
    // throw new Error('Method not implemented.');
    // ONE3D.loadedPercent = 100;
    document.getElementById('variantLoader').style.display = 'none';
    ONE3D.changeColor(this.selectedColor.hex_code);
    window.updatePayloadParameter('eLa3', this.selectedColor.color_name);

    console.log('Change variant compltete');
    setTimeout(() => {
      if (this.queryParams && this.queryParams.hasOwnProperty('goto')) {
        this.getFeatureList();
      } else {
        this.getFeatureList();
      }
    }, 1500);
  }
  onFeatureComplete() {
    window.sendData({
      eName: 'internal',
      eCat: 'audio_assisted_page',
      eAct: 'audio_assisted_video',
      eVal: 'stop',
    });
    console.log('afterrrr complt');
    this.featureAnimationPlaying = false;
    this.disableAllButtons = true;
    if (
      this.audioOliviaStatus == audioStatus.stop &&
      !this.featureAnimationPlaying
    ) {
      console.log('Both completed, 1st audio and then feature');
      this.waitingForNextFeature = setTimeout(() => {
        this.gotoNextFeature();
        // this.disableAllButtons = false;
        // console.log('this.disableAllButtons ---> ', this.disableAllButtons);
      }, 4000);
    }
    // if (this.selectedFeatureIndex + 1 < this.featuresList.length) {
    //   this.onSelectFeature(this.featuresList[this.selectedFeatureIndex + 1]);
    // }
  }
  onCarLoadComplete() {
    if (this.featuresList.length > 0) {
      setTimeout(() => {
        this.onSelectFeature(this.featuresList[0]);
      }, 2000);
    }
  }

  async getFeatureList() {
    try {
      let variantId: number = this.selectedVariant.variant_id;

      // if (this.loadedCar === 'zsev') {
      //   variantId = this.selectedVariant.variant_id;
      // } else {
      //   variantId = defaultCarDetails[this.loadedCar].variant_id;
      // }
      let apiBody = {
        model_id: defaultCarDetails[this.loadedCar].model_id,
        variant_id: variantId,
        source: 'web',
        seats: this.hectorSeatingOptions ? `${this.hectorSeatingOptions}S` : '',
      };
      let apiResp: any = await this._api.getFeatureList(apiBody);
      if (apiResp.status == 1) {
        console.log(apiResp);
        this.totalNoStory = apiResp.response.total_feature_story;
        this.currentFeatureStory = apiResp.response.currant_feature_story_id;
        this.featuresList = apiResp.response.all;
        console.log('this.featuresList', this.featuresList);
        console.log('this.featuresList', this.queryParams);
        let featureIndex = this.featuresList.findIndex(e=>(e.hotspot ==this.queryParams.hp));
        if(featureIndex>-1){
          this.onSelectFeature(this.featuresList[featureIndex]);
        }else{
          this.onSelectFeature(this.featuresList[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  onSelectFeature(feature) {
    if (this.isCarDoorOpen) {
      // AnimationManager.animmanager.doorAnimate();
      this._global.updateDoorState(false);
    }
    this.selectedFeature = feature;
    this.isFeatureSelected = true;
    let indx = this.featuresList.findIndex(
      (ele) => ele.feature_id == this.selectedFeature.feature_id
    );
    this.selectedFeatureIndex = indx;
    this._global.updateSoundTrackURL(this.selectedFeature.audio_path);
    if (indx == 0) {
      this.featurePreviousDisable = true;
    } else if (indx == this.featuresList.length) {
      this.featureNextDisable = true;
    }
    // this.featureClickCount(feature.feature_id);
    this._global.updateAccCam(true);
    this.playFeatureAnimation();
    this._ga.sendGAEvent('Hotspot_Features', "click", feature.name);
    // this.saveCustomerJourny('view_feature');
    // AnimationManager.animmanager.feature_Selected(feature.hotspot);
  }

  playFeatureAnimation() {
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    this._global.updateImageFeatureList([]);
    if (this.selectedFeature.type == 'threeD') {
      ONE3D.playFeature(this.selectedFeature.hotspot);
      this.featureAnimationPlaying = true;
      console.log(this.featureAnimationPlaying);
    } else if (this.selectedFeature.type == 'video') {
      // TODO Enable Video Div
      if (this._global.detectMob()) {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      } else {
        this._global.updateOverlaySrc(this.selectedFeature.desktop_image_video);
      }
      setTimeout(() => {
        document.getElementById('featureVideoFullScreen').style.display =
          'block';
      }, 100);
    } else if (this.selectedFeature.type == 'threeD+Image') {
      // TODO Enable Image top Div
      ONE3D.playFeature(this.selectedFeature.hotspot);
      // AnimationManager.animmanager.feature_Selected(
      //   this.selectedFeature.hotspot
      // );

      this.featureAnimationPlaying = true;
      if (this._global.detectMob()) {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      } else {
        this._global.updateOverlaySrc(this.selectedFeature.desktop_image_video);
      }

      setTimeout(() => {
        document.getElementById('featureImagePopup').style.display = 'block';
      }, 100);
    } else if (this.selectedFeature.type == 'image') {
      // TODO Enable Image fullscreen Div
      console.log('image Feature', this._global.detectMob());

      if (this._global.detectMob()) {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      } else {
        this._global.updateOverlaySrc(this.selectedFeature.desktop_image_video);
      }
      setTimeout(() => {
        document.getElementById('featureImageFullScreen').style.display =
          'block';
      }, 100);
    } else if (this.selectedFeature.type == 'carousel') {
      this._global.updateImageFeatureList(this.selectedFeature.carousel_imgage);
      document.getElementById('carusleFeature').style.display = 'block';
      setTimeout(() => {
        $('.topslider').slick({
          lazyLoad: 'ondemand',
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
          dots: true,
        });
      }, 500);
    }
  }

  gotoNextFeature() {
    this.disableAllButtons = false;
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    // let indx = this.featuresList.findIndex(
    //   (ele) => ele.feature_id == this.selectedFeature.feature_id
    // );
    // console.log(indx);
    if (this.waitingForNextFeature) clearTimeout(this.waitingForNextFeature);
    if (this.selectedFeatureIndex >= 0) {
      window.sendData({
        eName: 'internal',
        eCat: 'audio_assisted_page',
        eAct: 'audio_assisted_video',
        eVal: 'start',
      });
      if (this.selectedFeatureIndex + 1 == this.featuresList.length) {
        this.featureNextDisable = true;
        console.log('Ended All');
        ONE3D.event.exitFeatureView();
        this._global.updateAccCam(false);
        let url = defaultCarDetails[this.loadedCar].urlScheme;
        if (this.currentFeatureStory == this.totalNoStory) {
          if (this.loadedCar == 'kmodel') {
            this.router.navigate([`${url}/home`]);
          } else {
            this.router.navigate([`${url}/variants`]);
          }
        } else {
          if (this.queryParams && this.queryParams.goto) {
            this.location.back();
          } else {
            this._global.toggleFeatureCompletePopup(true);
          }
        }
      } else {
        this.featurePreviousDisable = false;
        // this.selectedFeature = this.featuresList[this.selectedFeatureIndex + 1];
        this.onSelectFeature(this.featuresList[this.selectedFeatureIndex + 1]);
        // this.playFeatureAnimation();
        // this.featureClickCount(indx + 1);
        if (this.selectedFeatureIndex + 1 == this.featuresList.length) {
          this.featureNextDisable = true;
        }
      }
    }
  }

  gotoPreviousFeature() {
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    // let indx = this.featuresList.findIndex(
    //   (ele) => ele.feature_id == this.selectedFeature.feature_id
    // );
    // console.log(indx);
    if (this.selectedFeatureIndex == 0) {
      this.featurePreviousDisable = true;
    } else {
      this.featurePreviousDisable = false;
      this.featureNextDisable = false;
      // this.selectedFeature = this.featuresList[this.selectedFeatureIndex + 1];
      this.onSelectFeature(this.featuresList[this.selectedFeatureIndex - 1]);
      // this.playFeatureAnimation();
      // this.featureClickCount(indx + 1);
      if (this.selectedFeatureIndex + 1 == this.featuresList.length) {
        this.featureNextDisable = true;
      }
    }
  }

  progressBarLength() {
    let ind = this.selectedFeatureIndex + 1;
    return (ind / this.featuresList.length) * 100;
  }

  public playMoreFeature() {
    console.log('Playing new set');
  }

  onVariantSelect(variant: any) {
    console.log('###  >>> variant data >', variant);
    if (
      this.selectedVariant.variant_id !== variant.variant_id ||
      this.isIntColorChange
    ) {
      console.log('Inner');
      this._global.toggleIntColorChange(false);
      document.getElementById('variantLoader').style.display = 'block';
      this._global.showLoading(true);
      // this.selectedVariant = variant;
      this._data.setLocalVariant(variant.variant_id);
      // localStorage.setItem('selectedVariant', variant.variant_id);
      this._global.updatedVariant(variant);
      this._global.updatedPrice(variant.price);
      ONE3D.removeAllAccessories();
      ONE3D.changeVariant(variant.d_name, { showFeature: true });
      window.updatePayloadParameter('eLa2', variant.title);

      sessionStorage.removeItem('selectedColorInt');
      if (this.isCarDoorOpen) {
        this._global.updateDoorState(false);
        defaultCarDetails[this.loadedCar].component.defaultCmp.isCarDoorOpen =
          false;
      }
      if (this.ACCESS) {
        this._socket.emitChangeVariant(variant);
      }

      this.getVariantDetails();
      // this.gaScript.sendGAEvent('view variants', variant.title, 'click');
    }else{
      if (this.queryParams && this.queryParams.hasOwnProperty('goto')) {
        this.getFeatureList();
      } else {
        this.getFeatureList();
      }
    }
  }

  async getVariantDetails() {
    try {
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this._data.getLocalVariant()
          ? this._data.getLocalVariant()
          : defaultCarDetails[this.loadedCar].variant_id,
        source: 'web',
      };
      let apiResp: any = await this._api.getVariantDetails(apiData);
      if (apiResp.status === 1) {
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });

        // this.colorList = apiResp.response.varinat_color;
        this._global.updateColorList(apiResp.response.varinat_color);
        this._global.updateAmbientColorList(apiResp.response.ambient_light);
        this.setSelectedColor();
      }
    } catch (error) {
      console.error(error);
    }
  }
  setSelectedColor() {
    let selectedColorCode = this._data.getLocalColor();
    console.log('selectedColorCode', selectedColorCode);

    if (selectedColorCode) {
      let foundColorItem = this.colorList.filter(
        (ele) => ele.variant_feature_id == selectedColorCode
      );
      if (foundColorItem.length > 0) {
        // this.selectedColor = foundColorItem[0];
        this._global.updateSelectedColor(foundColorItem[0]);
        this._data.setLocalColor(foundColorItem[0].variant_feature_id);
      } else {
        console.log('foundColorItem222', this.colorList[0]);
        // this.selectedColor = this.colorList[0];
        let filteredD = this.colorList.find((ele) => ele.default == 1);
        if (filteredD) {
          this._global.updateSelectedColor(filteredD);
          this._data.setLocalColor(filteredD.variant_feature_id);
        }
      }
    } else {
      console.log('foundColorItem333', this.colorList[0]);
      // this.selectedColor = this.colorList[0];
      let filteredD = this.colorList.find((ele) => ele.default == 1);
      if (filteredD) {
        this._global.updateSelectedColor(filteredD);
        this._data.setLocalColor(filteredD.variant_feature_id);
      }
    }
    this.changeColoredVariant('yes');
  }
  async changeColoredVariant(flag = '') {
    try {
      let locationInfo = JSON.parse(localStorage.getItem('location_info'));
      this._global.showLoading(true);
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this.selectedVariant.variant_id,
        variant_feature_id: this.selectedColor.variant_feature_id,
        state: locationInfo ? locationInfo.state : 'Delhi',
        city: locationInfo ? locationInfo.city : 'Delhi',
        change_variant: flag,
        source: 'web',
        interior_color_name: localStorage.getItem('selectedColorInt')
          ? 'Champagne & Black'
          : 'Black',
        page: 'feature',
      };
      let apiResp: any = await this._api.checkVariantColorSelection(apiData);
      if (apiResp.status == 1) {
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });

        this._global.updatedPrice(apiResp.response.showroom_price);
        if (apiResp.response && apiResp.response.variant_feature_id) {
          if (apiResp.response.hasOwnProperty('color_category')) {
            if (apiResp.response.color_category == 'interior') {
              localStorage.setItem(
                'selectedColorInt',
                apiResp.response.variant_feature_id
              );
            } else if (apiResp.response.color_category == 'exterior') {
              // let selectdC = this.colorList.filter(
              //   (ele) =>
              //     ele.variant_feature_id == apiResp.response.variant_feature_id
              // );
              // this.selectedColor = selectdC[0];
              // console.log('this.selectedColor', this.selectedColor);
              // this._data.setLocalColor(apiResp.response.variant_feature_id);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  goBack() {
    ONE3D.exitFeatureView();
    this._global.updateAccCam(false);
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/home`]);
  }
}
