<!--new-want-to-quit-popup-->
<div
  class="soundcheckmain new-latest-rounded-popup new-grant-permission-popup-wrapper"
  [ngClass]="{ 'normal-mode': afterFirstTime }"
>
  <!-- <div
  class="soundcheckmain new-latest-rounded-popup new-grant-permission-popup-wrapper normal-mode"
> -->
  <!--popup-->
  <div class="soundcheckmaininner">
    <div class="soundcheckmainOuter soundcheckmainOuter2">
      <div class="soundcheckbg">
        <!-- <span class="new-main-close-icon">
          <img
            class="new-main-close-cross"
            src="assets/images/new-close-icon.svg"
          />
        </span> -->
        <div class="fg cvbftitle">GRANT PERMISSION</div>
        <div class="new-cvbf-content">
          Please allow agent to access MG Motor website.
        </div>

        <div class="fg soundcheckbtnarea">
          <div class="soundcheckbtnarealeft">
            <a
              href="javascript:void(0)"
              class="btnplayright"
              (click)="onCancel()"
              ><span>Deny</span></a
            >
          </div>
          <div class="soundcheckbtnarearight">
            <a
              href="javascript:void(0)"
              class="btnplayleft"
              (click)="onContinue()"
              ><span>Allow</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--popup-->

  <!--overlay-->
  <div class="soundcheckmainOverlay soundcheckmainOverlay2"></div>
  <!--overlay-->
</div>
<!--new-want-to-quit-popup-->
