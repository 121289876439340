import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from 'src/app/service/api.service';
import { DataService } from 'src/app/service/data.service';
import { GlobalService } from 'src/app/service/global.service';
import { EventService } from 'src/app/service/event.service';
import { GaService } from 'src/app/service/ga.service';
import { defaultCarDetails } from '../carSettings';
import { MgExpertService } from 'src/app/service/mg-expert.service';
import { Subscriber, Subscription } from 'rxjs';
declare var ONE3D: any;
declare var window: any;

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {
  @ViewChild('scrollToForm') private formContainer: ElementRef;
  globalPinNO: string;
  pinSummary: any;
  accessoriesList = {
    Exterior: [],
    Interior: [],
    Essential: [],
    Pack: [],
    Stickers: []
  };
  summaryForm: FormGroup;
  showReSendOtpBtn: boolean = false;
  showResendOtpCounter: boolean = false;
  showSendOtpBtn: boolean = false;
  showOtpField: boolean = false;
  failedVerifiedOpt: boolean;
  showChangeNo: boolean;
  verifiedPhoneNo: boolean;
  previousPhoneNo: any;
  openOtherCharges: boolean = false;
  stateList: any[] = [];
  cityList: any[] = [];
  selectedState: string;
  selectedCity: string;
  dealerList: any[] = [];
  selectedDealer: any;
  timerCount: number = 40;
  interval: any;
  verifyOtpErr: boolean = false;
  otpErrorMsg: boolean;
  showErrorMsg: string;
  iAgree: any;
  isMarketingUpdates:boolean=true;
  validOtp: boolean = false;
  showProtectionPlan: boolean = false;
  protectionPlanType: any = '';
  protectionPlanDetails: any;
  total: any;
  emiOption: any;
  EMI_OPTION: boolean;
  HIDE_3D: boolean;
  openAlert: boolean = false;
  loadedCar: any;
  locationData: any;
  removeAccDetails: any;
  isOpenshieldPlan: boolean;
  SOCKET_STATUS: string;
  blockTestDrive: boolean;
  ACCESS: any;
  callConnected: any;
  $dataTransfer: Subscription;
  isAccAppliedTo3D: any;
  showLoading: boolean;
  setStateCityByPin: boolean;
  exchangePrice: string;
  headLampIsOpen: boolean;
  sunRoofIsOpen: boolean;
  isCarDoorOpen: boolean;
  SummaryFlag: boolean;
  packData: any = [];
  selectedDealerId: any;
  additionalPack: any;
  optionsPack: any;
  constructor(
    private _api: ApiService,
    private _expert: MgExpertService,
    private _ga: GaService,
    private globalService: GlobalService,
    private dataService: DataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private _event: EventService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadedCar = this.dataService.getLocalCarBrand();
    // let loc = this.dataService.getLocationInfo();
    // if (loc) {
    //   this.locationData = JSON.parse(loc);
    // }
    this.receiveDataTransfer();
    //Commented for now
    //Before calling this, check if pre-filled data is true or not. 
    //You can get this data from localstorage. If localstorage true, 
    //Then user has made the form journey, in that case, don't call the
    //getStateList fn, just call the getSummaryFrom().
    // if (localStorage.getItem("pincodeFlowProgress") != null || localStorage.getItem("pincodeFlowProgress") != undefined){
    //   this.getSummaryFrom();
    //   this.createForm();
    // }else{
    //   this.getStatList();
    //   this.createForm();
    //   this.getSummaryFrom();
    // }
    this.createForm();
    await this.getStatList();
    // this.createForm();
    this.getSummaryFrom();
    this.globalService.summaryFlag.subscribe((flag) => {
      this.SummaryFlag = flag;
    });
    this.globalService.hide3D.subscribe((flag) => {
      this.HIDE_3D = flag;
    });
    // if(this.globalService.detectMob()){
    //   if(this.SummaryFlag){
    //     this.HIDE_3D = true
    //   }else{
    //     this.HIDE_3D = flag
    //   }
    // }else{
    //   this.HIDE_3D = flag
    // }
    // });
    this.globalService.pinno.subscribe((flag) => (this.globalPinNO = flag));
    this.globalService.accessUi.subscribe((data) => (this.ACCESS = data));
    this.globalService.loader.subscribe((data) => (this.showLoading = data));
    this.globalService.audioStatus.subscribe(
      (data) => (this.callConnected = data)
    );
    this.globalService.socketStatus.subscribe(
      (data) => (this.SOCKET_STATUS = data)
    );

    // this.globalService.selectedColor.subscribe((data) => {
    //   this.getSummaryFrom();
    // });
    this.globalService.locationData.subscribe(
      (data) => (this.locationData = data)
    );
    this.globalService.isAccAppliedTo3D.subscribe(
      (data) => (this.isAccAppliedTo3D = data)
    );
    this.globalService.exchangePrice.subscribe(
      (data) => (this.exchangePrice = data)
    );

    this.globalService.emiOption.subscribe((data) => {
      this.emiOption = data;
      console.log('sss', Object.keys(this.emiOption).length);
      if (Object.keys(this.emiOption).length == 0) {
        this.EMI_OPTION = false;
      } else {
        this.EMI_OPTION = true;
      }
    });
    this.globalService.headLampIsOpen.subscribe(
      (data) => (this.headLampIsOpen = data)
    );
    this.globalService.sunRoofIsOpen.subscribe(
      (data) => (this.sunRoofIsOpen = data)
    );
    this.globalService.carDoorIsOpen.subscribe(
      (data) => (this.isCarDoorOpen = data)
    );
    if (this.isCarDoorOpen) {
      this.globalService.updateDoorState(false);
      ONE3D.toggleDoors();
    }
    if (this.headLampIsOpen) {
      this.globalService.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this.globalService.updateSunRoofState(false);
      ONE3D.toggleSunroof();
    }
    window.sendData({ eName: 'page_view', eCat: 'summary_page' });
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    if (this.callConnected && !this.ACCESS) {
      this.openOtherCharges = true;
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.$dataTransfer.unsubscribe();
  }

  scrollToBottom(): void {
    this.formContainer.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'start',
    });
  }

  /**
   * fetch the Summary data from server based on pin_no
   */
  // getSummaryFrom() {
  //   let pin_no = this.dataService.getLocalPinNo();
  //   this.globalService.showLoading(true);
  //   let apiData = {
  //     source: 'web',
  //     pin_no: pin_no,
  //   };
  //   this._api.getBuildDetails(apiData).then((data: any) => {
  //     this.globalService.showLoading(false);
  //     console.log('data', data);
  //     if (data.status == 1) {
  //       this.pinSummary = data.response.summary;
  //       this.total = data.response.summary.total;
  //       console.log('this.pinSummary', this.pinSummary);
  //       // this.pinSummary['total'] = parseInt(this.total.split(',').join(''));
  //       this.addProtectionPlanToTotal();
  //       let accList = [];
  //       if (data.response.added_accessories.length == 0) {
  //         this.accessoriesList = {
  //           Exterior: [],
  //           Interior: [],
  //           Essential: [],
  //         };
  //       }
  //       if (data.response.added_accessories.hasOwnProperty('Exterior')) {
  //         this.accessoriesList.Exterior =
  //           data.response.added_accessories.Exterior;
  //         this.accessoriesList.Exterior.forEach((ele) => {
  //           accList.push(ele.mesh);
  //         });
  //       } else {
  //         this.accessoriesList.Exterior = [];
  //       }
  //       if (data.response.added_accessories.hasOwnProperty('Interior')) {
  //         this.accessoriesList.Interior =
  //           data.response.added_accessories.Interior;
  //         this.accessoriesList.Interior.forEach((ele) => {
  //           accList.push(ele.mesh);
  //         });
  //       } else {
  //         this.accessoriesList.Interior = [];
  //       }
  //       if (data.response.added_accessories.hasOwnProperty('Essential')) {
  //         this.accessoriesList.Essential =
  //           data.response.added_accessories.Essential;
  //         this.accessoriesList.Essential.forEach((ele) => {
  //           accList.push(ele.mesh);
  //         });
  //       } else {
  //         this.accessoriesList.Essential = [];
  //       }
  //       console.log('this.isAccAppliedTo3D====>>>', this.isAccAppliedTo3D);

  //       // if (!this.isAccAppliedTo3D && ONE3D.loadedPercent == 100) {
  //       //   console.log('this.isAccAppliedTo3D', accList);

  //       //   ONE3D.addMultiAccessories(accList);
  //       // }
  //       if (data.response.customer_login == 'yes') {
  //         let customerData = JSON.parse(atob(data.response.customer_info));
  //         if (customerData.test_drive == 'yes') {
  //           this.blockTestDrive = true;
  //         }
  //         this.preFillForm(customerData);
  //       }else{
  //         // this.getStatList();
  //       }
  //       this.globalService.updateLocation({
  //         state: this.pinSummary.state,
  //         city: this.pinSummary.city,
  //       });
  //     }
  //   });
  // }
  getSummaryFrom() {
    return new Promise((resolve, reject) => {
      let pin_no = this.dataService.getLocalPinNo();
      this.globalService.showLoading(true);
      let apiData = {
        source: 'web',
        pin_no: pin_no,
      };
      this._api.getBuildDetails(apiData).then((data: any) => {
        this.globalService.showLoading(false);
        console.log('data', data);
        if (data.status == 1) {
          this.pinSummary = data.response.summary;
          this.total = data.response.summary.total;
          console.log('this.pinSummary', this.pinSummary);
          this.addProtectionPlanToTotal();
          let accList = [];
          this.packData = data.response.added_pack;
          this.additionalPack = data.response.added_sub_pack;
          this.optionsPack = data.response.added_sub_pack_kit;
          if (data.response.added_accessories.length == 0) {
            this.accessoriesList = {
              Exterior: [],
              Interior: [],
              Essential: [],
              Pack: [],
              Stickers: []
            };
          }
          if (data.response.added_accessories.hasOwnProperty('Exterior')) {
            this.accessoriesList.Exterior =
              data.response.added_accessories.Exterior;
            this.accessoriesList.Exterior.forEach((ele) => {
              accList.push(ele.mesh);
            });
          } else {
            this.accessoriesList.Exterior = [];
          }
          if (data.response.added_accessories.hasOwnProperty('Interior')) {
            this.accessoriesList.Interior =
              data.response.added_accessories.Interior;
            this.accessoriesList.Interior.forEach((ele) => {
              accList.push(ele.mesh);
            });
          } else {
            this.accessoriesList.Interior = [];
          }
          console.log("Check this response", data.response.added_accessories.hasOwnProperty('Essential'));
          if (data.response.added_accessories.hasOwnProperty('Essential')) {
            this.accessoriesList.Essential =
              data.response.added_accessories.Essential;
            this.accessoriesList.Essential.forEach((ele) => {
              accList.push(ele.mesh);
            });
            console.log("Check this listing", this.accessoriesList);
          } else {
            this.accessoriesList.Essential = [];
          }
          if (data.response.added_accessories.hasOwnProperty('Stickers')) {
            this.accessoriesList.Stickers =
              data.response.added_accessories.Stickers;
            this.accessoriesList.Stickers.forEach((ele) => {
              accList.push(ele.mesh);
            });
          } else {
            // this.accessoriesList.Essential = [];
            this.accessoriesList.Stickers = [];
          }
          console.log('this.isAccAppliedTo3D====>>>', this.isAccAppliedTo3D);

          if (data.response.customer_login == 'yes') {
            let customerData = JSON.parse(atob(data.response.customer_info));
            if (customerData.test_drive == 'yes') {
              this.blockTestDrive = true;
            }
            this.preFillForm(customerData);
          } else {
            // this.getStatList();
          }
          this.globalService.updateLocation({
            state: this.pinSummary.state,
            city: this.pinSummary.city,
          });

          resolve(data);
        } else {
          reject(new Error("Error getting data from API"));
        }
      });
    });
  }
  /**
   * this function called when user saves the Summary Form on HTML
   */
  bookNow() {
    console.log('form data', this.summaryForm);
  }
  /**
   * Test Drive Button clicked
   */
  testDrive() {
    console.log('test Drive APi call ');
  }
  /**
   * Create Summary Form for the Customer
   */
  createForm() {
    // let user_data = JSON.parse(localStorage.getItem('user_data'));
    let user_data = this.dataService.geUserDataInfo();
    console.log('user_data>>>>>>>>', user_data);
    if (user_data) {
      this.showReSendOtpBtn = false;
      this.showResendOtpCounter = false;
      this.showSendOtpBtn = false;
      this.showChangeNo = true;
      this.verifiedPhoneNo = true;
    }
    this.summaryForm = this.formBuilder.group({
      phone: new FormControl(
        {
          value:
            user_data && user_data.hasOwnProperty('mobile')
              ? user_data.mobile
              : '',
          disabled:
            user_data && user_data.hasOwnProperty('mobile') ? true : false,
        },
        {
          validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
          // updateOn: 'blur',
        }
      ),
      otp: new FormControl('', {
        validators: [Validators.pattern(/^[0-9]{4}$/)],
        // updateOn: 'blur',
      }),
      email: new FormControl(
        user_data && user_data.hasOwnProperty('email') ? user_data.email : '',
        {
          validators: [
            Validators.required,
            Validators.pattern(
              '^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$'
            ),
            // Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
          updateOn: 'blur',
        }
      ),
      firstName: new FormControl(
        user_data && user_data.hasOwnProperty('first_name')
          ? user_data.first_name
          : '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern("^[A-Za-z ,.'-~]+$"),
          ],
          updateOn: 'blur',
        }
      ),
      lastName: new FormControl(
        user_data && user_data.hasOwnProperty('last_name')
          ? user_data.last_name
          : '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern("^[A-Za-z ,.'-~]+$"),
          ],
          updateOn: 'blur',
        }
      ),
      pin: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      dealer: new FormControl('', [Validators.required]),
      lookingForFinance: new FormControl('no', {
        validators: [],
        updateOn: 'blur',
      }),
      protectionPlan: new FormControl('no', {
        validators: [],
        updateOn: 'blur',
      }),
    });
  }
  /**
   * this function called when key up event triggered in phone number field
   */
  onPhoneNoChange() {
    if (
      this.summaryForm.get('phone').valid &&
      this.previousPhoneNo != this.summaryForm.value.phone
    ) {
      this.showChangeNo = false;
      this.showSendOtpBtn = true;
    } else {
      this.showSendOtpBtn = false;
      this.showChangeNo = false;
      this.verifiedPhoneNo = true;
    }
  }
  /**
   * when user changes the number in the mobile number field
   */
  onClickChangeNo() {
    window.sendData({
      eName: 'click',
      eCat: 'summary_menu',
      eAct: 'change_number_plan_clicked',
    });
    this.summaryForm.controls['phone'].enable({ onlySelf: true });
    this.showChangeNo = false;
    this.summaryForm.controls['otp'].reset();
    this.previousPhoneNo = this.summaryForm.value.phone;
    this.verifiedPhoneNo = false;
    this._ga.sendGAEvent('summary', 'click', 'change number');
  }

  /**
   * toggle other charges view and hide
   */
  toggleCharges() {
    this.openOtherCharges = !this.openOtherCharges;
    this._ga.sendGAEvent(
      'summary',
      'click',
      this.openOtherCharges ? 'chrge hide' : 'chrge view'
    );
    window.sendData({
      eName: 'click',
      eCat: 'summary_menu',
      eAct: this.openOtherCharges
        ? 'hide_other_charges_button_clicked'
        : 'view_other_charges_button_clicked',
    });
  }
  /**
   * page routes to the emi calculator
   */
  gotoEmiPage() {
    window.sendLiveDashboardData({
      name: 'click',
      category: 'EMI',
      type: 'Plan',
    });
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this._api.saveBuildSummary(this.globalPinNO, 'emi');
    this.router.navigate([`${url}/emi-calculator`]);
    this._ga.sendGAEvent('summary', 'click', 'emi');
    // this.router.navigate(['hector/emi-calculator']);
  }
  /**
   * load state list for dropdown
   */
  //  getStatList() {
  //    this._api.getStateList().then((resp: any) => {
  //     if (resp.status == 1) {
  //       this.stateList = resp.response;
  //       console.log('this.locationData1', this.locationData);

  //       if (this.locationData.hasOwnProperty('state')) {
  //         this.summaryForm.patchValue({
  //           state: this.locationData.state,
  //         });
  //         //Commented this now. 
  //         console.log("Callme 454");
  //         this.stateSelected(this.locationData.state);
  //         //Already commented in the OG code. 
  //         // this.selectedState = this.stateList[0].state;
  //       } else {
  //         this.summaryForm.patchValue({
  //           state: 'Delhi',
  //         });
  //         console.log("Callme 461");
  //         this.stateSelected('Delhi');
  //       }
  //     }
  //   });
  // }
  getStatList(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._api.getStateList().then((resp: any) => {
        if (resp.status == 1) {
          this.stateList = resp.response;
          console.log('this.locationData1', this.locationData);

          if (this.locationData && this.locationData.hasOwnProperty('state')) {
            this.summaryForm.patchValue({
              state: this.locationData.state,
            });
            console.log("Callme 454");
            this.stateSelected(this.locationData.state)
          } else {
            this.summaryForm.patchValue({
              state: 'Delhi',
            });
            console.log("Callme 461");
            this.stateSelected('Delhi');
          }
          resolve();
        } else {
          reject(new Error('Error getting state list'));
        }
      });
    });
  }
  /**
   * function called when user selects the state from dropdown
   * @param state State Name which is selected
   */
  //  stateSelected(state, dealer_id?) {
  //   // if (this.selectedState !== state) {
  //   this.selectedState = state;
  //   this._api.getCityListing({ state }).then((resp: any) => {
  //     if (resp.status == 1) {
  //       this.cityList = resp.response;
  //       // this.selectedCity = this.cityList[0].city;
  //       console.log('this.locationData2', this.locationData);

  //       if (this.locationData.hasOwnProperty('city')) {
  //         // if (this.summaryForm.value.city !== this.locationData.city) {
  //         // }
  //         //Commented this time out
  //         // alert(dealer_id)
  //         setTimeout(() => {
  //           this.summaryForm.patchValue({
  //             city: this.locationData.city,
  //             dealer: dealer_id
  //           });
  //         }, 500);
  //         console.log('this.setStateCityByPin', this.summaryForm.value);

  //         if (this.setStateCityByPin) {
  //           this.summaryForm.controls['city'].disable();
  //           // this.summaryForm.controls['dealer'].disable();
  //           this.selectedCity = this.locationData.city;
  //         } else {
  //           this.citySelected(this.locationData.city);
  //         }
  //       } else {
  //         this.summaryForm.patchValue({
  //           city: 'Delhi',
  //         });
  //         this.citySelected('Delhi');
  //       }
  //     }
  //   });
  //   // }
  // }

  stateSelected(state, dealer_id?) {
    return new Promise((resolve, reject) => {
      this.selectedState = state;
      this._api.getCityListing({ state }).then(async (resp: any) => {
        if (resp.status == 1) {
          this.cityList = resp.response;

          if (this.locationData.hasOwnProperty('city')) {
            setTimeout(() => {
              this.summaryForm.patchValue({
                city: this.locationData.city,
                dealer: dealer_id
              });
            }, 500);

            if (this.setStateCityByPin) {
              this.summaryForm.controls['city'].disable();
              this.selectedCity = this.locationData.city;
            } else {
              await this.citySelected(this.locationData.city);
            }
          } else {
            console.log("Patching city", this.cityList[0]);
            this.summaryForm.patchValue({
              // city:"Delhi" // Original code. Delhi hardcoding is wrong. Hence assigning 1st element 
              //from the list. 
              city: this.cityList[0].city,
            });
            this.citySelected('Delhi');
          }
          resolve(this.cityList);
        } else {
          reject('Error occurred while getting city list.');
        }
      });
    });
  }

  /**
   * function called when user selects the city from dropdown
   * @param state State Name which is selected
   */
  // citySelected(city) {
  //   console.log('Printing.....', city, this.selectedState);
  //   this.selectedCity = city;
  //   this._api
  //     .getDealerListing({
  //       pin_no: this.dataService.getLocalPinNo(),
  //       city: city,
  //       state: this.selectedState,
  //       // city: "Mumbai",
  //       // state: "Maharashtra",
  //     })
  //     .then((resp: any) => {
  //       console.log('resp for 517', resp);
  //       if (resp.status == 1) {
  //         this.dealerList = resp.response;
  //         //  this.summaryForm.patchValue({
  //         //   dealer: 18,
  //         // });
  //         // this.selectedDealer = resp.response[0];
  //         //Commented this
  //         this.summaryForm.patchValue({
  //           dealer: '',
  //           // dealer: 18
  //         });
  //         this.selectedDealer = resp.response[0];
  //       }
  //     });
  // }
  citySelected(city): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log('Printing.....', city, this.selectedState);
      this.selectedCity = city;
      this._api.getDealerListing({
        pin_no: this.dataService.getLocalPinNo(),
        city: city,
        state: this.selectedState,
      })
        .then((resp: any) => {
          console.log('resp for 517', resp);
          if (resp.status == 1) {
            this.dealerList = resp.response;
            this.summaryForm.patchValue({
              dealer: '',
            });
            this.selectedDealer = resp.response[0];
            resolve(this.selectedDealer);
          } else {
            reject('Failed to get dealer listing');
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }
  /**
   * function called when user selects the Dealer from dropdown
   * @param state State Name which is selected
   */
  dealerSelected(dealer) {
    this.selectedDealer = dealer;
    let body = {
      pin_no: this.dataService.getLocalPinNo(),
      state: this.selectedState,
      city: this.selectedCity,
      dealer_id: this.selectedDealer,
    };
    this._api.dealerSelected(body).then((resp: any) => {
      console.log('resp', resp);
      if (resp.status == 1) {
        this.globalService.updateLocation({
          state: body.state,
          city: body.city,
        });
        this.dataService.setLocationInfo({
          state: body.state,
          city: body.city,
        });
        this.pinSummary.other_charges = resp.response.other_charges.details;
        console.log('dealer selection done');
      }
    });
  }
  /**
   *
   * @param controlName controller whose value needs to check
   * @param errorName type of error needs to check
   * @returns boolean
   */
  hasError = (controlName: string, errorName: string) =>
    (this.summaryForm.controls[controlName].touched ||
      this.summaryForm.controls[controlName].dirty) &&
    this.summaryForm.controls[controlName].hasError(errorName);

  logEvent(act) {
    window.sendData({ eName: 'click', eCat: 'summary_menu', eAct: act });
  }
  /**
   * Send the OTP to the User
   */
  otpSend() {
    this._ga.sendGAEvent('Summary', 'click', 'Send OTP');
    this.globalService.showLoading(true);
    this.showChangeNo = false;
    let apiData = {
      mobile: this.summaryForm.value.phone.toString(),
      sourec: 'web',
    };
    this._api.otpSend(apiData).subscribe(
      (data: any) => {
        this.globalService.showLoading(false);
        // console.log(data);
        if (data) {
          let response = JSON.parse(atob(data));
          if (response.status == 1) {
            this.summaryForm.controls['otp'].reset();
            this.showOtpField = true;
            this.showReSendOtpBtn = true;
            this.showSendOtpBtn = false;
            this.showResendOtpCounter = true;
            this.timerCount = 30;
            this.interval = setInterval(() => {
              this.resendOtpCounter();
            }, 1000);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  /**
   * resend otp Counter set
   */
  resendOtpCounter() {
    if (this.timerCount == 0) {
      clearInterval(this.interval);
      this.showResendOtpCounter = false;
    } else {
      this.timerCount--;
    }
  }
  /**
   * verify the OTP entered by the Users
   */
  onVeriFyOtp() {
    this._ga.sendGAEvent('Summary', 'click', 'Verify');
    console.log('dddd');
    this.globalService.showLoading(true);
    clearInterval(this.interval);
    this.showReSendOtpBtn = true;
    this.showResendOtpCounter = false;
    this.timerCount = 30;
    let apiData = {
      mobile: this.summaryForm.value.phone.toString(),
      otp: this.summaryForm.value.otp.toString(),
      pin_no: this.dataService.getLocalPinNo(),
      source: 'web',
    };
    window.sendData({
      eName: 'click',
      eCat: 'summary_menu',
      eAct: 'verify_otp_button_clicked',
    });
    this._api.customerLogin(apiData).subscribe((data: any) => {
      this.globalService.showLoading(false);
      if (data.status == 1) {
        window.updatePayloadParameter('cuId', data.response.cust_id);

        // console.log('Login done', data);
        this.showOtpField = false;
        this.showChangeNo = true;
        this.showSendOtpBtn = false;
        this.verifiedPhoneNo = true;
        this.showReSendOtpBtn = false;
        this.summaryForm.controls['phone'].disable({ onlySelf: true });
        this.preFillForm(data.response);
        // localStorage.setItem('user_data', JSON.stringify(data.response));
        this.dataService.setUserDataInfo(data.response);
        this.verifyOtpErr = false;
      } else {
        this.failedVerifiedOpt = true;
      }
    });
  }

  preFillForm(data) {
    console.log('Pre filed value', data);
    this.summaryForm.patchValue({
      phone: data.mobile,
      firstName: data.first_name ? data.first_name : '',
      lastName: data.last_name ? data.last_name : '',
      email: data.email ? data.email : '',
      pin: data.pincode ? data.pincode : '',
      //Code here, if pincode present in localstorage, 
      //fetch it from here.
      // pin: "400065",
      state: data.dealer_state ? data.dealer_state : '',
      city: data.dealer_city ? data.dealer_city : '',
      dealer: data.dealer_id ? data.dealer_id : '',
    });
    //Uncomment the IF condition
    // data.pincode = "400065"
    if (data.hasOwnProperty('pincode') && data.pincode != '') {
      data.dealer_id ? this.getDealerByPinCode(data.dealer_id) : this.getDealerByPinCode();
    }
    this.dataService.setLocationInfo({
      state: data.dealer_state ? data.dealer_state : '',
      city: data.dealer_city ? data.dealer_city : '',
    });

    if (data.dealer_state) {
      // console.log("Callme 694");
      // this.stateSelected(data.dealer_state);
    }
    console.log('data.dealer_state', data.dealer_state);
  }
  /**
   * this function called when user clicks on the submit button/book now button
   */
  updateCustomerInfo(submitType) {
    console.log(this.iAgree, this.verifiedPhoneNo, this.summaryForm.valid);
    if (submitType == 'test_drive') {
      window.sendLiveDashboardData({
        name: 'Test Drive',
        category: 'Test_Drive',
        type: 'CTA'
      });
    } else {
      window.sendLiveDashboardData({
        name: 'Book Now',
        category: 'Book_Now',
        type: 'CTA'

      });
    }
    if (this.summaryForm.valid && this.verifiedPhoneNo) {
      console.log('Inner 1');

      if (!this.iAgree) {
        this.otpErrorMsg = true;
        this.showErrorMsg = 'Please accept privacy policy';
        setTimeout(() => {
          this.otpErrorMsg = false;
        }, 5000);
      } else {
        this.globalService.showLoading(true);
        let user_data = this.dataService.geUserDataInfo();
        if (user_data && user_data.cust_id) {
          let apiData = {
            pin_no: this.dataService.getLocalPinNo(),
            mobile: this.summaryForm.getRawValue().phone,
            first_name: this.summaryForm.value.firstName,
            last_name: this.summaryForm.value.lastName,
            email: this.summaryForm.value.email,
            gender: '',
            pincode: this.summaryForm.value.pin,
            cust_id: user_data.cust_id.toString(),
            source: 'web',
            finance: this.summaryForm.value.lookingForFinance,
            protect_plan: this.protectionPlanDetails
              ? this.protectionPlanDetails.plan_id
              : '',
            selected_cta: submitType,
            consent: this.isMarketingUpdates?"yes":"no"
          };
          console.log("The data", apiData);
          this._api.saveConsultationDetails(apiData).subscribe((data: any) => {
            let response = JSON.parse(data);
            console.log('data', response);
            this.summaryForm.reset();
            // alert('configuration saved');
            this.globalService.showLoading(false);
            localStorage.clear();
            this.dataService.deleteCookie('_ud');
            this.globalService.toggleSubmitModal(true);
            const selectedDealer = this.dealerList.find(
              (ele) => ele.dealer_id == this.selectedDealer
            );
            if (submitType == 'test_drive') {

              this._api.saveBuildSummary(this.globalPinNO, 'test_drive');
              this._ga.sendGAEvent('CTA', 'click', 'Test drive');
              window.sendLiveDashboardData({
                name: 'click',
                category: 'Test Drive',
                type: 'CTA'
              });
              this.globalService.updateThankyouText(
                'Your test drive request was successfully submitted. You can connect with our Live Agent by clicking on the Green button.'
              );
            } else {

              this.globalService.toggleSubmitModal(false);
              this._api.saveBuildSummary(this.globalPinNO, 'book_now');
              // this.globalService.updateThankyouText(
              //   'Your Online Booking request was successfully submitted.'
              // );
              // alert(this.loadedCar)
              this._ga.sendGAEvent('CTA', 'click', 'book now');
              window.sendData({
                name: 'click',
                category: 'Book Now',
                type: 'CTA'

              });
              if (this.loadedCar == 'kmodel') {
                this.createCCIDAndRedirect();
              } else if (this.loadedCar == 'emodel') {
                window.location.replace(
                  'https://www.mgmotor.co.in/vehicles/comet-ev-electric-car-in-india/ebooking-vibe-check'
                );
              } else {
                window.location.replace('https://www.mgmotor.co.in/tools/ebooking');
              }

              // if (this.loadedCar == 'kmodel') {
              //   this.createCCIDAndRedirect();
              // } else {
              //   window.location.replace(
              //     'https://www.mgmotor.co.in/tools/ebooking'
              //   );

              // }
            }
          });
        } else {
          this.globalService.showLoading(false);
          this.verifyOtpErr = true;
        }
      }
    } else {
      console.log('Innwerer 23', this.verifiedPhoneNo);

      Object.keys(this.summaryForm.controls).forEach((field) => {
        const control = this.summaryForm.get(field);
        control.markAsTouched({ onlySelf: true });
        control.markAsDirty({ onlySelf: true });
      });
      if (!this.verifiedPhoneNo) {
        this.otpErrorMsg = true;
        this.showErrorMsg = 'Please verify the phone number';
        setTimeout(() => {
          this.otpErrorMsg = false;
        }, 5000);
      }
    }
  }
  async createCCIDAndRedirect() {
    try {
      let apiBody = {
        pin_no: this.globalPinNO,
      };
      let apiResp: any = await this._expert.createCCuid(apiBody);
      // alert(JSON.stringify(apiBody))
      console.log('apiResp', apiResp);
      if (apiResp.status == 200) {
        window.location.href = apiResp.response.booking_url;
      }
    } catch (error) {
      console.error(error);
    }
  }
  validateOtp() {
    console.log('caleed', this.summaryForm.value.otp);
    if (this.hasError('otp', 'pattern') || this.summaryForm.value.otp == '') {
      this.validOtp = false;
    } else {
      this.validOtp = true;
    }
  }
  /**
   * opens the Protection Plan Model
   */
  gotoProtectionPlan() {
    window.sendData({
      eName: 'click',
      eCat: 'summary_menu',
      eAct: 'protection_plan_clicked',
    });
    this.showProtectionPlan = true;
    this._api.saveBuildSummary(this.globalPinNO, 'plan_view');
    this._ga.sendGAEvent('Protect plan', 'click', 'click');
  }
  /**
   * close the protection plan model
   * @param value null
   */
  closeProtectionPlanModal(value) {
    this.showProtectionPlan = value;
    this.addProtectionPlanToTotal();
  }
  /**
   * sets the protection plan type which is selected by the user
   * @param value protection plan type
   */
  setProtectionPlanType(value) {
    this.protectionPlanType = value;
  }

  hide3d() {
    console.log('clicked');
    this.globalService.updateHide3D(!this.HIDE_3D);
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 500); // resize animation conflict issue fixed
  }
  show3d() {
    this.globalService.updateHide3D(false);
  }

  addProtectionPlanToTotal() {
    let localPlanData = sessionStorage.getItem('selectedProtectionPlan');
    this.protectionPlanType = sessionStorage.getItem(
      'selectedProtectionPlanType'
    );
    if (this.pinSummary && localPlanData) {
      console.log('inner 1');

      this.protectionPlanDetails = JSON.parse(localPlanData);
      if (typeof this.pinSummary.total != 'string') {
        this.pinSummary.total = this.pinSummary.total.toString();
      }
      if (
        (this.loadedCar == 'kmodel' || this.loadedCar == 'gloster') &&
        this.protectionPlanDetails.pro_plan_type.includes('MG Shield Plan')
      ) {
        console.log('inner 2');
        // let accPrice = parseInt(
        //   this.pinSummary['accessories_amount'].split(',').join('')
        // );
        let carPrice = parseInt(this.pinSummary['total'].split(',').join(''));
        let protectPlan = parseInt(
          this.protectionPlanDetails.mrp.split(',').join('')
        );
        // if (accPrice + protectPlan > 50000) {
        //   this.discoutPrice = 50000;
        //   this.pinSummary['total'] =
        //     carPrice + (accPrice + protectPlan - 50000);
        // } else {
        // }
        // this.discoutPrice = accPrice + protectPlan;
        this.pinSummary['total'] = carPrice + protectPlan;
      }

      if (
        this.protectionPlanDetails.pro_plan_type.includes('Protect Plan') ||
        this.protectionPlanDetails.pro_plan_type.includes('3-50 Plan')
      ) {
        this.pinSummary['total'] =
          parseInt(this.total.split(',').join('')) +
          parseInt(this.protectionPlanDetails.mrp.split(',').join(''));
      }
    } else {
      this.protectionPlanDetails = null;
      this.pinSummary['total'] = parseInt(this.total.split(',').join(''));
    }
  }

  formatCurrency(value: any) {
    // console.log('value', value);
    return isNaN(value)
      ? 'xx,xx,xxx'
      : Number(value).toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
  }

  openDeleteConfirmModal(item: any) {
    this.openAlert = true;
    this.removeAccDetails = item;
  }
  removeAccessory() {
    this.updateAccessoryToApi(this.removeAccDetails);
  }

  async updateAccessoryToApi(item: any) {
    try {
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this.pinSummary.variant_id,
        access_id: item.accessories_id,
        subgroup_id: item.subgroup_id,
        change_variant: '',
        flag: item.flag,
        price: item.price,
        type: 'Addons',
        qty: 1,
        is_multiple: item.is_multiple,
        source: 'web',
        acces_type: item.acces_type,
      };
      let respData: any = await this._api.addRemoveAccessories(apiData);
      if (respData.status == 1) {
        window.sendData({
          eName: 'click',
          eCat: 'summary_menu',
          eAct: 'delete_accessory_button_clicked',
          eval: item.marketing_name,
          eLa4: item.category,
          eLa5: item.subgroup_name,
          eLa6: item.subgroup_name,
        });
        this.globalService.updatedPrice(
          respData.response.summary.showroom_price
        );
        this.openAlert = false;
        this._ga.sendGAEvent(
          `${item.category} access`,
          'click',
          item.marketing_name
        );
        this.getSummaryFrom();
        if (item.hasOwnProperty('type') && item.type == '3d') {
          ONE3D.removeAccessories(item.mesh, item.tag);
        }
        // this.updateAccData(respData.response.accessories);
      }
    } catch (error) {
      this.globalService.showLoading(false);
      console.log('Error in Acc', error);
    }
  }

  openShieldPlan() {
    //code here
    this._ga.sendGAEvent('shield_plan', 'click', 'add');
    window.sendLiveDashboardData({
      name: 'click',
      category: 'Shield',
      type: 'Plan',
    });
    this._api.saveBuildSummary(this.globalPinNO, 'plan_view');
    this.isOpenshieldPlan = true;
  }

  closeShieldPopup(action: string) {
    this.isOpenshieldPlan = false;
    this.addProtectionPlanToTotal();
  }

  goBack() {
    window.sendData({
      eName: 'click',
      eCat: 'summary_menu',
      eAct: 'back_button_clicked',
    });
    // let url = defaultCarDetails[this.loadedCar].urlScheme;
    // this.router.navigate([`${url}/accessorize`]);
    this.location.back();
    /**
     * Conditions added by @JP
     * to avoid mobile-fullscreen
     */
    this.globalService.rightPanelHideShow(false);
    // setTimeout(() => {
    //   ONE3D.event.onResizeWindow();
    // }, 1000); // resize animation conflict issue fixed
  }

  async getDealerByPinCode(dealer_id?) {
    //Code here
    try {
      if (this.summaryForm.value.pin.toString().length == 6) {
        let body = {
          pincode: this.summaryForm.value.pin,
          model_id: defaultCarDetails[this.loadedCar].model_id,
        };
        let apiResp: any = await this._api.getDealerByPinCode(body);
        if (apiResp.status == 1 && apiResp.response.length > 0) {
          console.log('apiResp.dealers', apiResp.response);
          this.globalService.updateLocation({
            state: apiResp.response[0].state,
            city: apiResp.response[0].city,
          });
          // this.summaryForm.controls['state'].enable();
          // this.summaryForm.controls['city'].enable();
          this.summaryForm.patchValue({
            state: apiResp.response[0].state,
          });
          this.setStateCityByPin = true;
          this.summaryForm.controls['state'].disable();
          console.log("Callme 1040");
          //Pass dealer id if present in local storage.
          //Patch value for dealerList selection will happen inside
          //stateSelected, since patching it at multiple places 
          //leads to error and confusion. 
          //So city is getting patched in stateSelected, so will the
          //dealer - assuming dealerId is contained in the api res.
          // (dealer_id) ? this.stateSelected(apiResp.response[0].state, dealer_id) : this.stateSelected(apiResp.response[0].state);
          await this.stateSelected(apiResp.response[0].state, dealer_id)
          this.dealerList = apiResp.response;
          //This id should be from the localstorage if present.
          console.log("patching the val", this.selectedCity, this.selectedState);
          // console.log("state", this.selectedCity);
          // setTimeout(() => {
          // this.selectedDealerId=18;
          // this.summaryForm.patchValue({
          //   dealer: 18,
          // });
          // this.summaryForm.controls['dealer'].disable();
          //Pass the dealer id you get from API.
          // setTimeout(() => {
          // console.log("patching the val", this.selectedCity, this.selectedState);
          this.dealerSelected(dealer_id)
          // }, 5000);
        }
      } else {
        this.setStateCityByPin = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  receiveDataTransfer() {
    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      let keys = Object.keys(data);
      let key = keys[0];
      console.log('dataTransfer111', data);
      switch (key) {
        case 'gotoShieldPlanPage':
          this.isOpenshieldPlan = true;
          break;
        case 'gotoProtectionPlanPage':
          this.showProtectionPlan = true;

          break;
        default:
          break;
      }
    });
  }

  gotoCarExchangePage() {
    this._api.saveBuildSummary(this.globalPinNO, 'car_exchange');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/car-exchange`]);
    this._ga.sendGAEvent('summary', 'click', 'car-exchange');
  }
}
