<!-- <div class="wrapper"> -->
<div class="sound-wave" [ngClass]="{ stop: !showAnim }">
  <div class="sound-bar"></div>
  <div class="sound-bar"></div>
  <div class="sound-bar"></div>
  <div class="sound-bar"></div>
  <div class="sound-bar"></div>
  <div class="sound-bar"></div>
  <div class="sound-bar"></div>
</div>
<!-- </div> -->
