import { ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { Location } from '@angular/common';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { SocketService } from '../../service/socket.service';
import { GaService } from '../../service/ga.service';
import { defaultCarDetails } from '../carSettings';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/service/event.service';
import { dummyObj } from 'src/app/service/response';
import { environment } from 'src/environments/environment';
declare const ONE3D: any;
declare const window: any;
const SELECTEDACC = "selectedAcc";
const SELECTEDPACK = "selectedPacks";
@Component({
  selector: 'app-accessorize-list',
  templateUrl: './accessorize-list.component.html',
  styleUrls: ['./accessorize-list.component.scss'],
})
export class AccessorizeListComponent implements OnInit, OnDestroy {
  limitedEdition: boolean;
  limitedEditionData: any;
  sunRoofIsOpen: boolean;
  headLampIsOpen: boolean;
  logoOn: boolean;
  backRoutingUrl: string;
  //Custom 2
  overAllMeshListCustom2: any[];
  //Custom 1
  overAllMeshListCustom1: any[];
  //Custom 0
  overAllMeshListCustom0: any[];
  insertionIndexDummyObj: any;
  showList: boolean = false;
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    // Handle the event here
    localStorage.removeItem(SELECTEDPACK);
    localStorage.removeItem(SELECTEDACC);
  }
  loadedCar: string;
  globalPinNO: string;
  isCarDoorOpen: boolean;
  selectedColor: any;
  selectedVariant: import('../../service/global.service').variant;
  accList: any;
  accResp: any;
  selectedAccGroup: any;
  isAccPreview: boolean;
  totalPrice: any;
  ACCESS: boolean;
  locationInfo: any;
  isInterior: boolean;
  colorList: any[];
  isAccAppliedTo3D: boolean;
  isConfirmModalOpen: boolean;
  $dataTransfer: Subscription;
  $accAddData: Subscription;
  $accRemoveData: Subscription;
  $addPackAddData: Subscription;
  $addPackRemData: Subscription;
  $addOptionsAddData: Subscription;
  $addOptionsRemData: Subscription;
  SOCKET_STATUS: string;
  $selectedColorObserver: Subscription;
  audioCallConnected: boolean;
  accUrl: string;
  selectedAcc: any = [];
  selectedPacks: any = [];
  isOverlappingModal: boolean = false;
  overlappingItems: any = [];

  selectedPackage: any;
  selectedAccessory: any;
  selectedAdditionalPackage: any;
  selectedGroupOptionKit: any;
  selectedAddonAccessory: any;
  overlappingWith: string = "";
  packageDetails: any;
  viewDetailsShown: boolean = false;
  overlappingAccWithGamer: boolean = false;


  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _ga: GaService,
    private zone: NgZone,
    private router: Router,
    private _event: EventService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.accUrl = environment.accessoriesImageCdnUrl;
  }

  ngOnInit(): void {
    window['accRef'] = {
      zone: this.zone,
      one3dcallback: {
        onVariantLoadComplete: () => this.onVariantLoadComplete(),
        onCarLoadComplete: () => this.applyAddedAcc(),
        onKitLoadComplete: () => this.kitLoadComplete(),
      },
      component: this,
    };
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.globalPinNO = data));
    this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data));
    this._global.sunRoofIsOpen.subscribe((data) => (this.sunRoofIsOpen = data));
    this._global.limitedEditionUI.subscribe((data) => (this.limitedEdition = data));
    this._global.headLampIsOpen.subscribe(
      (data) => (this.headLampIsOpen = data)
    );
    this._global.logoOn.subscribe((data) => (this.logoOn = data));
    this.$selectedColorObserver = this._global.selectedColor.subscribe(
      (data) => {
        this.getAccList();
        this.removeColorAcc();
        this.selectedColor = data;
      }
    );
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.price.subscribe((data) => (this.totalPrice = data));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.isInterior.subscribe((data) => (this.isInterior = data));
    this._global.colorList.subscribe((data) => (this.colorList = data));
    this._global.locationData.subscribe((data) => (this.locationInfo = data));
    this._global.isAccAppliedTo3D.subscribe(
      (data) => (this.isAccAppliedTo3D = data)
    );
    this._global.socketStatus.subscribe((data) => (this.SOCKET_STATUS = data));
    this._global.audioStatus.subscribe((s) => (this.audioCallConnected = s));
    this.registerSubscriber();
    window.sendData({ eName: 'page_view', eCat: 'accessorize_page' });
    this._global.limitedEditionUI.subscribe((data) => (this.limitedEdition = data));
    this._global.limitedEditionData.subscribe((data) => (this.limitedEditionData = data));
    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      ONE3D.toggleDoors(100, 0);
    }
    if (this.headLampIsOpen) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this._global.updateSunRoofState(false);
      ONE3D.toggleSunroof(100, 0);
    }
    if (this.sunRoofIsOpen) {
      this._global.toggleMgLogo(!this.logoOn);
      ONE3D.toggleMgLogo()
    }
  }

  ngOnDestroy(): void {
    window['accRef'] = null;
    localStorage.removeItem(SELECTEDPACK);
    document.getElementById('featureImageFullScreen').style.display = 'none';
    ONE3D.exitAccessoriesView();
    this.resetAccCam();
    this._socket.emitAccCamReset();
    this.$selectedColorObserver.unsubscribe();
    this.$dataTransfer.unsubscribe();
    this.$accAddData.unsubscribe();
    this.$accRemoveData.unsubscribe();
  }
  onVariantLoadComplete() {
    ONE3D.changeColor(this.selectedColor.hex_code);
    this.applyAddedAcc();
  }
  kitLoadComplete() {
    console.log('kit successfully added');
    this._global.showCircleLoader(false);
    if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0) {
      //This will trigger only when pack is added, i.e when kit is added.
      this.selectedPackage.flag = "Remove";
      this.selectedPackage.checked = true;
      this._global.showLoading(false);
    }
  }

  async getAccList() {
    // this.isLoading=true;
    console.log('this.globalPinNO', this.globalPinNO);
    let variantId = this._data.getLocalVariant();
    let colorId = this._data.getLocalColor();
    this._global.showLoading(true);
    let apiData = {
      pin_no: this.globalPinNO,
      variant_id: variantId
        ? variantId
        : defaultCarDetails[this.loadedCar].variant_id,
      variant_feature_id: colorId
        ? colorId
        : defaultCarDetails[this.loadedCar].variant_feature_id, //  added one
      show: 2, //  need to confirm what is it for
      source: 'web',
    };
    try {
      let respData: any = await this._api.getAccessoriesList(apiData);
      // Object.assign(this.accResp,{});
      if (respData.status == 1) {

        this.accList = respData.response;
        this.accResp = respData.response;
        this.overAllMeshListCustom2 = [];
        this.overAllMeshListCustom0 = [];
        this.overAllMeshListCustom1 = [];

        if (this.accList?.packages) {
          this.accList?.packages?.forEach((packs: any) => {
            if (packs?.additionalPack?.length > 0) {
              packs?.additionalPack?.forEach(addPack => {
                if (addPack.allow_custom === 2) {
                  addPack.accessoriesList.forEach(acc => {
                    this.overAllMeshListCustom2.push(acc.mesh);
                  });
                }
                if (addPack.allow_custom === 1) {
                  addPack.accessoriesList.forEach(acc => {
                    this.overAllMeshListCustom1.push(acc.mesh);
                  });
                }
                if (addPack.allow_custom === 0) {
                  addPack.accessoriesList.forEach(acc => {
                    this.overAllMeshListCustom0.push(acc.mesh);
                  });
                }
              });
            }
          });
        }


        this._data.overAllMeshListCustom0 = this.overAllMeshListCustom0;
        this._data.overAllMeshListCustom1 = this.overAllMeshListCustom1;
        this._data.overAllMeshListCustom2 = this.overAllMeshListCustom2;


        if (this.accList?.packages) {
          this.accList?.packages?.forEach((packs: any) => {
            if (packs?.additionalPack?.length > 0) {
              const index = packs.additionalPack.findIndex(obj => obj.allow_custom === 2);
              if (index !== -1) {
                this.insertionIndexDummyObj = index;
                packs.additionalPack.splice(index, 0, dummyObj);
              }
            }
          });
        }

        // console.log("Check my list", this.overAllMeshListCustom2);

        // this.accList = resp.response;
        // this.accResp = resp.response;

        this.selectedPacks = [];

        //For Packages
        if (this.accList && this.accList.packages.length > 0) {
          this.accList?.packages?.forEach(element => {
            if (element?.additionalPack?.length > 0 && element.flag == "Remove") {
              element.additionalPack.forEach(additionalPack => {
                if (additionalPack.allow_custom === 0) {
                  this.processAdditionalPackFlag(additionalPack);
                }
                if (additionalPack.allow_custom === 1 && additionalPack?.accessoriesList?.length > 0) {
                  this.processGroupOptionsPackFlag(additionalPack.accessoriesList);
                }
                //Modify the && condition after verifying the API 
                if (additionalPack.allow_custom === 2 && additionalPack?.accessoriesList?.length > 0) {
                  this.processGroupOptionsKit(additionalPack);
                }
              });
            }
            if (element.flag == "Remove") {
              element.checked = true;
              this.selectedPacks.push(element);
            } else {
              element.checked = false;
            }
          });
        }
        // alert(1);
        console.log("The final acc list", this.accList);
        if (this._data.isLocalStorageItemSet(SELECTEDPACK)) {
          // this._data.setItem("selectedPacks",  JSON.stringify([...this.selectedPacks]))
          localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
        } else {
          // this._data.setItem("selectedPacks",  JSON.stringify([...this.selectedPacks]))
          localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
        }


        if (this.accList && this.accList.essential_subgroup?.length > 0) {
          this.accList.essential_subgroup.forEach(element => {
            if (element.flag == "Remove") {
              element.checked = true;
              this.selectedAcc.push(element);
            } else {
              element.checked = false;
            }
          });
        }

        if (this._data.isLocalStorageItemSet(SELECTEDACC)) {
          localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
          // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]))
        } else {
          localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
          // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]))
        }

        if (this.accList && this.accList.exterior_subgroup?.length > 0) {
          this.accList.exterior_subgroup.forEach(element => {
            if (element.flag == "Remove") {
              element.checked = true;
              this.selectedAcc.push(element);
            } else {
              element.checked = false;
            }
          });
        }

        if (this._data.isLocalStorageItemSet(SELECTEDACC)) {
          localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
          // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]))
        } else {
          localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
          // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]))
        }

        if (this.accList && this.accList.interior_subgroup?.length > 0) {
          this.accList.interior_subgroup.forEach(element => {
            if (element.flag == "Remove") {
              element.checked = true;
              this.selectedAcc.push(element);
            } else {
              element.checked = false;
            }
          });
        }
        if (this.accList && this.accList?.stickers_subgroup?.length > 0) {
          this.accList.stickers_subgroup.forEach(element => {
            if (element.flag == "Remove") {
              element.checked = true;
              this.selectedAcc.push(element);
            } else {
              element.checked = false;
            }
          });
        }

        if (this._data.isLocalStorageItemSet(SELECTEDACC)) {
          localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
          // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]))
        } else {
          localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
          // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]))
        }

        //Making sure, there are only unique ids present 
        this.selectedPacks = Array.from(new Set(this.selectedPacks.map(item => item.pack_id)))
          .map(id => this.selectedPacks.find(item => item.pack_id === id));

        this.selectedAcc = Array.from(new Set(this.selectedAcc.map(item => item.accessories_id)))
          .map(id => this.selectedAcc.find(item => item.accessories_id === id));

        localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
        localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
        // this._data.setItem("selectedAcc",  JSON.stringify([...this.selectedAcc]));
        // this._data.setItem("selectedPacks",  JSON.stringify([...this.selectedPacks]));

        console.log('acc this.accResp', this.accList);
        if (ONE3D.loadedPercent == 100 && !this.isAccAppliedTo3D) {
          this.applyAddedAcc();
        }
        // this.loadArrays(changeColor);
      }
      // this.isLoading=false;
      this._global.showLoading(false);
      console.log('acc list', this.selectedAccGroup);
      console.log('acc0 list', this.accList);
    } catch (error) {
      // this.isLoading=false;
      this._global.showLoading(false);
      console.log('Error in Acc', error);
    }
  }
  processGroupOptionsKit(groupOptionKit: any) {
    if (groupOptionKit.flag == "Remove") {
      groupOptionKit.checked = true;
    }
    groupOptionKit.accessoriesList.forEach(element => {
      if (element.flag == "Remove") {
        element.checked = true;
      } else if (element.flag == "Add") {
        element.checked = false;
        console.log("Setting it to false", element);
      }

    });
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 1000);
  }
  processAdditionalPackFlag(additionalPack: any) {
    const flag = additionalPack?.flag;
    if (flag === "Remove") {
      additionalPack.checked = true;
    } else if (flag === "Add") {
      additionalPack.checked = false;
    }
  }

  processGroupOptionsPackFlag(accessoriesList: any) {
    // console.log("The acc list", accessoriesList);
    accessoriesList.forEach(element => {
      if (element.flag == "Remove") {
        element.checked = true;
      } else if (element.flag == "Add") {
        element.checked = false;
      }
    });
  }


  applyAddedAcc() {
    if (ONE3D.loadedPercent == 100) {
      this._global.showCircleLoader(true);
      try {
        let selected = [];
        this.accList.accessories?.map((ele) => {
          if (ele.flag == 'Remove') selected.push(ele.mesh);
        });
        if (this.accList && this.accList?.packages?.length > 0) {
          this.accList?.packages?.map((ele) => {
            if (ele.flag == 'Remove') {
              if (ele?.accessoriesList?.length > 0) {
                let ary = ele.accessoriesList?.map(e => e.mesh);
                selected = [...selected, ...ary];
              }
            }
            if (ele?.additionalPack?.length > 0 && ele.flag == "Remove") {
              let result = this.processAdditionalAcc(ele);
              selected = [...selected, ...result];
            }
          });
        } else {
          console.log("Err, Package not found")
        }

        console.log("My final list", selected);
        if (selected.length > 0) {
          ONE3D.event.addMultiAccessories(selected, {
            callback: () => {
              // Logic
              console.log("Acc added");
              ONE3D.loadedPercent = 100;

              if (typeof window.getLoaderProgress === 'function') {
                window.getLoaderProgress(100);
              }
              this._global.showCircleLoader(false);
            }
          });
        } else {
          this._global.showCircleLoader(false);
        }
        if (this.loadedCar == 'kmodel' && !this.audioCallConnected) {
          this.applyBadgeAcc();
        }
      } catch (error) {
        // Handle the error or exception
        console.error("An error occurred:", error);
        this._global.showCircleLoader(false);
      }
    }
    this._global.updateAccApplied(true);
  }


  processAdditionalAcc(ele) {
    // Filter out objects with 'checked' property set to false and allow_custom === 0
    let filteredAdditionalPack = ele.additionalPack.filter(
      (item) => item.checked && item.allow_custom === 0
    );

    // Filter out objects with 'checked' property set to false and allow_custom === 2
    let filteredOptionsKit = ele.additionalPack.filter(
      (item) => item.flag == "Remove" && item.allow_custom === 2
    );

    // Filter out objects with 'checked' property set to false and allow_custom === 1
    let filteredOptionsPack = ele.additionalPack.filter(
      (item) => item.flag == "Remove" && item.allow_custom === 1
    );


    // Iterate over filteredOptionsPack array and filter accessoriesList
    let filteredAdditionalList = filteredAdditionalPack.flatMap((pack) => pack.accessoriesList).map((item) => item.mesh);


    // Iterate over filteredAdditionalPack array and filter accessoriesList
    let filteredOptionsList = filteredOptionsPack.flatMap((pack) =>
      pack.accessoriesList.filter((item) => item.flag === "Remove").map((item) => item.mesh)
    );

    let filteredGraphicOptionsList = filteredOptionsKit.flatMap((pack) =>
      pack.accessoriesList.filter((item) => item.flag === "Remove").map((item) => item.mesh)
    );

    // console.log("check this 460", filteredOptionsPack);
    // console.log("check this 4601", filteredOptionsList);
    let finalList = [...filteredOptionsList, ...filteredAdditionalList, ...filteredGraphicOptionsList];
    return finalList;

  }

  clearAllChecboxes(ele) {
    // Filter out objects with 'checked' property set to false and allow_custom === 0
    let filteredAdditionalPack = ele.additionalPack.filter(
      (item) => item.allow_custom === 0
    );

    // Filter out objects with 'checked' property set to false and allow_custom === 1
    let filteredOptionsPack = ele.additionalPack.filter(
      (item) => item.allow_custom === 1
    );

    // Filter out objects with 'checked' property set to false and allow_custom === 1
    let filteredOptionsKit = ele.additionalPack.filter(
      (item) => item.allow_custom === 2
    );


    // Iterate over filteredOptionsPack array and filter accessoriesList
    let filteredAdditionalList = filteredAdditionalPack.flatMap((pack) => pack.accessoriesList).map((item) => item.mesh);


    // Iterate over filteredAdditionalPack array and filter accessoriesList
    let filteredOptionsList = filteredOptionsPack.flatMap((pack) =>
      pack.accessoriesList.map((item) => item.mesh)
    );

    let filteredOptionsKitList = filteredOptionsKit.flatMap((pack) =>
      pack.accessoriesList.map((item) => item.mesh)
    );

    let finalList = [...filteredOptionsList, ...filteredAdditionalList, ...filteredOptionsKitList];
    return finalList;
  }

  applyBadgeAcc() {
    let accList = [];
    let findOne = this.accList.exterior_subgroup.find(
      (ele) => ele.accessories_id == 198
    );
    let findTwo = this.accList.exterior_subgroup.find(
      (ele) => ele.accessories_id == 204
    );
    console.log(findTwo);
    console.log(findOne);

    if (findOne && findOne.flag == 'Add') {
      // ONE3D.addAccessorie(findOne.mesh, findOne.tag);
      accList.push(findOne.mesh);
      this.updateAccessoryToApi(findOne);
    }
    if (findTwo && findTwo.flag == 'Add') {
      // ONE3D.addAccessorie(findTwo.mesh, findTwo.tag);
      accList.push(findTwo.mesh);
      this.updateAccessoryToApi(findTwo);
    }
    if (findOne.flag == 'Add' && findTwo.flag == 'Add') {
      ONE3D.addMultiAccessories(accList, {
        callback: () => {
          //Logic
        }
      });
    }
  }

  removeColorAcc() {
    for (const item in this.accList) {
      console.log('item >>>>', item);
      for (const iterator of this.accList[item]) {
        if (iterator.acces_type == 'color' && iterator.flag == 'Remove') {
          ONE3D.removeAccessories(iterator.mesh, iterator.tag);
        }
      }
    }
  }
  selectAccgrp(grp: string) {
    if (grp == this.selectedAccGroup) {
      this.selectedAccGroup = null;
    } else {
      this.selectedAccGroup = grp;
    }
    if (grp == '1') {
      this._ga.sendGAEvent('accessories', 'click', 'ext');
      window.sendLiveDashboardData({
        name: 'click',
        category: 'exterior_clicked',
        type: 'Accessorize',
      });
      this._api.saveBuildSummary(this.globalPinNO, 'ext_acc_view');
    } else if (grp == '2') {
      this._ga.sendGAEvent('accessories', 'click', 'int');
      window.sendLiveDashboardData({
        name: 'click',
        category: 'interior_clicked',
        type: 'Accessorize',
      });
      this._api.saveBuildSummary(this.globalPinNO, 'ext_acc_view');
    } else if (grp == '4') {
      this._ga.sendGAEvent('accessories', 'click', 'Comet LIT');
      this._api.saveBuildSummary(this.globalPinNO, 'ext_acc_view');

    } else {
      this._ga.sendGAEvent('accessories', 'click', 'ess_acc_view');
      window.sendLiveDashboardData({
        name: 'click',
        category: 'essential_clicked',
        type: 'Accessorize',
      });
    }
  }
  async accAddRemove(item) {
    if (this.accList.packages.length > 0) {
      if (this.limitedEdition) {
        let [overlappingAcc, overlappingAccGamer] = this.findOverlappingKeys(item, this.limitedEditionData);
        console.log(overlappingAcc, overlappingAccGamer);
        if (overlappingAcc && overlappingAcc.length > 0) {
          this.isOverlappingModal = true;
          this.overlappingAccWithGamer = true;
          this.selectedAccessory = item;
          return false;
        }
      }
      let isAccPresent = this.checkIfPresent(JSON.parse(localStorage.getItem("selectedAcc")) || [], "accessories_id", item);
      console.log("isAccPresent", isAccPresent);
      if (isAccPresent) {
        //Remove it from the API server as well.
        item.checked = false;
        // ONE3D.exitAccessoriesView();
        //Call only if selectedPack is true
        // if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0) {
        //   this.resetAccCam();
        // }
        console.log("Call me 347");
        await this.updateAccessoryToApi(item);
        this.selectedAcc = [...this.removeObjectByKey(this.selectedAcc, "accessories_id", item.accessories_id)];
        localStorage.setItem(SELECTEDACC, JSON.stringify(this.selectedAcc));
        item.checked = false;
        item.flag = "Add";
        if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0) {
          this.resetAccCam();
        }
        this.removeAcc(item);
        return false;
      }

      this.overlappingItems = this.isOverlapping(item, JSON.parse(localStorage.getItem(SELECTEDACC)) || [], JSON.parse(localStorage.getItem(SELECTEDPACK)) || [], "Acc");

      if (this.overlappingItems.length > 0) {
        //Show a prompt and handle the response of the user
        console.log("The overlapped items", this.overlappingItems);
        this.isOverlappingModal = true;
        // item.flag = "Add";
        this.selectedAccessory = item;
      } else {
        this.isOverlappingModal = false;
        // item.flag = "Remove";
        this.selectedAcc.push(item);
        this.selectedAccessory = {};
        try {
          document.getElementById('featureImageFullScreen').style.display = 'none';
          this._global.showLoading(true);
          //
          this.isAccPreview = true;
          this._global.updateAccPreview(this.isAccPreview);
          console.log('item', item);
          window.sendData({
            eName: 'click',
            eCat: 'accessorize_menu',
            eAct: 'accessory_selection',
            eval: item.marketing_name,
            eLa4: item.category,
            eLa5: '',
            eLa6: '',
          });

          if (item.type == 'image') {
            // document.getElementById('featureImageFullScreen').style.display = 'block';
            if (item.flag == 'Add') {
              if (window.innerWidth < 450) {
                console.log('mobile');

                this._global.updateOverlaySrc(
                  `${this.accUrl}full_screen/mobile/${item.image_url}`
                );
              } else {
                console.log('desktop');
                this._global.updateOverlaySrc(
                  `${this.accUrl}full_screen/${item.image_url}`
                );
              }
              setTimeout(() => {
                document.getElementById('featureImageFullScreen').style.display =
                  'block';
              }, 100);
              if (this.ACCESS) {
                this._socket.emitAccAdd(item);
              }
              this._global.updateAccCam(true);
            } else {
              if (this.ACCESS) {
                this._socket.emitAccRemove(item);
              }
              this._global.updateAccCam(false);
            }
            console.log("Call me 415");
            await this.updateAccessoryToApi(item);
            localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
            ONE3D.exitAccessoriesView();
          } else {
            ONE3D.setAccessorieCamera(item.hotspot_name, null, {
              callback: async () => {
                console.log('Camera set');
                // console.log(item);
                if (item.flag == 'Add') {
                  //This fn not to be called when adding pack
                  ONE3D.addAccessorie(item.mesh, item.tag, {
                    callback: () => {
                      console.log('ACC LOADED');
                      this._socket.socket.emit('dataTransfer', {
                        room: this._socket.room,
                        hideAccLoader: null,
                      });
                    },
                  });
                  //This fn to be called. 
                  if (this.ACCESS) {
                    this._socket.emitAccAdd(item);
                  }
                  this._global.updateAccCam(true);
                } else {
                  ONE3D.removeAccessories(item.mesh, item.tag);
                  this._socket.socket.emit('dataTransfer', {
                    room: this._socket.room,
                    hideAccLoader: null,
                  });
                  if (this.ACCESS) {
                    this._socket.emitAccRemove(item);
                  }
                  this._global.updateAccCam(true);
                }
                console.log("Call me 450");
                await this.updateAccessoryToApi(item);
                localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
              },
            });
          }

        } catch (error) {
          // this.isLoading=false;
          this._global.showLoading(false);
          console.log('Error in Acc', error);
        }
        localStorage.setItem(SELECTEDACC, JSON.stringify([...this.selectedAcc]));
        console.log("My added accs 543", this.selectedAcc);
      }
    } else {
      //Original code
      try {
        document.getElementById('featureImageFullScreen').style.display = 'none';
        this._global.showLoading(true);
        //
        this.isAccPreview = true;
        this._global.updateAccPreview(this.isAccPreview);
        console.log('item', item);
        window.sendData({
          eName: 'click',
          eCat: 'accessorize_menu',
          eAct: 'accessory_selection',
          eval: item.marketing_name,
          eLa4: item.category,
          eLa5: '',
          eLa6: '',
        });

        if (item.type == 'image') {
          // document.getElementById('featureImageFullScreen').style.display = 'block';
          if (item.flag == 'Add') {
            if (window.innerWidth < 450) {
              console.log('mobile');

              this._global.updateOverlaySrc(
                `${this.accUrl}full_screen/mobile/${item.image_url}`
              );
            } else {
              console.log('desktop');
              this._global.updateOverlaySrc(
                `${this.accUrl}full_screen/${item.image_url}`
              );
            }
            setTimeout(() => {
              document.getElementById('featureImageFullScreen').style.display =
                'block';
            }, 100);
            if (this.ACCESS) {
              this._socket.emitAccAdd(item);
            }
            this._global.updateAccCam(true);
          } else {
            if (this.ACCESS) {
              this._socket.emitAccRemove(item);
            }
            this._global.updateAccCam(false);
          }
          this.updateAccessoryToApi(item);
          ONE3D.exitAccessoriesView();
        } else {
          ONE3D.setAccessorieCamera(item.hotspot_name, null, {
            callback: () => {
              console.log('Camera set', item);
              if (item.flag == 'Add') {
                //This fn not to be called when adding pack
                ONE3D.addAccessorie(item.mesh, item.tag, {
                  callback: () => {
                    console.log('ACC LOADED');
                    this._socket.socket.emit('dataTransfer', {
                      room: this._socket.room,
                      hideAccLoader: null,
                    });
                  },
                });
                //This fn to be called. 
                if (this.ACCESS) {
                  this._socket.emitAccAdd(item);
                }
                this._global.updateAccCam(true);
              } else {
                ONE3D.removeAccessories(item.mesh, item.tag);
                this._socket.socket.emit('dataTransfer', {
                  room: this._socket.room,
                  hideAccLoader: null,
                });
                if (this.ACCESS) {
                  this._socket.emitAccRemove(item);
                }
                this._global.updateAccCam(true);
              }
              this.updateAccessoryToApi(item);
            },
          });
          // setTimeout(() => {
          //   console.log("The final acc list", JSON.stringify(this.selectedAcc));
          // }, 5000);

        }

      } catch (error) {
        // this.isLoading=false;
        this._global.showLoading(false);
        console.log('Error in Acc', error);
      }
    }
  }


  updateAccessoryToApi(item, isPack?) {
    return new Promise(async (resolve, reject) => {
      try {
        let val = isPack
          ? await this.getFlagById("pack_id", item.pack_id, JSON.parse(localStorage.getItem("selectedPacks")) || [])
          : await this.getFlagById("accessories_id", item.accessories_id, JSON.parse(localStorage.getItem("selectedAcc")) || []);

        console.log("After Match", val);

        let apiData = {
          pin_no: this.globalPinNO,
          variant_id: this.selectedVariant.variant_id,
          access_id: isPack ? item.pack_id : item.accessories_id,
          subgroup_id: isPack ? "0" : item.subgroup_id,
          change_variant: "",
          flag: val != null && val !== undefined ? val : "Add",
          price: isPack ? item.pack_price : item.price,
          type: "Addons",
          qty: 1,
          is_multiple: item.is_multiple,
          source: "web",
          acces_type: isPack ? "" : item.acces_type,
          is_pack: isPack ? "yes" : null,
          remove_pack: (val === "Remove" && isPack) ? "Yes" : ""
        };

        let respData: any = await this._api.addRemoveAccessories(apiData);
        if (respData.status == 1) {
          this._global.updatedPrice(respData.response.summary.showroom_price);
          this._ga.sendGAEvent(`${item.category} access`, "click", item.marketing_name);

          if (item.flag == "Add") {
            item.flag = "Remove";
          } else {
            item.flag = "Add";
          }
        }
        resolve(respData);
      } catch (error) {
        this._global.showLoading(false);
        console.log("Error in Acc", error);
        reject(error);
      }
    });
  }


  updateAccData(accAry) {
    for (const i of this.accList) {
      let ind = accAry.findIndex((e) => e.accessories_id == i.accessories_id);
      console.log('ind', ind);
      i.flag = accAry[ind].flag;
    }
    // let newList = this.accList.filter(e => e.flag == 'Remove');
    // console.log("accAryaccAry",newList);
    // const results = addedAcc.filter(({ accessories_id: id1 }) => !newList.some(({ accessories_id: id2 }) => id2 === id1));
    // for (const item of results) {
    //   SceneManager.scenemanager.accManager.RemoveAccessories(item.tag, item.mesh)
    // }
  }

  gotoAccList(e, item) {
    e.stopPropagation();
    console.log(item);
    if (item.accessories_count != 1) {
      let url = defaultCarDetails[this.loadedCar].urlScheme;
      this.router.navigate([`${url}/acc-details`], {
        queryParams: {
          subgroup_id: item.subgroup_id,
          category: item.category,
          variant_feature_id: this.selectedColor.variant_feature_id,
        },
      });
    } else {
      // SceneManager.scenemanager.accManager.setAccCam(item.hotspot_name, false);
    }
  }

  registerSubscriber() {
    this.$accAddData = this._event.subscribe('acc_add_data', (data) => {
      console.log('acc_add_data', data);
      this.accAddRemove(data.acc);
    });
    this.$accRemoveData = this._event.subscribe('acc_remove_data', (data) => {
      console.log('acc_remove_data', data);
      this.accAddRemove(data.acc);
    });

    this.$accAddData.add(this._event.subscribe('kit_add_data', (data) => {

      if (data.kit.pack_mesh_code == "GAMER_EDITION") {
        this._global.updateLimitedEditionUI(true);
        this._global.updateLimitedEdition3D(true);
        return false
      }
      let pack = data.kit;
      console.log('kit_add_data', pack);
      this.packAddRemove(pack);
    })

    );

    this.$accRemoveData.add(this._event.subscribe('kit_remove_data', (data) => {
      if (data.kit.pack_mesh_code == "GAMER_EDITION") {
        this._global.updateLimitedEditionUI(false);
        this._global.updateLimitedEdition3D(false);
        return false
      }
      let pack = data.kit;
      console.log('kit_remove_data', pack);
      this.packAddRemove(data.kit);
    })
    );

    this.$accAddData.add(this._event.subscribe('add_additional_kit_data', (data) => {
      let additionalPack = JSON.stringify(data.kit);
      console.log('add_additional_kit_data', additionalPack);
      this.additionalPackAddRem(JSON.parse(additionalPack)[1], JSON.parse(additionalPack)[0]);
    }));

    this.$accRemoveData.add(this._event.subscribe('remove_additional_kit_data', (data) => {
      let additionalPack = JSON.stringify(data.kit);
      console.log('remove_additional_kit_data', additionalPack);
      this.additionalPackAddRem(JSON.parse(additionalPack)[1], JSON.parse(additionalPack)[0]);
    }));

    this.$accAddData.add(this._event.subscribe('add_optional_kit_data', (data) => {
      let optionsPack = JSON.stringify(data.kit);
      console.log('add_optional_kit_data', optionsPack);
      this.addRemPackOptions(JSON.parse(optionsPack)[2], JSON.parse(optionsPack)[1], JSON.parse(optionsPack)[0]);
    }));

    this.$accRemoveData.add(this._event.subscribe('remove_optional_kit_data', (data) => {
      let optionsPack = JSON.stringify(data.kit);
      console.log('remove_options_kit_data', optionsPack);
      this.clearSelectionEvent(JSON.parse(optionsPack)[1], true, JSON.parse(optionsPack)[0])
    }));

    //Here 

    this.$accAddData.add(this._event.subscribe('add_graphics_kit_data', (data) => {
      let optionsPack = JSON.stringify(data.kit);
      console.log('add_graphics_kit_data', optionsPack);
      this.addRemOptKit(JSON.parse(optionsPack)[0], JSON.parse(optionsPack)[1], false);
    }));

    this.$accRemoveData.add(this._event.subscribe('remove_graphics_kit_data', (data) => {
      let optionsPack = JSON.stringify(data.kit);
      console.log('remove_graphics_kit_data', optionsPack);
      this.removeOptionKit(JSON.parse(optionsPack)[0], JSON.parse(optionsPack)[1]);
      this.apply3Dview(JSON.parse(optionsPack)[0], false);
    }));

    this.$accAddData.add(this._event.subscribe('add_graphic_option_data', (data) => {
      let optionsPack = JSON.stringify(data.kit);
      console.log('add_graphic_option_data', optionsPack);
      this.addRemGroupKits(JSON.parse(optionsPack)[0], JSON.parse(optionsPack)[1], JSON.parse(optionsPack)[2], true);
    }));


    this.$accAddData.add(this._event.subscribe('remove_graphic_option_data', (data) => {
      let optionsPack = JSON.stringify(data.kit);
      console.log('remove_graphic_option_data', optionsPack);
      this.addRemGroupKits(JSON.parse(optionsPack)[0], JSON.parse(optionsPack)[1], JSON.parse(optionsPack)[2], true);
    }));




    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      console.log('dataT acc list', data);
      this.accessorySocketSubs(data);
    });
  }
  accessorySocketSubs(data) {
    console.log('dataTransfer', data);
    let keys = Object.keys(data);
    let key = keys[0];
    switch (key) {
      case 'resetAccCam':
        this.resetAccCam();
        break;
      case 'setAccCam':
        let item = data[key];
        this.onCarView(item);
        break;
      case 'removeAllAccessories':
        ONE3D.removeAllAccessories();
        break;
      default:
        break;
    }
  }

  getStateValue() {
    return this.locationInfo.hasOwnProperty('city')
      ? this.locationInfo.city
      : 'Delhi';
  }
  /**
   * goto summary page on click of CTA Summary
   */
  gotoSummaryPage() {
    window.sendLiveDashboardData({
      name: 'Summary',
      category: 'RHS',
      type: 'Menu_RHS',
    });
    this._api.saveBuildSummary(this.globalPinNO, 'proceed_click');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/summary`]);
    // this.router.navigate(['/hector/summary']);
  }
  /*
   *Location modal toggle in global state
   */
  openLocModal() {
    window.sendData({
      eName: 'click',
      eCat: 'accessorize_menu',
      eAct: 'location_button_clicked',
    });
    this._global.toggleLocModal(true);
  }
  goBack() {
    window.sendData({
      eName: 'click',
      eCat: 'accessorize_menu',
      eAct: 'back_button_clicked',
    });
    if (!this.limitedEdition && this.backRoutingUrl == 'limited-edition-accessories') {
      this._global.updateLimitedEditionUI(true);
      this._global.updateLimitedEdition3D(true);
    }
    // this.location.back();
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/${this.backRoutingUrl}`]);
    if (this.ACCESS) {
      this._socket.emitGoBackToVariant();
    }
  }

  toggleConfirmModal() {
    this.backRoutingUrl = 'home';
    this.isConfirmModalOpen = true;
  }

  onModalDismiss(action: string) {
    console.log('action', action);

    this.isConfirmModalOpen = false;

    if (action == 'continue') {
      this._global.updateAccApplied(false);
      let selected = [];
      console.log('', this.accList);
      this.accList.exterior_subgroup?.map((ele) => {
        if (ele.flag == 'Remove') selected.push(ele.mesh);
      });
      this.accList?.interior_subgroup?.map((ele) => {
        if (ele.flag == 'Remove') selected.push(ele.mesh);
      });
      this.accList?.essential_subgroup?.map((ele) => {
        if (ele.flag == 'Remove') selected.push(ele.mesh);
      });
      this.accList?.stickers_subgroup?.map((ele) => {
        if (ele.flag == 'Remove') selected.push(ele.mesh);
      });
      this.accList?.accessories?.map((ele) => {
        if (ele.flag == 'Remove') selected.push(ele.mesh);
      });
      if (this.accList?.packages && this.accList.packages?.length > 0) {
        this.accList.packages?.map((ele) => {
          if (ele.flag == 'Remove') {
            let ary = ele.accessoriesList.map(e => e.mesh);
            selected = [...selected, ...ary];
          }
        });
      }
      selected = [...selected, ...this.overAllMeshListCustom0, ...this.overAllMeshListCustom1, ...this.overAllMeshListCustom2]
      // console.log('selected', selected);
      // console.log('selected', this.overAllMeshListCustom1);
      // console.log('selected', this.overAllMeshListCustom2);
      ONE3D.removeAllAccessories(selected);
      this.goBack();
    }
  }

  resetAccCam() {
    ONE3D.exitAccessoriesView();
    // this._global.updateAccPreview(false);
    this._global.updateAccCam(false);
    this._global.updateOverlaySrc('');
    document.getElementById('featureImageFullScreen').style.display = 'none';
  }

  async packAddRemove(pack) {
    console.log("pack add remove", pack);

    let isPackPresent = this.checkIfPresent(JSON.parse(localStorage.getItem(SELECTEDPACK)) || [], "pack_id", pack);
    console.log("ispresent", isPackPresent, JSON.parse(localStorage.getItem(SELECTEDPACK)));

    if (this.limitedEdition) {
      this.isOverlappingModal = true;
      this.selectedPackage = pack;
      return false;
    }

    if (isPackPresent) {

      await this.updateAccessoryToApi(pack, true);
      this.selectedPacks = [...this.removeObjectByKey(this.selectedPacks, "pack_id", pack.pack_id)];
      pack.checked = false;
      pack.flag = "Add";
      this.resetAdditionalPackChecks(pack);
      // console.log("getting set 1488", this.selectedPacks);
      localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
      // alert(1488)
      ONE3D.exitAccessoriesView();
      ONE3D.removeKit(pack.pack_mesh_code);
      // this._global.showCircleLoader(true);
      if (pack?.additionalPack?.length > 0) {
        // console.log("The pack is", pack);
        let result = this.clearAllChecboxes(pack);
        let selected: any = [];
        selected = [...selected, ...result];
        // console.log("List being removed", selected);
        if (selected.length > 0) {
          ONE3D.removeAllAccessories(selected, {
            callback: () => {
              // Logic
              // this._global.showCircleLoader(false);
            }
          });
        } else {
          // this._global.showCircleLoader(false);
        }
      }
      return false;
    }

    this.overlappingItems = this.isOverlapping(pack, JSON.parse(localStorage.getItem(SELECTEDACC)) || [], JSON.parse(localStorage.getItem(SELECTEDPACK)) || [], "Pack");
    console.log('overlapping', this.overlappingItems);

    if (this.overlappingItems.length > 0) {
      //Show a prompt and handle the response of the user
      console.log("The overlapped items", this.overlappingItems);
      this.isOverlappingModal = true;
      this.selectedPackage = pack;
    } else {
      try {
        //API call. 
        this._global.showCircleLoader(true);
        let response: any = await this.updateAccessoryToApi(pack, true);
        if (pack.additionalPack && pack.additionalPack.length > 0) {
          // if (response.status == 1) {
          //   if (pack.flag == "Add") {
          //     pack.flag = "Remove";
          //     pack.checked=true
          //   } else {
          //     pack.flag = "Add";
          //     pack.checked=false
          //   }
          // }
          console.log("pack sending", pack);
          this.selectedPacks.push(pack);
        } else {
          this.selectedPacks.push(pack);
        }
        // alert(pack.flag);
        console.log("getting set 1511", this.selectedPacks, response);
        localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
        setTimeout(() => {
          let arr = JSON.parse(localStorage.getItem("selectedPacks"));
          this.accList?.packages?.forEach(element => {
            arr.forEach(arr => {
              if (element.pack_id != arr.pack_id) {
                // arr.checked = false;
                // arr.flag = "Add";
                element.checked = false;
                element.flag = "Add";
              }
            });
          });
        }, 700);
        //This UI code is moved to call back kitLoadComplete();
        // pack.flag = "Remove";
        // pack.checked = true;
        this.selectedPackage = {};
        ONE3D.exitAccessoriesView();
        ONE3D.addKit(pack.pack_mesh_code);
      } catch (error) {
        this._global.showCircleLoader(true);
      }
    }
  }

  resetAdditionalPackChecks(pack) {
    // alert("called")
    if (this.accList.packages.length > 0) {
      this.accList?.packages?.forEach(element => {
        if (element.additionalPack && element.additionalPack.length > 0) {
          element.additionalPack.forEach((pack) => {

            if (pack.allow_custom === 1) {
              pack.accessoriesList?.forEach((accessory) => {
                accessory.checked = false;
                accessory.flag = "Add";
              });
            }
            if (pack.allow_custom === 0) {
              pack.checked = false;
              pack.flag = "Add";
            }
            //Modify accordingly
            if (pack.allow_custom === 2) {
              pack.checked = false;
              pack.flag = "Add";
              pack.accessoriesList.forEach(element => {
                element.checked = false;
                element.flag = "Add";
              });
            }
          });
        }
        if (pack.pack_id == element.pack_id) {
          element.checked = false;
          element.flag = "Add";
        }
      });
    }
  }


  //item.checked=false;
  // item.flag="Add";
  removeObjectByKey(arr, key, value) {

    if (arr && key && value) {
      return arr.filter(item => {
        if (item[key] === value) {
          item.checked = false;
          item.flag = "Add";
        }
        return item[key] !== value;
      });
    }
  }

  // Function to check if an item overlaps with the selected accessories or packages
  isOverlapping(item, selectedAccessories, selectedPackages, type) {
    // console.log("The values 1061", item, selectedPackages, type);
    // const { subgroup_id } = item;
    let subgroupIds = [];
    let explicitSubgroupIds = [];
    this.overlappingWith = "";

    if (type === "Pack" || type === "AdditionalPack" || type === "OptionsKit") {
      subgroupIds = item?.accessoriesList?.map(item => item.subgroup_id) || [];
      explicitSubgroupIds = item?.accessoriesList?.map(item => item.explicit_access) || [];
    } else if (type === "Acc" || type === "OptionsPack") {
      if (item && item.subgroup_id) {
        subgroupIds.push(item.subgroup_id);
        explicitSubgroupIds.push(item.explicit_access);
      }
    }

    subgroupIds = [...new Set(subgroupIds)];
    console.log("My subgroup ids are", subgroupIds);

    if (subgroupIds?.length == 0) {
      return false;
    }

    let overlapping = false;
    let overlappingItems: any = [];

    // Check if subgroup_id overlaps with selected accessories
    for (const subgroup_id of subgroupIds) {
      const existingAccessoryIndex = selectedAccessories.findIndex(a => a.subgroup_id === subgroup_id);
      if (existingAccessoryIndex >= 0) {
        const overlappingAcc = selectedAccessories[existingAccessoryIndex];
        // console.log("Pushing 1103", overlappingAcc);
        overlappingItems.push(overlappingAcc);
        overlapping = true;
        this.overlappingWith = "Acc";
      }
    }

    for (const explicit_id of explicitSubgroupIds) {
      let explicitArray = explicit_id.split(",").map(Number);
      const existingAccessoriesArray = selectedAccessories.filter(a => explicitArray.includes(a.subgroup_id));
      if (existingAccessoriesArray.length > 0) {
        overlappingItems = [...overlappingItems, ...existingAccessoriesArray];
        overlapping = true;
        this.overlappingWith = "Acc";
      }
    }


    // Check if subgroup_id overlaps with selected packages
    if (selectedPackages.length > 0) {
      let filteredAdditionalPack: any = [];
      let filteredOptionsPack: any = [];
      let filteredOptionsKit: any = [];

      //New code - 
      if (selectedPackages && selectedPackages?.length > 0) {
        selectedPackages.forEach(selectedPackage => {
          if (!(selectedPackage?.additionalPack?.length > 0)) {
            return false;
          }

          // // Filter out objects with 'checked' property set to false and allow_custom === 0
          // filteredAdditionalPack = selectedPackage.additionalPack.filter(
          //   (item) => item.checked && item.allow_custom === 0
          // );
          // // Filter out objects with 'checked' property set to false and allow_custom === 1
          // filteredOptionsPack = selectedPackage.additionalPack.filter(
          //   (item) => item.flag == "Remove" && item.allow_custom === 1
          // );


          // // Filter out objects with 'checked' property set to false and allow_custom === 2
          // filteredOptionsKit = selectedPackage.additionalPack.filter(
          //   (item) => item.flag == "Remove" && item.allow_custom === 2
          // );

          filteredAdditionalPack = this.filterAdditionalPack(selectedPackage, true, 0);
          filteredOptionsPack = this.filterAdditionalPack(selectedPackage, false, 1, item);
          filteredOptionsKit = this.filterAdditionalPack(selectedPackage, false, 2, item);

          console.log("The values", filteredAdditionalPack, filteredOptionsPack, filteredOptionsKit, subgroupIds);
        });
      }

      const arraysToCheck = [selectedPackages, filteredAdditionalPack, filteredOptionsPack, filteredOptionsKit]; // Add more arrays as needed


      //New Code 
      for (const subgroup_id of subgroupIds) {
        const existingPackageIndex = this.findIndexInArrays(arraysToCheck, p => p.accessoriesList?.some(a => a.subgroup_id === subgroup_id));
        if (existingPackageIndex >= 0) {
          overlapping = true;
          const overlappingPackage = arraysToCheck.find((_, index) => index === existingPackageIndex)[existingPackageIndex];
          if (overlappingPackage && overlappingPackage.accessoriesList) {
            overlappingPackage.accessoriesList = overlappingPackage?.accessoriesList?.filter(a => a.subgroup_id === subgroup_id);
            overlappingItems.push(overlappingPackage);
            // console.log("Pushing", overlappingPackage, overlappingItems);
            this.overlappingWith = "Pack";
          }
        }
      }

      //New code
      for (const explicit_id of explicitSubgroupIds) {
        let explicitArray = explicit_id.split(",").map(Number);
        const existingAccessoriesArray = this.findArrayInArrays(arraysToCheck, p => p.accessoriesList?.some(a => explicitArray.includes(a.subgroup_id)));
        if (existingAccessoriesArray) {
          overlapping = true;
          overlappingItems = [...overlappingItems, ...existingAccessoriesArray];
          this.overlappingWith = "Pack";
        }
      }

    }

    return overlappingItems;
  }


  onOverlapModalDismiss(action: string) {
    console.log("Action and overlapping and overlappingAccWithGamer", action, this.overlappingWith, this.overlappingAccWithGamer, this.limitedEdition)
    //First two if else conditions are to check 
    //gamer addition is enabled or not
    if (action == 'continue' && this.overlappingAccWithGamer) {
      this.isOverlappingModal = false;
      this._global.updateLimitedEditionUI(false);
      this._global.updateLimitedEdition3D(false);
      this.accAddRemove(this.selectedAccessory);
      this.overlappingAccWithGamer = false;
    } else if (action == 'continue' && this.limitedEdition) {
      this._global.updateLimitedEditionUI(false);
      this._global.updateLimitedEdition3D(false);
      this.isOverlappingModal = false;
      if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0) {
        this.packAddRemove(this.selectedPackage);
      }
    } else {
      if (action == 'continue' && this.overlappingWith == "Pack") {
        //Remove the overlapping item from  selected packs
        this.selectedPacks = [...this.removeObjectByKey(this.selectedPacks, "pack_id", this.overlappingItems[0].pack_id)];
        //Since we are changing the selectedPacks, we need to update the same to the server
        this.packAddRemove(this.overlappingItems[0]);
        console.log("getting set 1651", this.selectedPacks);
        localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]))
        this.overlappingItems = this.removeObjectByKey(this.overlappingItems, "pack_id", this.overlappingItems[0].pack_id);
        this.toggleOptions();
        this.isOverlappingModal = false;
      } else if (action == 'continue' && this.overlappingWith == "Acc") {
        //Remove the overlapping item from  selected Acc
        if (this.overlappingItems && this.overlappingItems.length > 0) {
          this.overlappingItems.forEach(element => {
            this.selectedAcc = [...this.removeObjectByKey(this.selectedAcc, "accessories_id", element.accessories_id)];
            //You also need to remove every element that is overlapping.
            this.accAddRemove(element)
          });
        }
        console.log("My added Acc 604", this.selectedAcc);
        localStorage.setItem(SELECTEDACC, JSON.stringify(this.selectedAcc))
        //Here you are removing only the last element of the overlapping Arr
        //But if the user presses continue, you should empty the entire overlapping arr.
        // this.overlappingItems = this.removeObjectByKey(this.overlappingItems, "accessories_id", this.overlappingItems[0].accessories_id);

        //Here with selectedPackage or selectedAcc, you're adding "to be added items" after a user
        //presses continue button. 
        this.overlappingItems = [];
        this.toggleOptions();
        this.isOverlappingModal = false;
      } else {
        // settime out required as its not updating the Html 
        setTimeout(() => {
          if (typeof this.selectedGroupOptionKit === 'object' && Object.keys(this.selectedGroupOptionKit).length !== 0) {
            this.selectedGroupOptionKit.checked = false
            this.selectedGroupOptionKit.flag = "Add";
          } else
            if (typeof this.selectedAdditionalPackage === 'object' && Object.keys(this.selectedAdditionalPackage).length !== 0) {
              this.selectedAdditionalPackage.checked = false
              this.selectedAdditionalPackage.flag = "Add";
            } else if (typeof this.selectedAddonAccessory === 'object' && Object.keys(this.selectedAddonAccessory).length !== 0) {
              this.selectedAddonAccessory.checked = false
              this.selectedAddonAccessory.flag = "Add";
            } else if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0) {
              this.selectedPackage.checked = false
              this.selectedPackage.flag = "Add";
            } else if (typeof this.selectedAccessory === 'object' && Object.keys(this.selectedAccessory).length !== 0) {
              this.selectedAccessory.checked = false
              this.selectedAccessory.flag = "Add";
            }
          this.isOverlappingModal = false;
        }, 50);
      }
    }
  }
  toggleOptions() {
    if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0 && typeof this.selectedGroupOptionKit === 'object' && Object.keys(this.selectedGroupOptionKit).length !== 0) {
      this.addRemOptKit(this.selectedGroupOptionKit, this.selectedPackage)
    } else
      if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0 && typeof this.selectedAdditionalPackage === 'object' && Object.keys(this.selectedAdditionalPackage).length !== 0) {
        this.additionalPackAddRem(this.selectedAdditionalPackage, this.selectedPackage)
      } else if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0 && typeof this.selectedAddonAccessory === 'object' && Object.keys(this.selectedAddonAccessory).length !== 0) {
        this.addRemPackOptions(this.selectedAddonAccessory, null, this.selectedPackage)
      } else if (typeof this.selectedPackage === 'object' && Object.keys(this.selectedPackage).length !== 0) {
        this.packAddRemove(this.selectedPackage);
      } else if (typeof this.selectedAccessory === 'object' && Object.keys(this.selectedAccessory).length !== 0) {
        this.accAddRemove(this.selectedAccessory);
      }

  }

  viewDetails(object) {
    this.packageDetails = null
    this.packageDetails = object;
    this.viewDetailsShown = true;
    // console.log(object);

  }

  toggleAdditionalAccordion() {
    const divElementPack = document.getElementById('additional-pack');
    // console.log("div", divElementPack)
    if (divElementPack) {
      divElementPack.classList.toggle('active-dropdown');
    }
  }

  toggleOptionsAccordion(packId: string) {
    const divElementOptions = document.getElementById('additional-options-' + packId);
    // console.log("div", divElementOptions)
    if (divElementOptions) {
      divElementOptions.classList.toggle('active-dropdown');
    }
  }

  toggleOptionsKitAccordion(additionalPack) {
    // alert("Remove?1")
    const divElementOptions = document.getElementById('additional-options-kit-' + additionalPack.pack_id);
    // console.log("div", divElementOptions)
    if (divElementOptions) {
      divElementOptions.classList.toggle('active-dropdown');
      //Check 
      if (additionalPack.flag == "Remove") {
        // alert("Remove?")
        // this.zone.run(() => {
        // Code to be executed within the Angular zone
        // console.log("Toggle", additionalPack);
        this.expandNestedAcc(additionalPack);
        // });
      }
    }
  }

  hideDetails() {
    this.viewDetailsShown = false;
    this.packageDetails = null;
  }
  gotoLimitedEdition() {
    this._ga.sendGAEvent("Accessories", "click", "View limited edition")
    this.backRoutingUrl = 'limited-edition-accessories';
    this.isConfirmModalOpen = true;
  }

  checkIfPresent(arr, key, object) {
    if (!arr || !Array.isArray(arr) || !key || !object) {
      return false; // handle null, empty, and key not present situations
    }
    return arr.some(item => item[key] === object[key]); // return true if key is present in any object of the array
  }


  findOverlappingKeys(obj, arr) {
    // const objSubgroupIds = obj ? Object.values(obj).filter(val => typeof val === 'object').map((val:any) => val.subgroup_id) : [];
    // const arrSubgroupIds = arr ? arr.reduce((acc, val) => [...acc, ...val.accessoriesList.map(a => a.subgroup_id)], []) : [];

    // const overlappingAcc = objSubgroupIds.filter(id => arrSubgroupIds.includes(id));
    // const overlappingAccGamer = arrSubgroupIds.filter(id => objSubgroupIds.includes(id));

    // return [overlappingAcc, overlappingAccGamer];

    const overlappingAcc = [];
    const overlappingAccGamer = [];

    if (!obj || Object.keys(obj).length === 0 || !arr || arr.length === 0) {
      return [overlappingAcc, overlappingAccGamer];
    }

    const objArr = Array.isArray(obj) ? obj : [obj];
    const objSubgroupIds = objArr.map((item) => item.subgroup_id);

    arr.forEach((pack) => {
      pack.accessoriesList.forEach((accessory) => {
        if (objSubgroupIds.includes(accessory.subgroup_id)) {
          overlappingAcc.push(accessory);
          overlappingAccGamer.push({
            pack_id: pack.pack_id,
            pack_name: pack.pack_name,
            accessories_id: accessory.accessories_id,
            subgroup_id: accessory.subgroup_id,
          });
        }
      });
    });

    return [overlappingAcc, overlappingAccGamer];
  }

  onCarView(item) {
    this._ga.sendGAEvent("Colour Lit", "click", item.marketing_name);
    ONE3D.setAccessorieCamera(item.hotspot_name, null, {
      callback: () => {
        // console.log('Camera set');
        // console.log(item);
        if (this.ACCESS) {
          this._socket.emitAccAdd(item);
        }
        this._global.updateAccCam(true);
      },
    });
  }

  async getFlagById(key, value, arrayList) {
    return new Promise((resolve, reject) => {
      let flag = 0;
      for (let i = 0; i < arrayList.length; i++) {
        if (arrayList[i][key] == value) {
          flag = 1;
          console.log("Matched", arrayList[i].flag, arrayList[i]);
          resolve(arrayList[i].flag);
        }
      }
      if (flag == 0) {
        resolve(null);
      }
    });
  }

  removeAcc(item) {
    // alert(1)
    ONE3D.removeAccessories(item.mesh, item.tag);
    this._socket.socket.emit('dataTransfer', {
      room: this._socket.room,
      hideAccLoader: null,
    });
    if (this.ACCESS) {
      this._socket.emitAccRemove(item);
    }
    // this._global.updateAccCam(true);
  }

  //OG code
  async additionalPackAddRem(additionalPack, pack) {
    console.log("Additional pack add remove", additionalPack, pack);
    //If false, add ->
    if (additionalPack.flag == "Add") {
      this.overlappingItems = this.isOverlapping(additionalPack, JSON.parse(localStorage.getItem("selectedAcc")) || [], JSON.parse(localStorage.getItem("selectedPacks")) || [], "AdditionalPack");

      if (this.overlappingItems.length > 0) {
        this.selectedAdditionalPackage = additionalPack;
        this.selectedPackage = pack;
        this.isOverlappingModal = true;
        setTimeout(() => {
          additionalPack.checked = false;
          additionalPack.flag = "Remove";
        }, 100);
        console.log("The overlapped items", this.overlappingItems);
        return false;
      }

      this._global.showCircleLoader(true);

      let res: any = await this.updateAdditionalPackAPI(additionalPack, pack, 0);
      if (res.status != 1) {
        this._global.showCircleLoader(false);
        return false;
      }

      res?.response?.packages?.forEach(packages => {
        if (additionalPack.allow_custom === 0 && packages.pack_id == additionalPack.parent_pack_id) {
          // console.log("Matched 2",additionalPack, packages );
          if (packages?.additionalPack?.length > 0) {
            packages.additionalPack.forEach(element => {
              if (element.allow_custom === 0 && element.pack_id == additionalPack.pack_id) {
                // console.log("Matched", additionalPack, element);
                //Make the values dynamic from API 
                additionalPack.flag = element.flag;
                additionalPack.checked = element.flag == "Remove" ? true : false;
              }
            });
          }
        }
      });

      // Retrieve the data from localStorage
      this.selectedPacks = JSON.parse(localStorage.getItem('selectedPacks'));
      // Find the pack object with pack_id = 1
      let packObj = this.selectedPacks.find((item) => item.pack_id === pack.pack_id);
      if (packObj) {
        // Find the additionalPack object with allow_custom === 0
        let additionalPackObj = packObj.additionalPack.find((item) => item.allow_custom === 0);
        if (additionalPackObj) {
          // Modify the checked property
          // Make it dynamic 
          additionalPackObj.checked = additionalPack.checked;
          additionalPackObj.flag = additionalPack.flag;
          console.log("additionalPackObj", additionalPackObj)
        }
      }
      // Save the updated data back to localStorage;
      this.selectedPackage = {};
      this.selectedAdditionalPackage = {};
      console.log("getting set 1825", this.selectedPacks);
      localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
      // ONE3D.addKit(additionalPack.pack_mesh_code);
      // this._global.showCircleLoader(true);
      let selected = [];
      additionalPack.accessoriesList.map((ele) => {
        selected.push(ele.mesh);
      });
      if (selected.length > 0) {
        // console.log(JSON.stringify(selected));

        ONE3D.exitAccessoriesView();
        ONE3D.event.addMultiAccessories(selected, {
          callback: () => {
            // Logic
            console.log("Acc added");
            ONE3D.loadedPercent = 100;

            if (typeof window.getLoaderProgress === 'function') {
              window.getLoaderProgress(100);
            }
            this._global.showCircleLoader(false);
          }
        });
      }
    } else {
      let res: any = await this.updateAdditionalPackAPI(additionalPack, pack, 0);
      if (res.status != 1) {
        return false;
      }
      res?.response?.packages?.forEach(packages => {
        if (additionalPack.allow_custom === 0 && packages.pack_id == additionalPack.parent_pack_id) {
          if (packages?.additionalPack?.length > 0) {
            packages.additionalPack.forEach(element => {
              if (element.allow_custom === 0 && element.pack_id == additionalPack.pack_id) {
                console.log("Matched", additionalPack, element);
                //Make the values dynamic from API 
                additionalPack.flag = element.flag;
                additionalPack.checked = element.flag == "Remove" ? true : false;
              }
            });
          }
        }
      });
      // Retrieve the data from localStorage
      this.selectedPacks = JSON.parse(localStorage.getItem('selectedPacks'));
      // Find the pack object with pack_id = 1
      let packObj = this.selectedPacks.find((item) => item.pack_id === pack.pack_id);
      if (packObj) {
        // Find the additionalPack object with allow_custom === 0
        let additionalPackObj = packObj.additionalPack.find((item) => item.allow_custom === 0);

        if (additionalPackObj) {
          // Modify the checked property
          //Make it dynamic
          additionalPackObj.checked = additionalPack.checked;
          additionalPackObj.flag = additionalPack.flag;
        }
      }
      // Save the updated data back to localStorage
      console.log("getting set 1842", this.selectedPacks);
      localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
      // ONE3D.removeKit(additionalPack.pack_mesh_code);
      let selected = [];
      additionalPack.accessoriesList.map((ele) => {
        selected.push(ele.mesh);
      });
      if (selected.length > 0) {
        ONE3D.exitAccessoriesView();
        ONE3D.removeAllAccessories(selected, {
          callback: () => {

          }
        });
      }
    }
  }

  async addRemPackOptions(packOptions, additionalPack: any = null, pack) {

    // packOptions.checked = false;
    if (!packOptions.checked) {
      // if (packOptions.parent_pack_id != pack.pack_id) {
      this.overlappingItems = this.isOverlapping(packOptions, JSON.parse(localStorage.getItem(SELECTEDACC)) || [], JSON.parse(localStorage.getItem(SELECTEDPACK)) || [], "OptionsPack");
      if (this.overlappingItems.length > 0) {
        this.isOverlappingModal = true;
        this.selectedAddonAccessory = packOptions;
        this.selectedPackage = pack;
        setTimeout(() => {
          packOptions.checked = false;
          packOptions.flag = "Remove";
        }, 100);
        console.log("The overlapped items", this.overlappingItems);
        return false;
      }
      // }

      this._global.showCircleLoader(true);

      // this.updateAccUI(packOptions);
      this.addUpdateAcc3D(packOptions);

      this._global.showCircleLoader(true);

      this._global.showCircleLoader(true);

      let res: any = await this.updateAdditionalWheelCoverAPI(packOptions, pack, 1);
      // let res: any = await this.updateAdditionalPackAPI(packOptions, pack, 1);
      // console.log("My result", res);
      if (res.status != 1) {
        return false;
      }

      this._global.showCircleLoader(false);

      //Flag status won't be set.
      this.clearSelection(additionalPack, false, pack);

      this.updateCheckedStatus(res, packOptions, additionalPack, pack);

      this.updatePackOptionsLocalStorage(packOptions, additionalPack, pack);

      setTimeout(() => {
        console.log("Alert alert alert", additionalPack);
      }, 700);

    } else {
      // alert(2);
      // packOptions.checked = !packOptions.checked;
      // this. addRemPackOptions(packOptions, additionalPack, pack); 
    }
  }

  updateCheckedStatus(res, packOptions: any, additionalPack: any, pack: any) {
    console.log("My response is", res);
    res?.response?.packages?.forEach(packages => {
      // console.log("Matched 0", packOptions, packages );
      if (packOptions.allow_custom === 1) {
        // console.log("Matched 1", packOptions, packages );
        if (packages?.additionalPack?.length > 0) {
          packages.additionalPack.forEach(element => {
            // console.log("Matched 2", element );
            if (element.allow_custom == 1 && element.pack_id == packOptions.pack_id && element?.accessoriesList?.length > 0) {
              // console.log("Matched 3",element, packOptions );
              element.accessoriesList.forEach(acc => {
                // console.log("Matched 4",acc );
                additionalPack?.accessoriesList.forEach(additionalPackAcc => {
                  if (acc.accessories_id == additionalPackAcc.accessories_id) {
                    //Make the values dynamic from API 
                    // console.log("Matched 5",acc, additionalPack);
                    // packOptions.flag = acc.flag;
                    // packOptions.checked = acc.flag == "Remove" ? true : false;
                    additionalPackAcc.flag = acc.flag;
                    additionalPackAcc.checked = acc.flag == "Remove" ? true : false;
                    console.log("Matched 2", packOptions.accessories_id
                      , acc.accessories_id
                    );
                    console.log("Matched 2", packOptions.flag
                      , acc.flag
                    );
                  } else {
                    // packOptions.flag = "Add";
                    // packOptions.checked = false;
                  }
                });

              });
            }
          });
        }
      }
    });
  }

  updateAccUI(packOptions: any) {
    if (packOptions.type == 'image') {
      // document.getElementById('featureImageFullScreen').style.display = 'block';
      if (packOptions.flag == 'Add') {
        if (window.innerWidth < 450) {
          console.log('mobile');

          this._global.updateOverlaySrc(
            `${this.accUrl}full_screen/mobile/${packOptions.image_url}`
          );
        } else {
          console.log('desktop');
          this._global.updateOverlaySrc(
            `${this.accUrl}full_screen/${packOptions.image_url}`
          );
        }
        setTimeout(() => {
          document.getElementById('featureImageFullScreen').style.display =
            'block';
        }, 100);
        if (this.ACCESS) {
          this._socket.emitAccAdd(packOptions);
        }
        this._global.updateAccCam(true);
      } else {
        if (this.ACCESS) {
          this._socket.emitAccRemove(packOptions);
        }
        this._global.updateAccCam(false);
      }
      ONE3D.exitAccessoriesView();
    } else {
      // console.log("1895", packOptions.hotspot_name, packOptions.mesh)
      ONE3D.setAccessorieCamera(packOptions.hotspot_name, packOptions.mesh, {
        callback: async () => {
          this._global.showCircleLoader(false);
          // console.log('Camera set', packOptions.hotspot_name, packOptions.mesh, packOptions.flag);
          if (packOptions.flag == 'Add') {
            //This fn to be called. 
            if (this.ACCESS) {
              this._socket.emitAccAdd(packOptions);
            }
            this._global.updateAccCam(true);
          } else {
            this._global.showCircleLoader(false);
            ONE3D.removeAccessories(packOptions.mesh);

            if (this.ACCESS) {
              this._socket.emitAccRemove(packOptions);
            }
            this._global.updateAccCam(true);
          }
        },
      });
    }
  }

  updatePackOptionsLocalStorage(packOptions, additionalPack, pack) {
    // Retrieve the data from localStorage
    this.selectedPacks = JSON.parse(localStorage.getItem('selectedPacks')) || [];
    // Find the pack object with pack_id;
    let packObj = this.selectedPacks.find((item) => item.pack_id === pack.pack_id);
    console.log("PackObj", packObj);
    if (packObj) {
      // Find the additionalPack object with allow_custom === 1
      let additionalPackObj = packObj.additionalPack.find((item) => item.allow_custom === 1 && item.pack_id === additionalPack.pack_id);
      console.log("additionalPackObj", additionalPackObj);
      if (additionalPackObj && additionalPackObj.accessoriesList?.length > 0) {
        console.log("Setting 1791");
        additionalPackObj.accessoriesList.forEach(element => {
          // console.log("Setting 1793", element, packOptions);
          // console.log("Setting 17931", element.accessories_id, packOptions.accessories_id);
          if (element.accessories_id === packOptions.accessories_id) {
            // Modify the checked property
            // Make it dynamic 
            additionalPackObj.checked = packOptions.checked;
            additionalPackObj.flag = packOptions.flag;
            element.checked = packOptions.checked;
            element.flag = packOptions.flag;
            console.log("Matched only once", element.accessories_id, packOptions);
            // additionalPackObj.checked = packOptions.checked;
            // additionalPackObj.flag = packOptions.flag;
          } else {
            element.checked = false;
            element.flag = "Add";
          }
        });
        console.log("additionalPackObj", additionalPackObj)
      }
    }
    this.selectedPackage = {};
    this.selectedAddonAccessory = {};
    console.log("getting set 1865", this.selectedPacks);
    localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
  }

  storePacks(pack) {
    this._data.setPacks(this.selectedPacks, pack);
  }

  clearSelection(additionalpack, flag?, parentPack?) {
    // console.log("Clear selection", this.accList);
    if (additionalpack == null) {
      return false;
    }
    if (this.accList.packages.length > 0) {
      this.accList?.packages?.forEach(element => {
        if (element.additionalPack && element.additionalPack.length > 0) {
          element.additionalPack.forEach((pack) => {
            // console.log("Resetting 21", pack);
            if (pack.allow_custom === 1 && pack.pack_id == additionalpack.pack_id) {
              let selected = [];
              pack?.accessoriesList?.forEach(async (accessory) => {
                if (accessory.flag == "Remove" && flag) {
                  // alert(11 + " "+ accessory.mesh)
                  ONE3D.removeAccessories(accessory.mesh);
                  setTimeout(async () => {
                    let res: any = await this.updateAdditionalPackAPI(accessory, parentPack, 1);
                    if (res.status != 1) {
                    }
                    this.updateCheckedStatus(res, accessory, additionalpack, parentPack);
                    this.updatePackOptionsLocalStorage(accessory, additionalpack, parentPack);
                    accessory.checked = false;
                    accessory.flag = "Add";
                  }, 90);
                }
                // selected.push(accessory.mesh);
                // console.log("list to be removed", JSON.stringify(selected));
              });
              console.log("2009", selected.length > 0 && flag);
              // if (selected.length > 0 && flag) {
              //   ONE3D.removeAllAccessories(selected, {
              //     callback: () => {
              //       console.log("Removed", 1988);
              //     }
              //   });
              // }
            }
          });
          // }
        }
      });
    }
  }


  updateAdditionalPackAPI(additionalPack, pack, allow_custom) {
    return new Promise(async (resolve, reject) => {
      try {
        let apiData = {
          pin_no: this.globalPinNO,
          variant_id: this.selectedVariant.variant_id,
          access_id: allow_custom === 0 ? additionalPack.pack_id : additionalPack.accessories_id,
          subgroup_id: allow_custom === 0 ? additionalPack.parent_pack_id.toString() : additionalPack.pack_id.toString(),
          change_variant: "",
          flag: additionalPack.flag,
          price: allow_custom == 0 ? pack.pack_price : additionalPack.price,
          type: "Addons",
          qty: 1,
          is_multiple: pack.is_multiple,
          source: "web",
          acces_type: "",
          is_pack: allow_custom == 0 ? "yes" : "no",
          is_sub_pack: "yes"
        };

        let respData: any = await this._api.addRemoveAccessories(apiData);
        if (respData.status == 1) {
          this._global.updatedPrice(respData.response.summary.showroom_price);
          this._ga.sendGAEvent(`${additionalPack.category} access`, "click", additionalPack.marketing_name);

          if (additionalPack.flag == "Add") {
            additionalPack.flag = "Remove";
          } else {
            additionalPack.flag = "Add";
          }
        }
        resolve(respData);
      } catch (error) {
        this._global.showLoading(false);
        console.log("Error in Acc", error);
        reject(error);
      }
    });
  }

  updateAdditionalWheelCoverAPI(additionalPack, pack, allow_custom) {
    return new Promise(async (resolve, reject) => {
      try {
        let apiData = {
          pin_no: this.globalPinNO,
          variant_id: this.selectedVariant.variant_id,
          access_id: allow_custom === 0 ? additionalPack.pack_id : additionalPack.accessories_id,
          subgroup_id: allow_custom === 0 ? additionalPack.parent_pack_id.toString() : additionalPack.pack_id.toString(),
          change_variant: "",
          flag: "Add",
          price: allow_custom == 0 ? pack.pack_price : additionalPack.price,
          type: "Addons",
          qty: 1,
          is_multiple: pack.is_multiple,
          source: "web",
          acces_type: "",
          is_pack: allow_custom == 0 ? "yes" : "no",
          is_sub_pack: "yes"
        };

        let respData: any = await this._api.addRemoveAccessories(apiData);
        if (respData.status == 1) {
          this._global.updatedPrice(respData.response.summary.showroom_price);
          this._ga.sendGAEvent(`${additionalPack.category} access`, "click", additionalPack.marketing_name);

          if (additionalPack.flag == "Add") {
            additionalPack.flag = "Remove";
          } else {
            additionalPack.flag = "Add";
          }
        }
        resolve(respData);
      } catch (error) {
        this._global.showLoading(false);
        console.log("Error in Acc", error);
        reject(error);
      }
    });
  }


  findIndexInArrays(arrays, condition) {
    for (const array of arrays) {
      const index = array.findIndex(condition);
      if (index >= 0) {
        return index;
      }
    }
    return -1;
  }

  findArrayInArrays(arrays, condition) {
    for (const array of arrays) {
      const foundArray = array.find(condition);
      if (foundArray) {
        return foundArray;
      }
    }
    return null;
  }


  clearSelectionEvent(additionalpack, flag?, parentPack?) {
    // console.log("Clear selection", this.accList);
    if (additionalpack == null) {
      return false;
    }
    if (this.accList.packages.length > 0) {
      this.accList?.packages?.forEach(element => {
        if (element.additionalPack && element.additionalPack.length > 0) {
          // console.log("Resetting 2", element, additionalpack);
          // if (element.additionalPack.pack_id == additionalpack.pack_id) {
          // console.log("Resetting 1");
          element.additionalPack.forEach((pack) => {
            // console.log("Resetting 21", pack);
            if (pack.allow_custom === 1 && pack.pack_id == additionalpack.pack_id) {
              let selected = [];
              pack?.accessoriesList?.forEach(async (accessory) => {
                if (accessory.flag == "Remove" && flag) {
                  ONE3D.removeAccessories(accessory.mesh, {
                    callback: () => {
                      console.log("Removed", 1988);
                    }
                  });
                  let res: any = await this.updateAdditionalPackAPI(accessory, parentPack, 1);
                  if (res.status != 1) {
                  }
                  this.updateCheckedStatus(res, accessory, additionalpack, parentPack);
                  this.updatePackOptionsLocalStorage(accessory, additionalpack, parentPack);
                }
                accessory.checked = false;
                accessory.flag = "Add";
                selected.push(accessory.mesh);
                // console.log("list to be removed", JSON.stringify(selected));
              });
              // console.log("2009", selected.length > 0 && flag);
              if (selected.length > 0 && flag) {
                ONE3D.removeAllAccessories(selected, {
                  callback: () => {
                    console.log("Removed", 1988);
                  }
                });
              }
            }
          });
          // }
        }
      });
    }
  }

  async addRemOptKit(optionKit, pack, flag = true) {

    // if (optionKit.parent_pack_id != pack.pack_id) {
    if (this.overlappingItems.length > 0) {
      this.isOverlappingModal = true;
      this.selectedGroupOptionKit = optionKit;
      this.selectedPackage = pack;
      setTimeout(() => {
        optionKit.checked = false;
        optionKit.flag = "Remove";
      }, 100);
      // console.log("The overlapped items", this.overlappingItems);
      return false;
    }

    this.overlappingItems = this.isOverlapping(optionKit, JSON.parse(localStorage.getItem(SELECTEDACC)) || [], JSON.parse(localStorage.getItem(SELECTEDPACK)) || [], "OptionsKit");
    // }

    // let flag1 = 0;
    if (!optionKit.checked) {
      if (flag) {
        this.expandNestedAcc(optionKit);
      }
      pack.additionalPack?.forEach(async additionalPack => {
        if (additionalPack.allow_custom == 2 && additionalPack.flag == "Remove") {
          // console.log("Item to be removed", optionKit);
          await this.apply3Dview(optionKit, false);
          // setTimeout(() => {
          this.removeOptionKit(additionalPack, pack);
          // }, 90);
        }
      });

      await this.apply3Dview(optionKit, true);
      // setTimeout(() => {
      optionKit.accessoriesList?.forEach(acc => {
        this.addRemGroupKits(acc, optionKit, pack, false);
      });
      // }, 50);


    }
  }
  colapseNestedAcc(optionKit: any) {
    const ele = document.getElementById("nested-acc-" + optionKit.pack_id);
    if (ele) {
      ele.style.display = "none";
    }
  }

  expandNestedAcc(optionKit) {
    const ele = document.getElementById("nested-acc-" + optionKit.pack_id);
    if (ele) {
      ele.style.display = "block";
    }
  }

  async addRemGroupKits(acc, additionalPack, pack, loadTogether = true) {
    //API call simulation 

    // if (loadTogether) {
    //   if (acc.checked)
    //   this.addUpdateAcc3D(acc);
    // }

    this._global.showCircleLoader(true);
    setTimeout(async () => {
      let result: any = await this.updateGraphicsOptToApi(acc, additionalPack, loadTogether);
      if (result.status != 1) {
        this._global.showCircleLoader(false);
        return false;
      }
      this._global.showCircleLoader(false);

      result?.response?.packages.forEach((packs: any) => {
        if (packs?.additionalPack?.length > 0) {
          const index = packs.additionalPack.findIndex(obj => obj.allow_custom === 2);
          if (index !== -1) {
            this.insertionIndexDummyObj = index;
            packs.additionalPack.splice(index, 0, dummyObj);
          }
        }
      });

      // let result: any = respAdded;
      result?.response?.packages?.forEach(elePack => {
        if (elePack.pack_id == pack.pack_id) {
          console.log("Matched 0");
          elePack?.additionalPack?.forEach(eleAddPack => {
            if (eleAddPack.pack_id == additionalPack.pack_id) {
              console.log("Matched 1");
              eleAddPack.checked = eleAddPack.flag == "Remove" ? true : false;
              eleAddPack.flag = eleAddPack.flag;
              additionalPack.checked = eleAddPack.checked;
              additionalPack.flag = eleAddPack.flag;
              eleAddPack?.accessoriesList?.forEach(eleAcc => {
                if (eleAcc.accessories_id == acc.accessories_id) {
                  // console.log("Matched 2");
                  eleAcc.checked = eleAcc.flag == "Remove" ? true : false;
                  acc.checked = eleAcc.checked;
                  acc.flag = eleAcc.flag;
                  console.log("Matched", eleAcc, acc);
                  this.cdRef.detectChanges();
                }
              });
            }
          });
        }
      });
      console.log("After", this.accList);
      this.selectedPackage = {};
      this.selectedGroupOptionKit = {};
      this.setGroupKitLocalStorage(acc, additionalPack, pack);

      if (!additionalPack.accessoriesList.some((accessory) => accessory.checked)) {
        // The code will go here when both checboxes are false, call the clear selection to 
        //Remove radio button selection.
        additionalPack.checked = false;
      } else {
        //
      }
    }, 190);

  }

  setGroupKitLocalStorage(acc, additionalPack, pack) {
    if (this._data.isLocalStorageItemSet(SELECTEDPACK)) {
      // Retrieve the data from localStorage
      this.selectedPacks = JSON.parse(localStorage.getItem(SELECTEDPACK)) || [];
      // Find the pack object with pack_id;
      let packObj = this.selectedPacks.find((item) => item.pack_id === pack.pack_id);
      console.log("PackObj", packObj);
      if (packObj) {
        // Find the additionalPack object with allow_custom === 2
        let additionalPackObj = packObj.additionalPack.find((item) => item.allow_custom === 2 && item.pack_id === additionalPack.pack_id);
        console.log("additionalPackObj", additionalPackObj);
        if (additionalPackObj && additionalPackObj.accessoriesList?.length > 0) {
          console.log("Setting 1791");
          additionalPackObj.accessoriesList.forEach(element => {
            // console.log("Setting 1793", element, packOptions);
            // console.log("Setting 17931", element.accessories_id, packOptions.accessories_id);
            if (element.accessories_id === acc.accessories_id) {
              // Modify the checked property
              // Make it dynamic 
              additionalPackObj.checked = additionalPack.checked;
              additionalPackObj.flag = additionalPack.flag;
              element.checked = acc.checked;
              element.flag = acc.flag;
              console.log("Matched only once", element, acc);
              this.cdRef.detectChanges();
            } else {
              // element.checked = false;
              // element.flag = "Add";
              // console.log("Matched only ELSE", element, acc);
            }
          });
          // console.log("additionalPackObj", additionalPackObj)
        }
      }
      console.log("getting set 1865", this.selectedPacks);
      localStorage.setItem(SELECTEDPACK, JSON.stringify([...this.selectedPacks]));
    }
  }

  async removeOptionKit(optionKit, pack) {
    // setTimeout(() => {
    this._global.showCircleLoader(true);
    let res: any = await this.removeGraphicOptionKit(optionKit);
    if (res.status != 1) {
      this._global.showCircleLoader(false);
      return false;
    }
    this.colapseNestedAcc(optionKit);
    this._global.showCircleLoader(false);

    res?.response?.packages.forEach((packs: any) => {
      if (packs?.additionalPack?.length > 0) {
        // packs.additionalPack.unshift(dummyObj);
        const index = packs.additionalPack.findIndex(obj => obj.allow_custom === 2);
        if (index !== -1) {
          this.insertionIndexDummyObj = index;
          packs.additionalPack.splice(index, 0, dummyObj);
        }
      }
    });


    // let res: any = respRemoved.response;
    res?.response?.packages?.forEach(elePack => {
      if (elePack.pack_id == pack.pack_id) {
        elePack?.additionalPack?.forEach(eleAddPack => {
          if (eleAddPack.pack_id == optionKit.pack_id) {
            optionKit.flag = eleAddPack.flag;
            optionKit.checked = optionKit.flag == "Remove" ? true : false;
            optionKit?.accessoriesList?.forEach(acc => {
              eleAddPack?.accessoriesList?.forEach(eleAcc => {
                acc.flag = eleAcc.flag;
                acc.checked = eleAcc.flag == "Remove" ? true : false;
                this.setGroupKitLocalStorage(acc, optionKit, pack);
              });
            });
          }
        });
      }
    });

    // }, 5000);
  }

  filterAdditionalPack(selectedPackage, checked, allowCustom, selectedItem?) {
    // && selectedItem.parent_pack_id != item.pack_id
    // This line is basically the item that is getting added via - 
    // wheel cover options or graphics options even if it has same subgroub id as it's 
    // parent pack's subgroup id, we don't want overlap to run on that package. 
    // Since a package can contain acc with same subgroup ids
    return selectedPackage.additionalPack.filter((item) => {
      if (selectedItem && selectedItem.parent_pack_id === item.parent_pack_id) {
        return false; // Skip the item if it has the same parent pack_id as the selectedItem
      }
      return item.checked === checked && item.allow_custom === allowCustom;
    });
  }

  updateGraphicsOptToApi(accessory, optionKit, loadTogether?) {
    return new Promise(async (resolve, reject) => {
      try {
        let apiData = {
          pin_no: this.globalPinNO,
          variant_id: this.selectedVariant.variant_id,
          //Send accessory id of all the accessory contained in a option kit in for loop.
          access_id: accessory.accessories_id,
          //Group option's pack id and send it in tostring()
          subgroup_id: optionKit?.pack_id?.toString(),
          change_variant: "",
          //Send accessory id of all the accessory contained in a option kit in for loop.
          flag: accessory.flag,
          //Send accessory id of all the accessory contained in a option kit in for loop.
          price: accessory.price,
          type: "Addons",
          qty: 1,
          //Don't send this. 
          is_multiple: "",
          source: "web",
          acces_type: "",
          is_pack: "no",
          is_sub_pack: "yes"
        };

        let respData: any = await this._api.addRemoveAccessories(apiData);
        if (respData.status == 1) {
          this._global.updatedPrice(respData.response.summary.showroom_price);
          this._ga.sendGAEvent(`${accessory.category} access`, "click", accessory.marketing_name);
          if (accessory.flag == "Add") {
            accessory.flag = "Remove";
            if (loadTogether) {
              this.addUpdateAcc3D(accessory);
            }
          } else {
            accessory.flag = "Add";
            if (loadTogether) {
              this.removeUpdateAcc3D(accessory);
            }
          }
        }
        resolve(respData);
      } catch (error) {
        this._global.showLoading(false);
        console.log("Error in Acc", error);
        reject(error);
      }
    });
  }

  //OG code
  // apply3Dview(optionsKit, flag) {
  //   console.log("Got called");
  //   try {
  //     if (flag) {
  //       this._global.showCircleLoader(true);
  //       let selected = [];
  //       optionsKit.accessoriesList.map((ele) => {
  //         selected.push(ele.mesh);
  //       });
  //       console.log(JSON.stringify(selected));
  //       if (selected.length > 0) {
  //         // console.log(JSON.stringify(selected));
  //         ONE3D.addMultiAccessories(selected, {
  //           callback: () => {
  //             //Logic
  //             this._global.showCircleLoader(false);
  //           }
  //         });
  //       }
  //     } else {
  //       this._global.showCircleLoader(true);
  //       let selected = [];
  //       optionsKit.accessoriesList.map((ele) => {
  //         selected.push(ele.mesh);
  //       });
  //       // console.log("Removed", JSON.stringify(selected));
  //       if (selected.length > 0) {
  //         // console.log(JSON.stringify(selected));
  //         ONE3D.removeAllAccessories(selected, {
  //           callback: () => {
  //             //Logic
  //             this._global.showCircleLoader(false);
  //           }
  //         });
  //       }
  //     }

  //   } catch (error) {
  //     this._global.showCircleLoader(false);
  //   }

  // }

  apply3Dview(optionsKit, flag) {
    return new Promise((resolve, reject) => {
      try {
        ONE3D.exitAccessoriesView();
        this._global.showCircleLoader(true);
        let selected = [];
        optionsKit.accessoriesList.forEach((ele) => {
          selected.push(ele.mesh);
        });
        console.log(JSON.stringify(selected));
        if (selected.length > 0) {
          if (flag) {
            ONE3D.event.addMultiAccessories(selected, {
              callback: () => {
                // Logic
                console.log("Acc added");
                ONE3D.loadedPercent = 100;
  
                if (typeof window.getLoaderProgress === 'function') {
                  window.getLoaderProgress(100);
                }
                this._global.showCircleLoader(false);
                resolve(true);
              }
            });
          } else {
            // alert("Removing");
            ONE3D.removeAllAccessories(this.overAllMeshListCustom2, {
              callback: () => {
                this._global.showCircleLoader(false);
                // alert("Removed");
                resolve(true);
              }
            });
          }
        } else {
          this._global.showCircleLoader(false);
          // alert("Nothing selected");
          resolve(false);
        }
      } catch (error) {
        this._global.showCircleLoader(false);
        // alert(JSON.stringify(error))
        reject(error);
      }
    });
  }


  removeGraphicOptionKit(optionKit) {
    return new Promise(async (resolve, reject) => {
      try {
        let apiData = {
          pin_no: this.globalPinNO,
          variant_id: this.selectedVariant.variant_id,
          flag: "Remove",
          pack_id: optionKit.pack_id,
          is_pack: "no",
          is_sub_pack: "yes"
        };

        let respData: any = await this._api.removeOptionKitAPI(apiData);
        if (respData.status == 1) {
          // this._global.updatedPrice(respData.response.summary.showroom_price);
          // this._ga.sendGAEvent(`${accessory.category} access`, "click", accessory.marketing_name);
          if (optionKit.flag == "Add") {
            optionKit.flag = "Remove";
          } else {
            optionKit.flag = "Add";
          }
        }
        resolve(respData);
      } catch (error) {
        this._global.showLoading(false);
        console.log("Error in Acc", error);
        reject(error);
      }
    });
  }

  addUpdateAcc3D(item) {
    try {
      // ONE3D.exitAccessoriesView();
      // console.log("Check this add", item.flag);
      // setTimeout(() => {
      ONE3D.setAccessorieCamera(item.hotspot_name, null, {
        callback: async () => {
          // if (item.flag == 'Add') {
          this._global.showCircleLoader(true);
          //This fn not to be called when adding pack
          ONE3D.addAccessorie(item.mesh, item.tag, {
            callback: () => {
              console.log('ACC LOADED');
              this._global.showCircleLoader(false);
            },
          });
          //This fn to be called. 
          if (this.ACCESS) {
            this._socket.emitAccAdd(item);
          }
          this._global.updateAccCam(true);
          // } else {
          //   ONE3D.removeAccessories(item.mesh, item.tag, {
          //     callback: () => {
          //       console.log('ACC Removed');
          //       this._global.showCircleLoader(false);
          //     },
          //   });
          //   if (this.ACCESS) {
          //     this._socket.emitAccRemove(item);
          //   }
          //   this._global.updateAccCam(true);
          // }
        },
      });
      // }, 100);

    } catch (error) {
      this._global.showCircleLoader(false);
    }
  }

  removeUpdateAcc3D(item) {
    try {
      // ONE3D.exitAccessoriesView();
      console.log("Check this remove", item.flag);
      // setTimeout(() => {
      ONE3D.setAccessorieCamera(item.hotspot_name, null, {
        callback: async () => {
          // if (item.flag == 'Add') {
          // this._global.showCircleLoader(true);
          //This fn not to be called when adding pack
          // {
          ONE3D.removeAccessories(item.mesh, item.tag);
          if (this.ACCESS) {
            this._socket.emitAccRemove(item);
          }
          this._global.updateAccCam(true);
          // }
        },
      });
      // }, 100);

    } catch (error) {
      this._global.showCircleLoader(false);
    }
  }

  clearSelectionOptionKit(additionalPack, pack) {
    this.removeOptionKit(additionalPack, pack);
    this.apply3Dview(additionalPack, false);
  }

  toggleGraphicOptions() {
    this.showList = !this.showList;
    this.accList?.packages?.forEach(packages => {
      packages?.additionalPack?.forEach(eleAdd => {
        console.log(eleAdd);
        if (eleAdd.allow_custom == 2 && eleAdd.flag == "Remove") {
          setTimeout(() => {
            this.expandNestedAcc(eleAdd);
          }, 700);
        }
      });
    });

  }


}

