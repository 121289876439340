import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/service/global.service';
import { DataService } from '../../service/data.service';

@Component({
  selector: 'app-session-warning',
  templateUrl: './session-warning.component.html',
  styleUrls: ['./session-warning.component.scss'],
})
export class SessionWarningComponent implements OnInit {
  private _result: Promise<unknown>;
  private _resolve: any;
  private _reject: any;

  constructor(
    public dialogRef: MatDialogRef<SessionWarningComponent>,
    public _data: DataService,
    public _global: GlobalService,
    @Inject(MAT_DIALOG_DATA) public model: any
  ) {}

  ngOnInit(): void {}

  onClose() {
    this.dialogRef.close({
      data: 'no',
    });
  }

  onClickYes() {
    let dealerData = this._data.getDealerDetails();
    if (dealerData) {
      this._global.updateDealerDetailsToken(dealerData);
    }
    this.dialogRef.close({
      data: 'yes',
    });
  }
}
