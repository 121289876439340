import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wave-animation',
  templateUrl: './wave-animation.component.html',
  styleUrls: ['./wave-animation.component.scss'],
})
export class WaveAnimationComponent implements OnInit {
  @Input() showAnim: boolean = false;
  constructor() {}

  ngOnInit(): void {}
}
