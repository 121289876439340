import { Component, NgZone, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { MgExpertService } from '../../service/mg-expert.service';
import { DataService } from '../../service/data.service';
import {
  GlobalService,
  glosterSeating,
  hectorPlusSeating,
} from '../../service/global.service';
import { CallService } from '../../service/call.service';
import { defaultCarDetails } from '../carSettings';
import { EventService } from 'src/app/service/event.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-car-compare',
  templateUrl: './car-compare.component.html',
  styleUrls: ['./car-compare.component.scss'],
})
export class CarCompareComponent implements OnInit {
  globalPinNO: string;
  colorList: any[];
  selectedColor: any;
  selectedVariant: import('../../service/global.service').variant;
  selectedSecondVariantDetails: any = {};
  variantList: any[];
  selectedSecondVariantId: number = 0;
  selectedThirdVariantId: number = 0;
  locationInfo: any;
  loadedCar: any;
  variantCodeList: any;
  compareData: any;
  OpenAccordionIndex: string = '';
  $dataTransfer: Subscription;
  $receiveCompareVariant: Subscription;
  ACCESS: boolean;
  callConnected: boolean;
  isChecked: any;
  copyCompareData: string;
  hectorSeatingOptions: hectorPlusSeating | glosterSeating;

  constructor(
    private _api: ApiService,
    private _mgExp: MgExpertService,
    private _data: DataService,
    private _global: GlobalService,
    private _callS: CallService,
    private router: Router,
    private zone: NgZone,
    private location: Location,
    private _event: EventService
  ) {}

  ngOnInit(): void {
    this._global.pinno.subscribe((data) => (this.globalPinNO = data));
    this._global.colorList.subscribe((data) => (this.colorList = data));
    this._global.selectedColor.subscribe((data) => (this.selectedColor = data));
    this._global.variantList.subscribe((data) => {
      this.variantList = data;
    });
    this._global.hectorSeatingOptions.subscribe((data) => {
      this.hectorSeatingOptions = data;
    });
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.locationData.subscribe((data) => (this.locationInfo = data));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.audioStatus.subscribe((data) => (this.callConnected = data));
    this.loadedCar = this._data.getLocalCarBrand();
    this.getVariantCodeList();
    console.log('this.loadedCar', this.loadedCar);
    console.log('this.variantList', this.variantList);
    this.registerSocket();
    if (this.loadedCar === 'hectorplus') {
      this.variantList = this.variantList.filter(
        (ele) => ele.seats_no == `${this.hectorSeatingOptions}S`
      );
    }
    console.log('this.variantList', this.variantList);
  }
  ngOnDestroy(): void {
    this.$dataTransfer.unsubscribe();
    this.$receiveCompareVariant.unsubscribe();
  }
  async getVariantCodeList() {
    try {
      let apiBody = {
        model_id: defaultCarDetails[this.loadedCar].model_id,
        // model_id: '1',
      };

      let rep: any = await this._api.getVariantCodeList(apiBody);
      console.log(rep);

      if (rep.status == 1) {
        this.variantCodeList = rep.response;
        // console.log('result===>>', result);
      }
    } catch (error) {
      console.error(error);
    }
  }
  closeModal() {
    this._global.toggleCarCompareModal(false);
    // this.selectedVariant.variant_id
  }
  resetVariant() {
    this.selectedSecondVariantDetails = {};
  }
  setSecondModal() {
    console.log(this.selectedSecondVariantId);
    if (this.selectedSecondVariantId) {
      this.selectedSecondVariantDetails = this.variantList.find(
        (ele) => ele.variant_id == this.selectedSecondVariantId
      );
      this.getCarCompData();
    }
  }
  isSelectedSecondVariant() {
    if (this.selectedSecondVariantDetails) {
      // added as it giving error on unselect
      return Object.keys(this.selectedSecondVariantDetails).length === 0
        ? true
        : false;
    }
  }

  async getCarCompData() {
    console.log(this.selectedVariant);
    console.log(this.selectedSecondVariantDetails);
    this.isChecked = false;
    let car1 = this.variantCodeList.find(
      (ele) => ele.title === this.selectedVariant.title
    );
    let car2 = this.variantCodeList.find(
      (ele) => ele.title === this.selectedSecondVariantDetails.title
    );
    try {
      let apiBody = {
        model_sales_code1: car1.model_sales_code,
        model_sales_code2: car2.model_sales_code,
      };

      let rep: any = await this._api.getCarCompareData(apiBody);
      console.log(rep);
      let result = {};
      if (rep.status == 1) {
        // this.compareData = rep.response;
        let resp = rep.response;
        for (const element in resp) {
          if (Object.prototype.hasOwnProperty.call(resp, element)) {
            const carEle = resp[element];
            Object.assign(result, { [element]: {} });
            for (const featureCat in carEle) {
              if (Object.prototype.hasOwnProperty.call(carEle, featureCat)) {
                const category = carEle[featureCat];
                if (typeof category == 'object' && category.length > 0) {
                  if (featureCat != 'Key Highlights') {
                    var mapped = carEle[featureCat].map((item) => ({
                      [item.AttributeID]: item,
                    }));
                  } else {
                    var mapped = carEle[featureCat].map((item) => ({
                      [item.AttributeValue]: item,
                    }));
                  }
                  var newObj = Object.assign({}, ...mapped);
                  result[element][featureCat] = newObj;
                }
              }
            }
          }
        }
        // console.log(this.compareData[this.selectedVariant.title]);
        this.compareData = Object.assign({}, result);
        this.copyCompareData = JSON.stringify(this.compareData);
        // this.copyCompareData = JSON.parse(JSON.stringify(this.compareData));
        console.log('result---->', this.compareData);
      }
    } catch (error) {}
  }

  checkArray(value: any) {
    // if (value && typeof value == 'string' || typeof value == 'number') {
    //   return false
    // } else
    if (value && typeof value == 'object') {
      return value.length ? true : false;
    }
    return false;
  }

  togglEAccordion(index) {
    console.log('index', index);
    setTimeout(() => {
      document
        .querySelector("[data-feature='" + index + "']")
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
    if (this.OpenAccordionIndex == index) {
      this.OpenAccordionIndex = '';
    } else {
      this.OpenAccordionIndex = index;
    }
  }

  returnValueForSecondKey(key, index) {
    if (
      this.selectedSecondVariantDetails.hasOwnProperty('title') &&
      this.compareData[this.selectedSecondVariantDetails.title] &&
      this.compareData[this.selectedSecondVariantDetails.title].hasOwnProperty(
        key
      )
    ) {
      if (
        this.compareData[this.selectedSecondVariantDetails.title][
          key
        ].hasOwnProperty(index)
      ) {
        return this.compareData[this.selectedSecondVariantDetails.title][key][
          index
        ].AttributeValue;
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }
  registerSocket() {
    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      let keys = Object.keys(data);
      let key = keys[0];
      switch (key) {
        case 'accordionKey':
          console.log('open', data[key]);
          this.togglEAccordion(data[key]);
          break;
        case 'hideCommonFeatures':
          // console.log('open', data[key]);
          this.isChecked = true;
          this.togglEAccordion(this.OpenAccordionIndex);
          this.hideCommnFEature();
          break;
        case 'showCommonFeatures':
          this.togglEAccordion(this.OpenAccordionIndex);
          // console.log('open', data[key]);
          this.isChecked = false;
          this.hideCommnFEature();
          break;
        default:
          break;
      }
    });
    this.$receiveCompareVariant = this._event.subscribe(
      'receive_compare_variant',
      (data) => {
        console.log('receive_compare_variant', data);
        // this.selectedSecondVariantId = data.model_sales_code2;
        let variant = this.variantList.find(
          (ele) => ele.model_sales_code == data.model_sales_code2
        );
        this.selectedSecondVariantId = variant.variant_id;
        this.setSecondModal();
      }
    );
  }

  returnZero() {
    return 0;
  }
  hideCommnFEature() {
    console.log('isChecked', this.isChecked);
    if (this.isChecked) {
      let keys = Object.keys(this.compareData);
      let firstVariant = this.compareData[keys[0]];

      for (const catKey in firstVariant) {
        if (Object.hasOwnProperty.call(firstVariant, catKey)) {
          const categoryF = firstVariant[catKey];
          for (const attr in categoryF) {
            if (Object.prototype.hasOwnProperty.call(categoryF, attr)) {
              const element = categoryF[attr];
              const secondV = this.returnValueForSecondKey(catKey, attr);
              if (secondV != '-') {
                if (secondV == element.AttributeValue) {
                  delete categoryF[attr];
                }
              }
              // console.log('element', catKey, element);
            }
          }
          if (Object.keys(firstVariant[catKey]).length === 0) {
            delete firstVariant[catKey];
          }
        }
      }
      console.log('this.compareData ------>', this.compareData);
    } else {
      this.compareData = JSON.parse(this.copyCompareData);
    }
  }
}
