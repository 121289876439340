<!--maincontainerrightin-->
<div class="maincontainerrightin  new-accessrize-details-main-container">
  <!--title-->
  <div class="fg variantlisttitle ascategorytitle">
    <span (click)="goBack()"
      ><img src="./assets/images/back-category-icon.svg" alt="img" /></span
    ><span style="text-transform: uppercase"
      >{{ params?.category }} ACCESSORIES</span
    >
  </div>
  <!--title-->
  <!--category-->
  <div class="fg accessrizelistspace">
    <!--sub category list-->
    <div class="fg accessorieslistdropdown">
      <div class="fg detailforminfoRow">
        <div class="floating-label coobjectinputlable2">
          <div class="custom-select mngpaym">
            <select [(ngModel)]="selectedGroup" (change)="subgroupChange()">
              <option *ngFor="let item of subGroup" [value]="item.subgroup_id">
                {{ item.subgroup_name }}
              </option>
            </select>
          </div>

          <label>Accessory Categories</label>
        </div>
      </div>
    </div>
    <div class="fg accessorieslist accessorieslistsc">
      <ul>
        <li *ngFor="let item of accList">
          <div class="accessoriesList">
            <div class="accessoriesListcheck">
              <div class="iuthis mngcheckBox">
                <input
                  id="{{ item.category + '_' + item.accessories_id }}"
                  type="checkbox"
                  [(ngModel)]="item.checked"
                  (ngModelChange)="accAddRemove(item)"
                  name="{{ item.category + '_' + item.accessories_id }}"
                  value="{{ item.category + '_' + item.accessories_id }}"
                />
                <label
                  for="{{ item.category + '_' + item.accessories_id }}"
                ></label>
              </div>
            </div>
            <div class="accessoriesListimg">
              <img
                [src]="accUrl + item.thumb_url"
                alt="img"
              />
            </div>
            <h2 class="fg accessoriesListinfo">
              {{ item.marketing_name
              }}<span *ngIf="!item.price.includes('xx')"
                ><i class="fa fa-rupee" aria-hidden="true"></i>
                {{ item.price }}</span
              >
            </h2>
          </div>
        </li>
      </ul>
    </div>
    <!--sub category list-->
  </div>
  <!--category-->
</div>
<!--button-->
<div class="variantlisttitlebtn">
  <div class="variantlisttitlebtnleft">
    <a
      href="javascript:void(0)"
      (click)="openLocModal()"
      class="variantlisttitlebtnleftbtn accessSummaryprice"
    >
      ₹{{ totalPrice }}
      <span
        >Ex-Showroom Price <b>{{ getStateValue() }}</b></span
      ></a
    >
    <!-- <a router class="variantlisttitlebtnleftbtn">Accessorize</a> -->
  </div>
  <div class="variantlisttitlebtnright">
    <a
      class="variantlisttitlebtnrightbtn"
      href="javascript:void(0)"
      (click)="gotoSummaryPage()"
      >Summary</a
    >
  </div>
</div>
<!--button-->
<app-overlapping-modal *ngIf="isOverlappingModal" (dismiss)="onModalDismiss($event)"></app-overlapping-modal>