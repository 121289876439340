<!--maincontainerrightin-->
<div class="maincontainerrightin new-accessrize-main-container" *ngFor="let pack of limitedEditionData">
  <!--title-->
  <div class="variantlisttitle variantlisttitle2 gamer-editiond-title">
    <div class="gamer-editiond-title-in">
      <div class="gamer-editiond-left">

        <button (click)="backToHome()" aria-label="Back to Home">
          <span><img src="./assets/images/red-arow-leftdir.svg" alt="img" /></span>
          <span>{{pack.pack_name}}</span>
        </button>

      </div>

      <div class="gamer-editiond-right">
        <label class="switch">
          <input (ngModelChange)="toggleGamerEdition($event)" [ngModel]="limitedEdition" type="checkbox">
          <span class="sliding round"></span>
        </label>
      </div>
    </div>
  </div>
  <!--title-->

  <div class="fg accessrizelistspace accessrizelist">

    <!--listing-->
    <div class="fg gameredition-listing" *ngIf="limitedEdition">
      <ul>
        <li *ngFor="let item of pack.accessoriesList">
          <div class="gameredition-listing-inner">
            <div class="gameredition-img"><img [src]="accUrl + item.thumb_url" alt="img" /></div>
            <div class="gameredition-info">{{item.marketing_name}}
              <div class="gameredition-infobtn">
                <button class="oncarview-button" (click)="onCarView(item)">View</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--listing-->

    <div class="view-gamer-edition" *ngIf="!limitedEdition">
      Turn the toggle on on top right to view <span>Gamer Edition Accessories.</span>
    </div>

    <div class="buttonaddacc">
      <a (click)="gotoAccessories()" class="more-accessories-button"> ADD MORE ACCESSORIES </a>
    </div>

    <!--bottom space-->
    <div class="gameredition-space"></div>
    <!--bottom space-->

  </div>

</div>
<div class="maincontainerrightin new-accessrize-main-container" *ngIf="limitedEditionData?.length==0">
  <!--title-->
  <div class="variantlisttitle variantlisttitle2 gamer-editiond-title">
    <div class="gamer-editiond-title-in">
      <div class="gamer-editiond-left">

        <button (click)="backToHome()" aria-label="Back to Home">
          <span><img src="./assets/images/red-arow-leftdir.svg" alt="img" /></span>
          <span>Gamer Edition</span>
        </button>

      </div>

      <div class="gamer-editiond-right">
        <label class="switch">
          <input disabled (ngModelChange)="toggleGamerEdition($event)" [ngModel]="limitedEdition" type="checkbox">
          <span class="sliding round"></span>
        </label>
      </div>
    </div>
  </div>
  <!--title-->

  <div class="fg accessrizelistspace accessrizelist">

    <div class="view-gamer-edition">
      Gamer Edition not available on selected Color.
    </div>

    <div class="buttonaddacc">
      <a (click)="gotoAccessories()" class="more-accessories-button"> ADD MORE ACCESSORIES </a>
    </div>

    <!--bottom space-->
    <div class="gameredition-space"></div>
    <!--bottom space-->

  </div>

</div>
<!--maincontainerrightin-->


<!--button-->
<div class="variantlisttitlebtn variantlisttitlebtn-gebtn-hide" (click)="gotoSummary()">
    <div
      class="variantlisttitlebtnleft">
      <a
        href="javascript:void(0)"
        class="variantlisttitlebtnleftbtn"
        >Book Test Drive</a
      >
    </div>
    <div class="variantlisttitlebtnright">
      <a
        href="javascript:void(0)"
        class="variantlisttitlebtnrightbtn"
      > Keep me Informed
      </a>
    </div>
  </div> 

<!--button-->


<!--

    <div class="gamer-edition-left">
        <div class="variantlisttitle variantlisttitle2">
        <button (click)="backToHome()" mat-icon-button aria-label="Back to Home">
            <mat-icon class="icon-back-mat">chevron_left</mat-icon>
        </button>
        <span>Gamer Edition</span>
        </div>
    </div> 
-->