import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IntroVideoComponent } from './intro-video/intro-video.component';
import { SoundCheckComponent } from './sound-check/sound-check.component';
import { SummaryComponent } from './three-d-module/summary/summary.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/sound-check',
    pathMatch: 'full',
  },
  {
    path: 'sound-check',
    component: SoundCheckComponent,
  },
  {
    path: 'intro',
    component: IntroVideoComponent,
  },
  {
    path: 'summary',
    component: SummaryComponent,
  },
  // {
  //   path: 'car',
  //   loadChildren: () =>
  //     import('./three-d-module/three-d-module.module').then(
  //       (m) => m.ThreeDModuleModule
  //     ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
