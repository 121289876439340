import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/service/global.service';
import * as moment from 'moment';
import { EventService } from 'src/app/service/event.service';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/service/data.service';
import { GaService } from 'src/app/service/ga.service';

@Component({
  selector: 'app-appointment-success',
  templateUrl: './appointment-success.component.html',
  styleUrls: ['./appointment-success.component.scss'],
})
export class AppointmentSuccessComponent implements OnInit {
  @Output() onClose: EventEmitter<string> = new EventEmitter();
  @Output() connectNowClicked: EventEmitter<string> = new EventEmitter();
  @Output() onReschedule = new EventEmitter();
  @Input() appointmentData:any;
  @Input() showRescheduleBtn:boolean;
  appointmentDate: Date;
  $dataTransfer: Subscription;
  showConnectButton: boolean;
  loadedCar: string;

  constructor(
    private _global: GlobalService,
    private _event:EventService,
    private _data:DataService,
    private _ga:GaService,
  ) {}

  ngOnInit(): void {
    this._data.dismissUIBlockLayer();
    this.loadedCar = this._data.getLocalCarBrand();
    this.appointmentDate = moment(this.appointmentData.appoinment_date + ' ' + this.appointmentData.appoinment_timing).toDate();
    setTimeout(() => {
      this.$dataTransfer = this._event.subscribe('show_connect', (data) => {
        this.showConnectButton = data;
      });
    }, 10);
  }
  
  ngOnDestroy(): void {
    if(this.$dataTransfer){
      this.$dataTransfer.unsubscribe();
    }
  }
  onCloseClick(label) {
    this._ga.sendGAEvent('Schedule Call', 'click', label);
    window.sendLiveDashboardData({
      name: 'Continue Browsing',
      category: 'pulsehub icon_continuebrowsing',
      type: 'MG_eXpert',
    });
    this.onClose.emit('close');
  }
  onRescheduleClick() {
    this._ga.sendGAEvent('Pulsehub Icon', 'click', 'Reschedule');
    window.sendLiveDashboardData({
      name: 'Reschedule',
      category: 'pulsehub',
      type: 'MG_eXpert',
    });
    this.onReschedule.emit();
  }

  public onExploringBtnClick(){
    this._global.updateAppointmentSuccessModal(false);
  }

  connectNow(){
    this._ga.sendGAEvent("Pulsehub Icon","click","Connect Now");
    window.sendLiveDashboardData({
      name: 'Connect Now',
      category: 'pulsehub icon_connectnow',
      type: 'MG_eXpert',
    });
    this.connectNowClicked.emit();
  }
}
