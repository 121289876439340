import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { DataService } from 'src/app/service/data.service';
import { GlobalService } from 'src/app/service/global.service';
import { EventService } from 'src/app/service/event.service';
import { SnackbarService } from '../../service/snackbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-protection-plan',
  templateUrl: './protection-plan.component.html',
  styleUrls: ['./protection-plan.component.scss'],
})
export class ProtectionPlanComponent implements OnInit {
  protectionPlanList: any = [];
  threeYearsProtection: any = [];
  fiveYearsProtection: any = [];
  tabName: string;
  tabDescription: string = null;
  protectionTap: Array<boolean> = [true, false];

  @Output() closeBtnClickOnProtectionPlanModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() closeBtnClickOnProtectionPlanModal2: EventEmitter<any> =
    new EventEmitter<boolean>();
  planAdded: boolean;
  $dataTransfer: Subscription;
  ACCESS: boolean;
  callConnected: boolean;

  constructor(
    private globalService: GlobalService,
    private dataService: DataService,
    private _snack: SnackbarService,
    private _api: ApiService,
    private _event: EventService
  ) {}

  ngOnInit(): void {
    this.globalService.accessUi.subscribe((data) => (this.ACCESS = data));
    this.globalService.audioStatus.subscribe(
      (data) => (this.callConnected = data)
    );
    this.receiveDataTransfer();
    this.getProtectionPlansList();
    window.sendData({
      eName: 'form_view',
      eCat: 'protection_plan',
      eAct: '',
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.$dataTransfer.unsubscribe();
    window.sendData({
      eName: 'click',
      eCat: 'protection_plan',
      eAct: 'close_button_clicked',
    });
  }
  /**
   * get the list of protection Plans from server
   */
  getProtectionPlansList() {
    this.globalService.showLoading(true);
    let selectedPlan = sessionStorage.getItem('selectedProtectionPlan');
    let selectedPlanType = sessionStorage.getItem('selectedProtectionPlanType');
    let apiData = {
      source: 'web',
      pin_no: this.dataService.getLocalPinNo(),
    };
    this._api.getProtectionPlansList(apiData).subscribe(
      (data: any) => {
        console.log('getProtectionPlansList data', data);
        if (data.status == 1) {
          this.protectionPlanList = data.response;
          if (this.protectionPlanList && this.protectionPlanList.length > 0) {
            if (selectedPlanType) {
              this.tabName = selectedPlanType;
            } else {
              this.tabName = data.response[0].plan_name;
            }
            if (selectedPlan) {
              let id = JSON.parse(selectedPlan).plan_id;
              this.protectionPlanList.forEach((elem) => {
                if (elem.plans && elem.plans.length > 0) {
                  elem.plans.forEach((e) => {
                    if (e.plan_id == id) {
                      e['is_added'] = true;
                      e['pro_plan_type'] = elem.plan_name;
                    } else {
                      e['is_added'] = false;
                      e['pro_plan_type'] = elem.plan_name;
                    }
                  });
                }
              });
            } else {
              this.protectionPlanList.forEach((elem) => {
                if (elem.plans && elem.plans.length > 0) {
                  elem.plans.forEach((e) => {
                    e['is_added'] = false;
                    e['pro_plan_type'] = elem.plan_name;
                  });
                }
              });
            }

            this.tabDescription = this.protectionPlanList[0].plan_description;
            this.filterPlans(this.protectionPlanList[0].plans);
            console.log('getProtectionPlansList data', this.protectionPlanList);
          }
          this.globalService.showLoading(false);
        } else {
          this.protectionPlanList = [];
          this.globalService.showLoading(false);
        }
      },
      (error) => {
        this.globalService.showLoading(false);
        this.protectionPlanList = [];
      }
    );
  }
  /**
   * filters the array into 3 years and 5 years
   * @param mainArr array of objects which has plan details
   */
  filterPlans(mainArr) {
    this.threeYearsProtection = [];
    this.fiveYearsProtection = [];

    mainArr.forEach((elem) => {
      if (elem.year == '3 years') {
        this.threeYearsProtection.push(elem);
      } else if (elem.year == '5 years') {
        this.fiveYearsProtection.push(elem);
      }
    });
  }
  /**
   *
   * @param index index of selected protection plan
   * @param tabName name of tab which is selected
   */
  onClickProtectionPlanTap(index, tabName) {
    this.protectionTap.forEach((item, ind) => {
      if (ind == index) {
        this.protectionTap[ind] = true;
      } else {
        this.protectionTap[ind] = false;
      }
    });
    this.tabDescription = this.protectionPlanList[index].plan_description;
    this.filterPlans(this.protectionPlanList[index].plans);
  }
  /**
   * when user added the Protection Plan
   * @param id id of clicked Protection Plan
   * @param type type of protection Plan
   */
  onClickAddToPlan(id, type) {
    id.is_added = true;
    sessionStorage.setItem('selectedProtectionPlan', JSON.stringify(id));
    if (type == '3') {
      this.threeYearsProtection.forEach((element) => {
        if (element.plan_id == id.plan_id) {
          element['is_added'] = true;
        } else {
          element['is_added'] = false;
        }
      });
    } else if (type == '5') {
      this.fiveYearsProtection.forEach((element) => {
        if (element.plan_id == id.plan_id) {
          element['is_added'] = true;
        } else {
          element['is_added'] = false;
        }
      });
    }

    this.protectionPlanList.forEach((elem) => {
      if (elem.plans && elem.plans.length > 0) {
        elem.plans.forEach((e) => {
          if (e.plan_id != id.plan_id) {
            e['is_added'] = false;
          }
        });
      }
    });
    this.planAdded = true;
    window.sendData({
      eName: 'click',
      eCat: 'protection_plan',
      eAct: 'add_to_build_button_clicked',
      eval: id.plan_id,
    });
  }
  /**
   * when user remove the Protection Plan
   * @param id id of clicked Protection Plan
   * @param type type of protection Plan
   */
  onClickRemoveToPlan(id, type) {
    sessionStorage.removeItem('selectedProtectionPlan');
    if (type == '3') {
      this.threeYearsProtection.forEach((element) => {
        element['is_added'] = false;
      });
    } else if (type == '5') {
      this.fiveYearsProtection.forEach((element) => {
        element['is_added'] = false;
      });
    }

    window.sendData({
      eName: 'click',
      eCat: 'protection_plan',
      eAct: 'remove_from_build_button_clicked',
      eval: id,
    });
  }
  /**
   * close Protection Plan popup
   */
  closeProtectionPlanModal() {
    console.log('close');
    if (this.planAdded) {
      // this._matSnackBar.open('Your Shield plan is saved.', '', {
      //   duration: 5000,
      // });
      this._snack.openSnackBar('Your Protection plan is changed.', 'Success');
    }
    this.closeBtnClickOnProtectionPlanModal.emit(false);
    this.closeBtnClickOnProtectionPlanModal2.emit(this.tabName);
  }

  receiveDataTransfer() {
    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      let keys = Object.keys(data);
      let key = keys[0];
      console.log('dataTransfer111', data);
      switch (key) {
        case 'closeProtectionPlanPage':
          this.closeProtectionPlanModal();
          break;
        case 'tabChangeForPP':
          let index = data[key];
          this.onClickProtectionPlanTap(index - 1, '');
          break;
        case 'addProtectionPlans':
          console.log('data[key]', data[key]);
          this.selectProtectPlan(data[key]);
          // let data = data[key];
          // this.onClickProtectionPlanTap(index, '');
          break;
        case 'removeProtectionPlans':
          console.log('data[key]', data[key]);
          this.removeSocketProtectPlan(data[key]);
          // let data = data[key];
          // this.onClickProtectionPlanTap(index, '');
          break;
        default:
          break;
      }
    });
  }
  selectProtectPlan(ppData: any) {
    let filetredD = null;
    let selectedY = 0;
    console.log(this.threeYearsProtection);
    console.log(this.fiveYearsProtection);

    filetredD = this.threeYearsProtection.find(
      (ele) => ele.plan_id == ppData.plan_id
    );
    if (filetredD) {
      selectedY = 3;
    }
    if (!filetredD) {
      filetredD = this.fiveYearsProtection.find(
        (ele) => ele.plan_id == ppData.plan_id
      );
      if (filetredD) {
        selectedY = 5;
      }
    }
    var objDiv = document.getElementById('scrollD');
    if (selectedY == 5) {
      objDiv.scrollTop = objDiv.scrollHeight;
      // document.getElementById("scrollD").scrollTo
    } else {
      objDiv.scrollTop = 0;
    }
    console.log(filetredD);
    console.log(selectedY);

    this.onClickAddToPlan(filetredD, selectedY);
  }
  removeSocketProtectPlan(ppData: any) {
    let filetredD = null;
    let selectedY = 0;

    filetredD = this.threeYearsProtection.find(
      (ele) => ele.plan_id == ppData.plan_id
    );
    if (filetredD) {
      selectedY = 3;
    }
    if (!filetredD) {
      filetredD = this.fiveYearsProtection.find(
        (ele) => ele.plan_id == ppData.plan_id
      );
      if (filetredD) {
        selectedY = 5;
      }
    }

    this.onClickRemoveToPlan(filetredD.plan_id, selectedY);
  }
}
