<div class="maincontainerrightin new-self-explore-main-container">
  <!--title-->
  <div class="fg variantlisttitle variantlisttitlehome">
    <button mat-icon-button aria-label="Back to Home" (click)="goBack()">
      <mat-icon class="icon-back-mat">chevron_left</mat-icon>
    </button>
    <span>SELF EXPLORE</span>
  </div>
  <!--title-->
  <!--VARIANTS-->
  <div class="fg accessrizelist activeColorspace">
    <div class="fg questionList mngselfexplore">
      <!--fe-list-->
      <!-- <ul>
        <li>
          <a href="javascript:void(0)" (click)="gotoVariant()"
            ><div class="questionListOuter">
              <div class="questionListOuterleft">
                <img src="./assets/images/home-best-icon.svg" alt="img" />
              </div>
              <div class="questionListOuterright">
                Explore Features
                <span>Sit Back & Relax as we walk you through the Astor. </span>
              </div>
            </div></a
          >
        </li>
      </ul> -->
      <!--fe-list-->
      <!--Explore Features-->
      <ul>
        <li [ngClass]="{ active: isOpenExploreFeature }">
          <a
            href="javascript:void(0)"
            (click)="isOpenExploreFeature = !isOpenExploreFeature"
            ><div class="questionListOuter">
              <div class="questionListOuterleft">
                <img src="./assets/images/home-self-icon.svg" alt="img" />
              </div>
              <div class="questionListOuterright">Explore Features</div>
            </div></a
          >
        </li>

        <div class="fg ExploreFeatures" *ngIf="isOpenExploreFeature">
          <li *ngFor="let item of featureCatList; let ind = index">
            <a
              href="javascript:void(0)"
              (click)="applyFeatureList(item.feature_story_id);logGA(item)"
            >
              <div class="ExploreFeaturesOuter">
                <div class="fg ExploreFeaturesinfo">
                  <div class="ExploreFeaturesinfoCount">{{ ind + 1 }}.</div>
                  {{ item.feature_story_name }}
                </div>
              </div>
            </a>
          </li>
        </div>
      </ul>
      <!--Explore Features-->
    </div>
  </div>
  <!--VARIANTS-->
</div>
<!--button-->
<div class="variantlisttitlebtn">
  <div class="variantlisttitlebtnleft">
    <a
      href="javascript:viod(0)"
      (click)="gotoAccPage()"
      class="variantlisttitlebtnleftbtn"
      >Variants</a
    >
  </div>
  <div class="variantlisttitlebtnright">
    <a
      href="javascript:viod(0)"
      (click)="gotoSummaryPage()"
      class="variantlisttitlebtnrightbtn"
    >
      Summary
    </a>
  </div>
</div>
<!--button-->
