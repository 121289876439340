import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var window

@Injectable({
  providedIn: 'root',
})
export class GaService {
  GA4_ID:string = '';
  constructor() {}

  public sendGAEvent(category: string, action: string, label: string) {
    console.log('GA EVE LOG::', category, action, label);
    if (typeof window.gtag == 'function' ) {
      // (<any>window)._gaq.push(['_trackEvent', category, action, label]);
      window.gtag('event', category, {[action]: label }); 
    }else{
      console.error('Ga not init yet',category, action, label);
    }
  }

  addGAscript(loadedCar){
    let checkScript = document.getElementById("GA_SCRIPT");
    if(!checkScript){
      switch (loadedCar) {
        case 'kmodel':
          this.GA4_ID = environment.ASTOR_GA4;
          break;
        case 'hectornew':
          this.GA4_ID = environment.HECTOR_NEW_GA4;
          break;
        case 'hectorplusnew':
          this.GA4_ID = environment.HECTOR_PLUS_NEW_GA4;
          break;
        case 'zsev':
          this.GA4_ID = environment.ZSEV_GA4;
          break;
        case 'gloster':
          this.GA4_ID = environment.GLOSTER_GA4;
          break;
        case 'emodel':
          this.GA4_ID = environment.COMET_GA4; // need id for e model
          break;
      
        default:
          alert("GA tag missing for selected car");
          break;
      }
      var gtagScript = document.createElement('script');
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.GA4_ID;
      gtagScript.async = true;
      gtagScript.id = "GA_SCRIPT"
      document.getElementsByTagName('head')[0].appendChild(gtagScript);
      var gtagScript2 = document.createElement('script');
      gtagScript2.innerText = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${this.GA4_ID}'); 
      `;
      document.getElementsByTagName('head')[0].appendChild(gtagScript2);
    }else{
      console.log('script already added',this.GA4_ID);
    }
    
  }
}
