import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import {
  GlobalService,
  glosterSeating,
  hectorPlusSeating,
} from '../../service/global.service';
import { audioStatus } from '../../service/global.service';
import { SocketService } from '../../service/socket.service';
import { CallService } from '../../service/call.service';
import { defaultCarDetails } from '../carSettings';
import { BehaviorSubject, Subscription } from 'rxjs';
import { KModelComponent } from '../k-model/k-model.component';
import { EventService } from 'src/app/service/event.service';
declare var ONE3D: any;
declare var $: any;
@Component({
  selector: 'app-single-feature-play',
  templateUrl: './single-feature-play.component.html',
  styleUrls: ['./single-feature-play.component.scss'],
})
export class SingleFeaturePlayComponent implements OnInit {
  globalPinNO: string;
  isCarDoorOpen: boolean;
  ACCESS: boolean;
  selectedVariant: import('../../service/global.service').variant;
  loadedCar: string;
  featuresList: any[] = [];
  selectedFeature: any;
  isFeatureSelected: boolean;
  featureNextDisable: boolean;
  featurePreviousDisable: boolean;
  featureAnimationPlaying: boolean;
  selectedFeatureIndex: number = 0;
  audioOliviaStatus: audioStatus;
  showEndPopup: boolean = false;
  variantList: any[];
  selectedColor: any;
  colorList: any[];
  audioObserver$: Subscription;
  currentFeatureStory: any;
  totalNoStory: any;
  queryParams: any;
  $dataTransfer: Subscription;
  callConnected: boolean;
  $observerAudio: Subscription;
  sunRoofIsOpen: boolean;
  headLampIsOpen: boolean;
  hectorSeatingOptions: hectorPlusSeating | glosterSeating;
  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _callS: CallService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private location: Location,
    private _event: EventService
  ) {}

  ngOnInit(): void {
    window['featureRef'] = {
      zone: this.zone,
      one3dcallback: {
        onFeatureComplete: () => this.onFeatureComplete(),
        onCarLoadComplete: () => this.onCarLoadComplete(),
        onVariantLoadComplete: () => this.onVariantChangeComplete(),
      },
      component: this,
    };
    this.route.queryParams.subscribe((data) => {
      if (data && data.goto) {
        this.queryParams = data;
      }
    });
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.globalPinNO = data));
    this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data));
    this._global.sunRoofIsOpen.subscribe((data) => (this.sunRoofIsOpen = data));
    this._global.headLampIsOpen.subscribe(
      (data) => (this.headLampIsOpen = data)
    );
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.variantList.subscribe((data) => (this.variantList = data));
    this._global.colorList.subscribe((data) => (this.colorList = data));
    this._global.selectedColor.subscribe((data) => (this.selectedColor = data));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this.$observerAudio = this._global.audioStatus.subscribe((data) => {
      this.callConnected = data;
      if (!data) {
        this.location.back();
      }
    });
    this._global.hectorSeatingOptions.subscribe(
      (data) => (this.hectorSeatingOptions = data)
    );
    this.getFeatureList();
    console.log('this.hectorSeatingOptions===>', this.hectorSeatingOptions);

    if (!this.hectorSeatingOptions) {
      if (
        this.selectedVariant.d_name !=
          defaultCarDetails[this.loadedCar].topVar &&
        window.hasOwnProperty('ONE3D') &&
        ONE3D.loadedPercent == 100
      ) {
        let selectedV = null;
        if (this.loadedCar == 'hectorplus') {
          selectedV = this.variantList.find(
            (ele) => ele.is_default == 1 && ele.seats_no == '6S'
          );
          this._global.updateHectorSeatingOption(hectorPlusSeating.sixSeater);
        } else if (this.loadedCar == 'zsev') {
          console.log('Logged 1--->');
          ONE3D.removeAllAccessories();
          this.getFeatureList();
          // selectedV = this.variantList.find(
          //   (ele) => ele.variant_id == this.selectedVariant.variant_id
          // );
        } else {
          selectedV = this.variantList.find((ele) => ele.is_default == 1);
        }
        if (selectedV) {
          this.onVariantSelect(selectedV);
        }
      } else {
        ONE3D.removeAllAccessories();
      }
    } else {
      let seatOp = this.hectorSeatingOptions + 'S';
      let filteredVariant = this.variantList.filter(
        (ele) => ele.seats_no == seatOp && ele.is_default == 1
      );
      if (
        filteredVariant.length > 0 &&
        this.selectedVariant.variant_id != filteredVariant[0].variant_id
      ) {
        this.onVariantSelect(filteredVariant[0]);
      }
    }
    // if (
    //   this.selectedVariant.d_name != defaultCarDetails[this.loadedCar].topVar &&
    //   window.hasOwnProperty('ONE3D') &&
    //   ONE3D.loadedPercent == 100
    // ) {
    //   let selectedV = this.variantList.find((ele) => ele.is_default == 1);
    //   if (selectedV) {
    //     this.onVariantSelect(selectedV);
    //   }
    // } else {
    //   ONE3D.removeAccessories();
    // }
    this.registerSocket();
  }

  ngOnDestroy(): void {
    window['featureRef'] = null;
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    this._global.updateImageFeatureList([]);
    this.$dataTransfer.unsubscribe();
    this.$observerAudio.unsubscribe();
    ONE3D.exitFeatureView();
  }

  onVariantChangeComplete() {
    // throw new Error('Method not implemented.');
    // ONE3D.loadedPercent = 100;
    document.getElementById('variantLoader').style.display = 'none';
    console.log('this.selectedColor --->', this.selectedColor);

    ONE3D.changeColor(this.selectedColor.hex_code);
    window.updatePayloadParameter('eLa3', this.selectedColor.color_name);

    console.log('Change variant compltete');
    // setTimeout(() => {
    //   if (this.queryParams.hasOwnProperty('goto')) {
    //     // this.getVariantListByStoryId();
    //   } else {
    //     this.getFeatureList();
    //   }
    // }, 1000);
    if (this.callConnected) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        variantLoadCompleteOnClient: null,
      });
    }
  }
  onFeatureComplete() {
    console.log('afterrrr complt');
    this.featureAnimationPlaying = false;
    if (
      this.audioOliviaStatus == audioStatus.stop &&
      !this.featureAnimationPlaying
    ) {
      console.log('Both completed, 1st audio and then feature');
      setTimeout(() => {
        this.gotoNextFeature();
      }, 4000);
    }
    // if (this.selectedFeatureIndex + 1 < this.featuresList.length) {
    //   this.onSelectFeature(this.featuresList[this.selectedFeatureIndex + 1]);
    // }
  }
  onCarLoadComplete() {
    if (this.featuresList.length > 0) {
      setTimeout(() => {
        // this.onSelectFeature(this.featuresList[0]);
        console.log('car load Complete');
      }, 2000);
    }
  }

  async getFeatureList() {
    try {
      let variantId: number = this.selectedVariant.variant_id;

      // if (this.loadedCar === 'zsev') {
      //   variantId = this.selectedVariant.variant_id;
      // } else {
      //   variantId = defaultCarDetails[this.loadedCar].variant_id;
      // }
      let apiBody = {
        model_id: defaultCarDetails[this.loadedCar].model_id,
        variant_id: variantId,
        source: 'web',
        seats: this.hectorSeatingOptions ? `${this.hectorSeatingOptions}S` : '',
      };
      let apiResp: any = await this._api.getFeatureList(apiBody);
      if (apiResp.status == 1) {
        console.log(apiResp);
        this.totalNoStory = apiResp.response.total_feature_story;
        this.currentFeatureStory = apiResp.response.currant_feature_story_id;
        this.featuresList = apiResp.response.all;
        console.log('this.featuresList', this.featuresList);
        // this.selectedFeature = this.featuresList[0];
      }
    } catch (error) {
      console.error(error);
    }
  }

  onSelectFeature(feature) {
    if (this.isCarDoorOpen) {
      // AnimationManager.animmanager.doorAnimate();
      this._global.updateDoorState(false);
    }
    this.selectedFeature = feature;
    this.isFeatureSelected = true;
    let indx = this.featuresList.findIndex(
      (ele) => ele.feature_id == this.selectedFeature.feature_id
    );
    this.selectedFeatureIndex = indx;
    this._global.updateSoundTrackURL(this.selectedFeature.audio_path);
    if (indx == 0) {
      this.featurePreviousDisable = true;
    } else if (indx == this.featuresList.length) {
      this.featureNextDisable = true;
    }
    // this.featureClickCount(feature.feature_id);
    this._global.updateAccCam(true);
    this.playFeatureAnimation();
    // this.gaScript.sendGAEvent('features', feature.name, 'click');
    // this.saveCustomerJourny('view_feature');
    // AnimationManager.animmanager.feature_Selected(feature.hotspot);
  }

  playFeatureAnimation() {
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    this._global.updateImageFeatureList([]);
    if (this.selectedFeature.type == 'threeD') {
      ONE3D.playFeature(this.selectedFeature.hotspot);
      this.featureAnimationPlaying = true;
      console.log(this.featureAnimationPlaying);
    } else if (this.selectedFeature.type == 'video') {
      // TODO Enable Video Div
      if (this._global.detectMob) {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      } else {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      }
      setTimeout(() => {
        document.getElementById('featureVideoFullScreen').style.display =
          'block';
      }, 100);
    } else if (this.selectedFeature.type == 'threeD+Image') {
      // TODO Enable Image top Div
      ONE3D.playFeature(this.selectedFeature.hotspot);
      // AnimationManager.animmanager.feature_Selected(
      //   this.selectedFeature.hotspot
      // );

      this.featureAnimationPlaying = true;
      if (this._global.detectMob) {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      } else {
        this._global.updateOverlaySrc(this.selectedFeature.desktop_image_video);
      }

      setTimeout(() => {
        document.getElementById('featureImagePopup').style.display = 'block';
      }, 100);
    } else if (this.selectedFeature.type == 'image') {
      // TODO Enable Image fullscreen Div
      console.log('image Feature', this._global.detectMob);

      if (this._global.detectMob) {
        this._global.updateOverlaySrc(this.selectedFeature.mobile_image_video);
      } else {
        this._global.updateOverlaySrc(this.selectedFeature.desktop_image_video);
      }
      setTimeout(() => {
        document.getElementById('featureImageFullScreen').style.display =
          'block';
      }, 100);
    } else if (this.selectedFeature.type == 'carousel') {
      this._global.updateImageFeatureList(this.selectedFeature.carousel_imgage);
      document.getElementById('carusleFeature').style.display = 'block';
      setTimeout(() => {
        $('.topslider').slick({
          lazyLoad: 'ondemand',
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
          dots: true,
        });
      }, 500);
    }
  }

  gotoNextFeature() {
    document.getElementById('featureVideoFullScreen').style.display = 'none';
    document.getElementById('featureImageFullScreen').style.display = 'none';
    document.getElementById('featureImagePopup').style.display = 'none';
    document.getElementById('carusleFeature').style.display = 'none';
    // let indx = this.featuresList.findIndex(
    //   (ele) => ele.feature_id == this.selectedFeature.feature_id
    // );
    // console.log(indx);
    if (this.selectedFeatureIndex >= 0) {
      if (this.selectedFeatureIndex + 1 == this.featuresList.length) {
        this.featureNextDisable = true;
        console.log('Ended All');
        ONE3D.event.exitFeatureView();
        this._global.updateAccCam(false);
        if (this.currentFeatureStory == this.totalNoStory) {
          let url = defaultCarDetails[this.loadedCar].urlScheme;
          if (this.loadedCar == 'kmodel') {
            this.router.navigate([`${url}/home`]);
          } else {
            this.router.navigate([`${url}/variants`]);
          }
        } else {
          this._global.toggleFeatureCompletePopup(true);
        }
      } else {
        this.featurePreviousDisable = false;
        // this.selectedFeature = this.featuresList[this.selectedFeatureIndex + 1];
        this.onSelectFeature(this.featuresList[this.selectedFeatureIndex + 1]);
        // this.playFeatureAnimation();
        // this.featureClickCount(indx + 1);
        if (this.selectedFeatureIndex + 2 == this.featuresList.length) {
          this.featureNextDisable = true;
        }
      }
    }
  }

  progressBarLength() {
    let ind = this.selectedFeatureIndex + 1;
    return (ind / this.featuresList.length) * 100;
  }

  public playMoreFeature() {
    console.log('Playing new set');
  }

  onVariantSelect(variant: any) {
    console.log('###  >>> variant data >', variant);
    if (this.selectedVariant.variant_id !== variant.variant_id) {
      console.log('Inner');
      document.getElementById('variantLoader').style.display = 'block';
      this._global.showLoading(true);
      // this.selectedVariant = variant;
      this._data.setLocalVariant(variant.variant_id);
      // localStorage.setItem('selectedVariant', variant.variant_id);
      this._global.updatedVariant(variant);
      this._global.updatedPrice(variant.price);
      setTimeout(() => {
        ONE3D.changeVariant(variant.d_name, { showFeature: true });
        ONE3D.removeAllAccessories();
      }, 1000);

      sessionStorage.removeItem('selectedColorInt');
      if (this.isCarDoorOpen) {
        this._global.updateDoorState(false);
        defaultCarDetails[this.loadedCar].component.defaultCmp.isCarDoorOpen =
          false;
      }
      if (this.ACCESS) {
        this._socket.emitChangeVariant(variant);
      }

      this.getVariantDetails();
      // this.gaScript.sendGAEvent('view variants', variant.title, 'click');
    }
  }

  async getVariantDetails() {
    try {
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this._data.getLocalVariant()
          ? this._data.getLocalVariant()
          : defaultCarDetails[this.loadedCar].variant_id,
        source: 'web',
      };
      let apiResp: any = await this._api.getVariantDetails(apiData);
      if (apiResp.status === 1) {
        // this.colorList = apiResp.response.varinat_color;
        this._global.updateColorList(apiResp.response.varinat_color);
        this._global.updateAmbientColorList(apiResp.response.ambient_light);
        this.setSelectedColor();
      }
    } catch (error) {
      console.error(error);
    }
  }
  setSelectedColor() {
    let selectedColorCode = this._data.getLocalColor();
    console.log('selectedColorCode', selectedColorCode);

    if (selectedColorCode) {
      let foundColorItem = this.colorList.filter(
        (ele) => ele.variant_feature_id == selectedColorCode
      );
      if (foundColorItem.length > 0) {
        // this.selectedColor = foundColorItem[0];
        this._global.updateSelectedColor(foundColorItem[0]);
        this._data.setLocalColor(foundColorItem[0].variant_feature_id);
      } else {
        console.log('foundColorItem222', this.colorList[0]);
        // this.selectedColor = this.colorList[0];
        let filteredD = this.colorList.find((ele) => ele.default == 1);
        if (filteredD) {
          this._global.updateSelectedColor(filteredD);
          this._data.setLocalColor(filteredD.variant_feature_id);
        }
      }
    } else {
      console.log('foundColorItem333', this.colorList[0]);
      // this.selectedColor = this.colorList[0];
      let filteredD = this.colorList.find((ele) => ele.default == 1);
      if (filteredD) {
        this._global.updateSelectedColor(filteredD);
        this._data.setLocalColor(filteredD.variant_feature_id);
      }
    }
    this.changeColoredVariant('yes');
  }
  async changeColoredVariant(flag = '') {
    try {
      let locationInfo = JSON.parse(localStorage.getItem('location_info'));
      this._global.showLoading(true);
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this.selectedVariant.variant_id,
        variant_feature_id: this.selectedColor.variant_feature_id,
        state: locationInfo ? locationInfo.state : 'Delhi',
        city: locationInfo ? locationInfo.city : 'Delhi',
        change_variant: flag,
        source: 'web',
        interior_color_name: localStorage.getItem('selectedColorInt')
          ? 'Champagne & Black'
          : 'Black',
        page: 'feature',
      };
      let apiResp: any = await this._api.checkVariantColorSelection(apiData);
      if (apiResp.status == 1) {
        this._global.updatedPrice(apiResp.response.showroom_price);
        if (apiResp.response && apiResp.response.variant_feature_id) {
          if (apiResp.response.hasOwnProperty('color_category')) {
            if (apiResp.response.color_category == 'interior') {
              localStorage.setItem(
                'selectedColorInt',
                apiResp.response.variant_feature_id
              );
            } else if (apiResp.response.color_category == 'exterior') {
              // let selectdC = this.colorList.filter(
              //   (ele) =>
              //     ele.variant_feature_id == apiResp.response.variant_feature_id
              // );
              // this.selectedColor = selectdC[0];
              // console.log('this.selectedColor', this.selectedColor);
              // this._data.setLocalColor(apiResp.response.variant_feature_id);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  registerSocket() {
    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      console.log('playFeature>>>>>>>>>>>', data);
      let keys = Object.keys(data);
      let key = keys[0];
      switch (key) {
        case 'playFeature':
          let featureId = data[key];
          this.selectedFeature = this.featuresList.find(
            (e) => e.feature_id == featureId
          );
          console.log('this.selectedFeature', this.selectedFeature);
          this.playFeatureAnimation();
          break;
        case 'resetFeatureCamera':
          ONE3D.exitFeatureView();
          this._global.updateImageFeatureList([]);
          this._global.updateOverlaySrc('');
          document.getElementById('featureVideoFullScreen').style.display =
            'none';
          document.getElementById('featureImageFullScreen').style.display =
            'none';
          document.getElementById('featureImagePopup').style.display = 'none';
          document.getElementById('carusleFeature').style.display = 'none';
          break;
        default:
          break;
      }
    });
  }

  goBack() {
    ONE3D.exitFeatureView();
    this._global.updateSoundTrackURL('');
    this._global.updateAccCam(false);
    this.location.back();
  }
}
