import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Howl, Howler } from 'howler';
import { SocketService } from '../../service/socket.service';
import { agentState, GlobalService } from '../../service/global.service';
import { DataService } from '../../service/data.service';
import { GaService } from '../../service/ga.service';
import { audioStatus } from '../../service/global.service';
import { SnackbarService } from '../../service/snackbar.service';
import { MgExpertService } from '../../service/mg-expert.service';
import {
  defaultCarDetails,
  getAssetsURL,
} from '../../three-d-module/carSettings';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/service/api.service';
import { EventService } from 'src/app/service/event.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var window;

@Component({
  selector: 'app-olivia-audio',
  templateUrl: './olivia-audio.component.html',
  styleUrls: ['./olivia-audio.component.scss'],
})
export class OliviaAudioComponent implements OnInit, OnDestroy {
  @Input() audioURL: string;
  @Input() isEnqFormOpen: boolean;
  @Output() audioComplete: EventEmitter<boolean> = new EventEmitter();
  sound: any;
  isPlaying: boolean;
  isSoundMuted: boolean;
  socketStatus: string;
  toggleWarnMsg: any;
  pinNo: string;
  loadedCar: string;
  isOpenPulseHubEnqForm: boolean;
  openExpandModule: any;
  isEnqGenerated: boolean;
  audioCallStatus: boolean;
  showAgentBusy: boolean;
  showToolTip: boolean = true;
  dealerDetails: any;
  showDownModal: boolean;
  agentDetails: any;
  showGranPermission: boolean;
  agentAvailable: import('../../service/global.service').agentState;
  showAgentTiming: boolean;
  isAppointmentFormOpen: boolean;
  isAppointmentSuccessModalOpen: boolean;
  subs: Subscription;
  startTime: any;
  endTime: any;
  fromOutTime: boolean;
  $dataTransfer: Subscription;
  show_Skip_Continue: boolean = false; //Flag to render buttons after 5 sec of video play @JP
  // audioStatus:import('../../service/global.service').audioStatus;
  videoOptions = {
    autoplay: true,
    controls: true, // changed from false ,
    fluid: true,
    // play :true,
    // pause: true,
    sources: [
      {
        src: 'assets/videos/MG EXPERT.mp4',
        type: 'video/mp4',
      },
    ],
  };
  disabledMicAccess: boolean = false;
  firstTimeVideoPlayed: boolean;
  eventFromRightPanel: boolean;
  isMobileDevice: boolean;
  hideSkipIcon: boolean = false;
  openAppointmentFromEnquiryForm: boolean;
  counter: any;
  isAppointmentRescheduleFormOpen: boolean;
  appointmentData: any;
  isReschedule: boolean;
  showRescheduleBtn: boolean;
  constructor(
    public _socket: SocketService,
    public _global: GlobalService,
    private _data: DataService,
    private _expert: MgExpertService,
    private _snack: SnackbarService,
    private _ga: GaService,
    private router: Router,
    private queryParams: ActivatedRoute,
    private _api: ApiService,
    private _event: EventService,
    private http: HttpClient
  ) {
    this._global.isSoundMuted.subscribe((data) => (this.isSoundMuted = data));
    this._global.audioStatus.subscribe((data) => {
      this.audioCallStatus = data;
      if (data) {
        this.showToolTip = false;
        this.openExpandModule = false;
        this.showAgentBusy = false;
        this._global.updateAgentBusyStatus(this.showAgentBusy);
        console.log("Updated status", this.showAgentBusy);
        if (this.isPlaying) {
          this.stopPlaying();
        }
      }
    });
    // this._global.audioStatus.subscribe((data) => (this.isPlaying = data));
    this._global.socketStatus.subscribe((data) => (this.socketStatus = data));
    this._global.isOpenPulseHubEnqForm.subscribe(
      (data) => (this.isOpenPulseHubEnqForm = data)
    );

    this._global.isEnqGenerated.subscribe((data) => {
      this.isEnqGenerated = data;
      if (data && !this.audioCallStatus) {
        this._global.updateSoundTrackURL('./assets/videos/wait_for_you.mp3');
      }
    });

    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.dealerDetailsForToken.subscribe(
      (data) => (this.dealerDetails = data)
    );
    this._global.appointmentForm.subscribe(
      (data) => (this.isAppointmentFormOpen = data)
    );
    this._global.appointmentRescheduleForm.subscribe(
      (data) => (this.isAppointmentRescheduleFormOpen = data)
    );
    this._global.showRescheduleBtnFlag.subscribe(
      (data) => (this.showRescheduleBtn = data)
    );

    this._global.appointmentSuccessModal.subscribe(
      (data) => (this.isAppointmentSuccessModalOpen = data)
    );
    this.isMobileDevice = this._global.detectMob();
  }

  ngOnInit(): void {

    this._global.agentAvailability.subscribe((s) => {
      this.agentAvailable = s;
      // alert(this.agentAvailable)
      // if (condition) {

      // }
      // this.openExpandModule = false;
      // this.toggleWarnMsg = false;
      // this.showAgentTiming = false;
      console.log('Agent State', s);
    });
    // let localValue = sessionStorage.getItem('_TP');
    // if (localValue) {
    //   this.showToolTip = false;
    // } else {
    //   this.showToolTip = true;
    // }

    this.getTimeForAgent();
    this.subs = this._event.subscribe('load_complete', async () => {
      let qp = this.queryParams.snapshot.queryParams;
      console.log('qp', qp);
      if (qp && qp.token) {
        await this.checkTokenExpiry(qp.token).catch((err) => {
          console.error('err on token check', err);
        });
      } else if (qp && qp.utm_source && qp.utm_campaign == 'expert') {
        // this.hideSkipIcon = true;
        // this.checkShownVideoContentAndConnect();
      }
      if (qp.type && qp.type == 'landing-page') {
        this.checkShownVideoContentAndConnect();
      }

    });
    this.receiveDataTransfer();
    this.exposedFunctionForNudges();
  }
  exposedFunctionForNudges() {
    window.togglePulseHubEnqForm = () => {
      this.checkShownVideoContentAndConnect()

    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.isPlaying) {
      this.sound.stop();
    }
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.$dataTransfer.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('OnChanges', changes);
    // if (this.sound) {
    //   if (changes.audioURL.currentValue != '') {
    //     this.sound._src = changes.audioURL.currentValue;
    //     this._global.updateOliviaAudioStatus(audioStatus.playing);
    //     this.sound.load();
    //     // this.sound.fade(1, 0, 2000);
    //     this.sound.play();
    //   } else {
    //     if (this.isPlaying) {
    //       this.stopPlaying();
    //     }
    //   }
    // }
    if (this.isPlaying) {
      this.stopPlaying();
    }
    if (changes.audioURL && changes.audioURL.currentValue != '') {
      // if (this.isPlaying) {
      //   this.stopPlaying();
      // console.log('self.isSoundMuted', this.isSoundMuted);
      // }
      this.createAudioPlayer(changes.audioURL.currentValue);
      // this.sound._src = ;

      this._global.updateOliviaAudioStatus(audioStatus.playing);
      // this.sound.load();
      // this.sound.fade(1, 0, 2000);
      // this.sound.play();
    }
    // if (changes.audioURL.currentValue === '' && this.sound) {

    // }
  }

  createAudioPlayer(audioUrl: string) {
    let self = this;
    this.sound = new Howl({
      // html5: true,
      src: audioUrl,
      autoplay: true,
      loop: audioUrl.includes('wait_for_you.mp3') ? true : false,
      preload: true,
      volume: 1.0,
      mute: self.isSoundMuted,
      onend: function () {
        self.audioCompleted();
        self.isPlaying = false;
      },
      onplay: function () {
        self.isPlaying = true;
      },
    });
  }
  onMute() {
    if (!this.isSoundMuted) {
      window.sendData({
        eName: 'click',
        eCat: '',
        eAct: 'speaker_button_clicked',
        eVal: 'on',
      });
    } else {
      window.sendData({
        eName: 'click',
        eCat: 'audio_assisted_page',
        eAct: 'speaker_button_clicked',
        eVal: 'off',
      });
    }
    this._global.updateSoundStatus(!this.isSoundMuted);
    // this.isMuted = !this.isMuted;
    console.log(this.isSoundMuted);

    this.sound.mute(this.isSoundMuted);
  }

  stopPlaying() {
    // this.sound.fade(1, 0, 1000);
    // setTimeout(() => {
    // }, 1000);
    this.isPlaying = false;
    this.sound._src = '';
    this.sound.stop();
    this.sound = null;
  }

  get showPulseUpConnectButton() {
    if (
      (this.agentAvailable == agentState.online) &&
      this.socketStatus == '' &&
      !this.checkVariantPageOrNot &&
      this.agentAvailableTime &&
      !this.isEnqFormOpen &&
      this.loadedCar &&
      defaultCarDetails[this.loadedCar].isExpertEnabled
    ) {
      this._event.publish('show_connect', true);
      return true;
    }
    // this.toggleWarnMsg = false;
    // this.openExpandModule = false;
    this._event.publish('show_connect', false);
    return false;
  }
  get showPulseUpAgentNotAvailable() {
    if (
      // this.agentAvailable != agentState.online &&
      this.socketStatus == '' &&
      !this.checkVariantPageOrNot &&
      !this.agentAvailableTime &&
      defaultCarDetails[this.loadedCar].isExpertEnabled
    ) {
      return true;
    }
    // this.toggleWarnMsg = false;
    // this.openExpandModule = false;
    return false;
  }

  get showAgentWaiting() {
    if (this.isEnqGenerated && !this.audioCallStatus) {
      return true;
    }
    return false;
  }

  get checkHomePageOrNot() {
    return window.location.hash.includes('home');
  }
  get checkFeaturePageOrNot() {
    return window.location.hash.includes('features');
  }

  get checkVariantPageOrNot() {
    return (
      // window.location.hash.includes('intro') ||
      // window.location.hash.includes('summary')
      window.location.hash.includes('intro')
    );
  }

  audioCompleted() {
    this.audioComplete.emit(true);
    this._global.updateOliviaAudioStatus(audioStatus.stop);
    console.log('Finished!');
  }
  // socketConnect() {
  //   this._socket.socket.connect();
  //   this._socket.setupSocketConnection();
  // }

  toggleWaringConnect(flag?) {
    if (!this.isOpenPulseHubEnqForm) {
      // if (this.firstTimeVideoPlayed) {
      //   let loginUser = this._data.geUserDataInfo();
      //   // console.log('loginUser', loginUser);
      //   this._ga.sendGAEvent('clicked pulsehub', 'click', 'yes');
      //   if (loginUser) {
      //     this.createEnquiry(loginUser);
      //   } else {
      //     this._global.togglePulseHubEnqForm(true);
      //   }
      //   // return;
      // }
      this.toggleWarnMsg = !this.toggleWarnMsg;
      if (this.toggleWarnMsg) {
        this._global.togglePulseHub(true);
      } else {
        this._global.togglePulseHub(false);
      }
      this.openExpandModule = !this.openExpandModule;
      this.showToolTip = false;
      sessionStorage.setItem('_TP', '1');
      console.log('this.disabledMicAccess', this.disabledMicAccess);
      if (
        this.toggleWarnMsg &&
        this.agentAvailable == agentState.online &&
        this.agentAvailableTime
      ) {
        if (this._data.getOnLoadCounter() > 5) {
          this._ga.sendGAEvent('pulsehub icon', 'click', 'load_limit_reached_click');
        } else {
          this._ga.sendGAEvent('pulsehub icon', 'click', 'click');
        }
        // this._global.updateSoundTrackURL(
        //   `${getAssetsURL()}/audio-journey/Nudge to connect with Pulse Hub 3.mp3`
        // );
      } else {
        // this._ga.sendGAEvent('clicked pulsehub', 'click', 'not now');
        window.sendData({
          eName: 'click',
          eCat: 'audio_assisted_page',
          eAct: 'close_button_clicked',
        });
      }
      if (flag) {
        let result = this._data.getOnLoadCounter();
        if (result >= 6) {
          this._data.enableBlockUI();
          this._data.dismissUIBlockLayer();
        }
      }
      if (this.toggleWarnMsg && !this.disabledMicAccess) {
        this._data.enableUIBlockLayer();
      }
    }
  }
  toggleAgentTiming() {
    this.openExpandModule = !this.openExpandModule;
    this.showAgentTiming = !this.showAgentTiming;
  }

  openEnqModal() {
    this.toggleWaringConnect(false);
    let loginUser = this._data.geUserDataInfo();
    // console.log('loginUser', loginUser);
    this._ga.sendGAEvent('microphone blocked', 'click', 'proceed');
    this.openExpandModule = !this.openExpandModule;
    // this._data.hideUIBlockLayer();
    if (loginUser) {
      // this.createEnquiry(loginUser);
      // if(!this.appointmentData){
      //   this.openAppointment();
      // }else{
      this.checkAppointmentAndShow();
      // }
      // check appointment and open block 
    } else {
      this._global.togglePulseHubEnqForm(true);
    }
    // this._socket.socket.connect();
    // this._socket.setupSocketConnection();
  }

  openMicPermission() {
    window.sendLiveDashboardData({
      name: 'allow',
      category: 'pulsehub icon_allow',
      type: 'MG_eXpert',
    });
    this._data.enableUIBlockLayer();
    this._ga.sendGAEvent('pulsehub', 'click', 'alow');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log('You let me use your mic!');
        this.disabledMicAccess = false;
        this.firstTimeVideoPlayed = true;
        this.saveApiCallJourney('true');
        this._ga.sendGAEvent('Audio Permission success', 'click', 'click');
        this.openEnqModal();
      })
      .catch((err) => {
        console.log('No mic for you!');
        this.saveApiCallJourney('false');
        this.disabledMicAccess = true;
        this._ga.sendGAEvent('Audio Permission failed', 'click', 'click');
      });
  }

  async createEnquiry(userData: any, retry: boolean = false) {
    try {
      // console.log('valid');
      // let apiBody = {
      //   pin_no: this.pinNo,
      //   chat_id: this._socket.room,
      //   mobile: userData.mobile,
      //   first_name: userData.first_name,
      //   last_name: userData.last_name,
      //   user_type: 'new',
      // };
      // console.log('---->', this._data.getDealerDetails());
      // this._global.updateDealerDetailsToken(this._data.getDealerDetails());
      let apiBody = {
        pin_no: this.pinNo,
        // chat_id: this._socket.room,
        mobile: this._data.encryptData(userData.mobile),
        first_name: userData.first_name,
        last_name: userData.last_name,
        user_type: 'new',
        scanid: this.dealerDetails ? this.dealerDetails.scanid : '',
        dealer_code: this.dealerDetails ? this.dealerDetails.dealer_code : '',
        device_token: this.dealerDetails ? this.dealerDetails.device_token : '',
        dealer_agent_id: this.dealerDetails
          ? this.dealerDetails.dealer_agent_id
          : '',
        retry_option: retry ? 'yes' : 'no',
        utm_source: this.dealerDetails ? 'vdcall' : 'pulseuup',
      };
      console.log('api body', apiBody);

      let apiResp: any = await this._expert.createEnqForPulseHub(apiBody);
      if (apiResp.status == 200) {
        this._data.setLocalName(userData.first_name);
        this._snack.openSnackBar(
          'Request generated successfully, our agent is joining you soon',
          'Success'
        );
        this._global.updateEnqGenerated(true);
        if (!retry) {
          this.socketConnect();
          this._global.updateHide3D(false);
          this._global.togglePulseHubEnqForm(false);
          let loadedCar = this._data.getLocalCarBrand();
          let url = defaultCarDetails[loadedCar].urlScheme;
          this.router.navigate([`${url}/home`]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  socketConnect() {
    // this._socket.socket.connect();
    if (this.socketStatus == '') {
      this._socket.setupSocketConnection();
    } else {
      console.log('it seems already connected', this.socketStatus);
    }
  }
  async onTimerComplete($event) {
    console.log('Time completed');
    if (!this.audioCallStatus) {
      if (this.isPlaying) {
        // this.sound.stop();
        this.stopPlaying();
      }
      window.sendData({
        eName: 'internal',
        eCat: 'call_dropped',
      });
      this.openExpandModule = true;
      this.showAgentBusy = true;
      this._global.updateAgentBusyStatus(this.showAgentBusy);
      console.log("Updated status", this.showAgentBusy);
      this._data.enableUIBlockLayer();
      this._global.updateEnqGenerated(false);
      // if (
      //   this.dealerDetails &&
      //   this.dealerDetails.hasOwnProperty('dealer_agent_id')
      // ) {
      //   await this._api.notifyAgentsEmail({ pin_no: this.pinNo });
      // }
      // this._global.updateSocketStatus('');

      // setTimeout(() => {
      //   this.openExpandModule = false;
      //   this.showAgentBusy = false;
      // }, 10000);
    }
  }
  openAppointment() {
    this.isEnqFormOpen = false;
    this._global.updateAppointmentForm(true);
  }

  closeAgentBusy() {
    this.openExpandModule = false;
    this.showAgentBusy = false;
    this._global.updateAgentBusyStatus(this.showAgentBusy);
    console.log("Updated status", this.showAgentBusy);
  }
  get agentAvailableTime() {
    var d = new Date();
    return d.getHours() >= this.startTime && d.getHours() < this.endTime;
  }
  /**
   * opens the appointment module
   */
  openAppointmentForm() {
    this.closeAgentBusy();
    this._global.updateAppointmentForm(true);
  }

  retryCall() {
    this.closeAgentBusy();
    let loginUser = this._data.geUserDataInfo();
    this._ga.sendGAEvent('agent busy', 'click', 'retry');
    // console.log('loginUser', loginUser);
    if (loginUser) {
      this.createEnquiry(loginUser, true);
    } else {
      this._global.togglePulseHubEnqForm(true);
    }
  }
  onCancelAppointment($event) {
    console.log('eee', $event);
    this._global.updateAppointmentRescheduleForm(false);
    let result = this._data.getOnLoadCounter();
    if (result >= 6) {
      this._data.enableBlockUI();
      this._data.dismissUIBlockLayer();
    }
    // if (this.openAppointmentFromEnquiryForm) {
    //   return;
    // }
    // this.openExpandModule = true;
    // if (this.fromOutTime) {
    //   this.showAgentTiming = true;
    // } else {
    //   this.showAgentBusy = true;
    // }
    // this.openExpandModule = !this.openExpandModule;
    // this.showAgentTiming = !this.showAgentTiming;
  }
  /**
   * check the token expiry and sets the user data
   * @author Ankit Prajapati
   */

  checkTokenExpiry(token) {
    return new Promise((res, rej) => {
      let body = {
        token: token,
      };
      this._api
        .checkUrlToken(body)
        .then((resp: any) => {
          console.log('resp', resp);
          if (resp.status == 1) {
            let dealerInfo = {
              dealer_agent_id: resp.response.empid,
              dealer_code: resp.response.outlet_code,
              model: resp.response.model,
              scanid: resp.response.scanid,
              device_token: '',
            };
            this._global.updateDealerDetailsToken(dealerInfo);
            this._data.setUserDataInfo(resp.response);
            this.createEnquiry(resp.response);
          } else {
            console.error('token expired', resp);
          }
          res(true);
        })
        .catch((err) => {
          console.error('err', err);
          rej(err);
        });
    });
  }

  async getTimeForAgent() {
    try {
      let resp: any = await this.http.get('./assets/js/time.json').toPromise();
      // console.log('resp', resp);
      this.startTime = resp.start;
      this.endTime = resp.end;
    } catch (error) {
      console.log(error);
    }
  }
  hideLiveConstButton() {
    this.showToolTip = false;
    this._ga.sendGAEvent('pulsehub icon', 'click', 'hide');
  }

  receiveDataTransfer() {
    this.$dataTransfer = this._event.subscribe('openCallingModal', (data) => {
      // console.log("data", data);
      console.log(this.showPulseUpConnectButton);
      if (this.showPulseUpConnectButton) {
        this.checkShownVideoContentAndConnect()
        // this.checkAppointmentAndShow();
      } else {
        this.eventFromRightPanel = true;
        this.showToolTip = false;
        this.openExpandModule = true;
        this.showAgentBusy = true;
        this._global.updateAgentBusyStatus(this.showAgentBusy);
        console.log("Updated status", this.showAgentBusy);
        this._data.enableUIBlockLayer();
      }
    });
  }

  checkShownVideoContentAndConnect(flag = true) {
    window.sendLiveDashboardData({
      name: 'click',
      category: 'pulsehub icon_click',
      type: 'MG_eXpert',
    });
    // Turning off this
    this.showAgentBusy = false;
    // this._global.updateAgentBusyStatus(this.showAgentBusy);
    // console.log("Updated status", this.showAgentBusy);
    console.log('Called', this.firstTimeVideoPlayed);
    if (this.firstTimeVideoPlayed) {
      this.openExpandModule = false;
      this.showToolTip = false;
      let loginUser = this._data.geUserDataInfo();
      // console.log('loginUser', loginUser);
      // this._ga.sendGAEvent('pulsehub_avira', 'click', 'click'); // removed as per Amana's recommendation 
      this._ga.sendGAEvent('Pulsehub Icon', 'click', "Schedule Call");
      if (loginUser) {
        // check appointment
        this.checkAppointmentAndShow()
        // this.createEnquiry(loginUser);
      } else {
        this._global.togglePulseHubEnqForm(true);
      }
    } else {
      // alert(this.openExpandModule);
      // this.isOpenPulseHubEnqForm=true;
      if (flag) {
        this.toggleWaringConnect();
      }
    }
  }
  checkAppointmentAndShow() {
    this._global.showCircleLoader(true);
    this._api.checkAppointmentByPin({ pin_no: this.pinNo })
      .then((data: any) => {
        console.log('data', data);
        this._global.showCircleLoader(false);
        if (data.status == 1) {
          this.appointmentData = data.response;
          console.log('this.appointmentData', this.appointmentData);
          if (this.appointmentData && this.appointmentData.hasOwnProperty("appoinment_date")) {
            this._global.updateShowRescheduleBtnFlag(true);
            this._global.updateAppointmentRescheduleForm(false);
            this._global.updateAppointmentSuccessModal(true);
          } else {
            this.openAppointment();
          }
        } else {
          this._global.showCircleLoader(false);
          console.error('error from server', data);
        }
      })
      .catch(err => {
        this._global.showCircleLoader(false);
        this.openAppointment()
        console.error('error', err)
      });
  }
  buttonToggle() {
    setTimeout(() => {
      console.log('sleep Now');
      this.show_Skip_Continue = true;
      // And any other code that should run only after 5s
    }, 5000);
  }
  skipOnAppointmentModule() {
    this.eventFromRightPanel = false;
    this.showToolTip = false;
    this.openExpandModule = false;
    this.showAgentBusy = false;
    this._global.updateAgentBusyStatus(this.showAgentBusy);
    console.log("Updated status", this.showAgentBusy);
    this._ga.sendGAEvent('pulsehub', 'click', 'close');
  }

  async saveApiCallJourney(action: string) {
    try {
      let apiBody = {
        pin_no: this.pinNo,
        action: 'mic_permission',
        status: action,
      };
      let resp = await this._expert.saveCallJourney(apiBody);
    } catch (error) {
      console.log(error);
    }
  }

  async onTimeValueChange(time) {
    this.counter = time;
    if (time == 60) {
      console.log('Triggered...');

      if (!this.dealerDetails) {
        await this._api.notifyAgentsEmail({ pin_no: this.pinNo });
      }
    }
  }

  onConnectNowClicked() {
    this._global.updateAppointmentSuccessModal(false);
    this._global.updateAppointmentRescheduleForm(false);
    this._global.updateAppointmentForm(false);
    this._global.togglePulseHubEnqForm(false);
    let loginUser = this._data.geUserDataInfo();
    this.createEnquiry(loginUser);
  }
  closePopup() {
    this._global.updateAppointmentSuccessModal(false);
    let result = this._data.getOnLoadCounter();
    if (result >= 6) {
      this._data.enableBlockUI();
    }
  }
  onCloseDisabledMicPopUp() {
    this.disabledMicAccess = false;
  }
  onRescheduleClicked() {
    this._global.updateAppointmentSuccessModal(false);
    this._global.updateAppointmentRescheduleForm(true);
  }
  onAppointmentChanged(event) {
    this.appointmentData = event;
  }
}