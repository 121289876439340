<!--Custom-selectLocation-->
<div class="myLocation thankyoumessageouter">
  <div class="SelectLocationPopup">
    <div class="SelectLocationPopupin">
      <div class="sharePopup tymbg">
        <button (click)="closeModal()" class="close-btn">
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_6231_3087)">
              <path
                d="M33.5 15C33.5 23.0081 27.0081 29.5 19 29.5C10.9919 29.5 4.5 23.0081 4.5 15C4.5 6.99187 10.9919 0.5 19 0.5C27.0081 0.5 33.5 6.99187 33.5 15Z"
                fill="white"
                stroke="white"
              />
              <path
                d="M14.8752 9.61341L19.0001 13.7379L23.125 9.62591C23.2062 9.54328 23.3037 9.47841 23.4113 9.43538C23.5188 9.39235 23.6342 9.37211 23.75 9.37594C23.9772 9.39065 24.1913 9.48754 24.3523 9.64854C24.5133 9.80954 24.6102 10.0236 24.6249 10.2508C24.6261 10.3625 24.6045 10.4733 24.5615 10.5765C24.5186 10.6796 24.4551 10.7729 24.375 10.8508L20.2376 15.0002L24.375 19.1497C24.5375 19.3072 24.6274 19.5247 24.6249 19.7497C24.6102 19.9769 24.5133 20.1909 24.3523 20.3519C24.1913 20.5129 23.9772 20.6098 23.75 20.6245C23.6342 20.6284 23.5188 20.6081 23.4113 20.5651C23.3037 20.5221 23.2062 20.4572 23.125 20.3746L19.0001 16.2626L14.8877 20.3746C14.8065 20.4572 14.709 20.5221 14.6014 20.5651C14.4939 20.6081 14.3785 20.6284 14.2627 20.6245C14.0313 20.6125 13.8125 20.5151 13.6486 20.3513C13.4847 20.1874 13.3873 19.9686 13.3753 19.7372C13.3741 19.6254 13.3957 19.5147 13.4387 19.4115C13.4816 19.3084 13.5451 19.2151 13.6252 19.1372L17.7626 15.0002L13.6127 10.8508C13.5349 10.7718 13.4737 10.6781 13.4329 10.575C13.3921 10.4719 13.3725 10.3617 13.3753 10.2508C13.39 10.0236 13.4869 9.80954 13.6479 9.64854C13.8089 9.48754 14.023 9.39065 14.2502 9.37594C14.3651 9.37049 14.4799 9.38876 14.5875 9.42961C14.695 9.47047 14.7929 9.53304 14.8752 9.61341Z"
                fill="#191919"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_6231_3087"
                x="0"
                y="0"
                width="38"
                height="38"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_6231_3087"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_6231_3087"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </button>
        <!--form-->
        <div class="fg sharePopupw tymPopup">
          <iframe [src]="iframeSRC"></iframe>
        </div>

        <!--form-->
      </div>
    </div>
  </div>
  <div class="SelectLocationOverlay"></div>
</div>
<!--Custom-selectLocation-->
