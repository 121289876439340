import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { VJsPlayer } from '../directives/v-js-player.component';
import { OliviaAudioComponent } from './olivia-audio/olivia-audio.component';
import { WaveAnimationComponent } from './wave-animation/wave-animation.component';
import { CounterDirective } from './counter.directive';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { SnackbarMaterialComponent } from './snackbar-material/snackbar-material.component';
import { AgentAudioComponent } from './agent-audio/agent-audio.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AppointmentFormComponent } from './appointment-form/appointment-form.component';
import { MatNativeDateModule } from '@angular/material/core';
import { AppointmentSuccessComponent } from './appointment-success/appointment-success.component';
import { MicrophoneBlockedComponent } from './microphone-blocked/microphone-blocked.component';
import { AppointmentRescheduleComponent } from './appointment-reschedule/appointment-reschedule.component';

@NgModule({
  declarations: [
    VJsPlayer,
    OliviaAudioComponent,
    WaveAnimationComponent,
    CounterDirective,
    EnquiryFormComponent,
    SnackbarMaterialComponent,
    AgentAudioComponent,
    AppointmentFormComponent,
    AppointmentSuccessComponent,
    MicrophoneBlockedComponent,
    AppointmentRescheduleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule 
  ],
  exports: [
    VJsPlayer,
    OliviaAudioComponent,
    AgentAudioComponent,
    WaveAnimationComponent,
    CounterDirective,
    SnackbarMaterialComponent,
  ],
})
export class SharedModule {}
