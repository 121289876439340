<div class="cppPOPUP" id="cppPopup">
  <!--cppPopupOuter-->
  <div class="cppPopupOuter">
    <div class="cppPopupin">
      <div class="cppformPopup">
        <!--cppformPopupinner-->
        <div class="fg cppformPopupinner">
          <button
            class="cppProClosepopup"
            [ngClass]="{
              'pointer-block': !ACCESS && callConnected
            }"
            (click)="closeModal('close')"
          ></button>

          <h4 class="fg cppmaintitle" *ngIf="userName == ''">
            Personalize your My MG Shield Plan
          </h4>
          <h4 class="fg cppmaintitle" *ngIf="userName != ''">
            <span>{{ userName }}'s</span> Personalized My MG Shield
          </h4>

          <!--cppCol1-->
          <div class="cppCol1" [formGroup]="glosterForm">
            <div class="cppfildouter cppfildouterinfo">
              <!--tooltip-->
              <div class="cppfildouterl">
                <div class="toolTipOuter">
                  <span>
                    <div class="cppfildouterinfoicon">
                      <div class="tooltip">
                        <img src="./assets/images/info-icon.svg" alt="img" />
                        <img
                          src="./assets/images/info-icon-fill.svg"
                          alt="img"
                        />
                        <span class="tooltiptext">
                          <div class="tooltiptextin">
                            <b>Warranty</b>
                            Use this drop down to customize your warranty
                            coverage in terms of years and kilometers
                          </div>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>

              <!--tooltip-->

              <div class="cppfildouterr">
                <div class="floating-label coobjectinputlable2">
                  <div class="custom-select mngpaym">
                    <select
                      [ngClass]="{
                        'pointer-block': !ACCESS && callConnected
                      }"
                      formControlName="warranty"
                      (change)="
                        onWarentyChange();
                        glosterPrefieldValue.protectPlan = '';
                        glosterPrefieldValue.buybackPlan = '';
                        glosterPrefieldValue.roadsideAssist = ''
                      "
                    >
                      <option selected disabled value="">
                        Select One Option
                      </option>

                      <option
                        *ngFor="let item of warrantyList"
                        [value]="item.code"
                      >
                        {{ item.title }} &nbsp;|&nbsp; INR {{ item.price }}
                      </option>
                    </select>
                  </div>
                  <label>Select Warranty *</label>
                </div>
              </div>
            </div>

            <div class="cppfildouter cppfildouterinfo">
              <!--tooltip-->
              <div class="cppfildouterl">
                <div class="toolTipOuter">
                  <span>
                    <div class="cppfildouterinfoicon">
                      <div class="tooltip">
                        <img src="./assets/images/info-icon.svg" alt="img" />
                        <img
                          src="./assets/images/info-icon-fill.svg"
                          alt="img"
                        />
                        <span class="tooltiptext">
                          <div class="tooltiptextin">
                            <b>Roadside Assistance</b>
                            Use this drop down to customize your roadside
                            assistance Coverage.
                          </div>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>

              <!--tooltip-->
              <div class="cppfildouterr">
                <div class="floating-label coobjectinputlable2">
                  <div class="custom-select mngpaym">
                    <select
                      [ngClass]="{
                        'pointer-block': !ACCESS && callConnected
                      }"
                      formControlName="roadsideAssist"
                      (change)="
                        onRoadAssistChange();
                        glosterPrefieldValue.protectPlan = '';
                        glosterPrefieldValue.buybackPlan = ''
                      "
                    >
                      <option selected disabled value="">
                        Select One Option
                      </option>
                      <option
                        *ngFor="let item of roadsideAssistList"
                        [value]="item.code"
                      >
                        {{ item.title }} &nbsp;|&nbsp; INR {{ item.price }}
                      </option>
                    </select>
                  </div>
                  <label>Select Roadside Assistance *</label>
                </div>
              </div>
            </div>

            <div class="cppfildouter cppfildouterinfo">
              <!--tooltip-->
              <div class="cppfildouterl">
                <div class="toolTipOuter">
                  <span>
                    <div class="cppfildouterinfoicon">
                      <div class="tooltip">
                        <img src="./assets/images/info-icon.svg" alt="img" />
                        <img
                          src="./assets/images/info-icon-fill.svg"
                          alt="img"
                        />
                        <span class="tooltiptext">
                          <div class="tooltiptextin">
                            <b>Protect Plan</b>
                            Use this drop down to choose a maintenance
                            package.Classic package covers periodic maintenance
                            costs, and Premium package covers Periodic
                            maintenance plus wear and tear costs. (Tyre, Batter,
                            collisions not covered under any package)
                          </div>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>

              <!--tooltip-->
              <div class="cppfildouterr">
                <div class="floating-label coobjectinputlable2">
                  <div class="custom-select mngpaym">
                    <select
                      [ngClass]="{
                        'pointer-block': !ACCESS && callConnected
                      }"
                      formControlName="protectPlan"
                      (change)="
                        onProtectPlanChange();
                        glosterPrefieldValue.buybackPlan = ''
                      "
                    >
                      <option selected disabled value="">
                        Select One Option
                      </option>
                      <option
                        *ngFor="let item of protectPlanList"
                        [value]="item.code"
                      >
                        {{ item.title }} &nbsp;|&nbsp; INR {{ item.price }}
                      </option>
                    </select>
                  </div>
                  <label>Select Protect Plan *</label>
                </div>
              </div>
            </div>

            <div  *ngIf="buyBackPlanList?.length>0" class="cppfildouter cppfildouterinfo">
              <!--tooltip-->
              <div class="cppfildouterl">
                <div class="toolTipOuter">
                  <span>
                    <div class="cppfildouterinfoicon">
                      <div class="tooltip">
                        <img src="./assets/images/info-icon.svg" alt="img" />
                        <img
                          src="./assets/images/info-icon-fill.svg"
                          alt="img"
                        />
                        <span class="tooltiptext">
                          <div class="tooltiptextin">
                            <b>Buyback Plan</b>
                            Use this drop down to customize your roadside
                            assistance Coverage.
                          </div>
                        </span>
                      </div>
                    </div>
                  </span>
                </div>
              </div>

              <!--tooltip-->
              <div class="cppfildouterr">
                <div class="floating-label coobjectinputlable2">
                  <div class="custom-select mngpaym">
                    <select
                      [ngClass]="{
                        'pointer-block': !ACCESS && callConnected
                      }"
                      formControlName="buybackPlan"
                      (change)="onBuyBackChange()"
                    >
                      <option selected value="">NA</option>
                      <option
                        *ngFor="let item of buyBackPlanList"
                        [value]="item.code"
                      >
                        {{ item.title }} &nbsp;|&nbsp; INR {{ item.price }}
                      </option>
                    </select>
                  </div>
                  <label>Select Buyback Plan *</label>
                </div>
              </div>
            </div>
          </div>
          <!--cppCol1-->

          <!--cppCol2-->
          <div class="cppCol2">
            <div class="fg cppCol2title cppfildouterinfo">
              <div class="cppcol2fildouterl">Total Price:</div>
              <div class="cppcol2fildouterr">
                ₹ {{ pricetoLocatStr(protectionPlanTotalPrice) }}
              </div>
            </div>
          </div>
          <!--cppCol2-->

          <!--cppCol3-->
          <div
            class="cppCol3"
            *ngIf="!callConnected"
            [ngClass]="{
              'pointer-block': !ACCESS && callConnected
            }"
          >
            <div class="cppCol3btnleft">
              <button class="btn-sts" (click)="closeModal('summary')">
                Skip To Summary
              </button>
            </div>
            <div class="cppCol3btnright">
              <button
                class="btn-stssubmit"
                [disabled]="!glosterForm?.valid"
                (click)="saveShield()"
              >
                Submit
              </button>
            </div>
          </div>
          <!--cppCol3-->
        </div>
        <!--cppformPopupinner-->
      </div>
    </div>
  </div>
  <!--cppPopupOuter-->

  <!--cppPopupOverlay-->
  <div class="cppPopupOverlay"></div>
  <!--cppPopupOverlay-->
</div>
