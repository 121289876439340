import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var _$kt: any;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl: string;
  token: string;
  modelId: number = 3;
  headers: HttpHeaders;
  headersEncode: HttpHeaders;
  constructor(public http: HttpClient) {
    if (window.location.host == 'cc.mgmotor.co.in') {
      this.apiUrl = 'https://ccapi.mgmotor.co.in/api/v4';
      // this.apiUrl = 'https://ccapi.one3d.in/api/v3';
      this.token = "";
      // this.token =
      //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5MGVjMGFkYS1jYTE1LTQ1MWYtODdmMS04MGU0OWFjYTU3MTYiLCJqdGkiOiIzZjdlMWY3YjA3NGFhYzNiNjk1ZDczODMxMzNmMGMwMWY0OWY5MGU4OTEyM2EwODFkNmU3OTVkYWQ0ZDNjNDM3NTU2OGEyMGJhM2UzOGQ0NSIsImlhdCI6MTU5NDMwNTQyOSwibmJmIjoxNTk0MzA1NDI5LCJleHAiOjE2MjU4NDE0MjksInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zdCL-BNbvw6eO_NwxlwIvObvMiNGvYjNHOQQuYIZPt6cHOQBL2MzXA1H5PvwTUYaMlYehW7R2kitFbUprGurLJNQmTUWtYVYKqQ2TWlQEoAZF0_zZTrckKWVWX6Oiqxi_ZgEexpzHFwHWHzYS1hUEgE2LVfWVjVyECqviOHjTBjwb4uWcMiS1yn0S8UuB35TLxiSO529rt5fY5U4fnUV1_L2BNbCpGwF81MbhaDE55mc6DTVOUU9_Lo86eTot-tkf5P96N70EIoxMChF6Nu3O3MKzE9BhSx74B7IpYdJOrW7-XRm_nlrTEjT_l27EKLPQq6eDMnRNK4DHAa2tTZl_pDwBmyEUCEdOeTg0qBmCoVq8XIJZUk5uYSP_djPIsFVKNR67Eq_kFKZZv8OHEJEbBebUvs8F4tblTfFGtxqDlyEjT-K-yUD0Kq53ewp9BQxrxQaS0bvDfA2IykXBu1ZB_xETny2tVVSQJ9JTyQWMwUJfpax17fpwBBJ6DNAMCNhtV8SwEcRynqwvhmz-CAKeKfAE4JNUGX1PPvula7um8p5tuZyctQGt2lmm483gtXyZuKJJyrRTlA4hk0riGS8k3PQMrgcdigjZj87OQP6KXrxJGLiD7E0QmuUs_NL06XYVI1IJSHaKhb8QKXVjBPGGIFnrRQ8b9XpHePB3WgvXXs';
    } else {
      this.apiUrl = 'https://mg.one3-d.in/api/v4';
      this.token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5NTc4NTYzZS1mNjY2LTRkNzQtYmIyYi03ZDFiYTc5ZWIwMTMiLCJqdGkiOiIwODk3MjdkMWI5NDA5MTM3ZjNjMDUwMmM2OGZhMWI1NjJjYmRjMWRhMTcwMTViMDFjMTM3ZDliOWU4OTA0Y2Y0M2I3OTkwNjk5YTg0YzIzMiIsImlhdCI6MTY3MDQxMjUyMCwibmJmIjoxNjcwNDEyNTIwLCJleHAiOjE3MDE5NDg1MjAsInN1YiI6IjMiLCJzY29wZXMiOltdfQ.PoRoplUECsX-RsV6L05x1LQK0nOfV4KveiDmxjWVekSXh3zmWAjN66Bevn7iyOAOLYThQsVIxqCTbW4iFe831cyVUs3n8GIoYsQykDtg0tzUjrDIjCrlsZ-ePIQyv8FedOjo9VP6ymJnXhrxsHl7rPLbOtc7CPvENQg3wjZo6hZysr1u511jb3IsA8grjGA6KNq0Wq5x4BJHVrWD-gU8bmbCpU_OTThAA4BW_8CRE_4Z9_A_kgdFfR9rFnhNDyiSSNadbkK43mcV8p0yWngbKUaH0OBz-_Guf1JumesEW1Z1khmZjK4Lw3Uf66R2zWO668X72uSetlLjUMGq3fvfp2Qsgk1NNweUReO8JxIxgPyI_cmohU991jV_iD97NMHlgnMZw6m_HSgxnX6iZwsLv3hN1F4I2bVQY3Y7gPyhpGu879LT73ajNpRfUsc2DU6Nld-ozPmlElirHzVpY9_5pZwOFMMPjdTLP1XQkvsOIUNfFxDK5Mazmz4Z6awezP1LE0HYpDnkTl0suoDSdeiRJmd8E1CEq6SnBznN5XsIrn4s9qvJQI4mRbhuREgfht8vUF1TBcIB57yr5jEV4rW8woVuhIS5nwPbsQTKf5SMlX6SNqTRTY5YVTv4M8QZxALas6lnvm6pZkpXeGN5XiYstqYtqAy3L3R1AqU-Ly26qMI';
    }

    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', `application/json`);
    this.headers = this.headers.set('Authorization', `Bearer ${this.token}`);

    this.headersEncode = new HttpHeaders();
    this.headersEncode = this.headersEncode.set(
      'Content-Type',
      `application/x-www-form-urlencoded`
    );
    // this.headersEncode = this.headersEncode.set('responseType', `text`);
    this.headersEncode = this.headersEncode.set(
      'Authorization',
      `Bearer ${this.token}`
    );
  }

  getToken() {
    let body = {
      email: 'sanket@eccentricengine.com',
      password: '111#$dsdsd',
    };
    this.http
      .post(`${this.apiUrl}/login`, body, {
        headers: this.headers,
      })
      .subscribe((data: any) => {
        if (data.hasOwnProperty('success')) {
          console.log(data.success.token);

          this.headers = this.headers.set(
            'Authorization',
            `Bearer ${data.success.token}`
          );
          this.headersEncode = this.headersEncode.set(
            'Authorization',
            `Bearer ${data.success.token}`
          );
        }
      });
  }
  updateTokenToHeader(token: string) {
    this.token = token;
    this.headersEncode = this.headersEncode.set(
      'Authorization',
      `Bearer ${this.token}`
    );
    this.headers = this.headers.set('Authorization', `Bearer ${this.token}`);
  }

  createNewConfiguration(body: any) {
    return this.http
      .post(`${this.apiUrl}/create_configuraion`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getVariantList(body: any) {
    body['state'] = '';
    body['city'] = '';
    return this.http
      .post(`${this.apiUrl}/variant_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getVariantListWithCat(body: any) {
    body['state'] = 'Delhi';
    body['city'] = 'Delhi';
    return this.http
      .post(`${this.apiUrl}/variant_listing_category`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getVariantDetails(body: any) {
    return this.http
      .post(`${this.apiUrl}/variant_details`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  checkVariantColorSelection(body: any) {
    return this.http
      .post(`${this.apiUrl}/variant_color_selection_voicebot`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getAccessoriesList(body: any) {
    return this.http
      .post(`${this.apiUrl}/accessories_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  addRemoveAccessories(body: any) {
    return this.http
      .post(`${this.apiUrl}/accessories_add_remove`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  removeOptionKitAPI(body: any) {
    return this.http
      .post(`${this.apiUrl}/pack_remove`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getBuildDetails(body: any) {
    return this.http
      .post(`${this.apiUrl}/configuration_details_by_pin`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  sendOtp(body: any) {
    return this.http
      .post(`${this.apiUrl}/send_otp`, btoa(JSON.stringify(body)), {
        headers: this.headersEncode,
        responseType: 'text',
      })
      .toPromise();
  }

  customerRegistration(body: any) {
    return this.http
      .post(
        `${this.apiUrl}/customer_registration`,
        btoa(JSON.stringify(body)),
        { headers: this.headersEncode, responseType: 'text' }
      )
      .toPromise();
  }

  customerRegistrationWOOTP(body: any) {
    return this.http
      .post(
        `${this.apiUrl}/customer_registration_new`,
        btoa(JSON.stringify(body)),
        { headers: this.headersEncode, responseType: 'text' }
      )
      .toPromise();
  }

  getStoryBasedFeatureList(body: any) {
    return this.http
      .post(`${this.apiUrl}/story_based_feature_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getFeatureList(body: any) {
    return this.http
      .post(`${this.apiUrl}/feature_story_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getInteriorColors(body: any) {
    return this.http
      .post(`${this.apiUrl}/interior_color_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  /**
   * get State list from server
   * @param body
   * @returns
   */
  getStateList() {
    return this.http
      .get(`${this.apiUrl}/dealer_state_listing`, {
        headers: this.headers,
      })
      .toPromise();
  }
  /**
   * fetch city data basis on state
   * @param body {state:"state name"}
   * @returns
   */
  getCityListing(body: any) {
    return this.http
      .post(`${this.apiUrl}/dealer_city_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  /**
   * fetch Dealer data basis on city
   * @param body {state:"state name",city:"city name",pin_no:"pin number"}
   * @returns
   */
  getDealerListing(body: any) {
    return this.http
      .post(`${this.apiUrl}/dealer_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  /**
   * fetch Dealer data basis on city
   * @param body {state:"state name",city:"city name",pin_no:"pin number"}
   * @returns
   */
  dealerSelected(body: any) {
    return this.http
      .post(`${this.apiUrl}/dealer_selection`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  /**
   * send otp to user
   * @param body {mobile:encrypted,source:web}
   * @returns
   */
  otpSend(body: any) {
    return this.http.post(
      `${this.apiUrl}/send_otp`,
      btoa(JSON.stringify(body)),
      {
        headers: this.headersEncode,
        responseType: 'text',
      }
    );
  }
  /**
   * login user after entered OTP
   * @param body {mobile:encrypted,source:web}
   */
  customerLogin(body: any) {
    return this.http.post(
      `${this.apiUrl}/customer_login`,
      btoa(JSON.stringify(body)),
      {
        headers: this.headersEncode,
      }
    );
  }
  /**
   *save user data to server if everything is correct
   */
  saveConsultationDetails(body: any) {
    return this.http.post(
      `${this.apiUrl}/save_cust_personal_details`,
      btoa(JSON.stringify(body)),
      {
        headers: this.headersEncode,
        responseType: 'text',
      }
    );
  }
  /**
   * fetches the Plan Data
   * @param body {source:"web",pin_no:"con_id"}
   */
  getProtectionPlansList(body: any) {
    return this.http.post(`${this.apiUrl}/protect_plan`, body, {
      headers: this.headers,
    });
  }

  getPriceState(body: any) {
    return this.http
      .post(`${this.apiUrl}/price_state_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getPriceCity(body: any) {
    return this.http
      .post(`${this.apiUrl}/price_city_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  /**
   * save emi data
   * @param body {"pin_no": "MGvVD210901052526","down_payment":300000,"interest_rate":7.55,"finance_needed":500000,
    "monthly_payment":20000.05669,
    "loan_tenure":"5 years"}
   */

  saveEmiData(body: any) {
    return this.http
      .post(`${this.apiUrl}/save_emi_details`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getFeatureStoryCategory(body: any) {
    return this.http
      .post(`${this.apiUrl}/feature_story_category`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getFeatureStoryByID(body: any) {
    return this.http
      .post(`${this.apiUrl}/feature_story_by_id`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  saveBuildSummary(pin_no: string, action: string) {
    let apidata = {
      pin_no,
      action,
    };

    this.http
      .post(`${this.apiUrl}/save_customer_journey`, apidata, {
        headers: this.headers,
      })
      .subscribe((data) => console.log(`${action} saved`));
  }
  getCarCompareData(body) {
    return this.http
      .post(`${this.apiUrl}/variant_car_comparision`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getVariantCodeList(body) {
    return this.http
      .post(`${this.apiUrl}/variant_code_listing`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  astorProtectPlan(body: any) {
    return this.http
      .post(`${this.apiUrl}/astor_shield_plan`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  saveShieldPlan(body: any) {
    return this.http
      .post(`${this.apiUrl}/save_gloster_protect_plan_details`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getShieldPlan(body: any) {
    return this.http
      .post(`${this.apiUrl}/gloster_protect_plan`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  getHectorShieldPlan(body: any) {
    return this.http
      .post(`${this.apiUrl}/hector_shield_plan`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  checkUrlToken(body: any) {
    return this.http
      .post(`${this.apiUrl}/check_url_token`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getCarExchangeCityList(body: any) {
    return this.http
      .post(`${this.apiUrl}/getExchangeCities`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getCarExchangeMakeList(body: any) {
    return this.http
      .post(`${this.apiUrl}/getYearwiseMakes`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getCarExchangeModelList(body: any) {
    return this.http
      .post(`${this.apiUrl}/GetCarModelsByType`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getCarExchangeModeVariantlList(body: any) {
    return this.http
      .post(`${this.apiUrl}/GetCarVersions`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getCarExchangeValuation(body: any) {
    return this.http
      .post(`${this.apiUrl}/valuation`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  saveCarExchangeData(body: any) {
    return this.http
      .post(`${this.apiUrl}/save_custome_exchange_data_cw`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getCarExchangeData(body: any) {
    return this.http
      .post(`${this.apiUrl}/customer_selected_exchange_data`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  calculateEmiCarExchangeData(body: any) {
    return this.http
      .post(`${this.apiUrl}/emiOptions`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  saveEmiCarExchangeData(body: any) {
    return this.http
      .post(`${this.apiUrl}/saveCustomerSelectEmiOption`, body, {
        headers: this.headers,
      })
      .toPromise();
  }

  /**
   * get dealers by pincode
   * @param apiBody
   * @returns promise of api request
   * @author sanket
   */
  getDealerByPinCode(apiBody) {
    return this.http
      .post(`${this.apiUrl}/get_dealers_pincode_modelwise`, apiBody, {
        headers: this.headers,
      })
      .toPromise();
  }
  feedbackQuestionList() {
    return this.http
      .get(`${this.apiUrl}/mgexpert_feedback_question`, {
        headers: this.headers,
      })
      .toPromise();
  }
  feedbackSave(body: any) {
    return this.http
      .post(`${this.apiUrl}/save_mgexpert_feedback`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  checkAppointmentByPin(body: any) {
    return this.http
      .post(`${this.apiUrl}/appoinmentDetails`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  notifyAgentsEmail(body: any) {
    return this.http
      .post(`${this.apiUrl}/sendEmailonRmnotConnectOnCall`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
  getIpofUser() {
    return this.http.get('//api.ipify.org/?format=json').toPromise();
  }
  getRateingSystemJson() {
    return this.http.get(`assets/js/ratting.json`).toPromise();
  }

  async getLocation() {
    if (navigator.geolocation) {
      try {
        let position: any;
        position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const params = {
          lat: latitude,
          lon: longitude
      };
        let response:any;
        response = await this.getPincodeViaLatLon(params);
        // console.log("Pincode", response);
        if (response.message == "success" ){
          //Return the response, from whichever file this fn 
          //is called.
          return response?.pinCode;
        }
      } catch (error) {
        console.error(error);
        return null;
        // Handle error here
      }
    } else {
      console.error('Geolocation is not supported by this browser.');
      // Handle error here
    }
  }

  getPincodeViaLatLon(body:any){
    return this.http
    .post(`${this.apiUrl}/getPincodeByLatLon`, body, {
      headers: this.headers,
    })
    .toPromise();
  }

  getPincodeWiseDealers(body: any) {
    return this.http
      .post(`${this.apiUrl}/getPincodeWiseDealers`, body, {
        headers: this.headers,
      })
      .toPromise();
  }
}
