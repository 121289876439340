<div class="secObjectltopleftthumb">
  <div
    class="secObjectltopleftthumbimgbox"
    [ngClass]="{
      'border-none': imageNotFound,
      'display-none': checkIsDealerAgent()
    }"
  >
    <img
      [src]="agentDetails?.image_url"
      (error)="errorHandler($event)"
      alt="img"
    />
  </div>
</div>
<div class="fg secObjectltopleftinfo">
  <div class="fg secObjectltopleftinfotitle1">
    <span>{{
      agentDetails?.first_name
        ? agentDetails?.first_name
        : agentDetails?.username
    }}</span>
    <div class="secObjectltopleftinfotitle1b">
      <!-- <i>
            <b><img src="./assets/images/sound-play-icon2.png" /></b>
        </i> -->
      <app-wave-animation [showAnim]="!isSoundMuted"></app-wave-animation>
    </div>
  </div>
  <div
    *ngIf="audioCallStatus == 'connecting'"
    class="fg secObjectltopleftinfotitle3"
  >
    <!-- <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div> -->
    Trying to connect with you an eXpert
  </div>
  <div
    *ngIf="audioCallStatus == 'connected'"
    class="fg secObjectltopleftinfotitle3 connected-text"
  >
    CONNECTED!
  </div>
</div>
