import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { defaultCarDetails } from '../three-d-module/carSettings';
declare var window;
@Injectable({
  providedIn: 'root',
})
export class DataService {
  secret = '$s45Ddrg*6^6h*sweg%';
  variantList: any = [];
  exploreCar: boolean = false;
  //Custom 2
  overAllMeshListCustom2: any[];
  //Custom 1
  overAllMeshListCustom1: any[];
  //Custom 0
  overAllMeshListCustom0: any[];

  constructor() {
    window.getCarDetails = () => {
      let loadedCar = this.getLocalCarBrand();
      let car = defaultCarDetails[loadedCar];
      return car
    }
  }

  toggelExplore() {
    // document.getElementById("exploreCar").style.display = document?.getElementById("exploreCar")?.style.display=="block"?"none":"block";
    // this.exploreCar =   document?.getElementById("exploreCar")?.style.display=="block"?true:false;
    // alert( document?.getElementById("exploreCar")?.style.display)
    this.exploreCar = !this.exploreCar;
  }

  getLocalPinNo() {
    let pinNo = sessionStorage.getItem('p_');
    if (pinNo) {
      let str = this.decryptData(pinNo.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      let newStr = decryptString.replace(/["]+/g, '');
      return newStr;
    }
  }
  setLocalPinno(id: string) {
    let str = this.encryptData(id.toString());
    window.pin_no = id;
    sessionStorage.setItem('p_', str);
  }
  getLocalName() {
    let name = sessionStorage.getItem('n_');
    if (name) {
      let str = this.decryptData(name.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      let newStr = decryptString.replace(/["]+/g, '');
      return newStr;
    }
  }
  setLocalName(name: string) {
    let str = this.encryptData(name.toString());
    sessionStorage.setItem('n_', str);
  }
  getLocalCarBrand(): string {
    let pinNo = sessionStorage.getItem('b_');
    if (pinNo) {
      let str = this.decryptData(pinNo.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      let newStr = decryptString.replace(/["]+/g, '');
      return newStr;
    }
  }
  setLocalCarBrand(id: string) {
    let str = this.encryptData(id.toString());
    // window.pin_no = id;
    sessionStorage.setItem('b_', str);
  }
  getLocalVariant() {
    let variant = sessionStorage.getItem('v_');
    if (variant) {
      let str = this.decryptData(variant.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      let newStr = decryptString.replace(/["]+/g, '');
      return newStr;
    }
  }
  setLocalVariant(id: number | string) {
    let str = this.encryptData(id.toString());
    sessionStorage.setItem('v_', str);
  }

  getLocalColor() {
    let variant = sessionStorage.getItem('c_');
    if (variant) {
      let str = this.decryptData(variant.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      let newStr = decryptString.replace(/["]+/g, '');
      return newStr;
    }
  }
  setLocalColor(id: number | string) {
    let str = this.encryptData(id.toString());
    sessionStorage.setItem('c_', str);
  }
  getLocationInfo() {
    let location = sessionStorage.getItem('loc_');
    if (location) {
      // let str = this.decryptData(location.toString());
      // let decryptString = str.toString(CryptoJS.enc.Utf8);
      // let newStr = decryptString.replace(/["]+/g, '');
      return location;
    }
  }
  setLocationInfo(obj: any) {
    // let str = this.encryptData(JSON.stringify(obj));
    sessionStorage.setItem('loc_', JSON.stringify(obj));
  }

  geUserDataInfo() {
    let userData = this.getCookie('_ud');
    if (userData) {
      let str = this.decryptData(userData.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      // let newStr = decryptString.replace(/["]+/g, '');
      // let newStr2 = newStr.replace(/\//g, '');
      return JSON.parse(JSON.parse(decryptString));
    }
  }

  setDealerDetails(id: any) {
    let str = this.encryptData(JSON.stringify(id));
    sessionStorage.setItem('dlr_', str);
  }
  getDealerDetails() {
    let dealer = sessionStorage.getItem('dlr_');
    if (dealer) {
      let str = this.decryptData(dealer.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      // let newStr = decryptString.replace(/["]+/g, '');
      // let newStr2 = newStr.replace(/\//g, '');
      return JSON.parse(JSON.parse(decryptString));
    }
  }

  setSeatingInfo(number: number | string) {
    let str = this.encryptData(number.toString());
    sessionStorage.setItem('seating_', str);
  }
  getSeatingInfo() {
    let variant = sessionStorage.getItem('seating_');
    if (variant) {
      let str = this.decryptData(variant.toString());
      let decryptString = str.toString(CryptoJS.enc.Utf8);
      let newStr = decryptString.replace(/["]+/g, '');
      return newStr;
    }
  }

  setUserDataInfo(data: any) {
    let str = this.encryptData(JSON.stringify(data));
    window.updatePayloadParameter('cuId', data.cust_id);
    // sessionStorage.setItem('c_', str);
    this.setCookie('_ud', str, 30);
  }

  setCookie(name: string, value: string, exp_days: number) {
    var d = new Date();
    d.setTime(d.getTime() + exp_days * 24 * 60 * 60 * 1000);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
  }

  getCookie(name: string) {
    var cname = name + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(cname) == 0) {
        return c.substring(cname.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(name: string) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  encryptData(id: string) {
    return CryptoJS.AES.encrypt(JSON.stringify(id), this.secret).toString();
  }
  decryptData(value: string) {
    return CryptoJS.AES.decrypt(value, this.secret);
  }

  setItem(key: string, value: any): Promise<void> {
    // alert(1);
    // return new Promise<void>((resolve, reject) => {
    //   try {
    //     localStorage.setItem(key, JSON.stringify(value));
    //     resolve();
    //   } catch (error) {
    //     reject(error);
    //   }
    // });
    return new Promise<void>((resolve, reject) => {
      try {
        // alert(1)
        // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
        let str = this.encryptData(value.toString());
        // alert(str)
        localStorage.setItem(key, str);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  getItem<T>(key: string): Promise<T | null> {
    // return new Promise<T | null>((resolve) => {
    //   let value = localStorage.getItem(key);
    //   resolve(value ? JSON.parse(value) : [] );
    // });
    return new Promise<T | null>((resolve) => {

      let value = localStorage.getItem(key);
      let newStr: any;
      if (value) {
        let str = this.decryptData(value.toString());
        let decryptString = str.toString(CryptoJS.enc.Utf8);
        newStr = decryptString.replace(/["]+/g, '');
      }
      resolve(newStr ? JSON.parse(newStr) : []);
    });
  }

  setPacks(selectedPacks, pack) {

    let additionalPack = [];
    // let modifiedPack: any = {};
    let modifiedPack = { ...pack }; // Create a shallow copy of the pack object
    // Object.assign(modifiedPack, pack);
    modifiedPack = pack;
    console.log("Incoming pack", pack)
    // let modifiedPack = JSON.parse(JSON.stringify(pack)); // Deep copy of the pack object
    //For  allow_custom === 0

    // Filter out objects with 'checked' property set to false and allow_custom === 0
    let filteredAdditionalPack = modifiedPack.additionalPack.filter(
      (item) => item.checked && item.allow_custom === 0
    );

    if (filteredAdditionalPack.length > 0) {
      additionalPack = [...filteredAdditionalPack];
    }

    //For  allow_custom === 1

    // Find the additionalPack objects with allow_custom = 1
    let additionalPackWithCustom = modifiedPack.additionalPack.filter((item) => item.allow_custom === 1);

    if (additionalPackWithCustom.length > 0) {
      additionalPackWithCustom.forEach((additionalPackItem) => {
        // Filter the accessoriesList based on the checked property
        let filteredAccessories = additionalPackItem.accessoriesList.filter((accessory) => accessory.checked);

        if (filteredAccessories.length > 0) {
          // Update the accessoriesList property with the filtered accessories
          additionalPackItem.accessoriesList = filteredAccessories;
          additionalPack.push(additionalPackItem);
        }
      });
    }
    modifiedPack.additionalPack = additionalPack;
    console.log("Check this pack", modifiedPack, additionalPack);
    selectedPacks.push(modifiedPack);
    // return modifiedPack;
  }

  isLocalStorageItemSet(key) {
    return localStorage.getItem(key) !== null && localStorage.getItem(key) !== undefined;
  }

  getOnLoadCounter() {
    return parseInt(localStorage.getItem('onLoadCounter')) || 0;
  }

  clearLoadCounter() {
    localStorage.removeItem('onLoadCounter')
  }

  enableBlockUI() {
    // // Get the element by its ID
    let blockUI = document.getElementById('block-ui');
    // Set the display style property to 'block'
    blockUI.style.display = 'block';
    // this.hideUIBlockLayer();
  }

  dismissUIBlock() {
    // // Get the element by its ID
    let blockUI = document.getElementById('block-ui');
    blockUI.style.display = 'none';
  }

  dismissUIBlockLayer() {
    // // Get the element by its ID
    let blockUILayer = document.getElementById('block-ui-layer');
    blockUILayer.style.display = 'none'
  }

  enableUIBlockLayer() {
    if (this.getOnLoadCounter() >= 6) {
      // // Get the element by its ID
      let blockUILayer = document.getElementById('block-ui-layer');
      blockUILayer.style.display = 'block'
    }
  }

  clearOnLoadTimerOn60days() {
    try {
      // Check if 'firstTimeDate' is stored in localStorage
      let storedFirstTimeDate = localStorage.getItem('firstTimeDate');
      if (storedFirstTimeDate) {
        const firstTimeDate = new Date(storedFirstTimeDate);
        const currentDate = new Date();
        //For testing, setting the current date to 60 days ahead in future.
        // const currentDate = new Date(firstTimeDate.getTime() + (61 * 24 * 60 * 60 * 1000));
        const millisecondsPerDay = 24 * 60 * 60 * 1000; // Milliseconds in a day
        const daysPassed = Math.floor((currentDate.getTime() - firstTimeDate.getTime()) / millisecondsPerDay);
        // console.log("My data",daysPassed,  millisecondsPerDay);
        if (daysPassed >= 60) {
          // Clear storage after 60 days from the 'firstTimeDate'
          localStorage.removeItem('onLoadCounter');
          localStorage.removeItem('firstTimeDate');
        }
      } else {
        // Handle the case where 'firstTimeDate' is not set in localStorage
        console.error("'firstTimeDate' not found in localStorage.");
      }
    } catch (error) {
      // Handle any potential errors that might occur during date calculation or localStorage operations
      console.error('Error occurred:', error.message);
    }
  }

  // simulateAfter60Days() {
  //   let storedFirstTimeDate = localStorage.getItem('firstTimeDate');
  //   // Set the date 61 days ahead (60 days + 1 day for testing purposes)
  //   if (storedFirstTimeDate){
  //     const firstTimeDate = new Date(storedFirstTimeDate);
  //     const futureDate = new Date(firstTimeDate.getTime() + (61 * 24 * 60 * 60 * 1000));
  //     this.clearOnLoadTimerOn60days();
  //   }
  // }
}