import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import {
  GlobalService,
  hectorPlusSeating,
  variant,
} from '../../service/global.service';
import { SocketService } from '../../service/socket.service';
import { GaService } from '../../service/ga.service';
import { CallService } from '../../service/call.service';
import { defaultCarDetails } from '../carSettings';
import { Subscription } from 'rxjs';
import { EventService } from '../../service/event.service';
declare var ONE3D: any;
declare var window: any;
declare var lottie: any;
declare var animationDataForVariant: any;
@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss'],
})
export class VariantsComponent implements OnInit, OnDestroy {
  loadedCar: string;
  variantList: any = [];
  copyVariantList: any;
  selectedVariant: any;
  globalPinNO: any;
  isChangeVariantDone: boolean = true;
  isCarDoorOpen: boolean;
  colorList: any;
  selectedColor: any;
  ACCESS: boolean;
  sunRoofIsOpen: boolean;
  headLampIsOpen: boolean;
  uniqueVariantCat: any[];
  isInterior: boolean;
  price: any;
  $changeVariant: Subscription;
  SOCKET_STATUS: string;
  audioCallConnected: boolean;
  $seatingOptionChangeSubs: Subscription;
  $variantSubs: Subscription;
  createNewPinWhenModelChange: boolean;
  hectorSeatingOptions: string = '';
  limitedEdition: boolean;
  isLoading: boolean = false;

  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _ga: GaService,
    private _callS: CallService,
    private router: Router,
    private zone: NgZone,
    private location: Location,
    private _event: EventService
  ) {}

  ngOnInit(): void {
    window['variantRef'] = {
      zone: this.zone,
      one3dcallback: {
        onVariantLoadComplete: () => this.onVariantLoadComplete(),
      },
      component: this,
    };
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.globalPinNO = data));
    this._global.price.subscribe((data) => (this.price = data));
    this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data));
    this._global.sunRoofIsOpen.subscribe((data) => (this.sunRoofIsOpen = data));
    this._global.headLampIsOpen.subscribe(
      (data) => (this.headLampIsOpen = data)
    );
    this._global.createNewPinWhenModelChange.subscribe(
      (data) => (this.createNewPinWhenModelChange = data)
    );
    this._global.isInterior.subscribe((data) => (this.isInterior = data));
    this._global.limitedEditionUI.subscribe(
      (data) => (this.limitedEdition = data)
    );
    this._global.colorList.subscribe((data) => (this.colorList = data));
    this._global.selectedColor.subscribe((data) => (this.selectedColor = data));
    this._global.hectorSeatingOptions.subscribe((data) => {
      if (data) {
        this.hectorSeatingOptions = data + 'S';
        let filteredSeat = this.variantList.filter(
          (ele) => ele.seats_no == this.hectorSeatingOptions
        );
        this.uniqueVariantCat = [
          ...new Set(filteredSeat.map((item) => item.variant_name)),
        ];
        let defaultV = filteredSeat.filter((ele) => ele.is_default == 1);
        if (
          defaultV.length > 0 &&
          ONE3D.loadedPercent == 100 &&
          !this.audioCallConnected
        ) {
          this.onVariantClick(defaultV[0]);
        }
        // if (this.loadedCar === 'hectorplus') {

        // }
        // this.changeSeatingOption(data)
      } else {
        this.hectorSeatingOptions = '';

        this.uniqueVariantCat = [];
      }
      
    });

    this._global.variant.subscribe((data) => {
      this.selectedVariant = data;
      // alert(JSON.stringify(data))
    });

    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.socketStatus.subscribe((data) => (this.SOCKET_STATUS = data));
    this._global.audioStatus.subscribe(
      (data) => (this.audioCallConnected = data)
    );

    this.$variantSubs = this._global.variantList.subscribe((data) => {
      this.variantList = data;
      let filteredSeat = this.variantList;
      this.uniqueVariantCat = [
        ...new Set(filteredSeat.map((item) => item.variant_name)),
      ];

      console.log('this.uniqueVariantCat inside', this.uniqueVariantCat);
    });
    // this.variantList = this._data.variantList;
    this.registerSubscriber();
    console.log('this.uniqueVariantCat outside', this.uniqueVariantCat);

    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      ONE3D.toggleDoors(100, 0);
    }
    if (this.headLampIsOpen) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this._global.updateSunRoofState(false);
      ONE3D.toggleSunroof(100, 0);
    }
    window.sendData({ eName: 'page_view', eCat: 'variants_page' });
  }

  ngOnDestroy(): void {
    this.$changeVariant.unsubscribe();
    // this.$seatingOptionChangeSubs.unsubscribe();
    // this.$variantSubs.unsubscribe();
    console.log('this.isCarDoorOpen', this.isCarDoorOpen);

    if (this.isCarDoorOpen) {
      this._global.updateDoorState(false);
      if (this.audioCallConnected) ONE3D.toggleDoors(100, 0);
      else ONE3D.toggleDoors();
    }
    if (this.headLampIsOpen) {
      this._global.updateHeadLampState(false);
      ONE3D.toggleLights(false);
    }
    if (this.sunRoofIsOpen) {
      this._global.updateSunRoofState(false);
      // if (this.audioCallConnected) ONE3D.toggleSunroof(100, 0);
      // else
      ONE3D.toggleSunroof();
    }
    window['variantRef'] = null;
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      this.loadLottieFiles();
    }, 100);
  }
  loadLottieFiles() {
    var params = {
      container: document.getElementById('lottieVarint'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationDataForVariant,
    };

    var anim;

    anim = lottie.loadAnimation(params);
  }
  onVariantLoadComplete() {
    console.log('%c Variant load compt', 'background: #222; color: #bada55');
    // ONE3D.loadedPercent = 100;
    setTimeout(() => {
      if (this.audioCallConnected) {
        this._socket.socket.emit('dataTransfer', {
          room: this._socket.room,
          variantLoadCompleteOnClient: null,
        });
      }
      if (!this.isInterior) {
        this.isChangeVariantDone = true;
        console.log('this.selectedColor =>>>>>>>>>>>', this.selectedColor);
        ONE3D.changeColor(this.selectedColor.hex_code);
        if (this.selectedColor.edition.length == 0) {
          setTimeout(() => {
            document.getElementById('variantLoader').style.display = 'none';
          }, 1000);
        } else {
        }
        window.updatePayloadParameter('eLa3', this.selectedColor.color_name);
        this.changeColoredVariant('yes');
      } else {
        this.isChangeVariantDone = true;
        ONE3D.changeColor(this.selectedColor.hex_code);
        if (this.selectedColor.edition.length == 0) {
          // setTimeout(() => {
          document.getElementById('variantLoader').style.display = 'none';
          // }, 1500);
        } else {
        }
        this.changeColoredVariant('yes');
      }
    }, 1000);
    // throw new Error('Method not implemented.');
  }
  enableUiAfterComplete() {
    throw new Error('Method not implemented.');
  }

  // async getVariantList() {
  //   try {
  //     this._global.showLoading(true);
  //     let apiData = {
  //       fuel_type: '',
  //       transmission: '',
  //       sel_range: '',
  //       model_id: deafultCarDeatils[this.loadedCar].model_id,
  //       source: 'web',
  //     };

  //     let apiResp: any = await this._api.getVariantList(apiData);
  //     if (apiResp.status === 1) {
  //       this.variantList = apiResp.response;
  //       this._data.variantList = this.variantList;
  //       this.copyVariantList = this.variantList;
  //       this.setSelectedVariant();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // setSelectedVariant() {
  //   let variant = this._data.getLocalVariant();
  //   console.log('defaultVariant', variant);

  //   if (variant) {
  //     let foundVariant = this.variantList.filter(
  //       (ele) => ele.variant_id == variant
  //     );
  //     if (foundVariant.length > 0) {
  //       this.selectedVariant = foundVariant[0];
  //       this._global.updatedVariant(this.selectedVariant);
  //       this._global.updatedPrice(this.selectedVariant.price);
  //     } else {
  //       // this.selectedVariant = this.variantList[0];
  //       let selectV = this.variantList.filter(
  //         (ele) =>
  //           ele.variant_id == deafultCarDeatils[this.loadedCar].variant_id
  //       );
  //       this.selectedVariant = selectV[0];
  //       this._global.updatedVariant(this.selectedVariant);
  //       this._global.updatedPrice(this.selectedVariant.price);
  //     }
  //   } else {
  //     // this.selectedVariant = this.variantList[0];
  //     let selectV = this.variantList.filter(
  //       (ele) => ele.variant_id == deafultCarDeatils[this.loadedCar].variant_id
  //     );
  //     this.selectedVariant = selectV[0];
  //     this._global.updatedVariant(this.selectedVariant);
  //     this._global.updatedPrice(this.selectedVariant.price);
  //   }
  //   this.getVariantDetails();
  //   // if (this.globalPinNO != '') {
  //   // } else {
  //   //   this._global.showLoading(false);
  //   // }
  // }

  onVariantClick(variant: any) {
    console.log('###  >>> variant data >', variant);
    this.isLoading = true;
    try {
      if (
        this.selectedVariant.variant_id !== variant.variant_id &&
        this.isChangeVariantDone
      ) {
        window.sendData({
          eName: 'click',
          eCat: 'variants_menu',
          eAct: 'variant_selection_button_clicked',
        });
        console.log('Inner');
        document.getElementById('variantLoader').style.display = 'block';
        this._global.showLoading(true);
        this._data.setLocalVariant(variant.variant_id);
        this._global.updatedVariant(variant);
        this._global.updatedPrice(variant.price);
        this.isChangeVariantDone = false;
        ONE3D.removeAllAccessories();
        console.log('Dname=>>>', this.getShowFeatureValue(variant));

        ONE3D.changeVariant(variant.d_name, {
          showFeature: this.getShowFeatureValue(variant),
          callback: () => {
            console.log('Variant load complete');
            setTimeout(() => {
              this.isLoading = false;
            }, 1500);
          },
        });

        window.updatePayloadParameter('eLa2', this.selectedVariant.title);
        localStorage.removeItem('selectedAmbientId');
        localStorage.removeItem('selectedColorInt');
        this._ga.sendGAEvent('Variant', 'click', variant.title);
        sessionStorage.removeItem('selectedColorInt');
        this._global.updateCarExchangePrice('');
        this._global.updateAccApplied(false);

        if (this.isCarDoorOpen) {
          this._global.updateDoorState(false);
        }
        if (this.headLampIsOpen) {
          this._global.updateHeadLampState(false);
        }
        if (this.sunRoofIsOpen) {
          this._global.updateSunRoofState(false);
        }
        if (this.ACCESS) {
          this._socket.emitChangeVariant(variant);
        }

        sessionStorage.removeItem('selectedProtectionPlan');
        this.getVariantDetails();
      }
    } catch (error) {
      this.isLoading = false;
      console.error('An error occurred during variant change:', error);
      // Handle or log the error as needed
    } finally {
      // Any code you want to execute regardless of an error
    }
  }

  getShowFeatureValue(variant: variant): boolean {
    if (this.loadedCar == 'hectorplus' || this.loadedCar == 'hectorplusnew') {
      if (this.hectorSeatingOptions == '6S') {
        return (
          variant.variant_id == defaultCarDetails[this.loadedCar].variant_id
        );
      } else {
        return variant.variant_id == 59 || variant.variant_id == 119;
      }
    } else if (this.loadedCar == 'gloster') {
      if (this.hectorSeatingOptions == '6S') {
        return (
          variant.variant_id == defaultCarDetails[this.loadedCar].variant_id
        );
      } else {
        return variant.variant_id == 93;
      }
      // let variant = this.variantList.find(
      //   (ele) =>
      //     ele.is_default == 1 && ele.seats_no == this.hectorSeatingOptions
      // );
      return variant.variant_id == 93 || variant.variant_id == 88;
    } else if (this.loadedCar == 'zsev') {
      return true;
    } else {
      return variant.variant_id == defaultCarDetails[this.loadedCar].variant_id;
    }
  }
  async getVariantDetails() {
    console.log('Api call happening');
    try {
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this._data.getLocalVariant()
          ? this._data.getLocalVariant()
          : defaultCarDetails[this.loadedCar].variant_id,
        source: 'web',
      };
      let apiResp: any = await this._api.getVariantDetails(apiData);
      if (apiResp.status === 1) {
        // this.colorList = apiResp.response.varinat_color;
        // setTimeout(() => {
        // this.isLoading=false;
        // }, 1000);
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });
        this._global.updateColorList(apiResp.response.varinat_color);
        this._global.updateAmbientColorList(apiResp.response.ambient_light);
        this.setSelectedColor();
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
    }
  }
  setSelectedColor() {
    let selectedColorCode = this._data.getLocalColor();
    console.log('selectedColorCode', selectedColorCode);

    if (selectedColorCode) {
      let foundColorItem = this.colorList.filter(
        (ele) => ele.variant_feature_id == selectedColorCode
      );
      if (foundColorItem.length > 0) {
        // this.selectedColor = foundColorItem[0];
        this._global.updateSelectedColor(foundColorItem[0]);
        this._data.setLocalColor(foundColorItem[0].variant_feature_id);
      } else {
        console.log('foundColorItem222', this.colorList[0]);
        // this.selectedColor = this.colorList[0];
        let filteredD = this.colorList.find((ele) => ele.default == 1);
        if (filteredD) {
          this._global.updateSelectedColor(filteredD);
          this._data.setLocalColor(filteredD.variant_feature_id);
        }
      }
    } else {
      console.log('foundColorItem333', this.colorList[0]);
      // this.selectedColor = this.colorList[0];
      let filteredD = this.colorList.find((ele) => ele.default == 1);
      if (filteredD) {
        this._global.updateSelectedColor(filteredD);
        this._data.setLocalColor(filteredD.variant_feature_id);
      }
    }
  }
  async changeColoredVariant(flag = '') {
    try {
      let locationInfo = JSON.parse(localStorage.getItem('location_info'));
      let intColorId = localStorage.getItem('selectedColorInt');
      this._global.showLoading(true);
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this.selectedVariant.variant_id,
        variant_feature_id: this.selectedColor.variant_feature_id,
        state: locationInfo ? locationInfo.state : 'Delhi',
        city: locationInfo ? locationInfo.city : 'Delhi',
        change_variant: flag,
        source: 'web',
        interior_color_id: intColorId ? intColorId : '',
        page: 'feature',
      };
      let apiResp: any = await this._api.checkVariantColorSelection(apiData);
      if (apiResp.status == 1) {
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'updated',
        });
        this._global.updatedPrice(apiResp.response.showroom_price);
        if (apiResp.response && apiResp.response.variant_feature_id) {
          if (apiResp.response.hasOwnProperty('color_category')) {
            if (apiResp.response.color_category == 'interior') {
              localStorage.setItem(
                'selectedColorInt',
                apiResp.response.variant_feature_id
              );
            } else if (apiResp.response.color_category == 'exterior') {
              this._global.updatedPrice(apiResp.response.showroom_price);
              // let selectdC = this.colorList.filter(
              //   (ele) =>
              //     ele.variant_feature_id == apiResp.response.variant_feature_id
              // );
              // this.selectedColor = selectdC[0];
              // console.log('this.selectedColor', this.selectedColor);
              // this._data.setLocalColor(apiResp.response.variant_feature_id);
            }
          }
          console.log('apiResp.response.edition', apiResp.response.edition);
          // setTimeout(() => {
          document.getElementById('variantLoader').style.display = 'none';
          // }, 1500);
          if (this.selectedColor) {
            if (
              this.selectedColor.edition &&
              this.selectedColor.edition.length > 0
            ) {
              this._global.updateLimitedEditionData(apiResp.response.edition);
              this._global.updateSelectedEditionData(
                apiResp.response.edition[0]
              );
              this._global.updateShowLimitedPackFlag(true);
              this._global.updateLimitedEdition3D(true);
              this._global.updateLimitedEditionUI(true);
            } else {
              this._global.updateShowLimitedPackFlag(false);
              this._global.updateLimitedEditionData([]);
              if (this.limitedEdition == true) {
                this._global.updateLimitedEditionUI(false);
                this._global.updateLimitedEdition3D(false);
              }
            }
          } else {
            console.error('default color missing in color array');
          }
          // if (apiResp.response.edition && apiResp.response.edition.length > 0) {
          //   this._global.updateShowLimitedPackFlag(true);
          //   this._global.updateLimitedEditionData(apiResp.response.edition);
          //   this._global.updateSelectedEditionData(apiResp.response.edition[0]);
          //   let addedEdition = apiResp.response.edition.find(e => (e.flag == "Remove"));
          //   if (addedEdition) {
          //     this._global.updateLimitedEdition3D(true);
          //     this._global.updateLimitedEditionUI(true);
          //   } else {
          //     this._global.updateLimitedEdition3D(false);
          //     this._global.updateLimitedEditionUI(false);
          //   }
          // } else {
          //   this._global.updateShowLimitedPackFlag(false);
          //   this._global.updateLimitedEditionData([]);
          //   if (this.limitedEdition == true) {
          //     this._global.updateLimitedEditionUI(false);
          //     this._global.updateLimitedEdition3D(false);
          //   }
          // }
        }
      }
    } catch (error) {
      document.getElementById('variantLoader').style.display = 'none';
      console.error(error);
    }
  }

  returnVariantListByFilter(variant_name: string) {
    // console.log('variant_name', variant_name);
    return this.variantList.filter(
      (ele) =>
        ele.variant_name == variant_name &&
        ele.seats_no == this.hectorSeatingOptions
    );
  }

  gotoAccPage() {
    window.sendData({
      eName: 'click',
      eCat: 'variants_menu',
      eAct: 'accessorize_button_clicked',
    });
    this._ga.sendGAEvent('Variant', 'click', 'Accessories');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this._api.saveBuildSummary(this.globalPinNO, 'accessories');

    this.router.navigate([`${url}/accessorize`]);
    if (this.audioCallConnected && this.ACCESS) {
      this._socket.socket.emit('dataTransfer', {
        room: this._socket.room,
        gotoAccpage: null,
      });
    }
  }
  gotoFeaturePage() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/features`]);
    ONE3D.exitAccessoriesView();
    this._global.updateAccCam(false);
    this._global.updateOverlaySrc('');
    document.getElementById('featureImageFullScreen').style.display = 'none';
    this.router.navigate([`${url}/single-feature-play`]);
  }
  gotoEMICalculatorPage() {
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/emi-calculator`]);
  }
  registerSubscriber() {
    this.$changeVariant = this._event.subscribe('change_variant', (data) => {
      let seat =
        data.variant.seats_no == '6S'
          ? hectorPlusSeating.sixSeater
          : hectorPlusSeating.sevenSeater;
      if (data.variant.seats_no != this.selectedVariant.seats_no) {
        this._global.updateHectorSeatingOption(seat);
      }
      this.onVariantClick(data.variant);
    });
  }
  /**
   * goto summary page on click of CTA Summary
   */
  gotoSummaryPage() {
    window.sendData({
      eName: 'click',
      eCat: 'variants_menu',
      eAct: 'summary_button_clicked',
    });
    this._ga.sendGAEvent('Variant', 'click', 'Summary');
    this._api.saveBuildSummary(this.globalPinNO, 'proceed_click');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/summary`]);
    // this.router.navigate(['/hector/summary']);
  }

  openCarCompareModal() {
    console.log('Called');
    this._ga.sendGAEvent('Variant', 'click', 'compare');
    this._global.toggleCarCompareModal(true);
  }

  goBack() {
    window.sendData({
      eName: 'click',
      eCat: 'variants_menu',
      eAct: 'back_button_clicked',
    });
    this._global.updateSoundTrackURL('');
    let url = defaultCarDetails[this.loadedCar].urlScheme;

    this.router.navigate([`${url}/home`]);
    // this.location.back();
  }
}
