import { Component, OnInit } from '@angular/core';
declare var window;
declare var ONE3D;
@Component({
  selector: 'app-new-loader',
  templateUrl: './new-loader.component.html',
  styleUrls: ['./new-loader.component.scss']
})
export class NewLoaderComponent implements OnInit {
 
  p :any  = 0
  constructor() { 
    document.addEventListener("percentFired", ((e: any) => {
      this.p = e.detail.percent 
      window.anim.setCurrentRawFrameValue(Math.round(e.detail.percent/2));
    }));
  }

  ngOnInit(): void {
    window.loadLoader();
  }

}
