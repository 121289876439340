import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SocketService } from './socket.service';
import { GlobalService } from './global.service';

declare var AgoraRTC: any;
declare var ONE3D: any;
declare var $: any;
let selectedMicrophoneId;
const localTracks = {
  // videoTrack: null,
  audioTrack: null,
};

@Injectable({
  providedIn: 'root',
})
export class CallService implements OnInit, OnDestroy {
  CHANNEL_NAME: string;
  TOKEN: string;
  uID: string;
  CALL_CONNECTED: boolean = false;
  arModalOpen: boolean;
  agora_APP_ID: string;
  client: any;
  remoteUsers: any = {};
  agentDetails: any;
  isDealer: boolean;
  constructor(public _socket: SocketService, public _global: GlobalService) {
    this.CHANNEL_NAME = `chanel_${window.pin_no}`;
    if (window.location.host == 'cc.mgmotor.co.in') {
      this.agora_APP_ID = '059566fcef394ffea9fd89abcc18db76';
    } else {
      this.agora_APP_ID = 'ccd61a2f415f44b88a36686dbc2663bd';
    }
    this.isDealer = location.hash.includes('?pin_no');
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this._global.isArModalOpen.subscribe((data) => (this.arModalOpen = data));
    this._global.callingAgentDetails.subscribe((data) => {
      console.log('Updating agent details....');

      this.agentDetails = data;
    });
  }
  ngOnDestroy(): void {
    // this._socket.socket.off('connect_call_admin');
  }

  joinCall(data: any) {
    this.client = AgoraRTC.createClient({
      mode: 'rtc',
      codec: 'vp8',
    });
    // AgoraRTC.getDevices().then((devices) => {
    //   const audioDevices = devices.filter(function (device) {
    //     return device.kind === 'audioinput';
    //   });

    //   selectedMicrophoneId = audioDevices[0].deviceId;
    // });
    // let [secret, salt] = this.getSecretAndSalt();
    // this.client.setEncryptionConfig('aes-256-gcm2', secret, salt);
    this.CHANNEL_NAME = data.chanel;
    this.TOKEN = data.token;
    console.log('this.CHANNEL_NAME', this.CHANNEL_NAME);

    if (this.CHANNEL_NAME && this.TOKEN) {
      this.join();
      console.log('this.agentDetails--->', this.isDealer);
      window.updatePayloadParameter('eLa7', true);
      window.updateMultiplePayloadParameter({
        eLa8: data.ip,
        eLa10: this.isDealer ? 'dealer' : 'agent',
      });
      window.sendData({
        eName: 'internal',
        eCat: 'call_connected',
        eAct: '',
      });
      // setTimeout(() => {
      // }, 3 * 1000);
    } else {
      console.error('this', this.TOKEN);
      console.error('this', this.CHANNEL_NAME);
    }
  }
  endCall() {
    let vGrid: HTMLElement = document.querySelector('.video-grid');
    if (vGrid) vGrid.style.display = 'none';
    this.CALL_CONNECTED = false;
    window.sendData({
      eName: 'internal',
      eCat: 'call_disconnect',
    });
    window.updatePayloadParameter('eLa7', false);

    this.leave();
  }
  muteCall() {
    this.mute();
  }
  unmuteCall() {
    this.unMute();
  }

  private async join() {
    try {
      this.client.on('user-published', this.handleUserPublished.bind(this));
      this.client.on('user-unpublished', this.handleUserUnpublished.bind(this));
      this.client.on('user-left', this.handleUserLeft.bind(this));

      [this.uID, localTracks.audioTrack] = await Promise.all([
        // join the channel
        this.client.join(this.agora_APP_ID, this.CHANNEL_NAME, this.TOKEN , 0),
        // create local tracks, using microphone
        AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: selectedMicrophoneId,
        }),
        // AgoraRTC.createCameraVideoTrack(),
      ]);
      // play local video track
      // localTracks.videoTrack.play('local-player');
      // $('#local-player-name').text(`localVideo(${this.uID})`);

      // publish local tracks to channel
      await this.client.publish([
        localTracks.audioTrack,
        // localTracks.videoTrack,
      ]);
      this.CALL_CONNECTED = true;
      console.log('publish success');
      setTimeout(() => {
        ONE3D.event.onResizeWindow();
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  }

  fetchToken() {
    // return new Promise((res,rej)=>{
    //   this.apiService.refreshAgoraToken().subscribe((data:any)=>{
    //     if(data.status==200){
    //       this.TOKEN = data.response.token;
    //       this.CHANNEL_NAME = data.response.channel_name;
    //       res(this.TOKEN);
    //     }else{
    //       console.error('err from server',data);
    //     }
    //   })
    // });
  }

  updateToggle() {
    console.log('12222222222222222');
  }
  private async mute() {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setMuted(true);
  }
  private async unMute() {
    if (!localTracks.audioTrack) return;
    await localTracks.audioTrack.setMuted(false);
  }

  private async leave() {
    for (const trackName in localTracks) {
      var track = localTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        localTracks[trackName] = undefined;
      }
    }

    // remove remote users and player views
    this.remoteUsers = {};
    $('#remote-playerlist').html('');

    // leave the channel
    await this.client.leave();

    // $("#local-player-name").text("");
    // $("#join").attr("disabled", false);
    // $("#leave").attr("disabled", true);
    console.log('client leaves channel success');
  }

  // Declare a function to convert Base64 to Uint8Array.
  base64ToUint8Array(base64Str: string): Uint8Array {
    const raw = window.atob(base64Str);
    const result = new Uint8Array(new ArrayBuffer(raw.length));

    for (let i = 0; i < raw.length; i += 1) {
      result[i] = raw.charCodeAt(i);
    }

    return result;
  }

  // Declare a function to convert Hex to ASCII.
  hex2ascii(hexx: string) {
    const hex = hexx.toString(); //force conversion
    let str = '';
    for (let i = 0; i < hex.length; i += 2)
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
  }

  getSecretAndSalt() {
    // Suppose the getSecretAndSaltFromServer method returns the secret key and salt value obtained from the server ["dba643c8ba6b6dc738df43d9fd624293b4b12d87a60f518253bd10ba98c48453", "X5w9T+50kzxVOnkJKiY/lUk82/bES2kATOt3vBuGED="]
    let [secret, salt] = this.getSecretAndSaltFromServer();
    let newSalt = this.base64ToUint8Array(salt);
    secret = this.hex2ascii(secret);
    return [secret, newSalt];
  }
  getSecretAndSaltFromServer() {
    const secret =
      'dde5cbc6d1093e1ccb21f1c7e758d4771d17ee33ac874b1ee6b84dfe4b1a1bc2';
    const salt = '4BXXljExD3G1n2ZvfJp730vG2L8zA12XEJc//QW+FOA=';
    return [secret, salt];
  }

  // async subscribe(user, mediaType) {
  //   try {
  //     console.log('subscribe success...111');
  //     const uid = user.uid;
  //     console.log('subscribe success...222', uid);
  //     // subscribe to a remote user
  //     await this.client.subscribe(user, mediaType);
  //     if (mediaType === 'video') {
  //       const player = $(`
  //         <div id="player-wrapper-${uid}">
  //           <p class="player-name">remoteUser(${uid})</p>
  //           <div id="player-${uid}" class="player"></div>
  //         </div>
  //       `);

  //       $('#remote-playerlist').append(player);
  //       user.videoTrack.play(`player-${uid}`);
  //     }
  //     if (mediaType === 'audio') {
  //       user.audioTrack.play();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  async handleUserPublished(user: any, mediaType: string) {
    console.log('Callled......222', user, mediaType);

    const id = user.uid;
    this.remoteUsers[id] = user;
    try {
      // document.getElementById('video').style.display = 'block';
      const uid = user.uid;
      // subscribe to a remote user
      await this.client.subscribe(user, mediaType);
      if (mediaType === 'video') {
        console.log('Join as video....');
        let playerWrapper = document.createElement('div');
        let playerDiv = document.createElement('div');
        playerWrapper.id = `player-wrapper-${uid}`;
        playerWrapper.className = `video-placeholder new-video-placeholder`;
        playerDiv.id = `player-${uid}`;
        playerDiv.className = `player`;
        playerWrapper.append(playerDiv);
        let remotePlayerList = document.querySelector('#remote-playerlist');
        if (remotePlayerList) {
          remotePlayerList.append(playerWrapper);
        }
        // const player = $(`
        //   <div id="player-wrapper-${uid}">
        //     <div id="player-${uid}" class="player"></div>
        //   </div>
        // `);

        // $('#remote-playerlist').append(player);
        user.videoTrack.play(`player-${uid}`);
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleUserUnpublished(user: any) {
    const id = user.uid;
    delete this.remoteUsers[id];

    // $(`#player-wrapper-${id}`).remove();
  }
  handleUserLeft(user: any, reason: string) {
    const id = user.uid;
    // delete this.remoteUsers[id];

    $(`#player-wrapper-${id}`).remove();
  }
}

// function publish(rtc) {
//   console.log('publishinggg');
//   if (!rtc.client) {
//     return;
//   }
//   if (rtc.published) {
//     return;
//   }
//   var oldState = rtc.published;

//   // publish localStream
//   rtc.client.publish(rtc.localStream, function (err) {
//     rtc.published = oldState;
//     console.log('publish failed');
//     console.error(err);
//   });
//   rtc.published = true;
// }
// function addView(id, show) {
//   if (!$('#' + id)[0]) {
//     $('<div/>', {
//       id: 'remote_video_panel_' + id,
//       class: 'video-view new-video',
//       style: show ? 'display: block' : 'display: none',
//     }).appendTo('#video');

//     $('<div/>', {
//       id: 'remote_video_' + id,
//       class: 'video-placeholder new-video-placeholder',
//     }).appendTo('#remote_video_panel_' + id);
//     // $(
//     //   '<span class="new-video-cross-btn"><img src="assets/images/cross-white.svg"/></span>',
//     //   {
//     //     class: 'new-video-cross-btn',
//     //   }
//     // ).appendTo('#remote_video_panel_' + id);

//     $('<div/>', {
//       id: 'remote_video_info_' + id,
//       class: 'video-profile ' + (show ? '' : 'hide'),
//     }).appendTo('#remote_video_panel_' + id);

//     $('<div/>', {
//       id: 'video_autoplay_' + id,
//       class: 'autoplay-fallback hide',
//     }).appendTo('#remote_video_panel_' + id);
//   }
// }
// function removeView(id) {
//   if ($('#remote_video_panel_' + id)[0]) {
//     $('#remote_video_panel_' + id).remove();
//   }
// }
