<!--containt-start-->
<section>
  <!--variants comparison-->
  <div class="vcompareOuter">
    <!--vcomparew-->
    <div class="fg vcomparew">
      <div class="fg vcompareTopbox">
        <!--top fix title-->
        <div class="vcomparemaintitle">
          <div class="vcomparemaintitlein">
            <button
              class="vcClose"
              *ngIf="!callConnected"
              (click)="closeModal()"
            >
              <img src="./assets/images/blc-close-icon.svg" />
            </button>
            <!-- <span>VARIANT COMPARISON</span> -->
            <span>COMPARE</span>
          </div>
        </div>
        <!--top fix title-->

        <!--vcompareTopRow for desktop-->
        <div class="fg compareTopRow" [ngClass]="{ 'pointer-block': !ACCESS }">
          <div class="compareTopRoww">
            <div class="compareRow1Col1 compareRow1Colbrd">
              <div class="vcompareTopRowlinfo">
                <div class="coradio2new coradio2newdesk">
                  <input
                    id="hidefeaturesdesk"
                    type="checkbox"
                    name="comparison"
                    [(ngModel)]="isChecked"
                    (change)="hideCommnFEature()"
                  />
                  <label for="hidefeaturesdesk">Hide Common Features</label>
                </div>
              </div>
            </div>
            <div class="compareRow1Col2 compareRow1Colbrd">
              <div class="vcompareTopRowlinfo">
                <div class="vcompareTopRowlinfoctitle1">
                  <span>{{ selectedVariant.title }}</span>
                </div>
                <div class="vcompareTopRowlinfoctitle2">
                  ₹ {{ selectedVariant.price }}
                </div>
                <div class="vcompareTopRowlinfoctitle3">
                  (Ex-showroom price, {{ locationInfo.city }})
                </div>
              </div>
            </div>
            <!-- <div class="compareRow1Col2 compareRow1Colbrd">
              <div class="compareRow1Col2Close">
                <button>
                  <img src="./assets/images/objectcloseicon.svg" />
                </button>
              </div>
              <div class="vcompareTopRowlinfo">
                <div class="vcompareTopRowlinfoctitle1">
                  HECTOR SHARP HYBRID MT
                </div>
                <div class="vcompareTopRowlinfoctitle2">₹ 17,42,800</div>
                <div class="vcompareTopRowlinfoctitle3">
                  (on-road price, Delhi)<span><a href="#">Change</a></span>
                </div>
              </div>
            </div> -->
            <!-- <div class="compareRow1Col2 compareRow1Colbrd">
              <div class="compareRow1Col2Close">
                <button>
                  <img src="./assets/images/objectcloseicon.svg" />
                </button>
              </div>
              <div class="vcompareTopRowlinfo">
                <div class="vcompareTopRowlinfoctitle1">
                  HECTOR SHARP HYBRID MT
                </div>
                <div class="vcompareTopRowlinfoctitle2">₹ 17,42,800</div>
                <div class="vcompareTopRowlinfoctitle3">
                  (on-road price, Delhi)
                </div>
              </div>
            </div> -->

            <!-- 2nd row -->
            <div
              class="compareRow1Col2"
              [ngClass]="{
                compareRow1Colbrd: isSelectedSecondVariant()
              }"
            >
              <div
                class="vcompareTopRowlinfo"
                *ngIf="isSelectedSecondVariant()"
              >
                <div class="fg compareRow1Coltitle1">
                  Choose a variant to compare
                </div>
                <div class="fg compareRow1Coltitlecvc">
                  <div class="custom-select mngpaym">
                    <select
                      (change)="setSecondModal()"
                      [(ngModel)]="selectedSecondVariantId"
                    >
                      <option selected disabled value="0">
                        Select Variant
                      </option>
                      <option
                        *ngFor="let item of variantList"
                        [value]="item.variant_id"
                        [disabled]="
                          selectedVariant.variant_id == item.variant_id
                        "
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="!isSelectedSecondVariant()">
                <!-- <div class="compareRow1Col2Close">
                  <button>
                    <img src="./assets/images/objectcloseicon.svg" />
                  </button>
                </div> -->
                <div class="vcompareTopRowlinfo">
                  <div class="vcompareTopRowlinfoctitle1">
                    {{ selectedSecondVariantDetails.title }}
                  </div>
                  <div class="vcompareTopRowlinfoctitle2">
                    ₹ {{ selectedSecondVariantDetails.price }}
                  </div>
                  <div class="vcompareTopRowlinfoctitle3">
                    (Ex-showroom price, {{ locationInfo.city }})<span
                      ><a
                        href="javascript:void(0)"
                        *ngIf="!callConnected"
                        (click)="resetVariant()"
                        >Change</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- 3rd row -->
            <!-- <div
              class="compareRow1Col2"
              [ngClass]="{
                compareRow1Colbrd: isSelectedSecondVariant()
              }"
            >
              <div
                class="vcompareTopRowlinfo"
                *ngIf="isSelectedSecondVariant()"
              >
                <div class="fg compareRow1Coltitle1">
                  Choose a variant to compare
                </div>
                <div class="fg compareRow1Coltitlecvc">
                  <div class="custom-select mngpaym">
                    <select
                      (change)="setSecondModal()"
                      [(ngModel)]="selectedSecondVariantId"
                    >
                      <option selected disabled value="0">
                        Select Variant
                      </option>
                      <option
                        *ngFor="let item of variantList"
                        [value]="item.variant_id"
                        [disabled]="
                          selectedVariant.variant_id == item.variant_id
                        "
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="!isSelectedSecondVariant()">
                <div class="compareRow1Col2Close">
                  <button>
                    <img src="./assets/images/objectcloseicon.svg" />
                  </button>
                </div>
                <div class="vcompareTopRowlinfo">
                  <div class="vcompareTopRowlinfoctitle1">
                    {{ selectedSecondVariantDetails.title }}
                  </div>
                  <div class="vcompareTopRowlinfoctitle2">
                    ₹ {{ selectedSecondVariantDetails.price }}
                  </div>
                  <div class="vcompareTopRowlinfoctitle3">
                    (Ex-showroom price, {{ locationInfo.city }})<span
                      ><a
                        href="javascript:void(0)"
                        *ngIf="!callConnected"
                        (click)="resetVariant()"
                        >Change</a
                      ></span
                    >
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!--vcompareTopRow for desktop-->

        <!--Hide Common Features-->
        <div
          class="fg cohidefeaturesmob"
          [ngClass]="{ 'pointer-block': !ACCESS }"
        >
          <!--vcompareTopRow for mob-->
          <div class="fg vcompareTopRow">
            <div class="fg vcompareTopRowl">
              <div class="vcompareTopRowlinfo">
                <div class="vcompareTopRowlinfotitle1">
                  {{ selectedVariant.title }}
                </div>
                <div class="vcompareTopRowlinfotitle2">
                  ₹ {{ selectedVariant.price }}
                </div>
                <div class="vcompareTopRowlinfotitle3">
                  (Ex-showroom price, {{ locationInfo.city }})
                </div>
              </div>
            </div>
            <div class="fg vcompareTopRowr" *ngIf="isSelectedSecondVariant()">
              <div class="fg compareRow1Coltitle1">
                Choose a variant to compare
              </div>
              <div class="fg compareRow1Coltitlecvc">
                <div class="custom-select mngpaym">
                  <select
                    (change)="setSecondModal()"
                    [(ngModel)]="selectedSecondVariantId"
                  >
                    <option selected disabled value="0">Select Variant</option>
                    <option
                      *ngFor="let item of variantList"
                      [value]="item.variant_id"
                      [disabled]="selectedVariant.variant_id == item.variant_id"
                    >
                      {{ item.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="fg vcompareTopRowr" *ngIf="!isSelectedSecondVariant()">
              <div class="vcompareTopRowlinfo">
                <div class="vcompareTopRowlinfotitle1">
                  {{ selectedSecondVariantDetails.title }}
                </div>
                <div class="vcompareTopRowlinfotitle2">
                  ₹ {{ selectedSecondVariantDetails.price }}
                </div>
                <div class="vcompareTopRowlinfotitle3">
                  (Ex-showroom price,
                  {{ locationInfo ? locationInfo.city : "Delhi" }})
                  <span>
                    <a
                      href="javascript:void(0)"
                      (click)="resetVariant()"
                      *ngIf="!callConnected"
                      >Change</a
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!--vcompareTopRow for mob-->

          <!--Hide Common Features-->
          <div class="fg vcomparemaintitlecheck">
            <div class="coradio2new">
              <input
                id="hidefeatures"
                type="checkbox"
                name="comparison"
                [(ngModel)]="isChecked"
                (change)="hideCommnFEature()"
              />
              <label for="hidefeatures">Hide Common Features</label>
            </div>
          </div>
          <!--Hide Common Features-->
        </div>
        <!--Hide Common Features-->

        <!--tab-->
        <div
          class="vctab"
          *ngFor="
            let item of compareData && compareData[selectedVariant.title]
              | keyvalue : returnZero;
            let inx = index
          "
          [attr.data-feature]="item.key"
          style="scroll-margin-block-start: 116px"
        >
          <!--tab title-->
          <div
            class="vctabtitleOuter"
            [ngClass]="{ tabactive: OpenAccordionIndex == item.key }"
          >
            <!--tabactive-->
            <div class="vctabtitleOuterw">
              <a href="javascript:void(0)" (click)="togglEAccordion(item.key)"
                ><div class="vctabtitle">{{ item.key }}</div></a
              >
            </div>
          </div>
          <!--tab title-->
          <!--tab info-->
          <div
            class="vctabinfo"
            [ngStyle]="{
              display: OpenAccordionIndex == item.key ? 'block' : 'none'
            }"
          >
            <!--vctabinfoRow1-->
            <div
              class="vctabinfoRow1"
              *ngFor="
                let cmpdata of item.value | keyvalue : returnZero;
                let locIndex = index
              "
            >
              <div class="vctabinfoRow1w">
                <div class="vctabinfoRow1left1">
                  {{ cmpdata.value.AttributeName }}
                </div>
                <ng-container
                  *ngIf="cmpdata.value.AttributeValue.includes('Yes')"
                >
                  <div
                    class="vctabinfoRow1left2"
                    *ngIf="cmpdata.value.AttributeValue.includes('Yes')"
                  >
                    <img src="./assets/images/greentik.svg" alt="img" />
                  </div>

                  <div class="vctabinfoRow1left3">
                    <img
                      src="./assets/images/{{
                        returnValueForSecondKey(item.key, cmpdata.key) == 'Yes'
                          ? 'greentik.svg'
                          : 'redtik.svg'
                      }}"
                      alt="img"
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="cmpdata.value.AttributeValue.includes('No')"
                >
                  <div
                    class="vctabinfoRow1left2"
                    *ngIf="cmpdata.value.AttributeValue.includes('No')"
                  >
                    <img src="./assets/images/redtik.svg" alt="img" />
                  </div>

                  <div class="vctabinfoRow1left3">
                    <img
                      src="./assets/images/{{
                        returnValueForSecondKey(item.key, cmpdata.key) == 'Yes'
                          ? 'greentik.svg'
                          : 'redtik.svg'
                      }}"
                      alt="img"
                    />
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    !cmpdata.value.AttributeValue.includes('Yes') &&
                    !cmpdata.value.AttributeValue.includes('No')
                  "
                >
                  <div class="vctabinfoRow1left2">
                    {{ cmpdata.value.AttributeValue }}
                  </div>
                  <div class="vctabinfoRow1left3">
                    {{ returnValueForSecondKey(item.key, cmpdata.key) }}
                  </div>
                </ng-container>
              </div>
            </div>
            <!--vctabinfoRow1-->
          </div>
          <!--tab info-->
        </div>
        <!--tab-->
      </div>
      <!--wrapper-->

      <!--vcompareOverlay-->
      <div class="vcompareOverlay"></div>
      <!--vcompareOverlay-->
    </div>

    <!--vcomparew-->
  </div>
  <!--variants comparison-->
</section>
<!--containt-end-->
