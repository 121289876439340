<!--header-start-->
<header>
  <!--main-logo-->
  <div class="topLogo">
    <a href="javascript:void(0)"
      ><img src="./assets/images/mg-logo.svg" alt="img"
    /></a>
    <div class="topLogohector">
      <img src="./assets/images/gloster_logo.svg" />
    </div>
  </div>
  <!--main-logo-->
</header>
<!--header-end-->

<!--new pincode flow code-->

<div class="connectionpopupouter">
  <div class="connectionpopupouterw">
    <!--row 1 pincode-->
    <div
      class="fg new-connect-with-expert-wrapper connectionpopupouterwrapper"
      *ngIf="isVisible[0]"
    >
      <div class="fg connectionpopupouter-title1">
        <i><img src="./assets/images/pincode-location-big.svg" /></i>
        <span>Nearest Dealerships with your Gloster</span>
        <p>
          Provide your Pin Code to get the fastest delivery of Gloster from
          dealers near you
        </p>
        <div (click)="closePopup(0)" class="connectionpopupouter-close">
          <button>
            <img src="./assets/images/close-connectinn-icon.svg" />
          </button>
        </div>
      </div>

      <div class="secObjectlbtmform">
        <form [formGroup]="pincodeForm" (ngSubmit)="submitPincode()">
          <div
            class="fg floating-label coobjectinputlable rightconfirmbtninput"
          >
            <input
              [(ngModel)]="pincode"
              class="coobjectinputpin"
              placeholder="Enter Pincode"
              type="number"
              (keypress)="numberOnly($event)"
              onKeyPress="if(this.value.length==6) return false;"
              id="pincode"
              formControlName="pincode"
            />
            <label>Pincode</label>
            <div
              class="fg errmessagered"
              *ngIf="
                pincodeForm.controls['pincode'].invalid &&
                (pincodeForm.controls['pincode'].dirty ||
                  pincodeForm.controls['pincode'].touched)
              "
            >
              <div
                class="fg"
                *ngIf="pincodeForm.controls['pincode'].errors.required"
              >
                Pincode is required.
              </div>
              <div
                class="fg"
                *ngIf="
                  isPinSubmitted &&
                  pincodeForm.controls['pincode'].errors.pattern
                "
              >
                Pincode must be a 6-digit number.
              </div>
            </div>
            <div
              class="fg errmessagered"
              *ngIf="
                noDataForPin &&
                !(
                  pincodeForm.controls['pincode'].invalid &&
                  (pincodeForm.controls['pincode'].dirty ||
                    pincodeForm.controls['pincode'].touched)
                )
              "
            >
              <div class="fg">No Dealers. Change Pincode.</div>
            </div>
            <div class="rightconfirmbtn">
              <button
                class="otpbuttonareafull"
                (click)="submitPincode()"
                [disabled]="pincodeForm.invalid"
              >
                <span>Confirm</span>
              </button>
            </div>
          </div>
        </form>
        <!--input-->
        <div class="orsignbrd"><span>or</span></div>
        <div class="fg coobjectinputlable coobjectinputlablelast">
          <button
            [disabled]="isLocationBtnDisable"
            class="otpbuttonareafull"
            (click)="getLocation()"
          >
            <span><img src="./assets/images/location-access-icon.svg" /></span
            ><span>Allow location access</span>
          </button>
        </div>

        <!--<div *ngIf="!pincodeForm.invalid" class="fg coobjectinputlable coobjectinputlablelast">
          <button class="otpbuttonareafull" (click)="submitPincode()" [disabled]="pincodeForm.invalid"><span>Confirm</span></button>
        </div>-->
      </div>
    </div>
    <!--row 1 pincode-->

    <!--row 2 dealer contact-->
    <div
      class="fg new-connect-with-expert-wrapper connectionpopupouterwrapper connectionpopupouterwrapperspacew"
      *ngIf="isVisible[1]"
    >
      <div class="fg connectionpopupouterwrapperspace">
        <div class="fg connectionpopupouter-title1">
          <span>Which dealer should contact you?</span>
          <div (click)="closePopup(1)" class="connectionpopupouter-close">
            <button>
              <img src="./assets/images/close-connectinn-icon.svg" />
            </button>
          </div>
        </div>
      </div>
      <div class="fg connectionpopupouterwrapperspace-row">
        <div class="fg connectionpopupouterwrapperspace-row-title">
          Showing dealers for pincode: <b>{{ pincode }}</b>
          <div (click)="goBack('pincode')" class="editpin">
            <a><span>Edit</span></a>
          </div>
        </div>
      </div>

      <div class="fg locationListing">
        <ul *ngIf="dealerList.length > 0">
          <li *ngFor="let dealer of dealerList">
            <div class="locationListingRadio">
              <div class="coradio3 coradioadd">
                <input
                  [id]="dealer.dealer_id"
                  type="radio"
                  name="dealerName"
                  [value]="dealer.dealer_id"
                  [(ngModel)]="selectedDealer"
                  (click)="selectDealer(dealer)"
                />
                <label [for]="dealer.dealer_id"></label>
              </div>
            </div>

            <!-- <div class="locationListing-row1">{{dealer?.company_name}}</div> -->
            <div class="locationListing-row2">
              <div class="locationIcon">
                <img src="./assets/images/location-list-icon.svg" />
              </div>
              <address>
                {{ dealer?.facillity_description }}, {{ dealer?.city }}
              </address>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--row 2 dealer contact-->

    <!--row 3 name/number-->
    <div
      class="fg new-connect-with-expert-wrapper connectionpopupouterwrapper"
      *ngIf="isVisible[2]"
    >
      <div class="connectionpopupouter-title1">
        <span>Almost there! How would you like to be addressed?</span>
        <div (click)="closePopup(2)" class="connectionpopupouter-close">
          <button>
            <img src="./assets/images/close-connectinn-icon.svg" />
          </button>
        </div>
      </div>
      <!--<p class="one3d-sub-heading-text">
      Get your questions answered by our expert live on a call.
    </p>-->
      <!--secObjectlbtmform-->
      <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
        <div class="secObjectlbtmform">
          <!--input-->

          <div
            class="floating-label coobjectinputlable"
            [ngClass]="{
              errmessageredinput: hasError('name', 'required')
            }"
          >
            <div class="fg">
              <input
                class="coobjectinputpin"
                type="text"
                formControlName="name"
                placeholder="<e.g. suvin kumar>"
              />
            </div>
            <label>Enter your Name</label>
            <div class="fg errmessagered" *ngIf="hasError('name', 'required')">
              Please Fill First Name
            </div>
            <div class="fg errmessagered" *ngIf="hasError('name', 'pattern')">
              Please Enter A Valid Name
            </div>
          </div>
          <!--input-->

          <!--input-->
          <div
            class="floating-label coobjectinputlable"
            [ngClass]="{
              errmessageredinput:
                hasError('mobile', 'required') || hasError('mobile', 'pattern')
            }"
          >
            <!--mobile number-->
            <div class="fg">
              <input
                class="coobjectinputpin"
                type="tel"
                formControlName="mobile"
                maxlength="10"
                placeholder="<e.g. 9999999999>"
                (keyup)="onChangeMobileNo()"
                (click)="onCheckboxChange(iAgree)"
              />
              <!-- <label>Mobile Number *</label> -->
              <!--<div class="coobjectinputlablerightspacebtn">
                <button class="submitbuttonobject" *ngIf="enableSendOTPButton" type="button" (click)="sendOTP()">
                  Send OTP
                </button>
                
              </div>-->
            </div>
            <label>Enter your Mobile Number</label>
            <!--err message-->
            <div class="errGroup">
              <div class="errmessagered" *ngIf="hasError('mobile', 'required')">
                Please Fill Mobile Number
              </div>
              <div class="errmessagered" *ngIf="hasError('mobile', 'pattern')">
                Please Enter Valid 10 Digit Mobile Number
              </div>
              <!-- <div class="coobjectinputlablerightspacebtn">
              <button class="submitbuttonobject" *ngIf=" queryForm.controls['name'].valid && queryForm.controls['mobile'].valid" type="button" (click)="sendOTP()">
                Send OTP
              </button>
              
            </div> -->
            </div>
            <!--err message-->
          </div>
          <div
            class="floating-label coobjectinputlable"
            [ngClass]="{
              errmessageredinput: hasError('carName', 'required')
            }"
          >
            <div class="fg">
              <input
                class="coobjectinputpin"
                type="text"
                formControlName="carName"
                maxlength="70"
                placeholder="E.g. ( MG Hector 2020)"
              />
            </div>
            <label>Which car do you currently own?</label>
            <div
              class="fg errmessagered"
              *ngIf="hasError('carName', 'required')"
            >
              Please Fill Car Name
            </div>
            <div
              class="fg errmessagered"
              *ngIf="hasError('carName', 'pattern')"
            >
              Please Enter A Valid Name
            </div>
            <div
              class="fg errmessagered"
              *ngIf="hasError('carName', 'minlength')"
            >
              Car Name should be more than two characters
            </div>
          </div>

          <!--input-->
          <div class="fg">
            <!--otpbuttonareafullouter2-->
            <div class="pincodeLeft">
              <div
                class="floating-label coobjectinputlable"
                *ngIf="showOTPField"
                [ngClass]="{
                  errmessageredinput:
                    hasError('otp', 'required') || failedVerifiedOpt
                }"
              >
                <input
                  class="coobjectinputpin"
                  value=""
                  type="tel"
                  formControlName="otp"
                  maxlength="4"
                  placeholder="Enter OTP"
                  (click)="onCheckboxChange(iAgree)"
                />
                <label>OTP</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('otp', 'required') || failedVerifiedOpt"
                >
                  OTP is not correct
                </div>
              </div>
            </div>

            <div class="pincodeRight">
              <div class="floating-label coobjectinputlable">
                <!-- <div class="pincodesRighttop"><div class="one3d-copy-static"> Didn’t get an OTP? </div></div> -->
                <div class="pincodesRightbtm">
                  <!-- otpbuttonarearesend
              otpbuttonenable -->
                  <button
                    [ngClass]="
                      showResendOtpCounter
                        ? 'otpbuttonarearesend'
                        : 'otpbuttonenable'
                    "
                    *ngIf="enableTimer"
                    type="button"
                    (click)="
                      sendOTP();
                      _ga.sendGAEvent('Form_INFO', 'click', 'Resend_OTP')
                    "
                    [disabled]="showResendOtpCounter"
                    (click)="onCheckboxChange(iAgree)"
                  >
                    {{
                      showResendOtpCounter
                        ? "Resend in " + countdown + "s"
                        : "Resend OTP"
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="term-space-outer">
            <div class="fg term-space">
              <div class="tprivacycenter">
                <div class="termcheck mngcheckBox">
                  <input
                    id="Dealer-assisted"
                    type="checkbox"
                    formControlName="dealerAssit"
                    value=""
                  />
                  <label for="Dealer-assisted"
                    >I want a Dealer-assisted Virtual Tour of the car</label
                  >
                </div>
              </div>
              <!-- <div class="fg errmessagered">Please Select the Checkbox</div> -->
            </div>

            <div class="fg term-space">
              <div class="tprivacyleft">
                <div class="termcheck mngcheckBox">
                  <input
                    id="iagree-privacy"
                    type="checkbox"
                    formControlName="privacyPolicyCheckbox"
                    value=""
                    checked
                  />
                  <label for="iagree-privacy">I agree to the</label>
                </div>
              </div>
              <div class="tprivacyright">
                <a target="_blank" href="https://www.mgmotor.co.in/privacy"
                  ><span>Privacy Policy</span></a
                >
              </div>
              <div
                class="fg errmessagered"
                *ngIf="hasError('privacyPolicyCheckbox', 'required')"
              >
                Please Select the Checkbox
              </div>
            </div>
          </div>

          <div class="fg otpbuttonareafullouter">
            <div class="otpbuttonarea-left">
              <button
                (click)="goBack('dealerList')"
                [disabled]="enableTimer"
                type="button"
                class="otpbuttonareabrdback"
              >
                Back
              </button>
            </div>
            <div class="otpbuttonarea-right">
              <button
                class="otpbuttonareafull"
                type="button"
                [disabled]="queryForm.invalid"
                (click)="onSubmit()"
              >
                Continue
              </button>
            </div>
          </div>

          <!--<div class="fg secObjectlbtmformbtn">
            <div class="secObjectlbtmformbtnright">
              
            </div>
          </div>-->
        </div>
      </form>
    </div>
    <!--row 3 name/number-->

    <!--row 4 Thank you-->
    <div
      class="fg new-connect-with-expert-wrapper connectionpopupouterwrapper"
      *ngIf="isVisible[3]"
    >
      <div class="fg connectionpopupouter-title1">
        <span>Thank you, {{ apiDataObj?.customer_name }}</span>
        <p>
          Someone from our team in
          <b>{{ selectedDealerObj?.company_name }}</b> will be in touch with
          you.
        </p>
      </div>

      <div class="secObjectlbtmform">
        <div
          (click)="continue()"
          class="fg coobjectinputlable coobjectinputlablelast"
        >
          <button class="otpbuttonareafull">
            <span>Continue browsing</span>
          </button>
        </div>
      </div>
    </div>
    <!--row 4 Thank you-->
  </div>
</div>

<div
  class="connectionpopupouter-overlay"
  *ngIf="isVisible[0] || isVisible[1] || isVisible[2] || isVisible[3]"
></div>

<!--new pincode flow code-->

<!--sec object-->
<div class="secObject secObjectmaincontainerleftfull">
  <!--right object-->
  <div class="secObjectr" *ngIf="agentDetails.username && audioCallStatus">
    <div
      class="secObjectwidth284"
      [ngClass]="{
        'grant-microphone-permission-added': enableGrantAccessModal
      }"
    >
      <!--top-->
      <div class="fg secObjectltop">
        <div class="secObjectltopleft">
          <agent-audio [agentData]="agentDetails"></agent-audio>
          <div
            class="secObjectltopleftthumb2 secObjectltopleftthumbcall"
            *ngIf="audioCallStatus"
          >
            <div
              class="secObjectltopleftthumbcallright microphone"
              *ngIf="!isAgentMute"
              (click)="muteCall()"
            >
              <a href="javascript:void(0)">
                <!-- <img src="./assets/images/end-call-icon.svg" /> -->
                <span class="material-icons md-light md-20"> mic </span>
              </a>
            </div>
            <div
              class="secObjectltopleftthumbcallright microphone"
              *ngIf="isAgentMute"
              (click)="unmuteCall()"
            >
              <a href="javascript:void(0)">
                <!-- <img src="./assets/images/end-call-icon.svg" /> -->
                <span class="material-icons md-light md-20"> mic_off </span>
              </a>
            </div>
            <div class="secObjectltopleftthumbcallright">
              <a href="javascript:void(0)" (click)="onClickEndCall()"
                ><img src="./assets/images/end-call-icon.svg"
              /></a>
            </div>
          </div>
        </div>

        <!--grant-microphone-permission-->
        <div class="grant-microphone-permission" *ngIf="enableGrantAccessModal">
          <div class="grant-microphone-permission-box">
            <span class="grant-microphone-permission-icon">
              <img
                src="./assets/images/grant-microphone.svg"
                class="grant-microphone-icon-desktop"
              />
              <img
                src="./assets/images/grant-microphone-mobile.svg"
                class="grant-microphone-icon-mobile"
              />
            </span>
            <span class="grant-microphone-permission-content">
              GRANT MICROPHONE PERMISSION
            </span>
          </div>
        </div>
        <!--grant-microphone-permission-->
      </div>
      <!--top-->
    </div>
  </div>
  <!--right object-->
</div>
<!--sec object-->

<div
  class="grant-microphone-permission-overlay"
  *ngIf="enableGrantAccessModal"
></div>
<!--sec object -->
<!-- 'new-summary-expand': HIDE_3D, commented by @JP for full screen error  -->
<div
  class="secObject"
  [ngClass]="{
    secObjectfull: isCarCompareModalOpen
  }"
>
  <!--left home icon-->
  <div class="secObjectl" *ngIf="!hideAllIcons && homePageCheck()">
    <div class="secObjectlwidth40" [hidden]="SOCKET_STATUS == 'connected'">
      <a (click)="gotoHome()"
        ><img src="./assets/images/homeicon.svg" alt="img"
      /></a>
    </div>
  </div>
  <!--left home icon-->
  <!--right object-->
  <div class="secObjectr">
    <div [hidden]="agentDetails.username && audioCallStatus">
      <app-olivia-audio
        *ngIf="loadedCar"
        [isEnqFormOpen]="isOpenPulseHubEnqForm"
        [audioURL]="soundTrackURL"
        (audioComplete)="audioCompleted($event)"
      ></app-olivia-audio>
    </div>
    <!-- </div> -->
  </div>
  <!--right object-->
</div>
<!--sec object-->
<!-- <div class="objectOverlay" *ngIf="isOpenPulseHubEnqForm"></div> -->
<!--containt-start-->
<section>
  <!--color-set-->
  <div
    class="colorarea colorAccordionForScreen"
    [hidden]="
      !ACCESS ||
      isAccCamSet ||
      colorList.length == 0 ||
      HIDE_3D ||
      hideColorsOnSummary()
    "
  >
    <button
      class="colorListUParow"
      *ngIf="colorList.length > 6 && !scrollPos.isTop"
      (click)="scrollUpwards()"
    >
      <img src="./assets/images/slider-top-arow.svg" />
    </button>
    <div class="colorList">
      <ul>
        <li
          *ngFor="let color of colorList"
          [ngClass]="{ active: color == selectedColor }"
        >
          <!-- <abbr [title]="color.color_name"> -->
          <a
            href="javascript:void(0)"
            [matTooltip]="color.color_name"
            matTooltipPosition="right"
            (click)="onSelectColor(color)"
          >
            <div class="pathafinder" style="background: none">
              <div
                class="colorbagtop"
                [ngStyle]="{
                  background: returnColor1(color.color_palet_hex)
                }"
              ></div>
              <div
                class="colorbagbtm"
                [ngStyle]="{
                  background: returnColor2(color.color_palet_hex)
                }"
              ></div>
            </div>
          </a>
          <!-- </abbr> -->
        </li>
      </ul>
    </div>
    <button
      class="colorListDOWNarow"
      *ngIf="colorList.length > 6 && !scrollPos.isBottom"
      (click)="scrollDownColors()"
    >
      <img src="./assets/images/slider-bottom-arow.svg" />
    </button>
  </div>
  <!--color-set-->

  <!--interior-set || hideAllIcons-->
  <div class="interiorarea" [hidden]="!ACCESS || isAccCamSet || HIDE_3D">
    <div class="interiorList">
      <ul>
        <li
          [hidden]="
            hideHotspotOnAccPage() || hideColorsOnSummary() || hideAllIcons
          "
          [ngClass]="{ active: isCarDoorOpen }"
          class="one3d-doors-toggle"
        >
          <!-- <abbr title="Door"> -->
          <a
            matTooltip="Door"
            href="javascript:void(0)"
            [ngClass]="{ disabled: doorInProgress }"
            (click)="toggleDoor()"
          >
            <b
              ><img
                src="./assets/images/{{
                  isCarDoorOpen ? 'door_open.svg' : 'door_close.svg'
                }}"
                alt="img"
            /></b>
          </a>
          <!-- </abbr> -->
        </li>

        <li
          [hidden]="
            hideHotspotOnAccPage() ||
            isInterior ||
            hideColorsOnSummary() ||
            hideAllIcons
          "
          [ngClass]="{ active: headLampOn }"
          class="one3d-lights-toggle"
        >
          <!-- <abbr title="Head Lamp"> -->
          <a
            matTooltip="Head Lamp"
            href="javascript:void(0)"
            (click)="toggleHeadLamp()"
          >
            <b
              ><img
                src="./assets/images/{{
                  headLampOn ? 'headlamp_on.svg' : 'headlamp_off.svg'
                }}"
                alt="img"
            /></b>
          </a>
          <!-- </abbr> -->
        </li>

        <li
          [hidden]="
            hideHotspotOnAccPage() ||
            hideColorsOnSummary() ||
            selectedVariant.sunroof == 'no' ||
            hideAllIcons
          "
          [ngClass]="{ active: sunRoofOn }"
          class="one3d-sunroof-toggle"
        >
          <!-- <abbr title="Sunroof"> -->
          <a
            matTooltip="Sunroof"
            href="javascript:void(0)"
            [ngClass]="{ disabled: sunRoofInProgress }"
            (click)="toggleRoof()"
          >
            <b
              ><img
                src="./assets/images/{{
                  sunRoofOn ? 'sunroof_on.svg' : 'sunroof_off.svg'
                }}"
                alt="img"
            /></b>
          </a>
          <!-- </abbr> -->
        </li>

        <li class="viewAr viewAr-mobile one3d-ar-icon" *ngIf="setViewArIcon()">
          <a
            rel="ar"
            (click)="onClickArGA()"
            [href]="sanitizer.bypassSecurityTrustUrl(urlForAr)"
            matTooltip="View in AR"
          >
            <img src="./assets/images/AR_LOGO.svg" alt="img" />
            <!-- <img src="./assets/images/ar_icon.svg" alt="img"
          /> -->
          </a>
        </li>

        <li class="one3d-ext-int-toggle">
          <!--canvasButtonArea-->
          <div class="canvasButtonArea1">
            <div class="middle">
              <label (click)="toggleIntExt('ext')">
                <input type="radio" name="radio" [checked]="!isInterior" />
                <div class="front-end box">
                  <span class="first-child">
                    <i
                      ><img src="./assets/images/ext-car-icon.svg" /><img
                        src="./assets/images/ext-carwhite-icon.svg"
                    /></i>
                    <i>EXT</i>
                  </span>
                </div>
              </label>

              <label (click)="toggleIntExt('int')">
                <input type="radio" name="radio" [checked]="isInterior" />
                <div class="back-end box">
                  <span class="first-child">
                    <i
                      ><img src="./assets/images/int-blc-icon.svg" /><img
                        src="./assets/images/int-in-icon.svg"
                      />
                    </i>
                    <i>INT</i></span
                  >
                </div>
              </label>
            </div>
          </div>
          <!--canvasButtonArea-->
        </li>
        <li class="one3d-seating-toggle" *ngIf="!audioCallStatus">
          <!-- Setting area -->

          <div
            class="seatingarea"
            *ngIf="isVariantPage() && !isEnqGenerated && !audioCallStatus"
          >
            <div class="seatingareaBG">
              <div class="seatingareal">
                <span
                  ><img src="./assets/images/color-seat.png" alt="img" /></span
                ><span class="seating-name">SEATING:</span>
              </div>
              <div class="seatingarear">
                <div
                  class="interiorRadio coradio"
                  *ngFor="let seating of seatingOptions; let i = index"
                >
                  <a
                    ><input
                      id="seat{{ i }}"
                      type="radio"
                      name="hectorSeatingOptions"
                      [(ngModel)]="hectorSeatingOptions"
                      [value]="seating"
                      (change)="onSeatingOptionChange(seating)"
                    /><label for="seat{{ i }}">{{ seating }}</label></a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Setting area -->
        </li>
      </ul>
    </div>
  </div>
  <!--interior-set-->
  <!-- <div class="back-ro-pos">
          <a>
            <img src="./assets/images/close-1.svg" alt="close" srcset="" />
            Back to Default Position
          </a>
        </div> -->

  <!--maincontainer start-->
  <div
    class="fg maincontainer"
    [ngClass]="{
      'selfexploreBlock selfexploreBlockq': isInFeaturePage(),
      'hide-3d': HIDE_3D
    }"
  >
    <!--maincontainerleft-->
    <div
      class="maincontainerleft mobile-fullscreen"
      [ngClass]="{
        'mobile-fullscreen':
          !ACCESS && _global.detectMob() && !DISPLAY_TO_CUSTOMER
      }"
      [ngStyle]="{
        width:
          (ACCESS && !isHiddenRightPanel) || DISPLAY_TO_CUSTOMER
            ? '73%'
            : '100%'
      }"
    >
      <div class="hideTabIcon" [hidden]="isAccCamSet || SOCKET_STATUS != ''">
        <a href="javascript:void(0)" (click)="hideRightPanel()"
          ><span>{{ !isHiddenRightPanel ? "HIDE" : "SHOW" }}</span></a
        >
      </div>
      <!--canvas-area-->
      <div
        class="canvasheight canvasarea"
        [ngClass]="{
          'mobile-fullscreen':
            !ACCESS && _global.detectMob() && !DISPLAY_TO_CUSTOMER
        }"
        [ngStyle]="{ 'pointer-events': !ACCESS ? 'none' : 'inherit' }"
        (click)="resetAccCam()"
      >
        <div id="one3d"></div>
        <div
          id="featureImageFullScreen"
          class="fullcreen-content"
          style="display: none"
        >
          <div
            class="canvasheight canvasarea"
            [ngClass]="{ 'one3d-full-height-video-image': !ACCESS }"
          >
            <img alt="" srcset="" [src]="overlaySrc" />
          </div>
        </div>
        <div
          id="featureVideoFullScreen"
          class="fullcreen-content"
          style="display: none"
        >
          <div
            class="canvasheight canvasarea"
            [ngClass]="{ 'one3d-full-height-video-image': !ACCESS }"
          >
            <app-vjs-player
              [muted]="true"
              [options]="videoOptions"
            ></app-vjs-player>
          </div>
        </div>
        <div
          id="featureCarousal"
          class="fullcreen-content"
          style="display: none"
        >
          <div class="canvasheight canvasarea" style="height: 937px"></div>
        </div>
        <div id="featureImagePopup">
          <img [src]="overlaySrc" alt="" srcset="" />
        </div>
        <div
          class="accessrizelistposibtn"
          *ngIf="isAccCamSet && isInAccessoryPage()"
        >
          <a
            href="javascript:void(0)"
            (click)="resetAccCam()"
            class="accessrizelistposibutton"
            ><span><img src="./assets/images/close-1.svg" /></span>
            <span>Back to Default Position</span>
          </a>
        </div>

        <!--image slider-->
        <div class="fullimageslider" id="carusleFeature">
          <div
            class="fg topslidermng topslider"
            *ngIf="featureImageList.length > 0"
          >
            <div *ngFor="let item of featureImageList">
              <div class="fullimagesliderinner">
                <img [src]="item" />
                <!-- <div class="fullimagesliderCaption">
                      <div class="fg fullimageslidertitle1">
                        Adaptive Cruise Control
                      </div>
                      <div class="fg fullimageslidertitle2">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text
                      </div>
                    </div> -->
              </div>
            </div>
          </div>
        </div>
        <div
          class="fullimageslideroverlay"
          *ngIf="featureImageList.length > 0"
        ></div>
        <!--image slider-->
      </div>

      <!--disclaimer-->
      <div class="one3d-disclaimer-inner" *ngIf="!_global.detectMob()">
        <div class="one3d-disclaimer-row-1">
          <p>
            <b>Disclaimer:</b> Specifications & colors shown here may vary from
            the actual product.
          </p>
        </div>
        <div class="one3d-disclaimer-row-2">
          <div class="one3d-branding-icon">
            <b
              ><img src="assets/images/one3d-branding-icon.svg" alt="eccentric"
            /></b>
          </div>
        </div>
      </div>
      <!--disclaimer-->

      <!--canvas-area-->
    </div>
    <!--maincontainerleft-->
    <!--maincontainerright-->

    <div
      class="maincontainerright"
      [ngClass]="{
        'new-interior-view-car': isInterior,
        'new-variant-has-trims': isInterior && colorList.length > 0,
        'new-accessories-added': isAccCamSet,
        fullshowsummary: HIDE_3D,
        'mobile-fullscreen': DISPLAY_TO_CUSTOMER
      }"
      [hidden]="
        _global.detectMob()
          ? !ACCESS && !DISPLAY_TO_CUSTOMER
          : (!ACCESS || isHiddenRightPanel) && !DISPLAY_TO_CUSTOMER
      "
    >
      <!--color option mobile-->
      <div class="colorarea colorListMob">
        <div
          class="colorList"
          [hidden]="
            isAccCamSet || hideColorsOnSummary() || colorList.length == 0
          "
        >
          <ul>
            <li
              *ngFor="let color of colorList"
              [ngClass]="{ active: color == selectedColor }"
            >
              <a href="javascript:void(0)" (click)="onSelectColor(color)">
                <div class="pathafinder" style="background: none">
                  <div
                    class="colorbagtop"
                    [ngStyle]="{
                      background: returnColor1(color.color_palet_hex)
                    }"
                  ></div>
                  <div
                    class="colorbagbtm"
                    [ngStyle]="{
                      background: returnColor2(color.color_palet_hex)
                    }"
                  ></div>
                </div>
                <div class="colorTitle" *ngIf="color == selectedColor">
                  {{ selectedColor.color_name }}
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!--color option mobile-->
      <router-outlet
        [hidden]="!isHiddenRightPanel && !DISPLAY_TO_CUSTOMER"
      ></router-outlet>
    </div>
  </div>

  <!--continue-viewing-popup-->
  <div class="soundcheckmain" *ngIf="openFeatureCompletePopup">
    <!--popup-->
    <div class="soundcheckmaininner">
      <div class="soundcheckmainOuter soundcheckmainOuter2">
        <div class="soundcheckbg">
          <div class="fg cvbftitle">
            Would you like to view the Next burst of features?
          </div>

          <div class="fg soundcheckbtnarea">
            <div class="soundcheckbtnarealeft">
              <a
                href="javascript:void(0)"
                (click)="gotoVariant()"
                class="btnplayright"
                ><span>Skip</span></a
              >
            </div>
            <div class="soundcheckbtnarearight">
              <a
                href="javascript:void(0)"
                class="btnplayleft"
                (click)="palyMoreFeatures()"
                ><span>Continue</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--popup-->

    <!--overlay-->
    <div class="soundcheckmainOverlay soundcheckmainOverlay2"></div>
    <!--overlay-->
  </div>
  <!--continue-viewing-popup-->
</section>
<!--containt-end-->
<app-location-modal *ngIf="openLocationModal"></app-location-modal>
<app-car-compare *ngIf="isCarCompareModalOpen"></app-car-compare>
<app-thank-you-modal *ngIf="isSubmitModalOpen"></app-thank-you-modal>
<feedback-form *ngIf="isFeedBackModelOpen"></feedback-form>
<app-ar-video-overlay *ngIf="openArModal"></app-ar-video-overlay>
<app-end-call-modal
  *ngIf="toggleEndCallModal"
  (dismiss)="onModalDismiss($event)"
></app-end-call-modal>
<div id="variantLoader">
  <div class="myProgress">
    <div class="inner-progress">
      <div id="progressBarV"></div>
    </div>
    <p>Loading your Car</p>
  </div>
</div>

<div
  class="one3d-explore-hide-button-wrap"
  *ngIf="_global.detectMob() && !homePageCheck()"
>
  <button class="one3d-explore-hide-button" (click)="hideRightPanel()">
    <img src="./assets/images/one3d-arrow-up.svg" />
    <span class="one3d-explore-text">Explore</span>
    <span class="one3d-hide-text">Hide</span>
  </button>
</div>
<!-- <div class="disclaimer" *ngIf="!_global.detectMob()">
  <strong>Disclaimer:</strong> Specifications & colors shown here may vary from
  the actual product.
</div> -->
