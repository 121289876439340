import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../service/data.service';
import { ApiService } from '../service/api.service';
import { GlobalService } from '../service/global.service';
import { GaService } from '../service/ga.service';
import { defaultCarDetails } from './../three-d-module/carSettings';
import { Howl, Howler } from 'howler';
declare var window: any;

@Component({
  selector: 'app-intro-video',
  templateUrl: './intro-video.component.html',
  styleUrls: ['./intro-video.component.scss'],
})
export class IntroVideoComponent implements OnInit {
  muted: boolean = false;
  showQueryForm: boolean = false;
  videoOptions: any = {};
  queryForm: FormGroup;
  enableSendOTPButton: boolean;
  countdown: number;
  interval: any = 1000;
  iAgree: boolean = true;
  showOTPField: boolean;
  countDownTimeSubscription: any;
  enableTimer: boolean;
  showResendOtpCounter: boolean;
  oldNo: any;
  verifyOtpErr: boolean;
  failedVerifiedOpt: boolean;
  soundTrackURL: string = '';
  audioPlayed: boolean = false;
  isVideoPlaying: boolean = false;
  videoPayed: boolean;
  loadedCar: any;
  videoThumbNail: string;
  isSoundMuted: boolean;
  pinNo: string;
  constructor(
    public _data: DataService,
    public _api: ApiService,
    public _global: GlobalService,
    public _ga: GaService,
    public router: Router
  ) {}

  ngOnInit(): void {
    // let self = this;
    // this.sound = new Howl({
    //   src: [
    //     'https://dxf3kulhcrbjg.cloudfront.net/mg_expert/audio-journey/eXpert Introduction.wav',
    //   ],
    //   loop: false,
    //   volume: 0.8,
    //   onend: function () {
    //     self.audioCompleted();
    //   },
    // });

    this.loadedCar = this._data.getLocalCarBrand();
    console.log('this.loadedCar', this.loadedCar);

    this.videoThumbNail = this._global.detectMob()
      ? defaultCarDetails[this.loadedCar].video.intro.thumbnail_m
      : defaultCarDetails[this.loadedCar].video.intro.thumbnail_d;
    console.log('this.loadcar', this.loadedCar);
    // this.counter$ = timer(0, 1000).pipe(
    //   take(this.count),
    //   map(() => --this.count)
    // );
    this.soundTrackURL = defaultCarDetails[this.loadedCar].audio.intro.start;
    this._global.isSoundMuted.subscribe((data) => (this.isSoundMuted = data));
    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this.createForm();
    const pinNo = this._data.getLocalPinNo();
    if (!pinNo) {
      this.createNewConfig();
    }
    window.sendData({ eName: 'page_view' });
  }

  stopAudioPlaying() {
    this.soundTrackURL = '';
    this.audioCompleted(true);
    this._ga.sendGAEvent('intro', 'click', 'play');
    window.sendData({
      eName: 'click',
      eCat: 'intro_video',
      eAct: 'play_now_button_clicked',
    });
  }

  audioCompleted(status: boolean) {
    this.audioPlayed = status;
    if (!this.videoPayed && !this.showQueryForm) {
      this.videoPayed = true;
      this.isVideoPlaying = true;
      if (this._global.detectMob()) {
        console.log('here 0');
        this.videoOptions = {
          autoplay: true,
          controls: false,
          fluid: true,
          sources: [
            {
              src: defaultCarDetails[this.loadedCar].video.intro.mobile,
              type: 'application/x-mpegURL',
            },
          ],
        };
        console.log(defaultCarDetails[this.loadedCar].video.intro.mobile);
      } else {
        console.log('here 1');
        this.videoOptions = {
          autoplay: true,
          controls: false,
          fluid: true,
          sources: [
            {
              src: defaultCarDetails[this.loadedCar].video.intro.desktop,
              type: 'application/x-mpegURL',
            },
          ],
        };
      }
      window.sendData({
        eName: 'internal',
        eCat: 'intro_video',
        eAct: 'intro_video_autoplay',
        eVal: 'start',
      });
    }
  }
  createForm() {
    this.queryForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[ a-zA-Z\-\']+$/),
      ]),
      mobile: new FormControl('', {
        validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)],
      }),
      otp: new FormControl('', {
        validators: [Validators.required],
      }),
      // checkPrivacyPolicy : new FormControl(true, {
      //   validators: [Validators.required],
      // }),
    });
    // this.queryForm.invalid
  }

  async createNewConfig() {
    try {
      const userIP = await this.getIPAddress();
      console.log('--->IP', userIP);

      let apiData = {
        pin_no: '',
        mid: '',
        source: 'Web',
        city: 'Delhi',
        state: 'Delhi',
        variant_id: defaultCarDetails[this.loadedCar].variant_id,
        variant_feature_id:
          defaultCarDetails[this.loadedCar].variant_feature_id,
        utm: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        cust_id: '',
        ip: userIP,
      };

      let apiResp: any = await this._api.createNewConfiguration(apiData);
      console.log('apiResp', apiResp);
      if (apiResp.status === 1) {
        // this._data.variantList = apiResp.response.variants;
        this._ga.sendGAEvent('pin_no', 'click', apiResp.response.pin_no);
        window.updatePayloadParameter('pinId', apiResp.response.pin_no);
        window.sendData({
          eName: 'internal',
          eCat: 'configuration',
          eAct: 'created',
        });
        this._global.updatePinno(apiResp.response.pin_no);
        this._data.setLocalPinno(apiResp.response.pin_no);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async getIPAddress() {
    try {
      const apiIp: any = await this._api.getIpofUser();
      return apiIp.ip;
    } catch (error) {
      console.error(error);
    }
  }
  toggleMute() {
    console.log('this.isSoundMuted', this.isSoundMuted);

    this._global.updateSoundStatus(!this.isSoundMuted);
    // this.muted = !this.muted;
    // console.log(this.muted);
    if (this.isSoundMuted) {
      window.sendData({
        eName: 'click',
        eCat: 'intro_video',
        eAct: 'speaker_button_clicked',
        eVal: 'off',
      });
    } else {
      window.sendData({
        eName: 'click',
        eCat: 'intro_video',
        eAct: 'speaker_button_clicked',
        eVal: 'on',
      });
    }
  }
  videoCompleted(data) {
    console.log('video completed', data);
    if (data) {
      // this.showQueryForm = true;
      this.gotoHomePage();
      this.isVideoPlaying = false;
      this.audioPlayed = false;
      this._api.saveBuildSummary(this.pinNo, 'intro_view');
      this.soundTrackURL = this.soundTrackURL =
        defaultCarDetails[this.loadedCar].audio.intro.end;
      window.sendData({ eName: 'form_view', eCat: 'intro_video_lead_form' });
    }
    window.sendData({
      eName: 'internal',
      eCat: 'intro_video',
      eAct: 'intro_video_autoplay',
      eVal: 'stop',
    });
  }

  onChangeMobileNo() {
    if (this.queryForm.get('mobile').valid) {
      if (this.oldNo != this.queryForm.value.mobile) {
        this.enableSendOTPButton = true;
        this.oldNo = this.queryForm.value.mobile;
        this.enableTimer = false;
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) =>
    (this.queryForm.controls[controlName].touched ||
      this.queryForm.controls[controlName].dirty) &&
    this.queryForm.controls[controlName].hasError(errorName);

  async onSubmit() {
    // $e.preventDefault();
    try {
      this._ga.sendGAEvent('intro', 'Login_Intro', 'Verify_OTP');
      console.log('this.queryForm.value', this.queryForm.value);
      if (this.queryForm.valid) {
        clearInterval(this.interval);
        this.showResendOtpCounter = false;
        this.countdown = 30;
        let apiData = {
          mobile: this.queryForm.value.mobile.toString(),
          otp: this.queryForm.value.otp.toString(),
          pin_no: this._data.getLocalPinNo(),
          customer_name: this.queryForm.value.name,
          source: 'web',
        };
        let resp: string = await this._api.customerRegistration(apiData);
        let apiRespData: any = JSON.parse(atob(resp));
        if (apiRespData.status == 1) {
          // localStorage.setItem(
          //   'user_data',
          //   JSON.stringify(apiRespData.response)
          // );
          this._data.setUserDataInfo(apiRespData.response);
          sessionStorage.setItem('mobile', this.queryForm.value.phone);
          this.failedVerifiedOpt = false;
          this.gotoHomePage();
          this._ga.sendGAEvent('intro', 'Login_Intro', 'Submit');
          window.sendData({
            eName: 'click',
            eCat: 'intro_video_lead_form',
            eAct: 'submit_button_clicked',
            eVal: 'success',
          });
        } else {
          this.failedVerifiedOpt = true;
          window.sendData({
            eName: 'click',
            eCat: 'intro_video_lead_form',
            eAct: 'submit_button_clicked',
            eVal: 'fail',
          });
        }
      } else {
        console.log('Here 2', this.queryForm);

        Object.keys(this.queryForm.controls).forEach((field) => {
          const control = this.queryForm.get(field);
          control.markAsTouched({ onlySelf: true });
          control.markAsDirty({ onlySelf: true });
        });
      }
    } catch (error) {}
  }
  async sendOTP() {
    try {
      let payload = { mobile: this.queryForm.value.mobile, sourec: 'web' };
      let resp: string = await this._api.sendOtp(payload);
      window.sendData({
        eName: 'click',
        eCat: 'intro_video_lead_form',
        eAct: 'send_otp_button_clicked',
      });
      let apiResp: any = JSON.parse(atob(resp));
      console.log('resp', apiResp);
      if (apiResp.status == 1) {
        this.enableSendOTPButton = false;
        this.enableTimer = true;
        this.showOTPField = true;
        this.showResendOtpCounter = true;
        this.countdown = 30;
        this.interval = setInterval(() => {
          this.resendOtpCounter();
        }, 1000);
        this._ga.sendGAEvent('lead', 'Login_Intro', 'Send_OTP');
        this.showOTPField = true;
      }
    } catch (error) {
      console.error(error);
    }
  }
  resendOtpCounter() {
    if (this.countdown == 0) {
      clearInterval(this.interval);
      this.showResendOtpCounter = false;
    } else {
      // this.showResendOtpCounter = true;
      this.countdown--;
    }
  }
  
  logPolicyClick() {
    window.sendData({
      eName: 'click',
      eCat: 'intro_video_lead_form',
      eAct: 'privacy_policy_button_clicked',
    });
  }

  redirectToHome() {
    // this.injectAssetsToDOM()
    this._ga.sendGAEvent('intro', 'Login_Intro', 'Skip');
    // this._ga.sendGAEvent('Lead', 'click', 'skip');
    this.showQueryForm = true;
    this._ga.sendGAEvent('intro', 'click', 'skip');
    this._ga.sendGAEvent('Lead', 'click', 'skip');
    // this.showQueryForm = true;
    this.gotoHomePage();
    if (this.isVideoPlaying) {
      this.isVideoPlaying = false;
      this.videoPayed = false;
    } else {
      this.videoPayed = false;
    }
    this.audioPlayed = false;
    this.soundTrackURL = defaultCarDetails[this.loadedCar].audio.intro.end;
    window.sendData({
      eName: 'internal',
      eCat: 'intro_video',
      eAct: 'skip_button_clicked',
    });
  }

  gotoHomePage() {
    this._ga.sendGAEvent('intro', 'click', 'skip');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/home`]);
  }
  logSkipEvent() {
    window.sendData({
      eName: 'click',
      eCat: 'intro_video_lead_form',
      eAct: 'skip_button_clicked',
    });
  }

  getBrandLogo() {
    if (this.loadedCar == 'kmodel') {
      return './assets/images/astor_logo.svg';
    } else if (this.loadedCar == 'hector') {
      return './assets/images/hectorlogo.svg';
    } else if (this.loadedCar == 'zsev') {
      return './assets/images/mg_zsev_logo.svg';
    } else if (this.loadedCar == 'hectorplus') {
      return './assets/images/hectorpluslogo.svg';
    } else if (this.loadedCar == 'gloster') {
      return './assets/images/gloster_logo.svg';
    } else if (this.loadedCar == 'emodel') {
      return './assets/images/e-model-logo.svg';
    } else if (
      this.loadedCar == 'hectornew' ||
      this.loadedCar == 'hectorplusnew'
    ) {
      return './assets/images/new-gen-hec-logo.svg';
    }
  }
  /**
   * On Error playing the video, then get the mp4 link @JP
   */
  onErrorEvent(e) {
    if (!this._global.detectMob()) {
      //on error playing mobile version
      this.videoOptions = {
        autoplay: true,
        controls: false,
        fluid: true,
        sources: [
          {
            src: defaultCarDetails[this.loadedCar].video.intro.desktop_Mp4,
            type: 'video/mp4',
          },
        ],
      };
    } else {
      //on error playing desktop version
      this.videoOptions = {
        autoplay: true,
        controls: false,
        fluid: true,
        sources: [
          {
            src: defaultCarDetails[this.loadedCar].video.intro.mobile_Mp4,
            type: 'video/mp4',
          },
        ],
      };
    }
  }
  // /**
  //  * @Author JP
  //  * @param e iAgree value
  //  * used to make the Submit button enable & disable based on iAgree value
  //  */
  onCheckboxChange(e){
    console.log(e, 'checkboxEvent');
    console.log(this.queryForm);
    // if (this.queryForm.value.name!= '' && this.queryForm.value.mobile.length==10 && this.queryForm.value.otp!=''){
      if(!e && this.queryForm.valid){
        this.queryForm.setErrors({ 'invalid': true });
      } else if (e && this.queryForm.invalid){
        this.queryForm.setErrors(null);
        console.log(this.queryForm);
      }
    // }
  }
}
