<div class="profilePOPUP" id="protectionPlans">
  <div class="profilePopupOuter">
    <div class="profilePopupin">
      <div class="planPopupOuterw">
        <div class="planPopupOuter">
          <button
            class="shareProClosepopup selectLocation_close"
            [ngClass]="{
              'pointer-block': !ACCESS && callConnected
            }"
            (click)="closeProtectionPlanModal()"
          ></button>

          <h2 class="fg planmaintitle">CHOOSE A PROTECT PLAN</h2>
          <div
            class="tab-pannel"
            *ngIf="protectionPlanList && protectionPlanList.length > 0"
          >
            <div class="protectionnav tab-row" id="menutop">
              <ul>
                <li
                  class="ProPlan active"
                  *ngFor="let protection of protectionPlanList; let i = index"
                  (click)="onClickProtectionPlanTap(i, protection.plan_name)"
                  [ngClass]="{
                    'pointer-block': !ACCESS && callConnected
                  }"
                  [ngClass]="{ active: protectionTap[i] }"
                >
                  <a href="javaScript:void(0)">{{ protection?.plan_name }}</a>
                </li>
              </ul>
            </div>

            <!--tab-1-->
            <div class="fg profile-div showproplan">
              <div class="protectioninfo">
                <!--scroll-area-->

                <div
                  class="fg testDiv"
                  style="overflow: scroll; width: auto"
                  id="scrollD"
                >
                  <!--scroll-area-->
                  <div class="fg protectioninfodesk">
                    <p>{{ tabDescription }}</p>
                  </div>

                  <div
                    class="fg plantermtitle"
                    *ngIf="
                      threeYearsProtection && threeYearsProtection.length > 0
                    "
                  >
                    <span>3 YEARS</span>
                  </div>
                  <div
                    class="fg planlist"
                    *ngIf="
                      threeYearsProtection && threeYearsProtection.length > 0
                    "
                    #threeYearsProtectionul
                  >
                    <ul>
                      <li
                        *ngFor="
                          let threeYearsProtectionPlans of threeYearsProtection
                        "
                      >
                        <div class="planlistinner">
                          <div class="planlistinnert">
                            <h4 class="fg planlistinnert1">
                              {{ threeYearsProtectionPlans?.plan_type }}
                            </h4>
                            <h3 class="fg planlistinnert2">
                              <i class="fa fa-rupee" aria-hidden="true"></i
                              >{{ threeYearsProtectionPlans?.mrp }}
                            </h3>
                            <div class="fg planlistinnert3">
                              <p>
                                {{
                                  threeYearsProtectionPlans?.plan_type_description
                                }}
                              </p>
                            </div>
                          </div>
                          <div
                            *ngIf="!threeYearsProtectionPlans.is_added"
                            class="planlistinnerb"
                            [ngClass]="{
                              'pointer-block': !ACCESS && callConnected
                            }"
                            (click)="
                              onClickAddToPlan(threeYearsProtectionPlans, '3')
                            "
                            style="cursor: pointer"
                          >
                            <a>ADD TO BUILD</a>
                          </div>
                          <div
                            *ngIf="threeYearsProtectionPlans.is_added"
                            class="planlistinnerb planlistinnerbremove"
                            [ngClass]="{
                              'pointer-block': !ACCESS && callConnected
                            }"
                            (click)="
                              onClickRemoveToPlan(
                                threeYearsProtectionPlans.plan_id,
                                '3'
                              )
                            "
                            style="cursor: pointer"
                          >
                            <a>REMOVE</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!--planlist-->

                  <div
                    class="fg plantermtitle"
                    *ngIf="
                      fiveYearsProtection && fiveYearsProtection.length > 0
                    "
                  >
                    <span>5 YEARS</span>
                  </div>
                  <!--planlist-->
                  <div
                    class="fg planlist"
                    *ngIf="
                      fiveYearsProtection && fiveYearsProtection.length > 0
                    "
                    #fiveYearsProtectionul
                  >
                    <ul>
                      <li
                        *ngFor="
                          let fiveYearsProtectionPlans of fiveYearsProtection
                        "
                      >
                        <div class="planlistinner">
                          <div class="planlistinnert">
                            <h4 class="fg planlistinnert1">
                              {{ fiveYearsProtectionPlans?.plan_type }}
                            </h4>
                            <h3 class="fg planlistinnert2">
                              <i class="fa fa-rupee" aria-hidden="true"></i
                              >{{ fiveYearsProtectionPlans?.mrp }}
                            </h3>
                            <div class="fg planlistinnert3">
                              <p>
                                {{
                                  fiveYearsProtectionPlans?.plan_type_description
                                }}
                              </p>
                            </div>
                          </div>
                          <div
                            *ngIf="!fiveYearsProtectionPlans.is_added"
                            class="planlistinnerb"
                            [ngClass]="{
                              'pointer-block': !ACCESS && callConnected
                            }"
                            (click)="
                              onClickAddToPlan(fiveYearsProtectionPlans, '5')
                            "
                            style="cursor: pointer"
                          >
                            <a>ADD TO BUILD</a>
                          </div>
                          <div
                            *ngIf="fiveYearsProtectionPlans.is_added"
                            class="planlistinnerb planlistinnerbremove"
                            [ngClass]="{
                              'pointer-block': !ACCESS && callConnected
                            }"
                            (click)="
                              onClickRemoveToPlan(
                                fiveYearsProtectionPlans.plan_id,
                                '5'
                              )
                            "
                            style="cursor: pointer"
                          >
                            <a>REMOVE</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!--planlist-->
                  <!--scroll-area-->
                </div>

                <!--scroll-area-->
              </div>
            </div>
            <!--tab-1-->
          </div>
          <!--tab-pannel-->
          <div
            style="display: inline-flex"
            *ngIf="protectionPlanList && protectionPlanList.length == 0"
          >
            <!-- <p>No Plan Found.</p> -->
            <mat-spinner [diameter]="30" color="accent"></mat-spinner>
          </div>
        </div>
        <!--plans-->
      </div>
    </div>
  </div>
  <div class="profilePopupOverlay"></div>
</div>
