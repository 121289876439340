<!--bottom-->
<div class="fg secObjectlbtm">
  <div class="fg secObjectlbtminner new-main-schedule-live-consultation one3d-slc-success-wrapper"> 
      <div class="one3d-slc-success-thumbnail">
        <img [src]="'./assets/images/models/'+loadedCar+'.png'" alt="model image hector" width="100%" class="one3d-slc-success-thumbnail-image">
        <!-- <span class="one3d-slc-back-btn">
          <img src="./assets/images/one3d-back-btn-white.svg" alt="back btn white">
        </span> -->
      </div> 
      <div class="secObjectlbtmClose" (click)="onCloseClick('Close')"><a><img src="./assets/images/Close.svg"></a></div>
    <div class="one3d-slc-success-content">
      <div class="one3d-slc-heading">
        Your call with MG eXpert is set up for
      </div>
      <div class="secObjectlbtmform">
        <div class="user-details-wrapper">
          <div class="details-title">
            Date
            <span class="details-title-icon">
              <img src="./assets/images/new-date-icon-red.svg" />
            </span>
          </div>
          <div class="details-value">
            {{appointmentDate | date:'dd/MM/yyyy'}}
            <!-- {{this.queryForm.value.name}} -->
          </div>
        </div>
        <div class="user-details-wrapper">
          <div class="details-title">
            Time
            <span class="details-title-icon">
              <img src="./assets/images/new-time-icon-red.svg" />
            </span>
          </div>
          <div class="details-value">
            {{appointmentDate | date:'shortTime'}}
            <!-- {{this.queryForm.value.mobile}} -->
          </div>
        </div>
        <!--input-->

        <div class="fg secObjectlbtmformbtn" *ngIf="showRescheduleBtn!=true">
          <div class="secObjectlbtmformbtnright">
            <button
              class="submitbuttonobject2 w-100 one3d-confirm-and-close-btn"
              type="button"
              (click)="onCloseClick('Continue browsing')"
            >
              Continue browsing
            </button>
          </div>
        </div>
        <div class="fg secObjectlbtmformbtn" *ngIf="showRescheduleBtn">
          <div class="secObjectlbtmformbtnright">
            <button
              class="submitbuttonobject2 w-100 one3d-confirm-and-close-btn"
              type="button"
              (click)="onRescheduleClick()"
            >
              Reschedule
            </button>
          </div>
        </div>
        <div class="fg secObjectlbtmformbtn one3d-connect-now-new-btn-wrap">
          <div class="secObjectlbtmformbtnright ">
            <button
              *ngIf="showConnectButton"
              class="submitbuttonobject w-100 one3d-connect-now-new-btn"
              type="button"
              (click)="connectNow()"
            >
              Connect Now
            </button>
          </div>
        </div>
      </div>
    </div>
      
      
    <!--secObjectlbtmform-->
  </div>
</div>
<!--bottom-->
<div class="secObject-overlay"></div>
