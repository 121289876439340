import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/service/data.service';
import { GaService } from 'src/app/service/ga.service';
import { GlobalService } from 'src/app/service/global.service';
import { EventService } from 'src/app/service/event.service';
import { ApiService } from '../../service/api.service';
import { SnackbarService } from '../../service/snackbar.service';
import { Subscriber, Subscription } from 'rxjs';

@Component({
  selector: 'app-mg-shield',
  templateUrl: './mg-shield.component.html',
  styleUrls: ['./mg-shield.component.scss'],
})
export class MgShieldComponent implements OnInit {
  warrantyList: any;
  glosterForm: FormGroup;
  roadsideAssistList: any;
  protectPlanList: any;
  buyBackPlanList: any;
  protectionPlanTotalPrice: any = '0';
  showPriceDiv: boolean = false;
  glosterPrefieldValue: any;
  userName: any = '';
  loadedCar: any;
  pinNo: string;

  selectedVariant: import('../../service/global.service').variant;
  @Output() closePopup: EventEmitter<string> = new EventEmitter<string>();
  @Input() fromShow: string;
  $dataTransfer: Subscription;
  ACCESS: boolean;
  callConnected: boolean;

  constructor(
    public _api: ApiService,
    public _global: GlobalService,
    public _data: DataService,
    public _ga: GaService,
    public _event: EventService,
    public _snack: SnackbarService
  ) { }

  ngOnInit(): void {
    this.loadedCar = this._data.getLocalCarBrand();
    this._global.pinno.subscribe((data) => (this.pinNo = data));
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.mgShieldValues.subscribe(
      (data: any) => (this.glosterPrefieldValue = data)
    );
    let userData = this._data.geUserDataInfo();
    if (userData) {
      this.userName = userData.first_name;
    }
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.audioStatus.subscribe((data) => (this.callConnected = data));
    console.log('this.glosterPrefieldValue', this.glosterPrefieldValue);

    this.createForm();
    if (this.loadedCar == 'kmodel') {
      this.getKmodelWarrantyPlan();
    } else if (this.loadedCar == "gloster") {
      this.getGlosterWarrantyPlan();
    }else if (this.loadedCar == "hectornew"){
      this.getHectorWarrantyPlan();
    }
    // this.getPinProtectPlan();
    this.receiveDataTransfer();
  }
  async getHectorWarrantyPlan() {
    try {
      let apiData = {
        type: '',
        code: '',
        price: '',
        pin_no: this.pinNo,
        variant_id: this.selectedVariant.variant_id,
      };
      let apiResp: any = await this._api.getHectorShieldPlan(apiData);
      if (apiResp.status == 1) {
        this.warrantyList = apiResp.response.data;
        // console.log(this.warrantyList);
        if (
          this.glosterPrefieldValue.hasOwnProperty('warranty') &&
          this.glosterPrefieldValue.warranty != ''
        ) {
          this.glosterForm.patchValue({
            warranty: this.glosterPrefieldValue.warranty,
          });
          this.onWarentyChange();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.$dataTransfer.unsubscribe();
  }
  createForm() {
    // this.glosterForm.valid
    this.glosterForm = new FormGroup({
      warranty: new FormControl('', Validators.required),
      roadsideAssist: new FormControl(
        {
          value: '',
          disabled: true,
        },
        Validators.required
      ),
      protectPlan: new FormControl(
        {
          value: '',
          disabled: true,
        },
        Validators.required
      ),
      buybackPlan: new FormControl({
        value: '',
        disabled: true,
      }),
    });
  }

  async getGlosterWarrantyPlan() {
    try {
      let apiData = {
        pin_no: this.pinNo,
      };
      // let apiData = {
      //   type: '',
      //   code: '',
      //   price: '',
      //   pin_no: this.pinNo,
      //   variant_id: this.selectedVariant.variant_id,
      // }
      // let obj = {
      //   warranty: '',
      //   roadsideAssist: '',
      //   protectPlan: '',
      //   buybackPlan: '',
      // };
      let apiResp: any = await this._api.getShieldPlan(apiData);
      if (apiResp.status == 1) {
        console.log('pindata', apiResp);
        // let resp = apiResp.response;
        if (apiResp.status == 1) {
          this.warrantyList = apiResp.response.data;
          // console.log(this.warrantyList);
          if (
            this.glosterPrefieldValue.hasOwnProperty('warranty') &&
            this.glosterPrefieldValue.warranty != ''
          ) {
            this.glosterForm.patchValue({
              warranty: this.glosterPrefieldValue.warranty,
            });
            this.onWarentyChange();
          }
        }

        // this.globalSrvc.updateGlosterShield(obj);
      }
    } catch (error) {
      console.error(error);
    }
  }

  closeModal(action: string) {
    // this.count--;
    if (action == 'summary') {
      this._ga.sendGAEvent(
        this.fromShow == 'summary' ? 'View_protect' : 'View Plans',
        'click',
        'skip to summary'
      );
    } else {
      this._ga.sendGAEvent(
        this.fromShow == 'summary' ? 'View_protect' : 'View Plans',
        'click',
        'cross'
      );
    }
    this.closePopup.emit(action);
  }
  async getKmodelWarrantyPlan() {
    try {
      let apiData = {
        type: '',
        code: '',
        price: '',
        pin_no: this.pinNo,
        variant_id: this.selectedVariant.variant_id,
      };
      let apiResp: any = await this._api.astorProtectPlan(apiData);
      if (apiResp.status == 1) {
        this.warrantyList = apiResp.response.data;
        // console.log(this.warrantyList);
        if (
          this.glosterPrefieldValue.hasOwnProperty('warranty') &&
          this.glosterPrefieldValue.warranty != ''
        ) {
          this.glosterForm.patchValue({
            warranty: this.glosterPrefieldValue.warranty,
          });
          this.onWarentyChange();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async onWarentyChange() {
    // console.log('loggg', _e);
    try {
      this.glosterForm.controls['roadsideAssist'].enable();
      this.glosterForm.patchValue({
        roadsideAssist: '',
        protectPlan: '',
        buybackPlan: '',
      });
      this.roadsideAssistList = [];
      this.protectPlanList = [];
      this.buyBackPlanList = [];
      if (this.glosterForm.value.warranty != '') {
        let selectedArry = this.warrantyList.filter(
          (ele) => ele.code == this.glosterForm.value.warranty
        );

        let apiData = {
          type: selectedArry[0].type,
          code: selectedArry[0].code,
          price: selectedArry[0].price,
          pin_no: this.pinNo,
          variant_id: this.selectedVariant.variant_id,
        };
        console.log("This is my data", apiData, this.loadedCar, selectedArry);
        let apiResp: any;
        if (this.loadedCar == 'kmodel') {
          apiResp = await this._api.astorProtectPlan(apiData);
        } else if (this.loadedCar == 'gloster') {
          apiResp = await this._api.getShieldPlan(apiData);
        } else if (this.loadedCar == 'hectornew') {
          apiResp = await this._api.getHectorShieldPlan(apiData);
        }
        if (apiResp.status == 1) {
          this.roadsideAssistList = apiResp.response.data;
          this.protectionPlanTotalPrice = apiResp.response.total_price;
          // console.log(this.warrantyList);

          if (
            this.glosterPrefieldValue.hasOwnProperty('roadsideAssist') &&
            this.glosterPrefieldValue.roadsideAssist != ''
          ) {
            this.glosterForm.patchValue({
              roadsideAssist: this.glosterPrefieldValue.roadsideAssist,
            });
            this.onRoadAssistChange();
          }
        }
      } else {
        this.glosterForm.patchValue({
          roadsideAssist: '',
          protectPlan: '',
          buybackPlan: '',
        });
        this.roadsideAssistList = [];
        this.protectPlanList = [];
        this.buyBackPlanList = [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  async onRoadAssistChange() {
    console.log("Check my list", this.roadsideAssistList, this.glosterForm.value.roadsideAssist);
    try {
      this.glosterForm.controls['protectPlan'].enable();
      this.glosterForm.patchValue({
        protectPlan: '',
        buybackPlan: '',
      });
      this.protectPlanList = [];
      this.buyBackPlanList = [];
      if (this.glosterForm.value.roadsideAssist != '') {
        let selectedArry = this.roadsideAssistList.filter(
          (ele) => ele.code == this.glosterForm.value.roadsideAssist
        );

        let apiData = {
          type: selectedArry[0].type,
          code: selectedArry[0].code,
          price: selectedArry[0].price,
          pin_no: this.pinNo,
          variant_id: this.selectedVariant.variant_id,
        };
        let apiResp: any;
        console.log("Api data", apiData, this.loadedCar, this.roadsideAssistList, this.glosterForm.value.roadsideAssist);
        console.log("Api data selectedArry", selectedArry);
        if (this.loadedCar == 'kmodel') {
          apiResp = await this._api.astorProtectPlan(apiData);
        } else if (this.loadedCar == 'gloster') {
          apiResp = await this._api.getShieldPlan(apiData);
        } else if (this.loadedCar == 'hectornew') {
          apiResp = await this._api.getHectorShieldPlan(apiData);
        }
        if (apiResp.status == 1) {
          this.protectPlanList = apiResp.response.data;
          this.protectionPlanTotalPrice = apiResp.response.total_price;
          // console.log(this.warrantyList);
          if (
            this.glosterPrefieldValue.hasOwnProperty('protectPlan') &&
            this.glosterPrefieldValue.protectPlan != ''
          ) {
            this.glosterForm.patchValue({
              protectPlan: this.glosterPrefieldValue.protectPlan,
            });
            this.onProtectPlanChange();
          }
        }
      } else {
        this.glosterForm.patchValue({
          protectPlan: '',
          buybackPlan: '',
        });
        this.protectPlanList = [];
        this.buyBackPlanList = [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  async onProtectPlanChange() {
    try {
      this.glosterForm.controls['buybackPlan'].enable();
      this.glosterForm.patchValue({
        buybackPlan: '',
      });
      this.buyBackPlanList = [];
      if (this.glosterForm.value.protectPlan != '') {
        let selectedArry = this.protectPlanList.filter(
          (ele) => ele.code == this.glosterForm.value.protectPlan
        );

        let apiData = {
          type: selectedArry[0].type,
          code: selectedArry[0].code,
          price: selectedArry[0].price,
          pin_no: this.pinNo,
          variant_id: this.selectedVariant.variant_id,
        };
        let apiResp: any;
        if (this.loadedCar == 'kmodel') {
          apiResp = await this._api.astorProtectPlan(apiData);
        } else if (this.loadedCar == 'gloster') {
          apiResp = await this._api.getShieldPlan(apiData);
        } else if (this.loadedCar == 'hectornew') {
          apiResp = await this._api.getHectorShieldPlan(apiData);
        }
        if (apiResp.status == 1) {
          this.buyBackPlanList = apiResp.response.data;
          console.log('this.buyBackPlanList', this.buyBackPlanList);

          this.protectionPlanTotalPrice = apiResp.response.total_price;
          // console.log(this.warrantyList);
          if (
            this.glosterPrefieldValue.hasOwnProperty('buybackPlan') &&
            this.glosterPrefieldValue.buybackPlan != ''
          ) {
            this.glosterForm.patchValue({
              buybackPlan: this.glosterPrefieldValue.buybackPlan,
            });
            this.onBuyBackChange();
          }
        }
      } else {
        this.glosterForm.patchValue({
          buybackPlan: '',
        });
        this.buyBackPlanList = [];
      }
    } catch (error) {
      console.error(error);
    }
  }
  async onBuyBackChange() {
    try {
      if (this.glosterForm.value.buybackPlan != '') {
        let selectedArry = this.buyBackPlanList.filter(
          (ele) => ele.code == this.glosterForm.value.buybackPlan
        );

        let apiData = {
          type: selectedArry[0].type,
          code: selectedArry[0].code,
          price: selectedArry[0].price,
          pin_no: this.pinNo,
          variant_id: this.selectedVariant.variant_id,
        };
        let apiResp: any;
        if (this.loadedCar == 'kmodel') {
          apiResp = await this._api.astorProtectPlan(apiData);
        } else if (this.loadedCar == 'gloster') {
          apiResp = await this._api.getShieldPlan(apiData);
        } else if (this.loadedCar == 'hectornew') {
          apiResp = await this._api.getHectorShieldPlan(apiData);
        }
        if (apiResp.status == 1) {
          this.protectionPlanTotalPrice = apiResp.response.total_price;
          this.showPriceDiv = true;
          // console.log(this.warrantyList);
        }
      } else {
        let apiData = {
          type: 'buyback',
          code: '0',
          price: 0,
          pin_no: this.pinNo,
          variant_id: this.selectedVariant.variant_id,
        };
        // let apiResp: any = await this._api.astorProtectPlan(apiData);
        let apiResp: any;
        if (this.loadedCar == 'kmodel') {
          apiResp = await this._api.astorProtectPlan(apiData);
        } else if (this.loadedCar == 'gloster') {
          apiResp = await this._api.getShieldPlan(apiData);
        } else if (this.loadedCar == 'hectornew') {
          apiResp = await this._api.getHectorShieldPlan(apiData);
        }
        if (apiResp.status == 1) {
          this.protectionPlanTotalPrice = apiResp.response.total_price;
          this.showPriceDiv = true;
          // console.log(this.warrantyList);
        }
      }
    } catch (error) {
      console.error(error);
    }

    // let selectedArry = this.buyBackPlanList.filter(
    //   (ele) => ele.code == this.glosterForm.value.buybackPlan
    // );
  }
  pricetoLocatStr(number: number) {
    return number.toLocaleString('en-IN');
  }
  async saveShield() {
    try {
      let selectedArry = this.protectPlanList.filter(
        (ele) => ele.code == this.glosterForm.value.protectPlan
      );
      let obj = {
        plan_type: selectedArry.length > 0 ? selectedArry[0].title : '',
        mrp: this.pricetoLocatStr(this.protectionPlanTotalPrice),
        is_added: true,
        pro_plan_type: 'MG Shield Plan',
      };
      const formValue = { ...this.glosterForm.value };
      // console.log(formValue);
      let apiData = { pin_no: this.pinNo };
      let apiResp: any = await this._api.saveShieldPlan(apiData);

      if (apiResp.status == 1) {
        this._global.updateMGShield(formValue);
        // this._matSnackBar.open('Your Shield plan is saved.', '', {
        //   duration: 5000,
        // });
        this._snack.openSnackBar('Your Shield plan is saved.', 'Success');
        this._api.saveBuildSummary(this.pinNo, 'added_plan');
        sessionStorage.setItem('selectedProtectionPlan', JSON.stringify(obj));
        this.closePopup.emit('summary');
      }
    } catch (error) {
      console.error(error);
    }
    // .subscribe((data: any) => {
    //   this._ga.sendGAEvent(
    //     this.fromShow == 'summary' ? 'View_protect' : 'View Plans',
    //     'click',
    //     'submit'
    //   );
    //   this.globalSrvc.updateGlosterShield(formValue);
    // });
  }

  async saveCustomerJourny(action: string) {
    try {
      this._api.saveBuildSummary(this.pinNo, action);
    } catch (error) {
      console.error(error);
    }
  }

  receiveDataTransfer() {
    this.$dataTransfer = this._event.subscribe('dataTransfer', (data) => {
      let keys = Object.keys(data);
      let key = keys[0];
      console.log('dataTransfer111', data);
      switch (key) {
        case 'shieldPlan':
          console.log('data[key]', data[key]);
          this.addPlanForCustomer(data[key]);

          break;
        case 'closeShieldPlanPage':
          this.saveShield();

          break;
        default:
          break;
      }
    });
  }
  addPlanForCustomer(arg0: any) {
    let obj = {
      warranty: arg0.warrantyTitle,
      roadsideAssist: arg0.roadsideAssistanceTitle,
      protectPlan: arg0.protectPlanTitle,
      buybackPlan: arg0.BuybackPlanTitle,
    };
    this._global.updateMGShield(obj);
    // this.getWarrantyPlan();
    if (this.loadedCar == 'kmodel') {
      this.getKmodelWarrantyPlan();
    } else if (this.loadedCar == "gloster") {
      this.getGlosterWarrantyPlan();
    }else if (this.loadedCar == "hectornew"){
      this.getHectorWarrantyPlan();
    }
  }
}
