import { Location } from '@angular/common';
import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { DataService } from '../../service/data.service';
import { GlobalService } from '../../service/global.service';
import { GaService } from '../../service/ga.service';
import { SocketService } from '../../service/socket.service';
import { defaultCarDetails } from '../carSettings';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { EventService } from 'src/app/service/event.service';
declare var ONE3D: any;
@Component({
  selector: 'app-acc-details',
  templateUrl: './acc-details.component.html',
  styleUrls: ['./acc-details.component.scss'],
})
export class AccDetailsComponent implements OnInit, OnDestroy {
  globalPinNO: string;
  isCarDoorOpen: boolean;
  selectedColor: any;
  selectedVariant: import('../../service/global.service').variant;
  totalPrice: any;
  ACCESS: boolean;
  locationInfo: any;
  loadedCar: any;
  accResp: any;
  accList: any;
  isAccPreview: boolean;
  params: any;
  subGroup: any;
  selectedGroup: any;
  previousAdded: any;
  accUrl: string;
  limitedEditionData: any;
  $subscription: Subscription
  isOverlappingModal: boolean;
  overLappingPacks: any[];
  overlappingAccessories: any[];
  accToBeAdded: any;
  limitedEdition: boolean;
  constructor(
    private _api: ApiService,
    private _data: DataService,
    private _global: GlobalService,
    private _socket: SocketService,
    private _ga: GaService,
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private myEvent: EventService
  ) {
    this.accUrl = environment.accessoriesImageCdnUrl;
  }

  ngOnInit(): void {
    window['accRef'] = {
      zone: this.zone,
      one3dcallback: {
        onCarLoadComplete: () => this.applyAddedAcc(),
      },
      component: this,
    };
    this.route.queryParams.subscribe((data: any) => {
      if (data) {
        this.params = data;
      }
    });
    this.loadedCar = this._data.getLocalCarBrand();
    this.$subscription = this._global.pinno.subscribe((data) => (this.globalPinNO = data));
    this.$subscription.add(this._global.carDoorIsOpen.subscribe((data) => (this.isCarDoorOpen = data)));
    this.$subscription.add(this._global.selectedColor.subscribe((data) => (this.selectedColor = data)));
    this.$subscription.add(this._global.variant.subscribe((data) => (this.selectedVariant = data)));
    this.$subscription.add(this._global.price.subscribe((data) => (this.totalPrice = data)));
    this.$subscription.add(this._global.accessUi.subscribe((data) => (this.ACCESS = data)));
    this.$subscription.add(this._global.locationData.subscribe((data) => (this.locationInfo = data)));
    this.$subscription.add(this._global.limitedEditionData.subscribe((data) => (this.limitedEditionData = data)));
    this.$subscription.add(this._global.limitedEditionUI.subscribe((data) => (this.limitedEdition = data)));
    this.getAccList();
  }
  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
    ONE3D.exitAccessoriesView();
    document.getElementById('featureImageFullScreen').style.display = 'none';
  }
  async getAccList() {
    // this.isLoading=true;
    console.log('this.globalPinNO', this.globalPinNO);
    let variantId = this._data.getLocalVariant();
    let colorId = this._data.getLocalColor();
    this._global.showLoading(true);
    let apiData = {
      pin_no: this.globalPinNO,
      variant_id: variantId
        ? variantId
        : defaultCarDetails[this.loadedCar].variant_id,
      variant_feature_id: colorId
        ? colorId
        : defaultCarDetails[this.loadedCar].variant_feature_id, //  added one
      show: 2, //  need to confirm what is it for
      source: 'web',
    };
    try {
      let respData: any = await this._api.getAccessoriesList(apiData);
      // Object.assign(this.accResp,{});
      if (respData.status == 1) {
        this.accList = respData.response;
        this.accResp = respData.response;

        console.log('acc this.accResp', this.accList);
        // this.applyAddedAcc();
        this.loadArrays(false);
      }
      // this.isLoading=false;
      this._global.showLoading(false);
    } catch (error) {
      // this.isLoading=false;
      this._global.showLoading(false);
      console.log('Error in Acc', error);
    }
  }

  applyAddedAcc() {
    if (ONE3D.loadedPercent == 100) {
      let selected = [];
      this.accResp.accessories.map((ele) => {
        if (ele.flag == 'Remove') selected.push(ele.mesh);
      });
      if (selected.length > 0) {
        ONE3D.event.addMultiAccessories(selected);
      }
    }
  }

  subgroupChange() {
    this.accList = this.accResp.accessories.filter(
      (e) => e.subgroup_id == this.selectedGroup
    );
    this.accList.map(e => e.checked = false);
    // ONE3D.setAccessorieCamera(this.accList[0].hotspot_name);
    ONE3D.setAccessorieCamera(this.accList[0].hotspot_name, null, {
      callback: () => {
        console.log('Camera set');
      },
    });
    this._global.updateAccCam(true);
    // SceneManager.scenemanager.accManager.setAccCam(
    //   this.accList[0].hotspot_name,
    //   false
    // );
  }

  async accAddRemove(item) {
    // this.isLoading=true;
    let overLapping = this.checkOverlapping(item);
    console.log('overLapping', overLapping);
    if (overLapping.overlappedPacks.length > 0 || overLapping.overlappedAccessories.length > 0) {
      this.accToBeAdded = item;
      this.overLappingPacks = overLapping.overlappedPacks;
      if (overLapping.overlappedPacks.length > 0) {
        this.isOverlappingModal = true;
      }
      this.overlappingAccessories = overLapping.overlappedAccessories;
      for (const iterator of overLapping.overlappedAccessories) {
        if (item.subgroup_id == iterator.subgroup_id) {
          iterator.checked = false;
          this.continueAccAddition(item)
        } else {
          this.isOverlappingModal = true;
          break;
        }
      }
    } else {
      this.continueAccAddition(item);
    }
  }
  async continueAccAddition(item) {
    try {
      document.getElementById('featureImageFullScreen').style.display = 'none';      //
      window.sendData({
        eName: 'click',
        eCat: 'accessorize_menu',
        eAct: 'accessory_selection',
        eval: item.marketing_name,
        eLa4: item.category,
        eLa5: item.subgroup_name,
        eLa6: item.subgroup_name,
      });
      this.isAccPreview = true;
      this._global.updateAccPreview(this.isAccPreview);
      
      this._global.showLoading(false);
      if (this.previousAdded && this.previousAdded.type == '3d') {
        this._global.showLoading(true);
        ONE3D.removeAccessories(
          this.previousAdded.mesh,
          this.previousAdded.tag
        );
      }
      if (this.previousAdded != item) {
        this.accList.map((ele) => { ele.flag = 'Add'; ele.checked = false; });
      }
      if (item.type == 'image') {
        // document.getElementById('featureImageFullScreen').style.display = 'block';
        if (item.flag == 'Add') {
          if (window.innerWidth < 450) {
            console.log('mobile');

            this._global.updateOverlaySrc(
              `${this.accUrl}full_screen/mobile/${item.image_url}`
            );
          } else {
            console.log('desktop');
            this._global.updateOverlaySrc(
              `${this.accUrl}full_screen/${item.image_url}`
            );
          }
          this.previousAdded = item;
          setTimeout(() => {
            document.getElementById('featureImageFullScreen').style.display =
              'block';
          }, 100);
          if (this.ACCESS) {
            this._socket.emitAccAdd(item);
          }
          this._global.updateAccCam(true);
        } else {
          if (this.ACCESS) {
            this._socket.emitAccRemove(item);
          }
          this._global.updateAccCam(true);
        }
      }else{
        try {
          this._global.showCircleLoader(true);
          ONE3D.setAccessorieCamera(item.hotspot_name, null, {
            callback: () => {
              console.log('Camera set');
              console.log(item);
              if (item.flag == 'Add') {
                this.previousAdded = item;
                ONE3D.addAccessorie(item.mesh, item.tag, {
                  callback: () => {
                    console.log('ACC LOADED');
                    this._global.showCircleLoader(false);
                  },
                });
                if (this.ACCESS) {
                  this._socket.emitAccAdd(item);
                }
                this._global.updateAccCam(true);
              } else {
                console.log("Else part", item);
                this._global.showCircleLoader(false);
                ONE3D.removeAccessories(item.mesh, item.tag);
                if (this.ACCESS) {
                  this._socket.emitAccRemove(item);
                }
                this._global.updateAccCam(true);
              }
            },
          });
        } catch (error) {
          // Handle the error here
          console.log("Error", error)
          this._global.showCircleLoader(false);
        }
      }
       
      let apiData = {
        // "pin_no": "PIN12",
        // "variant_id": "1",
        pin_no: this.globalPinNO,
        variant_id: this.selectedVariant.variant_id,
        access_id: item.accessories_id,
        subgroup_id: item.subgroup_id,
        change_variant: '',
        flag: item.flag,
        price: item.price,
        type: 'Addons',
        qty: 1,
        is_multiple: item.is_multiple,
        source: 'web',
        acces_type: item.acces_type,
      };

      let respData: any = await this._api.addRemoveAccessories(apiData);
      if (respData.status == 1) {
        this._global.updatedPrice(respData.response.summary.total);

        // this.updateAccData(respData.response.accessories);
        console.log('item', item);
        this._ga.sendGAEvent(
          `${item.category} access`,
          'click',
          item.marketing_name
        );
        if (item.flag == 'Add') {
          item.flag = 'Remove';
          item.checked = true;
        } else {
          item.flag = 'Add';
          item.checked = false;
        }
      }
    } catch (error) {
      // this.isLoading=false;
      this._global.showLoading(false);
      console.log('Error in Acc', error);
    }
  }

  loadArrays(change: boolean) {
    if (this.params.subgroup_id) {
      if (this.params.category.toLowerCase() == 'interior') {
        this.subGroup = this.accResp.interior_subgroup;
        this.accList = this.accResp.accessories.filter(
          (e) => e.subgroup_id == this.params.subgroup_id
        );
      } else if (this.params.category.toLowerCase() == 'exterior') {
        this.subGroup = this.accResp.exterior_subgroup;
        this.accList = this.accResp.accessories.filter(
          (e) => e.subgroup_id == this.params.subgroup_id
        );
      } else if (this.params.category.toLowerCase() == 'essential') {
        this.subGroup = this.accResp.essential_subgroup;
        this.accList = this.accResp.accessories.filter(
          (e) => e.subgroup_id == this.params.subgroup_id
        );
      } else if (this.params.category.toLowerCase() == 'stickers') {
        this.subGroup = this.accResp.stickers_subgroup;
        this.accList = this.accResp.accessories.filter(
          (e) => e.subgroup_id == this.params.subgroup_id
        );
      }
      for (const iterator of this.accList) {
        if (iterator.flag == "Remove") {
          iterator.checked = true
        } else {
          iterator.checked = false
        }
      }
      this.selectedGroup = this.params.subgroup_id;
      if (change != true) {
        // ONE3D.setAccessorieCamera(this.accList[0].hotspot_name);
        ONE3D.setAccessorieCamera(this.accList[0].hotspot_name, null, {
          callback: () => {
            console.log('Camera set');
          },
        });
        this._global.updateAccCam(true);
        // SceneManager.scenemanager.accManager.setAccCam(
        //   this.accList[0].hotspot_name,
        //   false
        // );
      }
    } else {
      this.accList = this.accResp.accessories;
    }
  }
  /**
   * goto Accessory listing page to back
   */
  goBack() {
    if (this.isAccPreview == true) {
      ONE3D.exitAccessoriesView();
    }
    this.isAccPreview = false;
    this._global.updateAccPreview(this.isAccPreview);
    this._global.updateAccCam(false);
    this.location.back();
    window.sendData({
      eName: 'click',
      eCat: 'accessorize_menu',
      eAct: 'back_to_accessory_selection_clicked',
    });
  }

  /**
   * goto summary page on click of CTA Summary
   */
  gotoSummaryPage() {
    this._api.saveBuildSummary(this.globalPinNO, 'proceed_click');
    let url = defaultCarDetails[this.loadedCar].urlScheme;
    this.router.navigate([`${url}/summary`]);
    // this.router.navigate(['/hector/summary']);
  }

  openLocModal() {
    this._global.toggleLocModal(true);
  }

  getStateValue() {
    return this.locationInfo.hasOwnProperty('city')
      ? this.locationInfo.city
      : 'Delhi';
  }

  checkOverlapping(accessory) {
    let overlappedPacks = [];
    let overlappedAccessories = [];
    if (this.accResp.packages) {

      let addedPacks = this.accResp.packages.filter(e => e.flag == 'Remove');

      let accList: any = [];
      let addedList = [];

      if (addedPacks.length > 0) {
        for (const pack of addedPacks) {
          for (const acc of pack.accessoriesList) {
            accList = [...accList, ...pack.accessoriesList];
          }
        }
      }

      if (addedPacks && addedPacks.length > 0) {
        addedPacks.forEach(addedPack => {
          // Filter out objects with 'checked' property set to false and allow_custom === 0
          let filteredAdditionalPack = addedPack.additionalPack?.filter(
            (item) => item.flag == 'Remove' && item.allow_custom === 0
          );

          // Filter out objects with 'checked' property set to false and allow_custom === 1
          let filteredOptionsPack = addedPack.additionalPack?.filter(
            (item) => item.flag == 'Remove' && item.allow_custom === 1
          );

          // Filter out objects with 'checked' property set to false and allow_custom === 2
          let filteredOptionsKit = addedPack.additionalPack?.filter(
            (item) => item.flag == 'Remove' && item.allow_custom === 2
          );


          if (filteredAdditionalPack?.length > 0) {
            for (const pack of filteredAdditionalPack) {
              for (const acc of pack.accessoriesList) {
                accList = [...accList, ...pack.accessoriesList];
              }
            }
          }

          if (filteredOptionsPack?.length > 0) {
            for (const pack of filteredOptionsPack) {
              for (const acc of pack.accessoriesList) {
                if (acc.flag === "Remove") {
                  addedList.push(acc);
                }
              }
            }

          }

          if (filteredOptionsKit?.length > 0) {
            for (const pack of filteredOptionsKit) {
              for (const acc of pack.accessoriesList) {
                if (acc.flag === "Remove") {
                  addedList.push(acc);
                }
              }
            }

          }
        });
      }

      // console.log("1", addedList, accList);
      accList = [...addedList, ...accList];
      // console.log("2", accList);
      accList = Array.from(new Set(accList.map(item => item.accessories_id)))
        .map(id => accList.find(item => item.accessories_id === id));
      console.log("2", accList);

      if (addedPacks.length > 0) {
        for (const pack of addedPacks) {
          for (const acc of accList) {
            if (accessory.subgroup_id == acc.subgroup_id || acc.explicit_access.split(",").map(Number).includes(accessory.subgroup_id)) {
              overlappedPacks.push(pack);
              break;
            }
          }
        }
      }

    } else {
      console.log('no packs for model',);
    }
    if (this.accResp.accessories) {
      let addedAccessories = this.accResp.accessories.filter(e => e.flag == 'Remove');
      if (addedAccessories.length > 0) {
        for (const acc of addedAccessories) {
          if (accessory.subgroup_id == acc.subgroup_id || acc.explicit_access.split(",").map(Number).includes(accessory.subgroup_id)) {
            overlappedAccessories.push(acc);
          }
        }
      }
    } else {
      console.log('no ACC array ',);
    }
    if (this.limitedEdition) {
      for (const pack of this.limitedEditionData) {
        for (const acc of pack.accessoriesList) {
          if (accessory.subgroup_id == acc.subgroup_id || acc.explicit_access.split(",").map(Number).includes(accessory.subgroup_id)) {
            overlappedPacks.push(pack);
            break;
          }
        }
      }
    } else {
      console.log('no edition added',);
    }
    return { overlappedPacks, overlappedAccessories };
  }

  onModalDismiss(action: string) {
    this.isOverlappingModal = false;
    if (action == 'continue') {
      for (const iterator of this.overLappingPacks) {
        if (iterator.pack_mesh_code && iterator.pack_mesh_code == "GAMER_EDITION") {
          this._global.updateLimitedEditionUI(false);
          this._global.updateLimitedEdition3D(false);
        } else {
          console.log('iterator', iterator);
          ONE3D.removeKit(iterator.pack_mesh_code);
          this.removeAllAdditionAcc();
          this.updateAccessoryToApi(iterator, true);
          // console.log("511", iterator);
          // this.myEvent.publish("resetAdditionalPacks", iterator)
        }
      }
      for (const iterator of this.overlappingAccessories) {
        ONE3D.removeAccessories(iterator.mesh);
        this.updateAccessoryToApi(iterator, false);
      }
      this.continueAccAddition(this.accToBeAdded);
    }
    if (action == 'cancel') {
      this.accToBeAdded.checked = false;
    }
  }
  removeAllAdditionAcc() {
    ONE3D.removeAllAccessories([...this._data.overAllMeshListCustom2, this._data.overAllMeshListCustom1, ...this._data.overAllMeshListCustom0], {
      callback: () => {
        this._global.showCircleLoader(false);
        // alert("Removed");
        // resolve(true);
      }
    });
  }
  async updateAccessoryToApi(item, isPack?) {
    try {
      let apiData = {
        pin_no: this.globalPinNO,
        variant_id: this.selectedVariant.variant_id,
        access_id: isPack ? item.pack_id : item.accessories_id,
        subgroup_id: isPack ? "0" : item.subgroup_id,
        change_variant: '',
        flag: item.flag,
        price: isPack ? item.pack_price : item.price,
        type: 'Addons',
        qty: 1,
        is_multiple: item.is_multiple,
        source: 'web',
        acces_type: isPack ? "" : item.acces_type,
        is_pack: isPack ? "yes" : null,
        remove_pack:(item.flag === "Remove" && isPack) ? "Yes" : ""
      };
      let respData: any = await this._api.addRemoveAccessories(apiData);
      if (respData.status == 1) {
        this._global.updatedPrice(respData.response.summary.showroom_price);
        this._ga.sendGAEvent(
          `${item.category} access`,
          'click',
          item.marketing_name
        );
        this.accResp.packages = respData.response.packages;
        this.accResp.accessories = respData.response.accessories;
      }
    } catch (error) {
      this._global.showLoading(false);
      console.log('Error in Acc', error);
    }
  }
}
