<!--maincontainerrightin-->
<div class="maincontainerrightin new-accessrize-main-container">
  <!--title-->
  <div class="variantlisttitle variantlisttitle2 gamer-editiond-title">
    <div class="gamer-editiond-title-in">
      <div class="gamer-editiond-left">
        <button aria-label="Back to Home" [hidden]="SOCKET_STATUS == 'connected'" (click)="toggleConfirmModal()">
          <span><img src="./assets/images/red-arow-leftdir.svg" alt="img" /></span>
          <span>Accessorize</span>
        </button>
      </div>
      <div class="gamer-editiond-right" *ngIf="limitedEditionData.length > 0" (click)="gotoLimitedEdition()">
        <a class="ViewLimitedEdition-top-button">View Limited Edition</a>
      </div>
    </div>
  </div>
  <!--title-->

  <!--ACCESSORIZE-->
  <div class="fg accessrizelistspace accessrizelist">
    <div class="colorAccordion_container">
      <!-- Packs  -->
      <div class="colorAccordion_head categoryLinkList" *ngIf="accList && accList.packages.length > 0"
        [ngClass]="{ active: selectedAccGroup == '4' }" (click)="selectAccgrp('4')">
        <div class="categoryLinkListicon">
          <div class="categoryLinkListiconi">
            <img src="./assets/images/color-bucket.svg" />
          </div>
          Color Kit
        </div>
      </div>
      <div class="colorAccordion_body" [ngStyle]="{ display: selectedAccGroup == '4' ? 'block' : 'none' }">
        <div class="fg accessorieslist accessorieslisthidespace">
          <ul>
            <li *ngFor="let pack of accList?.packages">
              <div class="fg accessoriesListmng">
                <div class="accessoriesList">
                  <div class="accessoriesListcheck">
                    <!-- *ngIf="acc.accessories_count == 1 || acc.flag == 'Remove'" -->
                    <div class="iuthis mngcheckBox">
                      <input id="{{ pack.pack_id }}" type="checkbox" name="check-acc"
                        (ngModelChange)="packAddRemove(pack)" [(ngModel)]="pack.checked" />
                      <label for="{{ pack.pack_id }}"></label>
                    </div>
                  </div>
                  <div class="accessoriesListimg">
                    <img [src]="accUrl + pack.pack_thumb_url" alt="img" />
                  </div>
                  <h2 class="fg accessoriesListinfo accessoriesListinfo-View-btn">
                    <div class="view-details-button" *ngIf="!viewDetailsShown" (click)="viewDetails(pack)">
                      <button>View Details</button>
                    </div>
                    <div class="view-details-button" *ngIf="
                        viewDetailsShown &&
                        pack.pack_id == packageDetails.pack_id
                      " (click)="hideDetails()">
                      <button>View Less</button>
                    </div>
                    {{ pack.pack_name
                    }}<span><i class="fa fa-rupee" aria-hidden="true"></i>
                      {{ pack.pack_price }}</span>
                  </h2>
                </div>
              </div>


              <!-- Nested array list -->
              <div *ngIf="
                  viewDetailsShown && packageDetails.pack_id == pack.pack_id
                " class="fg accessorieslistview-details">
                <div class="fg accessorieslistview-details-title">
                  This {{ packageDetails?.pack_name }} includes :
                </div>
                <ul>
                  <li *ngFor="let acc of packageDetails?.accessoriesList">
                    <div class="accessorieslistview-details-row">
                      <div class="gameredition-img">
                        <img [src]="accUrl + acc.thumb_url" alt="img" />
                      </div>
                      <div class="gameredition-info">
                        {{ acc.marketing_name }}
                        <div *ngIf="pack.checked" class="gameredition-infobtn">
                          <button class="oncarview-button" (click)="onCarView(acc)">
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <!-- add class "active-dropdown" to 'one3d-add-pack-parent' on click of "colorAccordion_head"-->

                <!-- Starting of sub packs  -->

                <div *ngIf="pack?.additionalPack && pack.additionalPack?.length > 0">

                  <div *ngFor="let additionalPack of pack?.additionalPack; let i = index">

                    <!-- Additional Pack  -->

                    <div id="additional-pack" class="one3d-add-pack-parent" *ngIf="additionalPack?.allow_custom == 0">
                      <div class="one3d-additional-pack">
                        <!-- add class "active" to 'colorAccordion_head' on click of "colorAccordion_head"-->
                        <div class="colorAccordion_head categoryLinkList active" (click)="toggleAdditionalAccordion()">
                          <div class="accessoriesListcheck">
                            <div class="iuthis mngcheckBox">
                              <input id="add-pack" type="checkbox" name="check-pack" [disabled]="!pack.checked"
                                (click)="$event.stopPropagation()"
                                (ngModelChange)="additionalPackAddRem(additionalPack, pack)"
                                [(ngModel)]="additionalPack.checked" />
                              <label for="add-pack"></label>
                            </div>
                          </div>
                          <h2 class="one3d-add-pack">{{additionalPack.pack_name}}
                            <span style="display: block;font-size: 14px;font-weight: 500;"><i class="fa fa-rupee" aria-hidden="true"></i>
                              {{additionalPack?.pack_price}} </span>
                          </h2>

                        </div>
                      </div>
                      <div class="one3d-accessories-pack-wrap" *ngIf="additionalPack?.accessoriesList?.length>0">
                        <ul *ngFor="let addPackAcc of additionalPack?.accessoriesList">
                          <li>
                            <div class="accessorieslistview-details-row">
                              <div class="gameredition-img">
                                <img [src]="accUrl + addPackAcc.thumb_url" alt="img" />
                              </div>
                              <!-- <h2 class="fg accessoriesListinfo">
                                {{addPackAcc?.price}}
                                <span><i class="fa fa-rupee" aria-hidden="true"></i>
                                  {{addPackAcc?.price}} </span>
                              </h2> -->
                              <div class="gameredition-info">
                                {{addPackAcc.marketing_name}}  
                                <!-- <span><i class="fa fa-rupee" aria-hidden="true"></i>
                                  {{addPackAcc?.price}} </span> -->
                                <div *ngIf="additionalPack.checked" class="gameredition-infobtn">
                                  <button class="oncarview-button" (click)="onCarView(addPackAcc)">
                                    View
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <!-- Additional Pack Arrays with custom flag == 2  -->
                    <!-- <ng-container> -->
                      <div (click)="toggleGraphicOptions()" class="one3d-add-pack-list colorAccordion_head categoryLinkList active" *ngIf="i ==insertionIndexDummyObj " >
                       <h2> Graphic Options</h2> 
                      </div>
                    <!-- </ng-container> -->
                  
                   
                    <!-- <ng-template> -->
                      <div *ngIf="additionalPack?.allow_custom == 2 && i!= insertionIndexDummyObj && showList"
                      [id]="'additional-options-kit-' + additionalPack.pack_id" class="one3d-add-pack-parent">
                      <!-- //Graphics options kit -->
                      
                      <div style="display: none;"  class="one3d-add-pack-list colorAccordion_head categoryLinkList active"
                        (click)="toggleOptionsKitAccordion(additionalPack)">
                        <h2>Graphic Options</h2>
                      </div>

                      <!-- Graphics options kit  -->
                      <div  class="one3d-add-pack-list-wrap one3d-add-pack-list-wrap-new-fresh">
                        <div class="one3d-listing-pack">
                          <div class="one3d-clear-selection-wrap">
                            <span *ngIf="additionalPack.checked" class="one3d-clear-selection-button"
                              (click)="clearSelectionOptionKit(additionalPack, pack)">
                              Clear Selection
                            </span>
                          </div>
                          <form>
                            <ul class="one3d-acc-subkits-ul-1">
                              <li>
                                <div class="fg accessoriesListmng addpack-list">
                                  <div class="accessoriesList">
                                    <div class="accessoriesListcheck">
                                      <div class="one3d-r-btn">
                                        <input [checked]="additionalPack.checked"
                                          (click)="addRemOptKit(additionalPack, pack);$event.stopPropagation()"
                                          [disabled]="!pack.checked" [id]="additionalPack.pack_id" type="radio"
                                          name="additional-pack" [value]="additionalPack.pack_id" /><label
                                          [for]="additionalPack.pack_id"></label>
                                      </div>
                                    </div>
                                    <!-- <div class="accessoriesListimg"> -->
                                      <!-- <img alt="img" [src]="accUrl + additionalPack.thumb_url" /> -->
                                    <!-- </div> -->
                                    <h2 class="fg accessoriesListinfo accessoriesListinfo-View-btn">
                                      {{additionalPack.pack_name}}
                                    </h2>
                                  </div>
                                </div>


                                <!-- Checkboxes of radio button  -->
                                <div style="display: none;"  [id]="'nested-acc-'+additionalPack.pack_id"
                                  *ngIf="additionalPack?.accessoriesList?.length>0"
                                  class="fg accessorieslist one3d-accessories-sub-list">
                                  <div class="one3d-additional-subkits-packs-name-heading">
                                    {{additionalPack.pack_name}} Includes:
                                  </div>
                                  <ul *ngFor="let acc of additionalPack.accessoriesList">
                                    <li>
                                      <div class="accessoriesList">
                                        <div class="accessoriesListcheck">
                                          <div *ngIf="additionalPack.checked" class="iuthis mngcheckBox">
                                            <input [disabled]="!pack.checked || !additionalPack.checked"
                                            (click)="addRemGroupKits(acc,additionalPack, pack, true)"
                                              [checked]="acc.checked" [id]="acc.accessories_id" type="checkbox"
                                              name="check-opt-kit" />
                                            <label [for]="acc.accessories_id"> </label>
                                          </div>
                                        </div>
                                        <div class="accessoriesListimg">
                                          <img  [src]="accUrl + acc.thumb_url"
                                            alt="img" />
                                        </div>
                                        <h2 class="fg accessoriesListinfo">
                                          {{acc.marketing_name}}
                                          <span><i class="fa fa-rupee" aria-hidden="true"></i>
                                            {{acc.price}} </span>
                                        </h2>
                                      </div>
                                    </li>
                                  </ul>
                                </div>

                              </li>

                            </ul>
                          </form>
                        </div>
                      </div>

                    </div>

                    <!-- custom_flag == 2 -->
                    <!-- </ng-template> -->

                    <!-- Additional Pack Arrays with custom flag == 1  -->

                    <div [id]="'additional-options-' + additionalPack.pack_id" class="one3d-add-pack-parent"
                      *ngIf="additionalPack?.allow_custom == 1">
                      <!-- //Graphics or Wheel  name  -->
                      <div class="one3d-add-pack-list colorAccordion_head categoryLinkList active"
                        (click)="toggleOptionsAccordion(additionalPack.pack_id)">
                        <h2>{{additionalPack.pack_name}}</h2>
                      </div>

                      <!-- Graphics or Wheel cover  -->
                      <div class="one3d-add-pack-list-wrap">
                        <div class="one3d-listing-pack" *ngIf="additionalPack?.accessoriesList?.length>0">

                          <div class="one3d-clear-selection-wrap">
                            <span class="one3d-clear-selection-button"
                              (click)="clearSelection(additionalPack, true, pack)">
                              Clear Selection
                            </span>
                          </div>
                          <form>
                            <ul *ngFor="let graphicsORWheelcover of  additionalPack?.accessoriesList">

                              <li>
                                <div class="fg accessoriesListmng addpack-list">
                                  <div class="accessoriesList">
                                    <div class="accessoriesListcheck">
                                      <div class="one3d-r-btn">
                                        <input [checked]="graphicsORWheelcover.checked"
                                          (click)="addRemPackOptions(graphicsORWheelcover,additionalPack, pack); $event.stopPropagation()"
                                          [disabled]="!pack.checked" [id]="graphicsORWheelcover.accessories_id"
                                          type="radio" name="additional-pack"
                                          [value]="graphicsORWheelcover.accessories_id" /><label
                                          [for]="graphicsORWheelcover.accessories_id"></label>
                                      </div>
                                    </div>
                                    <div class="accessoriesListimg">
                                      <img alt="img" [src]="accUrl + graphicsORWheelcover.thumb_url" />
                                    </div>
                                    <h2 class="fg accessoriesListinfo accessoriesListinfo-View-btn">
                                      {{graphicsORWheelcover.marketing_name}}<span><i aria-hidden="true"
                                          class="fa fa-rupee"></i>{{graphicsORWheelcover.price}}</span>
                                    </h2>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </form>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>


                <!-- Ending of sub packs  -->

              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- Packs -->

      <!--Show Top Exterior Accessories-->
      <div class="colorAccordion_head categoryLinkList" *ngIf="accList && accList.exterior_subgroup"
        [ngClass]="{ active: selectedAccGroup == '1' }" (click)="selectAccgrp('1')">
        <div class="categoryLinkListicon">
          <div class="categoryLinkListiconi">
            <img src="./assets/images/accessories-ext-icon.svg" />
          </div>
          Show Top Exterior Accessories
        </div>
      </div>
      <div class="colorAccordion_body" [ngStyle]="{ display: selectedAccGroup == '1' ? 'block' : 'none' }">
        <div class="fg accessorieslist">
          <ul>
            <li *ngFor="let acc of accList && accList.exterior_subgroup">
              <div class="accessoriesList">
                <div class="accessoriesListcheck">
                  <!-- (change)="accAddRemove(acc, $event)" [checked]="acc.flag == 'Remove'" -->
                  <div class="iuthis mngcheckBox" *ngIf="acc.accessories_count == 1 || acc.flag == 'Remove'">
                    <input id="{{ acc.hotspot_name }}" type="checkbox" name="check" (ngModelChange)="accAddRemove(acc)"
                      [(ngModel)]="acc.checked" />
                    <label for="{{ acc.hotspot_name }}"></label>
                  </div>
                </div>
                <div class="accessoriesListimg">
                  <img [src]="accUrl + acc.thumb_url" alt="img" />
                </div>
                <h2 class="fg accessoriesListinfo">
                  {{
                  acc.accessories_count == 1
                  ? acc.marketing_name
                  : acc.subgroup_name
                  }}<span><i class="fa fa-rupee" aria-hidden="true"></i>
                    {{ acc.price }}</span>
                  <span (click)="gotoAccList($event, acc)" style="color: red; cursor: pointer"
                    *ngIf="acc.accessories_count != 1">
                    Explore more options
                  </span>
                </h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--Show Top Exterior Accessories-->
      <!--Show Top Interior Accessories-->
      <div class="colorAccordion_head categoryLinkList" *ngIf="accList && accList.interior_subgroup"
        [ngClass]="{ active: selectedAccGroup == '2' }" (click)="selectAccgrp('2')">
        <div class="categoryLinkListicon">
          <div class="categoryLinkListiconi">
            <img src="./assets/images/accessories-int-icon.svg" />
          </div>
          Show Top Interior Accessories
        </div>
      </div>
      <div class="colorAccordion_body" [ngStyle]="{ display: selectedAccGroup == '2' ? 'block' : 'none' }">
        <div class="fg accessorieslist">
          <ul>
            <li *ngFor="let acc of accList && accList.interior_subgroup">
              <div class="accessoriesList">
                <div class="accessoriesListcheck">
                  <!-- (change)="accAddRemove(acc, $event)" [checked]="acc.flag == 'Remove'" -->
                  <div class="iuthis mngcheckBox" *ngIf="acc.accessories_count == 1 || acc.flag == 'Remove'">
                    <input id="{{ acc.hotspot_name }}" type="checkbox" name="check" (ngModelChange)="accAddRemove(acc)"
                      [(ngModel)]="acc.checked" />
                    <label for="{{ acc.hotspot_name }}"></label>
                  </div>
                </div>
                <div class="accessoriesListimg">
                  <img [src]="accUrl + acc.thumb_url" alt="img" />
                </div>
                <h2 class="fg accessoriesListinfo">
                  {{
                  acc.accessories_count == 1
                  ? acc.marketing_name
                  : acc.subgroup_name
                  }}<span><i class="fa fa-rupee" aria-hidden="true"></i>
                    {{ acc.price }}</span>
                  <span (click)="gotoAccList($event, acc)" style="color: red; cursor: pointer"
                    *ngIf="acc.accessories_count != 1">
                    Explore more options
                  </span>
                </h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--Show Top Interior Accessories-->
      <!--Show Essential Accessories-->
      <div class="colorAccordion_head categoryLinkList" *ngIf="accList && accList.essential_subgroup"
        [ngClass]="{ active: selectedAccGroup == '3' }" (click)="selectAccgrp('3')">
        <div class="categoryLinkListicon">
          <div class="categoryLinkListiconi">
            <img src="./assets/images/accessories-ese-icon.svg" />
          </div>
          Show Essential Accessories
        </div>
      </div>
      <div class="colorAccordion_body" [ngStyle]="{ display: selectedAccGroup == '3' ? 'block' : 'none' }">
        <div class="fg accessorieslist">
          <ul>
            <li *ngFor="let acc of accList && accList.essential_subgroup">
              <div class="accessoriesList">
                <div class="accessoriesListcheck">
                  <!-- (change)="accAddRemove(acc, $event)" [checked]="acc.flag == 'Remove'" -->
                  <div class="iuthis mngcheckBox" *ngIf="acc.accessories_count == 1 || acc.flag == 'Remove'">
                    <input id="{{ acc.hotspot_name }}" type="checkbox" name="check" (ngModelChange)="accAddRemove(acc)"
                      [(ngModel)]="acc.checked" />
                    <label for="{{ acc.hotspot_name }}"></label>
                  </div>
                </div>
                <div class="accessoriesListimg">
                  <img [src]="accUrl + acc.thumb_url" alt="img" />
                </div>
                <h2 class="fg accessoriesListinfo">
                  {{
                  acc.accessories_count == 1
                  ? acc.marketing_name
                  : acc.subgroup_name
                  }}<span><i class="fa fa-rupee" aria-hidden="true"></i>
                    {{ acc.price }}</span>
                  <span (click)="gotoAccList($event, acc)" style="color: red; cursor: pointer"
                    *ngIf="acc.accessories_count != 1">
                    Explore more options
                  </span>
                </h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--Show Essential Accessories-->
      <!--Show Essential Accessories-->
      <div class="colorAccordion_head categoryLinkList" *ngIf="accList && accList.stickers_subgroup"
        [ngClass]="{ active: selectedAccGroup == '5' }" (click)="selectAccgrp('5')">
        <div class="categoryLinkListicon">
          <div class="categoryLinkListiconi">
            <img src="./assets/images/accessories-stickers-icon.svg" />
          </div>
          Stickers
        </div>
      </div>
      <div class="colorAccordion_body" [ngStyle]="{ display: selectedAccGroup == '5' ? 'block' : 'none' }">
        <div class="fg accessorieslist">
          <ul>
            <li *ngFor="let acc of accList && accList.stickers_subgroup">
              <div class="accessoriesList">
                <div class="accessoriesListcheck">
                  <!-- (change)="accAddRemove(acc, $event)" [checked]="acc.flag == 'Remove'" -->
                  <div class="iuthis mngcheckBox" *ngIf="acc.accessories_count == 1 || acc.flag == 'Remove'">
                    <input id="{{ acc.hotspot_name }}" type="checkbox" name="check" (ngModelChange)="accAddRemove(acc)"
                      [(ngModel)]="acc.checked" />
                    <label for="{{ acc.hotspot_name }}"></label>
                  </div>
                </div>
                <div class="accessoriesListimg">
                  <img [src]="accUrl + acc.thumb_url" alt="img" />
                </div>
                <h2 class="fg accessoriesListinfo">
                  {{
                  acc.accessories_count == 1
                  ? acc.marketing_name
                  : acc.subgroup_name
                  }}<span><i class="fa fa-rupee" aria-hidden="true"></i>
                    {{ acc.price }}</span>
                  <span (click)="gotoAccList($event, acc)" style="color: red; cursor: pointer"
                    *ngIf="acc.accessories_count != 1">
                    Explore more options
                  </span>
                </h2>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!--Show Essential Accessories-->
    </div>
  </div>
  <!--ACCESSORIZE-->
</div>
<!--maincontainerrightin-->
<!--button-->
<div class="variantlisttitlebtn">
  <div class="variantlisttitlebtnleft">
    <a href="javascript:void(0)" (click)="openLocModal()" class="variantlisttitlebtnleftbtn accessSummaryprice">
      ₹{{ totalPrice }} <br />
      <span>Ex-Showroom Price <b>{{ getStateValue() }}</b></span></a>
    <!-- <a router class="variantlisttitlebtnleftbtn">Accessorize</a> -->
  </div>
  <div class="variantlisttitlebtnright">
    <a class="variantlisttitlebtnrightbtn" href="javascript:void(0)" (click)="gotoSummaryPage()">Summary</a>
  </div>
</div>
<!--button-->
<app-acc-remove-modal *ngIf="isConfirmModalOpen" (dismiss)="onModalDismiss($event)"></app-acc-remove-modal>

<app-overlapping-modal *ngIf="isOverlappingModal" (dismiss)="onOverlapModalDismiss($event)"></app-overlapping-modal>