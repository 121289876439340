<div
  class="secObjectwidth284"
  [ngClass]="{ secObjectOpenForm: isEnqFormOpen || isAppointmentFormOpen }"
  [ngClass]="{
    'introducing-expert-is-open':
      toggleWarnMsg || showAgentBusy || isAppointmentFormOpen || isEnqFormOpen
  }"
>
  <div class="fg secObjectltop">
    <div class="secObjectltopleft">
      <div class="secObjectltopleftthumb">
        <div class="secObjectltopleftthumbimgbox">
          <img src="./assets/images/mngthumb.jpg" alt="img" />
          <div class="volumeinner" (click)="onMute()">
            <span class="material-icons md-light md-18">
              {{ !isSoundMuted ? "volume_up" : "volume_off" }}
            </span>
          </div>
        </div>
      </div>
      <div class="fg secObjectltopleftinfo">
        <div class="fg secObjectltopleftinfotitle1">
          <span>AVIRA</span>
          <div class="secObjectltopleftinfotitle1b">
            <app-wave-animation
              [showAnim]="isPlaying && !isSoundMuted"
            ></app-wave-animation>
          </div>
        </div>
        <div
          class="fg secObjectltopleftinfotitle3 secObjectltopleftinfotitle3-your-expert"
          *ngIf="!showAgentWaiting">
          <b style="color: rgba(98, 195, 89, 1)">Tap to Connect</b>
        </div>

        <div
          class="fg secObjectltopleftinfotitle3 secObjectltopleftinfotitle3-new"
          *ngIf="showAgentWaiting">
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
          <!-- <img src="./assets/images/three-red-dots-animation.svg" /> -->
          Connecting with <b>MG eXpert</b>
        </div>
        <div class="secObjectltopleftthumb2">
          <div
            class="secObjectltopleftthumb2in secObjectltopleftthumb2incalling"
            (click)="checkShownVideoContentAndConnect(true)"
            *ngIf="showPulseUpConnectButton">
            <img src="./assets/images/calling-icon.svg" />
            <span
              class="calling-hand-animated"
              [hidden]="toggleWarnMsg || isEnqFormOpen ||
              isAppointmentFormOpen || 
              isAppointmentRescheduleFormOpen ||
              isAppointmentSuccessModalOpen
              " >
              <img src="./assets/images/calling-hand.svg" />
            </span>
          </div>
          <div
            class="secObjectltopleftthumb2in secObjectltopleftthumb2incalling"
            (click)="toggleAgentTiming()"
            *ngIf="showPulseUpAgentNotAvailable"
          >
            <img src="./assets/images/agent-not-available.svg" />
          </div>
          <div
            class="secObjectltopleftthumb2in secObjectltopleftthumb2in-timer"
            *ngIf="showAgentWaiting"
          >
            <img src="./assets/images/new-clock-timer.svg" />
            <span
              class="new-timer-time"
              [counter]="120"
              [interval]="1000"
              (value)="onTimeValueChange($event)"
              (onComplete)="onTimerComplete($event)"
              >{{ counter }}s</span
            >
          </div>
        </div>
      </div>

      <div
        class="like-to-join-expert-wrapper introducing-expert-wrapper"
        *ngIf="toggleWarnMsg && !disabledMicAccess"
      >
        <div class="like-to-join-expert" >
          <div class="introducing-expert-main-wrap">
            <h4>INTRODUCING MG EXPERT</h4>
            <div class="introducing-expert-video-wrap">
              <app-vjs-player
                [muted]="false"
                [options]="videoOptions"
              ></app-vjs-player>
            </div>
            <div class="like-to-join-microphone-top">
              <span class="like-to-join-microphone-icon">
                <img src="assets/images/new-speaker-icon.svg" alt="img" />
              </span>
              <span class="like-to-join-microphone-text">
                We hope you are able to hear the sound.
              </span>
            </div>
            <div class="like-to-join-microphone-top">
              <span class="like-to-join-microphone-icon">
                <img src="assets/images/new-mic-icon.svg" alt="img" />
              </span>
              <span class="like-to-join-microphone-text">
                <strong>Allow</strong> your mic permission to be able to
                converse with our Expert consultant.
              </span>
            </div>
          </div>
          <div class="like-to-join-expert-btns">
            <button
              *ngIf="!hideSkipIcon"
              class="like-to-join-expert-btn like-to-join-expert-btn-not-now"
              (click)="toggleWaringConnect(true)">
              Close
            </button>
            <!-- <button
              class="like-to-join-expert-btn like-to-join-expert-yes"
              [ngClass]="{ 'width-100': hideSkipIcon }"
              *ngIf="!disabledMicAccess"
              (click)="openEnqModal()"
            >
              Continue
            </button> -->
            <button
              class="like-to-join-expert-btn like-to-join-expert-yes"
              [ngClass]="{ 'width-100': hideSkipIcon }"
              (click)="openMicPermission()"
            >
              Allow & Continue
            </button>
          </div>
        </div>
      </div>
      <!--overlay-->
      <!-- <div class="one3d-expert-modal-overlay"></div> -->
      <!--overlay-->

      <div class="like-to-join-expert-wrapper introducing-expert-wrapper" *ngIf="showAgentBusy">
        <div class="like-to-join-expert-oops">
          <h4>
            <strong style="color: black">Oops!</strong>
            <span
              class="like-to-join-expert-oops-close"
              (click)="closeAgentBusy()"
            >
              <img src="./assets/images/cross-white.svg"
            /></span>
          </h4>
          <p style="color: black">
            Seems like all our MG Experts are busy at the moment, try again or schedule a call for later
          </p>
          <div class="like-to-join-expert-btns">
            <button
              class="like-to-join-expert-btn like-to-join-expert-btn-not-now"
              *ngIf="!eventFromRightPanel"
              (click)="retryCall()"
            >
              Retry
            </button>
            <!-- <button
              class="like-to-join-expert-btn like-to-join-expert-btn-not-now"
              *ngIf="eventFromRightPanel"
              (click)="skipOnAppointmentModule()"
            >
              Close
            </button> -->

            <button
              class="like-to-join-expert-btn like-to-join-expert-yes"
              (click)="checkShownVideoContentAndConnect(true)"
            >
            {{ showAgentBusy ? 'Schedule a Call' : 'Connect Now' }}
            </button>
          </div>
        </div>
      </div>

    </div>

    <!--top-->
    <app-enquiry-form
      *ngIf="isEnqFormOpen"
      (openAppointment)="openAppointment()"
      (appointmentChanged)="onAppointmentChanged($event)"
    ></app-enquiry-form>
    <app-appointment-form
      *ngIf="isAppointmentFormOpen"
      [isScheduleButtonClick]="openAppointmentFromEnquiryForm"
      (cancelAppointment)="onCancelAppointment($event)"
      (connectNowClicked)="onConnectNowClicked($event)"
      (appointmentChanged)="onAppointmentChanged($event)"
    ></app-appointment-form>
    <app-appointment-reschedule
      *ngIf="isAppointmentRescheduleFormOpen"
      [appointmentData]="appointmentData"
      (appointmentChanged)="onAppointmentChanged($event)"
      (cancelAppointment)="onCancelAppointment($event)"
      (connectNowClicked)="onConnectNowClicked($event)"
    ></app-appointment-reschedule>
    <app-appointment-success 
    *ngIf="isAppointmentSuccessModalOpen" 
    (onClose)="closePopup()"
    (connectNowClicked)="onConnectNowClicked($event)"
    (onReschedule)="onRescheduleClicked()"
    [appointmentData] = "appointmentData"
    [showRescheduleBtn] = "showRescheduleBtn"
    ></app-appointment-success>
    <!-- <div
      class="objectOverlay like-to-join-expert-wrapper-overlay"
      *ngIf="toggleWarnMsg"
    ></div> -->
  </div>
</div>
<app-microphone-blocked *ngIf="disabledMicAccess"
  (closeDisabledMicPopUp)="onCloseDisabledMicPopUp($event)"
>
</app-microphone-blocked>