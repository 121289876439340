import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { io } from 'socket.io-client';
import { defaultCarDetails } from '../three-d-module/carSettings';
import { DataService } from './data.service';
import { agentState, GlobalService } from './global.service';
import { EventService } from './event.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from './api.service';
import { MgExpertService } from './mg-expert.service';

declare const ONE3D: any;
declare const TweenMax: any;
@Injectable({
  providedIn: 'root',
})
export class SocketService implements OnDestroy {
  socket: any;
  room: string;
  pin_no: string;
  ACCESS: boolean;
  callConnected: boolean;
  joiningByReload: boolean = false;
  pinNo: string;
  connected: boolean;
  constructor(
    private _data: DataService,
    private _expert: MgExpertService,
    private _global: GlobalService,
    private router: Router,
    private event: EventService,
    private _snackBar: MatSnackBar
  ) {
    this._global.pinno.subscribe((data) => {
      this.pin_no = data;
      this.room = `chat${this.pin_no}`;
    });
    this._global.audioStatus.subscribe((data) => (this.callConnected = data));
    this._global.pinno.subscribe((data) => (this.pinNo = data));

    // this.pin_no = window.pin_no;
    let SERVER =
      window.location.hostname === 'cc.mgmotor.co.in'
        ? 'https://api-mgexpert.one3d.in:7458/'
        : 'https://apibutlerstaging.one3d.in:7458/';

    this.socket = io(SERVER, {
      transports: ['websocket'],
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
      query: { type: 'user' },
    });
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this.socket.on('connect', () => {
      console.log('connected data');
      // this._global.updateSocketStatus('connected');
      this.defaultEventsToSubscribe();
      // this.event.publish('connect', null);
      this.connected = true;
    });
    console.log('this.socket', this.socket);

    // this.socket.on('reconnecting', () => {
    //   console.log('reconnect attempt');
    // });

    // this.socket.on('reconnect', () => {
    //   console.log('reconnect Done');
    // });
    // this.socket.on('reconnect_failed', () => {
    //   console.log('reconnect_failed');
    // });
    // this.socket.on('reconnect_error', () => {
    //   console.log('reconnect_error');
    // });
    this.socket.on('connect_error', (err) => {
      if (err.type === 'TransportError') {
        console.error('connection broke =====>>>>', err);
      }
    });
  }
  async saveApiCallJourney(action: string, status: string) {
    try {
      let apiBody = {
        pin_no: this.pinNo,
        action,
        status,
      };
      let resp = await this._expert.saveCallJourney(apiBody);
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  setupSocketConnection() {
    // alert(1)
    console.log(this.socket, 'this.socket.....');
    // if (this.socket.connected != true) {
    this._global.updateSocketStatus('connecting');
    // alert(3+ JSON.stringify(this.socket)),
    this.room = `chat${window.pin_no}`;
    let name = this._data.getLocalName();
    if (!name) {
      name = 'anonymous';
    }
    this.socket.emit('joinRoom', {
      username: name,
      room: this.room,
      role: 'user',
      pin_no: this.pin_no,
      is_shared: false,
      // adminType: ""
    });
    this.saveApiCallJourney('room_join', 'true');
    // this.register3dEvents();
    this.socket.on('error', (error) => {
      console.error(error);
    });
    this.socket.on('disconnect', (error) => {
      console.error('socket disconnected', error);
      this.socket.off('users_updated');
      this.socket.off('rotate');
      this.socket.off('fov');
    });

    this.socket.on('users_updated', (data) => {
      console.log('user added', data);
      this._global.updateGroupList(data.users);
      let findUser = data.users.find((ele) => ele.id == this.socket.id);
      let findAdmin = data.users.find((ele) => ele.role == 'admin');
      console.log('findAdmin', findAdmin);
      if (findUser) {
        this._global.updateAccessUI(findUser.controller);
        if (window.innerWidth <= 750 && window.innerHeight <= 900) {
          if (findUser.controller == true) {
            ONE3D.halfPortrait();
          } else {
            ONE3D.fullPortrait();
          }
        }
      }
      if (findAdmin) {
        findAdmin.image_url =
          'https://dxf3kulhcrbjg.cloudfront.net/mg_expert/agent_image/' +
          findAdmin.username +
          '.jpg';
        console.log('findAdmin', findAdmin);
        window.updatePayloadParameter('eLa6', findAdmin.image_url);
        const agentName =
          findAdmin.hasOwnProperty('dealer') && findAdmin.dealer != ''
            ? findAdmin.dealer + findAdmin.username
            : findAdmin.username;
        window.updatePayloadParameter('eLa9', agentName);
        this._global.updateAgentDetails(findAdmin);
      }
    });

    this.socket.on('rotate', (data) => {
      ONE3D.event._setCameraAlpha(data['alpha']);
      ONE3D.event._setCameraBeta(data['beta']);
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        ONE3D.event._setCameraRadius(data['radius'] + 4);
      } else {
        ONE3D.event._setCameraRadius(data['radius']);
      }
    });
    this.socket.on('fov', (data) => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        ONE3D.event.getScene().cameras[0].fov = Number(data['fov']) + 0.1;
      } else {
        ONE3D.event.getScene().cameras[0].fov = data['fov'];
      }
    });
    this.socket.on('new_user_joined', (data) => {
      console.log('data iss', data);
      let loadedCar = this._data.getLocalCarBrand();
      this._global.updateHide3D(false);
      // let url = defaultCarDetails[loadedCar].urlScheme;
      // this.router.navigate([`${url}/variants`]);
    });
    this.socket.on('receive_compare_variant', (data) => {
      this.event.publish('receive_compare_variant', data);
    });
    this.socket.on('roomUsers', (data) => {
      this.event.publish('roomUsers', data);
    });
    this.socket.on('color_change_data', (data) => {
      this.event.publish('color_change_data', data);
    });
    this.socket.on('access_change', (data) => {
      this.event.publish('access_change', data);
    });
    this.socket.on('connected', (data) => {
      console.log('connected data', data);
      this.event.publish('connected', data);
    });
    this.socket.on('admin_drop_call', (data) => {
      this.event.publish('admin_drop_call', data);
    });
    this.socket.on('change_variant', (data) => {
      this.event.publish('change_variant', data);
    });
    this.socket.on('acc_add_data', (data) => {
      this.event.publish('acc_add_data', data);
    });
    this.socket.on('acc_remove_data', (data) => {
      this.event.publish('acc_remove_data', data);
    });
    this.socket.on('kit_add_data', (data) => {
      // console.log("check this 224", JSON.stringify(data))
      this.event.publish('kit_add_data', data);
    });
    //1
    this.socket.on('add_additional_kit_data', (data) => {
      console.log("check this 229", data)
      this.event.publish('add_additional_kit_data', data);
    });
    //2
    this.socket.on('remove_additional_kit_data', (data) => {
      console.log("check this 234", data)
      this.event.publish('remove_additional_kit_data', data);
    });
    //3
    this.socket.on('add_optional_kit_data', (data) => {
      console.log("check this 240", data)
      this.event.publish('add_optional_kit_data', data);
    });
    //4
    this.socket.on('remove_optional_kit_data', (data) => {
      console.log("check this 246", data)
      this.event.publish('remove_optional_kit_data', data);
    });
    this.socket.on('kit_remove_data', (data) => {
      console.log("check this 244", data)
      this.event.publish('kit_remove_data', data);
    });
    //Here
    this.socket.on('add_graphics_kit_data', (data) => {
      console.log("check this 253", data)
      this.event.publish('add_graphics_kit_data', data);
    });

    this.socket.on('remove_graphics_kit_data', (data) => {
      console.log("check this 258", data)
      this.event.publish('remove_graphics_kit_data', data);
    });

    this.socket.on('add_graphic_option_data', (data) => {
      console.log("check this 263", data)
      this.event.publish('add_graphic_option_data', data);
    });

    this.socket.on('remove_graphic_option_data', (data) => {
      console.log("check this 268", data)
      this.event.publish('remove_graphic_option_data', data);
    });





    this.socket.on('receive_emi_data', (data) => {
      this.event.publish('receive_emi_data', data);
    });
    this.socket.on('receive_car_exchange_data', (data) => {
      this.event.publish('receive_car_exchange_data', data);
    });
    this.socket.on('connect_call_admin', (data) => {
      this.event.publish('connect_call_admin', data);
    });
    this.socket.on('dataTransfer', (data) => {
      this.event.publish('dataTransfer', data);
    });

    // } else {
    //   this._snackBar.open('Already Connected', '', {
    //     duration: 5000,
    //   });
    // }
  }
  defaultEventsToSubscribe() {
    this.socket.on('agent_available', () => {
      console.log('agent_available');
      this.event.publish('agent_available', null);
      this._global.updateAgentAvailability(agentState.online);
    });
    this.socket.on('agent_not_available', () => {
      this.event.publish('agent_not_available', null);
      this._global.updateAgentAvailability(agentState.busy);
    });
    this.socket.on('agent_offline', () => {
      this.event.publish('agent_offline', null);
      this._global.updateAgentAvailability(agentState.offline);
    });
    this.socket.on('join_ongoing_call', () => {
      this.joiningByReload = true;
      this._global.updateFirstTimeConnection(true);
      // document.getElementById('video').style.display = 'none';
      this.setupSocketConnection();
    });
    this.socket.on('model_change_in_room', (data) => {
      this.onModelChange(data);
    });
    console.log('socket', this.socket);
  }
  onModelChange(data) {
    console.log('Logged--->1');

    let selectedModel: any = Object.values(defaultCarDetails).find(
      (obj: any) => {
        return obj.model_id == data.model_id;
      }
    );
    console.log('selectedModel--->', selectedModel);

    this._global.updateNewPinWhenModelChange(false);
    this._global.updateEmiOptions({});
    this._global.updateMGShield({
      buybackPlan: '',
      protectPlan: '',
      roadsideAssist: '',
      warranty: '',
    });
    this.router.navigate([`${selectedModel.urlScheme}/variants`]);
    // setTimeout(() => {
    //   this._global.updateHectorSeatingOption(null);
    // }, 3000);
    // throw new Error('Method not implemented.');
  }

  disconnect() {
    if (this.socket) {
      console.log('Disconnected called');
      this.socket.disconnect();
      // this.socket.disconnect();
    }
  }

  emitChangeVariant(selectedVariant: any) {
    if (this.callConnected) {
      this.socket.emit('change_variant', {
        room: this.room,
        variant: selectedVariant,
      });
    }
  }
  emitVariantDetails(selectedVariant: any) {
    if (this.callConnected) {
      this.socket.emit('variant_details', {
        room: this.room,
        pin_no: this.pin_no,
        variant: selectedVariant,
      });
    }
  }
  emitChangeColor(selectedColor: any) {
    if (this.callConnected) {
      this.socket.emit('color_change', {
        room: this.room,
        color: selectedColor,
      });
    }
  }
  emitChangeInteriorColor(selectedColor: any) {
    if (this.callConnected) {
      this.socket.emit('color_change', {
        room: this.room,
        interiorColor: selectedColor,
      });
    }
  }
  emitAccAdd(accdetails: any) {
    if (this.callConnected) {
      this.socket.emit('acc_add', {
        room: this.room,
        acc: accdetails,
      });
    }
  }
  emitAccRemove(accdetails: any) {
    if (this.callConnected) {
      this.socket.emit('acc_remove', {
        room: this.room,
        acc: accdetails,
      });
    }
  }
  emitAccCamReset() {
    if (this.callConnected) {
      this.socket.emit('dataTransfer', {
        room: this.room,
        resetAccCam: null,
      });
    }
  }
  emitGoBackToVariant() {
    if (this.callConnected) {
      this.socket.emit('dataTransfer', {
        room: this.room,
        gotoVariantPage: null,
      });
    }
  }
  emitCheckAgentStatus() {
    if (this.socket.connected) {
      this.socket.emit('check_agent_availability');
    } else {
      console.error('socket not connected');
    }
  }
  checkRunningCall() {
    if (this.socket.connected) {
      this.socket.emit('check_running_session', { room: this.room });
    } else {
      console.error('socket not connected');
    }
  }

  emitUserLeftBrowserTab() {
    if (this.callConnected) {
      this.socket.emit('dataTransfer', {
        room: this.room,
        userLeftBrowserTab: true,
      });
    }
  }

  register3dEvents() {
    if (ONE3D) {
      ONE3D.event
        .getScene()
        .cameras[0].onViewMatrixChangedObservable.add((camera: any) => {
          // console.log('============= >>>');
          if (this.ACCESS && this.socket.connected && this.callConnected) {
            this.socket.emit('rotate', {
              alpha: camera.alpha,
              beta: camera.beta,
              radius: camera.radius,
            });
            this.socket.emit('fov', { fov: camera.fov });
          }
        });
    } else {
      console.error('One3d missing at rotation subscriber');
    }
  }

  emitModelCompletedWhenCall() {
    this.socket.emit('dataTransfer', {
      room: this.room,
      modelLoadCompleteOnClient: true,
    });
  }
}
