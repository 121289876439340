import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/service/api.service';
import { GlobalService } from 'src/app/service/global.service';
import { SnackbarService } from 'src/app/service/snackbar.service';
import { MgExpertService } from 'src/app/service/mg-expert.service';
import { DataService } from 'src/app/service/data.service';

@Component({
  selector: 'feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  /**
   * rating parameter for check
   */
  ratingGiven: number = 0;
  /**
   * rating array
   */
  ratingSystem: any;
  pin_no: string;
  selectedT: any;
  rattingQuestion: string;
  questionList: any;
  secondQ: any = '';
  firstQ: any = '';
  feedBackText: string = '';
  constructor(
    private _global: GlobalService,
    private _expert: MgExpertService,
    private _api: ApiService,
    private _snack: SnackbarService,
    private _data:DataService
  ) {
    this._global.pinno.subscribe((data) => (this.pin_no = data));
  }

  ngOnInit(): void {
    this.selectedT = {
      1: false,
      2: false,
      3: false,
    };
    this.getRateSystem();
    this.getRateQuestions();
    window.sendData({
      eName: 'form_view',
      eCat: 'agent_feedback',
    });
  }
  async getRateQuestions() {
    try {
      let resp: any = await this._api.feedbackQuestionList();
      console.log('resp', resp);

      if (resp.status == 1) {
        this.questionList = resp.response;
      }
    } catch (error) {
      console.log(error);
    }
  }
  async getRateSystem() {
    try {
      let resp = await this._api.getRateingSystemJson();
      // console.log('resp====>>', resp);
      this.ratingSystem = resp;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * submit review
   */
  submitReview() {
    // console.log('reviewForm', reviewForm);
    try {
      let checkedIDs = [];
      this.ratingSystem[this.ratingGiven].rattingAnswer.forEach(
        (element, i) => {
          if (this.selectedT[i + 1] == true) {
            checkedIDs.push(element.text);
          }
        }
      );
      console.log('checkedIDs--->', checkedIDs);

      let body = {
        pin_no: this.pin_no,
        star: this.ratingGiven,
        what_went_wrong: checkedIDs.join(','),
        question_ans: this.firstQ,
        buy_plan: this.secondQ,
        feedback: this.feedBackText,
      };
      let resp: any = this._api.feedbackSave(body);
      console.log('resp', resp);
      if (resp) {
        this._global.toggleFeedBackFormModal(false);
        window.sendData({
          eName: 'form_view',
          eCat: 'agent_feedback',
          eAct: 'agent_feedback_from_submit',
          eVal: this.feedBackText,
          eLa4: checkedIDs.join(','),
        });
        this._snack.openSnackBar('Feedback submitted successfully', 'Success');
        //Reset the storage for car load - 
        // localStorage.removeItem('onLoadCounter');
        this._data.clearLoadCounter();
      }
    } catch (error) {
      console.error('error in feed', error);
      this._global.toggleFeedBackFormModal(false);
      this._snack.openSnackBar('Please, try after sometime', 'Error');
    }
  }

  starColorPath(currentPos: number) {
    if (this.ratingGiven == 0) return 'graystar';
    if (this.ratingGiven >= 1 && this.ratingGiven < 3) {
      return currentPos <= this.ratingGiven ? 'redstar' : 'graystar';
    } else if (this.ratingGiven >= 3 && this.ratingGiven <= 4) {
      return currentPos <= this.ratingGiven ? 'yellowstar' : 'graystar';
    } else {
      return 'greenstar';
    }
  }
  rateGivenNumber(n: number) {
    this.ratingGiven = n;
    this.selectedT = {
      1: false,
      2: false,
      3: false,
    };
    window.sendData({
      eName: 'form_view',
      eCat: 'agent_feedback',
      eAct: 'agent_feedback_select_star',
      eVal: n,
    });
  }
  selectActionsMulti(n: number) {
    this.selectedT[n] = !this.selectedT[n];
    console.log('this.selectedT', this.selectedT);
  }
  checkActionExits(n: number) {
    // console.log('this.selectedT', this.selectedT);

    return this.selectedT[n];
  }
}
