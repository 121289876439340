import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HectorWebComponent } from './hector-web/hector-web.component';
import { VariantsComponent } from './variants/variants.component';
import { AccessorizeListComponent } from './accessorize-list/accessorize-list.component';
import { HomeComponent } from './home/home.component';
import { FeaturesComponent } from './features/features.component';
import { SummaryComponent } from './summary/summary.component';
import { EmiCalculatorComponent } from './emi-calculator/emi-calculator.component';
import { ProtectionPlanComponent } from './protection-plan/protection-plan.component';
import { KModelComponent } from './k-model/k-model.component';
import { AccDetailsComponent } from './acc-details/acc-details.component';
import { SelfExploreComponent } from './self-explore/self-explore.component';
import { SingleFeaturePlayComponent } from './single-feature-play/single-feature-play.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { ZsevModelComponent } from './zsev-model/zsev-model.component';
import { HectorPlusComponent } from './hector-plus/hector-plus.component';
import { GlosterModelComponent } from './gloster-model/gloster-model.component';
import { CarExchangeComponent } from './car-exchange/car-exchange.component';
import { NewHectorNextGenComponent } from './new-hector-next-gen/new-hector-next-gen.component';
import { NewHectorplusNextGenComponent } from './new-hectorplus-next-gen/new-hectorplus-next-gen.component';
import { EModelComponent } from './e-model/e-model.component';
import { LimitedEditionAccessoriesComponent } from './limited-edition-accessories/limited-edition-accessories.component';
import { FeaturesListComponent } from './features-list/features-list.component';

const childrenArray = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'variants',
    component: VariantsComponent,
  },
  {
    path: 'accessorize',
    component: AccessorizeListComponent,
  },
  {
    path: 'acc-details',
    component: AccDetailsComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'features',
    component: FeaturesComponent,
  },
  {
    path: 'single-feature-play',
    component: SingleFeaturePlayComponent,
  },
  {
    path: 'emi-calculator',
    component: EmiCalculatorComponent,
  },
  {
    path: 'feedback-form',
    component: FeedbackFormComponent,
  },
  {
    path: 'summary',
    component: SummaryComponent,
  },
  {
    path: 'self-explore',
    component: SelfExploreComponent,
  },
  {
    path: 'car-exchange',
    component: CarExchangeComponent,
  },
  {
    path: 'limited-edition-accessories',
    component: LimitedEditionAccessoriesComponent,
  },
  {
    path: 'features-list',
    component: FeaturesListComponent,
  },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'astor',
    pathMatch: 'full',
  },
  {
    path: 'hector',
    redirectTo: "new-hector",
  },
  {
    path: 'hectorplus',
    redirectTo: 'new-hectorplus',
  },
  {
    path: 'astor',
    component: KModelComponent,
    children: childrenArray
  },
  {
    path: 'zsev',
    component: ZsevModelComponent,
    children: childrenArray
  },
  {
    path: 'gloster',
    component: GlosterModelComponent,
    children: childrenArray
  },
  {
    path: 'new-hector',
    component: NewHectorNextGenComponent,
    children: childrenArray
  },
  {
    path: 'new-hectorplus',
    component: NewHectorplusNextGenComponent,
    children: childrenArray
  },
  {
    path: 'e-model',
    component: EModelComponent,
    children: childrenArray
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThreeDModuleRoutingModule {}
