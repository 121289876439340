<!--header-start-->
<header>
  <!--main-logo-->
  <div class="topLogo">
    <a href="javascript:void(0)"><img src="./assets/images/mg-logo.svg" alt="main_logo" /></a>
    <div class="topLogohector">
      <!-- <img src="./assets/images/hectorlogo.svg" /> -->
      <img [src]="getBrandLogo()" alt="brand_logo" />
    </div>
  </div>
  <!--main-logo-->
</header>
<!--header-end-->

<!--sec object-->
<div class="secObject secObjectfull">
  <!--right object-->
  <div class="secObjectr">
    <div
      class="secObjectwidth284"
      [ngClass]="{ secObjectOpenForm: showQueryForm }"
    >
      <app-olivia-audio
        *ngIf="!audioPlayed && loadedCar"
        [audioURL]="soundTrackURL"
        (audioComplete)="audioCompleted($event)"
      ></app-olivia-audio>
      <!--top-->
      <div class="fg secObjectltop" *ngIf="audioPlayed">
        <div class="secObjectltopleft">
          <div class="secObjectltopleftthumb">
            <div class="secObjectltopleftthumbimgbox">
              <img src="./assets/images/mngthumb.jpg" alt="img" />
              <div class="volumeinner" (click)="toggleMute()">
                <!-- <img src="./assets/images/volume.svg" /> -->
                <span class="material-icons md-light md-18">
                  {{ !isSoundMuted ? "volume_up" : "volume_off" }}
                </span>
              </div>
            </div>
          </div>
          <div class="fg secObjectltopleftinfo">
            <div class="fg secObjectltopleftinfotitle1">
              <span>AVIRA</span>
              <div class="secObjectltopleftinfotitle1b">
                <!-- <i>
                  <b><img src="./assets/images/sound-play-icon2.png" /></b>
                  <b><img src="./assets/images/cclogo.svg" /></b>
                </i> -->
                <app-wave-animation
                  [showAnim]="isVideoPlaying && !isSoundMuted"
                ></app-wave-animation>
              </div>
            </div>
            <div class="fg secObjectltopleftinfotitle3">
              Your <b>MG eXpert</b>
            </div>
          </div>
          <!-- <div class="secObjectltopleftthumb2">
            <div
              class="secObjectltopleftthumb2in secObjectltopleftthumb2incalling"
            >
              <img src="./assets/images/calling-icon.svg" />
            </div>
          </div> -->
        </div>
      </div>
      <!--top-->
      <!--bottom-->
      <div class="fg secObjectlbtm" *ngIf="showQueryForm">
        <div class="fg secObjectlbtminner">
          <!--close button-->
          <!--<div class="secObjectlbtmClose"><a href="#"><img src="./assets/images/objectcloseicon.svg"></a></div>-->
          <!--close button-->
          <!--secObjectlbtmform-->
          <form [formGroup]="queryForm" (ngSubmit)="onSubmit()">
            <div class="secObjectlbtmform">
              <!--input-->
              <div
                class="floating-label coobjectinputlable"
                [ngClass]="{
                  errmessageredinput: hasError('name', 'required')
                }"
              >
                <input
                  class="coobjectinput"
                  value=""
                  formControlName="name"
                  maxlength="100"
                  type="text"
                  placeholder="Enter first name"
                  (click)="onCheckboxChange(iAgree)"
                />

                <label>First Name *</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('name', 'required')"
                >
                  Please fill First Name
                </div>
                <div
                class="fg errmessagered"
                *ngIf="hasError('name', 'pattern')"
              >
                Please enter a valid name
              </div>
              </div>
              <!--input-->

              <!--input-->
              <div
                class="floating-label coobjectinputlable"
                [ngClass]="{
                  errmessageredinput:
                    hasError('mobile', 'required') ||
                    hasError('mobile', 'pattern')
                }"
              >
                <!--mobile number-->
                <div class="fg">
                  <input
                    class="floating-input coobjectinput"
                    type="tel"
                    formControlName="mobile"
                    maxlength="10"
                    placeholder="Enter Mobile Number"
                    (keyup)="onChangeMobileNo()"
                    (click)="onCheckboxChange(iAgree)"
                  />
                  <!-- <label>Mobile Number *</label> -->
                  <div class="coobjectinputlablerightspacebtn">
                    <button
                      class="submitbuttonobject"
                      *ngIf="enableSendOTPButton"
                      type="button"
                      (click)="sendOTP()"
                      
                    >
                      Send OTP
                    </button>

                    <button
                      class="submitbuttonobject"
                      *ngIf="enableTimer"
                      type="button"
                      (click)="sendOTP()"
                      [disabled]="showResendOtpCounter"
                      (click)="onCheckboxChange(iAgree)"
                    >
                      {{
                        showResendOtpCounter
                          ? "Resend in " + countdown + "s"
                          : "Resend OTP"
                      }}
                    </button>
                  </div>
                </div>
                <label>Mobile Number *</label>
                <!--err message-->
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('mobile', 'required')"
                >
                  Please fill Mobile Number
                </div>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('mobile', 'pattern')"
                >
                  Please enter valid 10 digit Mobile Number
                </div>
                <!--err message-->
              </div>

              <!--input-->
              <!--input-->
              <div
                class="floating-label coobjectinputlable"
                *ngIf="showOTPField"
                [ngClass]="{
                  errmessageredinput:
                    hasError('otp', 'required') || failedVerifiedOpt
                }"
              >
                <input
                  class="floating-input coobjectinput"
                  value=""
                  type="tel"
                  formControlName="otp"
                  maxlength="4"
                  placeholder="Enter OTP"
                  (click)="onCheckboxChange(iAgree)"
                />
                <label>OTP *</label>
                <div
                  class="fg errmessagered"
                  *ngIf="hasError('otp', 'required') || failedVerifiedOpt"
                >
                  OTP is not correct
                </div>
              </div>
              <!--input-->
              <!--  -->
              <!-- "iAgree = !iAgree" formControlName="checkPrivacyPolicy"  ; onCheckboxChange(iAgree) -->
              <div class="fg detailforminfoRow privacyleftOuter">
                <div class="privacyleft">
                  <div class="iuthis mngcheckBox">
                    <input
                      id="iagree"
                      type="checkbox"
                      [checked]="iAgree"
                      (change)="iAgree = !iAgree" 
                    />
                    <label for="iagree"></label>
                  </div>
                </div>
                <div class="privacyright">
                  I agree to the
                  <span
                    ><a href="https://www.mgmotor.co.in/privacy" (click)="logPolicyClick()" target="_blank"
                      >Privacy Policy</a
                    ></span
                  >
                </div>
              </div>
              <!--  -->
              <div class="fg secObjectlbtmformbtn">
                <div class="secObjectlbtmformbtnleft">
                  <button
                    class="submitbuttonobject2"
                    type="button"
                    (click)="gotoHomePage();logSkipEvent()"
                  >
                    Skip
                  </button>
                </div>
                <div class="secObjectlbtmformbtnright">
                  <button
                    class="submitbuttonobject"
                    type="button"
                    [disabled]="queryForm.invalid || !iAgree"
                    (click)="onSubmit()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <!--secObjectlbtmform-->
        </div>
      </div>
      <!--bottom-->
    </div>
  </div>
  <!--right object-->
</div>
<!--sec object-->
<div class="objectOverlay" *ngIf="showQueryForm"></div>
<!--containt-start-->
<section>
  <!--canvas-area-->
  <div class="canvasheight newCanvas">
    <div class="introThumbOuter">
      <!--play area-->
      <div class="introVideobtn">
        <div class="introVideobtntop">
          <a href="javascript:void(0)" (click)="stopAudioPlaying()">
            <div class="introVideobtnplaybgin">
              <div class="introVideobtnplaybg">
                <img src="./assets/images/playbgicon.svg" />
              </div>
            </div>
            <span>Play Now</span>
          </a>
        </div>
        <!-- <div class="introVideobtnbtm">
          <div class="introVideobtnbtmskip">
            <a href="javascript:void(0)" (click)="redirectToHome()">
              <span>Skip</span>
              <b><img src="./assets/images/skip-btn-icon.svg" /></b>
            </a>
          </div>
        </div> -->
      </div>

      <!--img thumb-->
      <div class="fg introThumb" *ngIf="!videoPayed">
        <img [src]="videoThumbNail" alt="videothumb" />
      </div>
      <!--img thumb-->
      <!--video thumb-->
      <div class="fg introVideo" *ngIf="videoPayed">
        <div class="introVideo-wrap">
          <app-vjs-player
            [muted]="isSoundMuted"
            [options]="videoOptions"
            (videoCompleted)="videoCompleted($event)"
            (onError)="onErrorEvent($event)"
          ></app-vjs-player>
        </div>
      </div>
      <!--video thumb-->
    </div>
    <!--title-->

    <div class="new-skip-intro-video-wrapper">
      <div class="new-introVideooverlaytitle">
        <div
          class="introVideooverlaytitle"
          *ngIf="!videoPayed && !this.showQueryForm"
        >
          The video will autoplay in
          <span [counter]="15" [interval]="1000" (value)="counter = $event"
            >{{ counter }} seconds....</span
          >
        </div>
      </div>
      <div class="introVideobtnbtm">
        <div class="introVideobtnbtmskip">
          <a href="javascript:void(0)" (click)="redirectToHome()">
            <span>Skip</span>
            <b><img src="./assets/images/skip-btn-icon.svg" /></b>
          </a>
        </div>
      </div>
    </div>

    <!-- <div class="introVideooverlaytitle" *ngIf="!videoPayed">
      The video will autoplay in
      <span [counter]="15" [interval]="1000" (value)="counter = $event"
        >{{ counter }} seconds....</span
      >
    </div> -->

    <!--overlay-->
    <div class="introVideooverlay"></div>
  </div>
  <!--canvas-area-->

  <!--top-center-contain-->
  <!-- <div class="newThemeBlc">
    <div class="newThemeBlcin">
      <div class="fg newThemeIntro">
        Lorem ipsum dolor sit amet, consectetur<br />
        adipiscing eli...
      </div>
    </div>
  </div> -->
  <!--top-center-contain-->
</section>
<!--containt-end-->
