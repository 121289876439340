import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar-material',
  templateUrl: './snackbar-material.component.html',
  styleUrls: ['./snackbar-material.component.scss'],
})
export class SnackbarMaterialComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    console.log(data);
  }
  iconName: string;
  className: string;
  ngOnInit(): void {
    this.getIcon();
    this.getIconColor();
  }

  getIcon() {
    switch (this.data.snackType) {
      case 'Success':
        this.iconName = 'check_circle';
        break;
      case 'Error':
        this.iconName = 'error';
        break;
      case 'Warn':
        this.iconName = 'warning';
        break;
      case 'Info':
        this.iconName = 'info';
        break;
      default:
        break;
    }
  }
  getIconColor() {
    switch (this.data.snackType) {
      case 'Success':
        this.className = 'success';
        break;
      case 'Error':
        this.className = 'error';
        break;
      case 'Warn':
        this.className = 'warning';
        break;
      case 'Info':
        this.className = 'info';
        break;
      default:
        break;
    }
  }
}
