<!--maincontainerrightin-->
<div class="maincontainerrightin new-variants-main-container">
  <!--title-->
  <div class="fg variantlisttitle new-variantlisttitle-box">
    <button
      mat-icon-button
      aria-label="Back to Home"
      [hidden]="SOCKET_STATUS == 'connected'"
      (click)="goBack()"
    >
      <mat-icon class="icon-back-mat">chevron_left</mat-icon>
    </button>
    <span>VARIANTS</span>
    <div class="variantlisttitleComparebtn">
      <!-- *ngIf="loadedCar != 'zsev'" -->
      <a
        href="javascript:void(0)"
        (click)="openCarCompareModal()"
        class="vlcomparebtn"
        ><span><img src="./assets/images/taxi-icon.svg" /></span
        ><span>Compare</span></a
      >
    </div>
  </div>
  <!--title-->

  <!--VARIANTS-->
  <div class="veriantlistprice">
    <!-- <div class="one3d-nodata-found">No data found</div> -->

    <!-- class="one3d-tag-show" -->
    <ul [ngClass]="{'hundred-years-edition-add': vCat == '100YEAR'}" *ngFor="let vCat of uniqueVariantCat">
      <div
        class="hundred-years-edition veriantlistpricesubtitle"
        *ngIf="returnVariantListByFilter(vCat).length > 0 && vCat == '100YEAR'">
         <!-- <br /> -->
        <!-- <small style="font-size: 8px; color: #cacaca">
          {{
            vCat == "SHARP(O)*"
              ? "*The Sharp(O) variant of Astor is also known as Savvy."
              : null
          }}
        </small> -->
      </div>
      <div
        class="veriantlistpricesubtitle"
        *ngIf="returnVariantListByFilter(vCat).length > 0 && vCat != '100YEAR'">
        {{ vCat }} <br />
        <small style="font-size: 8px; color: #cacaca">
          {{
            vCat == "SHARP(O)*"
              ? "*The Sharp(O) variant of Astor is also known as Savvy."
              : null
          }}
        </small>
      </div>
      <div class="one3d-tag">NEW</div>
      <li
        *ngFor="let variant of returnVariantListByFilter(vCat)">
        <div >
          <div class="fg coradio3">
            <input
              (click)="onVariantClick(variant)"
              id="{{ variant.d_name }}"
              type="radio"
              [name]="variant.d_name"
              value="veriandiesel1"
              [checked]="selectedVariant.variant_id == variant.variant_id"
              [disabled]="isLoading"
            />
            <label for="{{ variant.d_name }}">
              <div class="fg">
                <div class="veriantlistpricel">{{ variant.title }}</div>
                <div
                  matTooltip="Ex-Showroom Price"
                  class="veriantlistpricer"
                  *ngIf="selectedVariant.variant_id == variant.variant_id"
                >
                  ₹ {{ price }}
                </div>
                <div
                  matTooltip="Ex-Showroom Price"
                  class="veriantlistpricer"
                  *ngIf="selectedVariant.variant_id != variant.variant_id"
                >
                  ₹ {{ variant.price }}
                </div>
              </div>
            </label>
          </div>
        </div>
       
      </li>
    </ul>
  </div>
  <!--VARIANTS-->
</div>
<!--maincontainerrightin-->
<!--button-->
<div class="variantlisttitlebtn">
  <div class="variantlisttitlebtnleft">
    <a
      href="javascript:void(0)"
      class="variantlisttitlebtnleftbtn"
      (click)="gotoAccPage()"
      >Accessorize</a
    >
    <!-- <a router class="variantlisttitlebtnleftbtn">Accessorize</a> -->
  </div>
  <div class="variantlisttitlebtnright" (click)="gotoSummaryPage()">
    <a href="javascript:void(0)" class="variantlisttitlebtnrightbtn">Summary</a>
  </div>
</div>
<!--button-->
