import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../service/global.service';
@Component({
  selector: 'app-thank-you-modal',
  templateUrl: './thank-you-modal.component.html',
  styleUrls: ['./thank-you-modal.component.scss'],
})
export class ThankYouModalComponent implements OnInit {
  text: string;
  callConnected: boolean;
  constructor(private _global: GlobalService) {}

  ngOnInit(): void {
    this._global.thankYouText.subscribe((data) => (this.text = data));
    this._global.audioStatus.subscribe((data) => (this.callConnected = data));
  }
  closeModal() {
    this._global.toggleSubmitModal(false);
    this.gotoMainPage();
  }
  gotoMainPage() {
    window.location.replace('https://cc.mgmotor.co.in/vehicles.html');
  }
}
