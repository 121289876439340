import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SocketService } from './service/socket.service';
import { GlobalService } from './service/global.service';
import { CallService } from './service/call.service';
import { GaService } from './service/ga.service';
import { MgExpertService } from './service/mg-expert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { fromEvent, of, Observable, merge, Observer } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { EventService } from './service/event.service';
import { SessionWarningComponent } from './three-d-module/session-warning/session-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from './service/data.service';
import { ApiService } from './service/api.service';
import { defaultCarDetails } from './three-d-module/carSettings';

declare var ONE3D: any;
declare var window: any;
declare var TweenMax;

declare global {
  interface Window {
    pin_no: string;
    updatePayloadParameter: any;
    updateMultiplePayloadParameter: any;
    sendLiveDashboardData: any;
    sendData: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  controlAlert: boolean;
  requesterName: any;
  groupUsers: any[];
  accessReqFrom: any;
  ACCESS: boolean;
  selectedVariant: import('./service/global.service').variant;
  isOnline: any;
  isLandScape: boolean;

  /**
   * manages the user active status
   * @enum 'active','away','offline'
   */
  USER_ACTIVE_STATUS: string = 'active';
  /**
   * 2 min timer for checking the user in-activity
   */
  time2: any;
  /**
   * 5 min timer for checking the user in-activity
   */
  time5: any;
  /**
   * stores the pin_no of the configuration
   */
  pin_no: string;
  DISPLAY_TO_CUSTOMER: boolean;
  toggleTCPopup: boolean;
  agentDetails: any;
  callConnected: boolean;
  firstTimeAccess: boolean;
  globalPrice: any;
  createNewPinWhenModelChange: boolean;
  newLoader = false;
  circleLoader: boolean;
  constructor(
    private _socket: SocketService,
    private _global: GlobalService,
    private _ga: GaService,
    private _expert: MgExpertService,
    private _callS: CallService,
    private _data: DataService,
    private _api: ApiService,
    private _events: EventService,
    private route: ActivatedRoute
  ) {
    window.getOne3DEvent = (eventData: Object) => {
      console.log('event data///////////', eventData);
      window.sendLiveDashboardData(eventData);
    };
  }

  ngOnInit(): void {
    console.log('URL--->', window.location.href);
    this.setVariantToLoad();
    this._global.circleLoader.subscribe((data) => (this.circleLoader = data));
    this._global.newLoader.subscribe((data) => (this.newLoader = data));
    this._global.groupMembers.subscribe((data) => (this.groupUsers = data));
    this._global.accessUi.subscribe((data) => (this.ACCESS = data));
    this._global.variant.subscribe((data) => (this.selectedVariant = data));
    this._global.pinno.subscribe((data) => (this.pin_no = data));
    this._global.createNewPinWhenModelChange.subscribe(
      (data) => (this.createNewPinWhenModelChange = data)
    );
    this._global.isTCModalOpen.subscribe((data) => (this.toggleTCPopup = data));
    this._global.showRightPanelToCustomer.subscribe(
      (data) => (this.DISPLAY_TO_CUSTOMER = data)
    );
    this._global.audioStatus.subscribe((s) => {
      this.callConnected = s;
    });
    this._global.firstTimeConnected.subscribe((s) => {
      this.firstTimeAccess = s;
    });
    this._global.price.subscribe((data) => (this.globalPrice = data));
    this._global.callingAgentDetails.subscribe(
      (data) => (this.agentDetails = data)
    );
    this.registerSubscriber();
    this._expert.generateToken();
    // this.isOnline = merge(
    //   of(navigator.onLine),
    //   fromEvent(window, 'online').pipe(mapTo(true)),
    //   fromEvent(window, 'offline').pipe(mapTo(false))
    // );
    // this.createOnline$().subscribe((data) => (this.isOnline = data));
    window['appComponentRef'] = {
      one3dcallback: {
        onCarLoadComplete: () => this.onCarLoadComplete(),
      },
      component: this,
    };

    // SessionWarningComponent.;
  }

  setVariantToLoad() {
    this.route.queryParams.subscribe((params) => {
      const variantId = params['variant_id'];
      if (variantId) {
        console.log('Variant ID--->', variantId);
        this._data.setLocalVariant(variantId);
      } else {
        console.log('No variant_id in url');
      }
    });
  }

  onCarLoadComplete() {
    if (this.createNewPinWhenModelChange) {
      this.updateUserActiveStatus();
      this._socket.emitCheckAgentStatus();
      this._socket.checkRunningCall();
      this._socket.register3dEvents();
    }
    this._events.publish('load_complete', {});
    this._data.clearOnLoadTimerOn60days();
    // this.processLoadingStorage();
    if (this.newLoader) {
      this.newLoader = false;
    }
  }

  processLoadingStorage() {
    let counter = this._data.getOnLoadCounter();
    let storedFirstTimeDate = localStorage.getItem('firstTimeDate');
    if (!storedFirstTimeDate) {
      // Set the first time date when counter reaches 5 for the first time
      const currentDate = new Date().toISOString();
      localStorage.setItem('firstTimeDate', currentDate);
    }
    counter++;
    localStorage.setItem('onLoadCounter', counter.toString());
    if (counter >= 10000) {
      // // Block the UI when counter reaches 5
      this._data.enableBlockUI();
      //Emit event to stop the pincode flow.
      this._global.togglePincodeFlow(true);
      // console.log('Counter reached 5, performing action...');
    }
  }

  async ngAfterViewInit(): Promise<void> {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    await this._api.getToken();
    this.audioPermissionChangeCallBack();
    window.addEventListener('online', () => {
      document.getElementById('noInternet').style.display = 'none';
    });
    window.addEventListener('offline', () => {
      document.getElementById('noInternet').style.display = 'block';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // event.target.innerWidth;
    // if (window.hasOwnProperty('ONE3D')) {
    //   ONE3D.event.onResizeWindow();
    // }
  }
  @HostListener('window:mousemove') refreshUserState() {
    this.resetTimer();
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    console.log('orientationChanged', event);
    if (window.innerHeight > window.innerWidth) {
      // alert('Please use Landscape!');
      this.isLandScape = true;
    } else {
      this.isLandScape = false;
    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    // Do something
    event.preventDefault();
    event.stopPropagation();
    if (this.callConnected) {
      this._socket.emitUserLeftBrowserTab();
    }
  }

  ngOnDestroy(): void {
    this.closeCoBrowsing();
    // window.removeEventListener('online')
    // window.removeEventListener('offline')
  }
  requestForAccess() {
    this._ga.sendGAEvent('request control', 'click', 'click');
    this._socket.socket.emit('request_access', { room: this._socket.room });
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }
  registerSubscriber() {
    this._socket.socket.on('incoming_access_request', (data: any) => {
      console.log('this.controlAlert one', data);
      // alert(JSON.stringify(data))
      // if (this.controlAlert == false) {
      let requester = this.groupUsers.find((e) => e.id == data.from);
      if (requester) {
        this.requesterName = requester.username;
      }
      this.accessReqFrom = data.from;
      this.controlAlert = true;
      console.log('this.controlAlert', this.controlAlert);
      // this.openAccessAlert(data);
      // } else {
      //   console.log('came here');
      // }
    });
    this._events.subscribe('connect_call_admin', (data) => {
      console.log('connect_call_admin');

      this._callS.joinCall(data);
      // alert(1+ JSON.stringify(data)),
      this._global.updateSocketStatus('connected');
      this._global.updateAudioCallStatus(true);
      this._socket.emitVariantDetails(this.selectedVariant);
      console.log('connect_call_admin received', data);
      if (typeof window.callConnected === 'function') {
        window.callConnected({
          orientation: this.isLandScape ? 'portrait' : 'landscape',
          device: this._global.detectMob() ? 'mobile' : 'desktop',
        });
      }

      // document.getElementById('video').style.display = 'none'; // moved to call.service.ts due to html patch showing if video is not there.
    });
    this._events.subscribe('connected', (data) => {
      console.log('data', data);
      this._global.updateSocketStatus('connected');
      // alert(2+ JSON.stringify(data))
      if (data.token && data.chanel) {
        this._callS.joinCall(data);
        this._global.updateAudioCallStatus(true);
      }
      console.log('Joining call back');
      if (typeof window.callConnected === 'function') {
        window.callConnected({
          orientation: this.isLandScape ? 'portrait' : 'landscape',
          device: this._global.detectMob() ? 'mobile' : 'desktop',
        });
      }
      // document.getElementById('video').style.display = 'block';
    });

    this._events.subscribe('admin_drop_call', (data: any) => {
      console.log('admin dropped call', data);
      // if(this.showAgentCallingPopup){
      //   this.showAgentCallingPopup=false;
      //   this.stopRing();
      // }
      this._global.updateEnqGenerated(false);
      this._global.toggleFeedBackFormModal(true);
      this._global.updateAccessUI(true);
      this._global.updateAgentDetails({});
      ONE3D.exitAccessoriesView();
      ONE3D.exitFeatureView();
      this._callS.endCall();
      this._global.hideAllIconsInHome(false);
      this._global.updateAudioCallStatus(false);
      this._global.rightPanelHideShow(false);
      const localData = this._data.getDealerDetails();

      if (localData) {
        sessionStorage.clear();
      }
      this.closeCoBrowsing();
      var updateDummy = { value: 0 };
      if (
        !location.hash.includes('summary') &&
        !location.hash.includes('emi-calculator') &&
        !this.ACCESS
      ) {
        TweenMax.to(updateDummy, 1.5, {
          value: 1,
          onUpdate: () => {
            ONE3D.event.onResizeWindow();
          },
        });
      }
      if (typeof window.userControl === 'function')
        window.dealerControl({
          orientation: this.isLandScape ? 'portrait' : 'landscape',
          device: this._global.detectMob() ? 'mobile' : 'desktop',
        });
      // this.CALL_ENABLE = false;
      // setTimeout(async() => {
      //   await this.callService.updateCallToken(false, false);
      // }, 5000);
    });
  }
  openAccessAlert(data) {
    if (window.confirm(`${this.requesterName} has requested for access.`)) {
      console.log('Allow');
      this.accessResp(true);
    } else {
      this.accessResp(false);
    }
  }

  onRequestModalDismiss(state: any) {
    console.log('State', state);
    if (state == 'continue') {
      this.accessResp(true);
      this._global.updateHide3D(false);
      this._global.updateFirstTimeConnection(true);
    } else {
      this.accessResp(false);
    }
  }
  accessResp(result) {
    this.controlAlert = false;
    let from = this.accessReqFrom;
    if (result == true) {
      this._global.updateAccessUI(false);
      let updateDummy = { value: 0 };
      TweenMax.to(updateDummy, 0.6, {
        value: 1,
        onUpdate: () => {
          ONE3D.event.onResizeWindow();
        },
      });
      // setTimeout(() => {
      //   ONE3D.event.onResizeWindow();
      // }, 500); // resize animation conflict issue fixed
    }
    this._socket.socket.emit('access_response', { result, from });
    if (typeof window.dealerControl === 'function') {
      window.dealerControl({
        orientation: this.isLandScape ? 'portrait' : 'landscape',
        device: this._global.detectMob() ? 'mobile' : 'desktop',
      });
    }
  }

  closeCoBrowsing() {
    this._socket.disconnect();
    this._global.updateSocketStatus('completed');
    this._socket.socket.off('roomUsers');
    this._socket.socket.off('message');
    this._socket.socket.off('users_updated');
    this._socket.socket.off('connected');
    this._socket.socket.off('room_created');
    this._socket.socket.off('show_booking_url');
    this._socket.socket.off('hide_booking_url');
    this._socket.socket.off('rotate');
    this._socket.socket.off('fov');
    this._socket.socket.off('access_change');
    this._socket.socket.off('compare_data_show');
    this._socket.socket.off('incoming_access_request');
    this._socket.socket.off('admin_drop_call');
    this._socket.socket.off('call_token_generated');
    this._socket.socket.off('compare_data_hide');
    this._socket.socket.off('show_feedback_form');
  }
  resetTimer() {
    clearTimeout(this.time2);
    clearTimeout(this.time5);
    if (this.USER_ACTIVE_STATUS != 'active') {
      this.USER_ACTIVE_STATUS = 'active';
      this.updateStatus('active');
      this.updateUserActiveStatus();
    }
    this.time2 = setTimeout(() => {
      this.updateStatus('away');
      this.updateUserActiveStatus();
    }, 2 * 60 * 1000);
    this.time5 = setTimeout(() => {
      this.updateStatus('offline');
      this.updateUserActiveStatus();
    }, 5 * 60 * 1000);
  }
  updateStatus(status) {
    this.USER_ACTIVE_STATUS = status;
  }
  updateUserActiveStatus() {
    return new Promise((res) => {
      let chat_id = '';
      if (this.pin_no) {
        chat_id = `chat${this.pin_no}`;
      } else {
        sessionStorage.removeItem('p_');
        sessionStorage.removeItem('c_');
        sessionStorage.removeItem('v_');
        sessionStorage.removeItem('b_');
        alert('please wait while reloading the page');
        window.location.reload();
        res(false);
        return false;
      }
      let params = {
        chat_id: chat_id,
        customer_status: this.USER_ACTIVE_STATUS,
        chat_status: '',
      };
      this._expert.updateUserStatus(params).then(
        (data: any) => {
          console.log('STATUS UPDATED', data);
          if (data.status == 200) {
            res(true);
          }
          res(false);
        },
        (err) => {
          console.error('error from status', err);
          res(false);
        }
      );
    });
  }

  closeModal() {
    this._global.toggleTCModal(false);
  }
  get isInFeaturePAge() {
    return location.hash.includes('feature');
  }

  async audioPermissionChangeCallBack() {
    console.log('audioPermissionChangeCallBack');
    if (navigator.userAgent.indexOf('Chrome') != -1) {
      const permissionName = 'microphone' as PermissionName;
      const audio_permission = await navigator.permissions.query({
        name: permissionName,
      });
      this._ga.sendGAEvent(
        'Audio Permission status',
        'click',
        audio_permission.state
      );
      audio_permission.onchange = (evt) => {
        // const allowed = audio_permission.state === "granted";
        if (audio_permission.state === 'granted') {
          this._ga.sendGAEvent('Audio Permission success', 'click', 'click');
        } else if (audio_permission.state === 'denied') {
          this._ga.sendGAEvent('Audio Permission failed', 'click', 'click');
        }
      };
    } else {
      console.log('not Chrome');
    }
  }

  connectCall() {
    window.sendLiveDashboardData({
      eName: 'click',
      eCat: 'car_config_side_menu',
      eAct: 'live_consultation_button_clicked',
    });
    this._ga.sendGAEvent('landing page', 'click', 'expert');
    this._events.publish('openCallingModal', '');
    this.dismissUIBlock();
  }
  dismissUIBlock() {
    this._data.dismissUIBlock();
  }
}
