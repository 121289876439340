import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from './data.service';
import { GaService } from './ga.service';
export interface variant {
  model_text: string;
  price: string;
  title: string;
  variant_id: number;
  d_name: string;
  variant_name: string;
}
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  secret = '$s45Ddrg*6^6h*sweg%';

  private priceSource = new BehaviorSubject<any>('0');
  price = this.priceSource.asObservable();

  private defaultPinSource = new BehaviorSubject<string>('');
  pinno = this.defaultPinSource.asObservable();

  private variantSource = new BehaviorSubject<variant>({
    model_text: 'HECTOR Gasoline 1.5 TCIC DCT Smart BSVI',
    price: '15,93,800',
    title: null,
    variant_id: 4,
    d_name: 'Sharp AT Petrol',
    variant_name: 'SMART',
  });
  variant = this.variantSource.asObservable();

  private loaderSource = new BehaviorSubject<boolean>(false);
  loader = this.loaderSource.asObservable();

  private doorOpenSource = new BehaviorSubject<boolean>(false);
  carDoorIsOpen = this.doorOpenSource.asObservable();

  private headLampSource = new BehaviorSubject<boolean>(false);
  headLampIsOpen = this.headLampSource.asObservable();

  private sunRoofSource = new BehaviorSubject<boolean>(false);
  sunRoofIsOpen = this.sunRoofSource.asObservable();

  private isInteriorSource = new BehaviorSubject<boolean>(false);
  isInterior = this.isInteriorSource.asObservable();

  private resetAccCamSource = new BehaviorSubject<boolean>(false);
  resetAccCam = this.resetAccCamSource.asObservable();

  private colorListSource = new BehaviorSubject<any[]>([]);
  colorList = this.colorListSource.asObservable();

  private colorAmbientListSource = new BehaviorSubject<any[]>([]);
  ambientColorList = this.colorAmbientListSource.asObservable();

  private selectedColorSource = new BehaviorSubject<any>({});
  selectedColor = this.selectedColorSource.asObservable();

  private accPreviewSrc = new BehaviorSubject<boolean>(false);
  accPreview = this.accPreviewSrc.asObservable();

  private overlaySource = new BehaviorSubject<string>('');
  overlaySrc = this.overlaySource.asObservable();

  private variantListSource = new BehaviorSubject<any[]>([]);
  variantList = this.variantListSource.asObservable();

  private groupMembersSource = new BehaviorSubject<any[]>([]);
  groupMembers = this.groupMembersSource.asObservable();

  private newLoaderSource = new BehaviorSubject<boolean>(false);
  newLoader = this.newLoaderSource.asObservable();

  private circleLoaderSource = new BehaviorSubject<boolean>(false);
  circleLoader = this.circleLoaderSource.asObservable();

  private accessUiSource = new BehaviorSubject<boolean>(true);
  accessUi = this.accessUiSource.asObservable();

  private showRightPanelToCustomerSource = new BehaviorSubject<boolean>(false);
  showRightPanelToCustomer = this.showRightPanelToCustomerSource.asObservable();

  private callingAgentDetailsSource = new BehaviorSubject<any>({});
  callingAgentDetails = this.callingAgentDetailsSource.asObservable();

  private soundTrackURLSource = new BehaviorSubject<string>('');
  soundTrackURL = this.soundTrackURLSource.asObservable();

  private audioOliviaStatusSource = new BehaviorSubject<audioStatus>(
    audioStatus.stop
  );
  audioOliviaStatus = this.audioOliviaStatusSource.asObservable();

  private openFeatureCompletePopupSource = new BehaviorSubject<boolean>(false);
  openFeatureCompletePopup = this.openFeatureCompletePopupSource.asObservable();

  private hideAllIconsSource = new BehaviorSubject<boolean>(false);
  hideAllIcons = this.hideAllIconsSource.asObservable();

  private openLocModalSource = new BehaviorSubject<boolean>(false);
  openLocModal = this.openLocModalSource.asObservable();

  private locationDataSource = new BehaviorSubject<any>({});
  locationData = this.locationDataSource.asObservable();

  private emiOptionSource = new BehaviorSubject<any>({});
  emiOption = this.emiOptionSource.asObservable();

  private playNextFeatureSource = new BehaviorSubject<boolean>(false);
  playNextFeature = this.playNextFeatureSource.asObservable();

  private isOpenPulseHubEnqFormSource = new BehaviorSubject<boolean>(false);
  isOpenPulseHubEnqForm = this.isOpenPulseHubEnqFormSource.asObservable();

  private isOpenPulseHubSource = new BehaviorSubject<boolean>(false);
  isOpenPulseHubForm = this.isOpenPulseHubSource.asObservable();

  private clearPincodeFlowSource = new BehaviorSubject<boolean>(false);
  clearPincodeFlow = this.clearPincodeFlowSource.asObservable();

  private featureImageListSource = new BehaviorSubject<any>([]);
  featureImageList = this.featureImageListSource.asObservable();

  private isSoundMutedSource = new BehaviorSubject<boolean>(false);
  isSoundMuted = this.isSoundMutedSource.asObservable();

  private isEnqGeneratedSource = new BehaviorSubject<boolean>(false);
  isEnqGenerated = this.isEnqGeneratedSource.asObservable();

  // private hide3DSource = new BehaviorSubject<boolean>(false);
  // hide3D = this.hide3DSource.asObservable();

  private carCompareModalSource = new BehaviorSubject<boolean>(false);
  isCarCompareModalOpen = this.carCompareModalSource.asObservable();

  private isFeedBackModelSource = new BehaviorSubject<boolean>(false);
  isFeedBackModelOpen = this.isFeedBackModelSource.asObservable();

  private submitModalSource = new BehaviorSubject<boolean>(false);
  isSubmitModalOpen = this.submitModalSource.asObservable();

  private hide3DSource = new BehaviorSubject<boolean>(false);
  hide3D = this.hide3DSource.asObservable();

  private socketStatusSource = new BehaviorSubject<string>('');
  socketStatus = this.socketStatusSource.asObservable();

  private audioStatusSource = new BehaviorSubject<boolean>(false);
  audioStatus = this.audioStatusSource.asObservable();

  private agentAvailabilitySource = new BehaviorSubject<agentState>(
    agentState.offline
  );
  agentAvailability = this.agentAvailabilitySource.asObservable();

  private thankYouTextSource = new BehaviorSubject<string>('');
  thankYouText = this.thankYouTextSource.asObservable();

  private isAccAppliedTo3DSource = new BehaviorSubject<boolean>(false);
  isAccAppliedTo3D = this.isAccAppliedTo3DSource.asObservable();

  private dealerDetailsForTokenSource = new BehaviorSubject<any>(null);
  dealerDetailsForToken = this.dealerDetailsForTokenSource.asObservable();

  private isArModalOpenSource = new BehaviorSubject<boolean>(false);
  isArModalOpen = this.isArModalOpenSource.asObservable();

  private isTandCModalSource = new BehaviorSubject<boolean>(false);
  isTCModalOpen = this.isTandCModalSource.asObservable();

  private notToSubsRotateDataSource = new BehaviorSubject<boolean>(false);
  notToSubsRotateData = this.notToSubsRotateDataSource.asObservable();

  private appointmentFormSource = new BehaviorSubject<boolean>(false);
  appointmentForm = this.appointmentFormSource.asObservable();

  private appointmentRescheduleFormSource = new BehaviorSubject<boolean>(false);
  appointmentRescheduleForm = this.appointmentRescheduleFormSource.asObservable();

  private showRescheduleBtnFlagSource = new BehaviorSubject<boolean>(true);
  showRescheduleBtnFlag = this.showRescheduleBtnFlagSource.asObservable();

  //#region created by @Animesh for display success popup
  private appointmentSuccessModalSource = new BehaviorSubject<boolean>(false);
  appointmentSuccessModal = this.appointmentSuccessModalSource.asObservable();
  //#endregion

  private isIntColorChangeSource = new BehaviorSubject<boolean>(false);
  isIntColorChange = this.isIntColorChangeSource.asObservable();
  /**
   * summary open flag @JP
   */
  private summaryFlagSource = new BehaviorSubject<boolean>(false);
  summaryFlag = this.summaryFlagSource.asObservable();

  private mgShieldSource = new BehaviorSubject<mgShield>({
    buybackPlan: '',
    protectPlan: '',
    roadsideAssist: '',
    warranty: '',
  });

  mgShieldValues = this.mgShieldSource.asObservable();

  private firstTimeConnectedSource = new BehaviorSubject<boolean>(false);
  firstTimeConnected = this.firstTimeConnectedSource.asObservable();

  private createNewPinWhenModelChangeSource = new BehaviorSubject<boolean>(
    true
  );
  createNewPinWhenModelChange =
    this.createNewPinWhenModelChangeSource.asObservable();

  private exchangePriceSource = new BehaviorSubject<string>('');
  exchangePrice = this.exchangePriceSource.asObservable();

  private hectorSeatingOptionsSource = new BehaviorSubject<
    hectorPlusSeating | glosterSeating
  >(null);
  hectorSeatingOptions = this.hectorSeatingOptionsSource.asObservable();

  private isAgentMuteSource = new BehaviorSubject<boolean>(false);
  isAgentMute = this.isAgentMuteSource.asObservable();

  private logoOnSource = new BehaviorSubject<boolean>(false);
  logoOn = this.logoOnSource.asObservable();

  private limitedEdition3DSource = new BehaviorSubject<boolean>(true);
  limitedEdition3D = this.limitedEdition3DSource.asObservable();

  private limitedEditionUISource = new BehaviorSubject<boolean>(true);
  limitedEditionUI = this.limitedEditionUISource.asObservable();

  private showLimitedPackFlagSource = new BehaviorSubject<boolean>(true);
  showLimitedPackFlag = this.showLimitedPackFlagSource.asObservable();

  private limitedEditionDataSource = new BehaviorSubject<any>([]);
  limitedEditionData = this.limitedEditionDataSource.asObservable();
  
  
  private selectedEditionDataSource = new BehaviorSubject<any>({});
  selectedEditionData = this.selectedEditionDataSource.asObservable();

  private agentBusySource = new BehaviorSubject<any>({});
  agentBusy = this.agentBusySource.asObservable();

  constructor(private _ga: GaService, private _data: DataService) {}
  updatedPrice(price) {
    this.priceSource.next(price);
  }
  updatePinno(pinno: string) {
    this.defaultPinSource.next(pinno);
  }

  updatedVariant(variant: variant) {
    this.variantSource.next(variant);
  }
  showLoading(showLoader: boolean) {
    this.loaderSource.next(showLoader);
  }

  updateDoorState(state: boolean) {
    this.doorOpenSource.next(state);
  }

  updateInteriorState(state: boolean) {
    this.isInteriorSource.next(state);
  }

  updateHeadLampState(state: boolean) {
    this.headLampSource.next(state);
  }
  updateSunRoofState(state: boolean) {
    this.sunRoofSource.next(state);
  }

  updateNewLoader(state: boolean) {
    this.newLoaderSource.next(state);
  }

  showCircleLoader(state: boolean) {
    this.circleLoaderSource.next(state);
  }

  updateColorList(List: any) {
    this.colorListSource.next(List);
  }
  updateAmbientColorList(List: any) {
    this.colorAmbientListSource.next(List);
  }
  updateAccCam(state: boolean) {
    this.resetAccCamSource.next(state);
  }
  updateSelectedColor(color: any) {
    this.selectedColorSource.next(color);
  }

  updateAccPreview(state: boolean) {
    this.accPreviewSrc.next(state);
  }
  updateOverlaySrc(src: string) {
    this.overlaySource.next(src);
  }
  updateVariantList(list: any) {
    this.variantListSource.next(list);
  }
  updateAccessUI(status: boolean) {
    this.accessUiSource.next(status);
    if (status == true) {
      let videoGrid = document.querySelector('.video-grid');
      videoGrid.classList.remove('shift-to-right');
    } else {
      let videoGrid = document.querySelector('.video-grid');
      videoGrid.classList.add('shift-to-right');
    }
  }
  updateGroupList(list: any) {
    this.groupMembersSource.next(list);
  }
  updateAgentDetails(details: any) {
    this.callingAgentDetailsSource.next(details);
  }
  updateSoundTrackURL(url: string) {
    this.soundTrackURLSource.next(url);
  }
  updateOliviaAudioStatus(status: audioStatus) {
    this.audioOliviaStatusSource.next(status);
  }
  toggleFeatureCompletePopup(status: boolean) {
    this.openFeatureCompletePopupSource.next(status);
  }
  hideAllIconsInHome(status: boolean) {
    this.hideAllIconsSource.next(status);
  }
  updateEmiOptions(details: any) {
    this.emiOptionSource.next(details);
  }
  updateHide3D(flag: boolean) {
    this.hide3DSource.next(flag);
  }
  updateLocation(details: any) {
    this.locationDataSource.next(details);
  }
  updatePlayNextFeature(state: boolean) {
    this.playNextFeatureSource.next(state);
  }
  updateImageFeatureList(data: any) {
    this.featureImageListSource.next(data);
  }
  updateSoundStatus(status: boolean) {
    this.isSoundMutedSource.next(status);
    this._ga.sendGAEvent('sound', 'click', status ? 'mute' : 'unmute');
  }
  toggleLocModal(state: boolean) {
    this.openLocModalSource.next(state);
  }
  togglePulseHubEnqForm(state: boolean) {
    this.isOpenPulseHubEnqFormSource.next(state);
  }
  togglePulseHub(state: boolean) {
    this.isOpenPulseHubSource.next(state);
  }
  togglePincodeFlow(state: boolean) {
    this.clearPincodeFlowSource.next(state);
  }
  toggleCarCompareModal(state: boolean) {
    this.carCompareModalSource.next(state);
  }
  toggleSubmitModal(state: boolean) {
    this.submitModalSource.next(state);
  }
  updateSocketStatus(state: string) {
    this.socketStatusSource.next(state);
  }
  updateAudioCallStatus(state: boolean) {
    this.audioStatusSource.next(state);
  }
  updateAgentAvailability(state: agentState) {
    this.agentAvailabilitySource.next(state);
  }
  updateThankyouText(msg: string) {
    this.thankYouTextSource.next(msg);
  }
  updateMGShield(value: mgShield) {
    this.mgShieldSource.next(value);
  }
  updateAccApplied(value: boolean) {
    this.isAccAppliedTo3DSource.next(value);
  }
  updateEnqGenerated(status: boolean) {
    this.isEnqGeneratedSource.next(status);
  }
  updateDealerDetailsToken(data: any) {
    this.dealerDetailsForTokenSource.next(data);
  }
  detectMob() {
    return window.innerWidth <= 750 && window.innerHeight <= 900;
  }
  rightPanelHideShow(status: boolean) {
    this.showRightPanelToCustomerSource.next(status);
    // if (status == true) {
    //   let videoGrid = document.querySelector('.video-grid');
    //   videoGrid.classList.remove('shift-to-right');
    // } else {
    //   let videoGrid = document.querySelector('.video-grid');
    //   videoGrid.classList.add('shift-to-right');
    // }
  }
  toggleFeedBackFormModal(state: boolean) {
    this.isFeedBackModelSource.next(state);
  }
  toggleArModal(state: boolean) {
    this.isArModalOpenSource.next(state);
  }
  toggleTCModal(state: boolean) {
    this.isTandCModalSource.next(state);
  }
  updateRotateData(state: boolean) {
    this.notToSubsRotateDataSource.next(state);
  }
  updateAppointmentForm(state: boolean) {
    this.appointmentFormSource.next(state);
  }
  updateAppointmentRescheduleForm(state: boolean) {
    this.appointmentRescheduleFormSource.next(state);
  }
  updateShowRescheduleBtnFlag(state: boolean) {
    this.showRescheduleBtnFlagSource.next(state);
  }

  //#region created by @Animesh for display success popup

  updateAppointmentSuccessModal(state: boolean) {
    this.appointmentSuccessModalSource.next(state);
  }

  //#endregion

  updateFirstTimeConnection(state: boolean) {
    this.firstTimeConnectedSource.next(state);
  }
  updateHectorSeatingOption(state: hectorPlusSeating | glosterSeating) {
    console.log('seat update------->', state);

    this.hectorSeatingOptionsSource.next(state);
  }
  toggleIntColorChange(state: boolean) {
    this.isIntColorChangeSource.next(state);
  }
  updateCarExchangePrice(state: string) {
    this.exchangePriceSource.next(state);
  }
  /**
   * summary open flag @JP
   */
  updateSummaryFlag(state: boolean) {
    this.summaryFlagSource.next(state);
  }
  updateNewPinWhenModelChange(state: boolean) {
    console.log('Called WHEnnnnnn');

    this.createNewPinWhenModelChangeSource.next(state);
  }

  toggleAgentMute(state: boolean) {
    this.isAgentMuteSource.next(state);
  }

  toggleMgLogo(state: boolean) {
    this.logoOnSource.next(state);
  }
  updateLimitedEdition3D(state:boolean) {
    this.limitedEdition3DSource.next(state);
  }
  updateLimitedEditionUI(state:boolean) {
    this.limitedEditionUISource.next(state);
  }
  updateShowLimitedPackFlag(state:boolean) {
    this.showLimitedPackFlagSource.next(state);
  }
  updateLimitedEditionData(state:[]) {
    this.limitedEditionDataSource.next(state);
  }
  updateSelectedEditionData(state:{}) {
    this.selectedEditionDataSource.next(state);
  }
  updateAgentBusyStatus(state:boolean) {
    this.agentBusySource.next(state);
  }
}

export enum audioStatus {
  playing,
  stop,
}
export enum agentState {
  online,
  offline,
  busy,
}
export interface mgShield {
  buybackPlan: string;
  protectPlan: string;
  roadsideAssist: string;
  warranty: string;
}
export enum hectorPlusSeating {
  fiveSeater = '5',
  sixSeater = '6',
  sevenSeater = '7',
}
export enum glosterSeating {
  sixSeater = '6',
  sevenSeater = '7',
}
