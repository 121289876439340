import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GaService } from '../service/ga.service';

@Component({
  selector: 'app-access-modal',
  templateUrl: './access-modal.component.html',
  styleUrls: ['./access-modal.component.scss'],
})
export class AccessModalComponent implements OnInit {
  @Input() nameRequester: string;
  @Input() afterFirstTime: boolean;
  @Output('dismiss') action: EventEmitter<string> = new EventEmitter<string>();
  constructor(private _ga: GaService) {}

  ngOnInit(): void {}
  onCancel() {
    this._ga.sendGAEvent('access', 'click', 'deny');
    window.sendData({
      eName: 'click',
      eCat: 'connect_to_agent',
      eAct: 'close_button_clicked',
    });
    this.action.emit('cancel');
  }
  onContinue() {
    this._ga.sendGAEvent('access', 'click', 'allow');
    window.sendData({
      eName: 'click',
      eCat: 'connect_to_agent',
      eAct: 'allow_button_clicked',
    });
    this.action.emit('continue');
  }
}
