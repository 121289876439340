import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/service/data.service';
import { defaultCarDetails } from '../carSettings';
import { GlobalService } from 'src/app/service/global.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SocketService } from 'src/app/service/socket.service';
import { GaService } from 'src/app/service/ga.service';
declare const ONE3D: any;
@Component({
  selector: 'app-limited-edition-accessories',
  templateUrl: './limited-edition-accessories.component.html',
  styleUrls: ['./limited-edition-accessories.component.scss']
})
export class LimitedEditionAccessoriesComponent implements OnInit {
  limitedEdition:boolean;
  limitedEditionData: any[]=[];
  accUrl: string;
  $subscription:Subscription
  ACCESS: boolean;
  constructor(
    private router:Router,
    private _data:DataService,
    private _global:GlobalService,
    private _socket: SocketService,
    private _ga:GaService,
  ) {
    this.accUrl = environment.accessoriesImageCdnUrl;
   }

  ngOnInit(): void {
    this.$subscription = this._global.limitedEditionUI.subscribe((data) => (this.limitedEdition = data));
    this.$subscription.add(this._global.limitedEditionData.subscribe((data) => {this.limitedEditionData = data}));
    this.$subscription.add(this._global.accessUi.subscribe((data) => (this.ACCESS = data)));
  }
  ngOnDestroy(): void {
    this.$subscription.unsubscribe();
    this.resetAccCam();
  }
  backToHome(){
    let loadedCar = this._data.getLocalCarBrand();
    let url = defaultCarDetails[loadedCar].urlScheme;
    this.router.navigate([`${url}/home`]);
  }
  gotoAccessories(){
    this._ga.sendGAEvent("Gamer edition","click","Add more accessories")
    let loadedCar = this._data.getLocalCarBrand();
    let url = defaultCarDetails[loadedCar].urlScheme;
    this.router.navigate([`${url}/accessorize`]);
  }
  toggleGamerEdition($event){
    this._ga.sendGAEvent("Gamer edition","click",$event?"On":"Off")
    this._global.updateLimitedEditionUI($event)
    this._global.updateLimitedEdition3D($event)
  }
  gotoSummary(){
    let loadedCar = this._data.getLocalCarBrand();
    let url = defaultCarDetails[loadedCar].urlScheme;
    this.router.navigate([`${url}/summary`]);
  }
  onCarView(item) {
    this._ga.sendGAEvent("Gamer edition","click",item.marketing_name);
    ONE3D.setAccessorieCamera(item.hotspot_name, null, {
      callback: () => {
        if (this.ACCESS) {
          this._socket.emitAccAdd(item);
        }
        this._global.updateAccCam(true);
      },
    });
  }
  resetAccCam() {
    ONE3D.exitAccessoriesView();
    // this._global.updateAccPreview(false);
    this._global.updateAccCam(false);
    this._global.updateOverlaySrc('');
    document.getElementById('featureImageFullScreen').style.display = 'none';
  }
}
