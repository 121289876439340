import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../service/data.service';
import { GaService } from '../service/ga.service';
import { defaultCarDetails, getAssetsURL } from '../three-d-module/carSettings';
import { Howl } from 'howler';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
declare var window: any;

@Component({
  selector: 'app-sound-check',
  templateUrl: './sound-check.component.html',
  styleUrls: ['./sound-check.component.scss'],
})
export class SoundCheckComponent implements OnInit {
  stepCount: string = '2'; //changing the step count to 2 from 1 to bypass Play Sound Pop up
  sound: any;
  playedCounter: number = 0;
  showAnim: boolean = true;
  loadedCar: any;
  assetsUrl: any = getAssetsURL();
  constructor(
    private router: Router,
    private titleService: Title,
    private routerAc: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private _data: DataService,
    private _ga: GaService
  ) { }

  ngOnInit(): void {
    this.routerAc.queryParams.subscribe((data) => {
      if (data) {
        console.log('data', data);
        if (data.car && data.car == 'hector') {
          this._data.setLocalCarBrand('hectornew');
          this.titleService.setTitle("MG Hector | eXpert");
        } else
          if (data.car && data.car == 'hectorplus') {
            this._data.setLocalCarBrand('hectorplusnew');
            this.titleService.setTitle("MG HectorPlus | eXpert");
          } else
            if (data.car && data.car == 'astor') {
              this._data.setLocalCarBrand('kmodel');
              this.titleService.setTitle('MG Astor | eXpert');
            } else
              if (data.car && data.car == 'zsev') {
                this._data.setLocalCarBrand('zsev');
                this.titleService.setTitle('MG ZS EV | eXpert');
              } else
                if (data.car && data.car == 'gloster') {
                  this._data.setLocalCarBrand('gloster');
                  this.titleService.setTitle('MG Gloster | eXpert');
                } else
                  if (data.car && data.car == 'new-hector') {
                    this._data.setLocalCarBrand('hectornew');
                    this.titleService.setTitle("MG Hector | eXpert");
                  } else
                    if (data.car && data.car == 'new-hectorplus') {
                      this._data.setLocalCarBrand('hectorplusnew');
                      this.titleService.setTitle("MG HectorPlus | eXpert");
                    } else
                      if (data.car && data.car == 'e-model') {
                        this._data.setLocalCarBrand('emodel');
                        this.titleService.setTitle("MG COMET EV '23 | eXpert");
                      }
      }
    });
    let self = this;
    this.sound = new Howl({
      src: [`${this.assetsUrl}/audio-journey/Welcome Hey There!.mp3`],
      loop: false,
      volume: 1,
      onend: function () {
        self.audioCompleted();
      },
    });
    this.loadedCar = this._data.getLocalCarBrand();
    this._ga.addGAscript(this.loadedCar);
    window.updatePayloadParameter(
      'eLa1',
      defaultCarDetails[this.loadedCar].gaModelName
    );
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.cdref.detectChanges();
    var id1 = this.sound.play();
    this.playedCounter++;
    this.showAnim = true;
    window.sendData({ eName: 'page_view' });
  }

  audioCompleted() {
    console.log('Completed');
    this.showAnim = false;
    // this.sound.play();
    // if (this.playedCounter <= 1) {
    //   this.sound._src =
    //     'https://dxf3kulhcrbjg.cloudfront.net/mg_expert/audio-journey/Sound CHeck 2.wav';
    //   this.sound.load();
    //   setTimeout(() => {
    //     this.showAnim = true;
    //     this.sound.play();
    //     this.playedCounter++;
    //   }, 1000);
    // }
  }
  gotoCheckPage() {
    this.sound._src = `${this.assetsUrl}/audio-journey/Sound CHeck 3.mp3`;
    this.sound.load();
    this.sound.play();
    this.stepCount = '2';
    this._ga.sendGAEvent('play sound', 'click', 'click');
  }
  gotoForwad(): void {
    this.router.navigate(['/intro']);
    this._ga.sendGAEvent('sound working', 'click', 'yes');
    window.sendData({
      eName: 'click',
      eCat: 'soundcheck',
      eAct: 'car_button_clicked',
      eVal: 'yes',
      eLa1: defaultCarDetails[this.loadedCar].gaModelName,
    });
  }
  gotoBack(): void {
    // this.stepCount = '1';
    this.showAnim = true;
    this.sound._src = `${this.assetsUrl}/audio-journey/Sound CHeck 3.mp3`;
    this.sound.load();
    this.sound.play();
    this._ga.sendGAEvent('sound working', 'click', 'no');
    window.sendData({
      eName: 'click',
      eCat: 'soundcheck',
      eAct: 'car_button_clicked',
      eVal: 'no',
      eLa1: defaultCarDetails[this.loadedCar].gaModelName,
    });
  }
}