import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-acc-remove-modal',
  templateUrl: './acc-remove-modal.component.html',
  styleUrls: ['./acc-remove-modal.component.scss'],
})
export class AccRemoveModalComponent implements OnInit {
  @Input("message")message = "Your Configuration will reset, Are you sure you want to Continue?";
  constructor() {}
  @Output('dismiss') action: EventEmitter<string> = new EventEmitter<string>();
  ngOnInit(): void {
    console.log('msg',this.message);
  }

  onCancel() {
    this.action.emit('cancel');
  }
  onContinue() {
    this.action.emit('continue');
  }
}
